// Customizable Area Start
import React, {RefObject} from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikProps } from "formik";
import * as Yup from "yup";

const configJSON = require("./config.js");

interface QrCodeType {
  id: number;
  code_type: string;
  url: string;
  updated_at: string;
}

export type Props = {
    open: boolean;
    dialogOpen: (newState: boolean) => void;
    onSubmit: (data: QrCodeType) => void;
    closeDialog: () => void;
    id: number;
    initialValues: QrCodeType;
  };

interface S {
}

interface SS {
  id: number;
}

export const Schema = Yup.object().shape({
    code_type: Yup.string().required(configJSON.thisFieldIsRequired),
    url: Yup.string()
        .url(configJSON.validFieldUrl)
        .required(configJSON.thisFieldIsRequired)
});

export class GenerateQrCodeDialogController extends BlockComponent<Props, S, SS> {

    qrCodeRef: RefObject<FormikProps<QrCodeType>>;
    constructor(props: Props) {
        super(props);
        this.qrCodeRef = React.createRef();
        this.state = {
        };
    }

  async componentDidMount(): Promise<void> {
  }

  handleSubmitDialog = () => {
    this.qrCodeRef.current && this.qrCodeRef.current.handleSubmit();
    return false;
  }
  handleSubmit = (data: QrCodeType) => {
    this.props.onSubmit(data);
  }
}
// Customizable Area End
