// Customizable Area Start
import React from "react";
import { withStyles, createStyles, MenuItem, Theme } from "@material-ui/core";
import { Formik } from "formik";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import TextField from "../../ss-cms-common-components/src/TextField/TextField.web";
export const configJSON = require("./config.js");
import PromoCodeComponentController, { InputProps } from "./PromoCodeComponentController.web";

export class PromoCodeComponent extends PromoCodeComponentController {

  renderPromoCodeNameInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_promoCodeName)}
        helperText={this.getHelperText(errors, touched, configJSON.field_promoCodeName)}
        value={values?.promoCodeName}
        fullWidth
        data-testid={configJSON.field_promoCodeName}
        label={configJSON.promoCodeName}
        required
        id={configJSON.field_promoCodeName}
        name={configJSON.field_promoCodeName}
        onBlur={handleBlur}
        className={classes.inputField}
      />
    );
  };

  renderDescriptionInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_description)}
        helperText={this.getHelperText(errors, touched, configJSON.field_description)}
        value={values?.description}
        fullWidth
        label={configJSON.label_description}
        required
        id={configJSON.field_description}
        name={configJSON.field_description}
        onBlur={handleBlur}
        className={classes.inputField}
      />
    );
  };

  renderCodeAndDiscountType = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <div className={classes.inputRaw}>
        <div className={classes.inputContainer}>
          <TextField
            onChange={(event) => this.onTagsChange(event, setFieldValue)}
            error={this.getErrorStatus(errors, touched, configJSON.field_code)}
            helperText={this.getHelperText(errors, touched, configJSON.field_code)}
            value={values?.code}
            fullWidth
            label={configJSON.label_code}
            required
            id={configJSON.field_code}
            name={configJSON.field_code}
            onBlur={handleBlur}
            className={classes.inputField}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextField
            onChange={(event) => this.onTagsChange(event, setFieldValue)}
            select
            error={this.getErrorStatus(errors, touched, configJSON.field_discountType)}
            helperText={this.getHelperText(errors, touched, configJSON.field_discountType)}
            value={values?.discountType}
            label={configJSON.discountType}
            required
            fullWidth
            className={classes.class_selectInput}
            id={configJSON.field_discountType}
            name={configJSON.field_discountType}
            onBlur={handleBlur}
          >
            {" "}
            {this.state.discountTypes.length > 0 && this.state.discountTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    );
  };

  renderDiscountInput = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <TextField
        onChange={(event) => this.onTagsChange(event, setFieldValue)}
        error={this.getErrorStatus(errors, touched, configJSON.field_discount)}
        helperText={this.getHelperText(errors, touched, configJSON.field_discount)}
        value={values?.discount}
        type={configJSON.number_type}
        label={configJSON.discount}
        required
        fullWidth
        id={configJSON.field_discount}
        name={configJSON.field_discount}
        onBlur={handleBlur}
        className={classes.inputField}
      />
    );
  };

  renderValidFrom = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <div className={classes.inputContainer}>
        <label className={classes.inputLabel}>
          {configJSON.validFromText}
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disablePast
            disableToolbar
            variant={configJSON.key_inline}
            inputVariant={configJSON.key_outlined}
            format={configJSON.dateFormat}
            placeholder={configJSON.dateFormatPlaceholder}
            name={configJSON.validFrom}
            InputProps={{
              className: classes.input,
            }}
            InputAdornmentProps={{ position: configJSON.key_start }}
            value={values?.validFrom}
            onChange={(date) => this.handleOnChangeValidFrom(date, setFieldValue)}
            error={this.getErrorStatus(errors, touched, configJSON.field_validFrom)}
            helperText={this.getHelperText(errors, touched, configJSON.field_validFrom)}
            onBlur={handleBlur}
            className={classes.inputField}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  renderValidTo = (
    { values,
      errors,
      touched,
      setFieldValue,
      handleBlur
    }: InputProps) => {
    const { classes } = this.props;
    return (
      <div className={classes.inputContainer}>
        <label className={classes.inputLabel}>
          {configJSON.validToText}
        </label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disablePast
            disableToolbar
            variant={configJSON.key_inline}
            inputVariant={configJSON.key_outlined}
            format={configJSON.dateFormat}
            placeholder={configJSON.dateFormatPlaceholder}
            name={configJSON.validTo}
            value={values?.validTo}
            onChange={(date) => this.handleOnChangeValidTo(date, setFieldValue)}
            InputProps={{
              className: this.props.classes?.input,
            }}
            InputAdornmentProps={{ position: configJSON.key_start }}
            KeyboardButtonProps={{
              className: this.props.classes?.keyboardButton,
            }}
            error={this.getErrorStatus(errors, touched, "validTo")}
            helperText={this.getHelperText(errors, touched, "validTo")}
            onBlur={handleBlur}
            className={classes.inputField}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  renderCartValuesInputs = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur
  }: InputProps) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.inputContainer}>
          <div className={classes.inputContainer}>
            <TextField
              onChange={(event) => this.onTagsChange(event, setFieldValue)}
              error={this.getErrorStatus(errors, touched, configJSON.field_minCartValue)}
              helperText={this.getHelperText(errors, touched, configJSON.field_minCartValue)}
              value={values?.minCartValue}
              type={configJSON.number_type}
              fullWidth
              label={configJSON.label_minCartValue}
              required
              id={configJSON.field_minCartValue}
              name={configJSON.field_minCartValue}
              onBlur={handleBlur}
              className={classes.inputField}
            />
          </div>

          <div className={classes.inputContainer}>
            <TextField
              onChange={(event) => this.onTagsChange(event, setFieldValue)}
              value={values?.userLimit}
              fullWidth
              label={configJSON.label_LimitPerCustomer}
              id={configJSON.field_limitUser}
              name={configJSON.field_limitUser}
              onBlur={handleBlur}
              className={classes.inputField}
            />
          </div>
        </div>

        <div className={classes.inputContainer}>
          <TextField
            onChange={(event) => this.onTagsChange(event, setFieldValue)}
            error={this.getErrorStatus(errors, touched, configJSON.field_maxCartValue)}
            helperText={this.getHelperText(errors, touched, configJSON.field_maxCartValue)}
            value={values?.maxCartValue}
            type={configJSON.number_type}
            fullWidth
            label={configJSON.label_maxCartValue}
            required
            id={configJSON.field_maxCartValue}
            name={configJSON.field_maxCartValue}
            onBlur={handleBlur}
            className={classes.inputField}
          />
        </div>
      </>
    );
  };

  render() {
    const { formRef, promoCodeData, classes } = this.props;
    let initialRecord = {
      promoCodeName: promoCodeData?.promoCodeName ? promoCodeData.promoCodeName : "",
      description: promoCodeData?.description ? promoCodeData.description : "",
      code: promoCodeData?.code ? promoCodeData.code : "",
      discountType: promoCodeData?.discountType ? promoCodeData.discountType : configJSON.flatValue,
      discount: promoCodeData?.discount ? promoCodeData.discount : "",
      minCartValue: promoCodeData?.minCartValue ? promoCodeData.minCartValue : "",
      maxCartValue: promoCodeData?.maxCartValue ? promoCodeData.maxCartValue : "",
      validFrom: promoCodeData?.validFrom ? promoCodeData.validFrom : new Date(),
      validTo: promoCodeData?.validTo ? promoCodeData.validTo : new Date(),
      userLimit: promoCodeData?.userLimit ? promoCodeData.userLimit : null,
    }
    return (
      <>
        <Formik
          innerRef={formRef}
          initialValues={initialRecord}
          validationSchema={this.state.promoCodeId ? this.Schema1 : this.Schema}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
        >
          {(formikProps) => {
            const { values, errors, touched, handleBlur, setFieldValue } =
              formikProps;
            return (
              <form autoComplete="off" noValidate>
                <div className={classes.addContainer}>
                  <div className={classes.detailContainer}>
                    <div>
                      <label className={classes.detailTitle}>
                        {configJSON.promoCodeDetails}
                      </label>
                      {this.renderPromoCodeNameInput({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleBlur
                      })}
                      {this.renderDescriptionInput({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleBlur
                      })}
                      {this.renderCodeAndDiscountType({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleBlur
                      })}
                      {this.renderDiscountInput({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleBlur
                      })}
                    </div>
                  </div>
                  <div className={classes.detailContainer}>
                    <div>
                      <label className={classes.detailTitle}>
                        {configJSON.restrictions}
                      </label>
                      <div className={classes.inputRaw}>
                        {this.renderValidFrom({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleBlur
                        })}

                        {this.renderValidTo({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleBlur
                        })}
                      </div>

                      <div className={classes.inputRaw}>
                        {this.renderCartValuesInputs({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleBlur
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  }
};


const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "48px",
    },
    inputField: {
      marginBottom: "20px"
    },
    validationError: {
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      color: "#d50000",
      marginTop: "6"
    },
    inputRaw: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%"
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "0.48 1"
    },
    inputLabel: {
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      color: "#000",
      marginBottom: "10px"
    },
    addContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f8f9fa",
      flexDirection: "column"
    },
    detailContainer: {
      backgroundColor: "#fff",
      width: "60%",
      padding: "40px",
      marginBottom: "30",
      border: "2px solid #ecedf0",
      borderRadius: "3px",
      "& input": {
        padding: "18.5px 14px!important"
      },
      "& .MuiInputBase-formControl": {
        minHeight: "50px"
      }
    },
    detailTitle: {
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      color: "#3c3e49",
      marginBottom: "30px"
    },
    class_selectInput: {
      padding: "0 !important",
      marginBottom: "20px"
    },
    formActionsWrapper: {
      top: 0,
      left: 0,
      position: "fixed",
      width: "100%",
      zIndex: 1,
    },
    formActions: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#3C3E49",
      padding: theme.spacing(1.25, 3),
    },
    logo: {
      objectFit: "none",
      width: "auto",
    },
    discardChangesbutton: {
      fontSize: "14px",
      lineHeight: "20px",
      textDecoration: "underline",
      textTransform: "none",
      padding: theme.spacing(1.5, 3),
      color: "#FFFFFF",
    },
    saveChangesbutton: {
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "none",
      padding: theme.spacing(1.5, 5),
      backgroundColor: "#00D659",
      color: "#3C3E49",
    },
  });

export default withStyles(styles)(withHeaderBar(PromoCodeComponent));
// Customizable Area End