Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";
exports.getMethod = "GET";
exports.getBrandsEndPoint = "bx_block_catalogue/brands/home_brand_banner";
exports.getTopServicesEndPoint = "bx_block_catalogue/catalogues/top_services";
exports.getLocationEndPoint = "bx_block_settings/common_settings";
exports.getCustomerFeedbackEndPoint = "bx_block_customer_feedback/customer_feedbacks";
exports.textTESTIMONIALS = "TESTIMONIALS";
exports.landingPageEnter = "webcustomer_landing_page_enter";
// Customizable Area End
