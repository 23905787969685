// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
const configJSON = require("./config");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    id: string;
  };

interface S {
  page: number;
  showQrCodeDialog: boolean;
  selectedTab: number;
}

interface SS {
  id: string;
}

class MobileAppController extends BlockComponent<Props, S, SS> {
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      page: 1,
      showQrCodeDialog: false,
      selectedTab: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive = (from: string, message: Message) => {
  };

  
  changeDialogState = (newState: boolean) => {
    this.setState({
      showQrCodeDialog: newState
    })
  }

  hideLoader = () => {
    this.props.hideLoader();
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };
  
  getTabContent = () => {
    if (this.state.selectedTab === 0) {
      return configJSON.appSettingsHint;
    }
    return this.state.selectedTab === 2
      ? configJSON.qrCodeHint
      : configJSON.appRequirementHint;
  };
}
export default MobileAppController;
// Customizable Area End