// Customizable Area Start
import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { Tab as TabType } from "./Types";

interface TabsProps {
    onTabChange?: (event: React.ChangeEvent<{}>, value: number) => void;
    value: number;
    headerTabs: TabType[];
  }
  
  export const Tabs = ({ onTabChange, value, headerTabs }: TabsProps) => {
    return (
      <MuiTabs
        classes={{
          root: "tabs-root",
          indicator: "tabs-indicator",
          flexContainer: "tabs-flex-container",
        }}
        value={value}
        onChange={onTabChange}
      >
        {headerTabs.map((headerTab) => (
          <MuiTab
            key={headerTab.tab_name}
            classes={{
              root: "tabs-root",
              labelIcon: "tab-label-icon",
              selected: "tab-label-selected",
            }}
            label={`${headerTab.tab_name} (${headerTab.count})`}
            data-testid={"email-tab-" + headerTab.tab_name}
          />
        ))}
      </MuiTabs>
    );
  };
  // Customizable Area End