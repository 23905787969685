// Customizable Area Start
import PromoCodeController from './PromoCodeController.web';
import * as React from 'react';
import TableComponent from '../../ss-cms-common-components/src/TableComponent/TableComponent';
import EmptyPage from '../../ss-cms-common-components/src/EmptyPage/EmptyPage';
import Pagination from '../../ss-cms-common-components/src/Pagination/Pagination';
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from "@material-ui/core";
import withLoader from '../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
import withSnackBar from '../../ss-cms-common-components/src/HOC/withSnackBar.Web';
import { withDialog } from '../../ss-cms-common-components/src/Dialog/withDialog';
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
export const configJSON = require("./config.js");

export class PromoCode extends PromoCodeController {
  render() {
    const { promoCodeData, totalCount, checkedPromoList , isLoader, pagination} = this.state;
    return (
      <>
        {promoCodeData?.length > 0  && 
                <Scrollbars ref={this.scrollRef}>
                <div style={withStyle.tableContainer} data-testid="tableContainer">
                  <div style={withStyle.header}>
                    <div style={withStyle.titleWrapper}>
                      <section style={withStyle.blockRow}>
                        <span style={withStyle.title}>{configJSON.promoCodes}</span>
                        {checkedPromoList?.length > 0 &&
                          <p
                            style={withStyle.class_deleteBtn}
                            data-testid={configJSON.testid_deleteItems}
                            onClick={this.handleDeleteModal}>{configJSON.deletePromoCodes}</p>
                        }
                      </section>
                      <div style={withStyle.buttonWrapper}>
                        <button style={withStyle.createBtn} onClick={this.goToAddPromoCode}>
                          {configJSON.createPromoCode}
                        </button>
                      </div>
                    </div>
                  </div>
                  <TableComponent
                    titles={configJSON.titles}
                    pagination={pagination}
                    data={promoCodeData}
                    edit
                    handleEditClick={(item) => this.handleEditClick(item)}
                    getCheckedItems={(item) => this.checkedItems(item)}
                    ignoredKeys={['id']}
                    checkbox
                    readMoreType={false}
                  />
                  <div style={withStyle.paginationWrapper}>
                    <Pagination
                      count={totalCount}
                      onChange={this.handlePageChange}
                      pageSize={this.state.per_page}
                      itemText={configJSON.paginationText}
                      pageNo={this.state.page || 1}
                    />
                  </div>
                </div>
              </Scrollbars>
        }
        {
          !isLoader && promoCodeData?.length == 0 && 
          <Scrollbars>
          <div style={withStyle.emptyPageBox} data-testid="emptyPageContainer">
            <EmptyPage
              title={configJSON.emptyPageTitle}
              message={configJSON.emptyPageMessage}
              button_label={configJSON.emptyPageButtonLabel}
              handleClick={this.goToAddPromoCode}
            />
          </div>
        </Scrollbars>
        }
      </>
    )
  }
}

const withStyle = {
  class_deleteBtn: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
    margin: "0",
    paddingLeft: "20px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  emptyPageBox: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f9fa"
  },
  tableContainer: {
    backgroundColor: "#f8f9fa",
    height: "100%",
    paddingLeft: "30",
    paddingRight: "30"
  },
  header: {
    width: "100%",
    paddingTop: "30px",
    paddingBottom: "30px"
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  blockRow: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",
    color: "#3c3e49"
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    gap: "24px"
  },
  createBtn: {
    border: "none",
    outline: "none",
    display: "flex",
    textAlign: "center" as "center",
    flexDirection: "row" as "row",
    alignItems: "center",
    padding: "10px 32px",
    background: "#00d659",
    borderRadius: "3px",
    width: "201px",
    height: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#3c3e49"
  },
  paginationWrapper: {
    marginTop: "30px",
    marginBottom: "24px"
  },
}
export default (withStyles(withStyle)(
  withSnackBar(withLoader(withDialog(withHeaderBar(PromoCode))))
));;
// Customizable Area End
