import React from "react";
// Customizable Area Start
import { Box, Grid, Card, Typography, Divider, Theme, withStyles, Button, CardActions, CardContent, Collapse, IconButton } from "@material-ui/core";
import ConfirmationController, {Props,configJSON} from "./ConfirmationController.web"
import Currency from "../../utilities/src/components/Currency.web";
import {CheckMarkCurrect} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
// Customizable Area End

// Customizable Area Start
export const styles = (theme: Theme) => ({
  confirmationContainerStyleSize: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 20px",
    }
  },
  textFirstStyleText: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  dividerTextStyle: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  textSecondTextBox: {
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "14px",
    marginBottom: "20px",
    color: "#3C3E49",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "35px",
    }
  },
  containerBoxStyleSize: {
    maxWidth: "628px",
    margin: "auto"
  },
  appointmentStyleBox: {
    maxWidth: "628px",
    margin: "auto",
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Rubik",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subStyleBox: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#3C3E49",
    lineHeight: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#757575"
    }
  },
  serviceTextBoxStyle: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Rubik",
    color: "#757575",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  titleTextStyle: {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px",
    paddingBottom: "5px",
  },
  servicePriceTextStyle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  successImgStyle: {
    width: "82.54px",
    height: "82.54px",
    cursor: "pointer",
    top:"250px",
    marginBottom: "40px",
  },
  footerBoxSize: {
    borderTop: "1px solid #DFDFDF",
  },
  footerContainerSize: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px"
  },
  footerTextSizeBox: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionFirstSizeStyle: {
    borderTop: "1px solid #DFDFDF",
    marginTop: "35px",
    marginBottom: "20px",
  },
  subHeadStyleSize: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subContainerStyles: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    borderTop: "1px solid #DFDFDF",
  },
  textConatinersStyleBox: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px"
  },
  commentBox: {
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    }
  },
  cardContainerSize: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  imageContainerStyles: {
    height: "160px",
    [theme.breakpoints.down(960)]: {
      height: "140px"
    },
    [theme.breakpoints.down(480)]: {
      height: "120px"
    },
  },
  imageTagStyles: {
    objectFit: "cover" as "cover"
  },
  proceedBtnBoxStyles: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '& .MuiButton-label': {
      textTransform: "none"
    },
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    }
  },
  card_ShadowBoxStyles:{
    marginTop: "25px",
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  collapseViewCardTextStyles: {
    padding: "10px 0px",
    height:"40px",
    alignItems: "flex-start"
  },
  viewPoliciesStyles:{
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    lineHeight: "20px",
  },
  expandedPoliciessBoxStyles: {
    transform: "rotate(-90deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandedPoliciesOpenStyles: {
    transform: "rotate(0deg)",
  },
  cardContentStyles:{
    "&.MuiCardContent-root":{
      padding:"0px"
    },
    "&.MuiCardContent-root:last-child":{
      paddingBottom: "10px"
    }
  },
  policyTitleTextStyles:{
    fontSize: "12px",
    fontWeight: 500,
    marginBottom:"5px",
    lineHeight: "20px"
  },
  policyDeviderTextBox:{
    margin:"10px 0px",
    border:"1px solid #E8E8E8"
  },
  policyDescStyles:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#3C3E49",
    lineHeight: "20px"
  },
  policyTextStyles:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#676B7E",
    lineHeight: "20px"
  },
  collapseBoxStyles:{
    marginTop: "10px"
  },
})
// Customizable Area End

export class Confirmation extends ConfirmationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start

  renderPolicies = (classes: Record<string, string>) => {
    const { no_of_days_cancel, no_of_days_reschedule, reschedule_text, refund_text, cancel_text, is_cancellation_policy, is_refund_policy, is_reschedule_policy } = this.props.commonSetting.attributes
    return (
      <>
        {
          (is_cancellation_policy || is_refund_policy || is_reschedule_policy) &&
          <Card className={classes.card_ShadowBoxStyles} style={{ maxWidth: "1923px" }}>
            <Box style={{ padding: "0px 15px" }}>
              <CardActions disableSpacing className={classes.collapseViewCardTextStyles}>
                <Typography variant="h6" className={classes.viewPoliciesStyles}>
                  {configJSON.viewPolicies}
                </Typography>
                <IconButton
                  className={clsx(classes.expandedPoliciessBoxStyles, {
                    [classes.expandedPoliciesOpenStyles]: this.state.expandedPolicies,
                  })}
                  data-testid="expandTestId"
                  onClick={this.handleExpandPolicies}
                  aria-expandedPolicies={this.state.expandedPolicies}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse
                in={this.state.expandedPolicies}
                timeout="auto" unmountOnExit >
                <CardContent className={classes.cardContentStyles}>
                  {
                    is_cancellation_policy &&
                    <>
                      <Box>
                        <Typography className={classes.policyTitleTextStyles}>{configJSON.cancelPolicies}</Typography>
                        <Typography className={classes.policyDescStyles}>{`${no_of_days_cancel} ${configJSON.cancellationPolicyMsg}`}</Typography>
                        <Typography className={classes.policyTextStyles}>{cancel_text}</Typography>
                      </Box>
                      <Divider className={classes.policyDeviderTextBox} />
                    </>
                  }
                  {
                    is_reschedule_policy &&
                    <>
                      <Box className={classes.collapseBoxStyles}>
                        <Typography className={classes.policyTitleTextStyles}>{configJSON.rechedulePolicies}</Typography>
                        <Typography className={classes.policyDescStyles}>{`${no_of_days_reschedule} ${configJSON.reschedulePolicyMsg}`}</Typography>
                        <Typography className={classes.policyTextStyles}>{reschedule_text}</Typography>
                      </Box>
                      <Divider className={classes.policyDeviderTextBox} />
                    </>
                  }
                  {
                    is_refund_policy &&
                    <>
                      <Box className={classes.collapseBoxStyles}>
                        <Typography className={classes.policyTitleTextStyles}>{configJSON.refundPolicies}</Typography>
                        <Typography className={classes.policyTextStyles}>{refund_text}</Typography>
                      </Box>
                    </>
                  }
                </CardContent>
              </Collapse>
            </Box>
          </Card>
        }
      </>
    )
  }

  render() {
    const { service, bookingData, paymentOption, classes } = this.props;
    let paymentMethod;

    if (paymentOption === "pay_in_person") {
      paymentMethod = "Pay later at location";
    } else if (paymentOption === "pay_online") {
      paymentMethod = "Online";
    } else {
      paymentMethod = "";
    }
    return (
      <>
        <Box className={classes.confirmationContainerStyleSize}>
          <Box style={{ textAlign: "center" }}> 
          <img src={CheckMarkCurrect} alt="icon" className={classes.successImgStyle} />
            <Typography
              className={classes.textFirstStyleText}
              style={{ textTransform: "uppercase" }}
            >
              {configJSON.thankyou} {bookingData.attributes.personal_detail.full_name}
            </Typography>
            <Divider className={classes.dividerTextStyle} />
            <Typography className={classes.textSecondTextBox}>
              {configJSON.yourBookingIsConfirmed}{bookingData.attributes.personal_detail.email}
            </Typography>
          </Box>
          <Box className={classes.containerBoxStyleSize}>
            <Card className={classes.cardContainerSize}>
              <Typography
                className={classes.appointmentStyleBox}
              >
                {configJSON.appointmentSummary}
              </Typography>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subStyleBox}>{configJSON.orderId}{bookingData.attributes.order_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subStyleBox}>{configJSON.orderDate}{this.momentChangeForOrderData(bookingData.attributes.order_date)}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                <Grid item xs={4} sm={4} md={4} className={classes.imageContainerStyles}>
                  <img
                    src={service.images[0].small_url ? service.images[0].small_url : service.images[0].url}
                    alt="img"
                    width="100%"
                    height= "100%"
                    className={classes.imageTagStyles}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Box>
                    <Typography className={classes.serviceTextBoxStyle}>
                      {configJSON.service}
                    </Typography>
                    <Typography className={classes.titleTextStyle}>
                      {service.title}, {service.duration} {configJSON.mins}
                    </Typography>
                    <Typography className={classes.servicePriceTextStyle}>
                      <Currency text={`${bookingData?.attributes?.total.toFixed(2)} `} disountPrice={service?.undiscountedPrice?.toFixed(2)} time={""} hasDiscount={service.hasDiscount} />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.sectionFirstSizeStyle}>
                <Typography className={classes.subHeadStyleSize}>{configJSON.appointmentDateAndTime}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinersStyleBox}>{this.momentChangeForAppointmentDate(bookingData.attributes.appointment_date,bookingData.attributes.time_zone_short)}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainerStyles}>
                <Typography className={classes.subHeadStyleSize}>{configJSON.personalDetails}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinersStyleBox}>{bookingData.attributes.personal_detail.full_name}</Typography>
                  <Typography className={classes.textConatinersStyleBox}>{bookingData.attributes.personal_detail.email} | {bookingData.attributes.personal_detail.full_phone_number}</Typography>
                  <Typography className={classes.commentBox}>{bookingData.attributes.personal_detail.comment}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainerStyles}>
                <Typography className={classes.subHeadStyleSize}>{configJSON.modeOfPayment}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinersStyleBox}>{paymentMethod}</Typography>
                </Box>
              </Box>
              <Box className={classes.footerBoxSize}>
                <Box className={classes.footerContainerSize}>
                  <Typography className={classes.footerTextSizeBox}>{configJSON.total}</Typography>
                  <Typography className={classes.footerTextSizeBox}><Currency text={bookingData?.attributes?.total.toFixed(2)} disountPrice={""} time={""} hasDiscount={false}/></Typography>
                </Box>
              </Box>
              {this.renderPolicies(classes)}
              <Button
                variant="contained"
                className={classes.proceedBtnBoxStyles}
                onClick={this.handleGoToHomeScreen}
              >
                {configJSON.ExploreMoreServices}
              </Button>
            </Card>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(Confirmation);
// Customizable Area End