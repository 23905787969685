// Customizable Area Start
import * as React from "react";
import { useState, useEffect } from "react";
import "./tableComponent.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import parser from "html-react-parser";
import {
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
  Collapse,
  Typography,
} from "@material-ui/core";
import { getStorageData } from "../../../../framework/src/Utilities";
import { PaginationData } from "../../../AdminPromoCode/src/PromoCodeController.web";

interface Data {
  id: number;
  description?: string;
  position?: number;
  customerName?: string;
  activated?: boolean;
  name?: string;
  number?: number;
  email?: string;
}

interface Datas {
  id: number;
  help_center_type?: string;
  title: string;
  description: string;
  status: boolean;
  truncated: boolean;
  truncatable: boolean;
}

interface StyleArray {
  columnName: string;
  style: React.CSSProperties;
}
type TableComponentPropType<T = Data> = {
  handleDeleteClick?: (item: T) => void;
  handleEditClick?: (item: T) => void;
  handleClick?: (item: T, index: number) => void;
  handlePushClick?: (item: T) => void;
  handleDownloadClick?: (value: string) => void;
  handleCustomerOnClick?: (id: number) => void;
  getCheckedItems?: (items: T[]) => void;
  handleReademore?:(item: number) => void;
  readMoreType: boolean;
  readMoreId?: number;
  data: T[];
  pagination?: PaginationData;
  titles: Array<string>;
  tableContainerClassname: React.CSSProperties;
  headCellStyle: React.CSSProperties;
  bodyCellStyle: React.CSSProperties;
  readMoreBtn: React.CSSProperties;
  activatedCellStyleTrue: React.CSSProperties;
  activatedCellStyleFalse: React.CSSProperties;
  permissionCellStyle: React.CSSProperties;
  descriptionContainerStyle: React.CSSProperties;
  pushButtonStyle: React.CSSProperties;
  placedStatusCellStyle: React.CSSProperties;
  deliveredStatusCellStyle: React.CSSProperties;
  refundedStatusCellStyle: React.CSSProperties;
  cancelledStatusCellStyle: React.CSSProperties;
  processingStatusCellStyle: React.CSSProperties;
  customerCellStyle: React.CSSProperties;
  customerCellStyleWithoutClick: React.CSSProperties;
  customisedTypeCellStyle: React.CSSProperties;
  originalTypeCellStyle: React.CSSProperties;
  draftStatusCellStyle: React.CSSProperties;
  activeStatusCellStyle: React.CSSProperties;
  deleteRecord?: boolean;
  edit?: boolean;
  activeStatus?: boolean,
  push?: boolean;
  checkbox?: boolean;
  isCheckboxPositionedRightSide?: boolean;
  showIds?: boolean;
  handleTableActive?: any,
  editIconAlign:
  | "left"
  | "right"
  | "inherit"
  | "center"
  | "justify"
  | undefined;
  editIconSize: "medium" | "small" | undefined;
  pushButtonSize: "large" | "medium" | "small" | undefined;
  pushButtonAlign:
  | "left"
  | "right"
  | "inherit"
  | "center"
  | "justify"
  | undefined;
  styleByIndex?: StyleArray[];
  downloadIconSize: "medium" | "small" | undefined;
  imageStyle: React.CSSProperties;
  imageBorderRadius?: string | number;
  bodyCellVerticalAlign?: string;
  bodyCellPadding?: string | number;
  ignoredKeys?: string[];
  readDatas?: Datas[];
};

export default function TableComponent<T = Data>(props: TableComponentPropType<T>){
  const {
    titles,
    data,
    pagination,
    tableContainerClassname,
    handleDeleteClick,
    deleteRecord,
    handleEditClick,
    handleClick,
    handleReademore,
    readMoreType,
    readMoreId,
    edit,
    activeStatus,
    editIconAlign,
    bodyCellStyle: _bodyCellStyle,
    readMoreBtn,
    headCellStyle,
    editIconSize,
    activatedCellStyleTrue,
    activatedCellStyleFalse,
    styleByIndex,
    permissionCellStyle,
    descriptionContainerStyle,
    push,
    pushButtonAlign,
    pushButtonSize,
    pushButtonStyle,
    handlePushClick,
    placedStatusCellStyle,
    deliveredStatusCellStyle,
    refundedStatusCellStyle,
    cancelledStatusCellStyle,
    processingStatusCellStyle,
    customerCellStyle,
    customerCellStyleWithoutClick,
    customisedTypeCellStyle,
    originalTypeCellStyle,
    draftStatusCellStyle,
    activeStatusCellStyle,
    downloadIconSize,
    handleDownloadClick,
    handleCustomerOnClick,
    checkbox,
    isCheckboxPositionedRightSide,
    getCheckedItems = () => { },
    showIds,
    imageStyle: _imageStyle,
    imageBorderRadius,
    bodyCellVerticalAlign,
    bodyCellPadding,
    handleTableActive,
    ignoredKeys = [],
    readDatas = []
  } = props;

  const bodyCellStyle = {
    ..._bodyCellStyle,
    verticalAlign: bodyCellVerticalAlign ?? _bodyCellStyle.verticalAlign,
    padding: bodyCellPadding || _bodyCellStyle.padding
  };

  const imageStyle = {
    ..._imageStyle,
    borderRadius: imageBorderRadius ?? _imageStyle.borderRadius
  };

  const classes = useStyles();
  const [currencyType, setCurrencyType] = useState<string>("");
  const [selected, setSelected] = useState<T[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [itemID, setItemId] = useState(NaN);

  const handleReademores = (id: number, index: number) => {
    setIsOpen(!isOpen)
    setItemId(id)

    let newTruncated = readDatas;
    newTruncated[index]["truncated"] = isOpen;
  }

  const checkTruncation = (index: number, element: HTMLElement) => {
    if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
      const newTruncated = readDatas;
      
      newTruncated[index]["truncatable"] = true;
      newTruncated[index]["truncated"] = true;
    } else {
      const newTruncated = readDatas;
      newTruncated[index]["truncatable"] = false;
      newTruncated[index]["truncated"] = false;
    }
  };

  useEffect(() => {
    props.getCheckedItems?.([]);
    setSelected([]);
  }, [data])

  useEffect(()=>{
    getCachedCurrencyType();
    if( window.location.pathname === "/admin/AdminStaticPage"){
      data.map((_, index) => {
        const element = document.getElementById(`text-${index}`);
        if(element){
          checkTruncation(index, element)
        }
      })
    }
  }, [])

  const getCachedCurrencyType = async () => {
    setCurrencyType(await getStorageData("currency_type"));
  }
  const onSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newSelecteds = data.map((item) => item);
      setSelected(newSelecteds);
      getCheckedItems(newSelecteds);
      return;
    }
    getCheckedItems([]);
    setSelected([]);
  };
  const onSelectItemClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: T
  ) => {
    const selectedIndex = selected.indexOf(item);
    let newSelected: (T)[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    getCheckedItems(newSelected);
    setSelected(newSelected);
  };
  const isSelected = (item: T) => selected.indexOf(item) !== -1;
  const pad = (n: number) => {
    return n < 10 ? "0" + n : n;
  };
  const renderDataCells = (item: any, key: any, index: number) => {
    switch (key) {
      case "id":
        return pad(item[key]).toString();
      case "activate":
        switch (item[key]) {
          case true:
            return (
              <FormControl fullWidth>
                <Select
                  defaultValue={item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={item.activate ? activatedCellStyleTrue : activatedCellStyleFalse}
                  onChange={(e) => handleTableActive(e, item)}
                  value={item.activate}

                >
                  <MenuItem value={"true"}>Activated</MenuItem>
                  <MenuItem value={"false"}>Deactivated</MenuItem>
                </Select>
              </FormControl>
            );
          case false:
            return (
              <FormControl fullWidth>
                <Select
                  defaultValue={item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={!item.activate ? activatedCellStyleFalse : activatedCellStyleTrue}
                  onChange={(e) => handleTableActive(e, item)}
                  label={!item.activate ? 'DeActivated' : 'Activated'}
                  value={item.activate}

                >
                  <MenuItem value={"true"}>Activated</MenuItem>
                  <MenuItem value={"false"}>Deactivated</MenuItem>
                </Select>
              </FormControl>
            );

            return (
              <div style={cancelledStatusCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          default:
            return (
              <div
                style={
                  !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
                }
                className="borderedCell"
              >
                {!!item[key] ? "Activated" : "Deactivated"}
              </div>
            );
            break;
        }

      case "customer":
        return (
          <div
            onClick={() => handleCustomerOnClick?.(item[key]?.id)}
            style={
              !!handleCustomerOnClick
                ? customerCellStyle
                : customerCellStyleWithoutClick
            }
          >
            {item[key]?.name}
          </div>
        );
      case "description":
        return (
          <>
            <Collapse in={itemID === item.id && isOpen} collapsedSize={60}>
              <div
                className={isOpen ? "clamp2" : "clamp"}
                id={`text-${index}`}
              >
                {parser(item[key] || "")}
              </div>
            </Collapse>
            {readDatas[index]?.truncatable && (
              <div>
                <button
                  className="readBtn"
                  onClick={() => handleReademores(item.id, index)}
                  style={{ display: readDatas[index]?.truncated ? "block" : "none" }}
                >
                  Read More
                </button>
                <button
                  className="readBtn"
                  onClick={() => handleReademores(item.id, index)}
                  style={{ display: readDatas[index]?.truncated ? "none" : "block" }}
                >
                  Read Less
                </button>
              </div>
          )}
          </>
        );

      case "download":
        return (
          <IconButton
            className="icon-download"
            aria-label="download"
            size={downloadIconSize}
            onClick={() => handleDownloadClick?.(item[key])}
          />
        );

      case "permissions":
        return item[key]?.map((permission: string) => (
          <div style={permissionCellStyle} className="borderedCell">
            {permission}
          </div>
        ));
      case "custom":
        return item[key]?.component;
      case "bulk-upload-product":
        return (
          <div key={item[key]?.image + item[key]?.name} className="bulk-uploads__table-row__main-container">
            <img src={item[key]?.image} alt="image loading" className="bulk-uploads__table-row__image" />
            <div className="bulk-uploads__table-row__labels-box">
              <label className="bulk-uploads__table-row__top-label">{item[key]?.name}</label>
              <label className="bulk-uploads__table-row__bottom-label">
                {currencyType}{" "}
                {item[key]?.price}
              </label>
            </div>
          </div>
        );
      case "activated":
        return (
          <div
            style={
              !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
            }
            className="borderedCell"
          >
            {!!item[key]?.status || !!item[key] ? "Active" : "Inactive"}
          </div>
        );
      case "published":
        return (
          <div
            style={
              !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
            }
            className="borderedCell"
          >
            {!!item[key]?.status || !!item[key] ? "Yes" : "No"}
          </div>
        );
      case "status":
        switch (item[key]?.status || item[key]) {
          case "draft":
            return (
              <FormControl fullWidth>
                <Select
                  value={item[key]?.status || item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={draftStatusCellStyle}
                  onChange={(e) => item[key]?.onSelect(e?.target?.value, item)}
                >
                  <MenuItem value={"draft"}>Draft</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                </Select>
              </FormControl>
            );
          case "active":
            return (
              <FormControl fullWidth>
                <Select
                  value={item[key]?.status || item[key]}
                  disableUnderline={true}
                  className="statusSelect"
                  style={activeStatusCellStyle}
                  onChange={(e) => item[key]?.onSelect(e?.target?.value, item)}
                >
                  <MenuItem value={"draft"}>Draft</MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                </Select>
              </FormControl>
            );
          case "placed":
          case 'confirmed':
            case 'returned':
              case 'delivered':
                case 'refunded':
                  case 'cancelled':
                    case 'in_transit':
                      case 'processing':

            return (
              <div style={getStatusStyle(item[key]?.status || item[key])} className="borderedCell">
                {(item[key]?.status || item[key]) === 'in_transit' ? 'In Transit' : camalize(item[key]?.status || item[key])}
              </div>
            );

          default:
            return (
              <div
                style={
                  !!item[key]?.status || !!item[key] ? activatedCellStyleTrue : activatedCellStyleFalse
                }
                className="borderedCell"
              >
                {!!item[key]?.status || !!item[key] ? "Active" : "Draft"}
              </div>
            );
            break;
        }

      case "type":
        switch (item[key]) {
          case "Customised":
            return (
              <div style={customisedTypeCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          case "Original":
            return (
              <div style={originalTypeCellStyle} className="borderedCell">
                {item[key]}
              </div>
            );
          default:
            break;
        }
      case "image":
        return <img src={item[key]} alt="image loading" style={imageStyle} />;

      case "category":
        return (
          <div style={{ marginRight: "40px" }}>
            {item[key]?.name}
          </div>
        );

      case "subCategory":
        return (
          <div>
            {item[key]?.sub_categories?.[0]?.name}
          </div>
        );

      case "subCategoryProduct":
        return (
          <div>
            {item?.subCategoryProduct?.map((subCategory: any) => (
              <span>
                {subCategory?.name}
                <br />
              </span>
              ))}
          </div>
        );
      case "price":
        return (
          <div style={{ marginRight: "25px" }}>
            {currencyType || ""}{item[key]}
          </div>
        );

      case "imageAndName":
        return (
          <div style={{ display: "flex", gap: 16, paddingLeft: 24 }}>
            <img src={item[key]?.image} alt="image loading" style={imageStyle} />
            <span>{item[key]?.name}</span>
          </div>
        );
      default:
        // Limit the visual length of the description to 30 characters
        return (item[key]?.length > 30 ? item[key]?.substring(0, 30) + '...' : item[key])?.toString()
    }
  };
  const getStatusStyle = (status:string) =>{
    switch(status){
      case "placed":
        return placedStatusCellStyle;
        case 'confirmed':

            case 'delivered':
              return deliveredStatusCellStyle;
              case 'refunded':
                return refundedStatusCellStyle;
              case 'cancelled':
                return cancelledStatusCellStyle;
                  case 'processing':
                    return processingStatusCellStyle;
                  default:
                    return cancelledStatusCellStyle;
    }
  }
  const camalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div id="table-component" >
      <TableContainer component={Paper} style={tableContainerClassname}>
        <Table stickyHeader>
          <TableHead>
            <TableRow hover>
              {checkbox && !isCheckboxPositionedRightSide && (
                <TableCell padding="checkbox" style={headCellStyle}>
                  <Checkbox
                    data-testid="table-checkbox-header"
                    icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                    </svg>
                    }
                    checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                    <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    }
                    color="primary"
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={onSelectAllClick}
                  />
                </TableCell>
              )}
              {titles?.map((title: string, index: number) => (
                <TableCell
                  style={isCheckboxPositionedRightSide ?
                    {
                      ...headCellStyle,
                      color: "#3C3E49",
                      fontSize: "20px",
                      backgroundColor: "#FFFFFF",
                      padding: "0 16px 30px 0",
                    } : headCellStyle}
                  align={"left"}
                  key={index}
                >
                  <span style={{ paddingLeft: title === "Product" ? "24px" : "" }}>{title}</span>
                </TableCell>
              ))}
              {!!push && (
                <TableCell
                  style={headCellStyle}
                  align={"left"}
                  key={titles.length}
                >
                  Push
                </TableCell>
              )}
              {!!edit && (
                <TableCell
                  style={headCellStyle}
                  align={editIconAlign}
                  key={titles.length + 1}
                >
                  Edit
                </TableCell>
              )}
              {!!deleteRecord && (
                <TableCell
                  style={headCellStyle}
                  align={editIconAlign}
                  key={titles.length + 2}
                >
                  Delete
                </TableCell>
              )}
              {checkbox && isCheckboxPositionedRightSide && (
                <TableCell
                  padding="checkbox"
                  style={{
                    ...headCellStyle,
                    backgroundColor: "#FFFFFF",
                    padding: "0 0 30px 0"
                  }}
                >
                  <Checkbox
                    icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                    </svg>
                    }
                    checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                    <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    }
                    color="primary"
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={onSelectAllClick}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data &&
              data?.map((item: any, index_num: number) => {
                console.log(item, "@@@@@")
                return (
                  <TableRow key={index_num} hover>
                    {checkbox && !isCheckboxPositionedRightSide && (
                      <TableCell
                        padding="checkbox"
                      >
                        <Checkbox
                          data-testid="select-box"
                          icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                          <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                          </svg>
                          }
                          checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                          <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          }
                          checked={isSelected(item)}
                          onChange={(e) => onSelectItemClick(e, item)}
                          color="primary"
                        />
                      </TableCell>
                    )}

                    {
                      pagination && pagination != undefined &&
                      <TableCell sortDirection="desc">
                        <Typography style={{ fontSize: '12px' }}>
                          {pagination.current_page == 1 ?
                            pagination.total_count - index_num
                            :
                            (pagination.total_count - (pagination?.prev_page * pagination.per_page)) - index_num
                          }
                        </Typography>
                      </TableCell>
                    }

                    {Object.keys(item).map((key: any, index: number) => {
                      if ((ignoredKeys || []).includes(key)) {
                        return null;
                      }
                      if (!!styleByIndex) {
                        const columnArray: Array<JSX.Element> = [];
                        styleByIndex.map((styles) => {
                          if (styles.columnName === key) {
                            columnArray.push(
                              <TableCell
                                id={key + index}
                              >
                                <div style={styles.style}>
                                  {renderDataCells(item, key, index_num)}
                                </div>
                              </TableCell>
                            );
                          }
                        });
                        if (
                          styleByIndex.filter(
                            (styles) => styles.columnName === key
                          ).length === 0
                        ) {
                          if (key === "permissions") {
                            columnArray.push(
                              <TableCell
                                style={bodyCellStyle}
                              >
                                <div className="rowBodyCell">
                                  {renderDataCells(item, key, index_num)}
                                </div>
                              </TableCell>
                            );
                          } else {
                            if (!!showIds) {
                              columnArray.push(
                                <TableCell
                                  style={bodyCellStyle}
                                >
                                  <div className="rowBodyCell">
                                    {renderDataCells(item, key, index_num)}
                                  </div>
                                </TableCell>
                              );
                            } else {
                              if (key !== "id") {
                                columnArray.push(
                                  <TableCell style={bodyCellStyle} className={classes.bodyCellStyle}>
                                    {renderDataCells(item, key, index_num)}
                                  </TableCell>
                                );
                              }
                            }
                          }
                        }
                        return columnArray;
                      } else {
                        if (!!showIds) {
                          return (
                            <TableCell
                              style={bodyCellStyle}
                            >
                              {renderDataCells(item, key, index_num)}
                            </TableCell>
                          );
                        } else {
                          if (key !== "id") {
                            return (
                              <TableCell style={bodyCellStyle} className={classes.bodyCellStyle} >
                                {renderDataCells(item, key, index_num)}
                              </TableCell>
                            );
                          }
                        }
                      }
                    })}
                    {!!push && (
                      <TableCell
                        align={pushButtonAlign}
                      >
                        <Button
                          data-testid="table-push-button"
                          variant="outlined"
                          size={pushButtonSize}
                          onClick={() => handlePushClick?.(item)}
                          style={pushButtonStyle}
                        >
                          Send notification
                        </Button>
                      </TableCell>
                    )}
                    {!!edit && (
                      <TableCell
                        align={editIconAlign}
                      >
                        <IconButton
                          className="icon-pencil"
                          data-testid="editButton"
                          aria-label="edit"
                          size={editIconSize}
                          onClick={() => handleEditClick?.(item)}
                        />
                      </TableCell>
                    )}
                    {!!deleteRecord && (
                      <TableCell
                        align={editIconAlign}
                      >
                        <IconButton
                          className="icon-bin-circle"
                          aria-label="edit"
                          size={editIconSize}
                          onClick={() => handleDeleteClick?.(item)}
                        />
                      </TableCell>
                    )}
                    {checkbox && isCheckboxPositionedRightSide && (
                      <TableCell
                        className={classes.bodyCellStyle}
                        padding="checkbox"
                      >
                        <Checkbox
                          icon={<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
                          <path stroke="#D0D2DA" strokeLinejoin="round" strokeLinecap="round" d="m0.5,3.37546c0,-1.58807 1.28739,-2.87546 2.87546,-2.87546l17.25276,0c1.58812,0 2.87546,1.28739 2.87546,2.87546l0,17.25276c0,1.58812 -1.28734,2.87546 -2.87546,2.87546l-17.25276,0c-1.58807,0 -2.87546,-1.28734 -2.87546,-2.87546l0,-17.25276z" clipRule="evenodd" fillRule="evenodd"/>
                          </svg>
                          }
                          checkedIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" fill="#6200EA"/>
                          <path d="M5.6001 13.3056L8.21343 17.0144C8.41456 17.3148 8.74845 17.4996 9.10983 17.5104C9.4712 17.5212 9.81553 17.3567 10.0342 17.0688L18.4001 6.48428" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          }
                          checked={isSelected(item)}
                          onChange={(e) => onSelectItemClick(e, item)}
                          color="primary"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  tableStyle: {
    padding: 10,
    border: "1px solid red",
  },
  bodyCellStyle: {
    "&:first-child": {
      borderLeft: "2px solid #ECEDF0",
    },
    "&:last-child": {
      borderRight: "2px solid #ECEDF0",
    },
  },
  readMoreBtn:{
    textDecoration:"underline",
    color:"#595959",
    cursor: "pointer"
  }
});
TableComponent.defaultProps = {
  tableContainerClassname: {
    boxShadow: "none",
  },
  deleteRecord: false,
  edit: false,
  activeStatus: false,
  push: false,
  checkbox: false,
  isCheckboxPositionedRightSide: false,
  showIds: true,
  downloadIconSize: "small" as "small",
  editIconSize: "small" as "small",
  editIconAlign: "center" as "center",
  pushButtonSize: "small" as "small",
  pushButtonAlign: "left" as "left",
  headCellStyle: {
    color: "#676B7E",
    fontSize: "14px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "normal",
    lineHeight: "22px",
    backgroundColor: "#F8F9FA",
  },
  bodyCellStyle: {
    fontSize: "12px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
  },
  readMoreBtn:{
    textDecoration:"underline",
    color:"#595959",
    fontSize:"12px",
    fontWidth:500,
    cursor: "pointer"
  },
  activatedCellStyleTrue: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
    content: 'Activated',

  },
  activatedCellStyleFalse: {
    backgroundColor: "#FFECEC",
    borderColor: "#FFD7D7",
    color: "#E00000",
    content: 'Deactivated',
  },
  descriptionContainerStyle: {},
  permissionCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#4F31E4",
    margin: 2,
    paddingLeft: 9,
    paddingRight: 9,
    lineHeight: '16px',
    fontSize: 12,
  },
  pushButtonStyle: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: "none" as "none",
    width: "max-content",
  },
  placedStatusCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#4F31E4",
  },
  deliveredStatusCellStyle: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
  },
  refundedStatusCellStyle: {
    backgroundColor: "#FFF2E7",
    borderColor: "#FFCA99",
    color: "#653508",
  },
  cancelledStatusCellStyle: {
    backgroundColor: "#FFECEC",
    borderColor: "#FFD7D7",
    color: "#E00000",
  },
  processingStatusCellStyle: {
    backgroundColor: "#FAF7E3",
    borderColor: "#FFDF3D",
    color: "#B05F07",
  },
  customerCellStyle: {
    color: "#6200EA",
    width: "max-content",
    textDecoration: "underline",
  },
  customerCellStyleWithoutClick: {
    color: "#3C3E49",
    width: "max-content",
  },
  customisedTypeCellStyle: {
    backgroundColor: "#F7F5FF",
    borderColor: "#BCB1F5",
    color: "#6200EA",
  },
  originalTypeCellStyle: {
    backgroundColor: "#F5F5F5",
    borderColor: "#AFB2C0",
    color: "#3C3E49",
  },
  draftStatusCellStyle: {
    backgroundColor: "#FFF2E7",
    borderColor: "#FFCA99",
    color: "#653508",
  },
  activeStatusCellStyle: {
    backgroundColor: "#EBF5F0",
    borderColor: "#B3DAC7",
    color: "#008243",
  },
  imageStyle: {
    width: 37,
    height: 36,
    borderRadius: 4,
    background: "#E8E8E8",
  },
};
// Customizable Area End