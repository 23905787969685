import React from "react";
// Customizable Area Start
import ServicemanagementController, {
  Props,
} from "./ServicemanagementController.web";
import Service from "./Service.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export default class ServiceWrapper extends ServicemanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const itemId = this.props.navigation.getParam("id")
    mixpanel.track(itemId ? "webadmin_service_details_page_enter" : "webadmin_service_create", itemId ? { itemId } : undefined);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Service
        service={this.state.service}
        categoriesData={this.state.categories}
        availabilityErrors={this.state.availabilityErrors}
        validateAvailability={this.validateAvailability}
        resetAvailabilityErrors={this.resetAvailabilityErrors}
        upsertService={this.upsertService}
        isLoading={this.state.isSaving}
      />
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
