// Customizable Area Start
import React, { RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { FormikProps } from "formik";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, checkResponseError, showError} from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

interface errorsTypes{
  errors: string
}
interface QrCodeResponse {
  id: number,
  type: string,
  attributes: {
    id: number;
    code_type: string;
    url: string;
    updated_at: string;
  }
}
interface QrCodeView {
  id: number;
  code_type: string;
  url: string;
  updated_at: string;
}

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    id: string;
    changeDialogState: (newState: boolean) => void;
    isDialogOpen?: boolean;
  };

interface S {
  qrCodes: QrCodeView[];
  selectedQrCodeId: number;
  selectedCode: QrCodeView;
  isLoaded: boolean;
}

interface SS {
  id: string;
}

class QrCodeController extends BlockComponent<Props, S, SS> {
  qrCodesMessageId: string = "";
  generateQrCodeMessageId: string = "";
  editQrCodeMessageId: string = "";
  deleteQrCodeItemId: number = 0;
  deleteQrCodeMessageId: string = "";
  
  formRef: RefObject<FormikProps<QrCodeView>>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      isLoaded: false,
      qrCodes: [],
      selectedQrCodeId: 0,
      selectedCode: {
        code_type: "android",
        url: "",
        id: 0,
        updated_at: ""
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
  }

  initPage = () => {
    this.readQrCodes();
    mixpanel.track("webadmin_mobile_app_qr_code_page_enter")
  };

  readQrCodes = async () => {
    const qrCodesMessage = await generateRequestMessage(
      configJSON.qrCodesApiEndpoint,
      configJSON.getApiMethodType
    );
    this.qrCodesMessageId = qrCodesMessage.messageId;
    this.send(qrCodesMessage);
  };

  

  generateQrCode = async (data: QrCodeView) => {
    if (this.state.selectedQrCodeId) {
      mixpanel.track("webadmin_mobile_app_qr_code_update", { type: data.code_type });
      const requestMessage = await generateRequestMessage(
        `${configJSON.qrCodesApiEndpoint}/${this.state.selectedQrCodeId}`,
        configJSON.putApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      this.editQrCodeMessageId = requestMessage.messageId;
      this.send(requestMessage);
    } else {
      mixpanel.track("webadmin_mobile_app_qr_code_create", { type: data.code_type });
      const requestMessage = await generateRequestMessage(
        configJSON.qrCodesApiEndpoint,
        configJSON.postApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      this.generateQrCodeMessageId = requestMessage.messageId;
      this.send(requestMessage);
    }

    this.props.showLoader();
  };

  deleteQrCode = async (qrCodeId: number) => {
    const requestMessage = await generateRequestMessage(
      `${configJSON.qrCodesApiEndpoint}/${qrCodeId}`,
      configJSON.deleteApiMethodType
    );
    this.deleteQrCodeItemId = qrCodeId;
    this.deleteQrCodeMessageId = requestMessage.messageId;
    this.send(requestMessage);
    this.props.showLoader();
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.formRef.current && this.formRef.current.handleSubmit();
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      
      this.messageQrCodesCall(message)
      this.messageGenerateQrCodeCall(message)
      this.messageEditQrCodeMessageId(message)
      this.messageDeleteQrCodeMessageId(message)

    }
  };

  messageQrCodesCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.qrCodesMessageId === apiRequestCallId) {
      this.setState({ isLoaded: true })
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeGenerateError, responseJson);
      }
      
      const qrCodes = (responseJson?.data || []).map((item: QrCodeResponse) => ({
        id: item.attributes.id,
        code_type: item.attributes.code_type,
        url: item.attributes.url,
        updated_at: item.attributes.updated_at,
      }));

      this.setState({ qrCodes });
      this.props.changeDialogState(false);
    }
  }
  messageGenerateQrCodeCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.generateQrCodeMessageId === apiRequestCallId) {
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeGenerateError, responseJson);
      }
      
      const qrCodes = [...this.state.qrCodes, responseJson.data.attributes];
      this.setState({ qrCodes });
      this.props.changeDialogState(false);
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeCreateMsg });
    }
    
  }
  messageEditQrCodeMessageId = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.editQrCodeMessageId === apiRequestCallId) {
      if (checkResponseError(responseJson)) {
        this.props.hideLoader();
        return this.handleError(configJSON.qrCodeEditError, responseJson);
      }

      const qrCodes = [...this.state.qrCodes];
      const item: QrCodeView =
        qrCodes.find((item) => item.id === this.state.selectedQrCodeId) || {} as QrCodeView;
      
      item.code_type = responseJson.data.attributes.code_type;
      item.url = responseJson.data.attributes.url;
      item.updated_at = responseJson.data.attributes.updated_at;

      this.setState({ qrCodes  });
      this.props.changeDialogState(false);
      this.props.hideLoader();
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeUpdateMsg });
    }
  }
  messageDeleteQrCodeMessageId = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.deleteQrCodeMessageId === apiRequestCallId) {
      this.props.hideLoader();
      if (checkResponseError(responseJson)) {
        return this.handleError(configJSON.errorOnDelete, responseJson);
      }
      const qrCodes = [...this.state.qrCodes];
      const codeIndex = qrCodes.findIndex(
        (item) => item.id === this.deleteQrCodeItemId
      );
      qrCodes.splice(codeIndex, 1);
      this.setState({ qrCodes });
      this.props.showHeaderBar({ type: "success", message: configJSON.QrCodeDeleteMsg });
    }
  }

  hideDialogBox = () => {
    this.props.changeDialogState(false);
    this.setState({
      selectedQrCodeId: 0,
      selectedCode: {
        code_type: "android",
        url: "",
        id: 0,
        updated_at: ""
      }
    });
  }

  handleError = (title: string, responseJson: errorsTypes) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  hideLoader = () => {
    this.props.hideLoader();
  };
  

  showGenerateQrCodeDialog = () => {
    const InitialValues = {
      code_type: "android",
      url: "",
      id: 0,
      updated_at: ""
    };
    this.setState({
      selectedQrCodeId: 0,
      selectedCode: InitialValues
    });
    this.props.changeDialogState(true);
  };

  showEditQrCodeDialog = (qrCodeId: number) => {
    const selectedCodeData = this.state.qrCodes.find((code) => code.id === qrCodeId) as QrCodeView;
    this.setState({
      selectedQrCodeId: qrCodeId,
      selectedCode: selectedCodeData
    });
    this.props.changeDialogState(true);
  };
  
}
export default QrCodeController;
// Customizable Area End
