import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"

// Customizable Area Start
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withLoader.Web"
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { generateRequestMessage, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities"
import { setStorageData } from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config")
// Customizable Area Start
export type Props = DialogProps &
  withHeadeActionBarProps &
  withLoaderProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string
    classes: ClassesTypes
  }
// Customizable Area End

interface S {
  // Customizable Area Start
  accountDetails: AccountDetailsTypes
  errors: ErrorsTypes
  passwordErrors: PasswordErrorsTypes
  isFetched: boolean
  showPassword: boolean
  showConfirmPassword: boolean
  isSetInitialState: boolean
  showPasswordChangeModal: boolean
  passwordInput: string
  confirmPasswordInput: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string
  // Customizable Area End
}

interface AccountDetailsTypes {
  email?: string
  name?: string
  country_phone_code?: string
  phone_number?: string
  country_id?: string | number
}
interface ClassesTypes {
  textField: string;
  textFieldCountryCode: string;
  textFieldPhone: string;
}
interface ErrorsTypes {
  email: string;
  name: string;
  phone_number: string;
}
interface PasswordErrorsTypes {
  password: string;
  confirmPassword: string;
}
interface ResponceJsonTypes {
  data: {
    id: string;
    attributes: {
      country_phone_code?: string
      permissions?: Array<string>
    }
  },
  errors?: Array<string>
}
export default class AccountDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  accountDetailsDataApiCallId: string = ""
  updateAccountDetailsApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ]
    this.state = {
      showPasswordChangeModal: false,
      showPassword: false,
      showConfirmPassword: false,
      isFetched: false,
      isSetInitialState: false,
      passwordInput: "",
      confirmPasswordInput: "",
      accountDetails: {
        email: "",
        name: "",
        country_phone_code: "+91",
        phone_number: "",
        country_id: 0
      },
      errors: {
        email: "",
        name: "",
        phone_number: "",
      },
      passwordErrors: {
        password: "",
        confirmPassword: "",
      },
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

      if (apiRequestCallId === this.accountDetailsDataApiCallId) {
        this.handleAccountDetailsDataApiCallIdResponse(responseJson)
      } else if (this.updateAccountDetailsApiCallId === apiRequestCallId) {
        this.handleUpdateAccountDetailsApiCallIdResponse(responseJson)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage))
      this.props.showHeaderBar({
        type: "error",
        message: AlertBodyMessage,
      })
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(getName(MessageEnum.ActionMessageFromToasterMessage))
      if (type === "SAVECHANGES") {
        this.updateAccountDetail()
      } else if (type === "DISCARDCHANGES" ) {
        this.getAccountDetails();
      }
    }
    // Customizable Area End
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleAccountDetailsDataApiCallIdResponse = (responseJson: ResponceJsonTypes) => {
    this.props.hideLoader()
    if (responseJson?.data) {
      let accountDetails = this.convertCountryCode(responseJson?.data?.attributes)
      this.setState({ accountDetails })
    } else {
      this.props.showHeaderBar({
        message: configJSON.generalFetchErrorMessage,
        type: "error",
      })
    }
  }
  handleUpdateAccountDetailsApiCallIdResponse = async (responseJson: ResponceJsonTypes) => {
    this.props.hideLoader()
    if (responseJson && responseJson?.data?.id) {
      this.props.showHeaderBar({ type: "success" })
      responseJson.data.attributes.permissions = ["all"];
      setStorageData("adminuser", JSON.stringify(responseJson))
      let accountDetails = this.convertCountryCode(responseJson?.data?.attributes)
      this.setState({ accountDetails })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else if (responseJson?.errors) {
      if (responseJson?.errors.length) {
        return this.props.showHeaderBar({ message: responseJson.errors[0], type: "error" })
      }
      return this.props.setDialogState(true, {
        title: configJSON.generalSaveErrorMessage,
        message: responseJson?.errors.join("\n"),
        confirmBackground: "#FF1744",
        confirmHoverBackground: "rgb(240, 25, 73)",
        hideCancel: true,
      })
    }
  }

  async componentDidMount() {
    this.getAccountDetails()
  }

  async getAccountDetails() {
    this.props.showLoader()
    const getAccountDetail = await generateRequestMessage(configJSON.accountDetailsAPI, "GET")
    this.accountDetailsDataApiCallId = getAccountDetail.messageId
    runEngine.sendMessage(getAccountDetail.id, getAccountDetail)
  }

  updateAccountDetail = async () => {
    this.props.showLoader()

    const accountDetails = {
      admin_user: {
        name: this.state.accountDetails.name,
        account_attributes: {
          email: this.state.accountDetails.email,
          country_id: this.state.accountDetails.country_id,
          phone_number: this.state.accountDetails.phone_number,
        }
      },
    }
    const requestMessage = await generateRequestMessage(
      configJSON.updateAccountAPI,
      configJSON.updateAccountAPIMethodType
    )
    this.updateAccountDetailsApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(accountDetails)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  updateUserPassword = async () => {
    this.props.showLoader()
    const body = {
      admin_user: {
        account_attributes: {
          password: this.state.passwordInput,
          password_confirmation: this.state.confirmPasswordInput,
        }
      }
    }
    const requestMessage = await generateRequestMessage(
      configJSON.updateAccountAPI,
      configJSON.updateAccountAPIMethodType
    )
    this.updateAccountDetailsApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleChangeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const fieldErrors = this.state.errors
    const fieldValues = this.state.accountDetails
    let country_id = 0;
    fieldValues[fieldName as keyof typeof fieldValues] = fieldValue
    if (fieldName === "name") {
      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          name: fieldValue,
        },
      })
      fieldErrors.name = ""
      if (!fieldValue.length) {
        fieldErrors.name = configJSON.nameIsRequired
      } else if (fieldValue.length > 49) {
        fieldErrors.name = configJSON.nameMustBeMaximum50Characters
      }
      this.openToastOnChange(fieldValues, fieldErrors)
    }

    if (fieldName === "email") {
      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          email: fieldValue,
        },
      })
      fieldErrors.email = ""
      if (!fieldValue.length) {
        fieldErrors.email = configJSON.emailIsRequired
      } else if (!/^\S+@\S+\.\S+$/.test(fieldValue)) {
        fieldErrors.email = configJSON.pleaseEnterAValidEmail
      }
      this.openToastOnChange(fieldValues, fieldErrors)
    }

    if (fieldName === "phone_number") {
      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          phone_number: fieldValue,
        },
      })
      fieldErrors.phone_number = ""
      this.openToastOnChange(fieldValues, fieldErrors)
    }
    if (fieldName === "country_phone_code") {
      if (fieldValue === "+971") {
        country_id = 4
      } else if (fieldValue === "+91") {
        country_id = 1
      } else if (fieldValue === "+44") {
        country_id = 2
      } else if (fieldValue === "+1") {
        country_id = 3
      }
      this.setState({
        accountDetails: {
          ...this.state.accountDetails,
          country_phone_code: fieldValue,
          country_id: country_id
        },
      })
      fieldErrors.phone_number = ""
      this.openToastOnChange(fieldValues, fieldErrors)
    }
  }

  handleChangePassword = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name
    const fieldValue = event.target.value
    const passwordErrors = this.state.passwordErrors

    if (fieldName === "password") {
      this.setState({ passwordInput: fieldValue })
      passwordErrors.password = ""
      if (!fieldValue.length) {
        passwordErrors.password = configJSON.passwordIsRequired
      } else if (fieldValue.length < 8) {
        passwordErrors.password = configJSON.mustContain8Char1Uppercase1Digit1Symbol
      } else if (!configJSON.passwordRegex.test(fieldValue)) {
        passwordErrors.password = configJSON.mustContain8Char1Uppercase1Digit1Symbol
      }
      passwordErrors.confirmPassword =
        this.state.confirmPasswordInput !== fieldValue &&
          this.state.confirmPasswordInput
          ? configJSON.passwordsMustMatch
          : ""
      this.setState({
        passwordErrors,
      })
    }
    if (fieldName === "confirmPassword") {
      this.setState({ confirmPasswordInput: fieldValue })
      passwordErrors.confirmPassword =
        this.state.passwordInput !== fieldValue && fieldValue
          ? configJSON.passwordsMustMatch
          : ""
      this.setState({
        passwordErrors,
      })
    }
  }

  openToastOnChange = (values: AccountDetailsTypes, errors: ErrorsTypes) => {
    this.setState({
      errors,
    })
    const fieldErrorKeys = Object.keys(errors)
    const fieldsValid = fieldErrorKeys.every((item) => errors[item as keyof typeof errors] === "")

    const requiredFields = ["name", "email"]
    const fieldsFilled = requiredFields.every((item) => values[item as keyof typeof values] !== "")

    if (fieldsFilled && fieldsValid) {
      this.props.showHeaderBar({ ignoreClickAway: true })
    } else {
      this.props.hideHeaderBar()
    }
  }

  convertCountryCode = (adminUserData: AccountDetailsTypes) => {
    if (adminUserData?.country_phone_code) {
      adminUserData.country_phone_code = adminUserData.country_phone_code
        ?.toString()
        .includes("+")
        ? adminUserData.country_phone_code
        : `+${adminUserData.country_phone_code}`;
    }
    return adminUserData;
  }
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    })
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
  }

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    })
  }

  handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
  }

  setOpenPasswordChangeModel = (value: boolean) => {
    this.setState({
      showPasswordChangeModal: value,
    })
  }

  handleSubmitPasswordChange = async () => {
    const { passwordInput, confirmPasswordInput } = this.state
    if (!passwordInput.length || !confirmPasswordInput.length || passwordInput !== confirmPasswordInput) {
      return
    }

    this.updateUserPassword()
    this.setState({
      showPasswordChangeModal: false,
    })
  }
}
