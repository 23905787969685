// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { RouterProps } from "react-router"
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web"
import { clearStorageData, generateRequestMessage, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities"
import { WithStyles } from "@material-ui/core";

const configJSON = require("./config")
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export type Props = RouterProps & WithStyles &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string
    showCreateSP: () => {}
    handleUpdateSP: (id: string) => void
    handleReademore: () => void;
  }

interface S {
  showCreatePage: boolean
  spTable: Array<SpTableType>
  page: number
  per_page: number
  search: string
  checkedStaticPageList: Array<SpTableType>
  showEmptyPage: boolean;
  readType: boolean;
  readMoreId: number;
  spTable2: Array<SpTableTypes>;
}

interface SS {
  id: string
}
interface ResponseJsonType {
  data?: [
    {
      attributes: {
        id: number;
        page_type?: {
          name: string;
          id: number;
        }
        title: string;
        active: boolean;
        description: {
          content: string
        }
      }
    }
  ],
  errors?: [],
  message?: string;
}
interface SpTableType {
  id: number;
  help_center_type?: string;
  title: string;
  description: string;
  status: boolean;
}

interface SpTableTypes {
  id: number;
  help_center_type?: string;
  title: string;
  description: string;
  status: boolean;
  truncated: boolean;
  truncatable: boolean;
}

export default class StaticPageTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  getStaticPageApiCallId: string = ""
  postBulkDeleteCallId: string = ""
  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.state = {
      showCreatePage: false,
      spTable: [],
      page: 1,
      per_page: 30,
      search: "",
      checkedStaticPageList: [],
      showEmptyPage: false,
      readType: false,
      readMoreId: NaN,
      spTable2: []
    }
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ]

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getStaticPageDataApi()
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      if (apiRequestCallId !== null) {
        this.messageGetStaticPageApiCall(apiRequestCallId, responseJson)
        this.messagePostBulkDeleteCall(apiRequestCallId, responseJson)
      }

    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      )
      if (type === "DISCARDCHANGES") {
        this.getStaticPageDataApi()
      }
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  messageGetStaticPageApiCall = (apiRequestCallId: string, responseJson: ResponseJsonType) => {
    if (this.getStaticPageApiCallId === apiRequestCallId) {
      this.props.hideLoader()

      if (responseJson.data) {
        this.initStaticPageData(responseJson)
      } else {
        this.handleErrorResponse(responseJson)
      }
    }
  }

  messagePostBulkDeleteCall = (apiRequestCallId: string, responseJson: ResponseJsonType) => {
    if (this.postBulkDeleteCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && responseJson?.message) {
        this.props.showHeaderBar({ type: "success", message: configJSON.textStaticPageDeletedSuccessfully })
        this.getStaticPageDataApi()
        this.setState({ checkedStaticPageList: [] })
      }
      if (responseJson?.errors) {
        return this.props.showHeaderBar({
          message: configJSON.generalErrorMessage,
          type: "error",
        })
      }
    }
  }

  handleBulkDeleteStaticpageAPI = async () => {
    const { checkedStaticPageList } = this.state
    let onlyIDs = checkedStaticPageList?.map((element: SpTableType) => +element.id)
    if (!onlyIDs?.length) {
      return;
    }
    await this.postBulkDelete(onlyIDs)
  }

  createStaticPageHandler = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminStaticPageCreate");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  }

  handleAddSPClick = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminStaticPageCreate");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  }
  handleUpdateSPClick = (item: SpTableType) => {
    if (item.help_center_type === "FAQs") {
      this.props.navigation.navigate("AdminStaticPageCreate", { id: item.help_center_type + "-" + item.id });
    } else {
      this.props.navigation.navigate("AdminStaticPageCreate", { id: item.id });
    }
  }


  handleErrorResponse = (responseJson: ResponseJsonType) => {
    if (!responseJson || !responseJson.errors) {
      return
    }
    const errors = responseJson.errors

    let allerrors = ""
    errors.forEach((object: string) => {
      if (allerrors.length <= 0) {
        allerrors = object
      } else {
        allerrors = `${allerrors}\n${object}`
      }
    })

    this.props.showHeaderBar({
      message: allerrors,
      type: "error",
    })
  }
  initStaticPageData = (response: ResponseJsonType) => {

    if (response.data && response.data.length > 0) {
      const transfer = response.data.map((item) => {
        return {
          id: item.attributes.id,
          help_center_type: item.attributes.page_type?.name,
          title: item.attributes.title,
          description: item.attributes.page_type?.id === 2 ? "" : item.attributes?.description?.content,
          status: item.attributes.active
        }
      })

      const transfer2 = response.data.map((item) => {
        return {
          id: item.attributes.id,
          help_center_type: item.attributes.page_type?.name,
          title: item.attributes.title,
          description: item.attributes.page_type?.id === 2 ? "" : item.attributes?.description?.content,
          status: item.attributes.active,
          truncated: false,
          truncatable: false
        }
      })

      this.setState({ spTable: transfer, showEmptyPage: false, spTable2 : transfer2 })
    } else {
      this.setState({ spTable: [], showEmptyPage: true, })
    }
  }

  getStaticPageDataApi = async (searchValue: string = "") => {
    mixpanel.track("webadmin_static_page_enter");
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      `${configJSON.staticPageDataApiURL}?search=${searchValue}&page=${this.state.page}&per_page=${this.state.per_page}`,
      configJSON.staticPageDataApiMethodType
    )
    this.getStaticPageApiCallId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  postBulkDelete = async (data: Array<number>) => {
    mixpanel.track("webadmin_static_page_delete");
    this.props.showLoader()
    let ids = ""
    data.forEach(element => {
      ids += `${element},`
    });
    const requestMessage = await generateRequestMessage(
      `${configJSON.staticPageBulkDeleteApiURL}/${ids}`,
      configJSON.staticPageBulkDeleteApiMethodType
    )
    this.postBulkDeleteCallId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handlerSearchCallback = (value: string) => {
    this.getStaticPageDataApi(value)
    this.setState({ search: value })
  }
  checkedItems = (item: Array<SpTableType>) => {
    this.setState({ checkedStaticPageList: item });
  }
  handleDeleteModal = () => {
    this.props.setDialogState(true, {
      title: configJSON.areYouSure,
      message: configJSON.areYouSureYouWantToDeleteThisStaticPages,
      confirmColor: "white",
      confirmBackground: "#FF1744",
      confirmHoverBackground: "rgb(242, 98, 129)",
      onSubmit: () => this.handleBulkDeleteStaticpageAPI(),
      okay: configJSON.textConfirmDelete
    })
  }

  handleReademore = (itemId: number) => {
    this.setState({ readType: !this.state.readType, readMoreId: itemId });
  };
}
// Customizable Area End