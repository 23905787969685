// Customizable Area Start
import React, { ChangeEvent, Suspense, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Box,
    TextField,
    Button,
    Typography,
    CardMedia,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
} from "@material-ui/core";
import Portal from "../../../components/src/Portal";
import { Link } from "react-router-dom";
import { ArrowBack as ArrowBackIcon, Add as AddIcon } from "@material-ui/icons";
import { useStyles } from "./styles/addStaff.web";
import { Availability } from "../../utilities/src/models/Availability";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
import Checkbox from '@material-ui/core/Checkbox';
import StaffAvailability from "../src/staffAvailiblity.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import moment from "moment";
import { configJSON } from "./AccountGroupsController";


const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required."),
    email: Yup.string().required("Email is required.").email("Invalid email address."),
    fullPhoneNumber: Yup.number().nullable().min(1000000, "Phone number is invalid").max(9999999999999999999, "Phone number is invalid"),
    Designation: Yup.string().required("Designation is required."),
    description: Yup.string()
        .required("Description is required.")
        .max(500, "Description must be at most 500 characters."),
    selectedServices: Yup.array().required("Service is required."),
});

interface Props {
    isLoading: boolean;
    instance: any;
}

const Service: React.FC<Props> = ({
    isLoading,
    instance,
}) => {
    const imageUploadInputRef = useRef<HTMLInputElement>(null);
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const resetButtonRef = useRef<HTMLButtonElement>(null);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isAvailiblityModalOpen, setIsAvailiblityModalOpen] = useState(false);
    const [selectedServiceData, setSelectedServiceData] = useState<any[]>([]);
    const [deletedImagesIds, setDeletedImagesIds] = useState<number[]>([]);
    const [isEmptyService, setIsEmptyService] = useState(false);
    const [firstName, SetFirstName] = useState("");
    const [removeImageIds, SetRemoveImageIds] = useState<any[]>([]);
    const [defaultHours, SetDefaultHours] = useState<any[]>([]);

    const [initialValues, setInitialValues] = useState<any>({
        title: "",
        description: "",
        fullPhoneNumber: '',
        images: [],
        duration: "",
        price: "",
        hasDiscount: false,
        discount: "",
        paymentPreference: "",
        availability: [
            {
                day: "Monday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Tuesday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Wednesday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Thursday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Friday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Saturday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
            {
                day: "Sunday",
                workingHours: [
                    {
                        openingTime: "",
                        closingTime: "",
                    },
                ],
                selected: false,
            },
        ],
        status: false,
        category: "",
    });

    const changeStateForDropdown = () => {
        if (isModalOpened) {
            setIsModalOpened(false)
            // setSelectedServiceData([])
        }
        else {
            setIsModalOpened(true)
        }
    };

    const handleSaveBtn = () => {
        setValues({ ...values, selectedServices: selectedServiceData || "" })
        setIsModalOpened(false)
        validateService()
        console.log(selectedServiceData, "selectedServiceData")

    }

    const validateService = () => {
        if (selectedServiceData.length > 0) {
            setIsEmptyService(false)
        }
        else {
            setIsEmptyService(true)
        }
    }

    const handleCheckboxValue = (id: any, data: any) => {
        const updatedData = selectedServiceData.filter((item: any) => item.id != id);

        if (updatedData.length < selectedServiceData.length) {
            setSelectedServiceData(updatedData);
        } else {
            setSelectedServiceData([...selectedServiceData, data]);
        }

        // validateService()
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (instance.state.editData?.id) {
                instance.editStaff(values, instance.state.editData.id, removeImageIds)
                mixpanel.track(configJSON.staffEdited,{staff_id: instance.state.editData.id});
            } else {
                instance.addStaff(values)
                mixpanel.track(configJSON.newStaffCreated);
            }
        },
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        getFieldProps,
        setValues,
        setErrors,
        setTouched,
        initialErrors,
        initialTouched,
    } = formik;

    const classes = useStyles({ hasImages: values.images.length > 0 });
    const [tempAvailability, setTempAvailability] = useState<Availability[]>([]);

    useEffect(() => {
        
        setInitialValues({
            fullName: instance.state?.editData ? instance.state.editData.attributes.full_name : "",
            email: instance.state?.editData ? instance.state.editData.attributes.email : "",
            fullPhoneNumber: instance.state?.editData ? instance.state.editData.attributes.phone_number : "",
            Designation: instance.state?.editData ? instance.state.editData.attributes.designation : "",
            description: instance.state?.editData ? instance.state.editData.attributes.description : "",
            images: instance.state?.editData ? instance.state.editData.attributes.images : [],
            availability: [
                {
                    day: "Monday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Tuesday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Wednesday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Thursday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Friday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Saturday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
                {
                    day: "Sunday",
                    workingHours: [
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ],
                    selected: false,
                },
            ],
            selectedServices: instance.state?.editData ? instance.state.editData.attributes.services : [],
        });
       
    }, []);

    useEffect(() => {
       
        if (tempAvailability.length > 0) {
            setValues({ ...values, availability: tempAvailability });
            setTimeout(()=>{
                closeModal();
            },1000)
        }
        
    }, [tempAvailability]);

    useEffect(()=>{
        if(instance.state?.radioSelection == 'default'){
            if(instance.state?.defaultAppointmentHours?.length){
            const transformedData = instance?.state?.defaultAppointmentHours?.map((item:any) => ({
                day: item.week_day,
                workingHours: [
                  {
                    openingTime: item?.start_time ? dateFormatinStaff(item.start_time) : "",
                    closingTime: item?.end_time ? dateFormatinStaff(item.end_time) : "",
                  },
                ],
                selected: item.selected,
              }));
            SetDefaultHours(transformedData);
            }
        }
    },[instance.state.defaultAppointmentHours])

    useEffect(() => {
        if (instance.state.editData) {
            const originalData = instance.state?.editData?.attributes.availabilities;
            const convertedData = originalData.map((availability:any) => {
                const { week_day, working_hours, selected } = availability;
                const workingHours = working_hours.map((hours:any) => {
                  const { start_time, end_time } = hours;
                  return {
                    openingTime: start_time ? moment(new Date(start_time)).utc().format("hh:mm A") : "",
                    closingTime: end_time ? moment(new Date(end_time)).utc().format("hh:mm A") : "",
                  };
                });
              
                return {
                  day: week_day,
                  workingHours,
                  selected,
                };
              });
            setValues({
                ...values, fullName: instance.state.editData.attributes.full_name,
                email: instance.state.editData.attributes.email,
                Designation: instance.state.editData.attributes.designation,
                description: instance.state.editData.attributes.description,
                fullPhoneNumber: instance.state.editData.attributes.phone_number,
                images: instance.state.editData.attributes.images,
                selectedServices: instance.state.editData.attributes.services,
                availability: convertedData
            });
            setSelectedServiceData(instance.state.editData.attributes.services)
            setInitialValues({
                fullName: instance.state?.editData ? instance.state.editData.attributes.full_name : "",
                email: instance.state?.editData ? instance.state.editData.attributes.email : "",
                fullPhoneNumber: instance.state?.editData ? instance.state.editData.attributes.phone_number : "",
                Designation: instance.state?.editData ? instance.state.editData.attributes.designation : "",
                description: instance.state?.editData ? instance.state.editData.attributes.description : "",
                images: instance.state?.editData ? instance.state.editData.attributes.images : [],
                availability:  instance.state?.editData || !instance.state?.editData?.attributes?.custom_hours ? convertedData  : [
                    {
                        day: "Monday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Tuesday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Wednesday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Thursday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Friday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Saturday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                    {
                        day: "Sunday",
                        workingHours: [
                            {
                                openingTime: "",
                                closingTime: "",
                            },
                        ],
                        selected: false,
                    },
                ],
                selectedServices: instance.state?.editData ? instance.state.editData.attributes.services : [],
            });
            const [firstName, lastName] = instance.state.editData.attributes.full_name.split(' ');
            SetFirstName(firstName)
        }
    }, [instance.state.editData]);

    useEffect(() => {
        if(instance.state.selectedCustomHours !== instance.state.radioSelection) {
            instance.setState({ radioSelection : instance.state.selectedCustomHours })
        }
    },[isAvailiblityModalOpen])

    const dateFormatinStaff = (date: any) => {
        const parsedDate = moment(date);
        const formattedTime = parsedDate.utc().format('hh:mm A');
        return formattedTime;
    }


    const clearFileInput = () => {
        if (imageUploadInputRef.current) {
            imageUploadInputRef.current.value = "";
        }
    };

    const addImage = () => {
        imageUploadInputRef.current && imageUploadInputRef.current.click();
    };

    const removeImage = (imageIndex: number, id: any) => {
        SetRemoveImageIds([...removeImageIds, id])
        const isImage = (obj: any): obj is ServiceImage => {
            return "id" in obj && "url" in obj;
        };

        const newImages = (values.images as (ServiceImage | File)[]).filter(
            (image, index) => {
                const deletedImage = index === imageIndex;
                if (deletedImage) {
                    isImage(image) &&
                        setDeletedImagesIds((currentIds) => [
                            ...currentIds,
                            (image as ServiceImage).id,
                        ]);
                    return false;
                }
                return true;
            }
        );
        setTouched({ ...touched, images: true });
        setValues({ ...values, images: newImages }, true);
    };

    const checkImage = (event: ChangeEvent<HTMLInputElement>) => {
        const sizeLimit = 20971520;
        const fileList: FileList | null = event.currentTarget.files;

        const newImages: File[] = [...(values.images as File[])];
        if (fileList) {
            setTouched({ ...touched, images: true }, false);
            for (let index = 0; index < fileList.length; index++) {
                const file: File = fileList[index];
                if ((!file.type.startsWith("image/jpeg")) && (!file.type.startsWith("image/png")) && (!file.type.startsWith("image/jpg")) && (!file.type.startsWith("image/gif"))) {
                    setErrors({ ...errors, images: "Image should be a JPEG, PNG, GIF, or JPG type" });
                    clearFileInput();
                    return;
                }
                if (file.size > sizeLimit) {
                    setErrors({ ...errors, images: "Image size should not be greater than 20MB" });
                    clearFileInput();
                    return;
                }
                newImages.push(fileList[index]);
            }
        }
        setValues({ ...values, images: newImages }, true);
        clearFileInput();
    };


    const shouldRenderAvailabilities = values.availability.some(
        (data: Availability) => data.selected
    );

    const shouldRenderDafaultAvailabilities =  defaultHours?.some(
        (data: Availability) => data.selected
    );

    const openModal = () => setIsAvailiblityModalOpen(true);

    const closeModal = () => {
        setIsAvailiblityModalOpen(false);
        setTempAvailability([]);
    };
    const availabilityModal = () =>{
        setIsAvailiblityModalOpen(false)
    }

    const checkAvailabilityForm = (currentAvailability: Availability[]) => {
        instance.setState({ selectedCustomHours : instance.state?.radioSelection })
        if(instance.state?.radioSelection == 'default'){
            if(instance.state?.defaultAppointmentHours?.length){
            const transformedData = instance?.state?.defaultAppointmentHours?.map((item:any) => ({
                day: item.week_day,
                workingHours: [
                  {
                    openingTime: item?.start_time ? dateFormatinStaff(item.start_time) : "",
                    closingTime: item?.end_time ? dateFormatinStaff(item.end_time): "",
                  },
                ],
                selected: item.selected,
              }));
            setValues({ ...values, availability: transformedData });
            SetDefaultHours(transformedData);

            setTimeout(()=>{
                closeModal();
            },1000)
            }
        }
        else{
        setValues({ ...values, availability: currentAvailability });
        SetDefaultHours(currentAvailability);
        setTempAvailability(currentAvailability);
        }

    };

    const isFormChanged =
        JSON.stringify(values) !== JSON.stringify(initialValues);
    console.log(JSON.stringify(values), "JSON.stringify(values)")
    console.log(JSON.stringify(initialValues), "JSON.stringify(initialValues)")

    const discardChanges = () => {
        resetButtonRef.current && resetButtonRef.current.click();
        setSelectedServiceData([])
        setIsEmptyService(false)
        if (instance.state.editData?.id) {
            instance.getEditData(instance.state.editData.id)
        }
    };

    const saveChanges = () => {
        if(!shouldRenderAvailabilities){
            setValues({
                ...values,
                availability: defaultHours,
            });
        }
        submitButtonRef.current && submitButtonRef.current.click();
        validateService()
    };

    const reset = () => {
        setDeletedImagesIds([]);
        setValues({
            ...initialValues,
            images: [...initialValues.images],
            availability: [...initialValues.availability],
        });
        setErrors({ ...initialErrors });
        setTouched({ ...initialTouched });
    };

    return (
        <>
            <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit>
                <Box className={classes.formActionsWrapper}>
                    <Box className={classes.formActions}>
                        <CardMedia
                            component="img"
                            src={require("../assets/Builder Studio Store.png")}
                            className={classes.logo}
                        />

                        <Box>
                            <Button
                                variant="text"
                                className={classes.discardChangesbutton}
                                onClick={discardChanges}
                            >
                                Discard changes
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.saveChangesbutton}
                                onClick={saveChanges}
                            >
                                {isLoading && <CircularProgress size={25} style={{ marginRight: 10 }} />}
                                Save changes
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>

            <Box className={classes.container}>
                <Link to="/AdminStaff" replace className={classes.link}>
                    <Button
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                        className={classes.backButton}
                    >
                        <Typography>Back</Typography>
                    </Button>
                </Link>

                <Typography className={classes.title}>
                    {instance.state.editData?.id ? "Edit staff" : "Add staff"}
                </Typography>

                <form onSubmit={handleSubmit} className={classes.wrapper} >
                    <Box className={classes.leftSideWrapper}>
                        <Box className={classes.card}>
                            <Typography className={classes.cardTitle} style={{ color: '#3C3E49' }}>
                                Staff details
                            </Typography>

                            <Box className={classes.inputsWrapper}>
                                <Box style={{ width: '65%' }}>
                                    <Typography className={classes.inputlabel}>
                                        Full name *
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Name"
                                        fullWidth
                                        {...getFieldProps("fullName")}
                                        error={Boolean(
                                            touched && touched.fullName && errors && errors.fullName
                                        )}
                                        helperText={
                                            touched && touched.fullName && errors && errors.fullName
                                        }
                                    />
                                </Box>
                                <Box style={{ width: '65%' }}>
                                    <Typography className={classes.inputlabel}>
                                        Email *
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Email"
                                        fullWidth
                                        {...getFieldProps("email")}
                                        error={Boolean(
                                            touched && touched.email && errors && errors.email
                                        )}
                                        helperText={
                                            touched && touched.email && errors && errors.email
                                        }
                                    />
                                </Box>

                                <Box style={{ width: '65%' }}>
                                    <Typography className={classes.inputlabel}>
                                        Phone number
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        placeholder="Phone number"
                                        fullWidth
                                        {...getFieldProps("fullPhoneNumber")}
                                        error={Boolean(
                                            touched && touched.fullPhoneNumber && errors && errors.fullPhoneNumber
                                        )}
                                        helperText={
                                            touched && touched.fullPhoneNumber && errors && errors.fullPhoneNumber
                                        }
                                    />
                                </Box>
                                <Box style={{ width: '65%' }}>
                                    <Typography className={classes.inputlabel}>
                                        Role *
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Role"
                                        fullWidth
                                        {...getFieldProps("Designation")}
                                        error={Boolean(
                                            touched && touched.Designation && errors && errors.Designation
                                        )}
                                        helperText={
                                            touched && touched.Designation && errors && errors.Designation
                                        }
                                    />
                                </Box>
                                <Box >
                                    <Box className={classes.descriptionWrapper}>
                                        <Typography className={classes.inputlabel}>
                                            Description *
                                        </Typography>

                                        <Typography className={classes.descriptionCounter}>
                                            {values.description.length} / 500
                                        </Typography>
                                    </Box>

                                    <TextField
                                        variant="outlined"
                                        placeholder="Description"
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        {...getFieldProps("description")}
                                        error={Boolean(
                                            touched &&
                                            touched.description &&
                                            errors &&
                                            errors.description
                                        )}
                                        helperText={
                                            touched &&
                                            touched.description &&
                                            errors &&
                                            errors.description
                                        }
                                    />
                                </Box>

                                <Box >
                                    <Typography className={classes.inputlabel} style={{ color: '#3C3E49' }}>
                                        Image
                                    </Typography>
                                    <input
                                        data-testid="image-upload"
                                        ref={imageUploadInputRef}
                                        name="images"
                                        className={classes.hidden}
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png, image/gif"
                                        onChange={checkImage}
                                        onBlur={handleBlur}
                                        multiple
                                    />
                                    <Box className={classes.imagesWrapper}>
                                        {(values.images as (ServiceImage | File)[]).map(
                                            (image: ServiceImage | File, index: number) => (
                                                <Box key={index}>
                                                    <Suspense fallback={<CircularProgress/>}>
                                                        <ImageCard
                                                            imageClassName={classes.previewImage}
                                                            image={
                                                                (image && typeof image === 'object' && (image as ServiceImage).small_url) ||
                                                                (image instanceof File && URL.createObjectURL(image)) ||
                                                                ((image as ServiceImage).url)
                                                            }
                                                            alt="image loading"
                                                        />
                                                    </Suspense>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => removeImage(index, (image as ServiceImage).id)}
                                                        className={classes.removeButton}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            )
                                        )}
                                        <Box className={classes.imageUpload} onClick={addImage}>
                                            <AddIcon className={classes.addIcon} />
                                            <Typography className={classes.addImageText}>
                                                Add image
                                            </Typography>
                                            <Typography className={classes.fileSize}>
                                                Max 20MB
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {touched && touched.images && errors && errors.images && (
                                        <Typography className={classes.validationError}>
                                            {errors.images}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.card}>
                            <Box
                                className={`${classes.cardTitle} ${classes.availabilityHeader}`}
                            >
                                <Box>
                                    <Typography className={classes.availabilityTitle}>
                                    Staff availability
                                    </Typography>

                                    <Typography className={classes.availabilityText}>
                                    See the days, times this staff member is available to take appointments.
                                    </Typography>
                                </Box>

                                <Button
                                    className={classes.editAvailablityButton}
                                    onClick={openModal}
                                    disabled={values.fullName == ""}
                                >
                                    Edit availability
                                </Button>
                            </Box>

                            {shouldRenderAvailabilities || instance.state.editData?.id ? (
                                <TableContainer>
                                    {shouldRenderAvailabilities && <Table className={classes.tableCells}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography className={classes.tableHeaderText}>
                                                        Day
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography className={classes.tableHeaderText}>
                                                        Time
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {values.availability.map(
                                                (availability: Availability, index: number) =>
                                                    availability.selected && (
                                                        <TableRow
                                                            key={index}
                                                            className={classes.tableBodyRowCells}
                                                        >
                                                            <TableCell>
                                                                <Typography className={classes.tableCellText}>
                                                                    {availability.day}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell>
                                                                <Box className={classes.workingHoursWrapper}>
                                                                    <Box className={classes.workingHours}>
                                                                        {availability.workingHours.map(
                                                                            ({ openingTime, closingTime }, index) => (
                                                                                <Typography
                                                                                    key={index}
                                                                                    className={classes.tableCellText}
                                                                                >
                                                                                    {openingTime} - {closingTime}
                                                                                </Typography>
                                                                            )
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                            )}
                                        </TableBody>
                                    </Table>}
                                </TableContainer>
                            ) :  <TableContainer>
                            {shouldRenderDafaultAvailabilities && <Table className={classes.tableCells}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography className={classes.tableHeaderText}>
                                                Day
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography className={classes.tableHeaderText}>
                                                Time
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {defaultHours.map(
                                        (availability: Availability, index: number) =>
                                            availability.selected && (
                                                <TableRow
                                                    key={index}
                                                    className={classes.tableBodyRowCells}
                                                >
                                                    <TableCell>
                                                        <Typography className={classes.tableCellText}>
                                                            {availability.day}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Box className={classes.workingHoursWrapper}>
                                                            <Box className={classes.workingHours}>
                                                                {availability.workingHours.map(
                                                                    ({ openingTime, closingTime }, index) => (
                                                                        <Typography
                                                                            key={index}
                                                                            className={classes.tableCellText}
                                                                        >
                                                                            {openingTime} - {closingTime}
                                                                        </Typography>
                                                                    )
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                    )}
                                </TableBody>
                            </Table>}
                        </TableContainer>}

                            {touched &&
                                touched.availability &&
                                errors &&
                                errors.availability && (
                                    <Typography className={classes.validationError}>
                                        {errors.availability}
                                    </Typography>
                                )}
                        </Box>
                    </Box>

                    <Box className={classes.RightSideWrapper}>


                        <Box className={classes.card}>
                            <Typography className={classes.cardTitle} style={{ color: '#3C3E49' }}>{firstName ? firstName + "'s Services" : "Select Services"}</Typography>

                            <Typography className={classes.inputlabel} style={{ color: '#3C3E49' }}>Select Service*</Typography>

                            <Box>
                                <Box className={classes.customMultiSelect} onClick={() => changeStateForDropdown()}>
                                    <span>Services</span>
                                    <span>
                                        <img
                                            src={require("../assets/Caret down.png")}
                                            alt="image loading" 
                                        />
                                    </span>
                                </Box>
                                {isModalOpened ?
                                    <Box className={classes.customMultiSelectDropdown}>
                                        <Box className={classes.customMultiSelectContent}>
                                            {instance.state.services.map((service: any, index: number) => {
                                                const isIdInArray = selectedServiceData.some((item: any) => item.id == service.id);
                                                let a = false;
                                                if (isIdInArray) {
                                                    a = true;
                                                } else {
                                                    a = false;
                                                }
                                                return (
                                                    <Box key={index}>
                                                        <Checkbox
                                                            checked={a}
                                                            style={{ marginRight: 8, color: '#364F6B', borderRadius: '10px' }}
                                                            onChange={() => handleCheckboxValue(service.id, service)}
                                                        />
                                                        {service.attributes.title}</Box>
                                                )
                                            })}
                                        </Box>
                                        <hr style={{ margin: 0 }} />
                                        <Box className={classes.saveBtnMultiSelect} ><u style={{ cursor: 'pointer' }} onClick={() => handleSaveBtn()}>Save</u></Box>
                                    </Box>
                                    :
                                    ""}
                            </Box>
                            <span style={{ color: 'red', fontSize: '12px' }}>{isEmptyService ? "Service is required" : ""}</span>
                            <Box>
                                {
                                    selectedServiceData.length > 0 ? selectedServiceData.map((value, index) => {
                                        return (
                                            <>
                                                <div key={index} style={{ color: '#3C3E49', marginTop: '15px' }}>
                                                    {value.attributes ? value.attributes.title : value.title}
                                                </div>
                                            </>
                                        )
                                    }) : ""
                                }
                            </Box>
                        </Box>
                    </Box>

                    <Button
                        ref={resetButtonRef}
                        className={classes.hidden}
                        onClick={reset}
                    />
                    <Button
                        ref={submitButtonRef}
                        type="submit"
                        className={classes.hidden}
                    />
                </form>
            </Box>

            {(isAvailiblityModalOpen && values.fullName !="") && (
                
        <Portal>
          <StaffAvailability
            availability={values.availability}
            availabilityErrors={[]}
            checkAvailabilityForm={checkAvailabilityForm}
            closeModal={closeModal}
            availabilityModal={availabilityModal}
            instance={instance}
            fullName={values.fullName}
          />
        </Portal>
      )}
        </>
    );
};

export default Service;
// Customizable Area End