import React from "react";
// Customizable Area Start
import { Box, Grid, Card, Typography, Divider, Theme, withStyles, Button, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import OrderStatusController, {Props,configJSON} from "./OrderStatusController.web"
import Currency from "../../utilities/src/components/Currency.web";
import { CrossMark, CheckMarkCurrect } from "./assets";
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosOutlined';
// Customizable Area End

// Customizable Area Start
export const styles = (theme: Theme) => ({
  confirmationContainerBox: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 20px",
    }
  },
  textFirstSize: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  dividerStyle: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  containerBoxStyle: {
    maxWidth: "628px",
    margin: "auto"
  },
  appointmentTextStyle: {
    maxWidth: "628px",
    margin: "auto",
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Rubik",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subTextStyle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#3C3E49",
    lineHeight: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#757575"
    }
  },
  serviceTextStyle: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Rubik",
    color: "#757575",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  titleTextStyle: {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px",
    paddingBottom: "5px",
  },
  servicePriceStyle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  successImage: {
    width: "82.54px",
    height: "82.54px",
    cursor: "pointer",
    top:"250px",
    marginBottom: "40px",
  },
  footerStyle: {
    borderTop: "1px solid #DFDFDF",
  },
  footerContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px"
  },
  footerTextStyle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionFirstBox: {
    borderTop: "1px solid #DFDFDF",
    marginTop: "35px",
    marginBottom: "20px",
  },
  subHeadingStyle: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subContainersStyle: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    borderTop: "1px solid #DFDFDF",
  },
  textConatinerBox: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px"
  },
  commentStyle: {
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    }
  },
  cardContainersBox: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  imageContainerBox: {
    height: "160px",
    [theme.breakpoints.down(960)]: {
      height: "140px"
    },
    [theme.breakpoints.down(480)]: {
      height: "120px"
    },
  },
  imageTagStyle: {
    objectFit: "cover" as "cover"
  },
  proceedBtnStyle: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '& .MuiButton-label': {
      textTransform: "none"
    },
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    }
  },
  cancelBtnStyle: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '& .MuiButton-label': {
      textTransform: "none"
    },
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    },
    [theme.breakpoints.down(500)]: {
      marginTop: "0",
    }
  },
  buttonContainerStyle: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down(500)]: {
      flexDirection: "column-reverse" as 'column-reverse',
    }
  },
  noBookingAlertStyle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '36px',
    textAlign: 'center' as 'center' 
  },
  noBookingMsgStyle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center' as 'center',
    color: '#8C8C8C'
  },
  noBookingContainers: {
    padding: "150px 40px",
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      padding: "100px 20px"
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  checkAgainButton: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    }
  },
  accordionContainerBox: {
    boxShadow: 'none',
    borderRadius: '5px',
    border: '1px solid #E8E8E8',
    margin: '16px 0',
    '&:before': {
      backgroundColor: 'transparent'
    }
  },
  viewPolicyStyle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    minHeight: 'auto !important',
    '& .MuiAccordionSummary-content.Mui-expanded':{
      marginBottom: '5px',
      marginTop: '12px'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  policyContainerBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    borderBottom: '1px solid #E8E8E8',
    width: '100%',
    paddingBottom: '16px',
    marginBottom: '16px'
  },
  lastPolicyContainerBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
  },
  policyHeaderStyle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  policyMsgStyle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#3C3E49'
  },
  accordionDetailsContainerBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    maxHeight: '380px',
    overflowY: 'auto' as 'auto',
  },
  dialogContentsStyle: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400 !important" as "bold",
    width: "65%",
    margin: "auto",
  },
  confirmationDialogBox: {
    padding: "32px 10px !important",
    textAlign: "center" as "center",
  },
  dialogBtn: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: '17px'
  },
  dialogCancelTextStyle: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: '17px'
  },
  cancelledMsgTextStyle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '25px'
  }
})
// Customizable Area End

export class OrderStatus extends OrderStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    const { classes } = this.props;
    const { isValidOrder, orderData, isCanceled, isCanceledValid, isCancelModalOpen, isCancelAvailable, isRescheduleAvailable, orderAlreadyCancelled } = this.state;
    const bookingData = orderData?.attributes;
    let paymentMethod = 'pay_online';

    if (bookingData?.payment_mode === "pay_in_person" || bookingData?.payment_mode === "pay_later") {
      paymentMethod = "Pay later at location";
    } else if (bookingData?.payment_mode === "pay_online" || bookingData?.payment_mode === 'pay_now') {
      paymentMethod = "Online";
    } else {
      paymentMethod = "";
    }
    return (
      <>
        <Box className={classes.confirmationContainerBox}>
          {!isCanceled && <Box style={{ textAlign: "center" }}> 
            <Typography
              className={classes.textFirstSize}
              style={{ textTransform: "uppercase" }}
              data-test-id='manageBookingHeader'
            >
              {configJSON.manageBooking}
            </Typography>
            <Divider className={classes.dividerStyle} />
          </Box>}
          <Box className={classes.containerBoxStyle}>
            {isCanceled ? 
              <Card className={classes.noBookingContainers}>
                <img src={isCanceledValid ? CheckMarkCurrect : CrossMark} alt="icon" className={classes.successImage} />
                <Typography
                  className={classes.noBookingAlertStyle}
                  style={{width: '250px'}}
                  data-test-id='cancelHeader'
                >
                  {isCanceledValid ? configJSON.cancelSuccessfully : configJSON.notCancelMessage}
                </Typography>
                <Button
                  variant="contained"
                  className={classes.checkAgainButton}
                  onClick={() => this.handleGoToScreen(false)}
                  data-test-id='exploreMoreServicesBtn'
                  >
                    {configJSON.ExploreMoreServices}
                </Button>
              </Card> 
             : isValidOrder ? <Card className={classes.cardContainersBox}>
              <Typography
                className={classes.appointmentTextStyle}
              >
                {configJSON.appointmentSummary}
              </Typography>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subTextStyle}>{configJSON.orderId}{bookingData?.order_number}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subTextStyle}>{configJSON.orderDate}{this.momentChangeForOrderData(bookingData?.order_date)}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                <Grid item xs={4} sm={4} md={4} className={classes.imageContainerBox}>
                  <img
                    src={bookingData?.service_images.small_url ? bookingData?.service_images.small_url: bookingData?.service_images.url}
                    alt="img"
                    width="100%"
                    height= "100%"
                    className={classes.imageTagStyle}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Box>
                    <Typography className={classes.serviceTextStyle}>
                      {configJSON.service}
                    </Typography>
                    <Typography className={classes.titleTextStyle}>
                      {bookingData?.service?.title}, {bookingData?.service?.duration} {configJSON.mins}
                    </Typography>
                    {bookingData?.service_provider?.full_name && <Typography className={classes.serviceTextServiceName}>
                      {configJSON.textAppointmentWith}{bookingData?.service_provider?.full_name}
                    </Typography>}
                    <Typography className={classes.servicePriceStyle}>
                      <Currency text={`${String(bookingData?.total.toFixed(2))} `}
                      disountPrice={String(bookingData?.catalogue_price.toFixed(2))}
                      time={""}
                      hasDiscount={bookingData?.total !== bookingData?.catalogue_price} />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.sectionFirstBox}>
                <Typography className={classes.subHeadingStyle}>{configJSON.dateAndTime}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerBox}>{this.momentChangeForAppointmentDate(bookingData?.appointment_date,bookingData?.time_zone_short)}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainersStyle}>
                <Typography className={classes.subHeadingStyle}>{configJSON.personalDetails}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerBox}>{bookingData?.customer?.full_name}</Typography>
                  <Typography className={classes.textConatinerBox}>{bookingData?.customer?.email} | {bookingData?.customer?.full_phone_number}</Typography>
                  <Typography className={classes.commentStyle}>{bookingData?.customer?.comment}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainersStyle}>
                <Typography className={classes.subHeadingStyle}>{configJSON.modeOfPayment}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerBox}>{paymentMethod}</Typography>
                </Box>
              </Box>
              <Box className={classes.footerStyle}>
                <Box className={classes.footerContainerStyle}>
                  <Typography className={classes.footerTextStyle}>{configJSON.total}</Typography>
                  <Typography className={classes.footerTextStyle}>
                    <Currency
                      text={`${String(bookingData?.total.toFixed(2))}`}
                      disountPrice={""}
                      time={""}
                      hasDiscount={false} />
                  </Typography>
                </Box>
              </Box>
              {orderAlreadyCancelled &&
                <Typography className={classes.cancelledMsgTextStyle}>
                  {configJSON.cancelledMsg}
                </Typography>
                }
              {(bookingData?.cancellation_policy?.is_cancellation_policy 
                || bookingData?.reschedule_policy?.is_reschedule_policy 
                || bookingData?.refund_policy?.is_refund_policy)
                && <Accordion className={classes.accordionContainerBox}>
                <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.viewPolicyStyle}
                  onClick={this.handleMixPanelTrack}
                >
                  View Policies
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainerBox}>
                  {bookingData?.cancellation_policy?.is_cancellation_policy && <Box className={bookingData?.refund_policy?.is_refund_policy || bookingData?.reschedule_policy?.is_reschedule_policy ? classes.policyContainerBox : classes.lastPolicyContainerBox}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.cancellationPolicy}
                    </Typography>
                    <Typography className={classes.policyMsgStyle}>
                    {bookingData?.cancellation_policy?.no_of_days_cancel} {configJSON.cancellationPolicyMsg}
                    </Typography>
                    <Typography className={classes.policyMsgStyle} style={{ color: '#676B7E' }}>
                      {bookingData?.cancellation_policy?.cancel_text}
                    </Typography>
                  </Box>}
                  {bookingData?.reschedule_policy?.is_reschedule_policy && <Box className={bookingData?.refund_policy?.is_refund_policy ? classes.policyContainerBox : classes.lastPolicyContainerBox}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.reschedulePolicy}
                    </Typography>
                    <Typography className={classes.policyMsgStyle}>
                    {bookingData?.reschedule_policy?.no_of_days_reschedule} {configJSON.reschedulePolicyMsg}
                    </Typography>
                    <Typography className={classes.policyMsgStyle} style={{ color: '#676B7E' }}>
                      {bookingData?.reschedule_policy?.reschedule_text}
                    </Typography>
                  </Box>}
                  {bookingData?.refund_policy?.is_refund_policy && <Box className={classes.lastPolicyContainerBox}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.refundPolicy}
                    </Typography>
                    <Typography className={classes.policyMsgStyle} style={{ color: '#676B7E' }}>
                      {bookingData?.refund_policy?.refund_text}
                    </Typography>
                  </Box>}
                </AccordionDetails>
              </Accordion>}
              <Box className={classes.buttonContainerStyle}>
                {isCancelAvailable && <Button
                  variant="contained"
                  className={classes.cancelBtnStyle}
                  data-test-id='bookingCancelBtn'
                  onClick={this.toogleCancelModal}
                >
                  {configJSON.cancelBooking}
                </Button>}
                {isRescheduleAvailable && <Button
                  variant="contained"
                  className={classes.proceedBtnStyle}
                  data-test-id='bookingResheduleBtn'
                   onClick={this.handleToResheduleScreen}
                >
                  {configJSON.rescheduleBooking}
                </Button>}
              </Box>
            </Card>:
            <Card className={classes.noBookingContainers}>
              <img src={CrossMark} alt="icon" className={classes.successImage} />
              <Typography
              className={classes.noBookingAlertStyle}
              >
                {configJSON.noBookingAlert}
              </Typography>
              <Typography
              className={classes.noBookingMsgStyle}
              >
                {configJSON.noBookingMsg}
              </Typography>
              <Typography
              className={classes.noBookingMsgStyle}
              >
                please {configJSON.checkAgain}
              </Typography>
              <Button
                  variant="contained"
                  className={classes.checkAgainButton}
                  onClick={() => this.handleGoToScreen(true)}
                  data-test-id='checkAgainBtn'
                >
                  {configJSON.checkAgain}
                </Button>
            </Card>}
          </Box>
        </Box>
        <Dialog open={isCancelModalOpen} onClose={this.toogleCancelModal} >
          <DialogContent className={classes.confirmationDialogBox}>
            <DialogContentText data-test-id="cancelAlertHeading" className={classes.dialogContentsStyle}>
              {configJSON.cancelBookingAlert}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
            <Button data-test-id="cancelModalNoId" onClick={this.toogleCancelModal} className={classes.dialogCancelTextStyle} style={{ textTransform: "none" }}>
              {configJSON.no}
            </Button>
            <Divider orientation="vertical" flexItem style={{ margin: "0 8px" }} />
            <Button data-test-id="cancelModalYesId" onClick={this.handleCancelBookingClick} className={classes.dialogBtn} style={{ textTransform: "none" }}>
              {configJSON.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(OrderStatus);
// Customizable Area End