// Customizable Area Start
import React from "react";
const configJSON = require('./config.js')

import {
    Typography, Button, Container, Grid, Box,
    TextField, IconButton, Card, CircularProgress,
    CardMedia, FormHelperText
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { pencil, chevron, uparrow, cross, downarrow, NotFoundIcon } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import { Formik, Field } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import CurrencyInput from "./CurrencyInput.web";
import { OrderType } from "./types";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import OrderManagementDetailsController, { staffDataArray } from "./OrderManagementDetailsController";
import Currency from "../../utilities/src/components/Currency.web";
// Customizable Area End

export default class OrderManagementList extends OrderManagementDetailsController {
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            filters,
            orderList_Data,
            isDialogOpen,
            isLoading,
            dateError,
            totalError,
            isCsvDownloading,
            pagination,
            isDateOpen,
            isOpen,
            isTotalOpen,
            isServiceProviderOpen,
            staffData
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Dialog
                    onClose={this.handleDialogClose}
                    aria-labelledby="customized-dialog-title"
                    open={isDialogOpen}
                    fullWidth
                    PaperProps={{
                        style: {
                            borderRadius: '3px',
                            width: '309px',
                            border: '0px solid #D0D2DA',

                        },
                    }}
                >
                    <DialogTitle id="customized-dialog-title" >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{
                                flexGrow: 1,
                                fontFamily: 'Rubik',
                                fontSize: '18px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                letterSpacing: '-0.02em',
                                textAlign: 'left',
                                color: '#3C3E49',
                            }}
                                data-testid="filterTestId"
                            >
                                {configJSON.filter}
                            </span>

                            <IconButton aria-label="close" onClick={this.handleDialogClose}>
                                <img src={cross} alt="image loading"/>
                            </IconButton>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingTop: '0.5rem',
                            marginTop: '0.5rem',
                            borderBottom: '1px solid #ECEDF0',
                            paddingBottom: '14px'
                        }}>
                            <span style={webStyle.OrderTextStyle} data-testid="appoinmentDataTestID">
                                Appointment date
                            </span>
                            {isDateOpen ? (
                                <img data-testid="AppoinmentCloseArrow" src={uparrow} alt="up Arrow" onClick={() => this.setIsDateClose()} />
                            ) : (
                                <img src={downarrow} width={24} height={25} alt="pencil Icon" onClick={this.setIsDateOpen} data-testid="AppoinmentOpenArrow" />
                            )}
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ position: 'relative', padding: '0' }}>
                        <Formik
                            initialValues={filters || {
                                from_date: '',
                                to_date: '',
                                checkboxes: {
                                    'Confirmed': false, 'Completed': false, 'InProgress': false, 'Cancelled': false, 'Refunded': false, 'placed': false, 'Rescheduled': false, 'ProcessRefund': false, 'RefundRequested': false, 'PaymentFailed': false, 'PaymentPending': false
                                },
                                integerField1: '',
                                integerField2: '',
                                checkboxesServiceProvider: [],
                            }}
                            onSubmit={this.handleSubmitList}
                        >
                            {({ handleSubmit, handleChange, values, resetForm }) => {
                                return (
                                    <form onSubmit={handleSubmit} style={{ margin:'0' }}>
                                        <div style={{padding: '8px 24px 0'}}>
                                            {isDateOpen && (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <div>
                                                        <label style={{
                                                            fontFamily: 'Rubik',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            lineHeight: '20px',
                                                            letterSpacing: '0px',
                                                            textAlign: 'left',
                                                            color: '#000000'
                                                        }}
                                                            data-testid="fromTestId">
                                                            From
                                                        </label>
                                                        <Field
                                                            type="date"
                                                            name="from_date"
                                                            onChange={handleChange}
                                                            value={values.from_date}
                                                            style={{
                                                                marginBottom: '0.5rem',
                                                                width: '119px',
                                                                height: '48px',
                                                                top: '24px',
                                                                borderRadius: '3px',
                                                                border: '1px solid #D0D2DA',
                                                                color: "#3C3E49",
                                                                fontSize: 14
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label style={{
                                                            fontFamily: 'Rubik',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            lineHeight: '20px',
                                                            letterSpacing: '0px',
                                                            textAlign: 'left',
                                                            color: '#000000'
                                                        }}>To</label>
                                                        <Field type="date" name="to_date"
                                                            onChange={handleChange}
                                                            value={values.to_date}
                                                            style={{
                                                                marginBottom: '0.5rem',
                                                                width: '119px',
                                                                height: '48px',
                                                                top: '24px',
                                                                borderRadius: '3px',
                                                                border: '1px solid #D0D2DA',
                                                                color: "#3C3E49",
                                                                fontSize: 14

                                                            }} />
                                                    </div>
                                                </div>
                                            )}
                                            {dateError && <FormHelperText error>{dateError}</FormHelperText>}
                                            <div style={{ maxWidth: '261px', width: '100%' }}>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                                    paddingTop: '0.5rem', marginTop: '0.5rem', borderBottom: '1px solid #ECEDF0', paddingBottom: '10px'
                                                }}>
                                                    <span style={{
                                                        fontFamily: 'Rubik',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        lineHeight: '20px',
                                                        letterSpacing: '0px',
                                                        textAlign: 'left',
                                                        color: '#3C3E49',
                                                        width: '122px',
                                                        height: '23px',
                                                        top: '0.5px'
                                                    }}>
                                                        {configJSON.textOrderStatus}
                                                    </span>


                                                    {this.state.isOpen ? (
                                                        <img src={uparrow} alt="up Arrow" onClick={this.setIsOpen} />
                                                    ) : (
                                                        <img src={downarrow} width={24} height={25} alt="pencil Icon" onClick={this.setIsOpen} />
                                                    )}
                                                </div>
                                                {isOpen && (
                                                    <div>
                                                        {configJSON.backendTypeOptions.map((option: string) => (
                                                            <div key={option} style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                                                <Field
                                                                    type="checkbox"
                                                                    name={`checkboxes.${option}`}
                                                                    onChange={handleChange}
                                                                    style={webStyle.checkboxStyle}
                                                                />
                                                                <label style={webStyle.checkBoxStylelabel} htmlFor={`checkboxes.${option}`}>
                                                                    {
                                                                        option === 'InProgress' ? configJSON.textInProgress :
                                                                            option === 'placed' ? configJSON.textPlaced :
                                                                                option === 'ProcessRefund' ? configJSON.textProcessRefund :
                                                                                    option === 'RefundRequested' ? configJSON.textRefundRequested :
                                                                                        option === 'Rescheduled' ? configJSON.textRescheduled : 
                                                                                            option === 'PaymentPending' ? configJSON.textPaymentPending :
                                                                                                option === 'PaymentFailed' ? configJSON.textFailed : option
                                                                    }
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{
                                                display: 'flex', borderBottom: '1px solid #ECEDF0',
                                                justifyContent: 'space-between', paddingBottom: '10px', alignItems: 'center', paddingTop: '0.5rem', marginTop: '0.5rem'
                                            }}>
                                                <span style={{
                                                    fontFamily: 'Rubik',
                                                    fontSize: '12px',
                                                    fontWeight: 400,
                                                    lineHeight: '20px',
                                                    letterSpacing: '0px',
                                                    textAlign: 'left',
                                                    color: '#3C3E49',
                                                    width: '122px',
                                                    height: '23px',
                                                    top: '0.5px'
                                                }}>
                                                    {configJSON.textTotal}
                                                </span>
                                                <div>
                                                    {isTotalOpen ? (
                                                        <img src={uparrow} alt="up Arrow" onClick={this.setIsTotalOpen} />
                                                    ) : (
                                                        <img src={downarrow} width={24} height={25} alt="pencil Icon" onClick={this.setIsTotalOpen} />
                                                    )}
                                                </div>
                                            </div>
                                            {this.state.isTotalOpen && (
                                                <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <div>
                                                        <label style={{
                                                            fontFamily: 'Rubik',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            lineHeight: '20px',
                                                            letterSpacing: '0px',
                                                            textAlign: 'left',
                                                            color: '#000000'
                                                        }}>
                                                            {configJSON.textFrom}
                                                        </label>
                                                        <CurrencyInput
                                                            type="text"
                                                            name="integerField1"
                                                            onChange={handleChange}
                                                            value={values.integerField1}
                                                            style={webStyle.fromLabelStyle}

                                                        />
                                                    </div>
                                                    <div>
                                                        <label style={webStyle.labeltoStyle}>{configJSON.textTo}</label>
                                                        <CurrencyInput type="text" name="integerField2" onChange={handleChange}
                                                            value={values.integerField2}
                                                            style={webStyle.toValueStyle}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {totalError && <FormHelperText error>{totalError} </FormHelperText>}
                                            <div style={{ maxWidth: '261px', width: '100%' }}>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                                                    paddingTop: '0.5rem', marginTop: '0.5rem', borderBottom: '1px solid #ECEDF0', paddingBottom: '10px'
                                                }}>
                                                    <span style={{
                                                        fontFamily: 'Rubik',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        lineHeight: '20px',
                                                        letterSpacing: '0px',
                                                        textAlign: 'left',
                                                        color: '#3C3E49',
                                                        width: '122px',
                                                        height: '23px',
                                                        top: '0.5px'
                                                    }}>
                                                        {configJSON.serviceProviderText}
                                                    </span>


                                                    {isServiceProviderOpen ? (
                                                        <img src={uparrow} alt="up Arrow" onClick={this.setIsServiceProviderClose} />
                                                    ) : (
                                                        <img src={downarrow} width={24} height={25} alt="pencil Icon" onClick={this.setIsServiceProviderClose} />
                                                    )}
                                                </div>
                                                {isServiceProviderOpen && staffData && staffData.length && staffData[0].attributes.full_name ? (
                                                    <div>
                                                        {staffData?.map((option: staffDataArray, index: number) => {
                                                            return (
                                                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                                                    <label style={webStyle.checkBoxStylelabel}>
                                                                        <Field
                                                                            type="checkbox"
                                                                            name={`checkboxesServiceProvider`}
                                                                            value={option.id}
                                                                            onChange={handleChange}
                                                                            style={webStyle.checkboxStyle}
                                                                        />
                                                                        {option.attributes.full_name}
                                                                    </label>
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                ): null}
                                            </div>
                                        </div>
                                        <div style={webStyle.footerBtnSection} >
                                            <Button onClick={this.resetFilter} variant="text" color="default"
                                                style={webStyle.resetAllBtn}
                                            >
                                                {configJSON.textResetAll}
                                            </Button>
                                            <Button type="submit" variant="contained" color="primary" style={webStyle.applyBtn}
                                            >
                                                {configJSON.textApply}
                                            </Button>
                                        </div>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </DialogContent>
                </Dialog>

                {this.state.adminSearch === "" && orderList_Data.length <= 0 && !isLoading && this.state.isOrderBlank ? null : <Container maxWidth="xl" style={webStyle.mainContainer}>
                    <Grid container style={webStyle.MainContainers}>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.gridSection1}>
                            <Typography variant="h3" style={webStyle.titleFirstText} data-testid="orderTestId">
                                {configJSON.textOrders}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.gridSection2} >
                            {isCsvDownloading ? <Box width="50px"><CircularProgress size="24px" /></Box> : <Typography variant="body1" data-testid="download-csv" onClick={() => { this.download() }}
                                style={webStyle.titleCsvStyle}
                            >
                                {configJSON.textDownloadCSV}
                            </Typography>}
                        </Grid>
                    </Grid>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div style={{ height: '41px', width: '12%', textAlign: 'center', border: '1px solid #D0D2DA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div data-testid="filter_search" onClick={this.handleDialogOpen} style={{ ...webStyle.containerStyle, textAlign: "left" }}>
                                {configJSON.textFilterOrders}
                                <img src={chevron} alt="Image" style={webStyle.filtericon} />
                            </div>
                        </div>
                        <div style={webStyle.searchBarContainer}>
                            <IconButton style={webStyle.iconButtonStyle}>
                                <SearchIcon style={webStyle.searchIconStyle} />
                            </IconButton>
                            <TextField
                                style={{ ...webStyle.textFieldStyle, textAlign: "left" }}
                                placeholder={configJSON.textSearchOrders}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputRef={this.queryRef}
                                onChange={() => this.serach_orders_list(this.queryRef)}
                                data-testid="searchTestID"
                            />
                        </div>
                    </div>
                </Container>}

                {isLoading ? (
                    <Card style={webStyle.noServicesCard}>
                        <Box style={webStyle.loaderContainer}>
                            <Typography style={webStyle.loadingResultsText}>
                                {configJSON.textLoadingResults}
                            </Typography>
                            <span>
                                <CircularProgress size={24} />
                            </span>
                        </Box>
                    </Card>
                ) : (
                    <>
                        {orderList_Data != null && orderList_Data.length > 0 ?
                            <Container maxWidth="xl" style={webStyle.mainContainer}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box>
                                            {orderList_Data.length > 0 ? (
                                                <>
                                                    <TableContainer style={webStyle.tableContainer}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow style={webStyle.tablethStyle}>
                                                                    <TableCell style={webStyle.tablecellstyle} data-testid="orderNumber">{configJSON.textOrderNumber}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textOrderDate}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textAppointmentDate}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textCustomer}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textServiceProvider}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textStatus}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textTotal}</TableCell>
                                                                    <TableCell style={webStyle.tablecellstyle}>{configJSON.textEdit}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody style={webStyle.tableBorder}>
                                                                {orderList_Data.map((order: OrderType, index: number) => {
                                                                    let status = "";
                                                                    let buttonStyle = {};
                                                                    if (order.attributes.status === "in_progress") {
                                                                        status = configJSON.textInProgress;
                                                                        buttonStyle = webStyle.orderStatusInProgress;
                                                                    } else if (order.attributes.status === "completed") {
                                                                        status = configJSON.textCompleted;
                                                                        buttonStyle = webStyle.orderStatusCompleted;
                                                                    } else if (order.attributes.status === "refunded") {
                                                                        status = configJSON.textRefunded;
                                                                        buttonStyle = webStyle.orderStatusRefunded;
                                                                    } else if (order.attributes.status === "placed") {
                                                                        status = configJSON.textPlaced;
                                                                        buttonStyle = webStyle.orderStatusPlaced;
                                                                    } else if (order.attributes.status === "cancelled") {
                                                                        status = configJSON.textCancelled;
                                                                        buttonStyle = webStyle.orderStatusCancelled;
                                                                    } else if (order.attributes.status === "payment_failed") {
                                                                        status = configJSON.textFailed;
                                                                        buttonStyle = webStyle.orderStatusCancelled;
                                                                    } else if (order.attributes.status === "pending") {
                                                                        status = configJSON.textPaymentPending;
                                                                        buttonStyle = webStyle.orderStatusInProgress;
                                                                    } else if (order.attributes.status === "confirmed") {
                                                                        status = configJSON.textConfirmed;
                                                                        buttonStyle = webStyle.orderStatusConfirmed;
                                                                    } else if (order.attributes.status === "rescheduled") {
                                                                        status = configJSON.textRescheduled;
                                                                        buttonStyle = webStyle.orderStatusRescheduled;
                                                                    } else if (order.attributes.status === "process_refund") {
                                                                        status = configJSON.textProcessRefund;
                                                                        buttonStyle = webStyle.orderStatusProcessRefund;
                                                                    } else if (order.attributes.status === "refund_requested") {
                                                                        status = configJSON.textRefundRequested;
                                                                        buttonStyle = webStyle.orderStatusRefundRequested;
                                                                    } else {
                                                                        status = order.attributes.status;
                                                                        buttonStyle = webStyle.tdButtonStyle;
                                                                    }

                                                                    return (
                                                                        <TableRow key={order.id} style={webStyle.tableRowStyle}>
                                                                            <TableCell style={webStyle.tbCellTdStyle}>{order.attributes.order_number}</TableCell>
                                                                            <TableCell style={webStyle.tbCellTdStyle}>{this.handleOrder_Date(order.attributes.order_date)}</TableCell>
                                                                            <TableCell style={webStyle.tbCellTdStyle}>{this.handleAppointmen_Date(order.attributes.appointment_date, configJSON.dateFormat)}</TableCell>
                                                                            <TableCell style={webStyle.tbCellTdCust}>{order.attributes.customer.full_name}</TableCell>
                                                                            <TableCell style={webStyle.tbCellTdStyle}>{order.attributes?.service_provider?.full_name ? order.attributes.service_provider.full_name : "-"}</TableCell>
                                                                            <TableCell>
                                                                                <button style={buttonStyle}>{status}</button>
                                                                            </TableCell>
                                                                            <TableCell style={webStyle.tbCellTdTotal}>{<Currency text={order?.attributes?.total ? order.attributes.total.toFixed(2) : '0'} disountPrice={""} time={""} hasDiscount={false} />}</TableCell>
                                                                            <TableCell>
                                                                                <img
                                                                                    src={pencil}
                                                                                    alt={configJSON.textPencil}
                                                                                    onClick={() => { this.getList(order.id) }}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    data-testid="orderDetailTestID"
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    {!!pagination?.total_count && <Box marginBottom={1.5}><Pagination
                                                        count={pagination?.total_count}
                                                        onChange={this.handlePageChange}
                                                        pageSize={pagination?.per_page}
                                                        itemText={configJSON.paginationText}
                                                        pageNo={pagination?.current_page || 1}
                                                    /></Box>}
                                                </>
                                            ) : <p>{configJSON.textNoRecordsFound}</p>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container >
                            :
                            (
                                orderList_Data != null && orderList_Data.length === 0 && this.state.isOrderBlank ?
                                    <>
                                        <Card style={webStyle.noServicesCard}>
                                            <Typography style={webStyle.title}>No orders</Typography>
                                            <Typography style={webStyle.paragraph}>
                                                There are no orders to show at the moment, but don't worry they'll come in soon!
                                            </Typography>
                                        </Card>
                                    </>
                                    :
                                    (
                                        orderList_Data != null && orderList_Data.length === 0 && !this.state.isOrderBlank ?
                                            <Card style={webStyle.noOrderCard}>
                                                <div style={webStyle.noOrderCardContent}>
                                                    <CardMedia
                                                        component="img"
                                                        image={NotFoundIcon}
                                                        style={webStyle.notFoundImage}
                                                        alt={configJSON.textNoResultsFound}
                                                    />
                                                    <Typography style={webStyle.noResultsFound}>
                                                        {configJSON.textNoResultsFound}
                                                    </Typography>
                                                    <Typography style={webStyle.pleaseRefineYourSearch}>
                                                        {configJSON.textPleaseRefineSearch}
                                                    </Typography>
                                                </div>
                                            </Card>
                                            : ""
                                    )
                            )}
                    </>
                )
                }
            </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    
    mainContainer: {
        marginTop: '0px',
        backgroundColor: '#F8F9FA',

    },
    searchBarContainer: {
        width: '92%',
        height: '41px',
        border: '1px solid #D0D2DA',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0',
        paddingLeft: '10px',
        backgroundColor: "white"
    },
    tableContainer: {
        marginTop: '40px',
    },
    mainWrapper: {
        paddingBottom: 32,
        marginTop: '20px'
    },
    MainContainers: {
        justifyContent: "space-between",
        alignItems: "center",
        margin: "40px 0 24px 0"
    },
    gridSection1: {
        display: 'flex',
        alignItems: 'center'
    },
    gridSection2: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    loadingText: {
        textAlign: "center" as "center",
        marginTop: 20,
    },
    tableBorder: {
        border: '1px solid #ECEDF0'
    },
    tablecellstyle: {
        fontFamily: 'Rubik',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0em',
        textAlign: "left" as "left",
        color: '#676B7E'
    },
    tbCellTdStyle: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#3C3E49'
    },
    tbCellTdCust: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '32px',
        color: '#6200EA',
        textDecoration: 'underline',
        textTransform: 'capitalize' as 'capitalize',
    },
    tableRowStyle: {
        height: '56',
        backgroundColor: '#FFFFFF',
        width: '1156px',
        border: '2px solid #ECEDEF',
    },
    tablethStyle: {
        height: '56',
        paddingBottom: '10px',
    },
    orderStatusCompleted: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: "#008243",
        backgroundColor: "#EBF5F0",
        border: "1px solid #B3DAC7",
    },
    orderStatusRefunded: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#653508',
        backgroundColor: "#FFF2E7",
        border: "1px solid #FFCA99",
    },
    orderStatusCancelled: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#E00000',
        backgroundColor: "#FFECEC",
        border: "1px solid #FFD7D7",
    },
    orderStatusInProgress: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#4F31E4',
        backgroundColor: "#F7F5FF",
        border: "1px solid #BCB1F5",
    },
    orderStatusConfirmed: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#8E24AA',
        backgroundColor: "#F3E5F5",
        border: "1px solid #BA68C8",
    },
    orderStatusRescheduled: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FF6D00',
        backgroundColor: "#FFF3E0",
        border: "1px solid #FFD180",
    },
    orderStatusProcessRefund: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#673AB7',
        backgroundColor: "#EDE7F6",
        border: "1px solid #673AB7",
    },
    orderStatusRefundRequested: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#3FC1CB',
        backgroundColor: "#ECF9FA",
        border: "1px solid #3FC1CB",
    },
    orderStatusPlaced: {
        padding: "2px 8px",
        gap: "10px",
        borderRadius: '15px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FAE52B',
        backgroundColor: "#FFFBD7",
        border: "1px solid #F4E875",
    },
    tdButtonStyle: {
        borderRadius: '32px',
        padding: '1px 7px 1px 7px',
        width: '90px',
        height: '22px',
        Gap: '10px',
        fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#4F31E4',
        backgroundColor: '#BCB1F5',
        boxShadow: 'none',
    },
    tbCellTdTotal: {
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        color: '#3C3E49',
    },
    titleCsvStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: "center" as "center",
        color: '#3C3E49',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    titleFirstText: {
        fontFamily: 'Rubik',
        fontSize: '28px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '-0.02em',
        textAlign: "left" as "left",
        color: '#3C3E49',
    },
    searchContainer: {
        padding: '4px'
    },

    filterText: {
        border: '1px solid #ccc',
        paddingRight: '8px',
        marginRight: '8px',
        width: '128px',
        height: '41px',
    },

    searchIcon: {
        borderRight: '1px solid #ccc',
        paddingRight: '8px',
    },
    filtericon: {
        marginLeft: '8px',
        color: '#676B7E',
    },
    searchIconStyle: {
        color: '#AFB2C0',
    },
    iconButtonStyle: {
        padding: '6px'
    },
    checkboxStyle: {
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        border: '1px solid #757575',
        backgroundColor: '#FFFFFF',
        borderColor: '#757575',
        marginRight: '0.5rem',
    },
    checkBoxStylelabel: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49',
        display: "flex",
        alignItem: "center"
    },
    applyBtn: {
        width: '101px',
        height: '40px',
        padding: '10px 16px',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left' as 'left',
        background: '#3C3E49',
        backgroundColor: '#00D659',
        color: '#3C3E49',
    },
    resetAllBtn: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#3C3E49',
        textDecoration: 'underline',
        textTransform: "none" as "none"
    },
    toValueStyle: {
        marginBottom: '0.5rem',
        width: '119px',
        height: '48px',
        top: '24px',
        borderRadius: '3px',
        border: '1px solid #D0D2DA',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49',
        paddingLeft: '10px',
    },
    labeltoStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#000000'
    },
    fromLabelStyle: {
        marginBottom: '0.5rem',
        width: '119px',
        height: '48px',
        top: '24px',
        borderRadius: '3px',
        border: '1px solid #D0D2DA',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        color: '#3C3E49',
        paddingLeft: '10px',
    },
    OrderTextStyle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#3C3E49',
        width: '122px',
        height: '23px',
        top: '0.5px'
    },
    footerBtnSection: {
        marginTop: '2rem',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
    },
    textFieldStyle: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        color: '#AFB2C0',
        width: '100%',
    },
    containerStyle: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0em',
        color: '#676B7E',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    noOrderCard: {
        display: "flex",
        flexDirection: "column" as "column",
        position: "absolute" as "absolute",
        borderRadius: "8px",
        background: "#FFFFFF",
        transform: "translate(-50%, -50%)",
        textAlign: "center" as "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        top: "45%",
        left: "50%",
        alignItems: "center",
        justifyContent: "center",
        padding: 24
    },
    noOrderCardContent: {
        display: "flex",
        flexDirection: "column" as "column",
        width: "280px",
        height: "180px",
        alignItems: "center",
        justifyContent: "center",
    },
    loadingResultsText: {
        textAlign: "center" as "center",
        fontSize: "18px",
        fontWeight: 500,
        color: "#3C3E49",
        lineHeight: "24px",
        whiteSpace: "pre" as "pre",
    },
    loaderContainer: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        padding: 24,
        gap: 10
    },
    notFoundImage: {
        width: "103px",
    },
    noServicesCard: {
        alignItems: "center",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "absolute" as "absolute",
        padding: "24px",
        width: "375px",
        background: "#FFFFFF",
        flexDirection: "column" as "column",
        justifyContent: "center",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    title: {
        height: "45px",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "32px",
        letterSpacing: "-0.56px",
        color: "#3C3E49",
        textAlign: "center" as "center",
    },
    paragraph: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#676B7E",
        margin: "20px 0",
        textAlign: "center" as "center",
    },
    pleaseRefineYourSearch: {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#83889E",
        marginTop: "8px",
    },
    noResultsFound: {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#000000",
        marginTop: "24px",
    }
};

export { OrderManagementList }
// Customizable Area End

