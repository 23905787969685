// Customizable Area Start
import { FieldAttributes } from 'formik';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { getStorageData } from "../../../framework/src/Utilities";

type S = {
    currency: string;
}

type P = {

} & FieldAttributes<{}>

export default class CurrencyInputController extends BlockComponent<P, S, {}> {
    constructor(props: P){
        super(props)
        this.state = {
            currency: "₹"
        }
    }

    storageListener = async () => {
        const storeDetailsString = await getStorageData("storeDetails")
        if (storeDetailsString) {
            this.setState({
                currency: JSON.parse(storeDetailsString).currency_symbol || "₹"
            })
        }
    };

    async componentDidMount() {
        super.componentDidMount();
        window.addEventListener("store_details", this.storageListener);
        const storeDetailsString = await getStorageData("storeDetails")

        if (storeDetailsString && storeDetailsString !== "undefined") {
            this.setState({
                currency: JSON.parse(storeDetailsString).currency_symbol || "₹"
            })
        }
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("store_details", this.storageListener);
        
    }

}

// Customizable Area End