Object.defineProperty(exports, '__esModule', {
  value: true
})

exports.upsertCommonSettingsAdminApi="bx_block_settings/common_settings";
exports.upsertCommonSettingsAdminAppointmentHoursApi="bx_block_settings/common_settings/appointment_hours";
exports.upsertCommonSettingsAdminAllAppointmentHoursApi="bx_block_settings/common_settings/all_appointment_hours";

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = 'catalogue/brands'
exports.routeLogin = "EmailAccountLogin"

exports.getMethod = 'GET'
exports.putMethod = 'PUT'
exports.postMethod = 'POST'

exports.textOpenTimeRequired = "Opening time is required"
exports.textOpenTimeFormat = "Opening time must be in 12-hour format"
exports.textCloseTimeRequired = "Closing time is required"
exports.textCloseTimeFormat = "Closing time must be in 12-hour format"
exports.textTimeValidation = "Closing time should be after Opening time"
exports.textImageRequired = "Image is required"
exports.textCityRequired = "City is required."
exports.textCountryRequired = "Country is required."
exports.textUrlRequired = "Url is required."
exports.textUrlNotValid = "Needs to be a valid url."
exports.textPinRequired = "Pin Code is required."
exports.textTimezoneRequired = "Timezone is required."
exports.textAddress1Required = "Address Line 1 is required."
exports.textAddress2Required = "Address Line 2 is required."
exports.textImageFormatInvalid = "Please select a valid image format (JPEG, JPG, GIF, PNG)"
exports.textImageSizeInvalid = "Image size should not be greater than 20MB."

exports.textAppointmentHours = "Appointment Hours"
exports.textWorkingHours = "Working hours"
exports.textCancel = "Cancel"
exports.textSave = "Save"
exports.textDiscardChanges = "Discard changes"
exports.textSaveChanges = "Save changes"
exports.textCommonSettings = "Common Settings"
exports.textHintOperatingHours = "Add the operating hours for your business. These would be the default appointment timings for all services."
exports.textDay = "Day"
exports.textTime = "Time"
exports.textUrlLabel = "URL *"
exports.textImageLabel = "Image *"
exports.textAddImage = "Add image"
exports.textImageMaxSize = "Max 20MB"
exports.textAddressDetails = "Address details"
exports.textAddressLine1Label = "Address Line 1 *"
exports.textAddressLine2Label = "Address Line 2 *"
exports.textCityLabel = "City *"
exports.textStateLabel = "State"
exports.textCountryLabel = "Country *"
exports.textPincodeLabel = "Pincode *"
exports.textTimezoneLabel = "Timezone"
exports.textTimeLabel = "Time *"
exports.textHintTimezone = "You can select your timezone from here."
exports.cancellation = "Cancellation Policy";
exports.cancelTitle = "Number of days within which cancellations can be accommodated* "
exports.addCancelPolicy = "Add Cancellation policy"
exports.refundTxt = "Refund Policy";
exports.refundTitle = "Add Refund Policy *";
exports.refundDesc = "Description of refund policy"
exports.reschduleTitle = "Reschedule Policy";
exports.reschduleSelectTitle = "Number of days within which  reschedule can be accommodated* ";
exports.placeholderReschdule = "Description of reschedule policy";
exports.addReschdule = "Add Reschedule policy";
exports.cancellationPolicyUpdated = "webadmin_settings_cancellation_policy_updated";
exports.reschedulePolicyUpdated = "webadmin_settings_reschedule_policy_updated";
exports.refundPolicyUpdated = "webadmin_settings_refund_policy_updated";

exports.timeZones = [
  { name: "Indian Standard Time (IST), UTC+5:30" },
  { name: "Greenwich Mean Time (GMT), UTC+0" },
  { name: "British Summer Time (BST), UTC+1" },
  { name: "Gulf Standard Time (GST), UTC+4" },
  { name: "Eastern Time (ET), UTC-5" },
  { name: "Eastern Daylight Time (EDT), UTC-4" },
  { name: "Central Time (CT), UTC-6" },
  { name: "Central Daylight Time (CDT), UTC-5" },
  { name: "Mountain Time (MT), UTC-7" },
  { name: "Mountain Daylight Time (MDT), UTC-6" },
  { name: "Pacific Time (PT), UTC-8" },
  { name: "Pacific Daylight Time (PDT), UTC-7" },
  { name: "Alaska Time (AKT), UTC-9" },
  { name: "Alaska Daylight Time (AKDT), UTC-8" },
  { name: "aii-Aleutian Time (HAST), UTC-10" },
  { name: "Hawaii-Aleutian Standard Time (HADT), UTC-9" },
];

// exports.countNumber = ["1","2","3","4","5","6","7"]
exports.countNumber = [
  { time: "1"},
  { time: "2"},
  { time: "3"},
  { time: "4"},
  { time: "5"},
  { time: "6"},
  { time: "7"},
]


exports.allowedImageFormats = [
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/png"
]

exports.days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

// Customizable Area End