// Customizable Area Start
import * as React from "react"

import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  Icon,
  FormHelperText,
} from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Editor from "../../ss-cms-common-components/src/Editor/EditorConvertToHTML"
import { createStyles, withStyles } from "@material-ui/core";
import CustomAutoCompletePicker from "../../ss-cms-common-components/src/CustomeAutoComplete/CustomAutoCompletePicker.web"
import "../assets/createstatic.css"
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web"
import IOSSwitch from "../../ss-cms-common-components/src/IOSSwitch/IOSSwitch"
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import CreateStaticPageController from "./CreateStaticPageController.web"
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog"
import { Scrollbars } from "react-custom-scrollbars"
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import { AddIcon, DeleteIcon } from "./assets";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
const configJSON = require("./config")

export class CreateStaticPage extends CreateStaticPageController {
  renderRightSection = () => {
    const { classes} = this.props;
    return (
      <div className={classes.rightsidebar}>
        <div className={classes.rightsidebarInner}>
          <label className={classes.labelStatus}>{configJSON.status}</label>
          <div>
            {this.state.help_center_type === "FAQs" ? (
              <div className={classes.pt2}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="checkedB"
                      isDisabled={true}
                      toggleEvent={this.handleActiveChange}
                      isChecked={
                        this.state.status
                      }
                    />
                  }
                  label=""
                />
                <label>
                  {this.state.status
                    ? configJSON.published
                    : configJSON.notPublished}
                </label>
              </div>
            ) : (
              <div className={classes.pt2}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name="checkedB"
                      toggleEvent={this.handleActiveChange}
                      isChecked={
                        this.state.status
                      }
                    />
                  }
                  label=""
                />
                <label>
                  {this.state.status
                    ? configJSON.published
                    : configJSON.notPublished}
                </label>
              </div>
            )}
          </div>
          <div className={classes.pt2}>
            <label className={classes.subStatusTxt}>
              {this.state.status ? configJSON.textPageWillBeVisible : configJSON.textPageWillNotVisible}
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderLeftSection = () => {
    const { editorStateDescription } = this.state;
    const {classes} = this.props;
    return (
      <div className={classes.leftContainer}>
        <Box
          p={5}
          className={this.props.classes.faqItem + " " + this.props.classes.p40}
        >
          <div className={classes.title}>
            {configJSON.staticPage}
          </div>
          <div className={classes.pageType}>{configJSON.staticPageType} *</div>
          <CustomAutoCompletePicker
            isDisabled={this.state.pageId ? true : false}
            handleInputChangeClick={this.handlePageTypeChange}
            value={this.getFromPageNo(this.state.help_center_no)}
            isError={this.state?.helpCenterTypeError ? true : false}
            isHelperText={
              this.state?.helpCenterTypeError
                ? this.state?.helpCenterTypeError
                : ""
            }
            skills={this.state.staticType}
            dataTestId="page_type"
          />
          <div>
            <div className={classes.pageType}>{configJSON.title} *</div>

            <TextField
              id="standard-size-small"
              size="small"
              data-testid="title"
              variant="outlined"
              fullWidth
              onChange={this.onTitleChange}
              value={this.state.title}
              required
              error={this.state?.titleError ? true : false}
              helperText={
                this.state?.titleError ? this.state?.titleError : ""
              }
              defaultValue={this.state.title}
            />

            {this.state.help_center_type === "FAQs" ? null : (
              <>
                <div
                  className={this.props.classes.rowItem1}
                >
                  <div className={classes.answereLabel}>{configJSON.description} *</div>
                </div>
                <Editor
                  editorState={editorStateDescription}
                  onEditorStateChange={this.handleEditorStateChangeDescription}
                  isErrorShow={
                    this.state.editorStateDescriptionError ? true : false
                  }
                  className={classes.editorBorder}
                />
                {this.state.editorStateDescriptionError ? (
                  <FormHelperText classes={{ root: "helper-text", error: "" }}>
                    {this.state.editorStateDescriptionError}
                  </FormHelperText>
                ) : null}
              </>
            )}
          </div>
        </Box>
        {this.state.help_center_type === "FAQs"
          ? this.renderLeftFAQSection()
          : null}
      </div>
    )
  }

  renderLeftFAQSection = () => {
    const { faqDataArray } = this.state;
    const {classes} = this.props;
    return (
      <div>
        {faqDataArray.map((item, index) => {
          if (item._destroy !== 1) {
            return (
              <div className={classes.faqBottom} key={index}>
                <Box
                  p={5}
                  className={classes.faqItem}
                >
                  <div className={classes.faqHeader}>
                    <div className={classes.faqHeaderLeft}>
                      <Typography className={classes.question}>
                        {`${configJSON.question} ${index + 1}`}
                      </Typography>

                      <IconButton
                        aria-label="delete"
                        size={"small"}
                        data-testid="deleteItem"
                        className={classes.deleteIcon}
                        onClick={() => this.removeFields(index)}
                      >
                        <img src={DeleteIcon} alt="image loading" />
                      </IconButton>
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            name="checkedB"
                            toggleEvent={(checked: boolean) => {
                              this.handleFaqStatusChanges(index, checked)
                            }}
                            isChecked={
                              item.status ? true : false
                            }
                          />
                        }
                        label=""
                      />
                      <label>
                        {item.status
                          ? configJSON.live
                          : configJSON.notLive}
                      </label>
                    </div>
                  </div>
                  <div className={classes.pageTyle}>
                    {configJSON.question} *
                  </div>

                  <TextField
                    id="standard-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={item.title}
                    name={"title"}
                    onChange={(event) => this.handleQueChange(index, event)}
                    error={item.titleError ? true : false}
                    helperText={item.titleError ? item.titleError : ""}
                  />

                  <div
                    className={this.props.classes.rowItem1}
                  >
                    <div className={classes.answereLabel}>{configJSON.answer} *</div>
                    <div className={classes.descriptionLength}>{item.contentLength}/500</div>
                  </div>
                  <Editor
                    editorState={item.content}
                    onEditorStateChange={(editor) =>
                      this.handleEditorStateChange(index, editor)
                    }
                    name={"content"}
                    isErrorShow={item.contentError ? true : false}
                    className={classes.editorBorder}
                  />
                  {item.contentError ? (
                    <FormHelperText
                      classes={{ root: classes.helperTxt, error: "" }}
                    >
                      {item.contentError}
                    </FormHelperText>
                  ) : null}

                  {this.state.faqDataArray.length - 1 == index ? (
                    <Button
                      className={classes.addButton}
                      variant="text"
                      color="primary"
                      data-testid="addNewBtn"
                      startIcon={
                        <Icon>
                          <img src={AddIcon} alt="image loading" />
                        </Icon>
                      }
                      onClick={() => this.onAddAnotherFaqItem()}
                    >
                      {configJSON.addAnotherQuestionAnswer}
                    </Button>
                  ) : null}
                </Box>
              </div>
            )
          }
        })}
      </div>
    )
  }

  render() {
    const {classes} = this.props;
    return (
      <Scrollbars>
        <div className={classes.mainContainer}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            className={classes.buttonContainer}
            onClick={this.handleBack}
            data-testid="backBtn"
          >
            <Typography>{configJSON.backButton}</Typography>
          </Button>
          <Typography className={classes.title}>
            {this.state.pageId ? configJSON.textEditStaticPage : configJSON.textCreateStaticPage}
          </Typography>

          <div className={classes.pageContainer}>
            {this.renderLeftSection()}

            {this.renderRightSection()}
          </div>
        </div>
      </Scrollbars>
    )
  }
}

export const styles = () =>
  createStyles({
    mainContainer:{
      "background": "#f8f9fa",
      "padding": "25"
    },
    pageContainer:{
      "display": "flex",
      "gap": "25px"
    },
    buttonContainer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      textTransform: "none" as "none",
      fontSize: "12px",
      lineHeight: "22px",
      color: "#676B7E",
    },
    rowItem: {
      display: "flex", alignItems: "center", gap: "16px", marginTop: "16px"
    },
    faqItem: {
      background: "#fff",
      border: "2px solid #ECEDF0",
      borderRadius: "3px"
    },
    rowItem1: {
      display: "flex",
      alignItems: "center",
      marginBottom: 8,
      marginTop: 25,
    },
    rightsidebar:{
      maxWidth: "30%",
      width: "30%"
    },
    rightsidebarInner:{
      boxSizing: "border-box",
      background: "#fff",
      border: "2px solid #ecedf0",
      borderRadius: "3px",
      padding: "40px"
    },
    labelStatus:{
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "28px",
      alignItems: "center",
      letterSpacing: "-0.02em",
      color: "#3c3e49"
    },
    pt2:{
      paddingTop:"20px"
    },
    subStatusTxt:{
    },
    leftContainer:{
      width: "70%"
    },
    title:{
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "32px",
      display: "flex",
      letterSpacing: "-0.02em",
      color: "#3c3e49",
      padding: "4px 0px 24px 0px"
    },
    pageType:{
      fontFamily: "\"Rubik\", sans-serif",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
      marginTop: "25px",
      color: "#3c3e49"
    },
    answereLabel:{
      "fontFamily": "\"Rubik\", sans-serif",
      "fontStyle": "normal",
      "fontWeight": 500,
      "fontSize": "12px",
      "lineHeight": "20px",
      "color": "#000000",
      "flex": "1"
    },
    editorBorder:{
      "border": "1px solid #d50000"
    },
    faqBottom:{
      "width": "100%",
      "marginTop": "19px",
      "marginBottom": "40px"
    },
    faqHeader:{
      "display": "flex",
      "alignItems": "center"
    },
    faqHeaderLeft:{
      "display": "flex",
      "flex": "1",
      "alignItems": "center"
    },
    faqDeleteIcon: {
      "height": "24px",
      "width": "24px"
    },
    question:{
      "fontFamily": "\"Rubik\", sans-serif",
      "fontStyle": "normal",
      "fontWeight": 500,
      "fontSize": "20px",
      "lineHeight": "28px",
      "alignItems": "center",
      "letterSpacing": "-0.02em",
      "color": "#3c3e49",
      "marginRight": "24px"
    },
    addButton:{
      "marginTop": "20",
      "fontFamily": "\"Rubik\", sans-serif",
      "fontStyle": "normal",
      "fontWeight": 400,
      "fontSize": "12px",
      "lineHeight": "20px"
    },
    descriptionLength:{
      "fontFamily": "\"Rubik\", sans-serif",
      "fontStyle": "normal",
      "fontWeight": 400,
      "fontSize": "12px",
      "lineHeight": "20px",
      "textAlign": "right",
      "color": "#000000"
    },
    helperTxt:{
      "color": "#d50000",
      "fontFamily": "\"Rubik\", sans-serif",
      "fontStyle": "normal"
    },
    p40: {
      padding: "40px"
    }
  });


export default withLayout(withStyles(styles)(withSnackBar(withLoader(withHeaderBar(withDialog(CreateStaticPage))))))
// Customizable Area End