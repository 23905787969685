// Customizable Area Start
import React from "react";
import { Box, Button, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles/DeleteModal.web";
import { Close as CloseIcon } from "@material-ui/icons";

interface Props {
  message: string;
  closeModal: () => void;
  confirmDeletion: () => void;
}

const DeleteModal: React.FC<Props> = ({
  message,
  closeModal,
  confirmDeletion,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.backdrop}>
      <Box className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography className={classes.title}>Are you sure?</Typography>

            <IconButton className={classes.iconWrapper} onClick={closeModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>

          <Typography className={classes.message}>{message}</Typography>
        </Box>

        <Box className={classes.actions}>
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={closeModal}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={confirmDeletion}
          >
            <Typography>Yes, delete</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteModal;
// Customizable Area End