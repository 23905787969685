import React from "react";
// Customizable Area Start
import { Container, Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "32px",
      color: "#3C3E49",
      marginBottom: "20px",
      fontFamily: "Rubik",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      color: "#676B7E",
      marginBottom: "20px",
      fontFamily: "Rubik",
    }
  },
  overrides: {
    MuiButton: {
      text: {
        display: 'none',
        textTransform: 'capitalize'
      }
    }
  }
});

// Customizable Area End
import PhotoLibraryController, {
  Props
} from "./PhotoLibraryController";

// Customizable Area Start
// Customizable Area End

export default class PhotoUpload extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const buttonStyle = {
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Rubik",
    };

    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyles.mainContainer}>
          <Container>
            <Box style={{...webStyles.photoUploadContainer, textAlign: "center"}}>
              <Box>
                <Typography data-test-id="heading" variant="h6">You have no gallery images</Typography>
                <Box style={webStyles.subContainer}>
                <Typography data-test-id="subheading" variant="subtitle1">
                  Start uploading your gallery images here so you can easily show
                  images on website.
                </Typography>
                </Box>
                  <Button
                    onClick={this.handleAddImage}
                    data-test-id="photoUpload"
                    variant="contained"
                    style={{ ...webStyles.uploadButton, ...buttonStyle, textTransform: "none" }}
                  >
                    Upload Image
                  </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#F8F9FA"
  },
  photoUploadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", 
    maxWidth: "684px", 
    minHeight: "396px", 
    margin: "40px auto", 
    padding: "0",
    backgroundColor: "#fff",
  },
  uploadButton: {
    backgroundColor: "#00D659",
    color: "#3C3E49",
    padding:"12px 40px",
    borderRadius: "0px",
    maxHeight: "48px",
    width: "221px",
  },
  subContainer: {
    maxWidth:"395px", 
    height:"95px", 
    display:"flex",
    alignItems:"center"
  }
};
// Customizable Area End
