
import React from "react";
// Customizable Area Start
import AdminStaffListController, { StaffDataObj } from "./AdminStaffListController.web";
import { pencil } from "./assets";
import { Link } from "react-router-dom";
import { Box,
     Checkbox,
     Table,
     TableBody,
     TableCell,
     TableContainer,
     TableHead,
     TableRow,
     Theme,
     Typography,
     createStyles,
     withStyles } from "@material-ui/core";
export const configJSON = require("./config");
import { CheckboxIcon1, CheckboxIconChecked1 } from "./assets";
// Customizable Area End

class StaffTable extends AdminStaffListController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const style = this.props.classes;
        const isRowSelected = (id: string) => this.props.selectedRows.includes(id);
        const isAllRowsSelected = this.props.selectedRows.length === this.props.staffData.length;
        let srNo = 1;
        const incrementId = ()=>{
            srNo = srNo+1
        }
        const pagination  = this.props.pagination;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box>
                    <TableContainer>
                        <Table className={style.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            className={style.checkbox}
                                            checked={isAllRowsSelected}
                                            icon={
                                                <img src={CheckboxIcon1} alt="image loading" width={20} height={20} />
                                            }
                                            checkedIcon={<img src={CheckboxIconChecked1} alt="image loading" />}
                                            onChange={this.props.allRowCheckBox}
                                        />
                                    </TableCell>

                                    <TableCell sortDirection="desc">
                                        <Typography className={style.headerText}>{configJSON.hash}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography className={style.headerText}>{configJSON.fullName}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography className={style.headerText}>{configJSON.email}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography className={style.headerText}>{configJSON.number}</Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography className={style.headerText}>{configJSON.designation}</Typography>
                                    </TableCell>

                                    <TableCell className={style.center}>
                                        <Typography className={style.headerText}>{configJSON.description}</Typography>
                                    </TableCell>

                                    <TableCell className={style.center}>
                                        <Typography className={style.headerText}>{configJSON.edit}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={style.tableBodyRow}>
                                {this.props.staffData.map((staff: StaffDataObj, index: number) => (
                                    <TableRow key={staff.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                className={style.checkbox}
                                                checked={isRowSelected(staff.id)}
                                                icon={
                                                    <img src={CheckboxIcon1} alt="CheckboxIcon" style={{width:20,height:20}} />
                                                }
                                                checkedIcon={<img src={CheckboxIconChecked1} alt="CheckIcon" />}
                                                onChange={(event) =>
                                                    this.props.rowCheckBox(event, staff.id)
                                                }
                                            />
                                        </TableCell>

                                        <TableCell sortDirection="desc">
                                            <Typography style={{fontSize:'12px'}}>
                                                {pagination.current_page == 1 ?
                                                    pagination.total_count - index
                                                    :
                                                    (pagination.total_count - (pagination.prev_page * pagination.per_page)) - index
                                                }
                                            </Typography>
                                        </TableCell>

                                        <TableCell sortDirection="desc">
                                            <Box className={style.service}>
                                                <Typography
                                                    className={`${style.servicetitle} ${style.cellText}`}
                                                >
                                                    {staff.attributes.full_name}
                                                </Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell>
                                            <Typography className={`${style.serviceEmail} ${style.cellText}`}>
                                                {staff.attributes.email || "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography
                                                className={style.cellText}
                                            >
                                                {staff.attributes.phone_number || "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography className={style.cellText}>
                                                {staff.attributes.designation || "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell className={style.center}>
                                            <Typography
                                                component="div"
                                                className={`${style.cellText} ${style.statusWrapper}`}
                                            >
                                                {staff.attributes.description ? (staff.attributes.description.length > 50 ? `${staff.attributes.description.slice(0,50)}...` : staff.attributes.description) :  "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell className={style.center}>
                                        <Link to={`/AdminStaffCreate/${staff.id}`}>
                                            <img
                                                src={pencil}
                                                style={{ width: "18px",
                                                height: "18px",
                                                borderRadius: "4px"}}
                                                alt="image loading"
                                               
                                            />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const Styles = (theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
            "& .MuiTableCell-root": {
                padding: theme.spacing(0, 2),
                height: "56px",
            },
            "& .MuiTableCell-paddingCheckbox": {
                padding: theme.spacing(0, 0, 0, 0.5),
            },
        },
        tableBodyRow: {
            "& tr": {
                border: "2px solid #ECEDF0",
            },
        },
        headerText: {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#676B7E",
        },
        checkbox: {
            color: "transparent !important",
        },
        center: {
            textAlign: "center",
        },
        service: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
        },
        servicetitle: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        cellText: {
            fontSize: "12px",
            lineHeight: "20px",
            color: "#3C3E49",
        },
        image: {
            width: "40px",
            height: "40px",
            flex: "0 0 40px",
            borderRadius: "4px",
        },
        statusWrapper: { display: "flex", justifyContent: "center" },
        pill: {
            width: "80px",
            padding: theme.spacing(0.25, 1),
            borderRadius: "32px"
        },
        draftPill: {
            border: "1px solid #FFCA99",
            backgroundColor: "#FFF2E7",
            color: "#653508"
        },
        activePill: {
            border: "1px solid #B3DAC7",
            backgroundColor: "#EBF5F0",
            color: "#008243"
        },
        serviceEmail:{
            wordBreak:"break-all"
        }
    })
export default withStyles(Styles, { withTheme: true })(StaffTable);
// Customizable Area End