// Customizable Area Start
import React from "react";
import AdminStaffListController from "./AdminStaffListController.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import {
    Box,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    InputAdornment,
    Portal,
    Slide,
    SvgIcon,
    TextField,
    Theme,
    Typography,
    createStyles,
    withStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import StaffTable from "./StaffTable.web";
import DeleteModal from "../../utilities/src/components/DeleteModal.web";
import { SuccessIcon,ErrorIcon }  from "./assets";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
export const configJSON = require("./config");

// Customizable Area End

class AdminStaffList extends AdminStaffListController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const style = this.props.classes;
        const {isLoading,
            isModalOpened, searchString, staffData, selectedRows, deleteSuccess, pagination,deleteError} = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
{staffData.length != 0 || searchString.length != 0 ?
                <Box className={style.wrapper}>
                    <Box className={style.header}>
                        <Box className={style.leftHeaderWrapper}>
                            <Typography className={style.title}>
                                {configJSON.bookingService}
                            </Typography>

                            {selectedRows.length > 0 &&
                                <Button
                                    variant="text"
                                    className={style.deleteButton}
                                    onClick={this.handleDeleteModal}
                                >
                                    {configJSON.deleteServices}
                                </Button>
                            }
                        </Box>

                        <Button
                            variant="contained"
                            className={style.addServiceButton}
                            onClick={this.hangleNavigation}
                        >
                            {configJSON.addService}
                        </Button>
                    </Box>

                    <Typography component="div">
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Search staff"
                            className={style.searchBar}
                            inputProps={{ className: style.searchInput }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className={style.icon} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.searchServices}
                        />
                    </Typography>

                    {!isLoading && staffData.length > 0 &&
                        <StaffTable
                            navigation={undefined}
                            id={""}
                            staffData={staffData}
                            isLoading={isLoading}
                            selectedRows={selectedRows}
                            rowCheckBox={(event: React.ChangeEvent<HTMLInputElement>, id: string) => this.handleRowCheckboxClick(event, id)}
                            allRowCheckBox={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectAllClick(event)} 
                            pagination={this.state.pagination} />
                    }
                </Box> :""}

                {
                    isLoading && (
                        <Card className={style.noServicesCard}>
                            <Box className={style.loadingResults}>
                                <Typography className={style.loadingResultsText}>
                                    {`Loading results...   `}
                                </Typography>
                                <span>
                                    <CircularProgress />
                                </span>
                            </Box>
                        </Card>
                    )
                }

                {
                    !isLoading && staffData.length == 0 && searchString.length == 0 &&
                    <Card className={style.noServicesCard} style={{ width: '600px', height: "300px" }}>
                        <Typography className={style.title}>{configJSON.noServiceProvider}</Typography>

                        <Typography className={style.paragraph} style={{ width: '400px' }}>
                            {configJSON.noServiceDes}
                        </Typography>
                        <Button className={style.addServiceButtonNoServices} onClick={this.hangleNavigation}>
                            {configJSON.addService}
                        </Button>
                    </Card>
                }

             
                <Slide direction="down" in={!!deleteSuccess} mountOnEnter unmountOnExit>
                    <Box className={style.notificationWrapper}>
                        <Box className={style.notification}>
                            <img
                                src={require("../assets/Builder Studio Store.png")}
                                className={style.logo}
                            />

                            <Box className={style.notificationMessage}>                         
                                {deleteSuccess && (
                                    <>
                                        <img src={SuccessIcon} alt="successImage"/>
                                        <Typography className={style.notificationText}>
                                            <strong>{configJSON.success}</strong>
                                            {deleteSuccess}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Slide>
                <Slide direction="down" in={!!deleteError} mountOnEnter unmountOnExit>
                    <Box className={style.notificationWrapper}>
                        <Box className={style.notification}>
                            <img
                                src={require("../assets/Builder Studio Store.png")}
                                className={style.logo}
                            />

                            <Box className={style.notificationMessage}>                         
                                {deleteError && (
                                    <>
                                        <img src={ErrorIcon} alt="error image"/>
                                        <Typography className={style.notificationText}>
                                            <strong>{configJSON.errorText}</strong>
                                            {deleteError}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Slide>


                {isModalOpened && (
                    <Portal>
                        <DeleteModal
                            message="Are you sure you want to delete the selected staff ?"
                            closeModal={this.handleDeleteModal}
                            confirmDeletion={this.deleteSatffData}
                        />
                    </Portal>
                )}

                {!isLoading && staffData.length === 0 && searchString.length > 0 &&(
                    <Card className={style.noServicesCard}>
                        <img
                            src={`${require("../assets/Not-found.png")}`}
                            className={style.notFoundImage}
                        />
                        <Typography className={style.noResultsFound}>
                            {configJSON.textNoResultsFound}
                        </Typography>
                        <Typography className={style.pleaseRefineYourSearch}>
                            {configJSON.textNoResultHint}
                        </Typography>
                    </Card>
                )}

                {!isLoading && !!pagination?.total_count && (
                    <Box marginBottom={1.5}>
                        <Pagination
                            count={pagination?.total_count}
                            onChange={this.handlePageChange}
                            pageSize={pagination?.per_page}
                            itemText={configJSON.paginationText}
                            pageNo={pagination?.current_page || 1}
                        />
                    </Box>
                )}
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const Styles = (theme: Theme) =>
    createStyles({
        noServicesCard: {
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            borderRadius: "8px",
            background: "#FFFFFF",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            top: "50%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
            height: "250px",
            width: "400px"
        },
        notificationWrapper: {
            top: 0,
            left: 0,
            position: "fixed",
            width: "100%",
            zIndex: 1,
        },
        notification: {
            display: "flex",
            backgroundColor: "#3C3E49",
            padding: theme.spacing(1.25, 3),
        },
        logo: {
            position: "absolute",
            width: "159px",
            objectFit: "none",
            height: "calc(100% - 20px)",
        },
        notificationMessage: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#3C3E49",
            height: "48px",
            flex: 1,
        },
        notificationIcon: {
            color: "transparent",
        },
        notificationText: {
            padding: theme.spacing(0, 1.25),
            fontSize: "14px",
            lineHeight: "20px",
            color: "#FFFFFF",
        },
        container: {
            padding: theme.spacing(5, 3),
        },
        noServicesCardContent: {
            display: "flex",
            flexDirection: "column",
            width: "280px",
            height: "180px",
            alignItems: "center",
            justifyContent: "center",
        },
        loadingResults: {
            display: "flex",
            alignItems: "center",
        },
        loadingResultsText: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "pre",
        },
        wrapper: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(5),
            padding: "30px"
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        leftHeaderWrapper: {
            display: "flex",
            gap: theme.spacing(2.25),
            alignItems: "center"
        },
        title: {
            height: "45px",
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "32px",
            letterSpacing: "-0.56px",
            color: "#3C3E49"
        },
        deleteButton: {
            textTransform: "none",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "20px",
            textDecoration: "underline",
            color: "#FF1744",
        },
        paragraph: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#676B7E",
            margin: "20px 0",
        },
        link: {
            textDecoration: "none",
        },
        addServiceButtonNoServices: {
            textTransform: "none",
            borderRadius: "3px",
            padding: theme.spacing(1.5, 5),
            fontSize: "16px",
            lineHeight: "24px",
            backgroundColor: "#00D659",
            color: "#3C3E49",
        },
        addServiceButton: {
            textTransform: "none",
            borderRadius: "3px",
            padding: theme.spacing(1.25, 4),
            fontSize: "12px",
            lineHeight: "20px",
            backgroundColor: "#00D659",
            color: "#3C3E49",
        },
        searchBar: {
            backgroundColor: "#FFFFFF",
        },
        searchInput: {
            borderColor: "#D0D2DA",
            padding: theme.spacing(1.75, 1.75, 1.75, 0),
            fontSize: "14px",
            lineHeight: "24px",
        },
        icon: {
            color: "#AFB2C0",
        },
        notFoundImage: {
            width: "103px",
        },
        noResultsFound: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            marginTop: theme.spacing(5),
        },
        pleaseRefineYourSearch: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#83889E",
            marginTop: theme.spacing(1),
        },
    })
export default withStyles(Styles, { withTheme: true })(AdminStaffList);
// Customizable Area End