// Customizable Area Start
import React, { Suspense } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Typography,
  Box,
  SvgIcon,
  CircularProgress,
} from "@material-ui/core";
import { pencil } from "../../accountgroups/src/assets";

import { Link } from "react-router-dom";
import { Service } from "../../utilities/src/models/Service";
import { useStyles } from "./styles/ServicesTable.web";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox-2.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked-2.svg";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
const ImageCard = React.lazy(() => import("../../utilities/src/components/ImageCard.web"))
const Currency = React.lazy(() => import("../../utilities/src/components/Currency.web"))

interface Props {
  services: Service[];
  selectedRows: string[];
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowCheckboxClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
}

const ServicesTable: React.FC<Props> = ({
  services,
  selectedRows,
  handleSelectAllClick,
  handleRowCheckboxClick,
}) => {
  const classes = useStyles();

  const isRowSelected = (id: string) => selectedRows.includes(id);

  const isAllRowsSelected = selectedRows.length === services.length;

  return (
    <Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  className={classes.checkbox}
                  checked={isAllRowsSelected}
                  icon={
                    <SvgIcon component={CheckboxIcon} viewBox="0 0 26 26" />
                  }
                  checkedIcon={<SvgIcon component={CheckboxIconChecked} />}
                  onChange={handleSelectAllClick}
                />
              </TableCell>

              <TableCell>
                <Typography className={classes.headerText}>Service</Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.headerText}>Category</Typography>
              </TableCell>

              <TableCell className={classes.priceCell}>
                <Typography className={classes.headerText}>Price</Typography>
              </TableCell>

              <TableCell className={classes.maxBookingsCell}>
                <Typography className={classes.headerText}>Maximum Bookings</Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.headerText}>Duration</Typography>
              </TableCell>

              <TableCell className={classes.center}>
                <Typography className={classes.headerText}>Status</Typography>
              </TableCell>

              <TableCell className={classes.center}>
                <Typography className={classes.headerText}>Edit</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBodyRow}>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    className={classes.checkbox}
                    checked={isRowSelected(service.id)}
                    icon={
                      <SvgIcon component={CheckboxIcon} viewBox="0 0 26 26" />
                    }
                    checkedIcon={<SvgIcon component={CheckboxIconChecked} />}
                    onChange={(event) =>
                      handleRowCheckboxClick(event, service.id)
                    }
                  />
                </TableCell>

                <TableCell>
                  <Box className={classes.service}>
                    <Suspense fallback={<CircularProgress/>}>
                      <ImageCard
                        imageClassName={classes.image}
                        image={
                          service.images.length > 0
                            ? (service.images[0] as ServiceImage).thumbnail_url
                            : ""
                        }
                        alt="image loading"
                      />
                    </Suspense>
                    <Typography
                      className={`${classes.servicetitle} ${classes.cellText}`}
                    >
                      {service.title}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  <Typography className={classes.cellText}>
                    {service.category.name || "-"}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    className={classes.cellText}
                  >
                    <Suspense fallback={<CircularProgress/>}>
                      <Currency text={service?.discountedPrice?.toFixed(2)} disountPrice={""} time={""} hasDiscount={false}/>
                    </Suspense>
                  </Typography>
                </TableCell>

                <TableCell className={classes.maximumBookingColumn}>
                  <Typography className={classes.cellText}>
                    {service.number_of_booking_per_slot || "1"}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography className={classes.cellText}>
                    {service.duration} mins
                  </Typography>
                </TableCell>

                <TableCell className={classes.center}>
                  <Typography
                    component="div"
                    className={`${classes.cellText} ${classes.statusWrapper}`}
                  >
                    <Box
                      className={`${classes.pill} ${service.status ? classes.activePill : classes.draftPill
                        }`}
                    >
                      {service.status ? "Active" : "Draft"}
                    </Box>
                  </Typography>
                </TableCell>

                <TableCell className={classes.center}>
                  <Link to={`/ServicesManagement/ServiceManagement/${service.id}`}>
                    <IconButton>
                      <img
                        src={pencil}
                        style={{
                          borderRadius: "4px"
                        }}
                        alt="Edit"
                        width={18}
                        height={18}
                      />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ServicesTable;
// Customizable Area End