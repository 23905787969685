import React from "react";
// Customizable Area Start
import { Typography } from "@material-ui/core";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
const Toolbar = ({ classes, title, handleBackClick }: any) => {
  return (
    <div className={classes.rootStyle}>
      <Typography
        className={classes.backButton}
        onClick={handleBackClick}
        style={{ display: "flex", alignItems: "center" }}
        component="div"
        data-testid="handleBack"
      >
        <img
          src={require("./assets/back.svg")}
          alt="image loading"
          className={classes.backImage}
          onClick={handleBackClick}
          style={{ cursor: "pointer" }}
        />
        Back
      </Typography>
      <Typography className={classes.pageHeading}>{title}</Typography>
    </div>
  );
};
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    rootStyle: {
      marginTop: "25px",
      marginBottom: "40px",
    },
    backButton: {
      color: "#676B7E",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      cursor: "pointer",
    },
    pageHeading: {
      color: "#3C3E49",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "0.02em",
      fontFamily: "Rubik",
      fontStyle: "normal",
      marginBottom: "2.5 rem",
    },
    backImage: {
      cursor: "pointer",
      marginRight: "4px",
    },
  });

export default withStyles(styles)(Toolbar);
// Customizable Area End
