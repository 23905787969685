// Customizable Area Start
import React from "react";
import { EmailSettings as EmailSettingsType } from "./Types";

interface EmailSettingsProps {
  emailSettings: EmailSettingsType;
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
}

export default class EmailSettingsController extends React.Component<
  EmailSettingsProps
> {
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onActiveChange(
      this.props.emailSettings.id,
      event.target.checked
    );
  };

  handleEditClick = () => {
    this.props.onEditClick(this.props.emailSettings.id);
  };
}
// Customizable Area End