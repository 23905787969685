// Customizable Area Start
import React, {Suspense}  from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  List,
  ListItem,
  CircularProgress,
} from "@material-ui/core";
import { nodataIcon } from "./assets";
import {
  createTheme,
  Theme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import PhotoLibraryController, { Props } from "./PhotoLibraryController";
import Skeleton from "@material-ui/lab/Skeleton";
import ProgressiveImage  from 'react-progressive-image';
const Pagination = React.lazy(() => import('../../ss-cms-common-components/src/Pagination/Pagination'));


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {},
    subtitle1: {
      margin: "20px 0px",
    },
    body1: {
      textAlign: "center",
    },
  },
});
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => ({
  noDataContainer: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    maxHeight: "416px",
    height: "416px",
    display: "flex",
  },
  message: {
    fontFamily: "Rubik sans-serif",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  img: {
    border: "0px",
  },
  container: {
    display: "flex",
    gap: theme.spacing(3.5),
    margin: theme.spacing(5, 10),
    [theme.breakpoints.down(1280)]: {
      margin: "8px 15px",
    },
  },
  listWrapper: {
    display: "flex",
    flex: "1 1 75%",
    justifyContent: "center",
  },
  list: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up(1440)]: {
      maxWidth: "1330px",
    },
  },
  item: {
    width: "calc(100% / 3)",
    padding: theme.spacing(1),
    [theme.breakpoints.down(800)]: {
      width: "calc(100% / 2)",
    }
  },
  card: {
    display: "flex",
    width: "405px",
    height: "374px",
    flex: "1",
    borderRadius: "0px",
    [theme.breakpoints.down(1250)]: {
      width: "405px",
      height: "330px",
    },
    [theme.breakpoints.down(1024)]: {
      width: "405px",
      height: "250px",
    },
    [theme.breakpoints.down(600)]: {
      width: "163px",
      height: "163px",
    },
  },
  skeletonLoading: {
    width: "405px",
    height: "374px",
    [theme.breakpoints.down(1024)]: {
      height: "300px",
    },
    [theme.breakpoints.down(600)]: {
      width: "190px",
      height: "173px",
    },
  },
  image: {
    height: "100%",
  },
  skeleton:{
    display:"flex",
    flexWrap: "wrap" as "wrap",
    flex: "1 1 75%",
    justifyContent: "center",
    gap:"10px"
  },
  skeletonContainer: {
    width: "405px",
    height: "374px",
    [theme.breakpoints.down(1250)]: {
      width: "330px",
      height: "320px",
    },
    [theme.breakpoints.down(1024)]: {
      width: "300px",
      height: "250px",
    },
    [theme.breakpoints.down(800)]: {
      width: "250px",
      height: "200px",
    },
    [theme.breakpoints.down(600)]: {
      width: "163px",
      height: "163px",
    },
  },
});

// Customizable Area End

export class Gallery extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <>
        <ThemeProvider theme={theme}>
          <Box className={classes.container}>
            {this.state.imageData.length > 0 ?
              <Box className={classes.listWrapper}>
                <List
                  className={classes.list}
                  style={{ alignItems: "flex-start", flexWrap: "wrap" }}
                >
                  {this.state.imageData.map((imageData) => (
                    <ListItem key={imageData.id} className={classes.item}>
                       <Card
                        className={classes.card}
                        style={{ flexDirection: "column" }}
                      >
                        <ProgressiveImage src={imageData?.file_url} placeholder={imageData?.file_url}>
                          {(src: string, loading: boolean) => loading ? <Skeleton variant="rect" animation="wave" width={"100%"} height={"100%"} />
                          : <img
                              src={src}
                              className={classes.image}
                              alt="gallery image"
                            />
                          }
                        </ProgressiveImage>
                      </Card>
                    </ListItem>
                  ))}
                </List>
              </Box>
            :
           ( this.state.loading ? (
              <Box className={classes.skeleton}>
                <Skeleton variant="rect" animation="wave" className={classes.skeletonContainer} />
                <Skeleton variant="rect" animation="wave" className={classes.skeletonContainer} />
                <Skeleton variant="rect" animation="wave" className={classes.skeletonContainer} />
              </Box>
            ) : (
              <Container>
                <Box
                  className={classes.noDataContainer}
                  style={{ flexDirection: "column" }}
                >
                  <Box style={{ margin: "auto", textAlign: "center" }}>
                    {this.state.loading ? (
                      <Box className={classes.skeletonContainer}>
                        <Skeleton variant="rect" animation="wave" className={classes.skeletonLoading} />
                      </Box>
                    ) : (
                      <>
                        <Box style={{ width: "100px", margin: "auto" }}>
                          <img src={nodataIcon} alt={"icon"} width={"100%"} />
                        </Box>
                        <Typography className={classes.message}>
                          No images uploaded
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Container>
              ))
            }
          </Box>
        </ThemeProvider>
        {!!this.state.pagination?.total_count && <Box marginBottom={1.5}>
          <Suspense fallback={<CircularProgress />}>
            <Pagination
              count={this.state.pagination?.total_count}
              onChange={this.handlePageChange}
              pageSize={this.state.pagination?.per_page}
              itemText={this.state.renameGallery ? this.state.renameGallery : "Gallery"}
              pageNo={this.state.pagination?.current_page || 1}
            />
          </Suspense>
        </Box>}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(Gallery);
// Customizable Area End
