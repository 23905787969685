import React from "react";
// Customizable Area Start
import CommonSettingsAdminController, {
  Props
} from "./CommonSettingsAdminController.web";
import CommonSettingsAdmin from "./CommonSettingsAdmin.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export default class CommonSettingsAdminWrapper extends CommonSettingsAdminController {
  constructor(props: Props) {
    super(props);
    mixpanel.track("webadmin_settings_page_enter");
  }


  render() {
    return (
      //Merge Engine DefaultContainer
      <CommonSettingsAdmin
        data={this.state.data}
        isSaving={this.state.isSaving}
        upsertService={this.upsertService}
        resetValues={this.state.resetValues}
        setResetValues={this.setResetValues}
        availabilityErrors={[]}
        isShowPopup={this.state.isShowPopup}
        />
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area End
