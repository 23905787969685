import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import CustomerController, {
  Props
} from "./CustomerController";
import { createTheme } from "@material-ui/core/styles";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  }
});

const webStyles = {
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF",
  },
  saveChangesbutton: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  containerEditWrapper: {
    padding: "40px"
  },
  link: {
    textDecoration: "none",
  },
  backButton: {
    textTransform: "none" as "none",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#676B7E",
    padding: "4px 0px",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    marginBottom: theme.spacing(5),
    color: "#3C3E49"
  },
  leftSideWrapper: {
    display: "flex",
    flex: "1 1 70%",
    gap: theme.spacing(3),
  },
  card: {
    width: "700px",
    height: "448px",
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    color: "#3C3E49",
    marginBottom: "15px",
    fontFamily: "Rubik"
  },
  inputLabel: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
  },
  input: {
    fontFamily: "Rubik",
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "24px",
    height: "auto",
    borderRadius: "3px",
    border: "1px solid #D0D2DA"
  },
}

// Customizable Area End

export default class ViewCustomer extends CustomerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { customerList } = this.state;
    const customerId = this.props.navigation.getParam("id");

    const customer = customerList.find((customer) => customer.id === customerId);
    return (
      <>
        <Box style={webStyles.containerEditWrapper}>
          <Button
            data-test-id="back-btn"
            variant="text"
            startIcon={<ArrowBackIcon />}
            style={webStyles.backButton}
            onClick={this.btnBackProps}
          >
            <Typography>Back</Typography>
          </Button>
          <Typography style={webStyles.title}>View customer</Typography>

          <Box style={webStyles.card}>
            <Typography style={webStyles.cardTitle}>
              Customer details
            </Typography>
            <Box style={{width:"60%"}}>
              <Box mb={2}>
                <Typography style={webStyles.inputLabel}>Full name *</Typography>
                <TextField
                  data-test-id="name"
                  name="name"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={customer?.attributes.full_name || ""}
                  inputProps={{
                    style: webStyles.input,
                  }}
                />
              </Box>
              <Box mb={2}>
                <Typography style={webStyles.inputLabel}>Email *</Typography>
                <TextField
                  data-test-id="email"
                  name="email"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={customer?.attributes.email || ""}
                  inputProps={{
                    style: webStyles.input,
                  }}
                />
              </Box>
              <Box mb={2}>
                <Typography style={webStyles.inputLabel}>Phone number</Typography>
                <TextField
                  data-test-id="number"
                  name="number"
                  variant="outlined"
                  disabled
                  fullWidth
                  value={customer?.attributes.full_phone_number || ""}
                  inputProps={{
                    style: webStyles.input,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}