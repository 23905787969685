import React from "react";
// Customizable Area Start
import { Box, Button, Card, withStyles, Grid, Typography, Divider, TextField, FormHelperText, TextareaAutosize, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Dialog, DialogContentText, DialogActions, DialogContent, CardContent, Collapse, CardActions, CircularProgress, DialogTitle, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import AppointmentsController, { AppointmentListData, ServiceCategories, Services, configJSON } from "./AppointmentsController.web";
import Calendar from "react-calendar";
import AppointmentStiper from "./AppointmentStiper.web";
import Confirmation from "./Confirmation.web";
import ConfirmationService from "./ConfirmationService.web"
import { ExpandMore, RadioButtonUnchecked, RadioButtonChecked } from "@material-ui/icons";
import clsx from "clsx";
import "react-calendar/dist/Calendar.css";
import { ArrowBackIcon, ArrowForwardIcon, ArrowIcon, StaffDummyImage, CrossMark } from "./assets";
import Currency from "../../utilities/src/components/Currency.web";
import StripePayments from "../../stripepayments/src/StripePayments.web";
import Promocode from "../../AdminPromoCode/src/PromocodeCustomer.web";
import Skeleton from "@material-ui/lab/Skeleton";
// Customizable Area End

// Customizable Area Start
export const webStyles = (theme: Theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "auto",
      width: "100%"
    },
  },
  card1: {
    display: "flex",
    padding: "40px",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    }
  },
  cardShadow: {
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  card_Shadow:{
    marginTop: "25px",
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  card2: {
    flexBasis: "40%",
  },
  customSelectedDay: {
    backgroundColor: "#3FC1CB",
    color: "white"
  },
  wrapper: {
    maxWidth: "718px",
    margin: "30px 33px 60px",
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 5px",
      padding: "0px"
    },
  },
  timeSlot: {
    border: "1px solid",
    padding: "10px",
    flexGrow: 1,
    fontSize: "12px",
    fontFamily: "Rubik",
    lineHeight: "20px",
    fontWeight: 500,
    borderRadius: "3px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 2px",
    },
  },
  dataAndTimeHeading: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    color: "#0000000",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px",
    },
    '@media(max-width: 480px)': {
      padding: "10px",
    }
  },
  containerBox: {
    maxWidth: "1440px",
    margin: "40px auto",
    '@media(max-width: 880px)': {
      margin: "15px 8px"
    },
    '@media(max-width: 480px)': {
      margin: "10px 7px"
    }
  },
  selectedDate: {
    backgroundColor: "#3FC1CB !important",
    color: "#FFF !important",
    borderRadius: "50% !important",
    width: "3em !important",
    height: "3em !important",
  },
  proceedBtn: {
    color: "white",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    '& .MuiButton-label': {
      textTransform: "none",
    }
  },
  btnEnable: {
    backgroundColor: "#3FC1CB",
  },
  btnDisable: {
    backgroundColor: "#D0D2DA",
  },
  inputLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
    fontFamily: "Rubik",
  },
  appointmentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "25px",
  },
  paymentHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "10px",
    fontFamily: "Rubik",
    marginBottom: "20px",
  },
  inputError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
  },
  formContainer: {
    marginTop: "2rem",
    marginBottom: "5rem"
  },
  headingSection: {
    padding: "0px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 14px",
    },
  },
  cancelText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#3FC1CB",
    marginLeft: "5px",
    lineHeight: "24px",
    cursor: "pointer",
    textDecoration: "underline",
  },
  arrowIcon: {
    color: "#3FC1CB",
    cursor: "pointer"
  },
  headingText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    color: "#3C3E49",
    margin: "20px 0px",
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  appointmentText: {
    fontFamily: "Rubik,",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#000000",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  serviceText: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  appoinmentsubContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    '@media(max-width: 480px)': {
      marginBottom: "0px",
    }
  },
  appoinmentsubContainer2: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "9px",
    '@media(max-width: 480px)': {
      marginBottom: "0px",
    }
  },
  divider: {
    borderColor: "#BFBFBF",
    marginBottom: "16px"
  },
  bottomSection: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  titleSection: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000"
  },
  titleSection1: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
  },
  serviceAndDuration: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000"
  },
  textEnd:{
    textAlign: "end" as "end"
  },
  appoinmentDate: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px"
  },
  comments: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#8C8C8C",
    fontStyle: "italic"
  },
  textLimit: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#000000",
  },
  dialogContent: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400 !important" as "bold",
    width: "65%",
    margin: "auto",
  },
  dialogButton: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  dialogCancel: {
    color: "#8C8C8C",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expandedPoliciess: {
    transform: "rotate(-90deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandedPoliciesOpen: {
    transform: "rotate(0deg)",
  },
  collapseCard: {
    padding: "8px 0px !important"
  },
  collapseViewCard: {
    padding: "10px 0px",
    height:"40px",
    alignItems: "flex-start"
  },
  collapseBox:{
    marginTop: "10px"
  },
  policyTitle:{
    fontSize: "12px",
    fontWeight: 500,
    marginBottom:"5px",
    lineHeight: "20px"
  },
  policyDevider:{
    margin:"10px 0px",
    border:"1px solid #E8E8E8"
  },
  policyDesc:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#3C3E49",
    lineHeight: "20px"
  },
  policytext:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#676B7E",
    lineHeight: "20px"
  },
  availableSlot: {
    width: "100%",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  calendarArrowIcon: {
    width: "13.49px !important",
    height: "12.59px !important",
    color: "#676B7E !important"
  },
  servicesOfferedMainContainer: {
    borderRadius: "5px",
    background: "var(--gray-1-white, #FFF)",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "60px"
  },

  services_Offered_Heading: {
    color: "#000",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px"
  },

  "& .MuiTabs-indicator": {
    display: "none"
  },

  services_Offered_Tabs_Container: {
    minWidth: "fit-content",
    MaxWidth: "fit-content",
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
      width: "fit-content",
      whiteSpace: "nowrap",
      justifyContent: "flex-start"
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color: "#3FC1CB",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "30px",
      textDecoration: "underline",
      position: "relative"
    },
    '& .MuiAccordionSummary-content.Mui-expandedPolicies': {
      color: "#3FC1CB",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "30px",
      textDecoration: "underline",
      position: "relative"
    },
    "& .MuiTab-root": {
      justifyContent: "flex-start",
      padding: "0px",
      minWidth: "fit-content",
      minHeight: "fit-content",
      color: "var(--Blue-Gray-8, #83889E)",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      textTransform: "capitalize"
    },
    "& .MuiTabs-flexContainer": {
      gap: "35px",
      paddingTop: "15px"
    },
    "& .MuiTab-textColorInherit ": {
      Opacity: 1
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#3FC1CB",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Rubik",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "20px",
      textDecoration: "underline",
      position: "relative"
    }
  },

  services_Offered_Tabs_Option_Container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column" as "column",
    paddingTop: "10px",
    gap: "15px",
  },

  services_Offered_Tabs_Option_Heading: {
    color: "var(--Blue-Gray-10, #3C3E49)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px"
  },

  services_Offered_Tabs_Option_Heading_Box:{
    fontWeight: 400,
  },

  services_Offered_Tabs_Option_Sub_Heading: {
    color: "var(--Blue-Gray-10, #3C3E49)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },

  serviceBox: {
    display: "flex",
    gap: "65px",
  },

  catagoryBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "30px"
  },

  radioBox: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiFormControlLabel-root": {
      marginRight: "0px"
    }
  },

  radioUncheked: {
    fontSize: 24,
    color: "#D0D2DA"
  },

  radioChecked: {
    fontSize: 24,
    color: "#6200EA"
  },

  editContainer: {
    display: "flex",
    justifyContent: "space-between"

  },

  editText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#3FC1CB",
    textDecoration: "underline",
    cursor: "pointer"
  },

  serviceProvider: {
    fontWeight: 400,
    fontSize: "14px",
    margin: "11px 0 15px 0",
    display: 'flex',
    alignItems: 'center'
  },

  loader: {
    margin: "0 auto"
  },

  accordionContainer: {
    width: "100%",
    boxShadow: "none",
    borderBottom: "1px solid #DFE0E6",
    "& .MuiAccordionDetails-root": {
      flexDirection: "column",
    }
  },

  heading: {
    textTransform: "capitalize" as "capitalize",
  },

  calendarCustomClass: {
    "& .react-calendar__navigation": {
      justifyContent: "center",
      "& .react-calendar__navigation__label": {
        flexGrow: "0.4 !important"
      }
    },
    "& .react-calendar__navigation button:enabled:hover": {
      backgroundColor: "transparent"
    },
    "& .react-calendar__navigation button:enabled:focus": {
      backgroundColor: "transparent"
    }
  },
  radioButton: {
    "&.Mui-checked": {
      color: "#00D659",
    },
    color: "#AFB2C0",
  },
  confirmationDialog: {
    padding: "50px 40px 20px 40px",
    textAlign: "center" as "center",
  },confirmationServiceFailedContainer: {
    margin: "60px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "60px 20px",
    }
  },
  failedIcon: {
    width: "82.54px",
    height: "82.54px",
    cursor: "pointer",
    top:"250px",
    marginBottom: "40px",
  },
  textFirstServiceFailed: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  dividerServiceFailed: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  textSecondServiceFailed: {
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "14px",
    marginBottom: "50px",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "35px",
    }
  },
  containerBoxServiceFailed: {
    maxWidth: "519px",
    margin: "auto"
  },
  proceedChangeBtn: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "0px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px"
  },
  totalBorder: {
    marginBottom: "15px"
  },
  StaffImage: {
    width:"35px",
    height:"35px",
    borderRadius: "4px",
    marginRight: "10px"
  },
  selectServiceProviderContainer: {
    width: "100%",
    marginBottom: "40px",
    padding: "20px 40px",
    display: "flex",
    flexDirection: "column" as const,
    gap: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      gap: '10px',
    }
  },
  serviceProviderTitle: {
    fontFamily: 'Rubik',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
    }
  },
  customMultiSelect: {
    padding: "11px 16px",
    borderRadius: '3px',
    border: '1px solid #D0D2DA',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center'
  },
  customMultiSelectDropdown: {
    border: '1px solid #D0D2DA',
    marginTop: '5px',
    borderRadius: '3px'
  },
  customMultiSelectContent: {
    overflowY: 'auto' as "auto",
    maxHeight: '200px',
  },
  serviceProviderListItem: {
    display: 'flex',
    padding: "10px 20px",
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#3C3E49',
    fontSize: '14px',
    lineHeight: '24px',
  },
  selectedServiceProvider: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#676B7E',
    lineHeight: '24px',
  },
  viewPolicies:{
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    lineHeight: "20px",
  },
  cardContent:{
    "&.MuiCardContent-root":{
      padding:"0px"
    },
    "&.MuiCardContent-root:last-child":{
      paddingBottom: "10px"
    }
  }
});

const webStyle = {
  field: {
    fontFamily: "Rubik,",
    padding: "12px 16px",
    fontSize: "14px",
    borderRadius: "3px",
    lineHeight: "24px",
    height: "auto"
  },
}
// Customizable Area End

export class Appointments extends AppointmentsController {
  // Customizable Area Start
  renderSelectSlot = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.appointmentsList.map((slot: AppointmentListData, index: number) => {
          const slotID = slot.id === this.state.selectedSlot.id ? "#0097A7" : "#3C3E49";
          const availableSlots = slot.is_available ? "#3C3E49" : "#AFB2C0";
          const slotSelected = slot.id === this.state.selectedSlot.id ? "pointer" : "default";
          return (
            <Grid item xs={3} sm={3} md={3} key={slot.id}>
              <Box
                data-testid={`selectSlot${index + 1}`}
                className={classes.timeSlot}
                style={{
                  border: `1px solid ${slot.is_available ? slotID : "#AFB2C0"}`,
                  backgroundColor: slot.id === this.state.selectedSlot.id ? "#E0F7FA" : "transparent",
                  color: slot.id === this.state.selectedSlot.id ? "#0097A7" : availableSlots,
                  textAlign: "center",
                  cursor: slot.is_available ? slotSelected : "not-allowed",
                }}
                onClick={() => slot.is_available && this.handelAvaibilityTimeSlot(slot)}
              >
                {this.convertTimeFormat(typeof slot.slot_start_time === "string" ? slot.slot_start_time : "")}
              </Box>
            </Grid>
          )
        })}
      </>
    )
  }

  renderService = (classes: Record<string, string>) => {
    const { discountedPrice, undiscountedPrice, title, duration, hasDiscount } = this.state.service
    const { imageStaffMember } = this.state;
    return (
      <>
        <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
          {configJSON.appointmentSummary}
        </Typography>

        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
          {configJSON.service}
        </Typography>

        <Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.serviceAndDuration}>{title}, {duration} {configJSON.mins}</Typography>
          <Typography variant="body1" className={classes.titleSection1}>
            {this.state.isPriceLoading ?
              <Skeleton variant="text" animation="wave" width={60} /> :
              <Currency text={`${discountedPrice?.toFixed(2)} `} disountPrice={`${undiscountedPrice?.toFixed(2)}`} time={""} hasDiscount={hasDiscount} />}</Typography>
        </Box>
        {this.state.selectedServiceProvider.id !== 0 && this.state.serviceProvider?.attributes?.full_name &&<Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.serviceProvider}>
            <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt="image" className={classes.StaffImage} />
            {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
          </Typography>
        </Box>}
        <Divider className={classes.divider} />
      </>
    )
  }

  renderServiceProvider = (classes: Record<string, string>) => {
    const { duration, title, price, discount_option, total } = this.state.selectedData;
    const { imageStaffMember } = this.state;
    return (
      <>
        <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
          {configJSON.appointmentSummary}
        </Typography>

        {
          (this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1) ?
            <Typography variant="h6" className={classes.serviceText}>
              {configJSON.service}
            </Typography>
            :
            <Box className={classes.editContainer}>
              <Typography variant="h6" className={classes.serviceText}>
                {configJSON.service}
              </Typography>
              <Typography variant="h6" className={classes.editText} onClick={this.handleEditServiceNavigation}>
                {configJSON.textedit}
              </Typography>
            </Box>

        }

        <Box className={classes.appoinmentsubContainer} style={{ marginBottom: "0" }}>
          <Typography variant="body1" className={classes.serviceAndDuration}>{title}, {duration} {configJSON.mins}</Typography>
          <Typography
            variant="body1"
            className={classes.titleSection1}>
            {this.state.isPriceLoading ?
             <Skeleton variant="text" animation="wave" width={60} /> :
              <Currency text={`${total?.toFixed(2)} `} disountPrice={`${price.toFixed(2)}`} time={""} hasDiscount={discount_option} />}
          </Typography>
        </Box>

        <Box className={classes.appoinmentsubContainer2}>
          <Typography variant="body1" className={classes.serviceProvider}>
            <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt="image" className={classes.StaffImage}/>
            {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
      </>
    )
  };

  renderAppoinmentSummaryService = (classes: Record<string, string>) => {
    const { discountedPrice, undiscountedPrice, hasDiscount, title, duration } = this.state.service;
    const { imageStaffMember } = this.state;
    return (
      <>
        {this.state.screenSize > 599 ? (
          <Card className={classes.card2}>
            <Box style={{ padding: "30px" }}>
              {this.renderService(classes)}
              <Box className={classes.appoinmentsubContainer}>
                <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                <Typography
                  variant="body1"
                  className={classes.bottomSection}>
                  {this.state.isPriceLoading ?
                   <Skeleton variant="text" animation="wave" width={60} />:
                    <Currency text={`${discountedPrice?.toFixed(2)} `} disountPrice={""} time={""} hasDiscount={false} />}
                </Typography>
              </Box>
            </Box>
          </Card>) : (
          <Card className={classes.cardShadow} style={{ maxWidth: "1923px" }}>
            <Box style={{ padding: "20px 30px" }}>
              <CardActions disableSpacing className={classes.collapseCard}>
                <Typography variant="h6" className={classes.appointmentText}>
                  {configJSON.appointmentSummary}
                </Typography>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  data-testid="expandTestId"
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"
                >
                  <ExpandMore />
                </IconButton>
              </CardActions>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                <CardContent className={classes.collapseCard}>
                  <Typography
                    variant="h6"
                    className={classes.serviceText}
                    style={{ textAlign: "left" }}
                  >
                    {configJSON.service}
                  </Typography>

                  <Box className={classes.totalBorder}>
                    <Box className={classes.appoinmentsubContainer}>
                      <Typography variant="body1" className={classes.serviceAndDuration}>
                        {title}<br/> {duration} {configJSON.mins}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.titleSection1} ${classes.textEnd}`}
                      >
                         {this.state.isPriceLoading ?
                        <Skeleton variant="text" animation="wave" width={60} />:
                        <Currency text={`${discountedPrice?.toFixed(2)} `} disountPrice={`${undiscountedPrice?.toFixed(2)}`} time={""} hasDiscount={hasDiscount} />}
                      </Typography>
                    </Box>
                  </Box>
                  {this.state.selectedServiceProvider.id !== 0 && this.state.serviceProvider?.attributes?.full_name &&<Box className={classes.appoinmentsubContainer}>
                    <Typography variant="body1" className={classes.serviceProvider}>
                      <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
                      {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
                    </Typography>
                  </Box>}
                  <Divider className={classes.divider} />
                </CardContent>
              </Collapse>
              <Box className={classes.appoinmentsubContainer}>
                <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                <Typography variant="body1" className={classes.bottomSection}>
                {this.state.isPriceLoading ?
                   <Skeleton variant="text" animation="wave" width={60} />:
                  <Currency text={`${discountedPrice?.toFixed(2)} `} disountPrice={""} time={""} hasDiscount={false} />}
                </Typography>
              </Box>
            </Box>
          </Card>
        )}
      </>
    )
  };

  renderAppoinmentSummaryServiceProvider = (classes: Record<string, string>) => {
    const { discount_option, price, total, duration, title } = this.state.selectedData;
    const { imageStaffMember } = this.state;
    return (
      <>
        {this.state.screenSize > 599 ? (
          <Card className={classes.card2}>
            <Box style={{ padding: "30px" }}>
              {this.renderServiceProvider(classes)}
              <Box className={classes.appoinmentsubContainer}>
                <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                <Typography
                  variant="body1"
                  className={classes.bottomSection}>
                  {this.state.isPriceLoading ?
                   <Skeleton variant="text" animation="wave" width={60} /> :
                    <Currency text={`${total.toFixed(2)}`} disountPrice={""} time={""} hasDiscount={false} />}
                </Typography>
              </Box>
            </Box>
          </Card>) : (
          <Card className={classes.cardShadow} style={{ maxWidth: "1923px" }}>
            <Box style={{ padding: "20px 30px" }}>
              <CardActions disableSpacing className={classes.collapseCard}>
                <Typography variant="h6" className={classes.appointmentText}>
                  {configJSON.appointmentSummary}
                </Typography>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  data-testid="expandTestId"
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"
                >
                  <ExpandMore />
                </IconButton>
              </CardActions>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                <CardContent className={classes.collapseCard}>
                  {
                    (this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1) ?
                      <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                        {configJSON.service}
                      </Typography>
                      :
                      <Box className={classes.editContainer}>
                        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                          {configJSON.service}
                        </Typography>
                        <Typography variant="h6" className={classes.editText} onClick={this.handleEditServiceNavigation}>
                          {configJSON.textedit}
                        </Typography>
                      </Box>
                  }

                  <Box className={classes.appoinmentsubContainer}>
                    <Typography variant="body1" className={classes.serviceAndDuration}>
                      {title}<br/> {duration} {configJSON.mins}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`${classes.titleSection1} ${classes.textEnd}`}
                    > 
                    {this.state.isPriceLoading ?
                      <Skeleton variant="text" animation="wave" width={60} /> :
                      <Currency
                        text={`${total.toFixed(2)} `}
                        disountPrice={`${price.toFixed(2)}`}
                        time={""}
                        hasDiscount={discount_option} />}
                    </Typography>
                  </Box>
                  <Box className={classes.appoinmentsubContainer}>
                    <Typography variant="body1" className={classes.serviceProvider}>
                      <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
                      {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
                    </Typography>
                  </Box>

                  <Divider className={classes.divider} />
                </CardContent>
              </Collapse>
              <Box className={classes.appoinmentsubContainer}>
                <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                <Typography variant="body1" className={classes.bottomSection}>
                {this.state.isPriceLoading ?
                      <Skeleton variant="text" animation="wave" width={60} /> :
                  <Currency text={`${total?.toFixed(2)} `} disountPrice={""} time={""} hasDiscount={false} />}
                </Typography>
              </Box>
            </Box>
          </Card>
        )}
      </>
    )
  };

  renderServices = (classes: Record<string, string>) => {
    let count = this.state.appointmentType == "catalogue" ? "" : this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1 ? "" : 0
    return (
      <>
        {this.state.activeStep === count && (
          <Box className={classes.containerBox}>
            <Box className={classes.headingSection}>
              <Box data-testid="cancelBackBtnId" style={{ display: "inline-flex", alignItems: "center" }} onClick={this.btnBackProps}>
                <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} width={"12px"} height={"12px"}/>
                <Typography className={classes.cancelText}>{configJSON.cancelBackToAllStylists}</Typography>
              </Box>
              <Typography className={classes.headingText}>{configJSON.bookAppointment}</Typography>
              <Box>
                <AppointmentStiper
                  activeStep={this.state.activeStep}
                  appoinmentType={this.state.appointmentType}
                  categoriesLength={this.state.serviceProvider?.attributes?.services_count}
                />
              </Box>
            </Box>
            <Grid container className={classes.cardContainer} spacing={2}>
              {this.state.screenSize > 599 ?
                this.renderBookAppointmentLeft(classes)
                :
                <Grid item xs={12} sm={12} md={8}>
                  <Card className={classes.card1} style={{ flexDirection: "column" }}>
                    <Box className={classes.dataAndTimeHeading}>{configJSON.service}</Box>
                    {
                      this.state.categoriesData.map((item: ServiceCategories, indexNum: number) => {
                        return (
                          <>
                            <Box className={classes.servicesOfferedMainContainer}>
                              <Box className={classes.serviceBox}>
                                <Accordion className={classes.accordionContainer} defaultExpanded={indexNum === 0 ? true : false}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.services_Offered_Tabs_Container}
                                  >
                                    <Typography className={classes.heading}>{item.name} ({this.state.categoriesData[indexNum].service_count})</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {item.services.map((item: Services, index: number) => {
                                      return (
                                        <>
                                          <Box>
                                            <RadioGroup
                                              aria-label="gender"
                                              name="gender1"
                                              value={this.state.selectedValue}
                                              onChange={(event) => this.handleRadioChange(event, index, indexNum)}
                                              style={{ gap: "30px" }}
                                            >
                                              <Box className={classes.radioBox}>
                                                <FormControlLabel
                                                  value={item.title}
                                                  control={<Radio
                                                    icon={<RadioButtonUnchecked className={classes.radioUncheked} />}
                                                    checkedIcon={<RadioButtonChecked className={classes.radioChecked} />}
                                                  />}
                                                  label=""
                                                />
                                                <Box className={classes.services_Offered_Tabs_Option_Container}>
                                                  <Box>
                                                    <Typography className={classes.services_Offered_Tabs_Option_Heading}>
                                                      {item.title}<br />
                                                    </Typography>
                                                    <Box className={classes.services_Offered_Tabs_Option_Heading_Box}>
                                                      <Currency text={`${item.total.toFixed(2)} `} disountPrice={`${item.price.toFixed(2)}`} time={` | ${item.duration} mins`} hasDiscount={item.discount_option} />
                                                    </Box>
                                                  </Box>
                                                  <Typography className={classes.services_Offered_Tabs_Option_Sub_Heading}>{item.description}</Typography>
                                                </Box>
                                              </Box>
                                            </RadioGroup>
                                          </Box>
                                        </>
                                      )
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </Box>
                          </>
                        )
                      })
                    }
                  </Card>
                </Grid>
              }

              <Grid item xs={12} sm={12} md={4}>
                {this.state.serviceProvider.type == "service_provider_checkout" && this.state.selectedServiceProvider.id === 0 ?
                  this.renderAppoinmentSummaryServiceProvider(classes)
                  :
                  this.renderAppoinmentSummaryService(classes)
                }
                <Button
                  data-testid="step-0"
                  variant="contained"
                  disabled={this.state.isDisabled}
                  className={`${classes.proceedBtn} ${this.state.isDisabled ? classes.btnDisable : classes.btnEnable}`}
                  onClick={() => this.handelProceedFromDateTime(this.state.activeStep, "serviceProvider")}
                >
                  {configJSON.proceed}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  };

  renderBookAppointmentLeft = (classes: Record<string, string>) => {
    return (
      <>
        <Grid item xs={12} sm={12} md={8}>
          <Card className={classes.card1} style={{ flexDirection: "column" }}>
            {
              this.state.isPriceLoading ?
              <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rect" width="100%" height={60} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rect" width="100%" height={200} />
              </Grid>
              </Grid>
                :
                <>
                  <Box className={classes.dataAndTimeHeading} >{configJSON.service1} {this.state?.serviceProvider?.attributes?.full_name}</Box>
                  <Box className={classes.servicesOfferedMainContainer}>
                    <Typography className={classes.services_Offered_Heading}></Typography>
                    <Box className={classes.serviceBox}>
                      <Tabs
                        className={classes.services_Offered_Tabs_Container}
                        orientation="vertical"
                        value={this.state.value}
                        onChange={this.handleTabChange}
                      >
                        {this.state.categoriesData.map((item: ServiceCategories, index: number) => {
                          return (
                            <Tab
                              label={`${item.name} (${item.service_count})`}
                              id={`simple-tab-${index}`}
                              aria-controls={`simple-tabpanel-${index}`}
                              key={item.name}
                              style={{ width: "max-content" }} />
                          )
                        })}
                      </Tabs>
                      <Box className={classes.catagoryBox}>
                        {this.state.categoriesData[this.state.value]?.services.map((item: Services, index: number) => {
                          return (
                            <>
                              <Box>
                                <RadioGroup
                                  aria-label="gender"
                                  name="gender1"
                                  value={this.state.selectedValue}
                                  onChange={(event) => this.handleOptionChange(event, index)}
                                  style={{ gap: "30px" }}
                                >
                                  <Box className={classes.radioBox}>
                                    <FormControlLabel
                                      value={item.title}
                                      control={<Radio
                                        icon={<RadioButtonUnchecked className={classes.radioUncheked} />}
                                        checkedIcon={<RadioButtonChecked className={classes.radioChecked} />}
                                      />}
                                      label=""
                                    />
                                    <Box className={classes.services_Offered_Tabs_Option_Container}>
                                      <Box>
                                        <Typography className={classes.services_Offered_Tabs_Option_Heading}>
                                          {item.title}<br />
                                        </Typography>
                                        <Box className={classes.services_Offered_Tabs_Option_Heading_Box}>
                                          <Currency text={`${item.total.toFixed(2)} `} disountPrice={`${item.price.toFixed(2)}`} time={` | ${item.duration} mins`} hasDiscount={item.discount_option} />
                                        </Box>
                                      </Box>
                                      <Typography className={classes.services_Offered_Tabs_Option_Sub_Heading}>{item.description}</Typography>
                                    </Box>
                                  </Box>
                                </RadioGroup>
                              </Box>
                            </>
                          )
                        })}
                      </Box>
                    </Box>
                  </Box>
                </>
            }
          </Card>
        </Grid>
      </>
    )
  };

  renderBookAppointment = (classes: Record<string, string>) => {
    let count = this.state.appointmentType == "catalogue" ? 0 : this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1 ? 0 : 1;
    return (
      <>
        {this.state.activeStep === count && (
          <Box className={classes.containerBox}>
            <Box className={classes.headingSection}>
              <Box data-testid="cancelBackBtnId" style={{ display: "inline-flex", alignItems: "center" }} onClick={this.state?.serviceProvider?.attributes?.full_name && this.state.activeStep == 1 ? this.handleBack : this.btnBackProps}>
                <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} width={"12px"} height={"12px"}/>
                <Typography className={classes.cancelText}>{configJSON.cancelBackToAllStylists}</Typography>
              </Box>
              <Typography className={classes.headingText}>{configJSON.bookAppointment}</Typography>
              <Box>
                <AppointmentStiper
                  activeStep={this.state.activeStep}
                  appoinmentType={this.state.appointmentType}
                  categoriesLength={this.state.serviceProvider?.attributes?.services_count}
                />
              </Box>
            </Box>
            <Grid container className={classes.cardContainer} spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                {!this.state.initialLoading && this.state.appointmentType === "catalogue" && this.state.serviceProviderList?.length > 1 ? <Card className={`${classes.cardShadow} ${classes.selectServiceProviderContainer}`}>
                  <Typography className={classes.serviceProviderTitle}>{configJSON.serviceProviderTitle}</Typography>
                  <Box>
                    <Box className={classes.customMultiSelect} onClick={this.toogleSelect}>
                      <Box className={classes.selectedServiceProvider}>
                        {this.state.selectedServiceProvider.id !== 0 && <img
                          src={this.state.selectedServiceProvider?.image || StaffDummyImage}
                          height={27}
                          width={27}
                          alt="image loading"
                          style={{ marginRight: 10, borderRadius: '4px' }}
                        />}
                        <span>{this.state.selectedServiceProvider.full_name}</span>
                      </Box>
                      <span>
                        <img
                          src={require("../assets/Caret_down.png")}
                          alt="image loading" />
                      </span>
                    </Box>
                    {this.state.isModalOpen ?
                      <Box className={classes.customMultiSelectDropdown}>
                        <Box className={classes.customMultiSelectContent}>
                          {this.state.serviceProviderList.map((serviceProvider: any, index: number) => {
                            return (
                              <>
                                <Box key={index} className={classes.serviceProviderListItem} onClick={() => this.handleServiceProviderChange(serviceProvider)}>
                                  <Box>
                                    {serviceProvider?.id !== 0 && <img
                                      src={serviceProvider?.image || StaffDummyImage}
                                      style={{ marginRight: 10, borderRadius: "4px" }}
                                      width={"27px"}
                                      height={"27px"}
                                      alt="serviceProvider_img"
                                    />}
                                    {serviceProvider.full_name}
                                  </Box>
                                  {this.state.selectedServiceProvider.id ===  serviceProvider?.id && <img
                                    src={require("../assets/selected_icon.svg")}
                                    alt="selected_icon"
                                  />}
                                </Box>
                                {this.state.serviceProviderList.length - 1 !== index && <hr style={{ margin: 0 }} />}
                              </>
                            )
                          })}
                        </Box>
                      </Box>
                      :
                      null}
                  </Box>
                </Card>: null}
                <Card className={classes.card1} style={{ flexDirection: "column" }}>
                  {!this.state.initialLoading && <>
                    <Box className={classes.dataAndTimeHeading} >{configJSON.dateAndTime}</Box>
                    <Box style={{ margin: "auto" }}>
                      {this.state.selectedDate && <Calendar
                        data-testid="txtInputAvailableDate"
                        className={classes.calendarCustomClass}
                        value={this.state.selectedDate}
                        onChange={(value) => this.handleDateChange(value)}
                        tileDisabled={this.dateIsDisabled}
                        next2Label={null}
                        prev2Label={null}
                        nextLabel={<img src={ArrowForwardIcon} alt="icon" className={classes.calendarArrowIcon} />}
                        prevLabel={<img src={ArrowBackIcon} alt="icon" className={classes.calendarArrowIcon} />}
                      />}
                    </Box>
                  </>}
                  <Box className={classes.wrapper}>
                    {
                      this.state.isLoading ? 
                      <>
                      {this.state.initialLoading ? ( <Grid container spacing={2}>
                     <Grid item xs={12}>
                       <Skeleton animation="wave" variant="rect" width="100%" height={50} />
                     </Grid>
                     <Grid item xs={12}>
                       <Skeleton animation="wave" variant="rect" width="100%" height={200} />
                     </Grid>
                   </Grid>):(<Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </Grid>) }
                      </> :
                        <>
                          {this.state.appointmentsList.length > 0 && (
                            <>
                              <Grid container>
                                <Box className={classes.availableSlot} style={{ textAlign: "center" }}>
                                  <Typography data-testid="dateAndTimeId" style={{ fontFamily: "Rubik,", fontWeight: 600, fontSize: "14px", color: "#3C3E49" }}>{configJSON.availableTimes} ({configJSON.in} {this.state.timeZone})</Typography>
                                </Box>
                              </Grid>
                              <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <Grid container spacing={2} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                  {this.renderSelectSlot(classes)}
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {(this.state.isDataLenghtCheck) && (
                            <Box style={{ textAlign: "center", width: "100%", marginBottom: "50px" }}>
                              <Typography style={{ fontFamily: "Rubik", fontWeight: 400, fontSize: "14px" }}>{configJSON.noSlotsAvailable}</Typography>
                            </Box>
                          )}
                        </>
                    }
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {this.state.serviceProvider.type == "service_provider_checkout" && this.state.selectedServiceProvider.id === 0 ?
                  this.renderAppoinmentSummaryServiceProvider(classes)
                  :
                  this.renderAppoinmentSummaryService(classes)
                }
                <Button
                  data-testid="step-0"
                  variant="contained"
                  disabled={this.state.isDisabled}
                  className={`${classes.proceedBtn} ${this.state.isDisabled ? classes.btnDisable : classes.btnEnable}`}
                  onClick={() => this.handelProceedFromDateTime(this.state.activeStep, "service")}
                >
                  {configJSON.proceed}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }

  renderCardAction = (classes: Record<string, string>) => {
    return (
      <CardActions disableSpacing className={classes.collapseCard}>
        <Typography data-testid="appointmentSummaryId" variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
          {configJSON.appointmentSummary}
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: this.state.expanded,
          })}
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
    )
  }

  renderAppointmentBookingForm = (classes: Record<string, string>) => {
    let count = this.state.appointmentType == "catalogue" ? 1 : this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1 ? 1 : 2;
    const selectedData = this.state.selectedData
    const { discountedPrice, undiscountedPrice, hasDiscount, title, duration } = this.state.service;
    const { imageStaffMember } = this.state;
    return (
      <>
        {this.state.activeStep === count && (
          <Box className={classes.containerBox}>
            {this.renderCancelBackFunction(classes)}
            <Grid container className={classes.cardContainer} spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Card style={{padding: "20px"}} className={classes.cardShadow}>
                  <Typography
                    data-testid="personalDetailsId"
                    variant="h4"
                    component="h1"
                    align="left"
                    gutterBottom
                    className={classes.appointmentHeading}
                  >
                    {configJSON.personalDetails}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>{configJSON.name}</Typography>
                        <TextField
                          data-testid="txtName"
                          name="name"
                          value={this.state.name}
                          placeholder="Name"
                          onChange={this.handleChange("name")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.name}
                        />
                        {this.state.errors.name && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.name}
                          </FormHelperText>
                        )}
                      </Box>

                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>{configJSON.email}</Typography>
                        <TextField
                          data-testid="txtEmail"
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange("email")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.email}
                        />
                        {this.state.errors.email && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.email}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Typography className={classes.inputLabel}>
                          {configJSON.mobile}
                        </Typography>
                        <TextField
                          data-testid="txtPhoneNumber"
                          name="phoneNumber"
                          placeholder="Mobile"
                          value={this.state.phoneNumber}
                          onChange={this.handleChange("phoneNumber")}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: webStyle.field
                          }}
                          error={!!this.state.errors.phoneNumber}
                        />
                        {this.state.errors.phoneNumber && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.phoneNumber}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box mb={2}>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography className={classes.inputLabel}>
                            {configJSON.comments}
                          </Typography>
                          <Typography className={classes.textLimit}>{this.state.characterCount}{configJSON.totalWordLimit}</Typography>
                        </Box>
                        <TextareaAutosize
                          data-testid="txtMessage"
                          name="comments"
                          placeholder="Comments"
                          value={this.state.comments}
                          onChange={this.handleChange("comments")}
                          minRows={6}
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            ...webStyle.field,
                            resize: "none",
                          }}
                        />
                        {this.state.errors.comments && (
                          <FormHelperText className={classes.inputError}>
                            {this.state.errors.comments}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {this.state.screenSize > 599 ? (
                  <Card style={{ padding: "40px 30px" }} className={classes.card2} >
                    {this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ? this.renderServiceProvider(classes) : this.renderService(classes)}
                    {
                      this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                        <Box className={classes.editContainer}>
                          <Typography variant="h6" className={classes.appoinmentDate}>
                            {configJSON.dateAndTime}
                          </Typography>
                          <Typography variant="h6" className={classes.editText} onClick={this.handleDateAndTimeNavigation}>
                            {configJSON.textedit}
                          </Typography>
                        </Box>
                        :
                        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                          {configJSON.dateAndTime}
                        </Typography>
                    }

                    <Box sx={{ marginBottom: "16px" }}>
                      <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                      <Typography variant="body1" className={classes.titleSection} data-testid="timezone-0">
                        {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                      </Typography>
                    </Box>
                    <Divider className={classes.divider} />

                    <Box className={classes.appoinmentsubContainer}>
                      <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                      <Typography variant="body1" className={classes.bottomSection}>
                        <Currency
                          text={`${this.state?.serviceProvider?.attributes?.full_name ? selectedData.total.toFixed(2) : discountedPrice?.toFixed(2)} `}
                          disountPrice={""}
                          time={""}
                          hasDiscount={false} />
                      </Typography>
                    </Box>
                  </Card>
                ) : (
                  <Card style={{ maxWidth: "1923px" }} className={classes.cardShadow}>
                    <Box style={{ padding: "20px 30px" }}>
                      {this.renderCardAction(classes)}
                      <Collapse in={this.state.expanded} timeout="auto" unmountOnExit >
                        <CardContent className={classes.collapseCard}>
                          {
                            (this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1) ?
                              <Typography variant="h6" className={classes.serviceText}>
                                {configJSON.service}
                              </Typography>
                              :
                              this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                                <Box className={classes.editContainer}>
                                  <Typography variant="h6" className={classes.serviceText}>
                                    {configJSON.service}
                                  </Typography>
                                  <Typography variant="h6" className={classes.editText} onClick={this.handleServiceNavigation}>
                                    {configJSON.textedit}
                                  </Typography>
                                </Box>
                                :
                                <Typography variant="h6" className={classes.serviceText}>
                                  {configJSON.service}
                                </Typography>
                          }

                          {
                            this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                              <>
                                <Box className={classes.appoinmentsubContainer}>
                                  <Typography variant="body1" className={classes.serviceAndDuration}>{selectedData.title}, {selectedData.duration} {configJSON.mins}</Typography>
                                  <Typography variant="body1" className={`${classes.titleSection1} ${classes.textEnd}`}>
                                    <Currency
                                      text={`${selectedData.total?.toFixed(2)} `}
                                      disountPrice={`${selectedData.price.toFixed(2)}`}
                                      time={""}
                                      hasDiscount={selectedData.discount_option} />
                                  </Typography>
                                </Box>
                                <Box className={classes.appoinmentsubContainer}>
                                  <Typography variant="body1" className={classes.serviceProvider}>
                                    <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
                                    {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
                                  </Typography>
                                </Box>
                              </>
                              :
                              <Box className={classes.totalBorder}>
                                <Box className={classes.appoinmentsubContainer}>
                                  <Typography variant="body1" className={classes.serviceAndDuration}>{title}<br/> {duration} {configJSON.mins}</Typography>
                                  <Typography variant="body1" className={`${classes.titleSection1} ${classes.textEnd}`}>
                                    <Currency
                                      text={`${discountedPrice?.toFixed(2)} `}
                                      disountPrice={`${undiscountedPrice?.toFixed(2)}`}
                                      time={""}
                                      hasDiscount={hasDiscount} />
                                  </Typography>
                                </Box>
                              </Box>
                          }
                          {this.state.selectedServiceProvider.id !== 0 && this.state.serviceProvider?.attributes?.full_name &&<Box className={classes.appoinmentsubContainer}>
                            <Typography variant="body1" className={classes.serviceProvider}>
                              <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
                              {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
                            </Typography>
                          </Box>}
                          <Divider className={classes.divider} />

                          {
                            this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                              <Box className={classes.editContainer}>
                                <Typography variant="h6" className={classes.appoinmentDate}>
                                  {configJSON.dateAndTime}
                                </Typography>
                                <Typography variant="h6" className={classes.editText} onClick={this.handleDateAndTimeNavigation}>
                                  {configJSON.textedit}
                                </Typography>
                              </Box>
                              :
                              <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
                                {configJSON.dateAndTime}
                              </Typography>
                          }

                          <Box sx={{ marginBottom: "16px" }}>
                            <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.selectedDate)}</Typography>
                            <Typography variant="body1" className={classes.titleSection} >
                              {this.convertTimeFormat(`${this.state.selectedSlot.slot_start_time} `) + " " + `(${this.state.timeZone})`}
                            </Typography>
                          </Box>
                          <Divider className={classes.divider} />
                        </CardContent>
                      </Collapse>
                      {
                        this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                          <Box className={classes.appoinmentsubContainer}>
                            <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                            <Typography variant="body1" className={classes.bottomSection}>
                              <Currency text={`${selectedData.total?.toFixed(2)}`}
                                disountPrice={""}
                                time={""}
                                hasDiscount={false} />
                            </Typography>
                          </Box>
                          :
                          <Box className={classes.appoinmentsubContainer}>
                            <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                            <Typography variant="body1" className={classes.bottomSection}>
                              <Currency text={`${discountedPrice?.toFixed(2)}`}
                                disountPrice={""}
                                time={""}
                                hasDiscount={false} />
                            </Typography>
                          </Box>
                      }
                    </Box>
                  </Card>
                )}
                <Button
                  data-testid="step-1"
                  variant="contained"
                  className={`${classes.proceedBtn} ${classes.btnEnable}`}
                  onClick={this.handleProceedToPersonalDetails}
                >
                  {configJSON.proceed}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    )
  }

  renderCancelBackFunction = (classes: Record<string, string>) => {
    return (
      <Box className={classes.headingSection}>
        <Box data-testid="backbuttonId" style={{ display: "inline-flex", alignItems: "center" }} onClick={this.handleBack}>
          <img src={ArrowIcon} alt="icon" className={classes.arrowIcon} width={"12px"} height={"12px"}/>
          <Typography className={classes.cancelText}>{configJSON.cancelBackToAllStylists}</Typography>
        </Box>
        <Typography data-testid="bookAppointment1Id" className={classes.headingText}>{configJSON.bookAppointment}</Typography>
        <Box>
          <AppointmentStiper
            activeStep={this.state.activeStep}
            appoinmentType={this.state.appointmentType}
            categoriesLength={this.state.serviceProvider?.attributes?.services_count}
          />
        </Box>
      </Box>
    )
  }

  renderPresonalDetails = (classes: Record<string, string>) => {
    const { discountedPrice, title, duration, undiscountedPrice, hasDiscount } = this.state.personalDetailsData?.service;
    const personalDetailsData = this.state.personalDetailsData;
    const { imageStaffMember } = this.state;
    return (
      <>
        {
          this.state.screenSize > 599 &&
          <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
            {configJSON.appointmentSummary}
          </Typography>
        }

        <Typography variant="h6" className={classes.serviceText} style={{ textAlign: "left" }}>
          {configJSON.service}
        </Typography>

        <Box className={classes.totalBorder}>
          <Box className={classes.appoinmentsubContainer}>
            <Typography variant="body1" className={classes.serviceAndDuration}>{title}, {duration} {configJSON.mins}</Typography>
            <Typography variant="body1" className={classes.titleSection1}>
              <Currency text={`${discountedPrice.toFixed(2)} `} disountPrice={`${undiscountedPrice?.toFixed(2)}`} time={""} hasDiscount={hasDiscount} />
            </Typography>
          </Box>
        </Box>
        {this.state.selectedServiceProvider.id !== 0 && this.state.serviceProvider?.attributes?.full_name &&<Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.serviceProvider}>
            <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
            {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
          </Typography>
        </Box>}
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
          {configJSON.dateAndTime}
        </Typography>

        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="body1" className={classes.titleSection}>{this.formatDate(personalDetailsData.selectedDate)}</Typography>
          <Typography variant="body1" className={classes.titleSection}>
            {this.convertTimeFormat(`${personalDetailsData.selectedSlot.slot_start_time} `) + " " + `(${personalDetailsData.timeZone})`}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.appoinmentDate} style={{ textAlign: "left" }}>
          {configJSON.personalDetails}
        </Typography>

        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
          <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
          <Typography variant="body1" className={classes.comments} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{this.state.comments}</Typography>
        </Box>

        <Divider className={classes.divider} />

        <Promocode
          navigation={undefined}
          totalValue={discountedPrice}
          totalPrice={(toatal) => this.handleTotalPrice(toatal)}
          isPromoApplied={(item) => this.handleIsPromoAppled(item)}
          promoCode={(item) => this.handlePromoCode(item)}
          userEmail={this.state.email}
          userPhoneNo={this.state.phoneNumber}
        />

        {this.state.screenSize > 599 && <Box className={classes.appoinmentsubContainer}>
          <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
          <Typography variant="body1" className={classes.bottomSection}>
            <Currency text={`${this.state.afterPromoCodePrice ? this.state.afterPromoCodePrice.toFixed(2) : discountedPrice?.toFixed(2)}`} disountPrice={""} time={""} hasDiscount={false} />
          </Typography>
        </Box>}
      </>
    )
  }

  renderPresonalDetailsForServiceProvider = (classes: Record<string, string>) => {
    const { title, duration, discount_option, price, total } = this.state.selectedData;
    const { imageStaffMember } = this.state;
    return (
      <>
        {
          this.state.screenSize > 599 &&
          <Typography variant="h6" className={classes.appointmentText} style={{ textAlign: "left" }}>
            {configJSON.appointmentSummary}
          </Typography>
        }

        {
          (this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1) ?
            <Typography variant="h6" className={classes.serviceText}>
              {configJSON.service}
            </Typography>
            :
            <Box className={classes.editContainer}>
              <Typography variant="h6" className={classes.serviceText}>
                {configJSON.service}
              </Typography>
              <Typography variant="h6" className={classes.editText} onClick={this.handleServiceNavigation}>
                {configJSON.textedit}
              </Typography>
            </Box>
        }

        <Box className={classes.appoinmentsubContainer} style={{ marginBottom: "0px" }}>
          <Typography variant="body1" className={classes.serviceAndDuration}>{title}, {duration} {configJSON.mins}</Typography>
          <Typography
            variant="body1"
            className={classes.titleSection1}>
            {this.state.isPriceLoading ?
             <Skeleton variant="text" animation="wave" width={60} /> :
              <Currency text={`${total?.toFixed(2)} `}
                disountPrice={`${price?.toFixed(2)}`}
                time={""}
                hasDiscount={discount_option} />}
          </Typography>
        </Box>

        <Box className={classes.appoinmentsubContainer2}>
          <Typography variant="body1" className={classes.serviceProvider}>
            <img src={imageStaffMember ? imageStaffMember : StaffDummyImage} alt={StaffDummyImage} className={classes.StaffImage}/>
            {configJSON.textAppointmentWith}{this.state.serviceProvider.attributes.full_name}
          </Typography>
        </Box>

        <Divider className={classes.divider} />

        <Box className={classes.editContainer}>
          <Typography variant="h6" className={classes.appoinmentDate}>
            {configJSON.dateAndTime}
          </Typography>
          <Typography variant="h6" className={classes.editText} onClick={this.handleDateAndTimeNavigation}>
            {configJSON.textedit}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="body1" className={classes.titleSection}>{this.formatDate(this.state.personalDetailsData.selectedDate)}</Typography>
          <Typography variant="body1" className={classes.titleSection}>
            {this.convertTimeFormat(`${this.state.personalDetailsData.selectedSlot.slot_start_time} `) + " " + `(${this.state.personalDetailsData.timeZone})`}
          </Typography>
        </Box>
        <Divider className={classes.divider} />

        <Box className={classes.editContainer}>
          <Typography variant="h6" className={classes.appoinmentDate}>
            {configJSON.personalDetails}
          </Typography>
          <Typography variant="h6" className={classes.editText} onClick={this.handlePersonalDetailNavigation}>
            {configJSON.textedit}
          </Typography>
        </Box>

        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="body1" className={classes.titleSection}>{this.state.name}</Typography>
          <Typography variant="body1" className={classes.titleSection}>{this.state.email} | {this.state.phoneNumber}</Typography>
          <Typography variant="body1" className={classes.comments} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>{this.state.comments}</Typography>
        </Box>

        <Divider className={classes.divider} />

        <Promocode
          navigation={undefined}
          totalValue={`${this.state?.serviceProvider?.attributes?.full_name ? this.state.selectedData.total : this.state.personalDetailsData.service.discountedPrice}`}
          totalPrice={(toatal) => this.handleTotalPrice(toatal)}
          isPromoApplied={(item) => this.handleIsPromoAppled(item)}
          promoCode={(item) => this.handlePromoCode(item)}
          userEmail={this.state.email}
          userPhoneNo={this.state.phoneNumber}
        />

        {
          this.state.screenSize > 599 &&
          <Box className={classes.appoinmentsubContainer}>
            <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
            <Typography variant="body1" className={classes.bottomSection}>
              <Currency
                text={`${this.state.afterPromoCodePrice ? this.state.afterPromoCodePrice.toFixed(2) : this.state?.serviceProvider?.attributes?.full_name
                  ? this.state.selectedData?.total.toFixed(2)
                  : this.state.personalDetailsData.service.discountedPrice?.toFixed(2)}`}
                disountPrice={""}
                time={""}
                hasDiscount={false} />
            </Typography>
          </Box>
        }
      </>
    )
  }

  renderAppointmentSummary = (classes: Record<string, string>) => {
    let count = this.state.appointmentType == "catalogue" ? 2 : this.state.categoriesData.length == 1 && this.state.categoriesData[0].services.length == 1 ? 2 : 3
    return (
      <>
        {this.state.activeStep === count && (
          <Box>
            <Box className={classes.containerBox}>
              {this.renderCancelBackFunction(classes)}
              <Grid container className={classes.cardContainer} spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                  <Card style={{padding: "20px", marginBottom: "20px"}} className={classes.cardShadow}>
                    <Typography
                      variant="h4"
                      component="h1"
                      align="left"
                      gutterBottom
                      className={classes.paymentHeading}
                    >
                      {configJSON.paymentLocation}
                    </Typography>

                    <FormControl component="fieldset" >
                      <RadioGroup
                        data-testid="paymentOptionId"
                        aria-label="paymentOption"
                        name="paymentOption"
                        value={this.state.paymentOption}
                        onChange={this.handlePaymentOptionChange}
                      >
                        <FormControlLabel
                          data-testid="payNowFormControlId"
                          value="pay_online"
                          control={
                            <Radio
                              data-testid="payNowId"
                              className={classes.radioButton}
                              checked={this.state.paymentOption === "pay_online"}
                              disabled={["pay_in_person"].includes(this.state.paymentPreference)}
                              onClick={this.handlePayonlineRadioBtn}
                            />
                          }
                          label="Pay now"
                        />
                        <FormControlLabel
                          data-testid="payAtLocationFormControlId"
                          value="pay_in_person"
                          control={
                            <Radio
                              data-testid="payAtLocationId"
                              className={classes.radioButton}
                              checked={this.state.paymentOption === "pay_in_person"}
                              disabled={["pay_online"].includes(this.state.paymentPreference)}
                              onClick={this.handlePayAtLocationRadioBtn}
                            />
                          }
                          label="Pay later at location"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Card>
                  {this.state.paymentOption == "pay_in_person" ? "" :
                    <Card style={{padding: "20px"}} className={classes.cardShadow}>
                      <Typography
                        data-testid="addressTestId"
                        variant="h4"
                        component="h1"
                        align="left"
                        gutterBottom
                        className={classes.appointmentHeading}
                      >
                        {configJSON.address}
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>{configJSON.country}</Typography>
                            <TextField
                              data-testid="txtCountry"
                              name="country"
                              placeholder="Country"
                              value={this.state.country}
                              onChange={this.handlePaymentChange("country")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.country}
                            />
                            {this.state.addressError.country && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.country}
                              </FormHelperText>
                            )}
                          </Box>

                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>{configJSON.flatHouseApartment}</Typography>
                            <TextField
                              data-testid="txtHouseNo"
                              name="houseNo"
                              placeholder="Flat / House / Apartment No"
                              value={this.state.houseNo}
                              onChange={this.handlePaymentChange("houseNo")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.houseNo}
                            />
                            {this.state.addressError.houseNo && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.houseNo}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>
                              {configJSON.addressLine1}
                            </Typography>
                            <TextField
                              data-testid="txtAddr1"
                              name="addr1"
                              placeholder="Address line 1"
                              value={this.state.addr1}
                              onChange={this.handlePaymentChange("addr1")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.addr1}
                            />
                            {this.state.addressError.addr1 && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.addr1}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>
                              {configJSON.addressLine2}
                            </Typography>
                            <TextField
                              data-testid="txtAddr2"
                              name="addr2"
                              placeholder="Address line 2"
                              value={this.state.addr2}
                              onChange={this.handlePaymentChange("addr2")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.addr2}
                            />
                            {this.state.addressError.addr2 && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.addr2}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>
                              {configJSON.city}
                            </Typography>
                            <TextField
                              data-testid="txtCity"
                              name="city"
                              placeholder="City"
                              value={this.state.city}
                              onChange={this.handlePaymentChange("city")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.city}
                            />
                            {this.state.addressError.city && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.city}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>
                              {configJSON.state}
                            </Typography>
                            <TextField
                              data-testid="txtStateName"
                              name="stateName"
                              placeholder="State"
                              value={this.state.stateName}
                              onChange={this.handlePaymentChange("stateName")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.stateName}
                            />
                            {this.state.addressError.stateName && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.stateName}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box mb={2}>
                            <Typography className={classes.inputLabel}>
                              {configJSON.zipCode}
                            </Typography>
                            <TextField
                              data-testid="txtZip"
                              name="zip"
                              placeholder="Zip code"
                              value={this.state.zip_code}
                              onChange={this.handlePaymentChange("zip_code")}
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: webStyle.field
                              }}
                              error={!!this.state.addressError.zip_code}
                            />
                            {this.state.addressError.zip_code && (
                              <FormHelperText className={classes.inputError}>
                                {this.state.addressError.zip_code}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  {this.state.screenSize > 599 ? (
                    <>
                      <Card style={{ padding: "40px 30px" }} className={classes.card2}>
                        {this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ? this.renderPresonalDetailsForServiceProvider(classes) : this.renderPresonalDetails(classes)}
                      </Card>
                    </>
                  ) : (
                    <Card style={{ maxWidth: "1923px" }} className={classes.cardShadow}>
                      <Box style={{ padding: "20px 30px" }}>
                        {this.renderCardAction(classes)}
                        <Collapse in={!this.state.expanded} timeout="auto" unmountOnExit >
                          <CardContent className={classes.collapseCard}>
                            {this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ? this.renderPresonalDetailsForServiceProvider(classes) : this.renderPresonalDetails(classes)}
                          </CardContent>
                        </Collapse>
                        {
                          this.state?.serviceProvider?.attributes?.full_name && this.state.selectedServiceProvider.id === 0 ?
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                              <Typography variant="body1" className={classes.bottomSection}>
                                <Currency text={`${this.state.afterPromoCodePrice ? this.state.afterPromoCodePrice.toFixed(2) : this.state.selectedData.total?.toFixed(2)}`} disountPrice={""} time={""} hasDiscount={false} />
                                </Typography>
                            </Box>
                            :
                            <Box className={classes.appoinmentsubContainer}>
                              <Typography variant="body1" className={classes.bottomSection}>{configJSON.total}</Typography>
                              <Typography variant="body1" className={classes.bottomSection}>
                                <Currency text={`${this.state.afterPromoCodePrice ? this.state.afterPromoCodePrice.toFixed(2) : this.state.service.discountedPrice?.toFixed(2)}`} disountPrice={""} time={""} hasDiscount={false} />
                                </Typography>
                            </Box>
                        }
                      </Box>
                    </Card>
                  )}
                  <Button
                    data-testid="step-2"
                    variant="contained"
                    className={`${classes.proceedBtn} ${classes.btnEnable}`}
                    onClick={this.handleOpenDialog}
                  >
                    {this.state.paymentOption === "pay_online" ? configJSON.payNow : configJSON.proceed}
                  </Button>
                  {this.renderPolicies(classes)}
                </Grid>
              </Grid>
              <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} >
                <DialogContent className={classes.confirmationDialog}>
                  <DialogContentText data-testid="areYouSureId" className={classes.dialogContent}>
                    {configJSON.areYouSure}
                  </DialogContentText>
                </DialogContent>
                <Divider style={{ margin: "16px 0" }} />
                <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
                  <Button data-testid="paymentModalNoId" onClick={this.handleCloseDialog} className={classes.dialogCancel} style={{ textTransform: "none" }}>
                    {configJSON.no}
                  </Button>
                  <Divider orientation="vertical" flexItem style={{ margin: "0 8px" }} />
                  <Button data-testid="paymentModalYesId" onClick={this.handleProceedToPayment} className={classes.dialogButton} style={{ textTransform: "none" }}>
                    {configJSON.yes}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                data-testid="alertDialogBoxId"
                open={!!this.state.slotBookingErrorMessage}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
              >
                <DialogTitle data-testid="alertId" id="dialog-title">{configJSON.alert}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="dialog-description">
                    {this.state.slotBookingErrorMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button data-testid="alertModalClose" onClick={this.closeAppointmentDialog} color="primary">
                    {configJSON.close}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        )}
      </>
    )
  }

  renderConfirmationFailureScreen=(classes: Record<string, string>)=>{
    return (
      <>
        <Box className={classes.confirmationServiceFailedContainer}>
          <Box style={{ textAlign: "center" }}>
            <img src={CrossMark} alt="icon" className={classes.failedIcon} />
            <Typography
              className={classes.textFirstServiceFailed}
              style={{ textTransform: "uppercase" }}
            >
              {configJSON.sorry} {this.state.name}
            </Typography>
            <Divider className={classes.dividerServiceFailed} />
            <Typography className={classes.textSecondServiceFailed}>
              {configJSON.yourCancelConfirmed}
            </Typography>
          </Box>
          <Box className={classes.containerBoxServiceFailed}>
            <Button
              data-testid="step-0"
              variant="contained"
              className={classes.proceedChangeBtn}
              onClick={()=>
                this.handleBacks()}>
              {configJSON.changePaymentMethod}
            </Button>

            <Box style={{ textAlign: "center",marginTop:'24px'}}>
              <Typography  style={{cursor:'pointer'}} onClick={()=> {
                this.state.serviceProvider?.attributes?.full_name ? (
                  this.props.navigation.navigate("ServiceProviders")
                ):(
                  this.props.navigation.navigate("Services")
                )
              } } className={classes.textSecondServiceFailed}>
                {configJSON.CancelTransaction}
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  renderPolicies = (classes: Record<string, string>) => {
    const {no_of_days_cancel, no_of_days_reschedule, reschedule_text, refund_text, cancel_text, is_cancellation_policy, is_refund_policy, is_reschedule_policy} = this.state.commonSetting.attributes
    return (
      <>
      {
      (is_cancellation_policy || is_refund_policy || is_reschedule_policy) &&
        <Card className={classes.card_Shadow} style={{ maxWidth: "1923px" }}>
          <Box style={{ padding: "0px 15px"}}>
            <CardActions disableSpacing className={classes.collapseViewCard}>
              <Typography variant="h6" className={classes.viewPolicies}>
                {configJSON.viewPolicies}
              </Typography>
              <IconButton
                className={clsx(classes.expandedPoliciess, {
                  [classes.expandedPoliciesOpen]: this.state.expandedPolicies,
                })}
                data-testid="expandTestId"
                onClick={this.handleExpandPolicies}
                aria-expandedPolicies={this.state.expandedPolicies}
                aria-label="show more"
              >
                <ExpandMore />
              </IconButton>
            </CardActions>
            <Collapse in={this.state.expandedPolicies} timeout="auto" unmountOnExit >
              <CardContent className={classes.cardContent}>
                {
                  is_cancellation_policy &&
                  <>
                    <Box>
                      <Typography className={classes.policyTitle}>{configJSON.cancelPolicies}</Typography>
                      <Typography className={classes.policyDesc}>{`${no_of_days_cancel} ${configJSON.cancellationPolicyMsg}`}</Typography>
                      <Typography className={classes.policytext}>{cancel_text}</Typography>
                    </Box>
                    <Divider className={classes.policyDevider} />
                  </>
                }
                {
                  is_reschedule_policy &&
                  <>
                    <Box className={classes.collapseBox}>
                      <Typography className={classes.policyTitle}>{configJSON.rechedulePolicies}</Typography>
                      <Typography className={classes.policyDesc}>{`${no_of_days_reschedule} ${configJSON.reschedulePolicyMsg}`}</Typography>
                      <Typography className={classes.policytext}>{reschedule_text}</Typography>
                    </Box>
                    <Divider className={classes.policyDevider} />
                  </>
                }
                {
                  is_refund_policy &&
                  <>
                    <Box className={classes.collapseBox}>
                      <Typography className={classes.policyTitle}>{configJSON.refundPolicies}</Typography>
                      <Typography className={classes.policytext}>{refund_text}</Typography>
                    </Box>
                  </>
                }
              </CardContent>
            </Collapse>
          </Box>
        </Card>
      }
      </>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.bookingData.attributes.appointment_date === "" && !this.state.activefailure && !this.state.isPayment? (
          <>
            {this.renderServices(classes)}
            {this.renderBookAppointment(classes)}
            {this.renderAppointmentBookingForm(classes)}
            {this.renderAppointmentSummary(classes)}
          </>
        ) : (
          <>
            { !this.state.activefailure && !this.state.isPayment &&
              <>
                {this.state?.serviceProvider?.attributes?.full_name ? (
                  <ConfirmationService service={this.state.service} paymentOption={this.state.paymentOption} bookingData={this.state.bookingData} navigation={this.props.navigation} commonSetting={this.state.commonSetting} />
                ) : (
                  <Confirmation service={this.state.service} paymentOption={this.state.paymentOption} bookingData={this.state.bookingData} navigation={this.props.navigation} commonSetting={this.state.commonSetting} />
                )}
              </>
            }
          </>
        )}
        {this.state.activefailure &&
          this.renderConfirmationFailureScreen(classes)
        }
        {
          this.state.isPayment &&
          <StripePayments
            navigation={this.props.navigation}
            id={this.state.orderId}
            emailId={this.state.email} 
            name={this.state.name} 
            line1={this.state.addr1} 
            postal_code={this.state.zip_code} 
            city={this.state.city} 
            state={this.state.stateName} 
            country={this.state.country} 
            description={this.state.comments}
            onPaymentFailed={this.paymentFailurSetPros}
            onPaymentSuccess={this.paymentSuccessSetProps}
          />
        }
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(webStyles)(Appointments);
// Customizable Area End
