import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
import { parseCatalogue } from "../../utilities/src/helpers/utils";
// Customizable Area Start
import { IconButtonProps } from "@material-ui/core";
export const configJSONUrl = require("../../../framework/src/config");
import * as Yup from "yup";
import moment, { Moment } from "moment";
import { Value } from "react-calendar/dist/cjs/shared/types";
import StorageProvider from "../../../framework/src/StorageProvider.web";
const navigation = require("react-navigation");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    // Customizable Area Start
    classes: Record<string, string>;
    personalDetailsData?: PersonalDetailsResponse;
    activeStep?: number;
    bookingData: BookingDataInterface;
    service: Service;
    paymentOption?: string;


    // Customizable Area End
}

// Customizable Area Start
interface ValidResponse {
    data: object;
}

interface ValidResponseMessage {
    message: string;
}

export interface ErrorResponse {
    errors: [
        {
            token: string;
        }
    ]
}

export interface StripesuccessRes {
    authentication_url: string;
    status: string;
}
export interface SubmitStripeRes {
    name: string;
    cardNumber: number | string;
    cardCVV: number | string;
    cardDate: number | string;
}

export interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export interface AppointmentListData {
    id: number;
    slot_start_time: string;
    slot_end_time: string;
    is_available: boolean;
}

export interface BookingDataInterface {
    id: string;
    type: string;
    attributes: {
        order_id: string;
        catalogue_id: number;
        payment_mode: string;
        total: number;
        status: string;
        duration: number;
        currency: null;
        appointment_date: string;
        order_date: string;
        is_promocode_applied: boolean;
        personal_detail: {
            id: number;
            full_phone_number: string;
            email: string;
            full_name: string;
            created_at: string;
            updated_at: string;
            appointment_id: number;
            comment: string;
        };
        service_provider: {
            id: number;
            full_name: string;
            phone_number: string;
            email: string;
            designation: string;
            image: string
        };
        billing_address: {
            id: number;
            country: string;
            city: string;
            zip_code: string;
            state: string;
            address_line_1: string;
            address_line_2: string;
            flat_number: string;
            created_at: string;
            updated_at: string;
            appointment_id: number;
        },
        time_slot: {
            slot_start_time: string;
            slot_end_time: string;
        };
        timezone: string;
        time_zone_short: string;
        service: {
            title: string;
            price: number;
            duration: number;
            discount_price: number;
            discount_option: boolean;
            images: string;
            thumbnail_url:string;
            small_url:string
        }
    }
}

export interface BookingDataInterface1 {
    data: BookingDataInterface
}

export interface AddAppointmentError {
    errors: [
        "Catalogue is currently inactive."
    ]
}
interface PersonalDetailsResponse {
    selectedDate: Date | string | null;
    selectedSlot: AppointmentListData;
    service: Service;
    timeZone: string;
}

interface StripeErrorResponse {
    stripe: string;
}

interface CatalgoueResponseData {
    data: {
        id: string;
        type: string;
        attributes: {
            title: string;
            description: string;
            duration: number;
            status: boolean;
            discount_option: boolean;
            discount: number;
            payment_preferences: string;
            category: {
                id: number;
                name: string;
            };
            images: [
                {
                    id: number;
                    url: string;
                }
            ];
            current_date: string;
            price: number;
            country: {
                id: number;
                name: string;
                code: string;
                phone_code: string;
            };
            currency: {
                id: number;
                name: string;
                symbol: string;
            };
            payment_method: string;
            time_zone: string;
            actual_price: number;
            availability: [
                {
                    working_day: string;
                    is_available: boolean;
                    working_hours: [
                        {
                            start_time: string;
                            end_time: string;
                        }
                    ]
                }
            ]
        }
    }
}

interface GetAppointmentListData {
    data: {
        id: string;
        type: string;
        attributes: {
            time_slots: [
                {
                    id: number;
                    slot_start_time: string;
                    slot_end_time: string;
                    is_available: false;
                }
            ]
        }
    },
    meta: {
        message: string;
        time_zone: string;
        time_zone_short: string;
    }
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface GetAppointmentListFailureData {
    message: string
}

interface CcAvenueEncryptedData {
    enc_resp: string;
    access_code: string;
}

interface CcAvenueError {
    errors: Array<ccAcenueErrorObj>
}

interface ccAcenueErrorObj {
    ccavenue_payment: string
}
interface StripeSuccessData {
    data: {
        id: string;
        type: string;
        attributes: {
            id: string;
            amount: number;
            currency: string;
            customer: string;
            client_secret: string;
            confirmation_method: string;
            created: number;
            payment_method: string;
            payment_method_types: [
                string
            ]
        }
    },
    meta: {
        authentication_url: string;
        status: string
    }
}

interface StripeErrorData {
    errors: [
        {
            stripe: string
        }
    ]
}

export interface Values {
    name: string;
    email: string;
    phoneNumber: string;
    comments: string;
}

export interface AppointmentValidationSuccessData {
    message: string;
    order_id: string;
}

export interface AppointmentValidationFailureData {
    errors: Array<string>;
}

export interface Address {
    country: string | boolean;
    addr1: string | boolean;
    addr2: string;
    houseNo: string;
    zip_code: string | boolean;
    city: string | boolean;
    stateName: string | boolean;
}

export interface Services {
    id: number,
    title: string,
    description: string,
    duration: number,
    total: number,
    discount_option: boolean,
    price: number,
    payment_preferences: string,
    currency: {
        id: number,
        name: string,
        symbol: string
    }
}

export interface ServiceCategories {
    name: string,
    service_count: number,
    services: Array<Services>
}

interface ServiceData {
    data: ServiceProvider
}

interface ServiceProviderData {
    data: {
        id: string;
        type: string;
        attributes: {
            time_slots: Array<AppointmentListData>;
            service_provider: {
                id: number;
                full_name: string;
                designation: string;
                image: [
                    {
                        id: number;
                        url: string;
                    }
                ]
            }
        }
    }
    meta: {
        message: string;
        time_zone: string;
        time_zone_short: string
    }
}

interface ServiceProvider {
    id: string,
    type: string,
    attributes: {
        email: string,
        full_name: string,
        phone_number: string,
        description: string,
        designation: string,
        categories: Array<ServiceCategories>,
        services_count: number,
        image: {
            id: number,
            url: string,
            thumbnail_url:string
        }
    }
}

interface ServiceProviderList {
    id: number,
    full_name: string,
    image: string | null
}
export interface CommonSetting {
    data: CommonSettingObj;
}

export interface CommonSettingObj {
    id: string;
    type: string;
    attributes: {
        is_cancellation_policy: boolean;
        is_reschedule_policy: boolean;
        is_refund_policy: boolean;
        no_of_days_cancel: number;
        cancel_text: string;
        no_of_days_reschedule: number;
        reschedule_text: string;
        refund_text: string;
    }
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    available_date: string;
    start_time: Moment | Date;
    end_time: Moment | Date;
    id: number;
    token: string;
    appointmentsList: Array<AppointmentListData>;
    selectedDate: Date | null;
    activeStep: number;
    serviceId: number;
    service: Service;
    personalDetailsData: PersonalDetailsResponse;
    selectedSlot: AppointmentListData;
    name: string;
    email: string;
    phoneNumber: string;
    comments: string;
    errors: Partial<Values>;
    addressError: Partial<Address>;
    isDisabled: boolean;
    paymentOption: string;
    activefailure:boolean;
    activeSuccess:boolean;
    country: string;
    addr1: string;
    addr2: string;
    houseNo: string;
    zip_code: string;
    city: string;
    stateName: string;
    appointmentsAvaibilityId: string;
    bookingData: BookingDataInterface;
    openDialog: boolean;
    screenSize: number;
    expanded: boolean;
    expandedPolicies: boolean;
    characterCount: number;
    paymentPreference: string;
    slotBookingErrorMessage: string;
    timeZone: string;
    stripeModalOpen: boolean;
    isOpenStripeModal: boolean;
    appointmentData: BookingDataInterface;
    stripeSuccessData: StripesuccessRes;
    stripeState: boolean;
    ccavenueModalShowRespons: CcAvenueEncryptedData
    timeZoneOffset: number;
    currency: string;
    price: number;
    appointmentValidationMsg: string;
    ccAvenueErrorMessage: string;
    isLoading: boolean;
    initialLoading: boolean;
    isDataLenghtCheck: boolean;
    isPriceLoading: boolean;
    appointmentType: string;
    value: number;
    serviceProvider: ServiceProvider;
    selectedValue: string;
    selectedIndex: number;
    categoriesData: Array<ServiceCategories>;
    serviceData: Array<Services>;
    selectedData: Services;
    catalogueId: number;
    imageStaffMember: string;
    isSlotSelected: boolean;
    serviceProviderList : Array<ServiceProviderList>;
    isModalOpen: boolean;
    selectedServiceProvider: ServiceProviderList;
    afterPromoCodePrice: number;
    isPromoCodeAppiled: boolean;
    promoCode: string;
    commonSetting: CommonSettingObj;
    orderId: string;
    isPayment: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    addAppointmentApiCallId: string = "";
    getAppointmentsListApiCallId: string = "";
    deleteAllAppointmentsApiCallId: string = "";
    getCatalogueApiCallId: string = "";
    stripePaymentApiCallId: string = "";
    ccAvenueintegrationApiCallId: string = "";
    appointmentValidationApiCallId: string = "";
    popupWin: Window | null = null;
    getServiceProviderApiCallId: string = "";
    getServiceProviderAvailabilityApiCallId: string = "";
    getServiceProvidersListApiCallId: string = "";
    getCommonSettingApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationRaiseMessage),
            getName(MessageEnum.NavigationMessage),
                  
            // Customizable Area End
        ];

        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        let endTime = new Date();
        endTime.setMinutes(endTime.getMinutes() + 30);
        this.state = {
            // Customizable Area Start
            timeZoneOffset: 0,
            id: 0,
            start_time: new Date(),
            end_time: endTime,
            available_date: moment(new Date()).format("DD/MM/YY"),
            appointmentsList: [],
            token: "",
            selectedDate: new Date(),
            activeStep: 0,
            serviceId: 0,
            service: {
                images: [
                    {
                        url: ""
                    }
                ],
                thumbnail_url: [
                    {
                        url: ""
                    }
                ],
                small_url: [
                    {
                        url: ""
                    }
                ],
                
            } as Service,
            personalDetailsData: {} as PersonalDetailsResponse,
            selectedSlot: {} as AppointmentListData,
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
            errors: {},
            isDisabled: true,
            paymentOption: "",
            activefailure:false,
            activeSuccess:false,
            country: "",
            addr1: "",
            addr2: "",
            houseNo: "",
            zip_code: "",
            city: "",
            stateName: "",
            addressError: {},
            appointmentsAvaibilityId: "",
            bookingData: {
                id: "",
                type: "",
                attributes: {
                    order_id: "",
                    catalogue_id: NaN,
                    payment_mode: "",
                    total: NaN,
                    status: "",
                    duration: NaN,
                    currency: null,
                    appointment_date: "",
                    order_date: "",
                    is_promocode_applied: false,
                    personal_detail: {
                        id: NaN,
                        full_phone_number: "",
                        email: "",
                        full_name: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN,
                        comment: ""
                    },
                    service_provider: {
                        id: NaN,
                        full_name: '',
                        phone_number: '',
                        email: '',
                        designation: "",
                        image: ''
                    },
                    billing_address: {
                        id: NaN,
                        country: "",
                        city: "",
                        zip_code: "",
                        state: "",
                        address_line_1: "",
                        address_line_2: "",
                        flat_number: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN
                    },
                    time_slot: {
                        slot_start_time: "",
                        slot_end_time: ""
                    },
                    timezone: "",
                    time_zone_short: "",
                    service: {
                        title: "",
                        price: NaN,
                        duration: NaN,
                        discount_price: NaN,
                        discount_option: false,
                        images: "",
                        thumbnail_url:"",
                        small_url:""
                    }
                }
            },
            openDialog: false,
            screenSize: window.innerWidth,
            expanded: false,
            expandedPolicies: false,
            characterCount: 0,
            paymentPreference: "",
            slotBookingErrorMessage: "",
            timeZone: "",
            stripeModalOpen: false,
            isOpenStripeModal: false,
            appointmentData: {
                type: "",
                id: "",
                attributes: {
                    catalogue_id: NaN,
                    order_id: "",
                    total: NaN,
                    status: "",
                    is_promocode_applied: false,
                    personal_detail: {
                        appointment_id: NaN,
                        id: NaN,
                        email: "",
                        created_at: "",
                        comment: "",
                        full_name: "",
                        updated_at: "",
                        full_phone_number: ""
                    },
                    service_provider: {
                        id: NaN,
                        full_name: '',
                        phone_number: '',
                        email: '',
                        designation: "",
                        image: ''
                    },
                    payment_mode: "",
                    appointment_date: "",
                    order_date: "",
                    currency: null,
                    duration: NaN,
                    time_slot: {
                        slot_end_time: "",
                        slot_start_time: ""
                    },
                    time_zone_short: "",
                    timezone: "",
                    billing_address: {
                        city: "",
                        address_line_1: "",
                        id: NaN,
                        zip_code: "",
                        state: "",
                        country: "",
                        appointment_id: NaN,
                        address_line_2: "",
                        updated_at: "",
                        created_at: "",
                        flat_number: ""
                    },
                    service: {
                        title: "",
                        price: NaN,
                        duration: NaN,
                        discount_price: NaN,
                        discount_option: false,
                        images: "",
                        thumbnail_url:"",
                        small_url:""
                    }
                }
            },
            stripeSuccessData: {} as StripesuccessRes,
            stripeState: false,
            ccavenueModalShowRespons: {} as CcAvenueEncryptedData,
            currency: "",
            price: NaN,
            appointmentValidationMsg: "",
            ccAvenueErrorMessage: "",
            isLoading: true,
            initialLoading: true,
            isDataLenghtCheck: false,
            isPriceLoading: true,
            appointmentType: "",
            value: 0,
            serviceProvider: {} as ServiceProvider,
            selectedValue: "",
            selectedIndex: 0,
            categoriesData: [],
            serviceData: [],
            selectedData: {} as Services,
            catalogueId: NaN,
            imageStaffMember: "",
            isSlotSelected: false,
            serviceProviderList: [{ 
                id: 0,
                full_name: "First available service provider",
                image: null
            }],
            isModalOpen: false,
            selectedServiceProvider: { 
                id: 0,
                full_name: "First available service provider",
                image: null
            },
            afterPromoCodePrice: NaN,
            isPromoCodeAppiled: false,
            promoCode: "",
            commonSetting: {} as CommonSettingObj,
            orderId: "",
            isPayment : false
            // Customizable Area End
        };

        // Customizable Area Start
        this.handleResize = this.handleResize.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
        // Customizable Area Start
        this.getCommonSettingData(this.state.token);
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        const status = searchParams.get("status");
        const bookingData = await StorageProvider.get("BookingData");
        const CommonSetting = await StorageProvider.get("CommonSetting");
        const service = await StorageProvider.get("Service");

        if (window.location.href.includes("status") && !service && !CommonSetting) {
            const navigation = new Message(getName(MessageEnum.NavigationMessage));
            navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Appointments");
            navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigation);
        }
        else if (status == "success") {
            mixpanel.track(configJSON.appointmentSuccessCceavenue);
            this.setState({
                bookingData: JSON.parse(bookingData),
                service: JSON.parse(service),
                commonSetting: JSON.parse(CommonSetting),
                paymentOption: "pay_online"
            }, () => {
                StorageProvider.remove("BookingData");
                StorageProvider.remove("Service");
                StorageProvider.remove("CommonSetting");
            });
        }
        else if (status == "failure") {
            mixpanel.track(configJSON.appointmentFailCceavenue);
            this.setState({ activefailure: true }, () => {
                StorageProvider.remove("Service");
                StorageProvider.remove("CommonSetting");
            });
        }
        const catalogueId = await StorageProvider.get("catalogueId");
        const navigationType = await StorageProvider.get("navigationType");
        if(navigationType === "service"){
            this.getCatalgoue();
        }
        mixpanel.track(configJSON.appointmentPageEnter);
        window.addEventListener("resize", this.handleResize);
        window.scrollTo(0,0)
        if(navigationType === "serviceProvider"){
            this.getServiceProvider(this.state.token, catalogueId);
        }
        // Customizable Area End
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
                this.postCCavenueSuccessCallBack(responseJson);
            }
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            if (this.isValidResponseMessage(responseJson)) {
                this.getAppointmentListMessageSuccessCallBack(responseJson);
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson, errorReponse);
            } 
        } 
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    isValidResponseMessage = (responseJson: ValidResponseMessage) => {
        return responseJson && responseJson.message;
    };

    isInValidResponse = (responseJson: ErrorResponse) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseMessage & BookingDataInterface1 & GetAppointmentListData & CatalgoueResponseData & StripeSuccessData & CcAvenueEncryptedData & AppointmentValidationSuccessData & CommonSetting) => {
        if (apiRequestCallId === this.addAppointmentApiCallId) {
            this.addAppointmentSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCatalogueApiCallId) {
            this.getCatalogueSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.stripePaymentApiCallId) {
            this.postStripeSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
            this.postCCavenueSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.appointmentValidationApiCallId) {
            this.postAppointmentValidationSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getServiceProviderApiCallId) {
            this.getServiceProviderSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getServiceProviderAvailabilityApiCallId) {
            this.getServiceProviderAvailabilitySuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getServiceProvidersListApiCallId) {
            this.getServiceProvidersListSuccess(responseJson);
        }
        if (apiRequestCallId === this.getCommonSettingApiCallId) {
            this.getCommonSettingSuccessCallBack(responseJson);
        }
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: ErrorResponse & AddAppointmentError & GetAppointmentListFailureData & CatalgoueResponseData & StripeErrorData & CcAvenueError & AppointmentValidationFailureData & BookingDataInterface1, errorReponse: StripeErrorData ) => {
        if (apiRequestCallId === this.addAppointmentApiCallId) {
            this.addAppointmentFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCatalogueApiCallId) {
            this.getCatalogueFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.stripePaymentApiCallId) {
            this.postStripeFailureCallBack(responseJson, errorReponse);
        }
        if (apiRequestCallId === this.ccAvenueintegrationApiCallId) {
            this.postCCavenueFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.appointmentValidationApiCallId) {
            this.postAppointmentValidationFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getServiceProviderAvailabilityApiCallId) {
            this.getServiceProviderAvailabilityFailureCallBack(responseJson);
        }
    };

    addAppointmentSuccessCallBack = (responseJson: BookingDataInterface1) => {
        let responseJsonData = responseJson.data;
        this.state.serviceProvider?.attributes?.full_name ? this.getServiceProviderAvailability(this.state.token) : this.getAppointmentList(this.state.token);
        if (this.state.paymentOption == "pay_in_person") {
            mixpanel.track(configJSON.appointmentSuccessPayInPerson);
            this.setState({ bookingData: responseJsonData })
        } else {
            this.setState({ appointmentData: responseJsonData, isOpenStripeModal: false })
        }
        this.setState({
            bookingData: responseJsonData
        }, () => this.popupWin?.close());

        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    }

    addAppointmentFailureCallBack = (errorResponseJson: AddAppointmentError) => {
        mixpanel.track(errorResponseJson.errors);
        this.setState({ slotBookingErrorMessage: errorResponseJson.errors[0] })
    }

    getAppointmentListSuccessCallBack = (responseJson: GetAppointmentListData) => {
        this.setState({
            appointmentsAvaibilityId: responseJson.data.id,
            appointmentsList: responseJson.data.attributes.time_slots,
            timeZone: responseJson.meta.time_zone_short,
            timeZoneOffset: this.textToUtcOffsetMinutes(responseJson.meta.time_zone)
        }, () => {
            const currentDate: any = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
            if(this.state.initialLoading){
                this.setState({
                    selectedDate: currentDate._d
                })
            }
            this.setState({
                isDataLenghtCheck: false,
                isLoading: false,
                initialLoading: false
            })
        });
    }

    getServiceProvidersListSuccess = (responseJson: any) => {
        this.setState({ serviceProviderList: [...this.state.serviceProviderList, ...responseJson.data] });
    }

    getAppointmentListMessageSuccessCallBack = (responseJson: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [] }, () => {
            this.setState({
                isLoading: false,
                initialLoading: false,
                isDataLenghtCheck: this.state.appointmentsList.length == 0 ? true : false
            })
        })
    }

    getAppointmentListFailureCallBack = (errorResponse: GetAppointmentListFailureData) => {
        mixpanel.track(errorResponse.message);
        this.setState({ appointmentsList: [], timeZone: "" }, () => this.setState({ isLoading: false, initialLoading: false,  isDataLenghtCheck: this.state.appointmentsList.length == 0 ? true : false }));
    }

    getCatalogueSuccessCallBack = (responseJson: CatalgoueResponseData) => {
        this.handleGetCatalgoueResponse(responseJson);
    }

    getCatalogueFailureCallBack = (errorReponse: CatalgoueResponseData) => {
        this.parseApiCatchErrorResponse(errorReponse);
    }

    postStripeSuccessCallBack = (responseJson: StripeSuccessData) => {
        mixpanel.track(configJSON.appointmentSuccessStripe);
        this.handlePostStripeResponse(responseJson);
    }

    postStripeFailureCallBack = (responseJson: StripeErrorData, errorReponse: StripeErrorData) => {
        mixpanel.track(responseJson.errors);
        this.parseApiCatchErrorResponse(errorReponse);
        this.handleErrorResponse(responseJson.errors);
    }

    postCCavenueSuccessCallBack = (responseJson: CcAvenueEncryptedData) => {
        this.handleCcrevenueIntegrationResponse(responseJson);
    }

    postCCavenueFailureCallBack = (errorReponse: CcAvenueError) => {
        mixpanel.track(errorReponse.errors);
        this.setState({ ccAvenueErrorMessage: errorReponse.errors[0].ccavenue_payment });
    }

    postAppointmentValidationSuccessCallBack = (responseJson: AppointmentValidationSuccessData) => {
        this.handleAppointmentValidationMessage(responseJson);
    }

    postAppointmentValidationFailureCallBack = (errorReponse: AppointmentValidationFailureData) => {
        mixpanel.track(errorReponse.errors);
        this.handleAppointmentFaliureMessage(errorReponse);
    }

    handleAppointmentValidationMessage = async (responseJson: any) => {
        if(responseJson?.message === 'Appointment is valid.')
        {
            this.setState({ appointmentValidationMsg: responseJson.message, orderId: responseJson.order_id })
        } else {
            this.setState({ orderId: responseJson?.data?.attributes?.order_id })
        }
        const { personalDetailsData } = this.state;
        if (responseJson.data || this.state.appointmentValidationMsg == "Appointment is valid.") {
            if (personalDetailsData) {
                this.setState({ openDialog: false })
                if (this.state.paymentOption == "pay_in_person") {
                    this.addappointmentApi();
                } else {
                    if (this.state.currency == "INR") {
                        StorageProvider.set("BookingData", JSON.stringify(responseJson?.data));
                        StorageProvider.set("Service", JSON.stringify(this.state.service));
                        StorageProvider.set("CommonSetting", JSON.stringify(this.state.commonSetting));
                        this.postCcavenuePayment();
                        mixpanel.track(configJSON.appointmentCceavenuePayment);
                    } else {
                        mixpanel.track(configJSON.appointmentStripePayment);
                        this.setState({ isOpenStripeModal: true , isPayment: true, bookingData: responseJson?.data });
                    }
                }
            } else {
                this.showAlert("Error", "Something went wrong");
            }
        }
    }

    handleAppointmentFaliureMessage = (errorReponse: AppointmentValidationFailureData) => {
        mixpanel.track(errorReponse.errors);
        this.setState({ slotBookingErrorMessage: errorReponse.errors[0] });
    }

    handleErrorResponse = (errorResponse: Array<StripeErrorResponse>) => {
        alert(errorResponse[0].stripe)
    }

    textToUtcOffsetMinutes(text: string) {
        const offsetRegex = /UTC([+-]\d+(:\d+)?)/;
        const offsetMatch = (text || "").match(offsetRegex);
        if (!offsetMatch) {
            return 0;
        }
        const offsetStr = offsetMatch[1];

        const offsetParts = offsetStr.split(':');
        const hours = parseInt(offsetParts[0]);
        const minutes = parseInt(offsetParts[1] || "0");
        const totalMinutes = hours * 60 + minutes;
        return totalMinutes
    }

    addAppointment = () => {
        const {
            serviceId,
            personalDetailsData,
            country,
            city,
            addr1,
            selectedSlot,
            paymentOption,
            selectedDate
        } = this.state;
        if (personalDetailsData && serviceId && country && addr1 && city && selectedDate instanceof Date) {
            const month = selectedDate.getMonth() + 1;
            const date = selectedDate.getDate();
            const year = selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
            let paymentMethod;

            if (paymentOption === "pay_in_person") {
                paymentMethod = "pay_later";
            } else if (paymentOption === "pay_online") {
                paymentMethod = "pay_now";
            } else {
                paymentMethod = "";
            }

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const attrs = {
                appointment: {
                    order_id: this.state.orderId,
                    time_slot_id: selectedSlot.id,
                    catalogue_id: serviceId,
                    appointment_date: formattedDate,
                    payment_mode: paymentMethod,
                    service_provider_id: this.state.serviceProvider.id,
                    is_promocode_applied: this.state.isPromoCodeAppiled,
                    promo_code: this.state.promoCode,
                    personal_detail_attributes: {
                        full_name: this.state.name,
                        full_phone_number: this.state.phoneNumber,
                        email: this.state.email,
                        comment: this.state.comments
                    },
                    billing_address_attributes: {
                        country: this.state.country,
                        city: this.state.city,
                        state: this.state.stateName,
                        address_line_2: this.state.addr2,
                        address_line_1: this.state.addr1,
                        zip_code: this.state.zip_code,
                        flat_number: this.state.houseNo
                    }
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addAppointmentApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addAppointmentAPiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addappointmentAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    
    addappointmentApi = async () => {
        const { serviceId, selectedSlot, paymentOption, selectedDate } = this.state;

        if (paymentOption === "pay_in_person" && selectedDate instanceof Date) {

            const month = selectedDate.getMonth() + 1;
            const date = selectedDate.getDate();
            const year = selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            let paymentMethod;
            const itemId = await StorageProvider.get("catalogueId");
            if (paymentOption === "pay_in_person") {
                paymentMethod = "pay_later";
                this.setState({isPayment: false});
                if (itemId) {
                    mixpanel.track(configJSON.appointmentCreatePayInPerson, { itemId });
                }
            } else if (paymentOption === "pay_online") {
                paymentMethod = "pay_now";
                this.setState({isPayment: true});
                if (itemId) {
                    mixpanel.track(configJSON.appointmentCreatePayNow, { itemId });
                }
            } else {
                paymentMethod = "";
            }

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const attrs = {
                appointment: {
                    order_id: this.state.orderId,
                    time_slot_id: selectedSlot.id,
                    catalogue_id: serviceId,
                    appointment_date: formattedDate,
                    payment_mode: paymentMethod,
                    service_provider_id: this.state.serviceProvider.id,
                    is_promocode_applied: this.state.isPromoCodeAppiled,
                    promo_code: this.state.promoCode,
                    personal_detail_attributes: {
                        full_name: this.state.name,
                        full_phone_number: this.state.phoneNumber,
                        email: this.state.email,
                        comment: this.state.comments
                    },
                    billing_address_attributes: {
                        country: this.state.country,
                        city: this.state.city,
                        state: this.state.stateName,
                        address_line_2: this.state.addr2,
                        address_line_1: this.state.addr1,
                        zip_code: this.state.zip_code,
                        flat_number: this.state.houseNo
                    }
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addAppointmentApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addAppointmentAPiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addappointmentAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);

        } else {
            this.appointmentValidationAPI();
        }
    }

    appointmentValidationAPI = async() => {
        const { serviceId, personalDetailsData, selectedSlot, paymentOption, selectedDate } = this.state;
        if (personalDetailsData && serviceId && selectedDate instanceof Date) {
            const month = selectedDate.getMonth() + 1;
            const date = selectedDate.getDate();
            const year = selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
            let paymentMethod;

            const itemId = await StorageProvider.get("catalogueId");
            if (paymentOption === "pay_in_person") {
                paymentMethod = "pay_later";
                if (itemId) {
                    mixpanel.track(configJSON.appointmentCreatePayInPerson, { itemId });
                }
            } else if (paymentOption === "pay_online") {
                paymentMethod = "pay_now";
                if (itemId) {
                    mixpanel.track(configJSON.appointmentCreatePayNow, { itemId });
                }
            } else {
                paymentMethod = "";
            }

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const serviceProviderId = this.state?.serviceProvider?.id ? this.state?.serviceProvider?.id : "";
            const attrs = {
                appointment: {
                    time_slot_id: selectedSlot.id,
                    service_provider_id: this.state.selectedServiceProvider?.id !== 0 ? this.state.selectedServiceProvider.id : serviceProviderId,
                    catalogue_id: serviceId,
                    appointment_date: formattedDate,
                    payment_mode: paymentMethod,
                    is_promocode_applied: this.state.isPromoCodeAppiled,
                    promo_code: this.state.promoCode,
                    personal_detail_attributes: {
                        full_name: this.state.name,
                        full_phone_number: this.state.phoneNumber,
                        email: this.state.email,
                        comment: this.state.comments
                    },
                    billing_address_attributes: {
                        country: this.state.country,
                        city: this.state.city,
                        state: this.state.stateName,
                        address_line_2: this.state.addr2,
                        address_line_1: this.state.addr1,
                        zip_code: this.state.zip_code,
                        flat_number: this.state.houseNo
                    }
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.appointmentValidationApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addAppointmentAPiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.appointmentValidationAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    postCcavenuePayment = () => {
        const amount1 =  this.state.afterPromoCodePrice
        const amount = this.state.price ? this.state.price : this.state.selectedData.total;
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
            token: this.state.token,
        };

        const attrs = {
            data:
            {
                order_id: this.state.orderId,
                amount: this.state.afterPromoCodePrice ? amount1 : amount ,
                currency: "INR",
                redirect_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integration/encrypts_and_decrypts/payment_confirmation`,
                cancel_url: `${configJSONUrl.baseURL}/bx_block_ccavenue_integration/encrypts_and_decrypts/payment_confirmation`,
                language: "EN",
                billing_name: this.state.name,
                billing_address: this.state.addr1,
                billing_city: this.state.city,
                billing_state: this.state.stateName,
                billing_zip: this.state.zip_code,
                billing_country: this.state.country,
                billing_tel: this.state.phoneNumber,
                billing_email: this.state.email,
                customer_identifier: "",
                promo_code: "",
                integration_type: "iframe_normal"
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ccAvenueintegrationApiCallId = requestMessage.messageId;

        const httpBody = {
            ...attrs,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addAppointmentAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dataEncryptionEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    isStringNullOrBlank(strings: string | null | undefined) {
        if (strings === null || strings === undefined) {
            return true;
        }
        if (typeof strings !== "string") {
            return true;
        }
        return strings.trim() === "";
    }

    getAppointmentList = async (token: string) => {
        const catalogueId = await StorageProvider.get("catalogueId")
        if (this.state.selectedDate && catalogueId) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getAppointmentsListApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.appointmentAPiEndPoint}?catalogue_id=${catalogueId}&availability_date=${formattedDate}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    getServiceProvidersList = async (token: string) => {
        const catalogueId = await StorageProvider.get("catalogueId")
        if (catalogueId) {
            const header = {
                "Content-Type": configJSON.serviceProvidersApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getServiceProvidersListApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.serviceProvidersApiEndPoint}?id=${catalogueId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getServiceProvidersListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    getCatalgoue = async () => {
        const catalogueId = await StorageProvider.get("catalogueId");
        if (catalogueId) {
            this.setState({ serviceId: catalogueId });
            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getCatalogueApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.catalogueAPIEndPoint}/${catalogueId}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    handleGetCatalgoueResponse = (responseJson: CatalgoueResponseData) => {
        let responseJsonData = responseJson.data;
        if (responseJsonData.attributes.status == false) {
            this.props.navigation.navigate("Services")
        }
        const currentDate : any = moment().add(new Date().getTimezoneOffset() + this.textToUtcOffsetMinutes(responseJsonData.attributes.time_zone), "m")
        this.setState({
            service: parseCatalogue(responseJsonData),
            currency: responseJsonData.attributes.currency.name,
            price: responseJsonData.attributes.price,
            isPriceLoading: false,
            appointmentType: responseJsonData.type,
            selectedDate: currentDate?._d,
            paymentPreference: responseJson.data.attributes.payment_preferences
        }, () => {
            this.state.serviceProvider?.attributes?.full_name ? this.getServiceProviderAvailability(this.state.token) : this.getAppointmentList(this.state.token); this.getServiceProvidersList(this.state.token)
        });
    };

    handlePostStripeResponse = (responseJson: { meta: StripesuccessRes }) => {
        this.setState({
            stripeSuccessData: responseJson.meta,
            isOpenStripeModal: false,
        }, () => {
            this.setState({ stripeState: true, }, () => {
                setTimeout(() => {
                    this.setState({ stripeState: false })
                }, 2000)
                    window.scrollTo({top:0, behavior:"smooth"})
            });
        })
    }
    handleCcrevenueIntegrationResponse = (responseJson: CcAvenueEncryptedData) => {
        this.setState({
            ccavenueModalShowRespons: responseJson,
        }, () => {
            window.location.href = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${responseJson.access_code}&encRequest=${responseJson.enc_resp}`
        })
    };

    toogleSelect = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }

    paymentFailurSetPros=()=>{
        mixpanel.track(configJSON.appointmentFailStripe);
        this.setState({
            activefailure:true,
            isPayment: false,
            bookingData: {
                id: "",
                type: "",
                attributes: {
                    order_id: "",
                    catalogue_id: NaN,
                    payment_mode: "",
                    total: NaN,
                    status: "",
                    duration: NaN,
                    currency: null,
                    appointment_date: "",
                    order_date: "",
                    is_promocode_applied: false,
                    personal_detail: {
                        id: NaN,
                        full_phone_number: "",
                        email: "",
                        full_name: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN,
                        comment: ""
                    },
                    service_provider: {
                        id: NaN,
                        full_name: '',
                        phone_number: '',
                        email: '',
                        designation: "",
                        image: ''
                    },
                    billing_address: {
                        id: NaN,
                        country: "",
                        city: "",
                        zip_code: "",
                        state: "",
                        address_line_1: "",
                        address_line_2: "",
                        flat_number: "",
                        created_at: "",
                        updated_at: "",
                        appointment_id: NaN
                    },
                    time_slot: {
                        slot_start_time: "",
                        slot_end_time: ""
                    },
                    timezone: "",
                    time_zone_short: "",
                    service: {
                        title: "",
                        price: NaN,
                        duration: NaN,
                        discount_price: NaN,
                        discount_option: false,
                        images: "",
                        thumbnail_url:"",
                        small_url:""
                    }
                }
            }
        })
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    }

    paymentSuccessSetProps =() => {
        mixpanel.track(configJSON.appointmentSuccessStripe);
        this.setState({
            activefailure:false,
            isPayment: false,
        })
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    }

    focusToPopup = () => {
        this.popupWin?.focus();
    };

    handelAvaibilityTimeSlot = (slot: AppointmentListData) => {
        this.setState({
            selectedSlot: slot,
            isDisabled: false,
            isSlotSelected: true
        });
    }

    handelProceedFromDateTime = async (step: number, commingFrom: string) => {
        step === 0 && commingFrom === "serviceProvider" && mixpanel.track(configJSON.appointmentProceedFromStaffService);
        if (this.state.isSlotSelected) {
            this.setState({isDisabled: false});
        }
        this.state.serviceProvider?.attributes?.full_name && this.getServiceProviderAvailability(this.state.token);
        this.setState({isDisabled: this.state.isSlotSelected ? false : true,expanded:false});
        const { selectedDate, service, selectedSlot, timeZone } = this.state;
        if (selectedSlot) {
            const itemId = await StorageProvider.get("catalogueId");
            if (itemId && commingFrom === "service") {
                mixpanel.track(configJSON.appointmentProceedFromDateTime, { itemId });
            }
            this.setState((prevState) => ({
                activeStep: prevState.activeStep + 1,
                timeZone: timeZone,
                personalDetailsData: {
                    selectedDate,
                    selectedSlot,
                    service,
                    timeZone
                }
            }));
        } else {
            this.showAlert("Error", "No slot selected");
        }
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
       
    };

    handleProceedToPersonalDetails = async () => {
        this.setState({ expanded:false })
        const { personalDetailsData } = this.state;
        try {
            this.AppointmentSchema.validateSync(this.state, { abortEarly: false });

            const itemId = await StorageProvider.get("catalogueId");
            if (itemId) {
                mixpanel.track(configJSON.appointmentProceedToPersonalDetails, { itemId });
            }
            let paymentOption = "pay_in_person";
            if ((this.state.service || this.state.serviceProvider) && ["pay_online_or_in_person", "pay_online"].includes(this.state.paymentPreference)) {
                paymentOption = "pay_online";
            }
            this.handleProceedToPersonalDetailsInnerFun(personalDetailsData, paymentOption);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors: Record<string, string> = {};
                error.inner.forEach((error) => {
                    errors[error.path as string] = error.message;
                })
                this.setState({ errors })
            }
        }
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    }

    handleProceedToPersonalDetailsInnerFun = (personalDetailsData: PersonalDetailsResponse, paymentOption: string) => {
        if (personalDetailsData) {
            this.setState({
                paymentOption,
                activeStep: this.state.activeStep + 1,
                errors: {}
            });
        } else {
            this.showAlert("Error", "Something went wrong");
        }
    }

    handleProceedToPayment = () => {
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
        this.appointmentValidationAPI();
        
    }

    handleBack = () => {
        this.setState((prevState) => ({
            isDisabled: false,
            activeStep: prevState.activeStep - 1,
            expanded:false
        }))
    };

    handleBacks= async() => {
        const bookingData = JSON.parse(await StorageProvider.get("BookingData"));
        const personal_detail = bookingData?.attributes?.personal_detail;
        const billing_address = bookingData?.attributes?.billing_address;
        const service = bookingData?.attributes?.service;
        const time_slot = bookingData?.attributes?.time_slot;

        const navigation = new Message(getName(MessageEnum.NavigationMessage));
            navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Appointments");
            navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigation);
        if(bookingData) {
            this.setState({
                isDisabled: false,
                activeStep: 2,
                expanded:false,
                activefailure:false,
                
                selectedSlot: {
                    id: time_slot.id,
                    ...time_slot
                },
                personalDetailsData: {
                    selectedDate: bookingData.attributes.appointment_date,
                    selectedSlot: {
                        id: time_slot.id,
                        ...time_slot
                    },
                    service:{
                        title: service.title,
                        undiscountedPrice: service.price,
                        duration: service.duration,
                        discountedPrice: service.discount_price,
                        hasDiscount: service.discount_option,
                        ...service
                    },
                    timeZone: bookingData.attributes.timezone
                },
                selectedServiceProvider: bookingData.attributes.service_provider ? {...bookingData.attributes.service_provider} : {},
                serviceId: bookingData.attributes.catalogue_id,
                paymentOption: "pay_online",
                name: personal_detail.full_name,
                phoneNumber: personal_detail.full_phone_number,
                email: personal_detail.email,
                comments: personal_detail.comment,
                country: billing_address.country,
                city: billing_address.city,
                stateName: billing_address.state,
                addr2: billing_address.address_line_2,
                addr1: billing_address.address_line_1,
                zip_code: billing_address.zip_code,
                houseNo: billing_address.flat_number
            });
        }
        else {
            this.setState((prevState) => ({
                isDisabled: false,
                activeStep: prevState.activeStep - 0,
                expanded:false,
                activefailure:false
            }));
        }
    };

    btnBackProps = () => {
        this.props.navigation.goBack();
    };

    handlePaymentOptionChange = (event: { target: { value: string; }; }) => {
        this.setState({
            paymentOption: event.target.value
        });
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
        });
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    };

    closeAppointmentDialog = () => {
        this.setState({
            activeStep: 0,
            slotBookingErrorMessage: "",
            ccAvenueErrorMessage: "",
            isDisabled: false
        })
    }

    onCloseModal = () => {
        this.setState({
            isOpenStripeModal: false,
        },()=>{
            window.scrollTo({top:0, behavior:"smooth"})  
        })    
           
    }

    handleResize() {
        this.setState({ screenSize: window.innerWidth });
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleExpandPolicies = () => {
        this.setState({ expandedPolicies: !this.state.expandedPolicies });
    };

    dateIsDisabled = (item: { date: Date, view: string }) => {
        let { date, view } = item;
        const currentDate: object = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
        return view === "month" && date < currentDate && !moment(date).isSame(currentDate, "day");
    };

    convertTimeFormat(timeString: string): string {
        return moment(timeString, "HH:mm").format("h:mm A");
    }

    formatDate = (dateStr: Date | string | null) => {
        const newDate = moment(dateStr, "DD-MM-YYYY").format("DD-MM-YYYY");
        const [date_day, month, year] = newDate.split("-");
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const date = new Date(Number(year), Number(month) - 1, Number(date_day));

        const daySuffix = (number: number) => {
            if (number >= 11 && number <= 13) {
                return "th";
            }
            switch (number % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        const formattedDate = `${date.toLocaleString("en-US", { weekday: "long" })}, ${date.getDate()}${daySuffix(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate;
    }

    handleDateChange = async (date: Value | null) => {
        this.setState({
            selectedDate: new Date(String(date)),
            isLoading: true
        }, () => this.state.serviceProvider?.attributes?.full_name || this.state.selectedServiceProvider.id !== 0 ? this.getServiceProviderAvailability(this.state.token) : this.getAppointmentList(this.state.token));
    };

    handleChange = (field: keyof Values) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        const characterCount = field === "comments" ? value.length : this.state.characterCount;
        try {
            const fieldValues: Partial<Values> = {
                [field]: value,
            };
            this.AppointmentSchema.validateSyncAt(field, fieldValues as Values);
            this.setState((prevState) => ({
                ...prevState,
                [field]: value,
                characterCount: characterCount,
                personalDetailsData: this.state.personalDetailsData,
                errors: { ...prevState.errors, [field]: "" },
            }));
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const _error = error as Yup.ValidationError;
                this.setState((prevState) => ({
                    ...prevState,
                    [field]: value,
                    characterCount: characterCount,
                    errors: { ...prevState.errors, [field]: _error.message },
                }));
            }
        }
    };

    handlePaymentChange = (field: keyof Address) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        if (field === "country" || field === "city" || field === "addr1" || field === "zip_code") {
            try {
                const fieldValues: Partial<Address> = {
                    [field]: value,
                };
                this.AddressSchema.validateSyncAt(field, fieldValues as Address);
                this.setState((prevState) => ({
                    ...prevState,
                    [field]: value,
                    addressError: { ...prevState.addressError, [field]: "" },
                }));
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    this.setState((prevState) => ({
                        ...prevState,
                        [field]: value,
                        isDisabled: true,
                        addressError: { ...prevState.addressError, [field]: (error as { message: string }).message },
                    }));
                }
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [field]: value,
                personalDetailsData: { ...this.state.personalDetailsData, [field]: value },
                addressError: { ...prevState.addressError, [field]: "" },
            }));
        }
    };

    AppointmentSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required")
            .min(4, "Name must be minimum 4 characters")
            .max(50, "Name must be maximum 50 characters")
            .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
        email: Yup.string().required("Email is required.").matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Please enter valid email"),
        phoneNumber: Yup.string()
            .required("Mobile Number is required")
            .matches(new RegExp("^[0-9]{9,13}$"), "Mobile Number is invalid"),
        comments: Yup.string()
            .max(300, "Comments must be maximum 300 characters"),
    });

    AddressSchema = Yup.object().shape({
        country: Yup.string()
            .required("Country is required")
            .min(3, "Country must be minimum 4 characters")
            .max(50, "Country must be maximum 50 characters")
            .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
        city: Yup.string().required("City is required.").matches(/^[a-zA-Z\s]+$/, "City should contain only alphabets"),
        addr1: Yup.string().required("Address line 1 is required"),
        zip_code: Yup.string()
            .required("Zip code is required")
    });

    handleOpenDialog = () => {
        const { country, addr1, city, zip_code, stateName } = this.state;

        if (this.state.paymentOption == "pay_in_person") {
            this.setState({
                openDialog: true, addressError: {}
            });
        } else if (!country || !addr1 || !city || !zip_code || !stateName) {
            this.setState({
                addressError: {
                    country: !country && "Country is required",
                    addr1: !addr1 && "Address line 1 is required",
                    city: !city && "City is required",
                    zip_code: !zip_code && "Zip code is required",
                    stateName: !stateName && "State is required",
                }
            })
        } else {
            this.setState({
                openDialog: true, addressError: {}
            });
        }
    };

    handlePayonlineRadioBtn = () => { this.setState({ paymentOption: "pay_online" }) }

    handlePayAtLocationRadioBtn = () => { this.setState({ paymentOption: "pay_in_person" }) }

    getServiceProvider = (token: string, providerId: number) => {
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getServiceProviderApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.serviceProvider}${providerId}/${configJSON.serviceProviderEndpint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAppointmentListAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getServiceProviderSuccessCallBack = (responseJson: ServiceData) => {
        const responseData = responseJson.data.attributes.categories;
        let serviceProviderData = responseData[0]?.services[0];
        if(this.state.selectedServiceProvider.id !== 0)
        {
            const filteredCategory = responseData.filter(category => category.name === this.state.service.category.name)
            const filterService = filteredCategory[0].services.filter(service => service.id == this.state.serviceId)
            serviceProviderData = filterService[0];
        }
        this.setState({
            serviceProvider: responseJson.data,
            categoriesData: responseData,
            selectedValue: serviceProviderData.title,
            selectedData: serviceProviderData,
            currency: serviceProviderData.currency.name,
            isPriceLoading: false,
            isDisabled: ((responseData.length === 1 && responseData[0]?.service_count === 1) || this.state.selectedServiceProvider.id !== 0) ? true : false,
            serviceId: serviceProviderData.id,
            imageStaffMember: responseJson.data.attributes.image.url,
            paymentPreference: serviceProviderData.payment_preferences
        },() => {
            this.getServiceProviderAvailability(this.state.token)
        })
    };

    getServiceProviderAvailability = (token: string) => {
        this.setState({ serviceId: this.state.selectedData.id, isLoading: true });
        if (this.state.selectedDate && this.state.selectedData.id) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: token,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getServiceProviderAvailabilityApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.serviceProviderAvailabilityEndPoint}?catalogue_id=${this.state.selectedData.id}&availability_date=${formattedDate}&service_provider_id=${this.state.serviceProvider?.id || this.state.selectedServiceProvider.id}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    getServiceProviderAvailabilitySuccessCallBack = (responseJson: ServiceProviderData) => {
        this.setState({
            appointmentsAvaibilityId: responseJson.data.id,
            appointmentsList: responseJson.data.attributes.time_slots,
            timeZone: responseJson.meta.time_zone_short,
            timeZoneOffset: this.textToUtcOffsetMinutes(responseJson.meta.time_zone)
        }, () => {
            const currentDate: any = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
            if (this.state.initialLoading) {
                this.setState({
                    selectedDate: currentDate._d
                })
            }
            this.setState({
                isDataLenghtCheck: false,
                isLoading: false,
                initialLoading: false
            })
        });
    }

    getServiceProviderAvailabilityFailureCallBack = (errorResponse: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [], timeZone: "" }, () => this.setState({ isLoading: false, initialLoading: false }));
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };

    handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        this.setState({
            selectedValue: event.target.value,
            selectedIndex: index,
            selectedData: this.state.categoriesData[this.state.value]?.services[index],
            serviceId: this.state.categoriesData[this.state.value]?.services[index].id,
            paymentPreference: this.state.categoriesData[this.state.value]?.services[index].payment_preferences
        });
    };

    handleEditServiceNavigation = () => {
        this.setState({isDisabled: false});
        const { activeStep } = this.state;
        if (activeStep == 1 || activeStep == 2) {
            this.setState({
                activeStep: 0,
            });
        } else {
            this.btnBackProps();
        }
    };

    handleServiceNavigation = () => {
        this.setState({
            activeStep: 0,
            isDisabled: false,
        });
    };

    handleDateAndTimeNavigation = () => {
        const { categoriesData } = this.state;
        const activeStep = (categoriesData.length == 1 && categoriesData[0].services.length == 1) ? 0 : 1;
        this.setState({
            isDisabled: false,
            activeStep: activeStep,
        });
    };

    handlePersonalDetailNavigation = () => {
        const { categoriesData } = this.state;
        const activeStep = (categoriesData.length == 1 && categoriesData[0].services.length == 1) ? 1 : 2;
        this.setState({
            activeStep: activeStep,
        });
    };
    
    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, indexNum: number) => {
        this.setState({
            selectedValue: event.target.value,
            selectedIndex: index,
            selectedData: this.state.categoriesData[indexNum]?.services[index],
            serviceId: this.state.categoriesData[indexNum]?.services[index].id,
            paymentPreference:this.state.categoriesData[indexNum]?.services[index].payment_preferences
        });
    };

    getCommonSettingData = (token: string) => {
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCommonSettingApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.commonSettingEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAppointmentListAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCommonSettingSuccessCallBack = (responseJson: CommonSetting) => {
       this.setState({commonSetting: responseJson.data})
    };

    handleServiceProviderChange = (serviceProvider: ServiceProviderList) => {
        if(this.state.selectedServiceProvider.id !== serviceProvider.id)
        {
            if(serviceProvider.id !== 0)
            {
                mixpanel.track(configJSON.selectServiceProviderEvent, { staff_id: serviceProvider?.id })
                this.setState({isLoading: true, isDisabled: true, selectedSlot: {} as AppointmentListData},() => {
                    this.getServiceProvider(this.state.token, serviceProvider?.id);
                })
            } else {
                this.setState({selectedData: {} as Services, serviceProvider: {} as ServiceProvider, isLoading: true, isDisabled: true, selectedSlot: {} as AppointmentListData} , () => {
                    this.getAppointmentList(this.state.token)
                })
            }
        }
        this.setState({ selectedServiceProvider : serviceProvider, isModalOpen: false })
    }
     
    handleTotalPrice = (total: number) => {
        this.setState({afterPromoCodePrice: total});
    };

    handleIsPromoAppled = (item: boolean) => {
        this.setState({isPromoCodeAppiled: item});
    };

    handlePromoCode = (item: string) => {
        this.setState({promoCode: item});
    };
    
    // Customizable Area End
}
