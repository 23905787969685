import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { ChangeEvent } from "react";
// Customizable Area Start
import { isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
const navigation = require("react-navigation");

export interface ContactUsArray {
  id: string;
  type: string;
  attributes: {
    name: string;
    email: string;
    subject: string;
    phone_number: string;
    description: string;
    created_at: string;
    country_code: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  identifier: string;
  // Customizable Area Start
  classes: {
    select: string,
    phoneBox: string,
  };
  // Customizable Area End
}

export interface Values {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
  countryCode: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  subject: string;
  errors: Partial<Values>;
  token: string;
  showSuccessMessageDialog: boolean;
  loading: boolean;
  countryCode: string,
  // Customizable Area End
}

interface SS {
  identifier: string;
}

export default class AddContactController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addContactApiCallId: string | undefined;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      subject: "",
      errors: {},
      showSuccessMessageDialog: false,
      token: "",
      loading: true,
      countryCode: "+91",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }

    const messageID = message.id;
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    runEngine.debugLog("API Message Received", message);

    if (messageID === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (messageID === getName(MessageEnum.RestAPIResponceMessage)) {
      if (apiRequestCallId === this.addContactApiCallId) {
            this.handleAddContactAPIResponse()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toNavigate.messageId, toNavigate);
  };

  handleAddContactAPIResponse() {
    this.setState({
      showSuccessMessageDialog: true,
      name: "",
      email: "",
      phoneNumber: "",
      countryCode: "+91",
      subject: "",
      message: "",
      loading: false,
    });
  }

  addQueryApi = () => {
    let response = {
      data: {
        name: this.state.name.trim(),
        email: this.state.email.trim(),
        phone_number: this.state.phoneNumber.trim(),
        country_code: this.state.countryCode.trim(),
        subject: this.state.subject.trim(),
        description: this.state.message.trim()
      },
    };
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(response)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  hideDialog = () => this.setState({ showSuccessMessageDialog: false });

  ContactSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(4, "Name must be minimum 4 characters")
      .max(50, "Name must be maximum 50 characters")
      .matches(/^[a-zA-Z\s]+$/, "Name should contain only alphabets"),
    email: Yup.string().required("Email is required.").email("Please enter valid email"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(7, "Please enter valid phone number")
      .max(10, "Please enter valid phone number"),
    countryCode: Yup.string(),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string()
      .required("Message is required")
      .min(8, "Message must be minimum 8 characters")
  });

  handleChange = (field: keyof Values) => (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    const { value } = event.target;

    try {
      const fieldValues: Partial<Values> = {
        [field]: value,
      };
      this.ContactSchema.validateSyncAt(field, fieldValues as Values);
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: { ...prevState.errors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessage = error?.message;
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
          errors: { ...prevState.errors, [field]: errorMessage },
        }));
      }
    }
  };

  handleSubmit = () => {
    const { ...prevState } = this.state;
    let formSubmit = true;

    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<Values> = {
          [field]: prevState[field as keyof Values],
        };
        this.ContactSchema.validateSyncAt(field, fieldValues as Values);
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: "" },
        }));
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errorMessage = error.message;
          formSubmit = false;
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, [field]: errorMessage },
          }));
        }
      }
    });

    if (formSubmit) {
      this.addQueryApi();
    }
  };
  // Customizable Area End
}
