// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Category } from "../../utilities/src/models/Category";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import {
  parseCatalogue,
  parseCategory,
} from "../../utilities/src/helpers/utils";
import { Availability } from "../../utilities/src/models/Availability";
import { Service } from "../../utilities/src/models/Service";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  categories: Category[];
  availabilityErrors: string[];
  serviceId: number | null;
  service: Service | null;
  isSaving: boolean;
}

interface SS {
  id: any;
}

export default class ServicemanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllCategoriesApiCallId: any;
  validateAvailabilityApiCallId: any;
  upsertCatalogueApiCallId: any;
  getCatalogueApiCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      categories: [],
      availabilityErrors: [],
      serviceId: null,
      service: null,
      isSaving: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const serviceId = this.props.navigation.getParam("id");
    if (serviceId) {
      this.getCatalogue(serviceId);
      this.setState({ serviceId });
    }
    this.getAllCategories();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCategoriesApiCallId != null &&
      this.getAllCategoriesApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetAllCategories(responseJson);
      } else {
        this.setState({ categories: [] });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validateAvailabilityApiCallId != null &&
      this.validateAvailabilityApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.message) {
        this.setState({ availabilityErrors: [] });
      } else {
        this.setState({ availabilityErrors: responseJson.errors });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.upsertCatalogueApiCallId != null &&
      this.upsertCatalogueApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.props.navigation.navigate("ServicesManagement");
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetCatalgoueResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  handleGetAllCategories = (responseJson: any) => {
    const categories: Category[] = responseJson.data.map(
      (response: any) => {
        const category = parseCategory(response, false);
        return category;
      }
    );

    this.setState({
      categories,
    });
  };

  handleGetCatalgoueResponse = (responseJson: any) => {
    const response = responseJson.data;

    this.setState({
      service: parseCatalogue(response),
    });
  };

  getAllCategories = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getAllCategoriesApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getCategoriesEndPoint + "?show_all=true",
      header,
    });
  };

  validateAvailability = (duration: number, availability: Availability[]) => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const body = {
      data: {
        duration,
        availabilityData: availability,
      },
    };
    const itemId = this.props.navigation.getParam("id");
    mixpanel.track("webadmin_service_availability_update_button", {itemId });

    this.validateAvailabilityApiCallId = BlockHelpers.callApi({
      method: configJSON.postMethod,
      endPoint: configJSON.validateAvailabilityEndPoint,
      header,
      body: JSON.stringify(body),
    });
  };

  upsertService = (
    values: any,
    isUpdate: boolean,
    deletedImagesIds?: number[]
  ) => {
    const itemId = this.props.navigation.getParam("id");
    if(itemId){
      mixpanel.track("webadmin_service_update_button", {itemId });
    }else{
      mixpanel.track("webadmin_service_create_button")
    }
    const header = {};

    const body = new FormData();
    body.append("[data][attributes][title]", values.title);
    body.append("[data][attributes][description]", values.description);
    values.images.forEach(
      (image: ServiceImage | File) =>
        image instanceof File &&
        body.append("[data][attributes][images][]", image)
    );
    body.append("[data][attributes][duration]", values.duration);
    body.append("[data][attributes][price]", values.price);
    body.append("[data][attributes][discount_option]", values.hasDiscount);
    body.append("[data][attributes][discount]", values.discount);
    body.append(
      "[data][attributes][payment_preferences]",
      values.paymentPreference
    );
    body.append("[data][attributes][status]", values.status);
    body.append("[data][attributes][category_id]", values.category);
    body.append("[data][attributes][enable_booking_per_slot]", values.enable_booking_per_slot ? values.enable_booking_per_slot : false);
    body.append("[data][attributes][number_of_booking_per_slot]", values.enable_booking_per_slot != false && values.enable_booking_per_slot != undefined ? (values.number_of_booking_per_slot ? values.number_of_booking_per_slot : 2) : "");

    body.append(
      "[data][attributes][availability]",
      JSON.stringify(this.formatAvailability(values.availability))
    );
    deletedImagesIds &&
      deletedImagesIds.length > 0 &&
      body.append(
        "[data][attributes][deleted_image_ids]",
        deletedImagesIds.toString()
      );

    this.upsertCatalogueApiCallId = BlockHelpers.callApi({
      method: isUpdate ? configJSON.patchMethod : configJSON.postMethod,
      endPoint: isUpdate
      ? `${configJSON.upsertCatalogueEndPoint}/${this.state.serviceId}`
      : configJSON.upsertCatalogueEndPoint,
      header,
      body,
    });
    this.setState({isSaving: true});
  };

  formatAvailability = (availability: Availability[]) => {
    return availability.map((data) => {
      const working_day = data.day;
      const working_hours = data.workingHours.map((workingHour) => {
                return {
          start_time: workingHour.openingTime,
          end_time: workingHour.closingTime,
        };
      });
      const is_available = data.selected;

      return {
        working_day,
        working_hours,
        is_available,
      };
    });
  };

  resetAvailabilityErrors = () => {
    this.setState({ availabilityErrors: [] });
  };

  getCatalogue = (catalogueId: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    this.getCatalogueApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getCatalogueEndPoint}/${catalogueId}`,
      header,
    });
  };
}
// Customizable Area End
