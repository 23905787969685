// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import InputFieldWithFormikController from "./InputFieldWithFormikController.web";

export class InputFieldWithFormik extends InputFieldWithFormikController {
  render(): React.ReactNode {
    return (
      <MuiTextField
        {...this.fieldToTextField()}
        {...this.props}
        variant="outlined"
        margin="dense"
        InputLabelProps={{
          ...this.props.InputLabelProps,
          shrink: true,
          classes: {
            asterisk: this.props.classes?.astriklabel,
            outlined: this.props.classes?.outlinedLabel,
            shrink: this.props.classes?.shrink,
            root: this.props.classes?.lableroot,
          },
        }}
        InputProps={{
          ...this.props.InputProps,
          classes: {
            input: this.props.classes?.input,
            root: this.props.classes?.inputfieledroot,
          },
          notched: false,
        }}
      >
        {this.props.children}
      </MuiTextField>
    );
  }
}

export const styles = () =>
  createStyles({
    inputfieledroot: {
      "label + &": {
        marginTop: 30,
      },
    },
    input: {
      borderRadius: "3px",
      position: "relative",
      backgroundColor: "#FFF",
      fontSize: "16px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.19,
      letterSpacing: "normal",
      padding: "0.4rem 0.4rem",
    },
    astriklabel: {
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#000000",
    },
    lableroot: {
      fontSize: "0.75rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.14,
      letterSpacing: "normal",
      color: "#000000",
    },
    outlinedLabel: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
    shrink: {
      transform: "translate(3px, 9px) scale(1)!important",
    },
  });

export default withStyles(styles)(InputFieldWithFormik);

// Customizable Area End