// Customizable Area Start
import React from "react";
import PromocodeController, { configJSON } from "./PromoCodeCustomerController.web";
import { Box, Divider, IconButton, InputAdornment, TextField, Typography, styled } from "@material-ui/core";
import { Right, Wrong } from "../../appointmentmanagement/src/assets";
import Currency from "../../utilities/src/components/Currency.web";
// Customizable Area End

export default class Promocode extends PromocodeController {
    // Customizable Area Start

    render() {
        const {discount_amount, Before_promocode_value} = this.state.promoApplied;
        const {isSuccess, isFailed} = this.state;
        return (
            <>
                <Box style={styles.promoCodeContainer}>
                    <TextFields
                        name="password"
                        type={"text"}
                        value={this.state.promoCode}
                        onChange={this.handlePromocode}
                        autoComplete="off"
                        style={styles.promocodeInp}
                        data-test-id="changePasswordTestId"
                        placeholder="Enter your promotion code"
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        data-test-id="showId"
                                        aria-label="toggle password visibility"
                                        style={styles.promocodeBtn}
                                        onClick={this.getPromoCode}
                                    >
                                        {configJSON.applyText}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {isSuccess &&
                        <>
                            <Box style={styles.statusContainer}>
                                <img src={Right} alt="image loading" />
                                <Typography component={"body"} style={styles.desText}>{configJSON.right}</Typography>
                            </Box>
                            <Box style={styles.discountBox}>
                                <Typography style={styles.discountText}>{configJSON.discount}</Typography>
                                <Currency
                                    text={`${isSuccess ? discount_amount.toFixed(2) : this.props.totalValue}`}
                                    disountPrice={""}
                                    time={""}
                                    hasDiscount={false} 
                                    isPrmocode={true}
                                />
                            </Box>
                        </>
                    }
                    {isFailed &&
                        <Box style={styles.statusContainer}>
                            <img src={Wrong} alt="image loading" />
                            <Typography component={"body"} style={styles.desText}>{this.state.promoFailed.error}</Typography>
                        </Box>
                    }
                
                </Box>
                <Divider style={styles.divider} />
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const TextFields = styled(TextField)({
    border: "1px solid #DFE0E6",
    borderRadius: "3px",
    padding: "11px 5px 11px 15px",
    width: "100%",
    marginBottom: "7px",
    textDecoration: "capatilize" as "capatilize",
    "&.MuiInputBase-input::placeholder": {
        fontSize: "12px !important"
    },
    "&::placeholder": {
        fontSize: "12px !important"
    },
    "& input::placeholder": {
        fontSize: "12px"
      }
});

const styles = {
    divider: {
        borderColor: "#BFBFBF",
        marginBottom: "16px"
    },
    promoCodeContainer: {
        margin: "20px 0px",
    },
    promocodeInp: {
        border: "1px solid #DFE0E6",
        borderRadius: "3px",
        padding: "11px 5px 11px 15px",
        width: "100%",
        marginBottom: "7px",
        textDecoration: "capatilize" as "capatilize",
        "&.MuiInputBase-input::placeholder": {
            fontSize: "12px !important"
        },
        "&::placeholder": {
            fontSize: "12px !important"
        },
        "& input::placeholder": {
            fontSize: "12px"
          }
    },
    promocodeBtn: {
        border: "1px solid #3FC1CB",
        borderRadius: "3px",
        background: "#3FC1CB",
        color: "black",
        fontSize: "14px",
        fontWidth: 400,
        padding: "10px 17px",
        "& :hover": {
            backgroundColor: "#3FC1CB"
        }
    },
    statusContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        "& p": {
            marginLeft: "20px",
            fontSize: "14px",
            fontWidth: 400,
            fontFamily: "Inter",
            color: "#0F172A"
        }
    },
    discountBox: {
        margin: "10px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h6": {
            fontSize: "16px",
            fontWidth: 400,
            fontFamily: "Inter",
            color: "#64748B"
        }
    },
    discountText:{
        fontSize: "16px",
        fontWidth: 700,
        fontFamily: "Inter",
        color: "#64748B"
    },
    desText:{
        marginLeft: "10px",
        fontSize: "14px",
        fontWidth: 400,
        fontFamily: "Inter",
        color: "#0F172A",
         background: "none"
    }
}
// Customizable Area End
