// Customizable Area Start
import React from "react";
import QrCodeController from "./QrCodeController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { ButtonWithDialog } from "../../ss-cms-common-components/src/ButtonWithDialog/ButtonWithDialog";
import "../assets/styles.css";
import { Button } from "@material-ui/core";
import { QRCodeSVG } from "qrcode.react";
import moment from "moment";
import {GenerateQrCodeDialog} from "./GenerateQrCodeDialog.web";

const configJSON = require("./config");

export class QrCode extends QrCodeController {
  render() {
    const qrCodes  = this.state.qrCodes;
      return (
        <div className="qr-codes">
          {!qrCodes.length && this.state.isLoaded ? (
            <div>{configJSON.currentlyThereIsNoQrCodeGenerated}</div>
          ) : (
            <>
              {qrCodes.map((code) => (
                <div className="qr-code-card-wrapper">
                <div className="qr-code-card">
                  <div className="qr-code-image">
                    <QRCodeSVG value={code.url} />
                  </div>
                  <div className="qr-code-type">{code.code_type}</div>
                  <span className="qr-code-title">
                    {code.code_type} {configJSON.qrCode}
                  </span>
                  <div className="qr-code-updated-at">
                    {configJSON.updatedAt} {moment(code.updated_at).format("DD/MM/YY")}
                  </div>
                  <Button
                    className="edit-qr-code-button"
                    variant="outlined"
                    onClick={() => this.showEditQrCodeDialog(code.id)}
                  >
                    {configJSON.editQrCode}
                  </Button>
                  <ButtonWithDialog
                    className="delete-qr-code-button"
                    containerClassName="mobile-app-dialog"
                    okClassName="error-button"
                    title={configJSON.qr_delete_dialog_title}
                    message={configJSON.qr_delete_dialog_msg}
                    okay={configJSON.qr_delete_dialog_confirm}
                    onSubmit={() => this.deleteQrCode(code.id)}
                  >
                    {configJSON.deleteQrCode}
                  </ButtonWithDialog>
                </div>
              </div>
              ))}
            </>
          )}
          <GenerateQrCodeDialog
              open={!!this.props.isDialogOpen}
              dialogOpen={this.props.changeDialogState}
              onSubmit={this.generateQrCode}
              id={this.state.selectedQrCodeId}
              closeDialog={this.hideDialogBox}
              initialValues={this.state.selectedCode}
          />
        </div>
      );
    };
}

type GenerateQrCodeButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export const GenerateQrCodeButton = ({
  onClick,
  disabled,
}: GenerateQrCodeButtonProps) => {
  return (
    <Button
      className="generate-qr-code-button"
      variant="outlined"
      data-testid="generateQr"
      onClick={onClick}
      disabled={disabled}
    >
      {configJSON.createQrCode}
    </Button>
  );
};

export default withDialog(withHeaderActionBar(withLoader(QrCode)));
// Customizable Area End