// Customizable Area Start
import React from "react";

export interface Props {
    text: string;
    disountPrice: string;
    time: string | number | object;
    hasDiscount: boolean;
    isPrmocode?: boolean;
}

interface S {
    currency: string;
}

interface SS {
    id: any;
}

export default class Currency extends React.Component<Props, S, SS>  {
    constructor(props: Props) {
        super(props);
        const storeDetails = this.getStoreDetails();
        this.state = {
            currency: storeDetails?.currency_symbol || "₹"
        };
    }


    async componentDidMount() {
        window.addEventListener("store_details", this.localStorageListener);
    }

    async componentWillUnmount() {
        window.removeEventListener("store_details", this.localStorageListener);
    }

    localStorageListener = () => {
        const storeDetails = this.getStoreDetails();
        if (storeDetails && storeDetails !== "undefined") {
            this.setState({ currency: storeDetails?.currency_symbol || "₹" })
        }
    }

    getStoreDetails = () => {
        const storeDetailsString = localStorage.getItem("storeDetails");
        if (storeDetailsString && storeDetailsString !== "undefined") {
            return JSON.parse(storeDetailsString)
        }
        else null
    }

    render() {
        // "\u200E" for left-to-right conversion for د.إ
        const fullText = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.text || "");
        const fullText1 = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.disountPrice || "");
        const promocodeApplied = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.text || "");

        return <>
            {this.props.isPrmocode ? <span style={webStyle.coupan}>-{promocodeApplied}</span>
                :
                <>
                    {fullText}
                    {this.props.hasDiscount && <span style={webStyle.discount}>{fullText1}</span>}
                    {this.props.time}
                </>
            }
        </>
    }
}

const webStyle = {
    discount : {
        color: "#8C8C8C",
        textDecoration: "line-through"
    },
    coupan:{
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter",
        color: "#6200EA"
    }
}

export const saveStoreDetails = (storeDetails: any) => {
    window.localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
    window.dispatchEvent(new Event("store_details"));
}
// Customizable Area End