// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { RouteProps } from "react-router-dom";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, showError, isTokenExpired, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";
const configJSON = require("./config.js");
import {countaryDataTypes} from "./Types";
import { WithStyles} from "@material-ui/core";
export interface PaymentMethod {
  id: string,
  type: string,
  attributes: {
    id: number,
    payment_method: string,
    active: boolean
  }
}

export type Props = DialogProps &
  RouteProps &
  withLoaderProps &
  withHeadeActionBarProps & WithStyles & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
  };

interface S {
  pageInitialized: boolean;
  paymentStatus: string;
  upiPaymentStatus: string;
  showActivateDialog: boolean;
  paymentMethods: PaymentMethod[];
  countryData?: countaryDataTypes;
  view: number;
}

interface SS {
  id: string | number;
}

class PaymentController extends BlockComponent<Props, S, SS> {
  initMessageId: string = "";
  countryMessageId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      view: 0,
      pageInitialized: true,
      showActivateDialog: false,
      paymentStatus: "loading",
      upiPaymentStatus: "loading",
      paymentMethods: [],
    };
    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    this.getStoreData();
  }

  receive = (from: string, message: Message) => {
    if (isTokenExpired(message)) {
      return this.logoutAndNavigateLogin();
    }
    this.messageInit(message);
    this.messageStoreInit(message);
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getStoreData = async () => {
    const requestMessage = await generateRequestMessage(
      configJSON.storeDetailsAPI,
      configJSON.getMethod
    );
    this.countryMessageId = requestMessage.messageId;
    this.send(requestMessage);
    this.props.showLoader();
  };

  initPage = async () => {
    const requestMessage = await generateRequestMessage(
      configJSON.paymentsAdminURL,
      configJSON.getMethod
    );
    this.initMessageId = requestMessage.messageId;
    this.send(requestMessage);
    this.props.showLoader();
  };
  
  messageInit = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.initMessageId === apiRequestCallId) {
      this.props.hideLoader();
      this.setState({ pageInitialized: true });
      if(responseJson.data){
        this.setState({
          paymentMethods: responseJson.data,
          showActivateDialog: false,
        });
      }
      else {
        this.handleError(
          configJSON.errorPageInitialisation,
          responseJson
        )
      }
    }
  };

  messageStoreInit = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.countryMessageId === apiRequestCallId) {
      this.setState({ pageInitialized: true });
      this.props.hideLoader();
      if(responseJson.data){
        this.setState({
          countryData: responseJson.data.attributes,
          showActivateDialog: false,
        });
      } else {
        this.handleError(
          configJSON.errorPageInitialisation,
          responseJson
        )
      }
    }
  };
  

  handleError = (title: string, responseJson: object) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };


  setActivateDialog = (newState: boolean) => {
    this.setState({
      showActivateDialog: newState,
    });
  };


  openErrorHeaderBar = () => {
    this.props.setDialogState(true, {
      title: configJSON.dialog_title,
      message: configJSON.dialog_msg,
      confirmBackground: configJSON.dialog_bg,
      confirmHoverBackground: configJSON.dialogCFHover,
      hideCancel: true,
    });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      view: newValue,
    });
  };

  setPaymentMethods = (paymentMethods: PaymentMethod[]) => {
    this.setState({ paymentMethods });
  };

}

export default PaymentController;
// Customizable Area End
