// Customizable Area Start
import React, { useEffect, useState, useRef } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { config } from "./config.web";

import StripePaymentsView from "./StripePaymentsView.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface ViewProps {
  // Customizable Area Start
  testID: string;
  stripePromise: Promise<Stripe | null>;
  stripeClientSecret: string | undefined;
  errorString: string | undefined;
  setOrderNumber: (_: number) => void;
  orderNumber: number | undefined;
  actionResult: string | undefined;
  stripeInitialised: boolean;
  isInStripeCallback: boolean;
  onHandleSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => void;
  submitOrderNumberButtonViewProps: { value: string };
  submitPaymentButtonViewProps: { value: string };
  loadingViewProps: { value: string };
  orderIdViewProps: { value: string };
  stripeMessageViewProps: { successValue: string; errorValue: string };
  // Customizable Area End
}

export interface ControllerProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  emailId: string;
  name: string;
  line1: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
  description: string;
  onPaymentFailed: () => void;
  onPaymentSuccess: () => void;
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage
  // Customizable Area End
];

const StripePayments = ({ navigation, id, emailId, name, city, line1, state, country, description, postal_code, onPaymentFailed, onPaymentSuccess }: ControllerProps) => {
  // Customizable Area Start
  const getPaymentMethodsCallId = useRef<string>("");
    // Customizable Area End

  // Customizable Area Start
  const params = new URLSearchParams(window.location.search);
  const returnedPaymentIntentClientSecret = params.get(
    "payment_intent_client_secret"
  );
  const isInStripeCallback = Boolean(returnedPaymentIntentClientSecret);
  const [orderNumber, setOrderNumber] = useState<number | undefined>(undefined);
  const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<
    string | undefined
  >(undefined);
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<
    string | undefined
  >(undefined);
  const [stripeClientSecret, setStripeClientSecret] = useState<
    string | undefined
  >(undefined);
  const [stripeCustomerId, setStripeCustomerId] = useState<string | undefined>(
    undefined
  );
  const [stripeInitialised, setStripeInitialised] = useState<boolean>(false);
  const [stripeActionResultMessage, setStripeActionResultMessage] = useState<
    string | undefined
  >(undefined);
  const [errorString, setErrorString] = useState<string | undefined>(undefined);
  // Customizable Area End

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const [stripePromise] = useState(loadStripe(config.stripeKey));

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  const restoreSessionFromLocalStorage = async () => {
    const persistedAuthToken = await StorageProvider.get("authToken");
    if (persistedAuthToken) {
      const messsage: Message = new Message(
        getName(MessageEnum.SessionSaveMessage)
      );
      messsage.addData(
        getName(MessageEnum.SessionResponseToken),
        persistedAuthToken
      );
      sendBlockMessage(messsage);
    }
  };
  // Customizable Area End

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
      if (resToken) {
        setUserAppAuthenticationToken(resToken);
      } else {
        restoreSessionFromLocalStorage();
        let resToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        setUserAppAuthenticationToken(resToken);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = extractNetworkResponse(
        message
      );
      
      if (
        apiRequestCallId === getPaymentMethodsCallId.current ||
        from === "UNIT_TEST"
      ) {
        setStripeClientSecret(responseJson.data.attributes.client_secret);
        setStripePaymentIntentId(responseJson.data.attributes.id);
        setStripeCustomerId(responseJson.data.attributes.customer);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  const createPaymentIntent = async () => {
    if (!orderNumber) {
      setErrorString("No order number");
      return;
    }

    sendNetworkRequest(
      getPaymentMethodsCallId,
      config.createPaymentIntentMethod,
      config.paymentStripeEndPoint,
      {
        "Content-Type": config.creatPaymentIntentApiContentType,
        token: userAppAuthenticationToken
      },
      {
        order_id: orderNumber,
        email: emailId,
        name: name,
        description: description,
        line1: line1,
        postal_code: postal_code,
        city: city,
        state: state,
        country: country,
      }
    );
  };

  const checkPaymentResult = async () => {
    const stripe = await stripePromise;
    if (!stripe || !returnedPaymentIntentClientSecret) {
      return;
    }
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      returnedPaymentIntentClientSecret
    );
    if (error) {
      return;
    } else if (paymentIntent) {
      const userNotification = `STRIPE OUTCOME: ${paymentIntent.status}`;
      setStripeActionResultMessage(userNotification);
    }
  };
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);
    subscribedMessages.forEach(message => subscribe(message));
    // Customizable Area Start
    getToken();
    // Customizable Area End
    return () => {
      subscribedMessages.forEach(message => unsubscribeFromMessage(message));
    };
  }, [
    getToken,
    receive,
    setReceiveCallback,
    subscribe,
    unsubscribeFromMessage
  ]);

  // Customizable Area Start
  useEffect(() => {
    checkPaymentResult();
  }, [checkPaymentResult, userAppAuthenticationToken]);

  useEffect(() => {
    if (orderNumber) {
      createPaymentIntent();
    }
  }, [ orderNumber]);

  useEffect(() => {
    if (stripeClientSecret && stripePaymentIntentId && stripeCustomerId) {
      setStripeInitialised(true);
    }
  }, [stripeClientSecret, stripePaymentIntentId, stripeCustomerId]);

  useEffect(() => {
    if(id)
    {
    setOrderNumber(parseInt(id))
    }
  },[id])
  // Customizable Area End

  // Customizable Area Start
  const onHandleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    stripe: Stripe | null,
    stripeElements: StripeElements | null
  ) => {
    event.preventDefault();
    if (!stripe || !stripeElements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements: stripeElements,
      confirmParams: { 
        return_url: window.location.href, 
        payment_method_data: {  
          billing_details : {name: name, email: emailId, address: {
            line1: line1,
            postal_code: postal_code,
            city: city,
            state: state,
            country: country,
          },}
        } ,
      },
       redirect: "if_required"
    });

    if (result.error) {
      if(result.error.type !== "validation_error")
      {
        onPaymentFailed()
        setStripeActionResultMessage(result.error.message);
      }
    } else {
      onPaymentSuccess()
    }
  };
  // Customizable Area End

  // Customizable Area Start
  const orderIdViewProps = {
    value: config.orderId
  };

  const submitOrderNumberButtonProps = {
    value: config.submitText
  };

  const submitPaymentButtonProps = {
    value: config.submitText
  };

  const loadingViewProps = {
    value: config.loading
  };

  const stripeMessageViewProps = {
    successValue: config.stripeSuccessMessage,
    errorValue: config.stripeErrorMessage
  };
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: id,
    // Customizable Area Start
    errorString,
    stripePromise: stripePromise,
    stripeClientSecret: stripeClientSecret,
    setOrderNumber: setOrderNumber,
    orderNumber: orderNumber,
    actionResult: stripeActionResultMessage,
    stripeInitialised: stripeInitialised,
    isInStripeCallback: isInStripeCallback,
    onHandleSubmit: onHandleSubmit,
    submitOrderNumberButtonViewProps: submitOrderNumberButtonProps,
    submitPaymentButtonViewProps: submitPaymentButtonProps,
    loadingViewProps: loadingViewProps,
    orderIdViewProps: orderIdViewProps,
    stripeMessageViewProps: stripeMessageViewProps
    // Customizable Area End
  };

  return <StripePaymentsView {...viewProps} />;
};

export default StripePayments;
