import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { clearStorageData, generateRequestMessage, isTokenExpired, showError } from "../../ss-cms-common-components/src/Utilities/Utilities";
export const configJSON = require("./config.js");

export type Props = RouterProps &
    DialogProps &
    withHeadeActionBarProps &
    withLoaderProps &
    withToastProps & {
        navigation: {
            navigate: (to: string, params: object) => void;
            getParam: (param: string) => string;
            goBack: () => void;
          };
        id: string;
        // Customizable Area Start
        // Customizable Area End
    };

interface S {
    // Customizable Area Start
    customerFeedbackData: Array<CustomerFeedbackDataType>;
    allCustomerFeedbackDataLength: number;
    search: string;
    noData: boolean;
    checkedFeedbackList: Array<CustomerFeedbackDataType>;
    rowPerPage: number;
    customerFeedbackMeta: {
        total_count: number
    }
    loading: boolean;

    // Customizable Area End
}

// Customizable Area Start
interface SS {
    id: string;
}

interface CustomerFeedbackData {
    data: [
        {
            id: number;
            attributes: CustomerFeedbackDataAttributes;
        }
    ];
    metadata: { 
        meta: {
            pagination: {
                total_count: number
            }
        }
    };
    message?: {};
    errors?: [];
}
interface CustomerFeedbackDataAttributes {
    description?: string;
    position?: number;
    customer_name?: string;
    is_active?: boolean;
}
interface CustomerFeedbackDataType {
    id: number;
    description?: string;
    position?: number;
    customerName?: string;
    activated?: boolean;
    name?: string;
    number?: number;
    email?: string;
}
// Customizable Area End
export default class CustomerFeedbackController extends BlockComponent<Props, S, SS> {
    getCustomerFeedbackApiCallId: string = "";
    getCustomerFeedbackLengthApiCallId: string = "";
    postBulkDeleteCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            customerFeedbackData: [],
            allCustomerFeedbackDataLength: 0,
            search: "",
            noData: false,
            checkedFeedbackList: [],
            rowPerPage: 10,
            customerFeedbackMeta: { total_count: 0 },
            loading: true
        };
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.ActionMessageFromToaster)
        ];

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    handleGetCustomerFeedbackApiCallIdResponse = (responseJson: CustomerFeedbackData) => {
        this.setState({ loading: false });
        if (responseJson && responseJson?.data) {
            this.initCustomerFeedbackData(responseJson);
        } else {
            showError(configJSON.textErrorOnInitialize,responseJson, this.props.hideLoader, this.props.setDialogState)
        }
        this.props.hideLoader();
    }
    handlePostBulkDeleteCallIdResponse = (responseJson: CustomerFeedbackData) => {
        if (responseJson && responseJson?.message) {
            this.props.showHeaderBar({ type: configJSON.keySuccess })
            this.getCustomerFeedbackDataApi(1, '')

            this.setState({ ...this.state, checkedFeedbackList: [], loading: false })
            this.props.hideLoader();
        } else if (responseJson?.errors) {
            this.setState({ ...this.state, loading: false })
            this.props.showHeaderBar({ message: configJSON.errorMessage, type: configJSON.keyError })
            this.props.hideLoader()
        }
    }
    handleGetCustomerFeedbackLengthApiCallId = (responseJson: CustomerFeedbackData) => {
        this.props.hideLoader();
        if (responseJson && responseJson?.data) {
            this.setState({ allCustomerFeedbackDataLength: responseJson?.data.length, customerFeedbackMeta: responseJson?.metadata?.meta?.pagination })
        } else {
            showError(configJSON.textErrorOnInitialize,responseJson, this.props.hideLoader, this.props.setDialogState)
        }
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            if (isTokenExpired(message)) {
                return this.logoutAndNavigateLogin();
            }

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.getCustomerFeedbackApiCallId === apiRequestCallId) {
                this.handleGetCustomerFeedbackApiCallIdResponse(responseJson);
            } else if (this.postBulkDeleteCallId === apiRequestCallId) {
                this.handlePostBulkDeleteCallIdResponse(responseJson);
            }
            if (this.getCustomerFeedbackLengthApiCallId === apiRequestCallId) {
                this.handleGetCustomerFeedbackLengthApiCallId(responseJson);
            }
        }
    }

    logoutAndNavigateLogin = () => {
        clearStorageData();
        const toSend = new Message(getName(MessageEnum.NavigationMessage));
        toSend.addData(
            getName(MessageEnum.NavigationTargetMessage),
            configJSON.keyLogin
        );
        toSend.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(toSend.messageId, toSend);
    };

    async componentDidMount() {
        super.componentDidMount();
        this.getCustomerFeedbackDataApi(1, '');
    }
    goToAddCustomerFeedback = () => {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.AdminCustomerFeedbackCreate);
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    };
    handleEditClick = (item: CustomerFeedbackDataType) => {
        this.props.navigation.navigate(configJSON.AdminCustomerFeedbackCreate, {id: item.id})
    };
    handlePageChange = (value: number) => {
        this.getCustomerFeedbackDataApi(value, this.state.search);
    };

    handleSearch = (search: string) => {
        this.setState({ search });
        this.getCustomerFeedbackDataApi(1, search);
    };

    handleClearSearch = () => {
        this.setState({ search: "" });
    };

    checkedItems = (item: Array<CustomerFeedbackDataType>) => {
        this.setState({ ...this.state, checkedFeedbackList: item })
    }

    handleDeleteModal = () => {
        this.props.setDialogState(true, {
            title: configJSON.deleteModalTitle,
            message: configJSON.deleteModalMessage,
            okay: configJSON.textConfirmDelete,
            confirmColor: configJSON.deleteModalConfirmColor,
            confirmBackground: configJSON.deleteModalConfirmBackground,
            confirmHoverBackground: configJSON.deleteModalConfirmHoverBackground,
            onSubmit: () => this.handleBulkDeleteFeedbackAPI()
        });
    }
    handleBulkDeleteFeedbackAPI = async () => {
        const { checkedFeedbackList } = this.state;
        let onlyIDs = checkedFeedbackList?.map((element: CustomerFeedbackDataType) => +element.id)
        await this.postBulkDelete(onlyIDs)
    }
    initCustomerFeedbackData = (data: CustomerFeedbackData) => {
        if (data.data && data.data.length > 0) {
            const responseData = data.data.map((item) => {
                return {
                    id: item.id,
                    description: item.attributes.description,
                    position: item.attributes.position,
                    customerName: item.attributes.customer_name,
                    activated: item.attributes.is_active,
                };
            });
            this.setState({
                allCustomerFeedbackDataLength: data?.data.length,
                customerFeedbackMeta: data?.metadata?.meta?.pagination,
                customerFeedbackData: responseData,
                noData: responseData?.length === 0
            });
        } else if (this.state.search) {
            if (data.data.length <= 0) {
                this.setState({ noData: true });
            } else {
                this.setState({ noData: false });

            }

        } else if (data.data.length <= 0) {
            this.setState({ customerFeedbackData: [] });

        }

    };
    getCustomerFeedbackDataApi = async (pageNo: number, search: string) => {
        this.props.showLoader();
        const requestMessage = await generateRequestMessage(`${configJSON.getCustomerFeedbackAPI}?&page=${pageNo ? pageNo : 1}&per_page=${this.state.rowPerPage}&search=${search}`, configJSON.validationApiMethodType)
        this.getCustomerFeedbackApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    postBulkDelete = async (data: Array<number>) => {
        this.props.showLoader();
        const requestMessage = await generateRequestMessage(configJSON.postBulkDeleteAPI, configJSON.deleteAPiMethod);
        let body = {
            action_type: configJSON.deleteAPiMethod,
            resource: configJSON.resource,
            ids: data
        }
        this.postBulkDeleteCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
}