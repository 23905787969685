import React from "react";
// Customizable Area Start
import { Box, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";
import { createTheme, Theme } from "@material-ui/core/styles";
import AppointmentStiperController , { Props } from "./AppointmentStiperController.web"
import './cal.css';
// Customizable Area End

// Customizable Area Start
const steps = [
  'Date & time',
  'Personal details',
  'Payment',
];

const steps1 = [
  "Service",
  "Date & time",
  "Personal details",
  "Payment",
];

const theme = createTheme({});

export const styles = (theme: Theme) => ({
  stepperClass: {
    maxWidth: '400px',
    minWidth: '700px',
    margin: "auto",
    background: "transparent !important",
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      minWidth: 'auto',
      margin: "auto",
      padding: "10px 0px"
    },
    '@media(max-width: 480px)': {
      margin: "0px -14px"
    },
    '& .MuiStep-horizontal': {
      paddingLeft: "0px",
      paddingRight: "0px",
    }
  }
});
// Customizable Area End


export class AppointmentStiper extends AppointmentStiperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { activeStep, categoriesLength } = this.props

    return (
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel className={this.props.classes.stepperClass}>
          {
            this.props.appoinmentType == "catalogue" ?
            steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )) :

            categoriesLength === 1 ?
            steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            )) :

            categoriesLength > 1 &&
              steps1.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))
          }
          
        </Stepper>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(AppointmentStiper);
// Customizable Area End
