// Customizable Area Start
import React, {RefObject} from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import * as Yup from "yup";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
const configJSON = require("./config");
import { WithStyles } from "@material-ui/core";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { styles } from "./AppRequirementForm.web";
import isEqual from "lodash/isEqual";
import { FormikProps, FormikHelpers } from "formik";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import {generateRequestMessage, checkResponseError, showError} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export const PostalCodeValidation = (countryField: string) =>
  Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .when(countryField, {
      is: (country: string) => country?.toLowerCase() === configJSON.st_india,
      then: (schema: Yup.StringSchema) =>
        schema.matches(/^\d{3}\s?\d{3}$/, configJSON.zip_invalid),
      otherwise: (schema: Yup.StringSchema) =>
        schema.when(countryField, {
          is: (country: string) => country?.toLowerCase() === configJSON.st_uk,
          then: (schema: Yup.StringSchema) =>
            schema.matches(
              /^(([A-Za-z][0-9]{1,2})|([A-Za-z][A-HJ-Ya-hj-y][0-9]{1,2})|([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-HJ-Ya-hj-y]?[0-9]?[A-Za-z])) [0-9][A-Za-z]{2}$/,
              configJSON.zip_invalid
            ),
          otherwise: (schema: Yup.StringSchema) =>
            schema.matches(
              /((^\d{5}-\d{4}$)|(^\d{5}$)|(^[A-Z]\d[A-Z] \d[A-Z]\d$))/,
              configJSON.zip_invalid
            ),
        }),
    });

export const AddressValidation = Yup.string()
  .max(75, configJSON.maximum_75Characters);

export const PhoneValidation = (countryField: string) =>
  Yup.string().when(countryField, {
    is: (country: string) => country?.toLowerCase() === configJSON.st_india || country?.toLowerCase() === configJSON.st_us,
    then: (schema: Yup.StringSchema) =>
      schema
        .matches(/^[0-9]{9,13}$/, configJSON.phone_9to13Characters)
        /*.min(10, configJSON.phone_10Characters)
        .max(10, configJSON.phone_10Characters)*/,
    otherwise: (schema: Yup.StringSchema) =>
      schema
        .matches(/^[0-9]{9,13}$/, configJSON.phone_9to13Characters)
        /*.min(11, configJSON.phone_11Characters)
        .max(11, configJSON.phone_11Characters)*/,
  });


interface AppReqAttributes {
  app_name:                    string;
  short_description:           string;
  description:                 string;
  tags:                        string[];
  website:                     string;
  email:                       string;
  phone:                       string;
  first_name:                  string;
  last_name:                   string;
  country_name:                string;
  state:                       string | undefined;
  city:                        string | undefined;
  postal_code:                 string;
  address:                     string;
  privacy_policy_url:          string;
  support_url:                 string;
  marketing_url:               string;
  terms_and_conditions_url:    string;
  target_audience_and_content: string;
  is_paid:                     boolean;
  default_price:               number;
  distributed_countries:       string;
  auto_price_conversion:       boolean;
  android_wear:                boolean;
  google_play_for_education:   boolean;
  us_export_laws:              boolean;
  copyright:                   string;
  app_icon:                    string;
  common_feature_banner:       string;
  app_categories:              AppCategories;
  app_categories_attributes:  Array<AppCategoryType>
}

interface AppCategoryType {
  id: number | undefined,
  destroy: boolean,
  app_category: string,
  app_type: string,
  feature_graphic: string,
  attachments_attributes: Array<string>,
  default_attachments_attributes: Array<string>,
  attachments_attributes_raw?: Array<{
    lastModified: number,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string
    }>,
  feature_graphic_raw?: Array<{
    lastModified: number,
    name: string,
    size: number,
    type: string,
    webkitRelativePath: string
    }>,
  product_title: string,
  review_notes: string,
  review_password: string,
  review_username: string,
  _destroy: boolean,
}

interface AppCategories {
  data: Array<AppCategoryRecordType>;
}

interface AppCategoryRecordType {
  id: number,
  type: string,
  attributes: AppCategoryTypeRes
}
interface AppCategoryTypeRes {
  destroy: boolean,
  app_category: string,
  app_type: string,
  screenshots: {data: Array<screenshotItems>},
  feature_graphic: string | null,
  product_title: string,
  review_notes: string,
  review_password: string,
  review_username: string,
  attachments_attributes: Array<attachmentsTypes>
}
interface screenshotItems{
  id: number,
  type: string,
  attributes: {
    url: string
  }
}

interface attachmentsTypes{
  id: number,
  image: string
}
export interface reqInitialType {
  app_name:                    string;
  short_description:           string;
  description:                 string;
  tags:                        Array<string>;
  website:                     string;
  email:                       string;
  phone:                       string;
  first_name:                  string;
  last_name:                   string;
  country_name:                string;
  state:                       string | undefined;
  city:                        string | undefined;
  postal_code:                 string;
  address:                     string;
  privacy_policy_url:          string;
  support_url:                 string;
  marketing_url:               string;
  terms_and_conditions_url:    string;
  target_audience_and_content: string;
  is_paid:                     boolean;
  default_price:               number;
  distributed_countries:       string;
  auto_price_conversion:       boolean;
  android_wear:                boolean;
  google_play_for_education:   boolean;
  us_export_laws:              boolean;
  copyright:                   string;
  app_icon:                    string | undefined;
  common_feature_banner:       string | undefined;
  feature_graphic:             string;
  app_categories_attributes?:  Array<AppCategoryTypePayload>;
  app_categories?:             Array<AppCategoryTypePayload>
}

interface attachmentTypesNew {
  id: string,
  image: string
}

interface AppCategoryTypePayload {
  id?: number,
  _destroy?: string,
  destroy?: boolean | undefined,
  app_type: string,
  app_category?: string,
  feature_graphic?: string,
  product_title: string,
  review_notes: string,
  review_password: string,
  review_username: string,
  attachments_attributes: Array<attachmentTypesNew>,
  default_attachments_attributes: Array<attachmentTypes> | undefined,
  attachments_attributes_raw: Array<attachmentTypes> | undefined,
  screenshots?: {
    data: Array<attachmentTypes>
  }
}

interface attachmentTypes {
  id: string,
  type?: string,
  attributes?: {
    app_category_id: number,
    id: number,
    is_default: boolean | null,
    is_present: boolean,
    position: number | null,
    url: string,
    url_id: null
    url_link: null
    url_type: null
  }
}

interface errorsTypes{
  errors: string
}

export interface ICountryAttributes {
    id: number;
    name: string;
    code: string;
    currency: ICurrency;
    created_at: string;
    updated_at: string;
    phone_code: string;
  }
  
  export interface ICountry {
    id: string;
    type: string;
    attributes: ICountryAttributes;
  }
  
  export interface IStateAttribute {
    id: number;
    name: string;
    gst_code: string;
  }
  
  export interface IState {
    id: number | string;
    type: string;
    attributes: IStateAttribute;
  }
  
  export interface ICityAttribute {
    id: number;
    name: string;
    address_state_id: number;
    created_at: string;
    updated_at: string;
  }
  
  export interface ICity {
    id: string;
    type: string;
    attributes: ICityAttribute;
  }
  
  export interface ICurrency {
    id: number;
    name: string;
    symbol: string;
    country_id: number;
    created_at: string;
    updated_at: string;
  }


  
export type Props = DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps &
  WithStyles<typeof styles> & {
    id: string;
  };


export const Schema = Yup.object().shape({
    app_name: Yup.string().required(configJSON.thisFieldIsRequired).max(30, configJSON.maximum_30Characters),
    short_description: Yup.string().required(configJSON.thisFieldIsRequired).max(170, configJSON.maximum_170Characters),
    description: Yup.string().required(configJSON.thisFieldIsRequired).max(4000, configJSON.maximum_4000Characters),
    email: Yup.string().email(configJSON.emailMustBeInValidFormat).required(configJSON.thisFieldIsRequired),
    phone: PhoneValidation("country_name"),app_icon: Yup.string().nullable().required(configJSON.appIconIsRequired),
    common_feature_banner: Yup.string().nullable().required(configJSON.commonFeatureBannerIsRequired),
    first_name: Yup.string().required(configJSON.thisFieldIsRequired).max(50, configJSON.maximum_50Characters),
    last_name: Yup.string().required(configJSON.thisFieldIsRequired).max(50, configJSON.maximum_50Characters),
    postal_code: PostalCodeValidation("country_name"),
    address: AddressValidation.required(configJSON.thisFieldIsRequired),
    tags: Yup.array().of(Yup.string()),
    is_paid: Yup.string(),
    default_price: Yup.number().when("is_paid", {
        is: (is_paid: string) => is_paid === "true",
        then: (schema: Yup.NumberSchema) => schema.required(configJSON.thisFieldIsRequired)
        .min(0.001, configJSON.defaultPriceHasToBeMoreThanZero),
    }),
    country_name: Yup.string(),
    state: Yup.string().when("country_name", {
        is: (countryName: string) => countryName,
        then: (schema: Yup.StringSchema) => schema.required(configJSON.thisFieldIsRequired),
    }),
    city: Yup.string().when("country_name", {
        is: (countryName: string) => (countryName === configJSON.st_cap_india),
        then: (schema: Yup.StringSchema) =>
        schema.required(configJSON.thisFieldIsRequired),
    }),
    app_categories_attributes: Yup.array().nullable().typeError(configJSON.validationAppCategories),

    //url validation
    website: Yup.string().nullable().url(configJSON.validationURL),
    privacy_policy_url: Yup.string().nullable().url(configJSON.validationURL),
    support_url: Yup.string().nullable().url(configJSON.validationURL),
    marketing_url: Yup.string().nullable().url(configJSON.validationURL),
    terms_and_conditions_url: Yup.string().nullable().url(configJSON.validationURL),
});

interface S {
  locations: ICountry[];
  states: IState[];
  cities: ICity[];
  country: ICountry;
  cityState: IState;
  city: ICity;
  localValidation: boolean;
  isCityValidationActive: boolean;
  openState: boolean;
  openCity: boolean;
  initialValues:reqInitialType;
  openCountry: boolean;
  separateCityState: boolean;
  tags_count: number;
  is_paid: string;
  app_categories_attributes_count: number;
}

interface SS {
  id: number;
}

export class AppRequirementFormController extends BlockComponent<Props, S, SS> {
    getLocationsDataApiCallId: string = "";
    getStatesDataApiCallId: string = "";
    getCitiesDataApiCallId: string = "";
    saveChangesMessageId: string = "";
    initialValuesMessageId: string = "";

    formRef: RefObject<FormikProps<reqInitialType>>;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.formRef = React.createRef();
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.ActionMessageFromToaster),
        ];
        this.state = {
          locations: [],
          states: [],
          cities: [],
          country: {} as ICountry,
          cityState: {} as IState,
          city: {} as ICity,
          app_categories_attributes_count: 0,
          tags_count: 1,
          localValidation: false,
          isCityValidationActive: false,
          openState: false,
          openCity: false,
          initialValues: {} as reqInitialType,
          openCountry: false,
          separateCityState: false,
          is_paid: "false"
        };
        
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      this.readAppRequirements();
    }
    
    receive(from: string, message: Message): void {
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        
        this.messageInitialValuesCall(message)
        this.messageSaveChangesCall(message)
        this.messageGetCountriesDataApiCall(message);
        this.messageGetStatesDataApiCall(message);
        this.messageGetCitiesDataApiCall(message);

        
      } else if (getName(MessageEnum.ActionMessageFromToaster)) {
        const type = message.getData(
          getName(MessageEnum.ActionMessageFromToasterMessage)
        );
        if (type === "SAVECHANGES") {
          this.formRef.current && this.formRef.current.handleSubmit();
        } else if (type === "DISCARDCHANGES") {
          this.readAppRequirements()
        }
      }
    }

  
  readAppRequirements = async () => {
    mixpanel.track("webadmin_mobile_app_requirements_page_enter");
    this.props.showLoader();
    const initialValuesMessage = await generateRequestMessage(
      configJSON.readAppRequirementsApiEndpoint,
      configJSON.getApiMethodType
    );
    this.initialValuesMessageId = initialValuesMessage.messageId;
    this.send(initialValuesMessage);
  };

  messageGetCountriesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (apiRequestCallId === this.getLocationsDataApiCallId) {
      if (responseJson?.data?.length > 0) {
        const locations = responseJson?.data;
        
        this.setState({ locations }, () => {
          this.getSelectedCountry(this.state.initialValues.country_name);
        });
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }

      setTimeout(() => {
        this.props.hideLoader();
      }, 1100);
    }
  };

  messageGetStatesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (apiRequestCallId === this.getStatesDataApiCallId) {  
      if (responseJson?.data?.length > 0) {
        const states = responseJson?.data;
        let selectedState = states?.filter((state: IState) => {
          return (state?.attributes.name) === (this.state.initialValues.state);
        })?.[0];
        this.setState( 
          {
            states,
            cityState: selectedState
          },
          () => {
            Number(this.state.country?.id) == 1 &&
              this.getCities(this.state.cityState?.attributes.id);
          }
        );
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
    }
    this.props.hideLoader();
  };

  getSelectedCountry = (countryId: null | string) => {
    const {initialValues} = this.state;
    let selectedCountry = this.state.locations?.filter((country) => {
      return country?.attributes?.name == countryId;
    })?.[0];
    if (selectedCountry) {
      this.setState(
        {
          country: selectedCountry,
          initialValues: {...initialValues, country_name: selectedCountry?.attributes.name}
        },
        () => this.getStates(Number(selectedCountry?.id))
      );
    }
  };

  messageGetCitiesDataApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.getCitiesDataApiCallId) {
      const {initialValues} = this.state;
      if (responseJson?.data?.length > 0) {
        const cities = responseJson.data;
        let selectedCity = cities?.filter((city: ICity) => {
          return (city?.attributes.name) == (initialValues.city);
        })?.[0];
        this.setState({
          cities,
          city: selectedCity
        });
      } else {
        showError(
          configJSON.textSomethingWentWrong,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      }
      this.props.hideLoader();
    }
  };

  messageInitialValuesCall = (message: Message) => {

    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.initialValuesMessageId) {
      this.props.hideLoader();
      if (checkResponseError(responseJson)) {
        return this.handleError(
          configJSON.errorOnInitialization,
          responseJson
        );
      }
      
      const appCategoryRow = responseJson.data?.attributes?.app_categories?.data || [];
      const app_categories = appCategoryRow.length > 0 ? (appCategoryRow).map((item: AppCategoryRecordType) => ({
        ...item.attributes,
        destroy: false,
        attachments_attributes: item.attributes.screenshots.data.map(
          (subItem: screenshotItems) => subItem.attributes.url
        ),
        default_attachments_attributes: item.attributes.screenshots.data,
      })) : [];
      
      const initialValues = {
        ...InitialValues,
        ...(responseJson.data?.attributes || {}),
        default_price:
          responseJson.data?.attributes?.default_price ??
          InitialValues.default_price,
        app_categories_attributes: app_categories,
      };
      initialValues.is_paid =
        initialValues.is_paid === true || initialValues.is_paid === "true"
          ? "true"
          : "false";

          
      if (typeof initialValues.tags !== "object") {
        initialValues.tags = [initialValues.tags];
      }
      if (this.state.locations && initialValues.country_name) {
        this.getSelectedCountry(initialValues.country_name);
      }

      this.setState(
        {
          initialValues,
          is_paid: initialValues.is_paid,
          separateCityState: initialValues.country_name === "India",
          tags_count: initialValues.tags.length,
          app_categories_attributes_count: app_categories ? app_categories.length : 0,
        },
        () => {
          this.formRef.current?.resetForm();
        }
      );
      this.getLocations();
    }
  }

  messageSaveChangesCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.saveChangesMessageId === apiRequestCallId) {
      this.props.hideLoader();
      if (checkResponseError(responseJson)) {
        return this.handleError(configJSON.errorOnSave, responseJson);
      }
      this.props.showHeaderBar({ type: "success", message: configJSON.textAppReqSaveChanges });
      this.readAppRequirements();
    }
  }
  
  handleClickNew = (name: string, setFieldValue: FormikHelpers<reqInitialType>["setFieldValue"]) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (name === "app_categories_attributes") {
      setFieldValue(
        `app_categories_attributes[${this.state.app_categories_attributes_count}]`,
        AppCategoryAttributesInitialValues
      );
    }
    this.setState(
      (prevState) => ({
        ['app_categories_attributes_count']: prevState['app_categories_attributes_count'] + 1,
      }),
      () => {
        this.showHeaderBar();
        const count = Number(this.state.app_categories_attributes_count);
        const elementName = `app_categories_attributes[${count - 1}]`;
        const element = document.getElementsByName(elementName)[0];
        if (element) {
          element.focus();
        }
      }
    );
  };
  
  handleClickNewTag = (event: React.MouseEvent<HTMLButtonElement>) =>  {
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      (prevState) => ({
        ['tags_count']: prevState['tags_count'] + 1,
      }),
      () => {
        this.showHeaderBar();
        const count = Number(this.state.tags_count);
        const elementName = `tags[${count - 1}]`;
        const element = document.getElementsByName(elementName)[0];
        if (element) {
          element.focus();
        }
      }
    );
  }


  handleError = (title: string, responseJson: errorsTypes) => {
    showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  hideLoader = () => {
    this.props.hideLoader();
  };

  handleSubmit = (values: reqInitialType) => {
    this.handleSaveChanges(values);
  };

  handleSaveChanges = async (values: reqInitialType) => {
    mixpanel.track("webadmin_mobile_app_requirements_save_changes");
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      configJSON.saveChangesApiEndpoint,
      configJSON.putApiMethodType
    );

   
    const initialValues = this.state.initialValues;
    const { app_icon, app_categories_attributes, common_feature_banner } = values
    if(app_icon !== initialValues.app_icon) {
      values.app_icon = app_icon
    } else {
      delete values.app_icon
    }
    
    delete values.app_categories;
    
    if (common_feature_banner !== initialValues.common_feature_banner) {
      values.common_feature_banner = common_feature_banner;
    }else {
      delete values.common_feature_banner
    }

    let newData = this.handleAppCategories(initialValues,values);
 
    if (!isEqual(app_categories_attributes, initialValues.app_categories_attributes)) {
      values.app_categories_attributes = newData?.appAttributes as Array<AppCategoryTypePayload>;
    }else{
      delete values.app_categories_attributes;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );

    this.saveChangesMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  handleAppCategories = (initialValues: reqInitialType, data : reqInitialType) => {
    let app_categories_attributes = data.app_categories_attributes;
    if (app_categories_attributes && app_categories_attributes!== undefined) {
      let appAttributes = app_categories_attributes.filter((this.filterByCategories(initialValues)) || [])
      .map((category: AppCategoryTypePayload) => {
        const {
          default_attachments_attributes,
          attachments_attributes_raw,
          attachments_attributes,
          destroy,
          feature_graphic,
          ...record
        } = category;
        
        

        if (destroy) {
          category._destroy= "1";
          return category;
        }else{
          delete category._destroy;
          delete category.destroy;
        }
        
        if ((category.feature_graphic || "").indexOf("://") === -1) {
          category.feature_graphic = category.feature_graphic;
        }else{
          delete category.feature_graphic
        }

        const { id } = category;
        const initialImages = id ? default_attachments_attributes || [] : [];

        delete category.default_attachments_attributes;
        delete category.attachments_attributes_raw;       
        
        return {
          ...category,
          attachments_attributes: attachments_attributes!= undefined && attachments_attributes.length > 0 && (attachments_attributes).map(this.getImage(initialImages)).filter((item) => !!item),
        };
      });
      
      return {appAttributes}
    }
    return {attachments_attributes: null}
  }

  getLocations = async () => {
    this.props.displaySpinner();
    const getLocationsData = await generateRequestMessage(
      configJSON.getLocationsAPI+'?show_all=true',
      configJSON.getApiMethodType
    );
    this.getLocationsDataApiCallId = getLocationsData.messageId;
    runEngine.sendMessage(getLocationsData.id, getLocationsData);
  }

  async getStates(locationId: number) {
    if (!locationId) {
      return;
    }
    this.props.displaySpinner();
    const getStatesData = await generateRequestMessage(
      `${configJSON.getLocationsAPI}/${locationId || 1}/address_states?show_all=true`,
      configJSON.getApiMethodType
    );
    this.getStatesDataApiCallId = getStatesData.messageId;
    runEngine.sendMessage(getStatesData.id, getStatesData);
  }

  async getCities(locationId: number) {
    if (!locationId) {
      return;
    }
    this.props.displaySpinner();
    const getCitiesData = await generateRequestMessage(
      `${configJSON.getCityAPI}/${locationId}/cities?show_all=true`,
      configJSON.getApiMethodType
    );
    this.getCitiesDataApiCallId = getCitiesData.messageId;
    runEngine.sendMessage(getCitiesData.id, getCitiesData);
  }

  countryToFlag = (isoCode: ICountryAttributes["code"]) => {
    if (!isoCode) return null;
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  handleChangeIsPaid = (event: string,
    setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({is_paid: event});
    setFieldValue("default_price", "");
  };

  // from conntroller
  getImage = (initialImages: attachmentTypes[]) => {
    return (item: attachmentTypes, index: number) => {
      const initialImageId = initialImages[index]?.id
      if (initialImageId) {
        // we have id, send destroy if image removed
        if (!item) {
          return {
            id: initialImageId,
            _destroy: "1",
          }
        }
      }

      if (!initialImageId) {
        if (!!item) {
          return  {"image": item}
        }
      }
    }
  }

  private filterByCategories = (initialValues: reqInitialType) =>  {
    return (category: AppCategoryTypePayload, index: number) => {
      if (!category) {
        return false
      }

      if (!category.id) {
        return true
      }

      return initialValues.app_categories_attributes && !isEqual(category, initialValues.app_categories_attributes[index])
    }
  }

  handleChangeCountry = (
    _event: React.ChangeEvent<{}>,
    value: ICountry | null,
    setFieldValue: FormikHelpers<AppReqAttributes>["setFieldValue"]
  ) => {
    if (value === null) {
      this.resetCountry();
    } else {
      setFieldValue("country_name", value.attributes.name);
      this.setState(
        {
          country: value,
          cityState: {} as IState,
          city: {} as ICity,
          states: [],
          cities: [],
        },
        () =>this.getStates(Number(this.state.country?.id || 1))
      );
    }
    this.showHeaderBar();
  };

  resetCountry() {
    this.setState({
      country: {} as ICountry,
      cityState: {} as IState,
      city: {} as ICity,
      states: [],
      cities: []
    });
  }
  
  showHeaderBar = () => {
    this.props.showHeaderBar({});
    return true;
  };


  handleChangeState = (_event: React.ChangeEvent<{}>, value: IState | null, setFieldValue: FormikHelpers<AppReqAttributes>["setFieldValue"]) => {
    setFieldValue("state", value?.attributes.name);
    this.setState(
      {
        cityState: (value ?? {}) as IState,
        city: {} as ICity,
        cities: [],
      },
      () => {
        if (Number(this.state.country.id) === 1) {
          this.getCities(this.state.cityState?.attributes.id);
        }
      }
    );
    this.showHeaderBar();
  };

  handleChangeCity = (_event: React.ChangeEvent<{}>, value: ICity | null, setFieldValue: FormikHelpers<AppReqAttributes>["setFieldValue"]) => {
    setFieldValue("city", value?.attributes.name);
    this.setState({
      city: value ?? ({} as ICity),
    });
    this.showHeaderBar();
  };

  handleCountryOpen = () => {
    this.setState({ openCountry: true });
  };
  handleCountryClose = () => {
    this.setState({ openCountry: false });
  };
  handleCountryInverse = () => {
    this.setState({ openCountry: !this.state.openCountry });
  };
  handleStateOpen = () => {
    this.setState({ openState: true });
  };
  handleStateClose = () => {
    this.setState({ openState: false });
  };
  handleStateInverse = () => {
    this.setState({ openState: !this.state.openState });
  };
  handleCityOpen = () => {
    this.setState({ openCity: true });
  };
  handleCityClose = () => {
    this.setState({ openCity: false });
  };
  handleCityInverse = () => {
    this.setState({ openCity: !this.state.openCity });
  };
}


export const InitialValues = {
    //details
    app_name: "",
    short_description: "",
    description: "",
    app_icon: undefined,
    common_feature_banner: undefined,
    tags: [""],
  
    //contact details
    website: "",
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    country_name: "",
    city: "",
    state: "",
    postal_code: "",
    address: "",
  
    //Page URLs
    privacy_policy_url: "",
    support_url: "",
    marketing_url: "",
    terms_and_conditions_url: "",
    target_audience_and_content: "",
  
    //Pricing and distribution
    is_paid: "false",
    default_price: "",
    distributed_countries: "",
    auto_price_conversion: false,
    android_wear: false,
    google_play_for_education: false,
    us_export_laws: false,
    copyright: "",
    app_categories_attributes: [],
  };
  
  export const AppCategoryAttributesInitialValues = {
    _destroy: "0",
    app_type: "",
    feature_graphic: null,
    product_title: "",
    app_category: "",
    review_username: "",
    review_password: "",
    review_notes: "",
    attachments_attributes: [],
  };
  export default AppRequirementFormController;
// Customizable Area End