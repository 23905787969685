// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject } from "react";
export const configJSON = require("./config");
import { FilterType, OrderType, PaginationType } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export interface Props {
  navigation: {
    navigate: (to: string, params?: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface PostOrderStatusFailureResponse {
  errors: Array<string>
}

export interface StaffData {
  data: Array<staffDataArray>;
}

export interface staffDataArray {
  id: string;
  type: string;
  isChecked: boolean;
  attributes: {
    email: string;
    full_name: string;
    phone_number: null;
    description: string;
    designation: string;
  },
}

export interface OrderSummary {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    appointment_date: string;
    order_date: string;
    status: string;
    total: number;
    catalogue_price: number;
    service: {
      title: string;
      price: number;
      duration: number;
      discount_price: number;
      discount_option: boolean;
    };
    service_images: {
      id: number;
      url: string;
    };
    service_provider: {
      id: number;
      full_name: string;
      email: string;
      designation: string;
      image: string;
      phone_number: string;
    };
    payment_mode: string;
    customer: {
      id: number;
      full_phone_number: string;
      email: string;
      full_name: string;
      created_at: string;
      updated_at: string;
      appointment_id: number;
      comment: string | null;
    };
    billing_address: {
      id: number;
      country: string;
      city: string;
      zip_code: string;
      state: string;
      address_line_1: string;
      address_line_2: string;
      flat_number: string | null;
      created_at: string;
      updated_at: string;
      appointment_id: number;
    };
    time_zone_short: string;
  };
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  orderList_Data: OrderType[];
  isDialogOpen: boolean;
  searchQuery: string;
  adminSearch?: string;
  selectedValue: string | null;
  orderSummary: OrderSummary | null;
  isLoading: boolean;
  isOrderBlank: boolean;
  isFormChanged: boolean;
  dateError: string | null;
  totalError: string | null;
  filters?: FilterType;
  isCsvDownloading: boolean;
  pagination: PaginationType;
  isOpen: boolean,
  isTotalOpen: boolean,
  isDateOpen: boolean,
  deletionError: string;
  isServiceProviderOpen: boolean;
  staffData: Array<staffDataArray>;
  // Customizable Area End
}

interface SS {}
export default class OrderManagementDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  orderCategoryAddApiCallId: string = "";
  orderCategoryGetApiCallId: string = "";
  searchCategoryGetApiCallId: string = "";
  orderStatusGetApiCallId: string = "";
  appointmentGetApiCallId: string = "";
  getStaffListingApiCall: string = "";
  // Customizable Area End
  debounceTimer: NodeJS.Timeout | null = null;
  queryRef: React.RefObject<HTMLInputElement>;


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.queryRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];

    this.submitButtonRef = React.createRef();
    this.resetButtonRef = React.createRef();

    this.state = {
      token: "",
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      orderList_Data: [],
      isDialogOpen: false,
      searchQuery: '',
      orderSummary: null,
      selectedValue: null,
      isLoading: false,
      isOrderBlank: false,
      isFormChanged: false,
      totalError: null,
      dateError: null,
      isCsvDownloading: false,
      isOpen: true,
      isTotalOpen: true,
      isDateOpen: true,
      pagination: {} as PaginationType,
      deletionError: "",
      isServiceProviderOpen: true,
      staffData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start  
  downloadCSVMessageId: string = "";
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    this.getStaffListing();
    if (location.pathname.includes("OrderManagementDetails")) {
      this.callAPI();
    } else {
      this.getListOrder();
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleDownloadCSV(message);
      if (responseJson.data) {
        if (apiRequestCallId === this.orderCategoryGetApiCallId) {
          this.handleApiResponse(message, true);
        } else if (apiRequestCallId === this.orderCategoryAddApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.searchCategoryGetApiCallId) {
          this.handleApiResponse(message, false);
        } else if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.updateState(message);
          this.goBack();
        } else if (apiRequestCallId === this.appointmentGetApiCallId) {
          this.updateState(message);
        } else if (apiRequestCallId === this.getStaffListingApiCall) {
          this.getStaffListingSuccess(responseJson)
        }
      } else if (responseJson.errors) {
        if (apiRequestCallId === this.orderStatusGetApiCallId) {
          this.handlePostOrderStatusFailureResponse(responseJson);
          this.setState({ isLoading: false });
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
          this.setState({ isLoading: false });
        }
      }

    }
  }

  callApi = async (data: APIPayloadType) => {
    const {contentType, method, endPoint, body } = data;
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

   const token= await getStorageData("admintoken")
    const header = {
      "Content-Type": contentType,
      token
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return requestMessage.messageId;
  };

  handleApiResponse(message: Message, isOrderBlank: boolean) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({ isOrderBlank });
    this.setState({ orderList_Data: responseJson.data, pagination: responseJson.metadata?.meta?.pagination });
    this.setState({ isLoading: false });
  }

  handlePostOrderStatusFailureResponse = (failureResponse: PostOrderStatusFailureResponse) => {
    this.setState({
      deletionError: failureResponse.errors[0]
    }, () => {
      setTimeout(() => {
        this.setState({ deletionError: "" });
      }, 4000);
    })
  }

  getStaffListing = async() => {
    this.getStaffListingApiCall = await this.callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.getStaffList
    })
  };

  getStaffListingSuccess = (responseJson: StaffData) => {
    let responseData = responseJson.data;

    responseData.forEach((item: staffDataArray) => {
      item["isChecked"] = false
    });
    this.setState({ staffData: responseData })
  };

  filterOrder = async(values: FilterType, page = 1) => {
    mixpanel.track("webadmin_order_list_filter");
    this.setState({ isLoading: true, filters: values, adminSearch: "" })
    const header = {};

    let complete = ""
    let cancelled = ""
    let inProgress = ""
    let confirmed = ""
    let refunded = ""
    let placed = ""
    let rescheduled = ""
    let processRefund = ""
    let refundRequested = ""
    let paymentFailed = ""
    let pending = ""


    let apiEndPoint = configJSON.orderlistfilterEndPoint + "?page=" + page;

    const checkboxValue = values.checkboxes

    if (values.from_date != null && values.from_date != undefined) {
      apiEndPoint += "&[start_date]=" + values.from_date;
    }

    if (values.to_date != null && values.to_date != undefined) {
      apiEndPoint += "&[end_date]=" + values.to_date;
    }


    if (checkboxValue.Confirmed) {
      confirmed = "confirmed"

      apiEndPoint += "&[status][]=" + confirmed;
    }

    if (checkboxValue.Completed) {
      complete = "completed"
      apiEndPoint += "&[status][]=" + complete;
    }


    if (checkboxValue.InProgress) {
      inProgress = "in_progress"
      apiEndPoint += "&[status][]=" + inProgress;
    }

    if (checkboxValue.Cancelled) {
      cancelled = "cancelled"
      apiEndPoint += "&[status][]=" + cancelled;
    }

    if (checkboxValue.Refunded) {
      refunded = "refunded"
      apiEndPoint += "&[status][]=" + refunded;
    }

    if (checkboxValue.placed) {
      placed = "placed"
      apiEndPoint += "&[status][]=" + placed;
    }

    if (checkboxValue.Rescheduled) {
      rescheduled = "rescheduled"
      apiEndPoint += "&[status][]=" + rescheduled;
    }

    if (checkboxValue.ProcessRefund) {
      processRefund = "process_refund"
      apiEndPoint += "&[status][]=" + processRefund;
    }

    if (checkboxValue.RefundRequested) {
      refundRequested = "refund_requested"
      apiEndPoint += "&[status][]=" + refundRequested;
    }

    if (checkboxValue.PaymentFailed) {
      paymentFailed = "payment_failed"
      apiEndPoint += "&[status][]=" + paymentFailed;
    }

    if (checkboxValue.PaymentPending) {
      pending = "pending"
      apiEndPoint += "&[status][]=" + pending;
    }

    if (values.integerField1 != null && values.integerField1 != undefined) {
      apiEndPoint += "&[total][from]=" + values.integerField1;
    }

    if (values.integerField2 != null && values.integerField2 != undefined) {
      apiEndPoint += "&[total][to]=" + values.integerField2;
    }
    if (values.checkboxesServiceProvider != null && values.checkboxesServiceProvider != undefined) {
      apiEndPoint += "&[service_provider_id]=" + values.checkboxesServiceProvider;
    }

    this.orderCategoryAddApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType,
    });
  };


  serach_orders = async(searchtxt: string) => {
    const header = {};
    const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${searchtxt}`;
    this.searchCategoryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });

  }


  serach_orders_list = (queryRef: RefObject<HTMLInputElement>) => {

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const query = (queryRef.current && queryRef.current.value) || "";

      this.adminSearch(query);
    }, 800);


  }

  adminSearch = async(search: string, page = 1) => {
    this.setState({ isLoading: true, filters: undefined, adminSearch: search })
    if (search.toString().length > 0) {
      mixpanel.track("webadmin_order_list_search");
      const header = {};
      const apiEndPoint = configJSON.ordercategoryApiEndPoint + `?search_query=${search}&page=${page}`;
      this.searchCategoryGetApiCallId = await this.callApi({
        method: configJSON.listOfOrdersMethod,
        endPoint: apiEndPoint,
        contentType: configJSON.apiContentType
      });
    } else if (search.toString().length == 0) {
      this.getListOrder()
    }
    clearTimeout(this.debounceTimer!);
  }

  getListOrder = async(page = 1) => {
    mixpanel.track("webadmin_order_list_enter");
    this.setState({ isLoading: true, filters: undefined, adminSearch: "" })
    const header = {};
    this.orderCategoryGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: configJSON.orderlistEndPoint + "?page=" + page,
      contentType: configJSON.apiContentType
    });
  };

  getList = async (itemId: string) => {
    await setStorageData("orderID", itemId.toString());
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList/OrderManagementDetails");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);

  }


  orderStatus = async(itemId: string | null, status: { selectedValue: string | null }) => {
    this.setState({ isLoading: true })
    const header = {};
    const body = new FormData();
    body.append("[appointment][status]", status.selectedValue + "");
    mixpanel.track("webadmin_order_detail_update");

    this.orderStatusGetApiCallId = await this.callApi({
      method: configJSON.createOrderMethod,
      endPoint: configJSON.orderstatusApiEndPoint + `?id=${itemId}`,
      body
    });
  };



  appointment_summary = async(itemId: string | null) => {
    mixpanel.track("webadmin_order_detail_enter", { itemId });
    const header = {};
    const apiEndPoint = configJSON.routeAppointmentSummary + `?id=${itemId}`;
    this.appointmentGetApiCallId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });

  }

  handleDownloadCSV = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.downloadCSVMessageId === apiRequestCallId) {
      this.setState({ isCsvDownloading: false });
      if (responseJson.csv_data) {
        const csvRows = responseJson.csv_data;
        let csvContent = "data:text/csv;charset=utf-8,";

        csvRows.forEach(function (rowArray: string[]) {
          let rowData = rowArray.join(",");
          csvContent += rowData + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  }

  download = async() => {
    const header = {};
    const apiEndPoint = configJSON.routeDownloadCSV;
    this.downloadCSVMessageId = await this.callApi({
      method: configJSON.listOfOrdersMethod,
      endPoint: apiEndPoint,
      contentType: configJSON.apiContentType
    });
    this.setState({ isCsvDownloading: true });
  }


  handleStatusChange = (newValue: string | null) => {
    this.setState({ selectedValue: newValue });
  }

  updateState = (message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const response = responseJson.data;
    this.setState({ isLoading: false });
    this.setState({ orderSummary: response });
    this.setState({ selectedValue: response.attributes.status });
  }

  resetFilter = () => {
    this.getListOrder();
    this.setState({ isDialogOpen: false, dateError: null, totalError: null, filters: undefined, adminSearch: "" });
  }


  submitButtonRef: RefObject<HTMLButtonElement>;
  resetButtonRef: RefObject<HTMLButtonElement>;

  callAPI = async () => {
    const itemId = await getStorageData("orderID")
    this.appointment_summary(itemId)
  }

  formatDate = (inputDate: string): string => {
    const parsedDaties = new Date(inputDate);

    if (isNaN(parsedDaties.getTime())) {
      return "Invalid Date";
    }

    const optiones: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', optiones);
    let formattedDate = formatter.format(parsedDaties);

    formattedDate = formattedDate.replace(/, (\d{4})/, ' $1,');
    formattedDate = formattedDate.replace(" at", "");
    formattedDate = formattedDate.replace(/(\d{1,2}) /, '$1 ');

    return formattedDate;
  };



  handleChange = (event: string, newValue: string | null) => {
    this.setState({ selectedValue: newValue });
  };

  handleSubmitDetail = async (values: { selectedValue: string | null }) => {
    const itemId = await getStorageData("orderID");
    this.orderStatus(itemId, values)
  };

  saveChanges = () => {
    if (this.submitButtonRef.current) {
      this.submitButtonRef.current.click();
      this.setState({ isFormChanged: false })

    }
  };

  discardChanges = () => {
    if (this.resetButtonRef.current) {
      this.resetButtonRef.current.click();
    }
  };


  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false, dateError: null, totalError: null });
  };


  handleSubmitList = (values: FilterType) => {
    let isError = false;
    const { from_date, to_date, integerField1, integerField2 } = values;
    if ((from_date && !to_date) || (to_date && !from_date)) {
      isError = true;
      this.setState({ dateError: configJSON.textSelectDateValidation });
    } else {
      this.setState({ dateError: null })
    }
    if ((integerField1 && !integerField2) || (integerField2 && !integerField1)) {
      isError = true;
      this.setState({ totalError: configJSON.textSelectAmountValidation })
    } else {
      this.setState({ totalError: null })
    }
    if (isError) {
      return;
    }
    this.filterOrder(values);
    this.handleDialogClose();

  };


  fetchSearchResults = () => {
    const { searchQuery } = this.state;

    if (searchQuery !== null && searchQuery !== '') {
      if (searchQuery.length > 0) {
        this.serach_orders(searchQuery)
      }

    }
  };

  handlePageChange = (page: number) => {
    if (this.state.filters) {
      this.filterOrder(this.state.filters, page);
    } else if (this.state.adminSearch) {
      this.adminSearch(this.state.adminSearch, page)
    } else {
      this.getListOrder(page);
    }
  }

  handleOrderDate = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
  };

  handleAppointmentDate = (appointment_date: string, time_zone_short: string) => {
    return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])");
  };

  handleOrder_Date = (order: string) => {
    return moment.utc(order).format(configJSON.dateFormat);
  };

  handleAppointmen_Date = (appointment_date: string, time_zone_short: string) => {
    return moment.utc(appointment_date).format(time_zone_short);
  };

  handleOrderStatus = (newValue: string | string[] | null) => {
    this.setState({ isFormChanged: true });
    if (newValue === null) {
      this.handleChange("selectedValue", "");
    } else if (Array.isArray(newValue)) {
      this.handleChange("selectedValue", newValue[0]);
    } else {
      this.handleChange('selectedValue', newValue);
    }
  };

  goBack = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "OrderMangementList");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  setIsTotalOpen = () => {
    this.setState({ isTotalOpen: !this.state.isTotalOpen });
  };

  setIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  setIsDateOpen = () => {
    this.setState({ isDateOpen: true });
  };

  setIsDateClose = () => {
    this.setState({ isDateOpen: false });
  };

  setIsServiceProviderClose = () => {
    this.setState({ isServiceProviderOpen: !this.state.isServiceProviderOpen });
  };

  // Customizable Area End
}
