// Customizable Area Start
import React from "react";
import {
  Category as CategoryType,
  EmailSettingsMap,
} from "./Types";
import EmailSettings from "./EmailSettings.web";

interface Props {
  categories: CategoryType[];
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
  emailSettings: EmailSettingsMap;
}

const Categories = ({
  categories,
  onActiveChange,
  emailSettings,
  onEditClick,
}: Props) => {
  return (
    <div className="categories">
      {categories.map((category, categoryIndex) => (
        <Category
          key={category.category_name + categoryIndex}
          data={category}
          onActiveChange={onActiveChange}
          onEditClick={onEditClick}
          emailSettings={emailSettings}
        />
      ))}
    </div>
  );
}

interface CategoryProps {
  data: CategoryType;
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
  emailSettings: EmailSettingsMap;
}

export const Category = ({
  data,
  onActiveChange,
  onEditClick,
  emailSettings,
}: CategoryProps) => {
  return (
    <div className="category">
      <span className="category-name">{data.category_name}</span>
      <div className="email-settings">
        {data.email_settings.map((setting) => (
          <EmailSettings
            key={setting.id}
            emailSettings={emailSettings[Number(setting.id)]}
            onActiveChange={onActiveChange}
            onEditClick={onEditClick}
          />
        ))}
      </div>
    </div>
  );
};

export default Categories;
// Customizable Area End