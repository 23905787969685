
export const config = {
	// Customizable Area Start
	creatPaymentIntentApiContentType: 'application/json',
	createpaymentIntentEndpoint: 'stripe_integration/payment_intents',
	createPaymentIntentMethod: 'POST',
	stripeKey:'pk_test_51Ls1IRSJgJuwTwj6NU6i9GAQdGmobrnyCFyfTkLfG398AZ4Tic2ZEOFHPoz6HPj2iaWW518QJ0jnGeINT6jC8PXz00cNeAJCwe',
	stripeCurrency: 'GBP',

	orderId: 'Order ID',
	loading: 'Loading...',
	cancelText: 'Cancel',
	submitText: 'Submit',

	stripeSuccessMessage: 'Successfully communicated with Stripe',
	stripeErrorMessage: 'There has been an error',
	paymentStripeEndPoint : "bx_block_stripe_integration/payment_intents"
	// Customizable Area End
};

