// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { PaginationType } from "../../ordermanagement/src/types";

export const configJSON = require("./config");

export interface Props {
  id: string;
}

type FormValues = {
  category_name: string;
  category_img: File | null;
};

export interface CategoriesData {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    created_at: string,
    updated_at: string,
    count: number,
    image_url: {
      url: string;
      thumbnail_url: string;
    }
  }
}

interface S {
  token: string;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  showChild: string;
  category_name: string;
  category_img: string;
  category_id: number;
  categories_Data: Array<CategoriesData>;
  selectedIds: Array<string>;
  isLoading:boolean;
  isError:boolean;
  msgError:string;
  deleteError:string;
  isSuccessfulyDelete:boolean
  pagination?: PaginationType;
  categoriesErrorRes: {message: string};
}

interface SS {
  id: number;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  updateCategoryEditApiCallId: string = "";
  deleteCategoryDelApiCallId: string = "";
  getCategoryAddApiCallId: string = "";
  getCategoryGetApiCallId: string = "";
  getCategoriesApiCallId: string = "";
  deleteCategoriesApiCallId: string = "";
  deleteSubCategoriesApiCallId: string = "";
  addCategoryApiCallId: string = "";
  addSubCategoryApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      showChild: "imagelist",
      category_name: "",
      category_img: "",
      category_id: 0,
      categories_Data: [],
      selectedIds: [],
      isLoading:false,
      isError:false,
      msgError:"",
      deleteError:"",
      isSuccessfulyDelete:false,
      categoriesErrorRes: {message: ""}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    this.getListCategory()
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    this.setState({isLoading:false})
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCategoryAddApiCallId && !responseJson.error) {
      this.setState({ showChild: "imagelist", categoriesErrorRes: { message: "" } });
      this.getListCategory();
      return;
    }

    if (apiRequestCallId === this.getCategoryGetApiCallId && responseJson) {
      if (responseJson.data) {
        this.setState({
          categories_Data: responseJson.data, pagination: responseJson.metadata?.meta?.pagination
        });
      } else if (responseJson.message == "No categories found") {
        this.setState({
          categoriesErrorRes: responseJson
        });
      }
    }

    if (apiRequestCallId === this.deleteCategoryDelApiCallId && !responseJson.error) {

      this.setState({isSuccessfulyDelete:true})
      setTimeout(() => {
        this.setState({isSuccessfulyDelete:false})
      }, 4000);

      this.getListCategory();
      return;
    }

    if (apiRequestCallId === this.updateCategoryEditApiCallId && !responseJson.error) {
      this.getListCategory();
      this.setState({ showChild: "imagelist" });
    }
    else if (responseJson.error) {
      // alert(responseJson.error)
      this.setState({ isError: true });
      this.setState({msgError:responseJson.error})
      setTimeout(() => {

        this.setState({ isError: false });
      }, 4000);
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigationMsg.messageId, navigationMsg);
  };

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };

  isStringNullOrBlank(strings: string) {
    return strings === null || strings.length === 0;
  }

  addCategory = async () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      const requestMessage = await generateRequestMessage(
        configJSON.categoryAPIEndPoint,
        configJSON.httpPostType
      )
      this.addCategoryApiCallId = requestMessage.messageId;
      let response = {
        categories: [{ name: this.state.category }]
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(response)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleAddCategory = () => {
    this.setState({ showChild: "addcategory" });
  };

  handleCreateCategory = () => {
    this.setState({ showChild: "addcategory" });
  };

  editCategory = (name: string, idNumber: number, image_url: string) => {
    this.setState({ category_img: image_url });
    this.setState({ category_name: name });
    this.setState({ category_id: idNumber });
    this.setState({ showChild: "editcategory" });
  };

  deleteCategory = async (categoryId: Array<string>) => {
    this.setState({
      selectedIds: categoryId
    },
      async () => {
        this.setState({isLoading:true})
        const requestMessage = await generateRequestMessage(
          configJSON.deleteCategortApiEndPoint,
          configJSON.httpDeleteType
        )
        this.deleteCategoryDelApiCallId = requestMessage.messageId;
        //GO TO REQUEST STATE

        const httpBody = {
          category_ids: categoryId
        };

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    );


  };


  getListCategory = (page = 1) => {
    this.setState({isLoading:true})
    const header = {};
    this.getCategoryGetApiCallId = BlockHelpers.callApi({
      method: configJSON.httpGetType,
      endPoint: configJSON.addCategoryApiEndPoint + "?page=" + page,
      header
    });
  };

  addimages = (values: FormValues) => {
    this.setState({isLoading:true})
    const header = {};
    const body = new FormData();
    body.append("[category][name]", values.category_name);
    if (values.category_img) {
      body.append("[category][image]", values.category_img);
    }
    this.getCategoryAddApiCallId = BlockHelpers.callApi({
      method: configJSON.httpPostType,
      endPoint: configJSON.addCategoryApiEndPoint,
      header,
      body
    });
  };
  isLoadingTrue=()=>{
    this.setState({isLoading:true})
  }

  editimages = async (idNumber: number, values: FormValues) => {


    const header = {};
    const body = new FormData();
    body.append("[category][name]", values.category_name);
    if (values.category_img) {
      body.append("[category][image]", values.category_img);
    }
    this.updateCategoryEditApiCallId = BlockHelpers.callApi({
      method: configJSON.httpPatchType,
      endPoint: configJSON.updateCategoryAPiEndPoint + `/${idNumber}`,
      header,
      body
    });
  };

  back = () => {
    this.setState({ showChild: "imagelist" });
  };

  backeditlist = () => {
    this.setState({ showChild: "imagelist" });
  };

  imageRemove = () =>{
    this.setState({ category_img: "" });
  }

  dialogClose = () =>{
    this.setState({ isError: false });
  }

  handlePageChange = (page: number) => {
    this.getListCategory(page);
  }

}

// Customizable Area End