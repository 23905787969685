// Customizable Area Start
import React from "react";
import PaymentMethodsController from "./PaymentMethodsController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const configJSON = require("./config");
import {withStyles, createStyles, Theme} from "@material-ui/core";

export class PaymentMethods extends PaymentMethodsController {
  render() {
    const {classes} = this.props;
    return (
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.titleWrapper}>
            <label className={classes.pageTitle}>{configJSON.enablePaymentMode}</label>
          </div>
          <div className={classes.itemContainer}>
            <FormGroup>
              {this.props.paymentMethods?.map((paymentMethod) => (
                <FormControlLabel
                  key={paymentMethod.id}
                  className={classes.item}
                  control={
                    <Checkbox
                      data-testid="payment-method-checkbox"
                      checked={paymentMethod?.attributes?.active}
                      onChange={() => this.handlePaymentMethodChange(paymentMethod)}
                    />
                  }
                  label={paymentMethod?.attributes?.payment_method}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

export const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      "backgroundColor": "#fff",
      "width": "65%",
      "padding": "40px",
      "border": "2px solid #ecedf0",
      "borderRadius": "3px"
    },
    titleWrapper:{
      "marginBottom": "25px"
    },
    pageTitle:{
      "fontFamily": "Rubik, sans-serif",
      "fontStyle": "normal",
      "fontWeight": 500,
      "fontSize": "12px",
      "lineHeight": "20px",
      "color": "#3c3e49"
    },
    itemContainer: {
      "marginBottom": "35px"
    },
    item:{
      "color": "#3c3e49"
    }
  });

export default withStyles(styles)(withDialog(withLoader(PaymentMethods)));
// Customizable Area End