Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.getServiceApiEndPoint = "bx_block_account_groups/service_providers/store_services"
exports.addStaffApiEndPoint = "bx_block_account_groups/service_providers"
exports.getStaffListingApiEndPoint = "bx_block_account_groups/customer_service_providers";

exports.errorTitle = "Error";
exports.errorText = "Error!  ";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.textHome = "Home";
exports.textServiceProviders = "Stylists";
exports.paginationText = "Staff";
exports.bookingService = "Staff";
exports.deleteServices = "Delete Staff";
exports.addService = "Add service";
exports.fullName = "Full name";
exports.email = "Email";
exports.number = "Number";
exports.designation = "Designation";
exports.description = "Description";
exports.edit = "Edit";
exports.deleteServices = "Delete Staff";
exports.getStaffList = "bx_block_account_groups/service_providers/?per_page=10&page=";
exports.searchTheme = "search_term";
exports.noServiceProvider = "No Service Providers Added";
exports.noServiceDes = "No Service Providers Added yet, Click below to add service providers and manager services";
exports.addService = "Add new service provider";
exports.deleteStaffEndpoint = "bx_block_account_groups/service_providers/delete_all?";
exports.deleteSuccess = "Are you sure you want to delete the selected staff ?";
exports.hash = "#";
exports.success = "Success! "
exports.getCategoriesEndPoint = "bx_block_account_groups/customer_service_providers/categories_with_service_provider";
exports.getSearchCategoriesEndPoint = "bx_block_account_groups/customer_service_providers/filter_service_provider";
exports.textNoResultsFound = "No Results Found!";
exports.textNoResultHint = "Please refine your search.";
exports.textBookNow = "Book now";
exports.getEditStaffDataApiEndPoint = "bx_block_account_groups/service_providers/";
exports.searchStaffEndPoint = "bx_block_account_groups/service_providers?search_term="
exports.textStyle = "Stylist";
exports.textServices = "Services";
exports.getStaffDetailsEndPoint = "bx_block_account_groups/customer_service_providers";
exports.getAppointmentHoursApiEndPoint = "bx_block_settings/common_settings/all_appointment_hours";

exports.textFilter = "Filter";
exports.getSearchStaffData = "bx_block_catalogue/catalogues/search?status=active"
exports.getSearchStaffEndPoint = "bx_block_account_groups/service_providers/search_provider";
exports.searchResultText = "Search Result";

exports.textService = "SERVICES";
exports.textServiceProvider = "SERVICE PROVIDERS";
exports.textMins = "mins";
exports.getBrandEndPoint = "bx_block_catalogue/brands/home_brand_header";
exports.viewServiceProviderListEvent='webcustomer_viewed_service_provider_list';
exports.updateServiceProviderAvailabilityEvent='webadmin_update_service_provider_availability';
exports.selectCategoryServiceProviderEvent='webcustomer_select_category_service_provider';
exports.staffMemberSelectedEvent='webcustomer_staff_member_selected';
exports.selectServicesItemEvent='webcustomer_services_select_item';
exports.staffPageEnter = "webadmin_staff_page_enter";
exports.addNewStaffButtonClicked = "webadmin_staff_add_new_staff_button_clicked";
exports.staffEdited = "webadmin_staff_edited";
exports.newStaffCreated = "webadmin_new_staff_created";
exports.staffDelete = "webadmin_staff_delete";
exports.staffDeleteSuccess = "webadmin_staff_delete_success";
exports.staffSelectItem = "webcustomer_staff_select_item";
exports.navigateStaffListing = "webcustomer_navigate_staff_listing_page";
// Customizable Area End
