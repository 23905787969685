// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { Navigation } from "../../utilities/src/models/Navigation";
import StorageProvider from "../../../framework/src/StorageProvider";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export const configJSON = require("./config");

export interface Props {
    navigation: Navigation;
    identifier: string;
    classes: Record<string, string>;
}

export interface StaffDataObj {
    id: string;
    type: string;
    attributes: {
        id: number;
        full_name: string;
        designation: string;
        description: string;
        services: Array<string>
        images: [
            {
                id: number;
                url: string;
                thumbnail_url:string;
                small_url:string;
            }
        ]
    }
}

export interface StaffDetailsData {
    data: StaffDataObj
}

interface Brand {
    data: {
        attributes: {
            header: {
                navigation_item_2: string
            }
        }
    }
}
// Customizable Area End

interface S {
    // Customizable Area Start
    service: StaffDataObj | null;
    selectedImageIndex: number;
    renameStylist: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    identifier: string;
    // Customizable Area End
}

export default class StaffDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getStaffDetailsApiCallId: string = "";
    getBrandNameApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        // Customizable Area Start
        // Customizable Area End
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            // Customizable Area Start
            service: null,
            selectedImageIndex: 0,
            renameStylist: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getStaffDetails();
        this.getBrandName();
        mixpanel.track(configJSON.staffSelectItem);
        // Customizable Area End
    }

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStaffDetailsApiCallId != null &&
            this.getStaffDetailsApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handleGetCatalgoueResponse(responseJson);
            } else {
                const errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
       else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBrandNameApiCallId != null &&
            this.getBrandNameApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(responseJson && !responseJson.errors && responseJson.data){
                this.getBrandSuccessCallBack(responseJson)
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    handleGetCatalgoueResponse = (responseJson: StaffDetailsData) => {
        const response = responseJson.data;

        this.setState({
            service: response
        });
    };

    getStaffDetails = () => {
        const header = {
            "Content-Type": configJSON.apiContentType
        };

        const catalogueId = this.props.navigation.getParam("id");

        this.getStaffDetailsApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getStaffDetailsEndPoint}/${catalogueId}`,
            header
        });
    };

    previewImage = (index: number) => {
        this.setState({
            selectedImageIndex: index
        });
    };

    homeNavigation = () => {
        this.handleNavigation("Home");
    };

    servicesNavigation = () => {
        this.handleNavigation("ServiceProviders");
    };

    appoinmentNavigation = (catalogueId: string) => {
        mixpanel.track("webcustomer_select_service_provider", { itemId: catalogueId });
        this.handleNavigation("Appointments");
        StorageProvider.set("catalogueId", catalogueId);
        StorageProvider.set("navigationType", "serviceProvider");
    };

    handleNavigation = (route: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), route);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    }

    getBrandName = () => {
        const header = {
            "Content-Type": configJSON.apiContentType
        };

        this.getBrandNameApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: configJSON.getBrandEndPoint,
            header
        });
    };

    getBrandSuccessCallBack = (responseJson: Brand) => {
        this.setState({renameStylist: responseJson.data.attributes.header.navigation_item_2});
    };
}
// Customizable Area End
