// Customizable Area Start
import React from "react";
import CountdownTimerController from "./CountdownTimerController.web"

export default class CountdownTimer extends CountdownTimerController {
  render() {
    const { remainingTime } = this.state;
    return (
      <div
        id="count-down-timer"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: "red",
          cursor: remainingTime < 0 ? "pointer" : undefined,
          textDecoration: remainingTime < 0 ? "underline" : undefined,
        }}
        onClick={remainingTime < 0 ? this.props.resendOtp : undefined}
      >
        {this.timeToText(remainingTime)}
      </div>
    );
  }
}
// Customizable Area End