import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Card,
  withStyles,
  Grid,
  Typography,
  DialogContentText,
  DialogActions,
  DialogContent,
  CircularProgress,
  IconButton,
  CardContent,
  Collapse,
  CardActions,
  Dialog,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import AppointmentRescheduleController, {
  AppointmentListData,
  configJSON,
} from "./AppointmentRescheduleController.web";
import Calendar from "react-calendar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import "react-calendar/dist/Calendar.css";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowIcon
} from "./assets";
import Currency from "../../utilities/src/components/Currency.web";
import Skeleton from "@material-ui/lab/Skeleton";

// Customizable Area End

// Customizable Area Start
export const webStyles = (theme: Theme) => ({
  cardContainers: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "auto",
      width: "100%",
    },
  },
  cards: {
    display: "flex",
    padding: "40px",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    "@media(max-width: 480px)": {
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    },
  },
  cardShadowStyle: {
    "@media(max-width: 480px)": {
      boxShadow:
        "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    },
  },
  cardSmall: {
    flexBasis: "40%",
  },
  wrapperClass: {
    maxWidth: "718px",
    margin: "30px 33px 60px",
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 5px",
      padding: "0px",
    },
  },
  timeSlotResheduled: {
    border: "1px solid",
    padding: "10px",
    flexGrow: 1,
    fontSize: "12px",
    fontFamily: "Rubik",
    lineHeight: "20px",
    fontWeight: 500,
    borderRadius: "3px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 2px",
    },
  },
  dataAndTimeHead: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    color: "#0000000",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px",
    },
    "@media(max-width: 480px)": {
      padding: "10px",
    },
  },
  containerBoxSize: {
    maxWidth: "1440px",
    margin: "40px auto",
    "@media(max-width: 880px)": {
      margin: "15px 8px",
    },
    "@media(max-width: 480px)": {
      margin: "10px 7px",
    },
  },
  proceedButton: {
    color: "white",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    "& .MuiButton-label": {
      textTransform: "none",
    },
  },
  buttonEnable: {
    backgroundColor: "#3FC1CB",
  },
  buttonDisable: {
    backgroundColor: "#D0D2DA",
  },
  headSection: {
    marginTop: "10px",
    padding: "0px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 14px",
    },
  },
  cancelTextButton: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#3FC1CB",
    marginLeft: "5px",
    lineHeight: "24px",
    cursor: "pointer",
    textDecoration: "underline",
  },
  arrowIconImage: {
    color: "#3FC1CB",
    width: "12px",
    height: "12px",
    cursor: "pointer",
  },
  headText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    color: "#3C3E49",
    margin: "20px 0px",
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  appointmentTextSize: {
    fontFamily: "Rubik,",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#000000",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  serviceTextSize: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#757575",
    marginBottom: "16px",
  },
  appoinmentsubContainers: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "9px",
    "@media(max-width: 480px)": {
      marginBottom: "0px",
    },
  },
  appoinmentsubContainerText: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #DFDFDF",
    marginBottom: "9px",
    "@media(max-width: 480px)": {
      marginBottom: "0px",
    },
  },
  bottomSectionSize: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    marginTop: "20px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  titleSectionStyle: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#000000",
  },
  titleSectionSize: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
  },
  serviceAndDurationText: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
  },
  textEnds: {
    textAlign: "end" as "end",
  },
  dialogContentBox: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: "400 !important" as "bold",
    width: "65%",
    margin: "auto",
  },
  dialogCancelBtn: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
  },
  expandText: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px",
  },
  expandsOpen: {
    transform: "rotate(180deg)",
  },
  collapseCardBox: {
    padding: "8px 0px !important",
  },
  availableSlotBox: {
    width: "100%",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "25px",
    },
  },
  calendarArrowImage: {
    width: "13.49px !important",
    height: "12.59px !important",
    color: "#676B7E !important",
  },
  serviceProviderBox: {
    fontWeight: 400,
    fontSize: "14px",
    margin: "11px 0 15px 0",
  },
  calendarCustomClassBox: {
    "& .react-calendar__navigation": {
      justifyContent: "center",
      "& .react-calendar__navigation__label": {
        flexGrow: "0.4 !important",
      },
    },
    "& .react-calendar__navigation button:enabled:hover": {
      backgroundColor: "transparent",
    },
    "& .react-calendar__navigation button:enabled:focus": {
      backgroundColor: "transparent",
    },
  },
  confirmationDialogBox: {
    textAlign: "center" as "center",
  },
  totalBorderSize: {
    marginBottom: "15px",
  },
  StaffImageSize: {
    width: "35px",
    height: "35px",
    borderRadius: "4px",
    marginRight: "10px",
  },
  subContainerSize: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    borderTop: "1px solid #DFDFDF",
  },
  sectionFirstBox: {
    borderTop: "1px solid #DFDFDF",
  },
  textConatinerBoxSize: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px",
  },
  subHeadingMainBox: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
});

// Customizable Area End

export class AppointmentReschedule extends AppointmentRescheduleController {
  // Customizable Area Start

  renderCancelBackFunction = (classes: Record<string, string>) => {
    return (
      <Box className={classes.headSection}>
        <Box
          data-testid="backbuttonId"
          style={{ display: "inline-flex", alignItems: "center" }}
          onClick={this.handleBack}
        >
          <img src={ArrowIcon} alt="icon" className={classes.arrowIconImage} />
          <Typography className={classes.cancelTextButton}>
            {configJSON.cancelBackToAllStylists}
          </Typography>
        </Box>
        <Typography
          data-test-id="bookAppointment1Id"
          className={classes.headText}
        >
          {configJSON.RescheduleBookingappointment}
        </Typography>
      </Box>
    );
  };
  renderBookAppointment = (classes: Record<string, string>) => {
    return (
      <>
        <Box className={classes.containerBoxSize}>
          <Grid container className={classes.cardContainers} spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Card
                className={classes.cards}
                style={{ flexDirection: "column" }}
              >
                {!this.state.initialLoading && (
                  <>
                    <Box className={classes.dataAndTimeHead}>
                      {configJSON.dateAndTime}
                    </Box>
                    <Box style={{ margin: "auto" }}>
                      {this.state.selectedDate && (
                        <Calendar
                          data-testid="txtInputAvailableDate"
                          className={classes.calendarCustomClassBox}
                          value={this.state.selectedDate}
                          onChange={(value) => this.handleDateChange(value)}
                          tileDisabled={this.dateIsDisabled}
                          next2Label={null}
                          prev2Label={null}
                          nextLabel={
                            <img
                              src={ArrowForwardIcon}
                              alt="icon"
                              className={classes.calendarArrowImage}
                            />
                          }
                          prevLabel={
                            <img
                              src={ArrowBackIcon}
                              alt="icon"
                              className={classes.calendarArrowImage}
                            />
                          }
                        />
                      )}
                    </Box>
                  </>
                )}
                <Box className={classes.wrapperClass}>
                  {this.state.isLoading ? (
                    <>
                    {this.state.initialLoading ? ( <Grid container spacing={2}>
                   <Grid item xs={12}>
                     <Skeleton animation="wave" variant="rect" width="100%" height={50} />
                   </Grid>
                   <Grid item xs={12}>
                     <Skeleton animation="wave" variant="rect" width="100%" height={200} />
                   </Grid>
                 </Grid>):(<Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Grid>) }
                    </>
                  
                  ) : (
                    <>
                      {this.state.appointmentsList.length > 0 && (
                        <>
                          <Grid container>
                            <Box
                              className={classes.availableSlotBox}
                              style={{ textAlign: "center" }}
                            >
                              <Typography
                                data-testid="dateAndTimeId"
                                style={{
                                  fontFamily: "Rubik,",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "#3C3E49",
                                }}
                              >
                                {configJSON.availableTimes} ({configJSON.in}{" "}
                                {this.state.timeZone})
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              {this.renderSelectSlots(classes)}
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {this.state.isDataLenghtCheck && (
                        <Box
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginBottom: "50px",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Rubik",
                              fontWeight: 400,
                              fontSize: "14px",
                            }}
                          >
                            {configJSON.noSlotsAvailable}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {this.renderAppoinmentSummaryService(classes)}

              <Button
                data-testid="handelProceedFromDateTime"
                variant="contained"
                disabled={this.state.isDisabled}
                className={`${classes.proceedButton} ${
                  this.state.isDisabled ? classes.buttonDisable : classes.buttonEnable
                }`}
                onClick={this.handelProceedFromDateTime}
              >
                {configJSON.proceed}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  renderSelectSlots = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.appointmentsList.map(
          (slot: AppointmentListData, index: number) => {
            const slotID =
              slot.id === this.state.selectedSlot.id ? "#0097A7" : "#3C3E49";
            const availableSlots = slot.is_available ? "#3C3E49" : "#AFB2C0";
            const slotSelected =
              slot.id === this.state.selectedSlot.id ? "pointer" : "default";
            return (
              <Grid item xs={3} sm={3} md={3} key={slot.id}>
                <Box
                  data-testid={`selectSlot${index + 1}`}
                  className={classes.timeSlotResheduled}
                  style={{
                    border: `1px solid ${
                      slot.is_available ? slotID : "#AFB2C0"
                    }`,
                    backgroundColor:
                      slot.id === this.state.selectedSlot.id
                        ? "#E0F7FA"
                        : "transparent",
                    color:
                      slot.id === this.state.selectedSlot.id
                        ? "#0097A7"
                        : availableSlots,
                    textAlign: "center",
                    cursor: slot.is_available ? slotSelected : "not-allowed",
                  }}
                  onClick={() =>
                    slot.is_available && this.handelAvaibilityTimeSlot(slot)
                  }
                >
                  {this.convertTimeFormat(
                    typeof slot.slot_start_time === "string"
                      ? slot.slot_start_time
                      : ""
                  )}
                </Box>
              </Grid>
            );
          }
        )}
      </>
    );
  };
  renderAppoinmentSummaryService = (classes: Record<string, string>) => {
    return (
      <>
        {this.state.screenSize > 599 ? (
          <Card className={classes.cardSmall}>
            <Box style={{ padding: "30px" }}>
              {this.renderService(classes)}
              <Box className={classes.appoinmentsubContainerText}>
                <Typography variant="body1" className={classes.bottomSectionSize}>
                  {configJSON.total}
                </Typography>
                <Typography variant="body1" className={classes.bottomSectionSize}>
                  {this.state.isPriceLoading ? (
                   <Skeleton variant="text" animation="wave" width={60} />
                  ) : (
                    <Currency
                    text={`${this.state.mainTotal?.toFixed(2)} `}
                    disountPrice={""}
                    time={""}
                    hasDiscount={false}
                  />
                   
                  )}
                </Typography>
              </Box>
            </Box>
          </Card>
        ) : (
          <Card className={classes.cardShadowStyle} style={{ maxWidth: "1923px" }}>
            <Box style={{ padding: "20px 30px" }}>
              <CardActions disableSpacing className={classes.collapseCardBox}>
                <Typography variant="h6" className={classes.appointmentTextSize}>
                  {configJSON.appointmentSummary}
                </Typography>
                <IconButton
                  className={clsx(classes.expandText, {
                    [classes.expandsOpen]: this.state.expanded,
                  })}
                  data-testid="expandTestId"
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.collapseCardBox}>
                  <Typography
                    variant="h6"
                    className={classes.serviceTextSize}
                    style={{ textAlign: "left" }}
                  >
                    {configJSON.service}
                  </Typography>

                  <Box className={classes.totalBorderSize}>
                    <Box className={classes.appoinmentsubContainers}>
                      <Typography
                        variant="body1"
                        className={classes.serviceAndDurationText}
                      >
                        {this.state.orderserviceTitle},{" "}
                        {this.state.orderserviceDuration} {configJSON.mins}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.titleSectionSize} ${classes.textEnds}`}
                      >
                      {this.state.isPriceLoading ?
                      <Skeleton variant="text" animation="wave" width={60} /> :
                      <Currency text={`${String(this.state.mainTotal?.toFixed(2))} `}
                      disountPrice={String(this.state.cataloguePrice?.toFixed(2))}
                      time={""}
                      hasDiscount={this.state.mainTotal !== this.state.cataloguePrice} />}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.appoinmentsubContainers}>
                    {this.state.orderServiceProviderFullname &&  <Typography
                      variant="body1"
                      className={classes.serviceProviderBox}
                    >
                      <img
                        src={
                          this.state.orderserviceUrl ? this.state.orderserviceUrl:this.state.orderUrl
                        }
                        alt="image"
                        className={classes.StaffImageSize}
                      />
                      {configJSON.textAppointmentWith}
                      {
                        this.state.orderServiceProviderFullname.full_name
                      }
                    </Typography>}
                   
                  </Box>

                  <Box className={classes.sectionFirstBox}>
                    <Typography className={classes.subHeadingMainBox}>
                      {configJSON.serviceDateAndTime}
                    </Typography>
                    <Box sx={{ marginBottom: "16px" }}>
                      <Typography
                        variant="body1"
                        className={classes.titleSectionStyle}
                      >
                        {this.formatDate(this.state.selectedDate)}
                      </Typography>
                      {this.state.selectedSlot.slot_start_time ? (
                        <Typography
                          variant="body1"
                          className={classes.titleSectionStyle}
                          data-testid="timezone-0"
                        >
                          {this.convertTimeFormat(
                            `${this.state.selectedSlot.slot_start_time} `
                          ) +
                            " " +
                            `(${this.state.timeZone})`}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.subContainerSize}>
                    <Typography className={classes.subHeadingMainBox}>
                      {configJSON.personalDetails}
                    </Typography>
                    <Box style={{ marginTop: "10px" }}>
                      {this.state.orderFullname ? 
                       <Typography className={classes.textConatinerBoxSize}>
                       {this.state.orderFullname}
                     </Typography>:<Skeleton variant="text" animation="wave" width={180} />}
                     {this.state.orderEmail || this.state.orderPhoneNo ?
                     <Typography className={classes.textConatinerBoxSize}>
                     {this.state.orderEmail} | {this.state.orderPhoneNo}
                   </Typography>:<Skeleton variant="text" animation="wave" width={200} />}
                      <Typography className={classes.comment}>
                        {this.state.orderComment}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Collapse>
              <Box className={classes.appoinmentsubContainers}>
                <Typography variant="body1" className={classes.bottomSectionSize}>
                  {configJSON.total}
                </Typography>
                <Typography variant="body1" className={classes.bottomSectionSize}>
                {this.state.isPriceLoading ?
                      <Skeleton variant="text" animation="wave" width={60} /> :
                <Currency
                    text={`${this.state.mainTotal?.toFixed(2)} `}
                    disountPrice={""}
                    time={""}
                    hasDiscount={false}
                  />}
                </Typography>
              </Box>
            </Box>
          </Card>
        )}
      </>
    );
  };
  renderService = (classes: Record<string, string>) => {
    return (
      <>
        <Typography
          variant="h6"
          className={classes.appointmentTextSize}
          style={{ textAlign: "left" }}
        >
          {configJSON.appointmentSummary}
        </Typography>

        <Typography
          variant="h6"
          className={classes.serviceTextSize}
          style={{ textAlign: "left" }}
        >
          {configJSON.service}
        </Typography>

        <Box className={classes.appoinmentsubContainers}>
          <Typography variant="body1" className={classes.serviceAndDurationText}>
            {this.state.orderserviceTitle}, {this.state.orderserviceDuration}{" "}
            {configJSON.mins}
          </Typography>
          <Typography variant="body1" className={classes.titleSectionSize}>
            {this.state.isPriceLoading ? (
             <Skeleton variant="text" animation="wave" width={60} />
            ) : (
              <Currency text={`${String(this.state.mainTotal?.toFixed(2))} `}
              disountPrice={String(this.state.cataloguePrice?.toFixed(2))}
              time={""}
              hasDiscount={this.state.mainTotal !== this.state.cataloguePrice} /> )}
          </Typography>
        </Box>
        <Box className={classes.appoinmentsubContainers}>
          {this.state.orderServiceProviderFullname &&  <Typography variant="body1" className={classes.serviceProviderBox}>
            <img
              src={
                this.state.orderserviceUrl ? this.state.orderserviceUrl:this.state.orderUrl
              }
              alt="image"
              className={classes.StaffImageSize}
            />

            {configJSON.textAppointmentWith}
           {
               this.state.orderServiceProviderFullname.full_name
            }
          </Typography>}
         
        </Box>

        <Box className={classes.sectionFirstBox}>
          <Typography className={classes.subHeadingMainBox}>
            {configJSON.serviceDateAndTime}
          </Typography>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="body1" className={classes.titleSectionStyle}>
              {this.formatDate(this.state.selectedDate)}
            </Typography>
            {this.state.selectedSlot.slot_start_time ? (
              <Typography
                variant="body1"
                className={classes.titleSectionStyle}
                data-testid="timezone-0"
              >
                {this.convertTimeFormat(
                  `${this.state.selectedSlot.slot_start_time} `
                ) +
                  " " +
                  `(${this.state.timeZone})`}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box className={classes.subContainerSize}>
          <Typography className={classes.subHeadingMainBox}>
            {configJSON.personalDetails}
          </Typography>
          <Box style={{ marginTop: "10px" }}>
            {this.state.orderFullname ? <Typography className={classes.textConatinerBoxSize}>
              {this.state.orderFullname}
            </Typography>:<Skeleton variant="text" animation="wave" width={180} /> }
            {this.state.orderEmail || this.state.orderPhoneNo ? <Typography className={classes.textConatinerBoxSize}>
              {this.state.orderEmail} | {this.state.orderPhoneNo}
            </Typography>:<Skeleton variant="text" animation="wave" width={200} /> }
            <Typography className={classes.comment}>
              {this.state.orderComment}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };
  dialog = (classes: Record<string, string>) => {
    return (
      <Dialog  data-testid="handleCloseDialog" open={this.state.openDialog} onClose={this.handleCloseDialog}>
        <DialogContent className={classes.confirmationDialogBox}>
          <DialogContentText
            data-testid="areYouSureId"
            className={classes.dialogContentBox}
          >
            {this.state.deleteError}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Button
            data-testid="paymentModalNoId"
            onClick={this.handleCloseDialog}
            className={classes.dialogCancelBtn}
            style={{ textTransform: "none" }}
          >
            {configJSON.ok}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.renderCancelBackFunction(classes)}
        {this.renderBookAppointment(classes)}
        {this.dialog(classes)}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(webStyles)(AppointmentReschedule);
// Customizable Area End
