// Customizable Area Start
// @ts-nocheck
import * as React from 'react';
import { Nav as NavContainer, NavItem, Button } from 'reactstrap';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";
import { getStorageData } from "../../../../framework/src/Utilities";
import NavController from './NavController.web'

import { ReactComponent as ServiceIcon } from './assets/room-service-bring-plate.svg';
import { ReactComponent as CategoriesIcon } from './assets/layout-corners-dashboard-1.svg';
export const configJSON = require('./config')
// Customizable Area End
class NavComponent extends NavController {
    // Customizable Area Start
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            adminuser: null,
            loading: true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/Home'
                },
                {
                    id: "orders",
                    label: "Orders",
                    link: "/OrderMangementList",
                    iconName: "icon-orders",
                },
                {
                    id: "categories",
                    label: "Categories",
                    link: "/CategoriessubcategoriesWeb",
                    icon: CategoriesIcon,
                },
                {
                    id: "services",
                    label: "Services",
                    link: "/ServicesManagement",
                    icon: ServiceIcon,
                },
                {
                    id: "customers",
                    label: "Customers",
                    link: "/CustomerAdminList",
                    iconName: "icon-customer",
                },
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: "dashboard",
                            label: "Branding",
                            link: "/DashboardAdmin",
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/AdminCustomerFeedback'
                        },
                        {
                            id: "gallery",
                            label: "Gallery",
                            link: "/Gallery",
                        },
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: [
                        {
                            id: "common-settings",
                            label: "Common Settings",
                            link: "/CommonSettingsAdmin",
                        },
                        {
                            id: "staff",
                            label: "Staff",
                            link: "/AdminStaff",
                        },
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/AdminStoreDetails'
                        },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App',
                            link: '/AdminMobileApp'
                        },
                        {
                            id: 'Promo code',
                            label: 'Promo code',
                            link: '/AdminPromoCode'
                        },
                    ]
                },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-account',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'Profile',
                            iconName: 'icon-user',
                            link: '/AdminAccount'
                        },
                    ]
                }
            ],
            tierType: "",
        }
    }
    // Customizable Area End

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData("admintoken");
        const adminUser = await getStorageData("adminuser");
        if (token && adminUser) {
            this.setState({
                adminuser: JSON.parse(adminUser) || {},
                loading: false
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // @ts-ignore
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // Customizable Area End
        // @ts-ignore
        return (
            // Customizable Area Start
            <NavContainer
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType} />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType} />
                                : permissionLinks(data) !== undefined
                                    ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType} /> : ''
                    ))
                }
            </NavContainer>
            // Customizable Area End
        );

    }


}
// Customizable Area Start
export const Nav = withToast(NavComponent);
// Customizable Area End