// Customizable Area Start
import React from "react";
import { Typography, Container, Grid, Paper, Box, TextField, Button, Slide, CardMedia, CircularProgress, Card } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StudioIcon, ErrorIcon } from "./assets";
import { Formik, Field, ErrorMessage, FieldProps } from 'formik';
import OrderManagementDetailsController from "./OrderManagementDetailsController";
import Currency from "../../utilities/src/components/Currency.web";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

export default class OrderManagementDetails extends OrderManagementDetailsController {
    sliding = () => {
        return (
            <Slide direction="down" in={this.state.isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
                <Box style={webStyle.formActionsWrapper as React.CSSProperties}>
                    <Box style={webStyle.formActions}>
                        <CardMedia
                            component="img"
                            src={StudioIcon}
                            style={webStyle.logo}
                        />

                        <Box>
                            <Button
                                variant="text"
                                style={webStyle.discardChangesbutton}
                                onClick={this.discardChanges}
                            >
                                Discard changes
                            </Button>
                            <Button
                                variant="contained"
                                style={webStyle.saveChangesbutton}
                                onClick={this.saveChanges}
                            >
                                Save changes
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Slide>
        )
    }


    render() {
        const options = ['in_progress', 'confirmed', 'completed', 'cancelled', 'placed', 'process_refund'];
        const { orderSummary } = this.state;


        if (!orderSummary) {

            return (
                <Card style={webStyle.noServicesCard}>
                    <Box style={webStyle.loaderContainer}>
                        <Typography style={webStyle.loadingResultsText}>
                            Loading results
                        </Typography>
                        <span>
                            <CircularProgress size={24} />
                        </span>
                    </Box>
                </Card>
            )
        }

        const attributes = orderSummary.attributes;
        const {
            order_number,
            appointment_date,
            order_date,
            total,
            service,
            service_images,
            service_provider,
            payment_mode,
            customer,
            billing_address,
            time_zone_short,
            catalogue_price
        } = attributes;


        return (
            <>
                <Slide direction="down" in={!!this.state.deletionError} mountOnEnter unmountOnExit >
                    <Box style={webStyle.notificationWrapper}>
                        <Box style={webStyle.notification}>
                            <CardMedia
                                component="img"
                                src={require("../../servicemanagementAdmin/assets/Builder Studio Store.png")}
                                style={webStyle.logo1}
                            />

                            <Box style={webStyle.notificationMessage}>
                                {this.state.deletionError && (
                                    <>
                                        <img
                                            src={ErrorIcon}
                                            style={webStyle.notificationIcon}
                                            alt="image loading"
                                        />
                                        <Typography style={webStyle.notificationText}>
                                            <strong>Error! </strong>
                                            {this.state.deletionError}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Slide>
                <ThemeProvider theme={theme}>
                    {this.sliding()}

                    {this.state.isLoading ? (
                        <Card style={webStyle.noServicesCard}>
                            <Box style={webStyle.loaderContainer}>
                                <Typography style={webStyle.loadingResultsText}>
                                    Loading results
                                </Typography>
                                <span>
                                    <CircularProgress size={24} />
                                </span>
                            </Box>
                        </Card>
                    ) : (
                        <Container maxWidth='xl' style={webStyle.mainWrapper}>

                            <Grid container  >
                                <Grid item lg={12} md={12} sm={6} xs={12} >
                                    <Button
                                        variant="text"
                                        startIcon={<ArrowBackIcon />}
                                        style={webStyle.TextTitleBack}
                                        onClick={this.goBack}
                                    >
                                        <Typography>Back</Typography>
                                    </Button>
                                    <Typography variant="h3" style={webStyle.titleFirstText} >
                                        Service order summary
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid container spacing={3} style={webStyle.mainSecondContainer}>
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                    <Paper style={webStyle.mainSection}>
                                        <Box style={webStyle.parentDiv}>
                                            <Typography variant="h3" style={webStyle.titleSecText}>
                                                Appointment summary
                                            </Typography>
                                            <Box style={webStyle.childDiv} >
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Typography style={webStyle.firstTitle}>
                                                            Order ID: {order_number}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Typography style={webStyle.secondTitle}>
                                                            Order Date: {this.handleOrderDate(order_date)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box style={webStyle.childDivthird} >
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <img src={service_images.url} alt="image loading" style={webStyle.imageStyle} />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <Typography style={webStyle.serviceTitle} >
                                                        Service
                                                    </Typography>
                                                    <Typography style={webStyle.serviceTitle2} >
                                                        {service.title}, {service.duration} mins
                                                    </Typography>
                                                    <Typography style={webStyle.serviceminTitle2} >
                                                        {service_provider !== null && `Appoinment with ${service_provider.full_name}`}
                                                    </Typography>
                                                    <Typography style={webStyle.serviceTitle3}>
                                                        <Currency text={`${String(total.toFixed(2))} `} disountPrice={String(catalogue_price.toFixed(2))} time={""} hasDiscount={total === catalogue_price ? false :true}/>
                                                    </Typography>

                                                </Grid>
                                            </Box>
                                            <hr style={webStyle.linehorizontalStyle} />
                                            <Box style={webStyle.childDivthird} >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography style={webStyle.textDate} >
                                                        Appointment Date & Time
                                                    </Typography>
                                                    <Typography style={webStyle.textDay} >
                                                        {this.handleAppointmentDate(appointment_date, time_zone_short)}
                                                    </Typography>

                                                </Grid>
                                            </Box>
                                            <hr style={webStyle.linehorizontalStyle} />
                                            <Box style={webStyle.childDivthird} >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography style={webStyle.textDate} >
                                                        Personal details
                                                    </Typography>
                                                    <Typography style={webStyle.titleDetial1} >
                                                        {customer.full_name}
                                                    </Typography>
                                                    <Typography style={webStyle.titleDetial2} >
                                                        {customer.email} | {customer.full_phone_number}
                                                    </Typography>
                                                    <Typography style={webStyle.titleDetial3} >
                                                        {customer.comment}
                                                    </Typography>
                                                </Grid>
                                            </Box>
                                            <hr style={webStyle.linehorizontalStyle} />
                                            <Box style={webStyle.childDivthird} >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography style={webStyle.textDate} >
                                                        Mode of Payment
                                                    </Typography>
                                                    <Typography style={webStyle.textDay} >
                                                        {payment_mode === "pay_later" ? "Pay later at location" : "Online"}
                                                    </Typography>

                                                </Grid>
                                            </Box>
                                            <hr style={webStyle.linehorizontalStyle} />
                                            <Box style={webStyle.childDiv} >
                                                <Grid container spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Typography style={webStyle.totalText} >
                                                            Total
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Typography style={webStyle.totalPrice}>
                                                            <Currency text={String(total.toFixed(2))} disountPrice={""} time={""} hasDiscount={false}/>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Paper style={webStyle.mainSecSection} >
                                        <Box style={webStyle.parentDiv}>
                                            <Typography variant="h3" style={webStyle.titleSecText}>
                                                Status
                                            </Typography>
                                            <Formik
                                                data-test-id="formik"
                                                initialValues={{ selectedValue: this.state.selectedValue }}
                                                onSubmit={this.handleSubmitDetail}
                                                validate={(values) => {
                                                    const errors: Record<string, string> = {};
                                                    if (!values.selectedValue) {
                                                        errors.selectedValue = 'Required';
                                                    }
                                                    return errors;
                                                }}
                                                enableReinitialize

                                            >
                                                {({ values, handleChange, handleSubmit }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <Box style={webStyle.childDiv}>
                                                            <Grid container spacing={3}>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Typography style={webStyle.textInputField}>
                                                                        Order status *
                                                                    </Typography>
                                                                    <Field name="selectedValue">
                                                                        {(fieldProps: FieldProps) => (
                                                                            <Autocomplete
                                                                                {...fieldProps.field}
                                                                                fullWidth
                                                                                options={options}
                                                                                getOptionLabel={(option: string) => option === 'in_progress' ? 'In Progress' : option === 'process_refund' ? 'Process refund' : option === 'refund_requested' ? 'Refund requested' : option.charAt(0).toUpperCase() + option.slice(1)}
                                                                                getOptionSelected={(option: string, value: string) => option === value}
                                                                                onChange={(event, newValue) => this.handleOrderStatus(newValue)}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        variant="outlined"
                                                                                        error={fieldProps.meta.touched && !!fieldProps.meta.error}
                                                                                    />
                                                                                )}
                                                                                data-testid="orderStatusTestId"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage name="selectedValue" component="div" />
                                                                    <Button
                                                                        ref={this.resetButtonRef}
                                                                        style={webStyle.hidden}
                                                                        onClick={() => this.callAPI()}
                                                                    />
                                                                    <Button
                                                                        ref={this.submitButtonRef}
                                                                        type="submit"
                                                                        data-test-id="submitClick"
                                                                        style={webStyle.hidden}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>

                                                    </form>
                                                )}
                                            </Formik>

                                        </Box>
                                    </Paper>
                                    {payment_mode === "pay_later" ? "" :
                                        <Paper style={webStyle.mainSecSection}>
                                            <Box style={webStyle.parentDiv}>
                                                <Typography variant="h3" style={webStyle.titleSecText}>
                                                    Customer address
                                                </Typography>
                                                <Box style={webStyle.childDiv} >
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.flat_number}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.address_line_1}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.address_line_2}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.city}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.state}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.country}
                                                            </Typography>
                                                            <Typography style={webStyle.textaddresstitle} >
                                                                {billing_address?.zip_code}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                    )}


                </ThemeProvider>
            </>

        );
    }
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
});

const webStyle = {
    notificationWrapper: {
        top: 0,
        left: 0,
        position: "fixed" as "fixed",
        width: "100%",
        zIndex: 1,
    },
    notification: {
        display: "flex",
        backgroundColor: "#3C3E49",
        padding: theme.spacing(1.25, 3),
    },
    logo1: {
        position: "absolute" as "absolute",
        width: "159px",
        objectFit: "none" as "none",
        height: "calc(100% - 20px)",
    },
    notificationMessage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#3C3E49",
        height: "48px",
        flex: 1,
    },
    notificationIcon: {
        color: "transparent",
    },
    notificationText: {
        padding: theme.spacing(0, 1.25),
        fontSize: "14px",
        lineHeight: "20px",
        color: "#FFFFFF",
    },
    imgTextStyle: {
        width: '12px',
        marginRight: '3px',
    },
    TextTitleBack: {
        textTransform: "none" as "none",
        color: '#676B7E',
        fontFamily: 'Rubik',
        fontSize: '12px',
        lineHeight: '22px',
        cursor: "pointer",
        marginTop: "30px"
    },
    hidden: {
        display: "none"
    },
    titleFirstText: {
        fontFamily: 'Rubik',
        fontSize: '28px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '-0.02em',
        textAlign: "left" as "left",
        color: '#3C3E49',
    },
    mainWrapper: {
        paddingBottom: 32,
        background: "#F8F9FA",

        marginTop: '20px',

    },
    firstSectionDiv: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '30px'
    },
    mainContainer: {
        justifyContent: "space-between",
        alignItems: "cener"
    },
    mainSecondContainer: {
        marginTop: '10px',
    },
    mainSection: {
        marginTop: '15px',
        width: '100%',
        height: "auto",
        border: '1px solid #ECEDF0',
        boxShadow: 'none',
    },
    mainSecSection: {
        marginTop: '15px',
        width: '100%',
        height: "263px",
        border: '1px solid #ECEDF0',
        boxShadow: 'none',
    },
    titleSecText: {
        color: '#3C3E49',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '28px',
        fontFamily: 'Rubik'
    },
    parentDiv: {
        padding: '20px',
        marginTop: '20px',
    },
    childDiv: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: '10px',
    },
    firstTitle: {
        fontSize: '14px',
        color: '#757575',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik'
    },
    secondTitle: {
        fontSize: '14px',
        color: '#757575',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        textAlign: 'right' as 'right',
    },
    childDivthird: {
        width: '722px',
        margin: '0px',
        display: "flex",
    },
    serviceTitle: {
        color: '#757575',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        marginTop: '20px',
        paddingLeft: '10px',
    },
    serviceTitle2: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        marginTop: '5px',
        width: '941px',
        paddingLeft: '10px',

    },
    serviceminTitle2: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        width: '240px',
        paddingLeft: '10px',
    },
    serviceTitle3: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        marginTop: '0px',
        paddingLeft: '10px',
    },
    textDate: {
        color: '#757575',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        marginTop: '20px',
    },
    textDay: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        marginTop: '12px',
    },
    imageStyle: {
        marginTop: '22px',
        height: '168px',
        width: '100%',
        objectFit: 'cover' as 'cover',
        maxWidth: '136px',
    },
    linehorizontalStyle: {
        marginTop: '25px',
        border: '1px solid rgb(177, 169, 169)',
    },
    totalText: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        fontFamily: 'Rubik',

    },
    totalPrice: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        fontFamily: 'Rubik',
        textAlign: 'right' as 'right',
    },
    textInputField: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left' as 'left'
    },
    textaddresstitle: {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
    },
    titleDetial3: {
        color: '#8C8C8C',
        fontStyle: 'italic',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    titleDetial2: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#000000',
    },
    titleDetial1: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#000000',
        marginTop: '5px',
    },
    Muichecked: {
        backgroundColor: 'blue'
    },
    formActionsWrapper: {
        top: 0,
        position: "fixed",
        width: "100%",
        zIndex: 1,
    },
    formActions: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#3C3E49",
        padding: theme.spacing(1.25, 3)
    },
    logo: {
        objectFit: "none" as "none",
        width: "auto"
    },
    discardChangesbutton: {
        fontSize: "14px",
        lineHeight: "20px",
        textDecoration: "underline" as "underline",
        textTransform: "none" as "none",
        padding: '8px 16px',
        color: "#FFFFFF",
        fontFamily: 'Rubik',

    },
    saveChangesbutton: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "none" as "none",
        padding: "10px 20px",
        backgroundColor: "#00D659",
        color: "#3C3E49",
        fontFamily: 'Rubik, sans-serif',
    },
    noServicesCard: {
        display: "flex",
        alignItems: "center" as "center",
        padding: '24px',
        width: "280px",
        height: "100px",
        background: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "absolute" as "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        flexDirection: "column" as "column",
        justifyContent: "center" as "center",
    },
    loaderContainer: {
        display: "flex",
        gap: 10,
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
    },
    loadingResultsText: {
        color: "#3C3E49",
        lineHeight: "24px",
        fontSize: "18px",
        fontWeight: 500,
        whiteSpace: "pre" as "pre",
        textAlign: "center" as "center",
    },

};
// Customizable Area End

