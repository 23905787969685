// Customizable Area Start
import React from "react";
import MuiSwitch from "@material-ui/core/Switch";
import {
  WithStyles,
  withStyles,
  Theme,
  createStyles,
  StyledComponentProps,
} from "@material-ui/core/styles";

type SwitchProps = WithStyles<typeof switchStyles> & StyledComponentProps & {
    onChange:
      | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
      | undefined;
    checked: boolean;
  };
  
  const switchStyles = (theme: Theme) =>
    createStyles({
      root: {
        width: 48,
        height: 24,
        padding: 0,
        margin: 0,
      },
      switchBase: {
        padding: 0,
        "&$checked": {
          transform: "translateX(29px)",
          color: theme.palette.common.white,
          "& + $track": {
            backgroundColor: "#00D659",
            opacity: 1,
            border: "none",
          },
        },
        "&$focusVisible $thumb": {
          color: "#00D659",
          border: "6px solid #fff",
        },
      },
      thumb: {
        width: 18,
        height: 18,
        marginTop: 3,
      },
      track: {
        borderRadius: 24 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
      },
      checked: {},
      focusVisible: {},
    });
  
  const SwitchComponent = ({
    classes,
    onChange,
    checked,
    ...props
  }: SwitchProps) => {
    return (
      <MuiSwitch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        onChange={onChange}
        checked={!!checked}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  };
  
  export const Switch = withStyles(switchStyles)(SwitchComponent);
  // Customizable Area End