import React from "react";
// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";
import { styles } from "./InputFieldWithFormik.web";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

export default class InputFieldWithFormikController extends React.Component<
  TextFieldProps & WithStyles<typeof styles>
> {
  fieldToTextField = () => {
    const {
      disabled,
      field: { ...field },
      form: { touched, errors },
      helperText,
      ...props
    } = this.props;
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
      variant: props.variant,
      error: showError,
      helperText: showError ? fieldError : helperText,
      disabled: disabled,
      ...field,
      ...this.props,
    };
  };
}
// Customizable Area End