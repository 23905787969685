import * as React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
// const history = useHistory();

type EmptyPagePropType = {
  title: string;
  message?: string;
  button_label?: string;
  handleClick?: () => void;
  classes: any;
  isButtonAvailable?: boolean;
  isCustomComponent?: boolean;
  Component?: any;
  static?: boolean;
};
const EmptyPage = (props: EmptyPagePropType) => {
  const {
    classes,
    title = "Page Title",
    button_label = "create",
    message = "No Data Available",
    handleClick = () => {},
    isButtonAvailable = true,
    isCustomComponent = false,
    Component = () => {},
  } = props;
  return (
    <Grid id="empty-page" container alignItems="center" justifyContent="center">
      <Grid
        container
        item
        xs={8}
        justifyContent="center"
        className={classes.pageContainer}
        spacing={3}
      >
        <Grid xs={6} item className={classes.textCenter}>
          <Typography className={classes.pageHeading}>{title}</Typography>
        </Grid>
        <Grid item xs={7} className={classes.textCenter}>
          <Typography className={classes.pageSubHeading}>{message}</Typography>
          {isCustomComponent && (
            <div className={classes.pageSubHeading}>
              {Component && <Component />}
            </div>
          )}
        </Grid>
        {isButtonAvailable && (
          <Grid item xs={6} className={classes.textCenter}>
            <Button
              color="secondary"
              onClick={handleClick}
              variant="contained"
              className={classes.saveButton}
            >
              {button_label}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
const styles = (theme: Theme) =>
  createStyles({
    pageHeading: {
      fontSize: "28px",
      fontWeight: 500,
      color: "#3C3E49",
      letterSpacing: "-0.02em",
    },
    pageSubHeading: {
      color: "#676B7E",
      fontWeight: 400,
      fontSize: "14px",
    },
    textCenter: {
      textAlign: "center",
    },
    pageContainer: {
      background: "#fff",
      margin: 0,
      // @ts-ignore
      position: (props) => (props.static ? undefined : "absolute"),
      top: "40%",
      // @ts-ignore
      transform: (props) => (props.static ? undefined : "translateY(-40%)"),
      padding: "48px 0",
    },
    saveButton: {
      padding: "12px 40px",
      background: "#00D659",
      borderRadius: 3,
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#3C3E49",
      textTransform: "none",
    },
  });
export default withStyles(styles)(EmptyPage);
