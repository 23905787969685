import React from "react";

// Customizable Area Start
import {
  CardMedia,
  withStyles,
  Typography,
  Box,
  Slide
} from "@material-ui/core";
import { builderStudioStore, tickIcon } from "./assets";
import PhotoLibraryController from "./PhotoLibraryController";


const webStyles = {
  formActions: {
    display: "flex",
    backgroundColor: "#3C3E49",
    padding: "20px 24px",
  },
  logo: {
    width: "auto",
    padding: "1%"
  },
  successMsg: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "rubik",
    lineHeight: "20px",
    padding: "4px 0px",
    color: "#FFFFFF",
    margin: "0px 12px"
  },
  successContent: {
    display: "flex",
    margin: "auto",
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
};
// Customizable Area End

export class Banner extends PhotoLibraryController {
  render() {
    // Customizable Area Start
    const { success } = this.props;

    return (
      <>
        <Slide direction="down" in={!!success} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
          <Box style={{ zIndex: 9999, position: "fixed", top: 0, width: "100%" }}>
            <Box style={webStyles.formActions}>
              <CardMedia
                component="img"
                src={builderStudioStore}
                style={webStyles.logo}
              />
              <Box style={webStyles.successContent}>
                <img
                  style={webStyles.closeIcon}
                  src={tickIcon}
                  alt="tickIcon"
                />
                <Typography style={webStyles.successMsg}>
                  <span style={{ fontWeight: 600 }}>Success!</span>{" "}
                  {" Image deleted successfully."}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Slide>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(webStyles)(Banner);
// Customizable Area End
