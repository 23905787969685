Object.defineProperty(exports, '__esModule', {
  value: true
});
// Customizable Area Start
exports.postAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.getApiMethod = 'GET';
exports.patchApiMethod = 'PATCH';
exports.deleteApiMethod = 'DELETE';
// Locations
exports.getLocationsAPIContentType = 'application/json';
exports.getLocationsAPIMethodType = 'GET';
exports.getLocationsAPI = '/bx_block_location_management/countries';
exports.locationsAPI = '//bx_block_location_management/countries';
exports.citiesAPI = '/bx_block_location_management/address_states';
exports.updateStoreDetailAPI = '/bx_block_store_details/store_profile';
exports.storeDetailsContentType = 'application/json';
exports.storeDetailsMethodType = 'GET';
exports.storeDetailsAPI = '/bx_block_store_details/store_profile';
exports.updateError = "Unable to update";
exports.currencies = [
  {
    value: 'GBP',
    country: 'uk',
    label: 'Pound sterling',
    preIcon: '£'
  },
  {
    value: 'INR',
    country: 'in',
    label: 'Indian rupee',
    preIcon: '₹'
  },
  {
    value: 'USD',
    country: 'us',
    label: 'US Dollar',
    preIcon: '$'
  },
  {
    value: 'EUR',
    country: 'eu',
    label: 'Euro',
    preIcon: '€'
  },
  {
    value: 'AED',
    country: 'ae',
    label: 'Emirati dirham',
    preIcon: 'د.إ'
  }
];
exports.errorEmailNotValid = 'Email not valid.';
exports.errorPasswordNotValid = 'Password not valid.';
exports.placeHolderEmail = 'Email';
exports.placeHolderPassword = 'Password';
exports.labelHeader = 'The reason we require login';
exports.btnTxtLogin = 'LOG IN';
exports.labelRememberMe = 'Remember me';
exports.btnTxtSocialLogin = 'LOGIN WITH SOCIAL MEDIA';
exports.labelOr = 'OR';
exports.labelTitle = 'Log in';
exports.storeDetails = 'Store details';
exports.details = 'Details';
exports.storeName = 'Store Name';
exports.storeTitle = 'Store Title *';
exports.requiredField = 'This field is required';
exports.email = 'Email *';
exports.emailValidation = 'This field must be e-mail';
exports.customerSupportEmail = 'Customer Support email';
exports.customerSupportEmailPlaceholder = 'Enter the customer support email';
exports.senderEmail = 'Sender email';
exports.senderEmailPlaceholder = 'Enter sender email address';
exports.addressAndPhone = 'Address & Phone';
exports.country = 'Country *';
exports.countryPlaceholder = 'Enter the country';
exports.cityState = 'City/State *';
exports.cityStatePlaceholder = 'Enter the city / state';
exports.postCode = 'Postcode / ZIP / Pin *';
exports.postCodePlaceholder = 'Enter the postcode';
exports.codePlaceholder = 'Enter the code';
exports.state = 'State *';
exports.statePlaceholder = 'Enter the state';
exports.city = 'City *';
exports.cityPlaceholder = 'Enter the city';
exports.addressLine1 = 'Address line 1 *';
exports.addressLine2 = 'Address line 2';
exports.addressPlaceholder = 'Enter the address details';
exports.addressPlaceholder2 = 'Enter extra address details';
exports.phoneNumber = 'Phone Number';
exports.phoneNumberPlaceholder = 'Enter your phone number';
exports.phoneNumberInfo =
  'This is your main contact number. It will appear in the footer under ‘Contact us’';
exports.phoneNumberNotValid = 'Phone number is not valid';
exports.whatsappIntegration = 'What’s app integration';
exports.whatsappNumber = 'What’s app number';
exports.whatsappNumberInfo =
  'This number will appear as ‘Chat on what’s app’ in the footer under ‘Contact us’';
exports.message = 'Message *';
exports.whatsappMessagePlaceholder = 'Default whatsapp message';
exports.whatsappMessageInfo =
  'This the first message that will automatically appear in the what’s app chat';
exports.whatsappMessageValidation = 'Message is required';
exports.configuration = 'Configuration';
exports.currency = 'Currency';
exports.gstNumber = 'GST number';
exports.gstNumberPlaceholder = 'Enter the GST number';
exports.gstNumberValidation = 'GST Number must be 15 digits';
exports.successMessage = 'Your data recorded successfully';
exports.stateNotFound = 'State Not Found';
exports.countryNotFound = 'Country Not Found';

exports.imgPasswordInVisiblePath = 'assets/ic_password_invisible.png';
exports.imgPasswordVisiblePath = 'assets/ic_password_visible.png';
exports.textAuthorizedSignatory = "Authorized Signatory"
exports.textImage = "Image";
exports.textSomethingWentWrong = "Something went wrong";
exports.textEnterEmail = "Enter the email"
exports.textStoreName = "Store Name"

exports.backendDataKeys = [
  "name",
  "email",
  "sender_email",
  "customer_support_email",
  "zipcode",
  "address_line_1",
  "address_line_2",
  "phone_number",
  "is_whatsapp_integration",
  "whatsapp_number",
  "whatsapp_message",
  "currency_id",
  "gst_number",
  "currency_symbol",
  "country_code",
  "created_at",
  "updated_at",
]
exports.backendDataKeyCountryId = "country_id";
exports.backendDataKeyCurrencyId = "currency_id";
exports.backendDataKeyAddressStateId = "address_state_id";
exports.backendDataKeyCityId = "city_id";
exports.backendDataKeyWhatsappNumber = "whatsapp_number";
exports.backendDataKeyPhoneNumber = "phone_number";
exports.backendShowAllDataQuery = "?show_all=true"
exports.backendAddressStatesRoute = "/address_states";
exports.backendAddressCitiesRoute = "/cities";
// Customizable Area End
