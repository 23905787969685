import React from "react";
// Customizable Area Start
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

import ServicesmanagementController, {
  Props,
} from "./ServicesmanagementController.web";
import ServicesList from "./ServicesList.web";

export default class ServicesListWrapper extends ServicesmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    mixpanel.track("webadmin_services_page_enter");
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ServicesList
        servicesData={this.state.services}
        searchServices={this.searchServices}
        deleteServices={this.deleteServices}
        isLoading={this.state.loading}
        deletionError={this.state.deletionError}
        deletionSuccess={this.state.deletionSuccess}
        pagination={this.state.pagination}
        handlePageChange={this.handlePageChange}
      />
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
