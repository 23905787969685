// Customizable Area Start
import React from "react";
import EmailTemplatesController, {
  Props,
} from "./EmailTemplatesController.web";
import Categories from "./Categories.web";
import { Scrollbars } from "react-custom-scrollbars";
import "../assets/styles.css";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { Header } from "./Header.web";
import { Tabs } from "./Tabs.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
// Customizable Area End

export class EmailTemplates extends EmailTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { selectedTab, headerTabs, emailSettings } = this.state;
    return (
      <Scrollbars>
        <div className="email-templates">
          <Header>
            <div className="tabs-wrapper">
              {!!this.state.headerTabs.length && (
                <Tabs
                  headerTabs={headerTabs}
                  onTabChange={this.handleTabChange}
                  value={selectedTab}
                />
              )}
            </div>
          </Header>

          {headerTabs.length ? (
            <Categories
              categories={headerTabs[selectedTab].categories}
              emailSettings={emailSettings}
              onActiveChange={this.handleActiveChange}
              onEditClick={this.handleEditClick}
            />
          ) : null}
        </div>
      </Scrollbars>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withLayout(
  withLoader(withSnackBar(withHeaderBar(withDialog(EmailTemplates))))
);
// Customizable Area End
