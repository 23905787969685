// Customizable Area Start
import PromoCodeCreateController from "./PromoCodeCreateController.web";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "date-fns";
import Toolbar from "../../ss-cms-common-components/src/Toolbar/Toolbar";
import PromoCodeComponent from "./PromoCodeComponent.web";
import { withStyles } from "@material-ui/core";
import withLoader from '../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
import withSnackBar from '../../ss-cms-common-components/src/HOC/withSnackBar.Web';
import { withDialog } from '../../ss-cms-common-components/src/Dialog/withDialog';
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
export const configJSON = require("./config.js");

export class PromoCodeCreate extends PromoCodeCreateController {
  promocodeComp = () => {
    if (this.state.promoCodeId != 0) {
      if (this.state.filledData) {
        return (
          <PromoCodeComponent
            onSubmit={this.handleSubmitPromocode}
            openToast={this.openToastOnChange}
            formRef={this.formRef}
            promoCodeData={this.state.filledData}
            navigation={this.props.navigation}
          />
        )
      }
    } else {
      return (<PromoCodeComponent
        onSubmit={this.handleSubmitPromocode}
        openToast={this.openToastOnChange}
        formRef={this.formRef}
        promoCodeData={{}}
        navigation={this.props.navigation}
      />
      )
    }
  }
  render() {
    return (
      <Scrollbars>
        <div style={withStyle.promocodeContainer}>
          <Toolbar
            title={
              this.state.promoCodeId ? configJSON.editPromoCode : configJSON.createPromoCode
            }
            handleBackClick={this.handleBackClick}
          />
          {this.promocodeComp()}
        </div>
      </Scrollbars>
    );
  }
}
const withStyle = {
  promocodeContainer: {
    backgroundColor: "#f8f9fa",
    height: "100%",
    padding: "55px 24px 40px"
  },
};
export default (withStyles(withStyle)(
  withSnackBar(withLoader(withDialog(withHeaderBar(PromoCodeCreate))))
));;
// Customizable Area End
