Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApiGetCountryCodes = "application/json";
exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.apiGetCountryCodesType = "GET";

exports.countryPlaceHolder = "Select Country";
exports.countryPlaceHolderMobile = "Type Here...";
// Customizable Area End
