// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  formActionsWrapper: {
    top: 0,
    left: 0,
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
  },
  logo: {
    objectFit: "none",
    width: "auto",
  },
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF",
  },
  saveChangesbutton: {
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  container: { padding: theme.spacing(10, 3, 5) },
  link: {
    textDecoration: "none",
  },
  backButton: {
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#676B7E",
    padding: theme.spacing(0.5, 0),
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0.5),
    },
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    marginBottom: theme.spacing(5),
  },
  wrapper: { display: "flex", gap: theme.spacing(3) },
  leftSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 70%",
  },
  RightSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 30%",
  },
  card: {
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    marginBottom: theme.spacing(4),
  },
  cardTitle1: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px"
  },
  inputsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  maximumBookingContainer: {
    marginTop: "23px"
  },
  inputlabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "7px"
  },
  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  descriptionCounter: {
    fontSize: "12px",
    lineHeight: "20px",
  },
  imagesWrapper: {
    display: "flex",
    gap: theme.spacing(3),
    flexWrap: "wrap",
  },
  previewImage: {
    width: "157px",
    height: "157px",
  },
  removeButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
  },
  imageUpload: (props: { hasImages: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "145px",
    width: props.hasImages ? "157px" : "100%",
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    padding: theme.spacing(2.5),
    cursor: "pointer",
  }),
  addIcon: {
    color: "#676B7E",
  },
  addImageText: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#676B7E",
  },
  fileSize: {
    fontSize: "10px",
    lineHeight: "24px",
    color: "#AFB2C0",
  },
  validationError: {
    color: "#f44336",
    margin: theme.spacing(0.375, 1.75, 0),
    fontSize: "0.75rem",
    lineHeight: "1.66",
  },
  fieldWrapper: {
    display: "flex",
    gap: theme.spacing(3.5),
  },
  field: {
    flex: "0 1 40%",
  },
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  switch: {
    "& .MuiSwitch-switchBase": {
      color: "#FFFFFF",
    },
    "& .Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#00D659",
        opacity: 1,
      },
    },
  },
  hasDiscountSwitchText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  discount: {
    display: "flex",
  },
  discountInputsWrapper: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "flex-start",
    flex: "0 1 40%",
  },
  discountPercent: {
    marginTop: theme.spacing(4.5),
  },
  disabledInput: {
    backgroundColor: "#F5F5F5",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: "#00D659",
    },
  },
  radioLabel: {
    fontFamily: "'Rubik', sans-serif !important",
  },
  hidden: {
    display: "none",
  },
  availabilityHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0),
  },
  availabilityTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    marginBottom: theme.spacing(1.5),
  },
  availabilityText: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#3C3E49",
  },
  editAvailablityButton: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#3C3E49",
    padding: theme.spacing(1.25, 4),
    borderRadius: "3px",
    border: "1px solid #3C3E49",
    pointerEvents: "auto",
    "&.Mui-disabled": {
      pointerEvents: "auto",
      cursor: "not-allowed",
    },
  },
  tableBodyRowCells: {
    "& .MuiTableCell-body": {
      borderBottom: "none",
      minWidth: "160px",
    },
  },
  tableCells: {
    marginTop: theme.spacing(3.5),
    "& .MuiTableCell-root": {
      padding: theme.spacing(2, 0),
    },
  },
  tableHeaderText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  tableCellText: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
    whiteSpace: "nowrap",
  },
  workingHoursWrapper: {
    width: "calc(100vw - 55vw)",
  },
  workingHours: {
    display: "flex",
    gap: theme.spacing(3),
    overflow: "auto",
  },
  helperText: {
    fontSize: "10px",
    lineHeight: "16px",
    color: "#83889E",
    marginTop: theme.spacing(1.5),
  },
  cardTitleContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  cardTitleBox:{
    display: "flex",
    alignItems: "center"
  },
  inputfields:{
    width:"100%"
  },
  addDesc:{
    marginTop:"25px"
  },
  selelctBox:{
    "&.MuiOutlinedInput-input":{
      padding: "15px",
    },
  }
}));
// Customizable Area End