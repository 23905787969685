import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSONUrl = require("../../../framework/src/config");
const navigation = require("react-navigation");
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    totalValue: number | string;
    totalPrice:(item: number) => void;
    isPromoApplied: (applied :boolean) => void;
    promoCode: (code: string) => void;
    userEmail: string;
    userPhoneNo: string;
    // Customizable Area End
}

// Customizable Area Start
interface ValidResponse {
    data: object;
}

interface ValidResponseMessage {
    message: string;
}

export interface ErrorResponse {
    error: [
        {
            token: string;
        }
    ]
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface PromoApplied {
    message:string;
    after_promocode_value: number;
    Before_promocode_value: number;
    discount_amount: number;
}

interface PromoFail {
    error: string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    token: string;
    promoCode: string;
    promoApplied: PromoApplied;
    promoFailed: PromoFail;
    isSuccess: boolean;
    isFailed: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PromoCodeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPromoCodeApiCall: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            promoCode: "",
            promoApplied: {} as PromoApplied,
            promoFailed: {} as PromoFail,
            isSuccess: false,
            isFailed: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        // Customizable Area End
    }

    async componentWillUnmount() {
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            if (this.isValidResponseMessage(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            runEngine.debugLog("Message Recived", message);

            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    apiCall = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
            token: this.state.token || ""
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    isValidResponseMessage = (responseJson: ValidResponseMessage) => {
        return responseJson && responseJson.message;
    };

    isInValidResponse = (responseJson: PromoFail) => {
        return responseJson && responseJson.error;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: PromoApplied) => {
        if(apiRequestCallId === this.getPromoCodeApiCall){
            this.setState({promoApplied: responseJson, isSuccess: true, isFailed: false});
            this.props.totalPrice(responseJson.after_promocode_value);
            this.props.isPromoApplied(true);
            this.props.promoCode(this.state.promoCode);
        }
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: PromoFail) => {
        if(apiRequestCallId === this.getPromoCodeApiCall){
            this.setState({promoFailed: responseJson, isFailed: true, isSuccess: false});
            this.props.totalPrice(NaN)
        }
    };

    getPromoCode = async () => {
        const {userEmail, userPhoneNo} = this.props
        this.getPromoCodeApiCall = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getPromoCodeEndPoint}code=${this.state.promoCode}&cart_value=${this.props.totalValue}&email=${userEmail}&phone_number=${userPhoneNo}`,
        });
    };

    handlePromocode =(event: { target: { value: string; }; }) => {
        this.setState({promoCode: event.target.value});
    };
    // Customizable Area End
}