// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Service = makeStyles((theme: Theme) => ({
  formActionsWrapper: {
    top: 0,
    position: "fixed",
    width: "100%",
    zIndex: 1
  },
  paragraph: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
    margin: "20px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3)
  },
  logo: {
    objectFit: "none",
    width: "auto"
  },
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF"
  },
  saveChangesbutton: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49"
  },
  container: { padding: theme.spacing(10, 3, 5) },
  link: {
    textDecoration: "none"
  },
  backButton: {
    textTransform: "none",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#676B7E"
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    marginBottom: theme.spacing(5)
  },
  title1: {
    height: "45px",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
  },
  addServiceButtonNoServices: {
    textTransform: "none",
    borderRadius: "3px",
    padding: theme.spacing(1.5, 5),
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  wrapper: { display: "flex", gap: theme.spacing(3) },
  leftSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 70%"
  },
  RightSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 30%"
  },
  card: {
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF"
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    marginBottom: theme.spacing(4)
  },
  inputsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3)
  },
  inputlabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px"
  },
  descriptionWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  descriptionCounter: {
    fontSize: "12px",
    lineHeight: "20px"
  },
  imagesWrapper: {
    display: "flex",
    gap: theme.spacing(3),
    flexWrap: "wrap"
  },
  previewImage: {
    width: "157px",
    height: "157px"
  },
  removeButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49"
  },
  imageUpload: (props: { hasImages: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "157px",
    width: props.hasImages ? "157px" : "100%",
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    padding: theme.spacing(2.5),
    cursor: "pointer"
  }),
  addIcon: {
    color: "#676B7E"
  },
  addImageText: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#676B7E"
  },
  fileSize: {
    fontSize: "10px",
    lineHeight: "24px",
    color: "#AFB2C0"
  },
  fieldWrapper: {
    display: "flex",
    gap: theme.spacing(3.5)
  },
  field: {
    flex: "0 1 40%"
  },
  switch: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  discount: {
    display: "flex"
  },
  discountInputsWrapper: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "flex-start",
    flex: "0 1 40%"
  },
  discountPercent: {
    marginTop: theme.spacing(4.5)
  },
  disabledInput: {
    backgroundColor: "#F5F5F5"
  },
  hidden: {
    display: "none"
  },
  availabilityDescription: {
    display: "flex",
    justifyContent: "space-between"
  },
  availabilityText: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#3C3E49"
  },
  editAvailablityButton: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    color: "#3C3E49",
    padding: theme.spacing(1.25, 4),
    borderRadius: "3px",
    border: "1px solid #3C3E49"
  },
  tableRow: {
    "& .MuiTableCell-body": {
      borderBottom: "none",
      minWidth: "160px"
    }
  },
  tableHeaderText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E"
  },
  tableCellText: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
    whiteSpace: "nowrap"
  },
  workingHoursWrapper: {
    overflow: "auto",
    width: "500px"
  },
  workingHours: {
    display: "flex",
    gap: theme.spacing(3)
  },
  helperText: {
    fontSize: "10px",
    lineHeight: "16px",
    color: "#83889E",
    marginTop: theme.spacing(1.5)
  },

  AddText: {
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    marginTop:'5px',
  },

  formTitle: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    textAlign: "left"
  },
  formFirstText: {
    color: "#000000",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    marginBottom: 8
  },

  formSecondText: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#3C3E49"
  },
  mbText: {
    fontFamily: "Rubik",
    fontSize: "10px",
    color: "#AFB2C0",
    fontWeight: 400,
    lineHeight: "24px"
  },
  AddImagetitle: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#676B7E"
  },
  imgUpload: {
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#676B7E"
  },
  mainPreview: {
    width: "100%",
    height: "100%",
    margin: "2px",
    border: "2px dotted #C0C3CE",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px"
  },



  loadingResults: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  loadingResultsText: {
    color: "#3C3E49",
    lineHeight: "24px",  
    fontSize: "18px",
    fontWeight: 500,
    whiteSpace: "pre",
    textAlign: "center",
  },
  loaderContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  noServicesCard: {
    alignItems: "center",
    display: "flex",
    padding: 48,
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    flexDirection: "column",
    justifyContent: "center",
  },
  noServices: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    borderRadius: "8px",
    background: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    top: "45%",
    left: "50%",
    alignItems: "center",
    justifyContent: "center",
    padding: 24
  },
  body: {
    backgroundColor: '#FAFAFA',
  },
  formErrorText: {
    color: 'red', 
    fontSize: '14px',
    marginTop: '4px', 
    fontFamily:'Rubik',
  },
   label:{
      textTransform: 'none',
    },
  

    customCheckbox: {
      '&$checked': {
          color: '#6200EA',       
          borderColor: '#6200EA',  
          '&:hover': {
              borderColor: '#6200EA', 
          },
      },
      '&$checked.Mui-checked': {
          borderColor: '#D0D2DA',  
          '&:hover': {
              borderColor: '#D0D2DA', 
          },
      },
  },
  checked: {},  
  
  notificationWrapper: {
    width: "100%",
    zIndex: 1,
    top: 0,
    position: "fixed",
   
  },
  notification: {
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
    display: "flex",  
  },
  notificationMessage: {
    justifyContent: "center",
    backgroundColor: "#3C3E49",
    height: "48px",
    flex: 1,
    display: "flex",
    alignItems: "center",
   
  },
  notificationIcon: {
    color: "transparent",
  },
  notificationText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
    padding: theme.spacing(0, 1.25),
   
  },

  centeredText: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    marginLeft: '8px', 
    color: '#FFFFFF',
  },
  centeredsecText:{
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    marginLeft: '8px', 
    color: '#FFFFFF',

  }


}));




// Customizable Area End