// Customizable Area Start
import * as React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { checkResponseError } from "../../ss-cms-common-components/src/ErrorHandler/ErrorHandler";
export const configJSON = require("./config.js");
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { generateRequestMessage, showError, isTokenExpired, clearStorageData } from "../../ss-cms-common-components/src/Utilities/Utilities";
import { Scrollbars } from "react-custom-scrollbars";
import { WithStyles } from "@material-ui/core"
export type Props = RouterProps & WithStyles &
    DialogProps &
    withHeadeActionBarProps &
    withLoaderProps &
    withToastProps & {
        navigation: {
            navigate: (to: string, params: object) => void;
            getParam: (param: string) => string;
            goBack: () => void;
        };
        id: string;
        setDialogState: (status: boolean, params: object) => void;
    };
interface promoCodeObjType {
    code: string;
    descriptionPromo: string;
    title: string;
    validity: string;
    id: string;
    description?: string;
    position?: number;
    customerName?: string;
    activated?: boolean;
    name?: string;
    number?: number;
    email?: string;
}
interface promocodeType {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "title": string,
        "description": string,
        "code": string,
        "discount_type": string,
        "discount": string,
        "valid_from": string,
        "valid_to": string,
        "min_cart_value": string,
        "max_cart_value": string,
        "limit": string | number | null
    }
}

export interface PaginationData {
    current_page: number,
    next_page: number | null,
    prev_page: number,
    total_pages: number,
    total_count: number,
    current_count: number,
    per_page: number
}

interface getPromoCodeTypes {
    data: Array<promocodeType>,
    metadata: {
        meta: {
            pagination: PaginationData
        }
    }
}

interface onlyIdsType {
    [index: number]: number
}
interface S {
    promoCodeData: Array<promoCodeObjType>;
    allPromoCodeDataLength: number;
    page: number;
    per_page: number;
    totalCount: number;
    checkedPromoList: Array<promoCodeObjType>;
    isLoader: boolean;
    pagination: PaginationData
}

interface SS {
    id: number;
}
export default class PromoCodeController extends BlockComponent<Props, S, SS> {
    getPromoCodesApiCallId: string = "";
    getPromoCodesLengthApiCallId: string = "";
    postBulkDeleteCallId: string = "";

    scrollRef: React.RefObject<Scrollbars>;
    constructor(props: Props) {
        super(props);
        this.scrollRef = React.createRef();
        this.receive = this.receive.bind(this);
        this.state = {
            promoCodeData: [],
            allPromoCodeDataLength: 0,
            page: 1,
            per_page: 10,
            totalCount: 0,
            checkedPromoList: [],
            isLoader: false,
            pagination: {} as PaginationData
        };
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.ActionMessageFromToaster)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            if (isTokenExpired(message)) {
                return this.logoutAndNavigateLogin();
            }

            this.messageGetPromoCodesApiCall(message)
            this.messagePostBulkDeleteCall(message)
        }
    }

    logoutAndNavigateLogin = () => {
        clearStorageData();
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "EmailAccountLogin"
        );
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    };

    messageGetPromoCodesApiCall = (message: Message) => {
        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.getPromoCodesApiCallId === apiRequestCallId) {
            if (checkResponseError(message)) {
                return showError(
                    configJSON.promoCodesError,
                    responseJson,
                    this.props.hideLoader,
                    this.props.setDialogState
                );
            }
            if (responseJson && responseJson?.data) {
                this.initPromoCodeData(responseJson);
            }
        }
    }


    messagePostBulkDeleteCall = (message: Message) => {
        const [responseJson, apiRequestCallId] = getResponseVariables(message);
        if (this.postBulkDeleteCallId === apiRequestCallId) {
            if (responseJson && responseJson?.message) {
                this.props.showHeaderBar({ type: 'success' })
                this.getPromoCodeDataApi(this.state.page)
                this.setState({ ...this.state, checkedPromoList: [] })
            } else if (responseJson?.errors) {
                return this.props.showHeaderBar({ message: configJSON.errorMessage, type: 'error' })
            }
        }
    }


    async componentDidMount() {
        await this.getPromoCodeDataApi(this.state.page);
    }
    goToAddPromoCode = () => {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminPromoCodeCreate");
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        runEngine.sendMessage(to.messageId, to);
    };
    handleEditClick = (item: promoCodeObjType) => {
        this.props.navigation.navigate("AdminPromoCodeCreate", { id: item.id });
    };
    handlePageChange = async (value: number) => {
        this.setPageState(value, await this.getPromoCodeDataApi(value))
    };

    checkedItems = (item: Array<promoCodeObjType>) => {
        this.setCheckedPromoListState(item)
    }

    handleDeleteModal = () => {
        this.props.setDialogState(true, {
            title: configJSON.deleteModalTitle,
            message: configJSON.deleteModalMessage,
            confirmColor: configJSON.deleteModaConfirmColor,
            confirmBackground: configJSON.deleteModalConfirmBackground,
            containerClassName: "promo-delete-dialog",
            confirmHoverBackground: configJSON.deleteModalConfirmHoverBackground,
            onSubmit: () => this.handleBulkDeletePromoCodeAPI()
        });
    }
    handleBulkDeletePromoCodeAPI = async () => {
        const { checkedPromoList } = this.state;
        let onlyIDs = checkedPromoList?.map((element: promoCodeObjType) => +element.id)
        await this.postBulkDelete(onlyIDs)

    }
    initPromoCodeData = (responseJson: getPromoCodeTypes) => {
        this.props.hideLoader();
        let promoData = responseJson.data;
        const responseData = promoData.map((item) => {
            const fromDay = item.attributes.valid_from.split('-')[2];
            const fromMonth = item.attributes.valid_from.split('-')[1];
            const toDay = item.attributes.valid_to.split('-')[2];
            const toMonth = item.attributes.valid_to.split('-')[1];
            const toYear = item.attributes.valid_to.split('-')[0];

            return {
                id: item.id,
                title: item.attributes.title,
                descriptionPromo: item.attributes.description,
                code: item.attributes.code,
                validity: `${fromDay} ${configJSON.months[parseInt(fromMonth) - 1]} - ${configJSON.months[parseInt(toMonth) - 1]} ${toDay} ${toYear}`,
            };
        });
        const totalCount = responseJson?.metadata?.meta?.pagination?.total_count || 0;
        const pagination = responseJson?.metadata?.meta?.pagination;
        this.setState({
            promoCodeData: responseData, totalCount: totalCount, isLoader: false, pagination: pagination
        });
    };
    getPromoCodeDataApi = async (page: number) => {
        this.props.showLoader();
        this.setState({ isLoader: true })
        const requestMessage = await generateRequestMessage(`${configJSON.getPromoCodeApi}?page=${page}&per_page=${this.state.per_page}`, "GET");
        this.getPromoCodesApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    postBulkDelete = async (data: onlyIdsType) => {
        this.props.showLoader();
        this.setState({ isLoader: true })
        const requestMessage = await generateRequestMessage(configJSON.postBulkDelete, configJSON.deleteApiMethod);
        let body = {
            "ids": data
        }
        this.postBulkDeleteCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    setPageState(state: number, callback?: void) {
        this.setState({ page: state }, () => callback)
    }
    setCheckedPromoListState(state: Array<promoCodeObjType>, callback?: void) {
        this.setState({ ...this.state, checkedPromoList: state }, () => callback)
    }
}
// Customizable Area End
