// Customizable Area Start
import React from "react";
import StaffDetailsController from "./StaffDetailsController";
import { Box, Breadcrumbs, withStyles, Theme, createStyles } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import ImageCard from "../../utilities/src/components/ImageCard.web";
import { StaffDummyImage } from "./assets";
import Skeleton from "@material-ui/lab/Skeleton";
export const configJSON = require("./config");
// Customizable Area End

class StaffDetails extends StaffDetailsController {
    render() {
        // Customizable Area Start
        const { service, selectedImageIndex } = this.state;
        const style = this.props.classes;
        return (
            <>
                    <>
                        <Box className={style.breadcrumbsWrapper}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color: "black"}}/>}>
                                <Box onClick={this.homeNavigation} className={style.breadcrumbLink}>
                                    <Typography>{configJSON.textHome}</Typography>
                                </Box>
                                <Box
                                    className={style.breadcrumbLink}
                                    onClick={this.servicesNavigation}
                                >
                                    <Typography>{this.state.renameStylist ? this.state.renameStylist : "Service Provider"}</Typography>
                                </Box>
                                <Typography className={style.breadcrumbNonLink}>
                                    {service?.attributes.full_name ? service.attributes.full_name : ""}
                                </Typography>
                            </Breadcrumbs>
                        </Box>

                        <Box className={style.currentCatalogueWrapper}>
                            {service ? 
                            <Box className={style.imagesWrapper}>
                                <Box className={style.largeImageWrapper}>
                                    <ImageCard
                                        imageClassName={style.largeImage}
                                        image={service.attributes.images.length > 0 ? (selectedImageIndex ? service?.attributes?.images[selectedImageIndex]?.url : service?.attributes?.images[0]?.url) : StaffDummyImage}
                                        alt="big image"
                                    />
                                </Box>

                                <Box className={style.smallImagesWrapper}>
                                    {service.attributes.images.length > 1 && (service.attributes.images).map(
                                        (image, index) => (
                                            image ?
                                            <img
                                                key={index}
                                                className={style.smallImage}
                                                src={image?.small_url ? image?.small_url : StaffDummyImage}
                                                onClick={() => this.previewImage(index)}
                                                alt="small image"
                                            />
                                            :
                                            <Skeleton variant="rect" width={85} height={83}/>
                                        )
                                    )}
                                </Box>
                            </Box>
                                :
                                <Skeleton variant="rect" width={500} height={500} />
                            }

                            {service ?
                            <Box className={style.details}>
                                <Box>
                                    <Typography className={style.infoTitle}>
                                        {this.state.renameStylist ? this.state.renameStylist : "Service Provider"}
                                    </Typography>
                                    <Typography className={style.catalogueName}>
                                        {service.attributes.full_name ? service.attributes.full_name : ""}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography className={style.infoTitle}>
                                        {configJSON.textServices}
                                    </Typography>
                                    <Typography className={style.info1}>
                                        {service.attributes.services &&
                                            service.attributes.services.map((service, index) => {
                                                return (
                                                    <Box key={index}>
                                                        <Typography className={style.serviceList}>
                                                            <span className={style.serviceListBolletPoint}>&#8226; </span>
                                                            {service}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography className={style.infoTitle}>
                                        {configJSON.description}
                                    </Typography>
                                    <Typography className={style.info}>
                                        {service.attributes.description ? service.attributes.description : ""}
                                    </Typography>
                                </Box>

                                <Typography
                                    className={style.bookNowButton}
                                    onClick={() => this.appoinmentNavigation(service.id)}
                                >
                                    {configJSON.textBookNow}
                                </Typography>
                            </Box>
                                :
                                <Box className={style.skeletonBox}>
                                <Skeleton />
                                <Skeleton width={"60%"} />
                              </Box>
                            }
                        </Box>
                    </>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Styles = (theme: Theme) =>
    createStyles({
        breadcrumbsWrapper: {
            margin: theme.spacing(5, 10, 1),
            [theme.breakpoints.down("sm")]: {
                margin: theme.spacing(2, 2.25, 1),
                display: "flex"
            },
        },
        breadcrumbLink: {
            color: "#3FC1CB",
            textDecoration: "none",
            cursor: "pointer",
        },

        breadcrumbNonLink: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "130px"
        },
        currentCatalogueWrapper: {
            display: "flex",
            gap: theme.spacing(16),
            padding: theme.spacing(6.25, 10, 11.25),
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                gap: theme.spacing(1.75),
                padding: theme.spacing(2.5),
            },
        },
        imagesWrapper: {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
            [theme.breakpoints.down("sm")]: {
                gap: theme.spacing(1.25),
            },
        },
        largeImageWrapper: {
            display: "flex",
            width: "628px",
            height: "611px",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                height: "335px",
            },
        },
        largeImage: {
            flex: "1 1 auto",
        },
        smallImagesWrapper: {
            display: "flex",
            width: "628px",
            gap: theme.spacing(3),
            overflow: "auto",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                gap: theme.spacing(1.25),
            },
        },
        smallImage: {
            width: "85px",
            height: "83px",
            flex: "0 0 auto",
            cursor: "pointer",
        },
        details: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: theme.spacing(5),
            marginTop: theme.spacing(8),
            [theme.breakpoints.down("sm")]: {
                gap: theme.spacing(3),
                marginTop: theme.spacing(0),
            },
        },
        infoTitle: {
            fontSize: "18px",
            lineHeight: "24px",
            color: "#757575",
            paddingBottom: theme.spacing(1),
        },
        catalogueName: {
            fontSize: "32px",
            lineHeight: "36px",
            letterSpacing: "3.2px",
            color: "#364F6B",
            textTransform: "uppercase",
        },
        servicesList: {
            paddingInlineStart: theme.spacing(5),
        },
        servicesListItem: {
            display: "list-item",
            listStyleType: "disc",
            padding: theme.spacing(0),
        },
        info: {
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000",
        },
        info1: {
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000",
            marginLeft: "10px",
        },
        bookNowButton: {
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            backgroundColor: "#3FC1CB",
            color: "#FFFFFF",
            borderRadius: "5px",
            cursor: "pointer",
            padding: "12px",
            width: "194px",
            '@media(max-width: 480px)': {
                width: '100%'
            },
            [theme.breakpoints.down("sm")]: {
                flex: 1,
            },
        },
        serviceList: {
            display: "flex",
            alignItems: "center",
            gap: 15,
        },
        serviceListBolletPoint: {
            fontSize: "20px",
        },
        skeletonBox:{
            display: "flex",
            flexDirection: "column",
            width:"50%"
          }
    });
// Customizable Area End

// Customizable Area Start
export default withStyles(Styles)(StaffDetails);
export { StaffDetails }
// Customizable Area End
