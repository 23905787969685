import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const navigation = require("react-navigation");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
}

interface ValidResponse {
    data: object;
}

interface ValidResponseMessage {
    message: string;
}

export interface ErrorResponse {
    errors: [
        {
            token: string;
            Tasklist: string;
        }
    ]
}

interface DeleteMock {
    message: string;
    errors:string;
}

export interface StaffDataObj {
    id: string,
    type: string,
    attributes: {
        email: string,
        full_name: string,
        phone_number: string,
        description: string,
        designation: string
    }
}

export interface Pagination {
    current_page: number;
    next_page: number;
    prev_page: number;
    total_pages: number;
    total_count: number;
    current_count: number;
    per_page: number;
}

export interface StaffData {
    data: Array<StaffDataObj>;
    metadata: {
        meta: {
            pagination: Pagination;
        }
    }
}
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    classes: Record<string, string>;
    staffData: Array<StaffDataObj>;
    isLoading: boolean;
    selectedRows: Array<string>;
    rowCheckBox: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    allRowCheckBox: (event: React.ChangeEvent<HTMLInputElement>) => void;
    pagination: Pagination;
        // Customizable Area End
}

export interface S {
    id: string;
    // Customizable Area Start
    staffData: Array<StaffDataObj>,
    searchString: string,
    isLoading: boolean,
    selectedRows: Array<string>,
    deletedRows: Array<string>,
    isModalOpened: boolean,
    deleteSuccess: string,
    deleteError:string,
    pagination: Pagination;
    toatalCount: number;
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminStaffListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStaffListingApiCall: string = "";
    getStaffSearchApiCall: string = "";
    deleteSatffDataApiCall: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            id: "",
            // Customizable Area Start
            staffData: [],
            searchString: "",
            isLoading: true,
            selectedRows: [],
            deletedRows:[],
            isModalOpened: false,
            deleteSuccess: "",
            deleteError:"",
            toatalCount: 0,
            pagination: {} as Pagination
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        mixpanel.track(configJSON.staffPageEnter);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getStaffListing();
        this.getStaffSearch();
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCall(apiRequestCallId, responseJson)
            } else if (this.isValidResponseMessage(responseJson)) {
                this.apiSuccessCall(apiRequestCallId, responseJson)
            } else if (this.isInValidResponse(responseJson)) {
                this.apifailureCall(apiRequestCallId, responseJson)
            } 
        }
    };

    apiSuccessCall = (apiRequestCallId: string, responseJson: StaffData & DeleteMock) => {
      
        if (apiRequestCallId === this.getStaffListingApiCall) {
            this.getStaffListingSuccess(responseJson)
        }
        if (apiRequestCallId === this.getStaffSearchApiCall) {
            this.getStaffSearchSuccess(responseJson)
        }
        if (apiRequestCallId === this.deleteSatffDataApiCall) {
            this.deleteSatffDataSuccess(responseJson)
        }

    };
   
    apifailureCall = (apiRequestCallId: string, responseJson: StaffData & DeleteMock) => {
        if (apiRequestCallId === this.deleteSatffDataApiCall) {
            this.deleteSatffDataFailure(responseJson)
        }

    };

    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    isValidResponseMessage = (responseJson: ValidResponseMessage) => {
        return responseJson && responseJson.message;
    };

    isInValidResponse = (responseJson: ErrorResponse) => {
        this.setState({isLoading:false})
        return responseJson && responseJson.errors;
    };

    apiCall = (data: APIPayloadType) => {
        let { contentType, method, endPoint, body } = data;
        const token = window.localStorage.getItem("admintoken")
        const header = {
            "Content-Type": contentType,
            token
        };

        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    getStaffListing = (page =1) => {
        this.getStaffListingApiCall = this.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getStaffList}+${page}`
        })
    };

    getStaffListingSuccess = (responseJson: StaffData) => {
        let responseMeta = responseJson.metadata?.meta?.pagination;
        this.setState({ staffData: responseJson.data, isLoading: false, pagination: responseMeta, toatalCount: responseMeta.total_count })
    };

    getStaffSearch = () => {
        this.getStaffSearchApiCall = this.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.searchStaffEndPoint}${this.state.searchString}`
        })
    };

    getStaffSearchSuccess = (responseJson: StaffData) => {
        let responseMeta = responseJson.metadata?.meta?.pagination;
        this.setState({ staffData: responseJson.data, pagination: responseMeta });
    };

    deleteSatffData = () => {
        this.setState({isLoading : true, isModalOpened: false})
        let deleteArray = this.state.deletedRows;
        let oneArrayInWord = deleteArray.join("").replace(",","").slice(0, -1);
        this.deleteSatffDataApiCall = this.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.deleteStaffEndpoint}${oneArrayInWord}`
        });
        mixpanel.track(configJSON.staffDelete, {"staff_id": oneArrayInWord});
    };
    
    deleteSatffDataSuccess = (responseJson: DeleteMock) => {
        this.setState({ selectedRows: [], deleteSuccess: responseJson.message },()=>{
            
            setTimeout(()=>{
                this.setState({deleteSuccess:""})
            },3000)
        })
        this.getStaffListing()
        let deleteArray = this.state.deletedRows;
        let staff_Id = deleteArray.join("").replace(",","").slice(0, -1);
        mixpanel.track(configJSON.staffDeleteSuccess, {"staff_id": staff_Id});
    };

    deleteSatffDataFailure = (responseJson: DeleteMock) => {
        this.getStaffListing()
        this.setState({selectedRows: [], deleteError: responseJson.errors },()=>{
            setTimeout(()=>{
                this.setState({deleteError:"",deletedRows:[]})
            },3000)
        })
        this.getStaffListing()
    };

    searchServices = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchString: event.target.value }, () => {
            this.getStaffSearch()
        });
    };

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {   
        if (event.target.checked) {
            const deletedServiceIds = this.state.staffData.map((staff) => 
                `service_provider_ids[]=${staff.id}&`
            );
            const selectedServiceIds = this.state.staffData.map((staff) => staff.id);
            this.setState({ selectedRows: selectedServiceIds, deletedRows: deletedServiceIds});
        } else {
            this.setState({ selectedRows: []});
        }
        
    };

    handleRowCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        let newID = `service_provider_ids[]=${id}&`;
      
        if (event.target.checked) {
            this.setState({ selectedRows: [...this.state.selectedRows, id],deletedRows:[...this.state.deletedRows,newID] }); 
        } else {
            this.setState((prevState) => ({
                selectedRows: prevState.selectedRows.filter((rowId: string) => rowId !== id),
                deletedRows: prevState.deletedRows.filter((deletedId: string) => deletedId !== newID)
            }));
        }
    };

    handleDeleteModal = () => {
        this.setState({ isModalOpened: !this.state.isModalOpened });
    };

    hangleNavigation = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"AdminStaffCreate");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        mixpanel.track(configJSON.addNewStaffButtonClicked);
    };

    handlePageChange = (page: number) => {
        this.getStaffListing(page);
        window.scrollTo({top:0, behavior:"smooth"})
    };
    // Customizable Area End
}
