import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Service } from "../../utilities/src/models/Service";
// Customizable Area Start
import { IconButtonProps } from "@material-ui/core";
export const configJSONUrl = require("../../../framework/src/config");
import moment, { Moment } from "moment";
import { Value } from "react-calendar/dist/cjs/shared/types";
import StorageProvider from "../../../framework/src/StorageProvider.web";
const navigation = require("react-navigation");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    // Customizable Area Start
    classes: Record<string, string>;
    // Customizable Area End
}

// Customizable Area Start
interface ValidResponse {
    data: object;
}

interface ValidResponseMessage {
    message: string;
}

export interface ErrorResponse {
    errors: [
        {
            token: string;
        }
    ]
}

export interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

export interface AppointmentListData {
    id: number;
    slot_start_time: string;
    slot_end_time: string;
    is_available: boolean;
}

export interface ResheduleDataInterface {
    id: number,
        type: string,
        attributes: {
            order_id: string,
            catalogue_id: number,
            payment_mode: string,
            total: number;
            status: string;
            duration: number,
            currency: string,
            updated_by_id: string,
            is_promocode_applied: boolean,
            appointment_date: string,
            order_date: string,
            personal_detail: {
                id: number,
                full_phone_number: string,
                email: string,
                full_name: string,
                created_at: string,
                updated_at: string,
                appointment_id: number,
                comment: string
            },
            service_provider: {
                id: number,
                full_name: string,
                email: string,
                phone_number: string,
                designation: string,
                image: string,
            },
            billing_address: string,
            time_slot: {
                slot_start_time: string,
                slot_end_time: string
            },
            timezone: string,
            time_zone_short: string,
            service: {
                title: string,
                price: string,
                duration: number,
                discount_price: number,
                discount_option: boolean,
                images: string,
            cancellation_policy: {
                is_cancellation_policy: boolean,
                no_of_days_cancel: number,
                cancel_text: string
            },
            reschedule_policy: {
                is_reschedule_policy: boolean,
                no_of_days_reschedule: number,
                reschedule_text: string
            },
            refund_policy: {
                is_refund_policy: boolean,
                refund_text: string
            }
        }

    }
}

export interface ResheduleDataInterface1 {
    data: ResheduleDataInterface
}
interface CatalgoueResponseData {
    data: {
        id: string;
        type: string;
        attributes: {
            title: string;
            description: string;
            duration: number;
            status: boolean;
            discount_option: boolean;
            discount: number;
            payment_preferences: string;
            category: {
                id: number;
                name: string;
            };
            images: [
                {
                    id: number;
                    url: string;
                }
            ];
            current_date: string;
            price: number;
            country: {
                id: number;
                name: string;
                code: string;
                phone_code: string;
            };
            currency: {
                id: number;
                name: string;
                symbol: string;
            };
            payment_method: string;
            time_zone: string;
            actual_price: number;
            availability: [
                {
                    working_day: string;
                    is_available: boolean;
                    working_hours: [
                        {
                            start_time: string;
                            end_time: string;
                        }
                    ]
                }
            ]
        }
    }
}

interface GetAppointmentListData {
    data: {
        id: string;
        type: string;
        attributes: {
            time_slots: [
                {
                    id: number;
                    slot_start_time: string;
                    slot_end_time: string;
                    is_available: false;
                }
            ]
        }
    },
    meta: {
        message: string;
        time_zone: string;
        time_zone_short: string;
    }
}

interface GetAppointmentListFailureData {
    message: string
}

export interface Values {
    name: string;
    email: string;
    phoneNumber: string;
    comments: string;
}
export interface AppointmentValidationSuccessData {
    message: string;
}
export interface AppointmentValidationFailureData {
    errors: Array<string>;
}
export interface FailureData {
    errors: string;
}

export interface Services {
    id: number,
    title: string,
    description: string,
    duration: number,
    total: number,
    discount_option: boolean,
    price: number,
    currency: {
        id: number,
        name: string,
        symbol: string
    }
}
export interface ServiceCategories {
    name: string,
    service_count: number,
    services: Array<Services>
}
interface ServiceProviderData {
    data: {
        id: string;
        type: string;
        attributes: {
            time_slots: Array<AppointmentListData>;
            service_provider: {
                id: number;
                full_name: string;
                designation: string;
                image: [
                    {
                        id: number;
                        url: string;
                    }
                ]
            }
        }
    }
    meta: {
        message: string;
        time_zone: string;
        time_zone_short: string
    }
}
export interface OrderSummery {
    serializer : {
        data: [
            { 
                id: string,
                type: string,
                
                attributes: {
                    order_number: number,
                    status: string,
                    total: number,
                    service: {
                        id:number,
                        title: string,
                        price: number,
                        discount_price: number,
                        discount_option: boolean,
                        duration: string
                    },
                    service_images: {
                        id: number,
                        url: string,
                        thumbnail_url:string,
                        small_url:string,
                    },
                    payment_mode: string,
                    currency: {
                        id: number,
                        name: string,
                        symbol: string
                    },
                    updated_by_id: number,
                    catalogue_price: number,
                    timezone: string,
                    time_zone_short: string,
                    order_date: string,
                    appointment_date: string,
                    customer: {
                        id: number,
                        full_phone_number: string,
                        email:string,
                        full_name: string,
                        created_at: string,
                        updated_at: string,
                        appointment_id: number,
                        comment: string
                    },
                    service_provider: {
                        id: number;
                        full_name: string;
                        phone_number: string;
                        email: string;
                        designation: string;
                        image: string
                    };

                }
            }
        ]
    }
}   
// Customizable Area End

export interface S {
    // Customizable Area Start
    available_date: string;
    start_time: Moment | Date;
    end_time: Moment | Date;
    id: number;
    token: string;
    appointmentsList: Array<AppointmentListData>;
    selectedDate: Date | null;
    selectedDate1: Date | null;
    service: Service;
    selectedSlot: AppointmentListData;
    name: string;
    email: string;
    order_id1:string;
    orderEmail:string;
    appointmentUpdatedata:any;
    orderDataEmail:string;
    orderDataStatus:string;
    orderDataID:number;
    orderDataNumber:number;
    orderId:string;
    orderserviceID:number,
    orderserviceIDs:number,
    orderPhoneNo:string;
    orderFullname:string;
    OrderDataFull:any;
    orderServiceProviderFullname:any;
    orderComment:string;
    mainTotal:number;
    cataloguePrice:number;
    orderCatalogue_price:number;
    orderserviceTitle:string;
    orderserviceDuration:string;
    orderserviceDiscountPrice:number;
    orderserviceprice:number,
    orderserviceDiscountOption:boolean;
    openDialog:boolean;
    deleteError:string;
    orderserviceUrl:string;
    orderUrl:string;
    orderappointment_date:string;
    ordertime_zone_short:string;
    phoneNumber: string;
    comments: string;
    errors: Partial<Values>;
    isDisabled: boolean;
    appointmentsAvaibilityId: string;
    screenSize: number;
    expanded: boolean;
    characterCount: number;
    slotBookingErrorMessage: string;
    deletionError:string;
    timeZone: string;
    timeZoneOffset: number;
    currency: string;
    price: number;
    appointmentValidationMsg: string;
    isLoading: boolean;
    initialLoading: boolean;
    isDataLenghtCheck: boolean;
    isPriceLoading: boolean;
    appointmentType: string;
    value: number;
    categoriesData: Array<ServiceCategories>;
    serviceData: Array<Services>;
    selectedData: Services;
    catalogueId: number;
    isSlotSelected: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentRescheduleController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAppointmentsListApiCallId: string = "";
    deleteAllAppointmentsApiCallId: string = "";
    popupWin: Window | null = null;
    getServiceProviderAvailabilityApiCallId: string = "";
    OrderSummeryApiCallId:string="";
    orderResheduleApiCallId:string="";
  
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationRaiseMessage),
            getName(MessageEnum.NavigationMessage),      
            // Customizable Area End
        ];

        this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
        let endTime = new Date();
        endTime.setMinutes(endTime.getMinutes() + 30);
        this.state = {
            // Customizable Area Start
            timeZoneOffset: 0,
            id: 0,
            start_time: new Date(),
            end_time: endTime,
            available_date: moment(new Date()).format("DD/MM/YY"),
            appointmentsList: [],
            token: "",
            selectedDate: new Date(),
            selectedDate1: new Date(),
            service: {
                images: [
                    {
                        url: ""
                    }
                ]
            } as Service,
            selectedSlot: {} as AppointmentListData,
            name: "",
            email: "",
            order_id1:"",
            orderEmail:"",
            appointmentUpdatedata:{},
            orderDataEmail:"",
            orderDataStatus:"",
            orderDataID:NaN,
            orderDataNumber:NaN,
            orderId:"",
            orderserviceID:NaN,
            orderserviceIDs:NaN,
            phoneNumber: "",
            orderPhoneNo:"",
            orderFullname:"",
            orderServiceProviderFullname:{},
            OrderDataFull:[],
            orderComment:"",
            mainTotal:NaN,
            cataloguePrice:NaN,
            orderCatalogue_price:NaN,
            orderserviceTitle:"",
            orderserviceDuration:"",
            orderserviceUrl:"",
            orderUrl:"",
            orderappointment_date:"",
            ordertime_zone_short:"",
            orderserviceDiscountPrice:NaN,
            orderserviceprice:NaN,
            orderserviceDiscountOption:false,
            openDialog:false,
            deleteError:"",
            comments: "",
            errors: {},
            isDisabled: true,
            appointmentsAvaibilityId: "",
            screenSize: window.innerWidth,
            expanded: false,
            characterCount: 0,
            slotBookingErrorMessage: "",
            deletionError:"",
            timeZone: "",
            currency: "",
            price: NaN,
            appointmentValidationMsg: "",
            isLoading: true,
            initialLoading: true,
            isDataLenghtCheck: false,
            isPriceLoading: true,
            appointmentType: "",
            value: 0,
            categoriesData: [],
            serviceData: [],
            selectedData: {} as Services,
            catalogueId: NaN,
            isSlotSelected: false,
            // Customizable Area End
        };

        // Customizable Area Start
        this.handleResize = this.handleResize.bind(this);
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
        // Customizable Area Start
        const orderData = JSON.parse(await StorageProvider.get("orderData"));
        this.setState({
            orderDataNumber:orderData.attributes.order_number,
            orderDataEmail:orderData.attributes.customer.email,
            orderDataID:orderData.id,
            orderDataStatus:orderData,
        })
      
        mixpanel.track(configJSON.appointmentReschedulePageEnter);
        window.addEventListener("resize", this.handleResize);
        
        await this.OrderSummery()
        // Customizable Area End
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponse(responseJson) || responseJson?.serializer) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            if (this.isValidResponseMessage(responseJson)) {
                this.getAppointmentListMessageSuccessCallBack(responseJson);
                this.apiSuccessCallBacks(apiRequestCallId, responseJson)
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            } 
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            runEngine.debugLog("Message Recived", message);

            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponse) => {
        return responseJson && responseJson.data;
    };

    isValidResponseMessage = (responseJson: ValidResponseMessage) => {
        return responseJson && responseJson.message;
    };

    isInValidResponse = (responseJson: ErrorResponse) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseMessage  & GetAppointmentListData & CatalgoueResponseData & AppointmentValidationSuccessData & OrderSummery & ResheduleDataInterface1) => {
       
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListSuccessCallBack(responseJson);
        }
       
        
        if (apiRequestCallId === this.getServiceProviderAvailabilityApiCallId) {
            this.getServiceProviderAvailabilitySuccessCallBack(responseJson);
        }
       
         if (apiRequestCallId === this.OrderSummeryApiCallId) {
           
            this.getOrderSummerySuccessCallBack(responseJson);  
        }
        if (apiRequestCallId === this.orderResheduleApiCallId) {
           
            this.getOrderResheduleApiCallBack(responseJson);  
        }
      
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: ErrorResponse  & GetAppointmentListFailureData   & AppointmentValidationFailureData  & FailureData ) => {
        
        if (apiRequestCallId === this.getAppointmentsListApiCallId) {
            this.getAppointmentListFailureCallBack(responseJson);
        }
      
       
        if (apiRequestCallId === this.getServiceProviderAvailabilityApiCallId) {
            this.getServiceProviderAvailabilityFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.orderResheduleApiCallId) {
            this.getOrderResheduleApiFailureCallBack(responseJson);  
        }
    };

    getOrderResheduleApiFailureCallBack = (errorReponse: FailureData) => {
        this.setState({
            openDialog:true,
            deleteError:errorReponse.errors
        })
    }

    textToUtcOffsetMinutes(text: string) {
        const offsetRegex = /UTC([+-]\d+(:\d+)?)/;
        const offsetMatch = (text || "").match(offsetRegex);
        if (!offsetMatch) {
            return 0;
        }
        const offsetStr = offsetMatch[1];

        const offsetParts = offsetStr.split(':');
        const hours = parseInt(offsetParts[0]);
        const minutes = parseInt(offsetParts[1] || "0");

        const totalMinutes = hours * 60 + minutes;
        return totalMinutes
    }

    isStringNullOrBlank(strings: string | null | undefined) {
        if (strings === null || strings === undefined) {
            return true;
        }
        if (typeof strings !== "string") {
            return true;
        }
        return strings.trim() === "";
    }

    handleBack = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"OrderStatus");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        window.scrollTo({top:0, behavior:"smooth"});  
    }

    focusToPopup = () => {
        this.popupWin?.focus();
    };

    handelAvaibilityTimeSlot = (slot: AppointmentListData) => {
        this.setState({
            selectedSlot: slot,
            isDisabled: false,
            isSlotSelected: true
        });
    }

    handelProceedFromDateTime = async () => {   
        mixpanel.track(configJSON.RescheduleProceedFromDateTime);
     this.OrderResheduleApi()
    };
    resheduleFinalPage = async () => {
        StorageProvider.set("bookingData", JSON.stringify(this.state.orderDataStatus)); 
        StorageProvider.set("appointmentUpdate", JSON.stringify(this.state.appointmentUpdatedata)); 
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"ResheduleConfirmStatus");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"});
        },200)
    }
    momentChangeForAppointmentDate = (appointment_date: string, time_zone_short: string) => {
        return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])")
    }

    momentChangeForOrderData = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
    }

    btnBackProps = () => {
        this.props.navigation.goBack();
    }

    handleResize() {
        this.setState({ screenSize: window.innerWidth });
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    dateIsDisabled = (item: { date: Date, view: string }) => {
        let { date, view } = item;
        const currentDate: object = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
        return view === "month" && date < currentDate && !moment(date).isSame(currentDate, "day");
    };

    convertTimeFormat(timeString: string): string {
        return moment(timeString, "HH:mm").format("h:mm A");
    }

    formatDate = (dateStr: Date | string | null) => {
        const newDate = moment(dateStr, "DD-MM-YYYY").format("DD-MM-YYYY");
        const [date_day, month, year] = newDate.split("-");
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const date = new Date(Number(year), Number(month) - 1, Number(date_day));

        const daySuffix = (number: number) => {
            if (number >= 11 && number <= 13) {
                return "th";
            }
            switch (number % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        const formattedDate = `${date.toLocaleString("en-US", { weekday: "long" })}, ${date.getDate()}${daySuffix(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`;
        return formattedDate;
    }

    handleDateChange = async (date: Value | null) => {
        this.setState({
            selectedDate: new Date(String(date)),
            isLoading: true
        }, () => this.state.orderServiceProviderFullname != null ?  this.getServiceProviderAvailability(this.state.token): this.getAppointmentList(this.state.token));
    };
    handleCloseDialog = () => {
        this.setState({
            openDialog: false,
        });
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
    };
    getServiceProviderAvailability = async (token: string) => {
        if (this.state.selectedDate && this.state.orderserviceID) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: token,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getServiceProviderAvailabilityApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.serviceProviderAvailabilityEndPoint}?catalogue_id=${this.state.orderserviceID}&availability_date=${formattedDate}&service_provider_id=${this.state.orderServiceProviderFullname?.id}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    getAppointmentList = async (token: string) => {
        if (this.state.selectedDate && this.state.orderserviceID) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;

            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: token,
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getAppointmentsListApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.appointmentAPiEndPoint}?catalogue_id=${this.state.orderserviceID}&availability_date=${formattedDate}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAppointmentListAPiMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
    getAppointmentListSuccessCallBack = (responseJson: GetAppointmentListData) => {
        this.setState({
            appointmentsAvaibilityId: responseJson.data.id,
            appointmentsList: responseJson.data.attributes.time_slots,
            timeZone: responseJson.meta.time_zone_short,
            timeZoneOffset: this.textToUtcOffsetMinutes(responseJson.meta.time_zone)
        }, () => {
            const currentDate: any = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
            if(this.state.initialLoading){
                this.setState({
                    selectedDate: currentDate._d
                })
            }
            this.setState({
                isDataLenghtCheck: false,
                isLoading: false,
                initialLoading: false
            })
        });
    }

    getAppointmentListMessageSuccessCallBack = (responseJson: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [] }, () => {
            this.setState({
                isLoading: false,
                initialLoading: false,
                isDataLenghtCheck: this.state.appointmentsList.length == 0 ? true : false
            })
        })
    }

    getAppointmentListFailureCallBack = (errorResponse: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [], timeZone: "" }, () => this.setState({ isLoading: false, initialLoading: false,  isDataLenghtCheck: this.state.appointmentsList.length == 0 ? true : false }));
    }

    getServiceProviderAvailabilitySuccessCallBack = (responseJson: ServiceProviderData) => {
        this.setState({
            appointmentsAvaibilityId: responseJson.data.id,
            appointmentsList: responseJson.data.attributes.time_slots,
            timeZone: responseJson.meta.time_zone_short,
            timeZoneOffset: this.textToUtcOffsetMinutes(responseJson.meta.time_zone)
        }, () => {
            const currentDate: any = moment().add(new Date().getTimezoneOffset() + this.state.timeZoneOffset, "m");
            if (this.state.initialLoading) {
                this.setState({
                    selectedDate: currentDate._d
                })
            }
            this.setState({
                isDataLenghtCheck: false,
                isLoading: false,
                initialLoading: false
            })
        });
    }

    getServiceProviderAvailabilityFailureCallBack = (errorResponse: GetAppointmentListFailureData) => {
        this.setState({ appointmentsList: [], timeZone: "" }, () => this.setState({ isLoading: false, initialLoading: false }));
    }

    OrderSummery = () => {
        const header = {
            "Content-Type": configJSON.appointmentApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.OrderSummeryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.OrderSummeryEndPoint + "email=" + this.state.orderDataEmail + "&order_id=" + this.state.orderDataNumber
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
             JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAppointmentListAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
   
    getOrderSummerySuccessCallBack =async (resData:OrderSummery) => {
        const responseJson = resData?.serializer;
        this.setState({
            OrderDataFull:responseJson.data[0],
           orderId: responseJson.data[0].id,
           orderEmail:responseJson.data[0].attributes.customer.email,
           orderFullname:responseJson.data[0].attributes.customer.full_name,
           orderServiceProviderFullname:responseJson.data[0].attributes.service_provider,
           orderPhoneNo:responseJson.data[0].attributes.customer.full_phone_number,
           orderComment:responseJson.data[0].attributes.customer.comment,
           mainTotal:responseJson.data[0].attributes.total,
           cataloguePrice:responseJson.data[0].attributes.catalogue_price,
           orderserviceTitle:responseJson.data[0].attributes.service.title,
           orderserviceID:responseJson.data[0].attributes.service.id,
           orderserviceDuration:responseJson.data[0].attributes.service.duration,
           orderserviceprice:responseJson.data[0].attributes.service.price,
           orderserviceDiscountPrice:responseJson.data[0].attributes.service.discount_price,
           orderserviceDiscountOption:responseJson.data[0].attributes.service.discount_option,
           orderserviceUrl:responseJson.data[0].attributes.service_images.small_url,
           orderUrl:responseJson.data[0].attributes.service_images.url,
           orderappointment_date:responseJson.data[0].attributes.appointment_date,
           ordertime_zone_short:responseJson.data[0].attributes.time_zone_short,
           isPriceLoading: false,
        }, async ()=>{
            await this.state.orderServiceProviderFullname != null ?  this.getServiceProviderAvailability(this.state.token): this.getAppointmentList(this.state.token) 
        });

        await StorageProvider.set("orderserviceID", JSON.stringify(this.state.orderserviceID));
       
     
    };

    getOrderResheduleApiCallBack = (responseJson:ResheduleDataInterface1) => {
        if(responseJson){
            this.setState({
                appointmentUpdatedata:responseJson.data,
               
            })
            this.resheduleFinalPage()
        } 
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"});
        },200)
       
    };
    
    OrderResheduleApi = () => {
        if (this.state.selectedDate) {
            const month = this.state.selectedDate.getMonth() + 1;
            const date = this.state.selectedDate.getDate();
            const year = this.state.selectedDate.getFullYear();
            const formattedDate = `${date < 10 ? "0" : ""}${date}-${month < 10 ? "0" : ""}${month}-${year}`;
            const header = {
                "Content-Type": configJSON.appointmentApiContentType,
                token: this.state.token,
            };

            const attrs = {
                appointment: {
                    time_slot_id:this.state.selectedSlot.id,
                    appointment_date:formattedDate
                }
            }
            
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.orderResheduleApiCallId = requestMessage.messageId;

            const httpBody = {
                ...attrs,
            };
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.orderResheduleApiMethod
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
             `${configJSON.orderResheduleApiEndPoint}${this.state.orderDataID}${"/reschedule_appointment"}`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    
    // Customizable Area End
}
