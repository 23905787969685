import React from "react";
// Customizable Area Start
import AppfooterController, { Props } from "./AppfooterController.web";
import Appfooter from "./Appfooter.web";
// Customizable Area End

export default class AppfooterWrapper extends AppfooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Appfooter
        countryCode={this.state.countryCode}
        phoneNumber={this.state.phoneNumber}
        socialMedias={this.state.socialMedias}
        applications={this.state.applications}
        copyright={this.state.copyright}
        staticPages={this.state.staticPages}
        loading={this.state.loading}
      />
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
