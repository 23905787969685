// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    tableLayout: "fixed",
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 2),
      height: "56px",
    },
    "& .MuiTableCell-paddingCheckbox": {
      padding: theme.spacing(0, 0, 0, 0.5),
    },
  },
  tableBodyRow: {
    "& tr": {
      border: "2px solid #ECEDF0",
    },
  },
  headerText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  maxBookingsCell: {
    width: "15%"
  },
  priceCell: {
    width: "10%"
  },
  checkbox: {
    color: "transparent !important",
  },
  center: {
    textAlign: "center",
  },
  service: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  servicetitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cellText: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
  },
  image: {
    width: "40px",
    height: "40px",
    flex: "0 0 40px",
    borderRadius: "4px",
  },
  statusWrapper: { display: "flex", justifyContent: "center" },
  pill: {
    width: "80px",
    padding: theme.spacing(0.25, 1),
    borderRadius: "32px",
  },
  draftPill: {
    border: "1px solid #FFCA99",
    backgroundColor: "#FFF2E7",
    color: "#653508",
  },
  activePill: {
    border: "1px solid #B3DAC7",
    backgroundColor: "#EBF5F0",
    color: "#008243",
  },
  maximumBookingColumn: {
    textAlign: "center"
  }
}));
// Customizable Area End