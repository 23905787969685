import React from "react";
import TextField from "@material-ui/core/TextField";
import UploadBox from "../../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";

const configJSON = {
  maximumTotalImageSizeCannotBeGreaterThan_50Mb: "Maximum total image size cannot be greater than 50MB",
  remove: "remove"
};

type FormikProps = {
  values: { [key: string]: any };
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

type DefaultProps = {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  showHeaderBar?: (options?: any) => void;
  onTextChange?: () => void;
};

type TextInputProps = DefaultProps & {
  [key: string]: any;
  limit?: number;
  rows?: number;
  InputProps?: any;
  disabled?: boolean;
} & FormikProps;

export const TextInput = ({
  name,
  label,
  placeholder = label,

  required,

  values,
  errors,
  touched,
  handleChange,
  showHeaderBar,
  onTextChange,
  limit = 0,
  rows,
  InputProps,
  disabled,
  ...props
}: TextInputProps) => {
  const classes = useStyles();
  const onChange = (e: any) => {
    handleChange(e);
    showHeaderBar?.();
  };
  let stringLength = limit && Object.keys(values).length > 0 ? (values[name]) : "";
  return (
    <div>
      {label && (
        <span className={classes.inputLabelWrapper}>
          <span
            className={
              disabled ? classes.disabledInputLabel : classes.inputLabel
            }
          >
            {label + (required ? " *" : "")}
          </span>
          {stringLength ? (
            <span className={classes.limitLabel}>{stringLength.length + `/${limit}`}</span>
          ) : null}
        </span>
      )}
      <TextField
        {...props}
        disabled={disabled}
        variant="outlined"
        placeholder={placeholder}
        className={classes.fullWidth}
        rows={rows}
        InputProps={{
          classes: {
            input: classes.resizeable,
            ...(InputProps?.classes || {}),
          },
          ...(InputProps || {}),
        }}
        onChange={onTextChange ? onTextChange : onChange}
        name={name}
        value={_.get(values, name)}
        error={!!(_.get(errors, name) && _.get(touched, name))}
        helperText={
          _.get(errors, name) && _.get(touched, name) ? _.get(errors, name) : ""
        }
      />
    </div>
  );
};

type Option = {
  label: any;
  value: any;
  id?: any;
};

type SelectInputProps = DefaultProps & {
  options: Option[];
  onChange?: (value: any, name: string, setFieldValue: any) => void;
  InputProps?: any;
} & FormikProps;

export const SelectInput = ({
  name,
  label,
  placeholder = label,
  options,

  required,

  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  onChange: propsChange,
  showHeaderBar,
  InputProps,
}: SelectInputProps) => {
  const classes = useStyles();
  const onChange = handleOnchange(
    handleChange,
    propsChange,
    name,
    setFieldValue,
    showHeaderBar
  );
  return (
    <div className={classes.selectInput}>
      <span className={classes.inputLabelWrapper}>
        <span className={classes.inputLabel}>
          <span>{label + (required ? " *" : "")}</span>
        </span>
      </span>
      <Select
        displayEmpty
        // @ts-ignore
        renderValue={(value) => {
          return value ? (
            options.find((i) => i.value === value)?.label || value
          ) : (
            <div className={classes.selectInputPlaceholder}>{placeholder}</div>
          );
        }}
        name={name}
        // @ts-ignore
        InputProps={{
          ...(InputProps || {}),
          autoComplete: "off",
        }}
        onChange={onChange}
        className={classes.fullWidth}
        classes={{
          root: classes.selectInputRoot,
          icon: classes.selectInputIcon,
        }}
        variant="outlined"
        placeholder={placeholder}
        value={_.get(values, name) || ""}
        error={!!(_.get(errors, name) && _.get(touched, name))}
      >
        {options.map((i) => (
          <MenuItem key={i.id || i.value} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
      </Select>
      {!!(_.get(errors, name) && _.get(touched, name)) && (
        <FormHelperText error={true}>
          {_.get(errors, name) && _.get(touched, name)
            ? _.get(errors, name)
            : ""}
        </FormHelperText>
      )}
    </div>
  );
};

type ImageInputProps = DefaultProps & {
  height?: string;
  width?: string;
  multiple?: boolean;
} & FormikProps;

export const ImageInput = ({
  name,
  label,
  required,
  height = "100px",
  width = "100%",

  values,
  setFieldValue,
  showHeaderBar,
  errors,
  touched,
  multiple,
}: ImageInputProps) => {
  const classes = useStyles();
  const image = _.get(values, name);

  const handleSelectImage = (files: any) => {
    if (!multiple) {
      if (files.length) {
        const reader = new FileReader();

        reader.onload = () => {
          setFieldValue(name, reader.result);
          showHeaderBar?.();
        };
        setFieldValue(name + "_raw", files[0]);
        reader.readAsDataURL(files[0]);
      }
    } else {
      const fileArray = Array.from(files);
      const totalSize = [
        ...(_.get(values, name + "_raw") || []),
        ...fileArray,
      ].reduce(
        // @ts-ignore
        (acc: number, curr) => acc + Number(curr?.size || 0),
        0
      );
      if (totalSize > 50 * 1024 * 1024) {
        return showHeaderBar?.({
          message: configJSON.maximumTotalImageSizeCannotBeGreaterThan_50Mb,
          type: "error",
        });
      }
      const beforeImageCount = _.get(values, name)?.length || 0;
      fileArray.forEach((i: any, index: number) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = () => {
          setFieldValue(`${name}[${index + beforeImageCount}]`, reader.result);
        };
        setFieldValue(`${name}_raw[${index + beforeImageCount}]`, i);
        reader.readAsDataURL(i);
      });

      showHeaderBar?.();
    }
  };

  const handleRemoveSelectedImage = (index?: number) => {
    if (multiple) {
      setFieldValue(`${name}[${index}]`, null);
      setFieldValue(`${name}_raw[${index}]`, null);
    } else {
      setFieldValue(name, null);
    }
    showHeaderBar?.();
  };

  const handleImageUploadSelectedImageWrapper = (classes: any) => {
    return (
      <div className={classes.imageUploadSelectedImageWrapper}>
        <img
          className={classes.imageUploadSelectedImage}
          src={image}
          alt={label}
        />
        <IconButton
          aria-label="close"
          className={classes.imageUploadSelectedImageRemove}
          onClick={() => {
            handleRemoveSelectedImage();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  const handleImageUploadMultipleWrapper = (image: any) => {
    return (
      <div className={classes.imageUploadMultipleWrapper}>
        {image.map((i: string, index: number) =>
          !i ? null : (
            <div
              className={classes.imageUploadMultipleSelectedImageWrapper}
            >
              <img
                className={classes.imageUploadMultipleSelectedImage}
                src={i}
                alt={label}
              />
              <Button
                className={classes.imageUploadMultipleSelectedImageRemove}
                onClick={() => {
                  handleRemoveSelectedImage(index);
                }}
              >
                {configJSON.remove}
              </Button>
            </div>
          )
        )}
        <UploadBox
          name={name}
          uploadIcon={require("./assets/add.svg")}
          height="155px"
          minHeight="120px"
          width="100%"
          margin="0"
          uploadMsg="Add Image"
          sizeMsg="Max 20MB"
          onSelectFile={handleSelectImage}
          multiple={multiple}
        />
      </div>
    )
  }

  return (
    <div className={classes.imageInput}>
      <span className={classes.inputLabel}>
        <span>{label + (required ? " *" : "")}</span>
      </span>
      {(!multiple && _.get(values, name)) ||
        (multiple && _.get(values, name) && _.get(values, name)?.filter((i: any) => !!i).length) ? (
        selectProperImageUploadWrapper(multiple, handleImageUploadSelectedImageWrapper, classes, handleImageUploadMultipleWrapper, image)
      ) : (
        <UploadBox
          name={name}
          uploadIcon={require("./assets/add.svg")}
          height={height}
          width={width}
          margin="0"
          uploadMsg="Add Image"
          sizeMsg="Max 20MB"
          onSelectFile={handleSelectImage}
          multiple={multiple}
        />
      )}
      <FormHelperText error={!!(_.get(errors, name) && _.get(touched, name))}>
        {_.get(errors, name) && _.get(touched, name) ? _.get(errors, name) : ""}
      </FormHelperText>
    </div>
  );
};

type RadioInputProps = DefaultProps & {
  classes: any;
  options: Option[];
  onChange?: (value: any, name: string, setFieldValue: any) => void;
} & FormikProps;

export const RadioInput = withStyles({
  root: {
    padding: 0,
    color: "#757575",
    "& .MuiSvgIcon-root:nth-of-type(1)": {
      color: "#D0D2DA",
    },
    "& .MuiSvgIcon-root:nth-of-type(2)": {
      color: "#00D659",
    },
  },
})(
  ({
    name,
    values,
    handleChange,
    classes: propsClasses,
    onChange: propsChange,
    setFieldValue,
    options,
    showHeaderBar,
  }: RadioInputProps) => {
    const classes = useStyles();
    const onChange = handleOnchange(
      handleChange,
      propsChange,
      name,
      setFieldValue,
      showHeaderBar
    );
    return (
      <div className={classes.radioGroupInput}>
        {options.map((i) => (
          <label className={classes.radioInput} key={`${name}-${i.value}`}>
            <Radio
              color="primary"
              onChange={onChange}
              classes={propsClasses}
              name={name}
              checked={_.get(values, name) === i.value}
              value={i.value}
            />
            <span className={classes.radioLabel}>{i.label}</span>
          </label>
        ))}
      </div>
    );
  }
);

type CheckboxInputProps = DefaultProps & {
  classes: any;
  onChange?: (checked: boolean) => void;
} & FormikProps;

export const CheckboxInput = withStyles({
  root: {
    padding: 0,
    color: "#757575",
    "&$checked": {
      color: green[800],
    },
  },
})(
  ({
    name,
    label,
    required,
    values,
    handleChange,
    onChange: propsOnChange,
    classes: propsClasses,
    showHeaderBar,
  }: CheckboxInputProps) => {
    const classes = useStyles();
    const onChange = (e: any) => {
      handleChange(e);
      propsOnChange?.(e.target.checked);
      showHeaderBar?.();
    };
    return (
      <label className={classes.checkboxInput}>
        <Checkbox
          color="primary"
          name={name}
          checked={_.get(values, name) || false}
          onChange={onChange}
          classes={propsClasses}
        />
        <span className={classes.checkboxLabel}>
          {label + (required ? " *" : "")}
        </span>
      </label>
    );
  }
);

function handleOnchange(
  handleChange: any,
  propsChange: any,
  name: string,
  setFieldValue: any,
  showHeaderBar: any
) {
  return (e: any) => {
    handleChange(e);
    propsChange?.(e.target.value, name, setFieldValue);
    showHeaderBar?.();
  };
}

function selectProperImageUploadWrapper(
  multiple: any,
  handleImageUploadSelectedImageWrapper: any,
  classes: any,
  handleImageUploadMultipleWrapper: any,
  image: any
): any {
  return !multiple ?
    handleImageUploadSelectedImageWrapper(classes)
    :
    handleImageUploadMultipleWrapper(image)
}
