import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";

import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export interface PaginationType {
  current_page: number;
  next_page: number;
  total_pages: number;
  total_count: number;
  current_count: number;
  per_page: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  identifier: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface CustomCustomer {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    email: string;
    full_name: string;
    full_phone_number: string;
    created_at: string;
  }
  // Customizable Area End
}


export interface S {
  // Customizable Area Start
  token: string;
  customerList: CustomCustomer[];
  loading: boolean;
  searchBy: string;
  showList: boolean;
  search?: string;
  pagination?: PaginationType;
  isCsvDownloading: boolean;
  customerError: string;
  currentPageNumber:number
  // Customizable Area End
}

interface SS {
  identifier: string;
}

export default class CustomerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactApiCallId: string | undefined;
  deleteCustomerApiCallId: string | undefined;
  searchCustomerApiCallId: string | undefined;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactApiCallId = "";
    this.deleteCustomerApiCallId = "";
    this.searchCustomerApiCallId = "";

    this.state = {
      search: "",
      token: "",
      customerList: [],
      loading: true,
      searchBy: "",
      showList: true,
      isCsvDownloading: false,
      customerError: "",
      currentPageNumber: 1
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const customerId = this.props.navigation.getParam("id");
    const page = this.props.navigation.getParam("page");
    (customerId && page) !== undefined && this.getCustomerList(page)
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }
    const messageID = message.id;
    const data = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    runEngine.debugLog("API Message Received", message);

    if (messageID === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      const customerId = this.props.navigation.getParam("id");
      if (this.state.searchBy === '' && customerId === undefined) {
        this.getCustomerList()
      }
    } else if (messageID === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleDownloadCSV(message);
      if (apiRequestCallId === this.contactApiCallId) {
        if(data.data){
          const contactData = data.data;
          const pagination = data?.metadata?.meta?.pagination;
          this.handleCustomerAPIResponse(contactData, pagination);
        }else {
          this.setState({customerError: data.errors[0].message, loading: false})
        }
      } else if (apiRequestCallId === this.searchCustomerApiCallId) {
        const contactData = data.data;
        const pagination = data?.metadata?.meta?.pagination;
        if (contactData) {
          this.handleCustomerAPIResponse(contactData, pagination);
        }
        else {
          this.setState({ showList: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
  
  handleDownloadCSV = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(this.downloadCSVMessageId === apiRequestCallId){
      this.setState({ isCsvDownloading: false });
      if(responseJson.csv_data){
        const csvRows = responseJson.csv_data;
        let csvContent = "data:text/csv;charset=utf-8,";

        csvRows.forEach(function (rowArray: string[]) {
          let rowData = rowArray.join(",");
          csvContent += rowData + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(link);
        link.click();
      }
    }
  }

  downloadCSVMessageId:string = "";
  download = async () => {
    this.setState({ isCsvDownloading: true })
    mixpanel.track("webadmin_customers_download_csv");
    const requestMessage = await generateRequestMessage(
      configJSON.routeDownloadCSV,
      configJSON.httpGetMethod
    )
    this.downloadCSVMessageId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerList = async (page = 1) => {
    mixpanel.track("webadmin_customers_page_enter");
    const requestMessage = await generateRequestMessage(
      configJSON.getCustomerAPiEndPoint + "?page=" + page,
      configJSON.httpGetMethod
    )
    this.contactApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCustomerAPIResponse(data: CustomCustomer[], pagination: PaginationType | undefined) {
        this.setState({ customerList: data, loading: false, pagination });
  }

  searchCustomerList = async (value: string, page=1) => {
    this.setState({ search: value })
    const requestMessage = await generateRequestMessage(
      configJSON.searchCustomerApiEndPoint + `?search=${value}&page=${page}`,
      configJSON.httpGetMethod
    )
    this.searchCustomerApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleFilterCustomerList = (event: any) => {
    const searchText = event.target.value.toLowerCase();

    if (searchText !== this.state.searchBy) {
      this.setState({ searchBy: searchText, showList: true });
      this.searchCustomerList(searchText);
    } else {
      this.setState({ searchBy: "", showList: true });
      this.getCustomerList();
    }
  }

  btnBackProps = () => {
    this.props.navigation.goBack()
  }

  handlePageChange = (page: number) => {
    this.setState({
      currentPageNumber: page
    })
    if (this.state.searchBy) {
      this.searchCustomerList(this.state.searchBy, page);
    } else {
      this.getCustomerList(page)
    }
    window.scrollTo({top:0, behavior:"smooth"})
  }

  handleNavigateCustomerAdminList = (item: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),`CustomerAdminList/${item}/${this.state.currentPageNumber}`);
    navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(navigation);
  }
  // Customizable Area End
}
