Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'

exports.getBrandsEndPoint = "bx_block_catalogue/brands/home_brand_footer";
exports.getStaticPagesEndPoint = 'bx_block_static_pages/static_pages'

exports.getMethod = 'GET'

// Customizable Area End
