// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  TextField,
  IconButton,
  SvgIcon
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { appointmentHours as useStyles } from "./styles/AppointmentHours.web";
import { Close as CloseIcon } from "@material-ui/icons";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { Availability } from "../../utilities/src/models/Appointment";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked.svg";
import moment from "moment"

const configJSON = require("./config");

const AppointmentHoursSchema = Yup.array().of(
  Yup.object().shape({
    day: Yup.string(),
    workingHours: Yup.array().of(
      Yup.object().shape({
        openingTime: Yup.string()
          .test("required", "Opening time is required", function () {
            const { from } = this;
            if (from && from.length) {
              if (from[1].value.selected) {
                return !!from[0].value.openingTime;
              } else {
                return (
                  !from[0].value.closingTime || !!from[0].value.openingTime
                );
              }
            }
          })
          .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
            "Opening time must be in 12-hour format"
          ),
        closingTime: Yup.string()
          .test("required", "Closing time is required", function () {
            const { from } = this;
            if (from && from.length) {
              if (from[1].value.selected) {
                return !!from[0].value.closingTime;
              } else {
                return (
                  !from[0].value.openingTime || !!from[0].value.closingTime
                );
              }
            }
          })
          .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
            "Closing time must be in 12-hour format"
          )
          .when(
            "openingTime",
            ([openingTime], schema: any) =>
              openingTime &&
              schema.test({
                test: (closingTime: string) => {
                  if (closingTime) {
                    const openingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${openingTime}`, "DD/MM/yyyy h:mm a").unix()
                    const closingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${closingTime}`, "DD/MM/yyyy h:mm a").unix()
                    return closingDateTime > openingDateTime;
                  }
                },
                message: "Closing time should be after Opening time",
              })
          ),
      })
    ),
    selected: Yup.bool(),
  })
);

interface Props {
  availability: Availability[];
  availabilityErrors: string[];
  checkAvailabilityForm: (confirmedAvailability: Availability[]) => void;
  closeModal: () => void;
}

const AppointmentHours: React.FC<Props> = ({
  availability,
  checkAvailabilityForm,
  closeModal
}) => {
  const classes = useStyles();

  const initialValues = availability;

  const formik = useFormik({
    initialValues,
    validationSchema: AppointmentHoursSchema,
    onSubmit: checkAvailabilityForm
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    values,
    touched,
  } = formik;

  const onSubmit = () => {
    handleSubmit();
    checkAvailabilityForm && checkAvailabilityForm(values);

    if (Object.keys(errors).length === 0) {
      closeModal && closeModal();
    }
  };

  return (
    <Box data-testid="appointment_modal" className={classes.backdrop}>
      <form className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography className={classes.title}>{configJSON.textAppointmentHours}</Typography>

            <IconButton className={classes.iconWrapper} onClick={closeModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>

          <Box className={classes.content}>
            <Box className={classes.subheaders}>
              <Typography className={classes.subtitle}>Day</Typography>
              <Typography className={classes.subtitle}>
                {configJSON.textWorkingHours}
              </Typography>
            </Box>

            <List className={classes.list}>
              {values.map(({ day: dateDay, workingHours, selected }, index) => (
                <ListItem
                  key={index}
                  data-testid={`${dateDay}-availability`}
                  disableGutters
                  className={classes.listItem}
                >
                  <Box className={classes.dayWrapper}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={selected}
                      {...getFieldProps(`[${index}].selected`)}
                      icon={
                        <SvgIcon component={CheckboxIcon} viewBox="0 0 20 20" />
                      }
                      checkedIcon={
                        <SvgIcon
                          component={CheckboxIconChecked}
                          viewBox="0 0 20 20"
                        />
                      }
                    />
                    <Typography className={classes.day}>{dateDay}</Typography>
                  </Box>
                  <List>
                    {workingHours.map((_wHour, hourIndex) => (
                      <ListItem
                        key={`${index}-${hourIndex}`}
                        disableGutters
                        className={classes.workingRange}
                      >
                        <TextField
                          variant="outlined"
                          placeholder="hh:mm AM/PM"
                          inputProps={{ className: classes.input }}
                          className={classes.field}
                          data-testid={`${dateDay}-opening-time`}
                          disabled={!selected}
                          {...getFieldProps(
                            `[${index}].workingHours[${hourIndex}].openingTime`
                          )}
                          error={Boolean(
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime
                          )}
                          helperText={
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).openingTime
                          }
                        />
                        <Typography className={classes.separator}>-</Typography>
                        <TextField
                          variant="outlined"
                          placeholder="hh:mm AM/PM"
                          inputProps={{ className: classes.input }}
                          className={classes.field}
                          data-testid={`${dateDay}-closing-time`}
                          disabled={!selected}
                          {...getFieldProps(
                            `[${index}].workingHours[${hourIndex}].closingTime`
                          )}
                          error={Boolean(
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime
                          )}
                          helperText={
                            touched &&
                            touched[index] &&
                            touched[index]!.workingHours &&
                            touched[index]!.workingHours![hourIndex] &&
                            (
                              touched[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime &&
                            errors &&
                            errors[index] &&
                            errors[index]!.workingHours &&
                            errors[index]!.workingHours![hourIndex] &&
                            (
                              errors[index]!.workingHours![
                              hourIndex
                              ] as FormikErrors<
                                (typeof workingHours)[typeof hourIndex]
                              >
                            ).closingTime
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box className={classes.actions}>
          <Button
            variant="text"
            className={classes.cancelButton}
            onClick={closeModal}
          >
            {configJSON.textCancel}
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            className={classes.saveButton}
            data-testid="save_availability"
          >
            {configJSON.textSave}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AppointmentHours;
// Customizable Area End