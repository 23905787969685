// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const Dashboard = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(5)
    }
  },
  containerHeight: {
    height:"480px",
    width: "100%",
    [theme.breakpoints.down(1100)]: {
      height: "350px"
    },
    [theme.breakpoints.down(900)]: {
      height: "270px"
    },
    [theme.breakpoints.down(750)]: {
      height: "220px"
    },
    [theme.breakpoints.down(580)]: {
      height: "180px"
    },
    [theme.breakpoints.down(480)]: {
      height: "140px"
    }
  },
  banner: {
    height: "100%",
    cursor: "pointer",
    width: "100%",
    objectFit: "fill",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
    }
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 10),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 4)
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2)
    }
  },
  sectionTitleWrapper: {
    [theme.breakpoints.down("sm")]: {
      alignSelf: "start"
    }
  },
  sectionTitle: {
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "3.2px",
    color: "#364F6B",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      paddingBottom: theme.spacing(1)
    }
  },
  titleDivider: {
    width: "60%",
    height: "3px",
    backgroundColor: "#3FC1CB",
    margin: "auto",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      margin: theme.spacing(0)
    }
  },
  testimonials: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(10, 0, 0, 0),
    width: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: theme.spacing(5, 0,0,0),
      gap: theme.spacing(2.5),
      width: "100%"
    }
  },
  testimonial: {
    width: "23%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: "270px",
      padding: theme.spacing(0, 0,0,0),
      alignItems: "start",
      textAlign: "start"
    }
  },
  testimonialUsername: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#3C3E49"
  },
  testimonialStarsWrapper: {
    display: "flex"
  },
  testimonialStar: {
    fontSize: "24px"
  },
  testimonialRated: {
    color: "#FFC400"
  },
  testimonialUnrated: {
    color: "#C4C4C4"
  },
  testimonialDescription: {
    fontSize: "14px",
    lineHeight: "21px",
    fontStyle: "italic",
    color: "#3C3E49",
    padding: theme.spacing(2.5, 0, 0, 0)
  },
  ServiceListSkeltonBox:{
    display: "flex",
    padding: "40px 0 0 0",
    height: "480px",
    width:"100%",
    gap: 24,
    [theme.breakpoints.down(1100)]: {
      height: "350px"
    },
    [theme.breakpoints.down(900)]: {
      overflowX: "auto",
      padding: "24px 0px 4px",
      gap: 16,
      height: "270px"
    },
    [theme.breakpoints.down(750)]: {
      height: "220px"
    },
    [theme.breakpoints.down(580)]: {
      height: "180px"
    },
    [theme.breakpoints.down(480)]: {
      height: "140px"
    }
  },
  testimonialSkeltonBox: {
    height: "277px",
    [theme.breakpoints.down(480)]: {
      height: "226px"
    }
  }
}));
// Customizable Area End
