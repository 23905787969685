//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    withStyles,
    Grid,
    createStyles,
    Button,
    IconButton,
} from "@material-ui/core";
import Slider from '@material-ui/core/Slider';
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import withDialogBox from "../HOC/withDialog.web";
import AddCropperController from "./AddCropperController.web";
const configJSON = require("./config.js");
// Customizable Area End
export class AddCropper extends AddCropperController {
    // Customizable Area Start
    closeDialog = (data) => {
        const { onCancel } = this.props;
        onCancel();
    };
    cropImage() {
        if (typeof this?.cropper?.cropper?.getCroppedCanvas() === "undefined") {
            return;
        }
        const { onSubmit, dataToPass } = this.props;
        onSubmit(this.cropper?.cropper?.getCroppedCanvas(
            dataToPass.type.match(/.(jpg|jpeg)$/i) ?
                { fillColor: '#fff' } : undefined
        )?.toDataURL(dataToPass.type, 0.6));
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, dataToPass, isAspectRatio } = this.props;

        return (
            <>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <Cropper
                            style={{ height: 350, width: '100%' }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            src={dataToPass.selectedFile}
                            ref={(cropper) => {
                                this.cropper = cropper;
                            }}
                            checkOrientation={false}
                            guides={true}
                            dragMode="crop"
                            cropBoxMovable={true}
                            aspectRatio={isAspectRatio && 3 / 1}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <IconButton onClick={this.zoomOut.bind(this)} >
                                    <RemoveIcon></RemoveIcon>
                                </IconButton>
                            </Grid>
                            <Grid item xs>
                                <Slider value={this.state.zoom} min={0.5} max={1} step={0.01} onChange={this.handleZoomChange.bind(this)} aria-labelledby="continuous-slider" />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={this.zoomIn.bind(this)} >
                                    <AddIcon></AddIcon>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes?.footerAction}>
                    <Button variant="text" color="primary" onClick={this.closeDialog.bind(this)} >{configJSON.cancel}</Button>
                    <Button variant="contained" color="primary" style={{ padding: '24px 48px', minWidth: '160px' }} onClick={this.cropImage.bind(this)}>{configJSON.crop}</Button>
                </div>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export const styles = (theme: Theme) =>
    createStyles({
        dialogFooter: {
            '& > *': {
                margin: theme?.spacing(0, 1),
            },
        },
        footerAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: '0 0 1',
            gap: '16px',
            borderTop: '1px solid grey'
        }
    })
export default withStyles(styles)(withDialogBox(AddCropper));
// Customizable Area End