// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { WithStyles, WithTheme } from "@material-ui/core";
import { styles } from "./ForgotPassword.web";
import { RouterProps } from "react-router";
import { FormikProps } from "formik";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { checkResponseError, showError } from "../../ss-cms-common-components/src/Utilities/Utilities";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export type Props = withHeadeActionBarProps &
  RouterProps &
  DialogProps &
  withLoaderProps &
  WithStyles<typeof styles> &
  WithTheme & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  email: string;
  forgotPasswordEmail: string;
  loading: boolean;
  showOtp: boolean;
  showOtpError: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  otpExpireDate?: Date;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export type DataWithoutTokenType = {
  email: string;
  otp?: string;
};

export type DataWithTokenType = {
  password: string;
  password_confirmation: string;
  email?: string;
  otp?: string;
};

export type RequestDataWithoutTokenType = {
  login: string;
  otp?: string;
};


export const SchemaWithToken = Yup.object({
  password: Yup.string()
    .required(configJSON.textEnterPassword)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&\-_=+{};:,.<>'])(?=.{8,})/,
      configJSON.textPasswordValidation
    ),
  password_confirmation: Yup.string()
    .required(configJSON.textEnterPassword)
    .oneOf([Yup.ref("password")], configJSON.textPasswordsMustMatch)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&\-_=+{};:,.<>'])(?=.{8,})/,
      configJSON.textPasswordValidation
    ),
});

export const SchemaWithoutToken = Yup.object({
  email: Yup.string()
    .email(configJSON.textEmailMustValid)
    .required(configJSON.textThisFieldIsRequired),
});

export const InitialValuesWithToken = {
  password: "",
  password_confirmation: "",
};

export const InitialValuesWithoutToken = {
  email: "",
  otp: "",
};

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  forgotPasswordApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      token: "",
      forgotPasswordEmail: "",
      loading: false,
      showOtp: false,
      showOtpError: false,
      showPassword: false,
      showConfirmPassword: false,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  goToLogin = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLogin'
    );
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(to);
  };

  handleDataChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: FormikProps<DataWithoutTokenType>["handleChange"]
  ) => {
    handleChange(event);
    this.setState({ showOtpError: false });
  };

  handleForgotPassword = (requestData: RequestDataWithoutTokenType) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestData.otp
        ? configJSON.forgotApiWithOtpEndPoint
        : configJSON.forgotAPiEndPoint
    );
    const headers = {
      "Content-Type": configJSON.htmlRequestContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  forgotPassword = async (values: DataWithoutTokenType) => {
    const requestData: RequestDataWithoutTokenType = {
      login: values.email,
      otp: values.otp,
    };
    if (this.state.showOtp && !values.otp) {
      return this.setState({ showOtpError: true });
    } else {
      this.setState({ showOtpError: false, email: requestData.login });
    }

    this.setState({
      forgotPasswordEmail: requestData.login,
    });
    return this.handleForgotPassword(requestData);
  };

  resetPassword = (values: DataWithTokenType) => {
    const requestData = { ...values, login: this.state.email };
    delete requestData.otp;

    this.setState({
      loading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );
    const headers = {
      "Content-Type": configJSON.htmlRequestContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ...requestData, token: this.state.token })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.htmlMethodPUT
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.messageForgotPasswordApiCall(message);
      this.messageResetPasswordApiCall(message);
    }
    // Customizable Area End
  }

  messageForgotPasswordApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.forgotPasswordApiCallId) {
      if (responseJson.token) {
        // validated otp
        this.setState({
          token: responseJson.token,
          loading: false,
        });
      } else {
        if (responseJson.errors || responseJson.error) {
          this.props.showHeaderBar({
            message: responseJson.error || responseJson.errors[0],
            type: "error",
          });
          this.setState({ loading: false });
        } else {
          this.props.showHeaderBar({
            message: responseJson.messages[0],
            type: "success",
          });

          const otpExpireDate = new Date(Date.now() + 5 * 60 * 1000);
          this.setState({ loading: false, showOtp: true, otpExpireDate });
        }
      }
    }
  };

  messageResetPasswordApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);

    if (apiRequestCallId === this.resetPasswordApiCallId) {
      if (checkResponseError(responseJson)) {
        showError(
          configJSON.textErrorResetPassword,
          responseJson,
          this.props.hideLoader,
          this.props.setDialogState
        );
      } else {
        this.props.showHeaderBar({
          type: "success",
          message: configJSON.textSuccessResetPassword,
        });
        setTimeout(() => {
          const to = new Message(getName(MessageEnum.NavigationMessage));
          to.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLogin");
          to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          runEngine.sendMessage(to.messageId, to);
        }, 4000);
      }
      this.setState({ loading: false });
    }
  };

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  resendOtp = () => {
    this.handleForgotPassword({
      login: this.state.forgotPasswordEmail,
    });
  };

  // Customizable Area End
}
