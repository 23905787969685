// Customizable Area Start
import React from "react";
import EmailTemplatesCreateController, {
  Props,
  normalizeContentWithDefaultEmailSettings,
} from "./EmailTemplatesCreateController.web";
import { FormHelperText, TextField, IconButton, Button, Typography, Box,  Tooltip, withStyles, Theme } from "@material-ui/core";
import Editor, {
  Preview,
} from "../../ss-cms-common-components/src/Editor-v4/Editor";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import "../assets/styles.css";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { Scrollbars } from "react-custom-scrollbars";
import { Prompt } from "react-router";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { DesktopIcon, TabletIcon, MobileIcon, InfoIcon } from "./assets";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { twitter_logo } from "../../footer/src/assets";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

const configJSON = require("./config.js");
// Customizable Area End
export class EmailTemplatesCreate extends EmailTemplatesCreateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab, content, defaultEmailSettings, headerVisible } = this.state;
    const normalizedContent = normalizeContentWithDefaultEmailSettings(
      content || "",
      defaultEmailSettings
    );

    return (
      <div className="email-template-create">
        {this.state.editorMaximized && (
          <Prompt
            message={(location, action) => {
              return true;
            }}
          />
        )}
        <div className="email-template-create-left">
          <Scrollbars>
            <div className="header">
              <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                className="icon-back-wrapper"
                onClick={this.handleBackButtonClick}
              >
                <Typography>Back</Typography>
              </Button>
              <Box>
                <span className="header-title">
                  {this.state.titleInit || "Create Email"}
                </span>
              </Box>
              <span className="hint">{configJSON.textQuickEmailPreview}</span>
              <div className="tabs-wrapper">
                <Tabs onTabChange={this.handleTabChange} value={selectedTab} />
                {/* <div className="preview-email-hint">
                  <img src={PreviewIcon} alt="preview email" />
                  <span>{configJSON.textPreviewEmail}</span>
                </div> */}
              </div>
            </div>
            <div className="container">
              <div
                className={`preview-wrapper ${this.handleDeviceResolution(
                  selectedTab
                )}`}
              >
                {headerVisible && <PreviewHeader logo={this.state.logo}/>}
                <Preview value={normalizedContent} />
                <PreviewFooter social={this.state.social} commonSettings={this.state.commonSettings} />
              </div>
            </div>
          </Scrollbars>
        </div>
        <Scrollbars style={{ width: "30%" }}>
          <div className="email-template-create-right">
            <div className="email-template-create-right-container">
              <span className="header-title">{configJSON.textEdit}</span>
              <span className="header-title-2">{configJSON.textTitle} *</span>
              <TextField
                data-testid="email-title-input"
                size="small"
                variant="outlined"
                fullWidth
                className="title-input"
                disabled
                value={this.state.title}
                onChange={this.handleTitleChange}
                error={this.state.titleError}
                helperText={
                  this.state.titleError && configJSON.textThisFieldIsRequired
                }
              />
              <span className="header-title-2">
                {configJSON.textEmailSubject} *
              </span>
              <TextField
                data-testid="email-subject-input"
                size="small"
                variant="outlined"
                fullWidth
                className="title-input"
                value={this.state.emailSubject}
                onChange={this.handleEmailSubjectChange}
                error={this.state.emailSubjectError}
                helperText={
                  this.state.emailSubjectError &&
                  configJSON.textThisFieldIsRequired
                }
              />
              <Box className="content-container">
                <span className="header-title-2" style={{paddingRight: '3px'}}>{configJSON.textContent} *</span>
                <HtmlTooltip
                  placement="right"
                  title={<Typography color="inherit">{configJSON.contentMessage}</Typography>}
                >
                  <img src={InfoIcon} alt="infoIcon" height={18} width={18}/>
                </HtmlTooltip>
              </Box>
              <Editor
                value={this.state.content}
                onChange={this.handleEditorChange}
                onMaximize={this.handleMaximize}
                height="clamp(200px, calc(100vh - 550px), calc(100vh - 550px))"
                error={!!this.state.contentError}
              />
              {this.state.contentError && (
                <FormHelperText error={true}>
                  {configJSON.textThisFieldIsRequired}
                </FormHelperText>
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    border: "1px solid #dadde9",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    padding:"10px"
  },
}))(Tooltip);

const PreviewHeader = ({ logo }: any) => {
  return (
    <div className="preview-header">
        {logo?.url && (
            <img src={logo?.thumbnail_url} alt="image loading" />
        )}
    </div>
  );
};

const PreviewFooter = ({ social, commonSettings }: any) => {
  return (
    <div className="preview-footer">
      <div className="images">
        {social.facebook?.selected && (
          <IconButton onClick={() => {
            window.open(social.facebook.url)
          }}>
            <FacebookIcon />
          </IconButton>
        )}
        {social.instagram?.selected && (
          <IconButton onClick={() => {
            window.open(social.instagram.url)
          }}>
            <InstagramIcon />
          </IconButton>
        )}
        {social.twitter?.selected && (
          <IconButton onClick={() => {
            window.open(social.twitter.url)
          }}>
            <img src={twitter_logo} alt="twitter" />
          </IconButton>
        )}
        {social.youtube?.selected && (
          <IconButton onClick={() => {
            window.open(social.youtube.url)
          }}>
            <YouTubeIcon />
          </IconButton>
        )}
      </div>
      <div className="location">{commonSettings?.attributes?.address_line1 + ","} {commonSettings?.attributes?.address_line2 + ","} {commonSettings?.attributes?.city + ","} {commonSettings?.attributes?.state + ","} {commonSettings?.attributes?.country + ","} {commonSettings?.attributes?.pin_code + ","}</div>
    </div>
  );
};

interface TabProps {
  onTabChange?: (event: React.ChangeEvent<{}>, value: number) => void;
  value: number;
}

export const Tabs = ({ onTabChange, value }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {[
        [configJSON.textDesktop, DesktopIcon],
        [configJSON.textTablet, TabletIcon],
        [configJSON.textMobile, MobileIcon],
      ].map(([label, Icon], itemIndex) => (
        <MuiTab
          data-testid={`preview-device-${label}`}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
            wrapper: "tab-icon-wrapper",
          }}
          label={label}
          icon={
            <Icon
              className="tab-icon"
              color={value === itemIndex ? "#6200EA" : "#9B9FB1"}
            />
          }
        />
      ))}
    </MuiTabs>
  );
};
// Customizable Area End

// Customizable Area Start
export default withLayout(withLoader(
  withSnackBar(withHeaderBar(withDialog(EmailTemplatesCreate)))
));
// Customizable Area End
