// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Service } from "../../utilities/src/models/Service";
export const configJSONUrl = require("../../../framework/src/config");
import moment from "moment";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const navigation = require("react-navigation");
import { IBlock } from "../../../framework/src/IBlock";
import storageProvider  from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    classes: Record<string, string>;
    navigation: typeof navigation;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    orderData: any;
    isValidOrder: boolean;
    orderDatadata1:any;
    appointmentUpdate1:any,
    // Customizable Area End
}

export interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class ResheduleConfirmController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            orderData: '',
            orderDatadata1:"",
            appointmentUpdate1:"",
            isValidOrder: false
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const orderDatadata = JSON.parse(await storageProvider.get("bookingData"));
        const appointmentUpdate = JSON.parse(await storageProvider.get("appointmentUpdate"));
        this.setState({
            orderDatadata1:orderDatadata,
            appointmentUpdate1:appointmentUpdate, 
        })
        mixpanel.track(configJSON.appointmentRescheduleSuccess);
        // Customizable Area End
    }
    // Customizable Area Start
 
    momentChangeForAppointmentDate = (appointment_date: string, time_zone_short: string) => {
        return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])")
    }

    momentChangeForOrderData = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
    }

    handleBack = () => {
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"});
        },200)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"Home");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
    }
    // Customizable Area End
}
