// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import { withRouter } from "react-router-dom";
import firebase from 'firebase';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../blocks/utilities/src/theme';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';

import Appheader from '../../blocks/header/src/AppheaderWrapper';
import Appfooter from '../../blocks/footer/src/AppfooterWrapper';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import OrderManagement from '../../blocks/ordermanagement/src/OrderManagement';
import Itemavailability from '../../blocks/itemavailability/src/ItemAvailability';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ServicesWrapper from '../../blocks/catalogue/src/ServicesWrapper';
import ServiceWrapper from '../../blocks/catalogue/src/ServiceWrapper';
import AddContact from '../../blocks/contactus/src/AddContact';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import DashboardWrapper from '../../blocks/dashboard/src/DashboardWrapper';
import StripePayments from '../../blocks/stripepayments/src/StripePayments.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ContactSuccess from '../../blocks/contactus/src/ContactSuccess';
import Gallery from '../../blocks/photolibrary/src/Gallery.web';
import Appointments from '../../blocks/appointmentmanagement/src/Appointments.web';
import AppointmentReschedule from '../../blocks/appointmentmanagement/src/AppointmentReschedule.web'
import Confirmation from '../../blocks/appointmentmanagement/src/Confirmation.web';
import ConfirmationService from '../../blocks/appointmentmanagement/src/ConfirmationService.web' 
import StaticPages from '../../blocks/staticpages/src/StaticPages.web';
import StaffListingPage from '../../blocks/accountgroups/src/StaffListingPage.web';
import StaffDetails from '../../blocks/accountgroups/src/StaffDetails.web';
import Advancesearch from '../../blocks/accountgroups/src/Advancesearch.web'
import OrderStatus from '../../blocks/appointmentmanagement/src/OrderStatus.web';
import ResheduleConfirmStatus from '../../blocks/appointmentmanagement/src/ResheduleConfirmStatus.web'

const routeMap = {
  Admin: {
    component: require('../../blocks/ss-cms-common-router/src/WebRoutes').default,
    path: '/admin/*',
    exact: true
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement'
  },
  Itemavailability: {
    component: Itemavailability,
    path: '/Itemavailability'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Services: {
    component: ServicesWrapper,
    path: '/Services',
    exact: true
  },
  ServicesSearch: {
    component: ServicesWrapper,
    path: '/Services/:searchQuery',
    exact: true
  },
  Service: {
    component: ServiceWrapper,
    path: '/Service/:id',
    exact: true
  },
  AddContact: {
    component: AddContact,
    path: '/AddContact'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },

  Home: {
    component: DashboardWrapper,
    path: '/',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ContactSuccess: {
    component: ContactSuccess,
    path: '/ContactSuccess'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AppointmentReschedule: {
    component: AppointmentReschedule,
    path: "/AppointmentReschedule"
  },
  Confirmation: {
    component: Confirmation,
    path: '/Confirmation'
  },
  ConfirmationService: {
    component: ConfirmationService,
    path: '/ConfirmationService'
  },
  StaticPages: {
    component: StaticPages,
    path: '/StaticPages',
    exact: true
  },
  StaticPageDetail: {
    component: StaticPages,
    path: '/StaticPages/:id',
    exact: true
  },
  ServiceProviders: {
    component: StaffListingPage,
    path: '/ServiceProviders',
    exact: true
  },
  StaffSearch: {
    component: StaffListingPage,
    path: "/ServiceProviders/:searchQuery",
    exact: true
  },
  StaffDetails: {
    component: StaffDetails,
    path: '/StaffDetails/:id',
    exact: true
  },
  Advancesearch: {
    component: Advancesearch,
    path: '/Advancesearch/:searchQuery',
    exact: true
  },
  OrderStatus: {
    component: OrderStatus,
    path: '/OrderStatus',
    exact: true
  },
  
  ResheduleConfirmStatus: {
    component: ResheduleConfirmStatus,
    path: '/ResheduleConfirmStatus',
    exact: true
  },
};


const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});



class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        <ThemeProvider theme={theme}>
          {WebRoutesGenerator({ routeMap, Appheader, Appfooter, location: this.props.location })}
          <ModalContainer />
        </ThemeProvider>
      </View>
    );
  }
}

export default withRouter(App);