// Customizable Area Start
import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  InputAdornment,
  Slide,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Search as SearchIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useStyles } from "./styles/ServicesList.web";
import ServicesTable from "./ServicesTable.web";
import { Service } from "../../utilities/src/models/Service";
import Portal from "../../../components/src/Portal";
import DeleteModal from "../../utilities/src/components/DeleteModal.web";
import { ReactComponent as ErrorIcon } from "../assets/Error-cross-circle.svg";
import { ReactComponent as SuccessIcon } from "../assets/Success-tick-circle.svg";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";

const configJSON = require("./config");

export interface PaginationType {
  current_page: number;
  next_page: number;
  total_pages: number;
  total_count: number;
  current_count: number;
  per_page: number;
}

interface Props {
  servicesData: Service[];
  searchServices: (queryRef: RefObject<HTMLInputElement>) => void;
  deleteServices: (catalogueIds: string[]) => void;
  isLoading: boolean;
  deletionError: string;
  deletionSuccess: string;
  pagination?: PaginationType;
  handlePageChange: (page: number) => void;
}

const ServicesList: React.FC<Props> = ({
  servicesData,
  searchServices,
  deleteServices,
  isLoading,
  deletionError,
  deletionSuccess,
  pagination,
  handlePageChange,
}) => {
  const classes = useStyles();
  const queryRef = useRef<HTMLInputElement>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeletionApproved, setIsDeletionApproved] = useState(false);

  useEffect(() => {
    if (isDeletionApproved) {
      deleteServices(selectedRows);
      setSelectedRows([]);
      setIsDeletionApproved(false);
    }
  }, [isDeletionApproved]);

  const handleRowCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selectedServiceIds = servicesData.map((service) => service.id);
      setSelectedRows(selectedServiceIds);
    } else {
      setSelectedRows([]);
    }
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const confirmDeletion = () => {
    setIsDeletionApproved(true);
    closeModal();
  };

  return (
    <>
      <Slide direction="down" in={!!deletionError} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.notificationWrapper}>
          <Box className={classes.notification}>
            <CardMedia
              component="img"
              src={require("../assets/Builder Studio Store.png")}
              className={classes.logo}
            />

            <Box className={classes.notificationMessage}>
              {deletionError && (
                <>
                  <SvgIcon
                    component={ErrorIcon}
                    className={classes.notificationIcon}
                  />
                  <Typography className={classes.notificationText}>
                    <strong>Error! </strong>
                    {deletionError}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>

      <Slide direction="down" in={!!deletionSuccess} mountOnEnter unmountOnExit>
        <Box className={classes.notificationWrapper}>
          <Box className={classes.notification}>
            <CardMedia
              component="img"
              src={require("../assets/Builder Studio Store.png")}
              className={classes.logo}
            />

            <Box className={classes.notificationMessage}>
              {deletionSuccess && (
                <>
                  <SvgIcon
                    component={SuccessIcon}
                    className={classes.notificationIcon}
                  />
                  <Typography className={classes.notificationText}>
                    <strong>Success! </strong>
                    {deletionSuccess}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        {isLoading && (
          <Card className={classes.noServicesCard}>
            <Box className={classes.loadingResults}>
              <Typography className={classes.loadingResultsText}>
                {`Loading results...   `}
              </Typography>
              <span>
                <CircularProgress />
              </span>
            </Box>
          </Card>
        )}

        {(servicesData.length > 0 ||
          (queryRef.current && queryRef.current.value)) && (
          <Box className={classes.wrapper}>
            <Box className={classes.header}>
              <Box className={classes.leftHeaderWrapper}>
                <Typography className={classes.title}>
                  Booking services
                </Typography>

                {selectedRows.length > 0 && (
                  <Button
                    variant="text"
                    onClick={openModal}
                    className={classes.deleteButton}
                  >
                    Delete Services
                  </Button>
                )}
              </Box>

              <Link to="/ServicesManagement/ServiceManagement">
                <Button
                  variant="contained"
                  className={classes.addServiceButton}
                >
                  Add service
                </Button>
              </Link>
            </Box>

            <Typography component="div">
              <TextField
                inputRef={queryRef}
                variant="outlined"
                fullWidth
                placeholder="Search services"
                className={classes.searchBar}
                inputProps={{ className: classes.searchInput }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
                onChange={() => searchServices(queryRef)}
              />
            </Typography>

            {!isLoading && servicesData.length > 0 && (
              <>
              <ServicesTable
                services={servicesData}
                selectedRows={selectedRows}
                handleSelectAllClick={handleSelectAllClick}
                handleRowCheckboxClick={handleRowCheckboxClick}
              />
              {!!pagination?.total_count && <Box marginBottom={1.5}><Pagination
                    count={pagination?.total_count}
                    onChange={handlePageChange}
                    pageSize={pagination?.per_page}
                    itemText={configJSON.paginationText}
                    pageNo={pagination?.current_page || 1}
                /></Box>}
              </>
            )}

            {!isLoading && servicesData.length === 0 && (
              <Card className={classes.noServicesCard}>
                <CardMedia
                  component="img"
                  image={`${require("../assets/Not-found.png")}`}
                  className={classes.notFoundImage}
                />
                <Typography className={classes.noResultsFound}>
                  No results found
                </Typography>
                <Typography className={classes.pleaseRefineYourSearch}>
                  Please refine your search
                </Typography>
              </Card>
            )}
          </Box>
        )}

        {!isLoading &&
          (!queryRef.current ||
            (queryRef.current && !queryRef.current.value)) &&
          servicesData.length === 0 && (
            <Card className={classes.noServicesCard}>
              <Typography className={classes.title}>No services</Typography>

              <Typography className={classes.paragraph}>
                You have not added any services at the moment.
              </Typography>

              <Link to="/ServicesManagement/ServiceManagement">
                <Button className={classes.addServiceButtonNoServices}>
                  Add service
                </Button>
              </Link>
            </Card>
          )}
      </Box>

      {isModalOpened && (
        <Portal>
          <DeleteModal
            message="Are you sure you want to delete the selected services ?"
            closeModal={closeModal}
            confirmDeletion={confirmDeletion}
          />
        </Portal>
      )}
    </>
  );
};

export default ServicesList;
// Customizable Area End