// Customizable Area Start
interface CountryType {
  code: string;
  phone: string;
}

export const countries: CountryType[] = [
  { code: "AE", phone: "+971" },
  { code: "GB", phone: "+44" },
  { code: "IN", phone: "+91" },
  { code: "US", phone: "+1" },
];
// Customizable Area End