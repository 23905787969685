// Customizable Area Start
import React from "react";
import { Form, Formik } from "formik";
import {
  RadioInput,
  TextInput,
} from "../../ss-cms-common-components/src/Inputs/Inputs.web";
import _ from "lodash";
const configJSON = require("./config");
import {GenerateQrCodeDialogController, Schema} from "./GenerateQrCodeDialogController.web";
import { Dialog } from "../../ss-cms-common-components/src/Dialog/Dialog";
export class GenerateQrCodeDialog extends GenerateQrCodeDialogController {  

  render(){
    const { open,dialogOpen,onSubmit, closeDialog, initialValues, id} = this.props;
    return (
        <Dialog
            containerClassName="create-qr-code-dialog"
            title={id ? configJSON.editQrCode : configJSON.createQrCode}
            titleClassName="create-qr-dialog-title"
            customContent
            open={open}
            setOpen={dialogOpen}
            onSubmit={this.handleSubmitDialog}
            onClose={closeDialog}
            okay={id ? configJSON.qr_btn_update : configJSON.qr_btn_save}
            >
            <Formik
                innerRef={this.qrCodeRef}
                initialValues={initialValues}
                validationSchema={Schema}
                onSubmit={this.handleSubmit}
                enableReinitialize
            >
                {(formikProps) => {
                return (
                    <Form autoComplete="off" noValidate className="form" translate="">
                    <span className="app-type-title">{configJSON.appType}</span>
                    <RadioInput
                        {...formikProps}
                        name={configJSON.qr_labelName}
                        options={[
                        { label: configJSON.labelAndroid, value: configJSON.qr_radion1 },
                        { label: configJSON.labelIOs, value: configJSON.qr_radion2 },
                        ]}
                    />
                    <div className="mt-30">
                        <TextInput label={configJSON.qr_urlLabel} name={configJSON.qr_urlName} required {...formikProps} />
                    </div>
                    </Form>
                );
                }}
            </Formik>
            </Dialog>
    );
  }
};

export default GenerateQrCodeDialog;
// Customizable Area End
