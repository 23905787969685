// Customizable Area Start
import React, {
  useEffect,
  useRef,
  useState,
  RefObject,
  Suspense,
  ChangeEvent,
} from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  useMediaQuery,
  Box,
  Divider,
  InputAdornment,
  TextField,
  ListItem,
  List,
  IconButton,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import theme from "../../utilities/src/theme";
import { Link } from "react-router-dom";
import { Appheader as useStyles } from "./styles/Appheader.web";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { ServiceList, configJSON } from "./AppheaderController.web";
import { Skeleton } from "@material-ui/lab";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

interface Props {
  logo: ServiceImage | File | null;
  storeName: string;
  confirmSearch: (searchQuery: string) => void;
  searchQuery: string;
  searchServiceList: (queryRef: RefObject<HTMLInputElement>) => void;
  searchListSuggestionData: Array<ServiceList>;
  searchElementTitleFunction: (title: string, isService: boolean) => void;
  openClose: boolean;
  closeSearch: () => void;
  searchRef1: string;
  isServiceProvider: boolean;
  renameItem1: string;
  renameItem2: string;
  renameItem3: string;
  toogleModal: () => void;
  isModalOpen: boolean;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBookingIdChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  emailError: string;
  bookingIdError: string;
  loading: boolean;
}

const Appheader: React.FC<Props> = ({
  logo,
  storeName,
  confirmSearch,
  searchQuery,
  searchServiceList,
  searchListSuggestionData,
  searchElementTitleFunction,
  openClose,
  closeSearch,
  searchRef1,
  isServiceProvider,
  renameItem1,
  renameItem2,
  renameItem3,
  toogleModal,
  isModalOpen,
  handleEmailChange,
  handleBookingIdChange,
  handleSubmit,
  emailError,
  bookingIdError,
  loading,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearcBarOpen, setIsSearchBarOpen] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  interface DrawerItem {
    text: string;
    link: string;
    mixpanel: string;
  }

  const drawerItems: DrawerItem[] = isServiceProvider
    ? [
        {
          text: renameItem1 ? renameItem1 : "Services",
          link: "/Services",
          mixpanel: "webcustomer_navigation_services_button",
        },
        {
          text: renameItem2 ? renameItem2 : "Service Providers",
          link: "/ServiceProviders",
          mixpanel: "webcustomer_navigation_staffListing_button",
        },
        {
          text: renameItem3 ? renameItem3 : "Gallery",
          link: "/Gallery",
          mixpanel: "webcustomer_navigation_gallery_button",
        },
      ]
    : [
        {
          text: renameItem1 ? renameItem1 : "Services",
          link: "/Services",
          mixpanel: "webcustomer_navigation_services_button",
        },
        {
          text: renameItem3 ? renameItem3 : "Gallery",
          link: "/Gallery",
          mixpanel: "webcustomer_navigation_gallery_button",
        },
      ];

  const toggleDrawer = (itemClick: boolean = false) => {
    setIsDrawerOpen(!isDrawerOpen);
    if (itemClick && isModalOpen) {
      toogleModal();
    }
  };

  const handleMobileManageBookingClick = () => {
    mixpanel.track(configJSON.navigationManageBooking);
    setIsDrawerOpen(!isDrawerOpen);
    toogleModal();
  };

  const toggleSearchBar = () => {
    searchForCatalogue;
    setIsSearchBarOpen(!isSearcBarOpen);
  };

  const searchForCatalogue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef.current) {
      mixpanel.track("webcustomer_navigation_search");
      setIsSearchBarOpen(false);
      const searchQuery = searchRef.current.value;
      searchRef.current.value = "";
      searchQuery.trim() !== "" && confirmSearch(searchQuery);
    }
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, [searchQuery]);

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>

        {isSmallScreen && isSearcBarOpen ? (
          <>
            <Typography component="div" className={classes.searchbarWrapperDiv}>
              <Box>
                <TextField
                  style={{ position: "relative", zIndex: 9 }}
                  variant="outlined"
                  inputRef={searchRef}
                  placeholder="Search"
                  onKeyDown={searchForCatalogue}
                  inputProps={{ className: classes.searchBar }}
                  onChange={() => searchServiceList(searchRef)}
                  onKeyDownCapture={closeSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        classes={{ positionStart: classes.inputAdornment }}
                      >
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                    classes: { adornedStart: classes.searchBarIconAdornment },
                  }}
                />
                )
              </Box>

              {loading ? (
                <Skeleton
                  variant="rect"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "180px",
                  }}
                  animation="wave"
                />
              ) : (
                <>
                  <Box
                    className={
                      openClose
                        ? classes.closeBox
                        : classes.serarchListSuggestionHide
                    }
                    onClick={() => {
                      closeSearch();
                      toggleSearchBar();
                    }}
                  ></Box>

                  <Box
                    className={
                      openClose
                        ? classes.serarchListSuggestion
                        : classes.serarchListSuggestionHide
                    }
                  >
                    {searchListSuggestionData.map((element: ServiceList) => {
                      const isMatch = element.title
                        .toLowerCase()
                        .startsWith(searchRef1.toLowerCase());
                      return (
                        <>
                          <Typography
                            onClick={() =>
                              searchElementTitleFunction(
                                element.title,
                                element.is_service
                              )
                            }
                            className={classes.titleName}
                          >
                            <Box
                              className={
                                isMatch ? classes.boldText : classes.normalText
                              }
                            >
                              {isMatch ? (
                                <Box>
                                  <span className={classes.normalText}>
                                    {searchRef1}
                                  </span>
                                  <span className={classes.boldText}>
                                    {element.title.substring(searchRef1.length)}
                                  </span>
                                </Box>
                              ) : (
                                element.title
                              )}
                            </Box>
                          </Typography>
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
            </Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={toggleSearchBar}
            />
          </>
        ) : (
          <>
            <Box className={classes.leftSideContainer}>
              {isSmallScreen && (
                <IconButton
                  className={classes.menuIcon}
                  onClick={() => toggleDrawer()}
                >
                  <MenuIcon />
                </IconButton>
              )}

              {isExtraSmallScreen && isModalOpen ? (
                <Typography className={classes.modalHeader}>
                  {configJSON.manageBooking}
                </Typography>
              ) : (
              <Box className={classes.logoAppHeader}>
                <Link to="/" className={classes.homeLink}>
                  <Suspense fallback={<CircularProgress />}>
                    {logo && (logo as ServiceImage).url ? (
                      <img className={classes.logo} src={logo && (logo as ServiceImage).url} height={"50px"} alt="image loading" />
                    ) : (
                      <Skeleton variant="circle" width={40} height={50} />
                    )}
                  </Suspense>
                  
                    <Typography className={classes.title}>
                      {storeName}
                    </Typography>
                </Link>
              </Box>
              )}

              {!isSmallScreen && (
                <Box className={classes.navigationItems}>
                  {drawerItems.map((item, index) => (
                    <Link
                      key={index}
                      to={item.link}
                      className={classes.link}
                      onClick={() => mixpanel.track(item.mixpanel)}
                    >
                      <Typography className={classes.linkText}>
                        {item.text}
                      </Typography>
                    </Link>
                  ))}
                </Box>
              )}
            </Box>

            <Box className={classes.RightSideContainer}>
              {isSmallScreen ? (
                <SearchIcon
                  className={classes.searchIcon}
                  onClick={toggleSearchBar}
                />
              ) : (
                <>
                  <Typography
                    component="div"
                    className={classes.searchbarWrapperDiv}
                  >
                    <Box>
                      <TextField
                        style={{ position: "relative", zIndex: 9 }}
                        variant="outlined"
                        inputRef={searchRef}
                        placeholder="Search"
                        onKeyDown={searchForCatalogue}
                        inputProps={{ className: classes.searchBar }}
                        onChange={() => searchServiceList(searchRef)}
                        onKeyDownCapture={closeSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              classes={{
                                positionStart: classes.inputAdornment,
                              }}
                            >
                              <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                          ),
                          classes: {
                            adornedStart: classes.searchBarIconAdornment,
                          },
                        }}
                      />
                    </Box>

                        <Box
                          className={
                            openClose
                              ? classes.closeBox
                              : classes.serarchListSuggestionHide
                          }
                          onClick={closeSearch}
                        ></Box>
                        <Box
                          className={
                            openClose
                              ? classes.serarchListSuggestion
                              : classes.serarchListSuggestionHide
                          }
                        >
                          {searchListSuggestionData.map(
                            (element: ServiceList) => {
                              const isMatch = element.title
                                ?.toLowerCase()
                                .startsWith(searchRef1.toLowerCase());
                              return (
                                <Typography
                                  key={element.id}
                                  onClick={() =>
                                    searchElementTitleFunction(
                                      element.title,
                                      element.is_service
                                    )
                                  }
                                  className={classes.titleName}
                                >
                                  <Box
                                    className={
                                      isMatch
                                        ? classes.boldText
                                        : classes.normalText
                                    }
                                  >
                                    {isMatch ? (
                                      <Box>
                                        <span className={classes.normalText}>
                                          {searchRef1}
                                        </span>
                                        <span className={classes.boldText}>
                                          {element.title.substring(
                                            searchRef1.length
                                          )}
                                        </span>
                                      </Box>
                                    ) : (
                                      element.title
                                    )}
                                  </Box>
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                     
                  </Typography>
                  <Box
                    className={classes.link}
                    onClick={toogleModal}
                    data-test-id="manageBookingBtn"
                  >
                    <Typography className={classes.bookNowButton}>
                    {configJSON.manageBooking}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Toolbar>

      {isSmallScreen && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => toggleDrawer()}
          PaperProps={{ className: classes.drawer }}
        >
          <CloseIcon
            className={classes.drawerCloseButton}
            onClick={() => toggleDrawer()}
          />

          <List>
            {drawerItems.map((item, index) => (
              <Box key={index}>
                <ListItem onClick={() => toggleDrawer(true)}>
                  <Link
                    to={item.link}
                    className={classes.link}
                    onClick={() => mixpanel.track(item.mixpanel)}
                  >
                    <Typography className={classes.linkText}>
                      {item.text}
                    </Typography>
                  </Link>
                </ListItem>
                <Divider className={classes.divider} />
              </Box>
            ))}

            <ListItem>
              <Box className={classes.link}>
                <Typography
                  onClick={handleMobileManageBookingClick}
                  className={classes.bookNowButton}
                >
                  {configJSON.manageBooking}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Drawer>
      )}
      <Modal
        open={isModalOpen}
        onClose={toogleModal}
        className={classes.manageBookingModal}
        hideBackdrop={isExtraSmallScreen}
      >
        <Box className={classes.manageBookingModalContainer}>
          {!isExtraSmallScreen && (
            <Typography className={classes.modalHeader}>
              <span data-test-id="manageBookingModalHeader">
                {configJSON.manageBooking}
              </span>
              <img
                src={require("../assets/cross.svg")}
                alt="closeIcon"
                className={classes.crossIcon}
                onClick={toogleModal}
                width={"14px"}
                height={"14px"}
              />
            </Typography>
          )}
          <Box className={classes.subContainer}>
            <Typography className={classes.modalSubHeader}>
              Enter your Email ID and Booking ID to search for your booking.
            </Typography>
            <Typography className={classes.inputlabel}>Email ID*</Typography>
            <TextField
              variant="outlined"
              placeholder="Email"
              fullWidth
              className={classes.inputField}
              name="email"
              onChange={handleEmailChange}
              error={emailError !== ""}
              helperText={emailError}
              data-test-id="emailInput"
              onFocus={() => window.scrollTo({top:0, behavior:"instant" as any})}
              onBlur={() => window.scrollTo({top:0, behavior:"instant" as any})}
            />
            <Typography className={classes.inputlabel}>Booking ID*</Typography>
            <TextField
              variant="outlined"
              placeholder="Booking ID"
              fullWidth
              className={classes.inputField}
              name="bookingId"
              onChange={handleBookingIdChange}
              error={bookingIdError !== ""}
              helperText={bookingIdError}
              data-test-id="bookingIdInput"
              onFocus={() => window.scrollTo({top:0, behavior:"instant" as any})}
              onBlur={() => window.scrollTo({top:0, behavior:"instant" as any})}
            />
            <Box
              className={classes.link}
              onClick={handleSubmit}
              data-test-id="searchBookingBtn"
            >
              <Typography className={classes.searchBookingButton}>
                Search Booking
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Appheader;
// Customizable Area End
