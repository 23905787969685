// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config");
import _ from "lodash";
import { FormikProps } from "formik";

export interface reqInitialType {
  app_name:                    string;
  short_description:           string;
  description:                 string;
  tags:                        Array<string>;
  website:                     string;
  email:                       string;
  phone:                       string;
  first_name:                  string;
  last_name:                   string;
  country_name:                string;
  state:                       string | undefined;
  city:                        string | undefined;
  postal_code:                 string;
  address:                     string;
  privacy_policy_url:          string;
  support_url:                 string;
  marketing_url:               string;
  terms_and_conditions_url:    string;
  target_audience_and_content: string;
  is_paid:                     boolean;
  default_price:               number;
  distributed_countries:       string;
  auto_price_conversion:       boolean;
  android_wear:                boolean;
  google_play_for_education:   boolean;
  us_export_laws:              boolean;
  copyright:                   string;
  app_icon:                    string | undefined;
  common_feature_banner:       string | undefined;
  feature_graphic:             string;
  app_categories_attributes?:  Array<AppCategoryTypePayload>;
  app_categories?:             Array<AppCategoryTypePayload>
}

interface AppCategoryTypePayload {
  id?: number,
  _destroy?: string,
  destroy?: boolean | undefined,
  app_type: string,
  app_category?: string,
  feature_graphic?: string,
  product_title: string,
  review_notes: string,
  review_password: string,
  review_username: string,
  attachments_attributes: Array<attachmentTypesNew>,
  default_attachments_attributes: Array<attachmentTypes> | undefined,
  attachments_attributes_raw: Array<attachmentTypes> | undefined,
  screenshots?: {
    data: Array<attachmentTypes>
  }
}
interface attachmentTypesNew {
  id: string,
  image: string
}

interface attachmentTypes {
  id: string,
  type?: string,
  attributes?: {
    app_category_id: number,
    id: number,
    is_default: boolean | null,
    is_present: boolean,
    position: number | null,
    url: string,
    url_id: null
    url_link: null
    url_type: null
  }
}

export type Props = {
  isDestroyed?: boolean;
  itemIndex: number;
  showHeaderBar: () => void;
  formikProps: FormikProps<reqInitialType>
};

type AppCategoryInputTypes = {
  app_type: string,
}
type Option = {
    label: string;
    value: string;
    id?: number | string;
  };

interface S {
  isDestroid: boolean;
  getCheckBoxLabel: string;
  appType: Array<Option>
}

interface SS {
}

class AppCategoryInputController extends BlockComponent<Props, S, SS> {
  getWebJsonMessageId: string = "";
  getMobileJsonMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
        isDestroid: false,
        getCheckBoxLabel: configJSON.remove,
        appType: [
            { label: configJSON.labelAndroid, value: "android" },
            { label: configJSON.labelIOs, value: "ios" },
        ]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate (prevProps: { formikProps: FormikProps<reqInitialType> })  {
        const {itemIndex, formikProps} = this.props;
        if(prevProps.formikProps !== formikProps){
            const idName = `app_categories_attributes[${itemIndex}].id`
            const id = _.get(formikProps.values, idName);
            if(id){
                this.setState({
                    getCheckBoxLabel: configJSON.deleteAppType
                });
            }
        }
  }
  handleDestroy = () => {
    const {itemIndex, formikProps} = this.props;
    const idName = `app_categories_attributes[${itemIndex}].id`
    const id = _.get(formikProps.values, idName);
    if (!id) {
        formikProps.setFieldValue(
          `app_categories_attributes[${itemIndex}]`,
          undefined
        );
    }
    this.setState({
        isDestroid: !this.state.isDestroid
    });
  }

}

export default AppCategoryInputController;
// Customizable Area End