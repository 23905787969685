Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.routeAdminStaticPage = "AdminStaticPage";
exports.routeLogin = "EmailAccountLogin"
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.staticPageDataApiMethodType = 'GET';
exports.staticPageDataApiURL = 'bx_block_static_pages/static_pages';
exports.staticPageBulkDeleteApiMethodType = 'DELETE';
exports.staticPageBulkDeleteApiURL = 'bx_block_static_pages/static_pages';
exports.FAQStaticPageDataApiMethodType = 'GET';
exports.FAQStaticPageDataApiURL = 'bx_block_static_pages/static_pages';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'adminconsole';
exports.labelBodyText = 'adminconsole Body';

exports.getStaticPageApiEndpoint = 'bx_block_static_pages/static_pages';
exports.getStaticPageApiMethodType = 'GET';
exports.getFAQStaticPageApiEndpoint = 'bx_block_static_pages/static_pages';
exports.getFAQStaticPageApiMethodType = 'GET';
exports.createStaticPageApiEndpoint = 'bx_block_static_pages/static_pages';
exports.createStaticPageApiMethodType = 'POST';
exports.createStaticPageFAQApiEndpoint = 'bx_block_static_pages/static_pages';
exports.createStaticPageFAQApiMethodType = 'POST';
exports.updateStaticPageApiEndpoint = 'bx_block_static_pages/static_pages';
exports.updateStaticPageApiMethodType = 'PATCH';
exports.updateStaticPageFAQApiEndpoint = 'bx_block_static_pages/static_pages';
exports.updateStaticPageFAQApiMethodType = 'PATCH';
exports.deleteFAQApiEndpoint = '/admin/v1/interactive_faqs';
exports.deleteFAQApiMethodType = 'DELETE';

exports.generalErrorMessage = 'Something went wrong';
exports.btnExampleTitle = 'Something went wrong';
exports.addStaticPageURL = '/website-and-emails/create-static-pages';
exports.createStaticPageURL = '/website-and-emails/create-static-pages/';
exports.usersEndpoint = 'account_block/accounts';
exports.permissionsEndpoint = 'bx_block_roles_permissions/roles';
exports.staticPageTypePlaceholder = 'Select a type';
exports.packageEndpoint = 'bx_block_contentmanagement/package_managements';

exports.backButton = 'Back';
exports.question = 'Question';
exports.answer = 'Answer';
exports.live = 'Live';
exports.notLive = 'Not Live';
exports.addAnotherQuestionAnswer = 'Add another question/answer';

exports.staticPage = 'Static page';
exports.staticPageType = 'Static page type';
exports.title = 'Title';
exports.description = 'Description';
exports.Status = 'Status';
exports.published = 'Published';
exports.notPublished = 'Not published';
exports.textCreateSuccessfully = 'Your static page created successfully';
exports.textEditSuccessfully = 'Your static page updated successfully';
exports.staticPageTableURL = '/website-and-emails/static-pages-table';
exports.thisFieldIsRequired = 'This field is required.';
exports.questionCanNotBeMoreThan_100Characters =
  'Question can not be more than 100 characters.';
exports.answerCanNotBeMoreThan_500Characters =
  'Answer can not be more than 500 characters.';
exports.titleCanNotBeMoreThan_20Characters =
  'Title can not be more than 20 characters.';
exports.titleCanNotBeLessThan_1Characters =
  'Title can not be less than 1 characters.';

exports.staticPageTableTitles = ['Type', 'Title', 'Description', 'Published'];
exports.areYouSure = 'Are you sure?';
exports.areYouSureYouWantToDeleteThisStaticPages =
  'Are you sure you want to delete this static pages.';
exports.staticPages = 'Static pages';
exports.deleteStaticPages = 'Delete Static Pages';
exports.bulkDeleteErrorMessage = "You can't delete faq page";
exports.youHaveNoStaticPages = 'You have no static pages';

exports.emptyPageMessage = `Add essential static pages such as About Us, FAQs, Privacy Policy,\n
 and Terms of Service to your site`;
exports.createNewStaticPage = 'Create new static page';
exports.textFAQDeletedSuccessfully = "Your FAQ's deleted successfully."
exports.textPageWillNotVisible = "This page is not visible on your store"
exports.textPageWillBeVisible = "This page is visible on your store"
exports.textEditStaticPage = "Edit static page"
exports.textCreateStaticPage = "Create static page"
exports.textConfirmDelete = "Yes, delete"
exports.textStaticPageDeletedSuccessfully = "Your static page deleted successfully."

exports.Skills = [
  { title: 'About us', type: 'about_us', page_no: 1 },
  { title: 'FAQs', type: 'other', page_no: 2 },
  { title: 'Terms of service', type: 'terms_of_service', page_no: 3 },
  { title: 'Privacy policy', type: 'privacy_policy', page_no: 4 }
];
exports.getStaticPageTitleEndPoint = "bx_block_static_pages/static_pages/page_types"

// Customizable Area End