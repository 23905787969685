import { values } from "lodash";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  handleInputChangeClick: (value: string) => void;
  value?: ValueType;
  isDisabled: boolean;
  isError: boolean;
  isHelperText: string;
  dataTestId: string;
  skills: Array<{name:string}>;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  optionValue: ValueType;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
type ValueType = {
  id: number;
  name: string;
};
// Customizable Area End
export default class CustomAutoCompletePickerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      optionValue: this.props.value || {} as ValueType,
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (nextProps.value !== this.props.value) {
      this.setState({ optionValue: nextProps.value || {} as ValueType });
    }
  }

// Customizable Area Start
  handleOptionChange = (object: ValueType) => {
    this.setState({ optionValue: object });
    this.props.handleInputChangeClick(object?.name);
  };
// Customizable Area End
}
