// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const add_plus = require("../assets/add_plus.svg");
export const image110 = require("../assets/image110.svg");
export const pencil = require("../assets/pencil.svg");
export const builder = require("../assets/builder.svg");
export const arrow = require("../assets/arrow.svg");
export const tick = require("../assets/tick.svg");
export const Error = require("../assets/Error.svg");
export const Success = require("../assets/Success.svg");
// Customizable Area End