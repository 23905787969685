import React, { Component } from "react";
import parser from "html-react-parser";
import { CKEditor as CKEditorV4, CKEditorEventHandler } from "ckeditor4-react";
import "./styles.css";

export type onMaximizeType = CKEditorEventHandler<"maximize">;

interface Props {
  value?: string;
  onChange: (newValue: string, plainText?: string) => void;
  onMaximize?: CKEditorEventHandler<"maximize">;
  height?: string | number;
  error?: boolean;
}

export default class Editor extends Component<Props> {
  handleChange: CKEditorEventHandler<"change"> = (e) => {
    this.props.onChange(
      e.editor.getData().replace(/[\r\n\t]/gm, ""),
      e.editor.document.getBody().getText()
    );
  };
  render() {
    return (
      <div className="ck-editor-wrapper">
        {this.props.value != null ? (
          <CKEditorV4
            config={{
              allowedContent: true,
              height: this.props.height || "auto",
              fullPage: true,
              resize_enabled: false,
              removeButtons: "PasteText,PasteFromWord,Paste",
            }}
            style={{
              borderColor: this.props.error ? "red" : undefined,
            }}
            initData={this.props.value}
            onChange={this.handleChange}
            readOnly={false}
            type="classic"
            onMaximize={this.props.onMaximize}
            onPaste={(...args) => { }}
          />
        ) : null}
      </div>
    );
  }
}

interface PreviewProps {
  value?: string;
  className?: string;
  [key: string]: any;
}

export const Preview = ({ value, className = "", ...props }: PreviewProps) => {
  return (
    <div className={"wysiwyg-editor-preview " + className} {...props}>
      {parser(value || "")}
    </div>
  );
};
