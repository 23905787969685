Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
// Customizable Area End

//Login API's
exports.loginAPiEndPoint = "/bx_block_login/login";
exports.forgotAPiEndPoint = "/bx_block_forgot_password/forgot_password";
exports.loginAPiMethod = "POST";
// Customizable Area Start
exports.forgotApiWithOtpEndPoint = "/bx_block_forgot_password/forgot_password/otp_validate";
exports.resetPasswordApiEndPoint = "/bx_block_forgot_password/forgot_password/reset_password"
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.textEmailMustValid = "Email must be in valid format."
exports.textThisFieldIsRequired = "This field is required."
exports.textBackToSignIn = "Back to sign in";
exports.textForgottenPassword = "Forgotten password?";
exports.textEmail = "Email"
exports.textEnterOtpFromEmail = "Enter OTP from email";
exports.textEnterOtp = "Enter OTP";
exports.textResetPassword = "Reset password";
exports.textEnterPassword = "Please enter a password"
exports.textPasswordsMustMatch = "Passwords must match"
exports.textPasswordValidation = "Must contain 8 characters including 1 uppercase letter, 1 digit and 1 symbol"
exports.textBackToSignIn = "Back to sign in"
exports.textChangePassword = "Change password"
exports.textPassword = "Password"
exports.textUpdatePassword = "Update password"
exports.textConfirmPassword = "Confirm password"
exports.textResendOtp = "Resend OTP"
exports.textErrorResetPassword = "Error in reset password"
exports.textSuccessResetPassword = "Password successfully changed. You will be redirected to login page"

exports.htmlRequestContentType = "application/json"
exports.htmlMethodPUT = "PUT"
// Customizable Area End
