// Customizable Area Start
import React from "react";
import MobileAppController from "./MobileAppController.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import "../assets/styles.css";
import { Scrollbars } from "react-custom-scrollbars";
import AppSettingsForm from "./AppSettingsForm.web";
import AppRequirementForm from "./AppRequirementForm.web";
import QrCode, {GenerateQrCodeButton} from "./QrCode.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

const configJSON = require("./config");

export class MobileApp extends MobileAppController {
  render() {
    const { selectedTab } = this.state;

    

    const renderTabs = () => {
      if (selectedTab === 1) {
        return (
          <AppRequirementForm />
        );
      } else if (selectedTab === 2) {
        return (
          <QrCode isDialogOpen={this.state.showQrCodeDialog} changeDialogState={this.changeDialogState} />
        );
      } else {
        return <AppSettingsForm />;
      }
    };

    return (
      <Scrollbars>
        <div className="mobile-app">
          <Header
            onClick={() => this.changeDialogState(true)}
            showQrCodeButton={selectedTab === 2}
            disabled={false}
          >
            <div className="tabs-wrapper">
                <MuiTabs
                  classes={{
                    root: "tabs-root",
                    indicator: "tabs-indicator",
                    flexContainer: "tabs-flex-container",
                  }}
                  data-testid="mobile-app-tab"
                  value={selectedTab}
                  onChange={this.handleTabChange}
                >
                  {configJSON.headerTabNames.map((tabItem:{tab_name: string}, index: number) => (
                    <MuiTab
                      data-testid={"mobile-app-tab-" + index}
                      key={tabItem.tab_name}
                      classes={{
                        root: "tabs-root",
                        labelIcon: "tab-label-icon",
                        selected: "tab-label-selected",
                      }}
                      label={tabItem.tab_name}
                    />
                  ))}
                </MuiTabs>
            </div>
          </Header>
          <div className="content">
            <span className="mobile-app-tab-hint">{this.getTabContent()}</span>
            {renderTabs()}
          </div>
          
        </div>
      </Scrollbars>
    );
  }
}


interface HeaderProps {
  children?: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showQrCodeButton?: boolean;
  disabled?: boolean;
}

export const Header = ({
  children,
  onClick,
  showQrCodeButton = false,
  disabled
}: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">{configJSON.mobileApp}</span>
        <div className="header-buttons">
          {showQrCodeButton && <GenerateQrCodeButton onClick={onClick} disabled={disabled} />}
        </div>
      </div>
      {children}
    </div>
  );
};


export default withLayout(withDialog(withHeaderActionBar(withLoader(MobileApp))));
// Customizable Area End