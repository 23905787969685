// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import {
  generateRequestMessage,
  checkResponseError,
  showError,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { Tab, EmailSettingsMap } from "./Types";
import StorageProvider  from "../../../framework/src/StorageProvider.web";
 
const configJSON = require("./config.js");
// Customizable Area End

export type Props = 
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  selectedTab: number;
  headerTabs: Tab[];
  emailSettings: EmailSettingsMap;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  settingsMessageId: string = "";
  settingsUpdates: string[] = [];
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedTab: 0,
      headerTabs: [],
      emailSettings: {},
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    // Customizable Area Start
    // Customizable Area End
  }

  initPage = async () => {
    this.props.displaySpinner();
    const requestMessage = await generateRequestMessage(
      configJSON.emailSettingsEndpoint,
      configJSON.httpGetMethod
    );
    this.settingsMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.settingsMessageId === apiRequestCallId) {
        if (checkResponseError(responseJson)) {
          return showError(
            configJSON.textErrorOnPageInitialization,
            responseJson,
            this.props.hideLoader,
            this.props.setDialogState
          );
        }
        const allEmailSettings = (responseJson.data as Tab[])
          .map((setting) =>
            setting.categories.map((category) => category.email_settings)
          )
          .flat(2);
        const emailSettings: EmailSettingsMap = {};
        allEmailSettings.forEach((setting) => {
          emailSettings[setting.id] = setting;
        });

        this.setState({
          headerTabs: responseJson.data,
          emailSettings,
        });
        this.props.hideLoader();
      }
      if (this.settingsUpdates.includes(apiRequestCallId)) {
        const updateIndex = this.settingsUpdates.indexOf(apiRequestCallId);
        this.settingsUpdates.splice(updateIndex, 1);
        this.setState((prevState) => ({
          emailSettings: {
            ...prevState.emailSettings,
            [Number(responseJson.data.id)]: {
              id: Number(responseJson.data.id),
              ...responseJson.data.attributes,
            },
          },
        }));
        this.props.hideLoader();
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };

  handleEditClick = (itemId: number) => {
    StorageProvider.set('activeTab',this.state.selectedTab)
    this.props.navigation.navigate("AdminEmailTemplatesCreate", { id: itemId });
  };

  handleActiveChange = async (itemId: number, newState: boolean) => {
    this.props.displaySpinner();
    const requestMessage = await generateRequestMessage(
      `${configJSON.emailSettingsEndpoint}/${itemId}`,
      configJSON.httpPatchMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ active: newState })
    );
    this.settingsUpdates.push(requestMessage.messageId);
    this.send(requestMessage);
  };
  // Customizable Area End
}
