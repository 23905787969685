// Customizable Area Start
import React, { Suspense } from "react";
import StaffListingPageController, { Props } from "./StaffListingPageController";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    List,
    ListItem,
    Theme,
    Typography,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { NavigateNext as NavigateNextIcon, Close as CloseIcon } from "@material-ui/icons";
import { IconNotFound, IconFilter } from "../../catalogue/src/assets";
import { StaffDummyImage } from "./assets";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
const configJSON = require("./config");
const FilterItems = React.lazy(() => import('../../catalogue/src/FilterItems.web'));
const FilterOptions = React.lazy(() => import('../../catalogue/src/FilterOptions.web'));
const Pagination = React.lazy(() => import('../../ss-cms-common-components/src/Pagination/Pagination'));
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
// Customizable Area End

export class StaffListingPage extends StaffListingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBreadcrumbs = () => {
        const style = this.props.classes;
        const { screenSize } = this.state;
        return (
            <>
                <Box className={style.breadcrumbsWrapper}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ color: "black" }} />}>
                        <Box className={style.breadcrumbLink} onClick={this.goBack}>
                            <Typography>{configJSON.textHome}</Typography>
                        </Box>
                        <Typography className={style.breadcrumbText}>
                            {this.state.renameItem2 ? this.state.renameItem2 : "Service Provider"}
                        </Typography>
                    </Breadcrumbs>

                    {screenSize < 1280 && (
                        <Box
                            className={style.filterButton}
                            onClick={this.toggleFilterDrawer}
                        >
                            <img
                                src={IconFilter}
                                className={style.filterIcon}
                                alt="filter image"
                            />
                            <Typography className={style.filterButtonText}>
                                {configJSON.textFilter}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </>
        )
    };

    renderPagination = () => {
        const { pagination } = this.state;
        const style = this.props.classes;
        return (
            <>
                {!!pagination?.total_count && (
                    <Box marginBottom={1.5}>
                        <Suspense fallback={<CircularProgress />}>
                            <Pagination
                                count={pagination?.total_count}
                                onChange={this.handlePageChange}
                                pageSize={pagination?.per_page}
                                itemText={this.state.renameItem2 ? this.state.renameItem2 : "Service Provider"}
                                pageNo={pagination?.current_page || 1}
                            />
                        </Suspense>
                    </Box>
                )}
            </>
        )
    };

    renderFilter = () => {
        const {
            categories,
            loading,
            tempSelectedCategories,
            screenSize
        } = this.state;

        const style = this.props.classes;
        return (
            <>
                {screenSize > 1280 && (
                    <Box className={style.filter}>
                        {!loading ?
                            <Suspense fallback={<CircularProgress />}>
                                <FilterOptions
                                    options={categories}
                                    tempSelectedCategories={tempSelectedCategories}
                                    selectCategory={this.selectCategory}
                                />
                            </Suspense>
                            :
                            <Box style={{ flexDirection: "row", width: "100%" }}>
                                <Skeleton width={"100%"} />
                                <Skeleton width={"90%"} />
                                <Skeleton width={"80%"} />
                                <Skeleton width={"70%"} />
                            </Box>
                        }
                    </Box>
                )}
            </>
        )
    };

    renderClip = () => {
        const { searchQuery } = this.state;
        const style = this.props.classes;
        return (
            <>
                {searchQuery && (
                    <Chip
                        label={searchQuery}
                        variant="outlined"
                        className={style.chip}
                        onDelete={this.deleteChip}
                        deleteIcon={<CloseIcon className={style.deleteChipIcon} />}
                    />
                )}
            </>
        )
    };

    renderSkeleton = () => {
        const { loading } = this.state;
        const style = this.props.classes;
        const skeletonData = [1,2,3,4,5,6];
        return (
            <>
                {loading && (
                    <Box className={style.loaderContainer}>
                        {skeletonData.map((item) => {
                            return (
                                <>
                                    <Box className={style.skeleton}>
                                        <Skeleton variant="rect" width={424} height={352} />
                                        <Skeleton />
                                        <Skeleton width={"60%"} />
                                    </Box>
                                </>
                            )
                        })}
                    </Box>
                )}
            </>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            isFilterDrawerOpen,
            categories,
            staffList,
            loading,
            tempSelectedCategories,
        } = this.state;

        const style = this.props.classes;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {this.renderBreadcrumbs()}

                <Suspense fallback={<CircularProgress/>}>
                    <FilterItems
                        options={categories}
                        isDrawerOpen={isFilterDrawerOpen}
                        toggleDrawer={this.toggleFilterDrawer}
                        tempSelectedCategories={tempSelectedCategories}
                        resetCategoriesSelected={this.resetCategoriesSelected}
                        applyCategoriesSelected={this.applyCategoriesSelected}
                        discardCategoriesSelected={this.discardCategoriesSelected}
                        selectCategory={this.selectCategory}
                    />
                </Suspense>
                <Box className={style.container}>
                    {this.renderFilter()}

                    <Box className={style.resultsWrapper}>

                        {this.renderClip()}

                        {this.renderSkeleton()}

                        {!loading && staffList.length > 0 && (
                            <Box className={style.listWrapper}>
                                <List className={style.list}>
                                    {staffList.map((staffList, index) => (
                                        <ListItem key={index} className={style.item}>
                                            <Link
                                                className={style.link}
                                                to={`/StaffDetails/${staffList.id}`}
                                                onClick={() => this.navigateStaffDetailPage(staffList.id)}
                                            >
                                                <Card className={style.card}>
                                                    {staffList ?
                                                    <Suspense fallback={<CircularProgress/>}>
                                                    <ImageCard
                                                        imageClassName={style.image}
                                                        image={staffList?.attributes?.images[0]?.small_url ? staffList?.attributes?.images[0]?.small_url : (staffList?.attributes?.images[0]?.url ? staffList?.attributes?.images[0]?.url : StaffDummyImage)}
                                                        alt="image loading"
                                                     />
                                                    </Suspense>
                                                        :
                                                        <Skeleton variant="rect" width={210} height={118} />
                                                    }
                                                    <CardContent className={style.content}>
                                                        {staffList ?
                                                            <Typography className={style.name}>
                                                                {staffList.attributes.full_name ? staffList.attributes.full_name : ""}
                                                            </Typography>
                                                            : <Skeleton />
                                                        }
                                                        {
                                                            staffList ?
                                                                <Typography className={style.brief}>
                                                                    {staffList.attributes.designation ? staffList.attributes.designation : ""}
                                                                </Typography>
                                                                :
                                                                <Skeleton width={"60%"} />
                                                        }
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                            <Button disableRipple onClick={() => this.handleBookNow(staffList.id)} className={style.bookNowButton}>{configJSON.textBookNow}</Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}

                        {this.renderPagination()}

                        {!loading && staffList.length === 0 && (
                            <Card className={style.noServicesCard}>
                                <img
                                    src={IconNotFound}
                                    alt="error image"
                                    width={"103px"}
                                />
                                <Typography className={style.noResultsFound}>
                                    {configJSON.textNoResultsFound}
                                </Typography>
                                <Typography className={style.pleaseRefineYourSearch}>
                                    {configJSON.textNoResultHint}
                                </Typography>
                            </Card>
                        )}
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Styles = (theme: Theme) =>
    createStyles({
        breadcrumbsWrapper: {
            margin: theme.spacing(5, 10, 1),
            [theme.breakpoints.down(1280)]: {
                margin: theme.spacing(2, 2.25, 1),
                display: "flex",
                justifyContent: "space-between",
            },
        },
        breadcrumbLink: {
            color: "#3FC1CB",
            textDecoration: "none",
            cursor: "pointer",
        },
        breadcrumbText: {
            color: "#000000",
        },
        container: {
            display: "flex",
            gap: theme.spacing(3.5),
            margin: theme.spacing(0, 10),
            [theme.breakpoints.down(1280)]: {
                margin: theme.spacing(0),
            },
        },
        filterButton: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
        },
        filterIcon: {
            objectFit: "none",
        },
        filterButtonText: {
            fontSize: "12px",
            color: "#9B9FB1",
        },
        filter: {
            display: "flex",
            flex: "1 1 25%",
            height: "fit-content",
            paddingTop: theme.spacing(2),
            marginBottom: '10px'
        },
        noServicesCard: {
            flex: "1 1 75%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: theme.spacing(2),
            padding: theme.spacing(12.5),
            height: "394px",
            marginBottom: '10px'
        },
        loadingResults: {
            display: "flex",
            alignItems: "center",
        },
        loadingResultsText: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "pre",
        },
        notFoundImage: {
            width: "103px",
        },
        noResultsFound: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            marginTop: theme.spacing(5),
        },
        pleaseRefineYourSearch: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#83889E",
            marginTop: theme.spacing(1),
        },
        resultsWrapper: {
            display: "flex",
            flexDirection: "column",
            flex: "1 1 75%",
            margin:'0px 10px'
        },
        chip: {
            alignSelf: "flex-start",
            minHeight: "32px",
            marginTop: theme.spacing(2),
            backgroundColor: "#FFFFFF",
            border: "1px solid #D0D2DA",
            fontSize: "14px",
            lineHeight: "24px",
            [theme.breakpoints.down(1280)]: {
                marginTop: theme.spacing(0),
                marginLeft: theme.spacing(1),
            },
        },
        deleteChipIcon: {
            width: "10px",
            height: "10px",
            color: "#D0D2DA",
            marginRight: "10px !important",
            marginLeft: theme.spacing(0),
        },
        listWrapper: {
            display: "flex",
            flex: "1 1 75%",
            justifyContent: "center",
        },
        list: {
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            width: "100%",
            [theme.breakpoints.up(1440)]: {
                maxWidth: "1330px",
            },
        },
        item: {
            width: "calc(100% / 3)",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("xs")]: {
                width: "calc(100% / 2)",
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.down(1280)]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        link: {
            textDecoration: "none",
            width: "100%",
            display: "flex",
        },
        card: {
            display: "flex",
            flexDirection: "column",
            width: "424px",
            height: "352px",
            flex: "1",
            boxShadow: "0 0",
            background: "inherit",
            [theme.breakpoints.down(1280)]: {
                width: "170px",
                height: "311px",
            },
            borderRadius: "0px",
        },
        image: {
            height: "100%",
            overflow: "hidden",
        },
        content: {
            padding: "16px 0",
            background: "inherit",
        },
        name: {
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down(1280)]: {
                fontSize: "14px",
                lineHeight: "20px",
            },
        },
        brief: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#000000",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down(1280)]: {
                fontSize: "12px",
                lineHeight: "20px",
            },
        },
        bookNowButton: {
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            backgroundColor: "#3FC1CB",
            color: "#FFFFFF",
            borderRadius: "5px",
            padding: "6px 60px",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                flex: 1,
                padding: "6px 10px"
            },
            "&:hover": {
                backgroundColor: "#3FC1CB",
            }
        },
        skeleton: {
            width: "430px",
            height: "300px",
            display: "flex",
            flexDirection: "column"
        },
        loaderContainer: {
            display: "flex",
            flexWrap: "wrap"
        }
    });
export default withStyles(Styles, { withTheme: true })(StaffListingPage);
// Customizable Area End