// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { RouterProps } from "react-router"
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext"
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web"
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web"
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web"
import {
  EditorStateType,
  imageIssues,
  getHtml,
  convertFromHtml
} from "../../ss-cms-common-components/src/Editor/EditorConvertToHTML";
import { getResponseVariables } from "../../ss-cms-common-components/src/GetResponseVariables/GetResponseVariables";
import { clearStorageData, generateRequestMessage, isTokenExpired } from "../../ss-cms-common-components/src/Utilities/Utilities"
const configJSON = require("./config")
import { WithStyles } from "@material-ui/core";
import BlockHelpers from "../../utilities/src/BlockHelpers"
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
 
export type Props = RouterProps &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps &
  WithStyles & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string
    showTable: () => void;
    match: MatchType;
 
  }
 
interface skillsType  {
   title: string, type: string, page_no: number
}
interface S {
  title: string
  description: EditorStateType
  help_center_type: string
  help_center_no: number
  pageId: string
  faqPageId: string
  showTable: boolean
  status: boolean
  answer: string
  editorStateDescription: EditorStateType
  faqDataArray: Array<FaqDataType>
  isPickerDisable: boolean
  helpCenterTypeError: string
  titleError: string;
  descriptionError: string;
  editorStateDescriptionError: string;
  staticType : Array<StaticType>;
  pageType: StaticType
}
 
interface SS {
  id: string
}
 
interface MatchType {
  params: {
    id: string;
  }
}

interface StaticType {
  id: number;
  name: string;
}
interface FaqDataType {
  id?: number
  title?: string
  content?: EditorStateType
  status?: boolean
  titleError?: string
  contentError?: string
  _destroy?: number
  contentLength?: number
}
interface ResponseJsonType {
  data: {
    attributes: {
      title: string
      titleError: string
      description: {
        content: string
      }
      page_type: {
        name: string;
        id: number
      }
      descriptionError: string
      editorStateDescription: EditorStateType
      help_center_type: string
      help_center_no: number
      active: boolean
    }
  }
  errors?: []
}
interface FaqResponseJsonType {
  title: string;
  data: {
    attributes: {
      title: string;
      description: {
        questions: [
          {
            attributes: {
              status: boolean;
              content: string;
            }
          }
        ]
      },
      page_type:{
        id: number;
        name: string;
      },
      active: boolean,
    }
  }
  errors?: []
}
export default class CreateStaticPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getStaticPageApiCallId: string = ""
  createStaticPageFAQApiCallId: string = ""
  getFAQStaticPageApiCallId: string = ""
  updateStaticPageApiCallId: string = ""
  updateStaticPageFAQApiCallId: string = ""
  createStaticPageApiCallId: string = ""
  getStaticPageDataApiCallId: string = ""
  getStaticPageTitleCallId: string = ""
  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.state = {
      title: "",
      titleError: "",
      descriptionError: "",
      editorStateDescriptionError: "",
      description: convertFromHtml(""),
      help_center_no: 0,
      help_center_type: "",
      pageId: props.navigation.getParam("id"),
      faqPageId: (props.navigation.getParam("id")||"").split("-")[1],
      showTable: false,
      status: false,
      answer: "",
      editorStateDescription: imageIssues(""),
      faqDataArray: [{ title: "", content: convertFromHtml(""), status: false }],
      isPickerDisable: false,
      helpCenterTypeError: "",
      staticType : [],
      pageType: {} as  StaticType
          }
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ]
 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    if (this.state.pageId) {
      if (this.state.pageId.includes("FAQs")) {
        this.getFAQStaticPageDataApi()
      } else {
        this.getStaticPage()
      }
    } else {
      mixpanel.track("webadmin_static_pages_create_enter");
    }
    this.getStaticPageApi();
  }
  async componentWillUnmount() {
    super.componentWillUnmount()
    this.props.hideHeaderBar()
  }
 
 
 
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
 
      this.messageGetStaticPageApiCall(message)
      this.messageCreateStaticPageApiCall(message)
      this.messageCreateStaticPageFAQApiCall(message)
      this.messageUpdateStaticPageFAQApiCall(message)
      this.messageUpdateStaticPageApiCall(message)
      this.messageGetFAQStaticPageApiCall(message)

      const [responseJson, apiRequestCallId] = getResponseVariables(message);
      if (this.getStaticPageTitleCallId === apiRequestCallId) {
        if (responseJson && responseJson?.data) {
          this.setState({staticType: responseJson.data})
        }
      }
 
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      )
      this.handleReceiveType(type)
    }
  }
 
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.routeLogin
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };
 
  handleReceiveType = (type: string) => {
    if (type === "SAVECHANGES") {
      if (this.state.pageId) {
        this.handlePageIdSaveFAQ()
      } else {
        this.handleReceiveTypeSub()
      }
    } else if (type === "DISCARDCHANGES") {
      this.handlePageIdFAQ()
    }
  }
 
  handleReceiveTypeSub = () => {
    if (this.state.help_center_type == "FAQs") {
      if (!this.validateFaqs()) {
        this.createStaticPageFAQApi()
      }
    } else {
      if (!this.validateStaticPage()) {
        this.createStaticPageApi()
      }
    }
  }
 
  handlePageIdSaveFAQ = () => {
    if (this.state.pageId.includes("FAQs")) {
      if (!this.validateFaqs()) {
        mixpanel.track("webadmin_static_pages_update", { type: "FAQs" })
        this.updateeStaticPageFAQApi()
      }
    } else {
      if (!this.validateStaticPage()) {
        this.updateStaticPageApi()
      }
    }
  }
 
  handlePageIdFAQ = () => {
    if (this.state.pageId) {
      if (this.state.pageId.includes("FAQs")) {
        this.getFAQStaticPageDataApi()
      } else {
        this.getStaticPage()
      }
    } else {
      this.resetPageData()
    }
  }
 
  messageGetStaticPageApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.getStaticPageApiCallId === apiRequestCallId) {
      this.props.hideLoader();
      if (responseJson && responseJson.data) {
        this.handleGetStaticPageResponse(responseJson)
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
 
  messageCreateStaticPageApiCall = (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.createStaticPageApiCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && !responseJson?.errors) {
        this.handleSuccessCallback()
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
 
  messageCreateStaticPageFAQApiCall =  (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.createStaticPageFAQApiCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && !responseJson?.errors) {
        this.handleSuccessCallback()
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
  messageGetFAQStaticPageApiCall =  (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.getFAQStaticPageApiCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && responseJson?.data) {
        this.initFAQStaticPageData(responseJson)
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
  messageUpdateStaticPageApiCall =  (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.updateStaticPageApiCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && !responseJson?.errors) {
        this.handleSuccessCallback()
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
  messageUpdateStaticPageFAQApiCall =  (message: Message) => {
    const [responseJson, apiRequestCallId] = getResponseVariables(message);
    if (this.updateStaticPageFAQApiCallId === apiRequestCallId) {
      this.props.hideLoader()
      if (responseJson && !responseJson?.errors) {
        this.handleSuccessCallback()
      } else {
        this.handleErrorRespone(responseJson)
      }
    }
  }
 
  
 
 
  openToastOnChange = () => {
    this.props.showHeaderBar({
      ignoreClickAway: true,
      message: "",
      type: "",
    })
  }
 
  handleSuccessCallback = () => {
    this.props.showHeaderBar({
      message: this.state.pageId ? configJSON.textEditSuccessfully : configJSON.textCreateSuccessfully,
      type: "success",
    })
    setTimeout(() => {
      const to = new Message(getName(MessageEnum.NavigationMessage));
      to.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.routeAdminStaticPage);
      to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      runEngine.sendMessage(to.messageId, to);
    }, 500)
  }
  handleErrorRespone = (responseJson: FaqResponseJsonType) => {
    if (!responseJson || !responseJson.errors) {
      return
    }
    const errors = responseJson.errors
 
    let allerrors = ""
    errors.forEach((object: string) => {
      if (allerrors.length <= 0) {
        allerrors = object
      } else {
        allerrors = `${allerrors}{\n}${object}`
      }
    })
    this.props.showHeaderBar({
      message: allerrors,
      type: "error",
    })
  }
  handleGetStaticPageResponse = (response: ResponseJsonType) => {
    this.setState({
      title: response.data?.attributes.title,
      titleError: "",
      description: imageIssues(response.data?.attributes.description.content),
      descriptionError: "",
      editorStateDescription: imageIssues(response.data?.attributes.description.content),
      help_center_type: response.data?.attributes.page_type.name,
      help_center_no: response.data?.attributes.page_type.id,
      status: response.data?.attributes.active,
      pageType: response.data.attributes.page_type
    })
  }
  initFAQStaticPageData = (response: FaqResponseJsonType) => {
    if (response.data.attributes?.description.questions && response.data.attributes?.description.questions.length > 0) {
      let isPublish = false
      const transfer = response.data.attributes.description?.questions.map((item) => {
      const contentLength =   this.htmlToText(item.attributes.content)
        if (item.attributes.status) {
          isPublish = true
        }
        return {
          ...item?.attributes,
          content: imageIssues(item.attributes.content),
          titleError: "",
          contentError: "",
          contentLength:contentLength ? contentLength.length-1 : NaN
        }
      })
      this.setState({
        help_center_type: "FAQs",
        help_center_no: 2,
        status: response.data.attributes.active,
        faqDataArray: transfer,
        title: response.data.attributes.title,
        pageType:response.data.attributes.page_type
      })
    }
  }
  htmlToText = (html: string) => {
    var temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent;
  };

  resetPageData = () => {
    this.setState({
      title: "",
      titleError: "",
      editorStateDescription: imageIssues(""),
      editorStateDescriptionError: "",
      help_center_no: 0,
      help_center_type: "",
      helpCenterTypeError: "",
      faqDataArray: [
        {
          title: "",
          content: convertFromHtml(""),
          status: false,
          titleError: "",
          contentError: "",
        },
      ],
 
      status: false,
    })
  }
 
  validateFAQData = () => {
    let isEmpty = false
    let faqData = [...this.state.faqDataArray]
    faqData.forEach((item) => {
      if (!item?.title?.trim()) {
        isEmpty = true
        item.titleError = configJSON.thisFieldIsRequired
      } else if (item?.title?.trim().length > 100) {
        isEmpty = true
        item.titleError = configJSON.questionCanNotBeMoreThan_100Characters
      } else {
        item.titleError = ""
      }
 
      if (item.content) {
        const content = item.content?.getCurrentContent()
        const textLength = content.getPlainText().length
        const isEditorEmpty = !content.hasText()
        if (isEditorEmpty) {
          item.contentError = configJSON.thisFieldIsRequired
          isEmpty = true
        } else if (textLength > 500) {
          item.contentError = configJSON.answerCanNotBeMoreThan_500Characters
          isEmpty = true
        } else {
          item.contentError = ""
        }
      } else {
        item.contentError = configJSON.thisFieldIsRequired
        isEmpty = true
      }
    })
    this.setState({ faqDataArray: faqData })
    return isEmpty
  }
  validateStaticPage = () => {
    let isEmpty;
    if (
      !this.validateTitle() &&
      !this.validateDescription() &&
      !this.validateType()
    ) {
      isEmpty = false
    } else {
      this.validateTitle()
      this.validateDescription()
      this.validateType()
      isEmpty = true
    }
    return isEmpty
  }
  validateFaqs = () => {
    let isEmpty;
    if (!this.validateFAQData() && !this.validateType()) {
      isEmpty = false
    } else {
      this.validateFAQData()
      this.validateType()
      isEmpty = true
    }
 
    return isEmpty
  }
 
  validateDescription = () => {
    let isError = false
    if (this.state.editorStateDescription) {
      const content = this.state.editorStateDescription.getCurrentContent()
      const isEditorEmpty = !content.hasText()
      if (isEditorEmpty) {
        this.setState({
          editorStateDescriptionError: configJSON.thisFieldIsRequired,
        })
        isError = true
      } else {
        this.setState({ editorStateDescriptionError: "" })
      }
    } 
    return isError
  }
 
  validateType = () => {
    let isError = false
    if (this.state.help_center_type) {
      this.setState({ helpCenterTypeError: "" })
    } else {
      isError = true
      this.setState({ helpCenterTypeError: configJSON.thisFieldIsRequired })
    }
    return isError
  }
  validateTitle = () => {
    let isError = false;
    
    if (!this.state?.title?.trim()) {
      isError = true
      this.setState({ titleError: configJSON.thisFieldIsRequired })
    } else if (this.state.title?.trim().length > 20) {
      isError = true
      this.setState({
        titleError: configJSON.titleCanNotBeMoreThan_20Characters,
      })
    } else {
      this.setState({ titleError: "" })
    }
    return isError
  }
  getStaticPage = async () => {
    mixpanel.track("webadmin_static_pages_detail_enter");
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      `${configJSON.getStaticPageApiEndpoint}/${this.state.pageId}`,
      configJSON.getStaticPageApiMethodType
    )
    this.getStaticPageApiCallId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getFAQStaticPageDataApi = async (searchValue: string = "") => {
    mixpanel.track("webadmin_static_pages_detail_enter");
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      `${configJSON.getFAQStaticPageApiEndpoint}/${this.state.faqPageId}`,
      configJSON.getFAQStaticPageApiMethodType
    )
    this.getFAQStaticPageApiCallId = requestMessage.messageId
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  createStaticPageApi = async () => {
    mixpanel.track("webadmin_static_pages_create", {type: this.state.help_center_type })
    const StaticPage = {
      title: this.state.title,
      description: {
        content: getHtml(this.state.editorStateDescription)
      },
      page_type: this.getType(this.state.help_center_type)?.id,
      active: this.state.status,
    }
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      configJSON.createStaticPageApiEndpoint,
      configJSON.createStaticPageApiMethodType
    )
    this.createStaticPageApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(StaticPage)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  getStaticPageApi = async () => {
      this.getStaticPageTitleCallId = BlockHelpers.callApi({
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.getStaticPageTitleEndPoint,
      });
  }

  createStaticPageFAQApi = async () => {
    mixpanel.track("webadmin_static_pages_create", { type: "FAQs"})
    const faqData = this.state.faqDataArray.map((item) => {
      if (item.title && item.content && !item.id) {
        return {
          attributes: {
            title: item.title,
            content: getHtml(item.content),
            status: item.status,
          }
        }
      }
    });
    const faqPage = {
      title: this.state.title,
      description: {
        questions: faqData
      },
      page_type: this.getType(this.state.help_center_type)?.id,
      active: this.state.status,
    }
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      configJSON.createStaticPageFAQApiEndpoint,
      configJSON.createStaticPageFAQApiMethodType
    )
    this.createStaticPageFAQApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(faqPage)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  updateStaticPageApi = async () => {
    mixpanel.track("webadmin_static_pages_update", {type: this.state.help_center_type })
    const StaticPage = {
      title: this.state.title,
      description: { content: getHtml(this.state.editorStateDescription) },
      active: this.state.status,
    }
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      `${configJSON.updateStaticPageApiEndpoint}/${this.state.pageId}`,
      configJSON.updateStaticPageApiMethodType
    )
    this.updateStaticPageApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(StaticPage)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  updateeStaticPageFAQApi = async () => {
    const faqData = this.state.faqDataArray.map((item) => {
      if (item.content) {
        return {
          attributes: {
            title: item.title,
            content: getHtml(item?.content),
            id: item.id,
            status: item.status,
          }
        }
      }
    })
    const body = {
      title: this.state.title,
      description: { questions: faqData },
      active: this.state.status
    }
 
    this.props.showLoader()
    const requestMessage = await generateRequestMessage(
      `${configJSON.updateStaticPageFAQApiEndpoint}/${this.state.faqPageId}`,
      configJSON.updateStaticPageFAQApiMethodType
    )
    this.updateStaticPageFAQApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
 
  
  
  onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    this.setState({ title: value });
    if (!value) {
      this.setState({ titleError: configJSON.thisFieldIsRequired })
    } else if (value.length > 20) {
      this.setState({
        titleError: configJSON.titleCanNotBeMoreThan_20Characters,
      })
    } else {
      this.setState({ titleError: "" })
    }
    this.openToastOnChange()
  }
 
  handleFaqStatusChanges = (faqIndex: number, status: boolean) => {
    let faqData = [...this.state.faqDataArray]
    faqData[faqIndex].status = status
    this.setState({ faqDataArray: faqData }, () => {
      this.handleFaqWholeStatusChanges(faqData)
    })
  }
  handleFaqWholeStatusChanges = (faqData: Array<FaqDataType>) => {
    const faqSatatusDATA = faqData.filter((item) => item.status)
 
    this.setState(
      {
        status: faqSatatusDATA.length > 0,
      },
      () => {
        this.openToastOnChange()
      }
    )
  }
 
  handleQueChange = (
    faqIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let faqData = [...this.state.faqDataArray]
    let quetionValue = event.target.value
    faqData[faqIndex]["title"] = quetionValue
 
    if (!quetionValue) {
      faqData[faqIndex].titleError = configJSON.thisFieldIsRequired
    } else if (quetionValue.length > 100) {
      faqData[faqIndex].titleError = configJSON.questionCanNotBeMoreThan_100Characters
    } else {
      faqData[faqIndex].titleError = ""
    }
    this.setState({ faqDataArray: faqData })
    this.openToastOnChange()
  }
  handleEditorStateChange = (faqIndex: number, editorState: EditorStateType) => {
    let faqData = [...this.state.faqDataArray]
    faqData[faqIndex].content = editorState
    if (editorState) {
      const content = editorState.getCurrentContent()
      const textLength = content.getPlainText().length
      const isEditorEmpty = !content.hasText()
      faqData[faqIndex].contentLength = textLength
      if (isEditorEmpty) {
        faqData[faqIndex].contentError = configJSON.thisFieldIsRequired
      } else if (textLength < 1 || textLength > 500) {
        faqData[faqIndex].contentError =
          configJSON.answerCanNotBeMoreThan_500Characters
      } else {
        faqData[faqIndex].contentError = ""
      }
    } else {
      faqData[faqIndex].contentError = configJSON.thisFieldIsRequired
    }
 
    this.setState({ faqDataArray: faqData })
    this.openToastOnChange()
  }
  handleEditorStateChangeDescription = (editorState: EditorStateType) => {
    this.setState({ editorStateDescription: editorState })
 
    if (editorState) {
      const content = editorState.getCurrentContent()
      const isEditorEmpty = !content.hasText()
      if (isEditorEmpty) {
        this.setState({
          editorStateDescriptionError: configJSON.thisFieldIsRequired,
        })
      } else {
        this.setState({ editorStateDescriptionError: "" })
      }
    } else {
      this.setState({
        editorStateDescriptionError: configJSON.thisFieldIsRequired,
      })
    }
 
    this.openToastOnChange()
  }
  onAddAnotherFaqItem = () => {
    const isEmpty = this.validateFAQData()
    if (!isEmpty) {
      let newfield = { title: "", content: convertFromHtml("") }
 
      this.setState({ faqDataArray: [...this.state.faqDataArray, newfield] })
    }
  }
  removeFields = (faqIndex: number) => {
    let faqData = [...this.state.faqDataArray]
    faqData.splice(faqIndex, 1)
    if (faqData.length == 0) {
      faqData.push({ title: "", content: convertFromHtml("") })
    }
    this.setState({ faqDataArray: faqData })
  }
  getType = (type: string) => {
    const found = this.state.staticType.find((element: StaticType) => {
      if (element?.name == type) {
        return element
      }
    })
    return found
  }
  getFromPageNo = (pageNo: number) => {
      if (this.state.pageType.id == pageNo) {
        return this.state.pageType
      }
  }
  handlePageTypeChange = (value: string) => {
    this.setState({ help_center_type: value }, () => {
      if (value) {
        this.setState({ helpCenterTypeError: "" })
      } else {
        this.setState({
          helpCenterTypeError: configJSON.thisFieldIsRequired,
        })
      }
      this.openToastOnChange()
    })
  }
  handleActiveChange = (checked: boolean) => {
        this.setState(
      {
        status: checked,
      },
      () => {
                this.openToastOnChange()
      }
    )
  }
  handleBack = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.routeAdminStaticPage);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  }
}
// Customizable Area End