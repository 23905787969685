// Customizable Area Start
import React, { ChangeEvent, RefObject } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseHeader } from "../../utilities/src/helpers/utils";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { Navigation } from "../../utilities/src/models/Navigation";
import { saveStoreDetails } from "../../utilities/src/components/Currency.web";
import storageProvider from "../../../framework/src/StorageProvider.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export const configJSON = require("./config");

export interface Props {
  navigation: Navigation;
  identifier: string;
}

export interface ServiceList {
  id: number,
  title: string,
  is_service: boolean
}

export interface AppHeaderResponse {
  data: AppHeaderData
}

export interface AppHeaderData {
  attributes: {
      header: {
          id: number,
          store_name: string,
          navigation_item_1: string ,
          navigation_item_2: string,
          navigation_item_3: string,
          website_title: string,
          image: {
              url: string,
              id: number,
              thumbnail_url: string
          },
          favicon: {
              url: string,
              id: number
          }
      }
  }
}
export interface SearchlistResponseInf {
  search_data: Array<ServiceList>
}

interface S {
  logo: ServiceImage | File | null;
  storeName: string;
  searchQuery: string;
  searchRef: string;
  searchListSuggestions: Array<ServiceList>;
  openClose: boolean;
  isServiceProvider: boolean;
  renameItem1: string;
  renameItem2: string;
  renameItem3: string;
  isModalOpen: boolean;
  email: string;
  emailError: string;
  bookingId: string;
  bookingIdError: string;
  loading:boolean;
}

interface SS {
  identifier: string;
}

export default class AppheaderController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string = "";
  getStoreDetailsCallId: string = "";
  searchServiceListAPICallId: string = "";
  getManageBookingAPICallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchQueryMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
      openClose: false,
      logo: null,
      storeName: "",
      searchQuery: "",
      searchRef: "",
      searchListSuggestions: [],
      isServiceProvider: false,
      renameItem1: "",
      renameItem2: "",
      renameItem3: "",
      isModalOpen: false,
      email: '',
      emailError: '',
      bookingId: '',
      bookingIdError: '',
      loading:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrands();
    this.getStoreDetails();
    this.getServiceProviderDetail();
    window.addEventListener('popstate', this.handleBackButton);
  }

  async componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const navigationData = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {


      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleGetBrandsResponse(responseJson);
      } else {
        this.setState({
          logo: null,
          storeName: "",
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SearchQueryMessage) === message.id) {
      const searchQuery = message.getData("SearchQueryMessage").trim();
      this.setState({ searchQuery });
    } else if (this.getStoreDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getServiceProviderCount(responseJson?.data?.attributes?.service_provider_available)
      saveStoreDetails(responseJson?.data?.attributes);
    } else if (this.searchServiceListAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.error) {
        this.handlesearchServiceListResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (this.getManageBookingAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.error) {
        this.handleManageBookingResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if(navigationData && navigationData?.openBookingModal)
    {
      this.toogleModal();
    }

  };

  handleGetBrandsResponse = (responseJson: AppHeaderResponse) => {
    const response = responseJson.data;

    const { logo, storeName, renameItem1, renameItem2, renameItem3, websiteTitle } = parseHeader(response);
    document.title = websiteTitle || "SSServiceInternal";

    this.setState({
      logo,
      storeName,
      renameItem1,
      renameItem2,
      renameItem3
    });
  };

  getBrands = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  confirmSearch = (searchQuery: string) => {
    this.setState({ searchQuery });
    this.props.navigation.navigate("Advancesearch", {
      searchQuery
    });
  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStoreDetailsCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStoreDetails,
      header
    });
  }

  searchServiceList = (queryRef: RefObject<HTMLInputElement>) => {
    this.setState({
      openClose:true,
      loading:true
    })
    if (queryRef.current) {
      this.setState({ searchRef: queryRef.current?.value })
    }

    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson,
    };

    const query = (queryRef.current && queryRef.current.value)
    this.searchServiceListAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.searchServiceListAPIEndPoint + "?query=" + query,
      header,
    });
  };

  handlesearchServiceListResponse = (responseData: SearchlistResponseInf) => {
   
    this.setState({ searchListSuggestions: responseData.search_data,loading:false})
    
  }

  handleManageBookingResponse = (responseData: any) => {
    storageProvider.set("bookingData", JSON.stringify(responseData))
    this.toogleModal();
    this.setState({email: '', bookingId: ''})
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "OrderStatus");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  }

  searchElementTitleFunction = (searchQuery: string, isService: boolean) => {
    this.setState({
      openClose:false,
    })
    if (isService) {
      this.confirmSearchService(searchQuery);
    } else {
      this.confirmSearchProvider(searchQuery)
    }
  };

  confirmSearchService = (searchQuery: string) => {
    this.props.navigation.navigate("ServicesSearch", {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  confirmSearchProvider = (searchQuery: string) => {
    this.props.navigation.navigate("StaffSearch", {
      searchQuery
    });
    this.setState({ searchQuery });
  };

  closeSearch = () => {
    this.setState({
      openClose:false,
      loading:false
    })
  }

  getServiceProviderCount = (isAvailable: boolean) => {
    this.setState({ isServiceProvider: isAvailable })
    window.localStorage.setItem("serviceProvider", JSON.stringify(isAvailable));
  }

  getServiceProviderDetail = () => {
    const serviceProviderDetails = localStorage.getItem("serviceProvider");
    if (serviceProviderDetails && serviceProviderDetails !== "undefined") {
      this.setState({isServiceProvider: JSON.parse(serviceProviderDetails)});
    }
  }

  toogleModal = () => {
    mixpanel.track(configJSON.navigationManageBooking);
    this.setState({email: '', bookingId: '', emailError: '', bookingIdError: ''})
    this.setState({ isModalOpen: !this.state.isModalOpen,loading:false },() => {
      if(this.state.isModalOpen)
      {
        setTimeout(() => {
          document.body.style.cssText = 'overflow: hidden !important';
        }, 0);
      }
    })
  }

  isValidEmail = (value : string) => {
    if(value === '') {
      this.setState({emailError: configJSON.emailRequired})
      return false;
    } else if(!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)))
    {
      this.setState({emailError: configJSON.invalidEmail})
      return false;
    } else {
      this.setState({emailError: ''})
      return true;
    }
  }

  isValidBookingId = (value : string) => {
    if(value === '')
    {
      this.setState({bookingIdError: configJSON.bookingIdRequired})
      return false;
    }
    else {
      this.setState({bookingIdError: ''})
      return true;
    }
  }

  isValidButtonSubmit = () => {
    const { email, bookingId } = this.state;
    let isValidEmail = false, isValidBookingId = false;
    isValidEmail = this.isValidEmail(email);
    isValidBookingId = this.isValidBookingId(bookingId)
    return isValidEmail && isValidBookingId
  }
  

  handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidEmail(event.target.value)
    this.setState({email: event.target.value})
  }
  handleBookingIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.isValidBookingId(event.target.value)
    this.setState({bookingId: event.target.value})
  }

  searchBookingDetails = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getManageBookingAPICallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: `${configJSON.getManageBookingEndPoint}?email=${this.state.email}&order_id=${this.state.bookingId}`,
      header
    });
  }

  handleSubmit = () => {
    if(this.isValidButtonSubmit())
    {
      mixpanel.track(configJSON.proceedManageBookingForm);
      this.searchBookingDetails()
    }
  }

  handleBackButton = (event:any) => {
    if (event.type === 'popstate') {
      this.setState({email: '', bookingId: '', emailError: '', bookingIdError: ''})
      this.setState({ isModalOpen: false })
      setTimeout(() => {
        document.body.style.cssText = 'overflow: auto';
      }, 0);
    }
  }
}
// Customizable Area End
