// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  formActionsWrapper: {
    top: 0,
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
  },
  logo: {
    objectFit: "none",
    width: "auto"
  },
  discardChangesbutton: {
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
    padding: theme.spacing(1.5, 3),
    color: "#FFFFFF",
  },
  saveChangesbutton: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    padding: theme.spacing(1.5, 5),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
  container: { padding: theme.spacing(5, 3) },
  wrapper: { display: "flex", gap: theme.spacing(3) },
  leftSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 70%",
  },
  RightSideWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    flex: "1 1 30%",
  },
  card: {
    padding: theme.spacing(5),
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF",
  },
  card1: {
    padding: "5.5px 15px",
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    backgroundColor: "#FFFFFF"
  },
  errorCard: {
    padding: "25px 20px",
    borderRadius: "3px",
    border: "2px solid red",
    backgroundColor: "#FFFFFF"
  },
  accordionContainer: {
    boxShadow: "none !important"
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    marginBottom: theme.spacing(4),
    color: "#000000",
  },
  inputsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  inputlabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: 8,
    color: "black"
  },
  inputlabel1: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: 8,
    color: "#000000",
  },
  input: {
    color: "#3C3E49",
    "&.Mui-disabled": {
      backgroundColor: "#3C3E4920",
      color: "#00000042",
      cursor: "not-allowed",
    },
  },
  copyrightWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  copyrightCounter: {
    fontSize: "12px",
    lineHeight: "20px",
  },
  previewImage: {
    width: "143px",
    height: "76px",
  },
  editButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#6200EA",
    minWidth: "auto",
  },
  removeFaviconButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#6200EA",
    minWidth: "auto",
  },
  removeButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#3C3E49",
    minWidth: "auto",
  },
  imageUpload: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    width: "133px",
    borderRadius: "10px",
    border: "2px dashed #C0C3CE",
    padding: theme.spacing(1.25),
    cursor: "pointer",
  },
  addIcon: {
    color: "#676B7E",
  },
  addImageText: {
    fontSize: "18px",
    lineHeight: "32px",
    color: "#676B7E",
  },
  fileSize: {
    fontSize: "10px",
    lineHeight: "24px",
    color: "#AFB2C0",
  },
  imageValidationError: {
    color: "#f44336",
    margin: "3px 3px 0px",
    fontSize: "0.75rem",
    lineHeight: "1.66",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  item: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    padding: theme.spacing(1, 0),
  },
  phoneNumberWrapper: { display: "flex" },
  select: {
    height: "54px",
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
  },
  phoneNumber: {
    "& > div": {
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px",
    },
  },
  countryCodeText: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    color: "#3C3E49",
  },
  countryCodeImage: {
    marginRight: theme.spacing(0.5),
  },
  platformWrapper: { width: "100%" },
  checkbox: {
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2, 0, 0),
    color: "transparent !important",
  },
  storeLabel: {
    marginTop: theme.spacing(1.75),
  },
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  switch: {
    "& .MuiSwitch-switchBase": {
      color: "#FFFFFF",
    },
    "& .Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#00D659",
        opacity: 1,
      },
    },
  },
  testimonialHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: theme.spacing(1.2),
    width:"100%",
    borderBottom: "1px solid rgba(159, 145, 145, 0.12)"
  },
  testimonialTitle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "-0.4px",
    color: "black"
  },
  hidden: {
    display: "none",
  },
  helperText: {
    fontSize: "10px",
    lineHeight: "20px",
    color: "#83889E",
    marginTop: theme.spacing(1.5),
  },

  cropperBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    borderRadius: "10px",
  },
  titleBlock:{
    display:"flex",
    padding: 40,
    color: "#3C3E49",
    width: "100%",
    justifyContent: "space-between"
  },
  modalTitle: {
    color: "#3C3E49",
    fontSize: 28,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: 1.21,
    fontStretch: "normal",
    fontFamily: "Rubik",
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  renameConatiner:{
    display: "flex",
    gap:"20px",
    marginTop: "25px"
  },
  renameBox:{
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3)
  },
  logoTitleContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  storeNameBox: {
    width: "100%"
  },
  sectionTestimonial: {
    fontSize: "20px",
    fontWeight: 500,
    color: "black"
  },
  accordianDetails: {
    flexDirection: "column"
  },
  testimonalBox:{
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    "& img":{
      marginLeft: "10px"
    }
  }
}));
// Customizable Area End