// Customizable Area Start
import * as React from "react";
import AdminHomeController from "./AdminHomeController.web";
import {
  withStyles,
  createStyles,
} from "@material-ui/core";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { Scrollbars } from "react-custom-scrollbars";
import ProgressTracker, { ProgressStep } from "../../ss-cms-common-components/src/ProgressTracker";
import RoundIconBadge from "../../ss-cms-common-components/src/Badge/RoundIconBadge";
import { Redirect } from "react-router-dom";

export const configJSON = require("./config");

export class AdminHome extends AdminHomeController {
  render() {
    const { classes } = this.props;

    if(!window.localStorage.getItem("admintoken")){
      return <Redirect to="EmailAccountLogin" />
    }
    
    return (
      <Scrollbars>
        <div className={classes.page}>
        <div className={classes.pages}>
        <div className={classes.welcome}>{configJSON.textWelcome}</div>
        <div className={classes.welcomeNext}> admin panel!</div>
        </div>  
        <div className={classes.hint}>{configJSON.textHint}</div>
        </div>
      </Scrollbars>
    );
  }
}

const Icon = () => (
  <RoundIconBadge
    iconName={"tick"}
    color={"green"}
    className="me-3"
    size="md"
  />
)
export const styles = () =>
  createStyles({
    page: {
      padding: "70px 400px 10px 50px",
      minHeight: "80vh",
      display: "flex",
      flexDirection: "column",
    },
    pages: {
      display: "flex",
      flexDirection: "row",
      gap: 5
    },
    header: {
      flexDirection: "row",
    },
    welcome: {
      fontFamily: "Rubik",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49"
    },
    welcomeNext: {
      fontFamily: "Rubik",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3E52FF"
    },
    hint: {
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "#83889E"
    },

    steps: {
      display: "flex",
      flexDirection: "column",
      gap: 16
    },
    stepTitle: {
      fontFamily: "Rubik",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49",
      marginTop: -8
    },
    step: {
      padding: "15px 20px",
      display: "flex",
      flexDirection: "row",
      gap: 16,
      fontWeight: 500,
      border: "0.5px solid #83889E",
      alignItems: "center",
      color: "#3C3E49"
    },

    done: {
      padding: "20px 0 0 0"
    },
    doneTitle: {
      fontFamily: "Rubik",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      textAlign: "left",
      color: "#3C3E49"
    },
    doneText: {}
  });

export default withLayout(withStyles(styles)(
  AdminHome
));
// Customizable Area End