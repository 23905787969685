const configJSON = require("../config.js");
import { Message } from "../../../../framework/src/Message";
import { getResponseVariables } from "../GetResponseVariables/GetResponseVariables";
import { withLoaderProps } from "../HOC/withBrandingSpinner.web";
import { DialogProps } from "../Dialog/withDialog";

export const checkResponseError = (message: Message) => {
  const [responseJson] = getResponseVariables(message);
  return (
    !responseJson ||
    responseJson?.errors ||
    responseJson?.error ||
    (responseJson?.data?.type === "error" &&
      responseJson?.data?.attributes?.errors)
  );
};

export const showError = (
  title: string,
  message: Message,
  hideLoader: withLoaderProps["hideLoader"],
  setDialogState: DialogProps["setDialogState"]
) => {
  const [responseJson] = getResponseVariables(message);

  hideLoader?.();
  let errors =
    responseJson?.error ||
    responseJson?.errors ||
    responseJson?.data?.attributes?.errors ||
    configJSON.serverConnectionError;
  errors = Object.entries(errors).map(([key, array]: [string, unknown]) =>
    (array as string[]).map((i: string) => configJSON.textFieldError(key, i))
  );

  const isOnlyOneString = typeof errors === "string";
  const stringErrors = typeof errors[0] === "string";
  setDialogState?.(true, {
    title: title,
    message: isOnlyOneString
      ? errors
      : stringErrors
      ? errors.join("\n")
      : errors
          .map((i: any) => Object.values(i))
          .flat()
          .join("\n"),
    okay: "OK",
    confirmColor: "white",
    confirmBackground: "#FF1744",
    confirmHoverBackground: "rgb(240, 25, 73)",
    hideCancel: true,
  });
};
