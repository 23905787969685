// Customizable Area Start
import React, {Suspense}  from "react";
import { Box, IconButton, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Appfooter as useStyles } from "./styles/Appfooter.web";
import { Link } from "react-router-dom";
import { Platform } from "../../utilities/src/models/Platform";
import GooglePlayBadge from "../assets/google-play-badge.png";
import AppStoreBadge from "../assets/../assets/app-store-badge.png";
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
import { StaticPage } from "./AppfooterController.web";
import { twitter_logo } from "./assets";
import { Skeleton } from "@material-ui/lab";

import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

interface Props {
  countryCode: string;
  phoneNumber: string;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];  
  loading:boolean
}

const Appfooter: React.FC<Props> = ({
  countryCode,
  phoneNumber,
  socialMedias,
  applications,
  copyright,
  staticPages,
  loading
}) => {
  const classes = useStyles();
  
  const { facebook, instagram, twitter, youtube } = socialMedias;
  const { android, ios } = applications;

  return (
    <>
      <Box className={classes.table}>
        <Box className={classes.column}>
          <Typography className={classes.header}>Contact us</Typography>
          <Box className={classes.infoWrapper}>
            <Link onClick={() => {
              mixpanel.track("webcustomer_footer_navigation", { type: "about_us" });
              setTimeout(()=>{
                window.scrollTo({ top: 0, behavior: "smooth" });
              },200)
              }}
              to={"/AddContact"} className={classes.info}>
              <Typography>Send a message</Typography>
            </Link>
            {(loading == true) ? 
          <Skeleton
          width="120px"
          height="24px"
        />: <>
        {phoneNumber ? <a className={classes.phoneNumber} href={"tel:+" + countryCode + phoneNumber}>
              <Typography
                className={classes.info}
              >{`(${countryCode}) ${phoneNumber}`}</Typography></a>:  "" }  
            </>
          }
          </Box>
        </Box>

        {staticPages.length > 0 && 
         <Box className={classes.column}>
          <Typography className={classes.header}>Help Center</Typography>
          <Box className={classes.titleBox}>
            <Box className={`${classes.infoWrapper} ${classes.helpCenter}`}>
              {staticPages.slice(0, 5).map((item: StaticPage) => {
                return (
                  <>
                    <Link
                      onClick={() => {
                        mixpanel.track("webcustomer_footer_navigation", { type: "about_us" });
                        setTimeout(() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }, 200)
                      }}
                      to={"/StaticPages/" + item.id} className={classes.info}>
                      <Typography>{item.attributes.title}</Typography>
                    </Link>
                  </>
                )
              })}
            </Box>

            {staticPages.length > 5 &&
              <Box className={`${classes.infoWrapper} ${classes.helpCenters}`}>
                {staticPages.slice(5).map((item: StaticPage) => {
                  return (
                    <>
                      <Link
                        onClick={() => {
                          mixpanel.track("webcustomer_footer_navigation", { type: "about_us" });
                          setTimeout(() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }, 200)
                        }}
                        to={"/StaticPages/" + item.id} className={classes.info}>
                        <Typography>{item.attributes.title}</Typography>
                      </Link>
                    </>
                  )
                })}
              </Box>
            }
          </Box>
        </Box>}
        {(loading == true)?
        <Box className={classes.row}>
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
      </Box>: <>
      {(facebook || instagram || twitter || youtube) ? (
          <Box className={classes.column}>
            <Typography className={classes.header}>Social</Typography>
            <Box className={classes.row}>
              {facebook && (
                <IconButton onClick={() => {
                  mixpanel.track("webcustomer_footer_navigation", { type: "facebook" })
                  window.open(facebook.url)
                }}>
                  <FacebookIcon className={classes.icon} />
                </IconButton>
              )}
              {instagram && (
                <IconButton onClick={() => {
                  mixpanel.track("webcustomer_footer_navigation", { type: "instagram" })
                  window.open(instagram.url)
                }}>
                  <InstagramIcon className={classes.icon} />
                </IconButton>
              )}
              {twitter && (
                <IconButton onClick={() => {
                  mixpanel.track("webcustomer_footer_navigation", { type: "twitter" })
                  window.open(twitter.url)
                }}>
                  <img src={twitter_logo} alt="twitter" className={classes.twitterIcon} />
                </IconButton>
              )}
              {youtube && (
                <IconButton onClick={() => {
                  mixpanel.track("webcustomer_footer_navigation", { type: "youtube" })
                  window.open(youtube.url)
                }}>
                  <YouTubeIcon className={classes.icon} />
                </IconButton>
              )}
            </Box>
     
          </Box>
        ): ""}
      </> 
       }
       
       {(loading == true) ? 
       <Box className={classes.imagesWrapper}>
       <Skeleton variant="rect"className={classes.imageSkeltonBox} />
       <Skeleton variant="rect" className={classes.imageSkeltonBox} />
       
     </Box>: <>
     {(android || ios) ? (
          <Box className={classes.column}>
            <Typography className={classes.header}>Download App</Typography>
            <Box className={classes.imagesWrapper}>
              {android && (
                <Suspense fallback={<CircularProgress />}>
                  <ImageCard
                    imageClassName={classes.image}
                    image={GooglePlayBadge}
                    onClick={() => {
                      mixpanel.track("webcustomer_app_download", { device: "android" })
                      window.open(android.url)
                    }}
                    alt="image loading"
                  />
                </Suspense>
              )}
              {ios && (
                <Suspense fallback={<CircularProgress />}>
                  <ImageCard
                    imageClassName={classes.image}
                    image={AppStoreBadge}
                    onClick={() => {
                      mixpanel.track("webcustomer_app_download", { device: "ios" })
                      window.open(ios.url)
                    }}
                    alt="image loading"
                  />
                </Suspense>
              )}
            </Box>
          </Box>
        ):""}
     </>
       }
      </Box >

      <Typography component="div" className={classes.copyrights}>
        {`${copyright} - Powered by `}
        <a href="https://www.builder.ai/">Builder.ai</a>
      </Typography>
    </>
  );
};

export default Appfooter;
// Customizable Area End