// Customizable Area Start
import CustomerFeedbackCreateController, {
  IOSSwitchProps
} from "./CustomerFeedbackCreateController.web";
import "../assets/customerFeedback.css";
import * as React from "react";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import UploadBox from "../../ss-cms-common-components/src/UploadBox/UploadBox.Web";
import {
  withStyles,
  Theme,
  createStyles,
  TextField,
  Switch,
  Typography,
  Modal,
  Box,
  Button,
} from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCropper from "../../ss-cms-common-components/src/AddCropper/AddCropper.web";
import CloseIcon from "@material-ui/icons/Close";
import Editor from "../../ss-cms-common-components/src/Editor/EditorConvertToHTML";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout";
import { AddIcon } from "./assets";
export const configJSON = require("./config.js");

export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 52,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(29px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: IOSSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
export class CustomerFeedbackCreate extends CustomerFeedbackCreateController {
  render() {
    const { classes } = this.props;
    const {
      description,
      position,
      customerName,
      activated,
      cropperOpen,
      selectedFile,
      customerFeedbackId,
      image,
      selectedFileType,

    } = this.state;

    const plainDescription = description?.getCurrentContent?.().getPlainText?.();
    const isDescriptionLimitReached = plainDescription?.length > 100;
    return (
      <Scrollbars>
        <div className="customer-feedback-page customer-feedback-create-container">
          <div className="header-customer-feedback-create">
            <div>
              <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                className="header-customer-feedback-create-icon-container"
                onClick={this.goBack}
              >
                <Typography>{configJSON.back}</Typography>
              </Button>
              <span className="header-customer-feedback-create-title">
                {customerFeedbackId ? configJSON.edit : configJSON.add} {configJSON.customerFeedback}
              </span>
            </div>
          </div>
          <div className="customer-feedback-create-bottom-container">
            <div className="customer-feedback-create-details-container">
              <div style={{ backgroundColor: "#FFF", padding: 40 }}>
                <label className="customer-feedback-details-title">
                  {configJSON.customerFeedbackDetails}
                </label>
                <div className="customer-feedback-details-input-container">
                  <div className="customer-feedback-details-input-label">
                    <span>{configJSON.description}</span>
                    <span
                      style={{
                        color: isDescriptionLimitReached ? configJSON.red : configJSON.black,
                      }}
                    >
                      {plainDescription?.length}
                      /100
                    </span>
                  </div>
                  <Editor
                    editorState={description}
                    onEditorStateChange={
                      this.handleDescriptionChange}
                    toolbar={{
                      options: ["inline", "textAlign", "list", "image"],
                      list: {
                        options: ["unordered", "ordered"],
                      },
                      textAlign: {
                        options: ["left", "center", "right"],
                      },
                    }}
                    data-testid='editor-onchange'
                  />
                  {!description?.getCurrentContent?.().getPlainText?.() &&
                    this.state.localValidation && (
                      <div className='validation-field'>
                        {configJSON.requiredField}
                      </div>
                    )}
                  {isDescriptionLimitReached && (
                    <span className="editor-description-limit-error">
                      {configJSON.maxCharacters}
                    </span>
                  )}



                </div>
                <div className="customer-feedback-details-input-container">
                  <label className="customer-feedback-details-input-label">
                    <span>{configJSON.position}</span>
                  </label>
                  <TextField
                    name="position"
                    InputProps={{
                      className: classes?.input,
                    }}
                    value={position}
                    onChange={this.handlePositionChange}
                    variant="outlined"
                  />
                  {!position &&
                    this.state.localValidation && (
                      <div className='validation-field test-position-field'>
                        {configJSON.requiredField}
                      </div>
                    )}
                </div>
                <div className="customer-feedback-details-input-container">
                  <label className="customer-feedback-details-input-label">
                    <span>{configJSON.customerName}</span>
                  </label>
                  <TextField
                    name="customerName"
                    InputProps={{
                      className: classes?.input,
                    }}
                    value={customerName}
                    onChange={this.handleCustomerNameChange}
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  {!customerName &&
                    this.state.localValidation && (
                      <div className='validation-field test-customer-field'>
                        {configJSON.requiredField}
                      </div>
                    )}
                </div>
                <label className="customer-feedback-details-input-label">
                  <span>{configJSON.image}</span>
                </label>
                {!!image ? (
                  <div className={classes?.editUploadBoxContainer}>
                    <CloseIcon
                      onClick={
                        this.removeLogoImg
                      }
                      data-testid="removeImage"
                      className={classes?.crossOverImage}
                    />
                    <img
                      className={classes?.editUploadBoxImage}
                      alt="image loading"
                      src={image}
                    />
                  </div>
                ) : (
                  <UploadBox
                    dropContainerStyle={{ margin: 0 }}
                    uploadIcon={AddIcon}
                    height="180px"
                    width="100%"
                    uploadMsg={configJSON.addImage}
                    sizeMsg={configJSON.sizeMsg}
                    onSelectFile={this.selectImageFile.bind(this)}
                    accept=".jpeg, .jpg, .png, .gif"
                    allowedExtensions={['.jpeg', '.jpg', '.png', '.gif']}
                  />
                )}
                {!this.state.image &&
                  this.state.localValidation && (
                    <div className='validation-field test-image-field'>
                      {configJSON.imageEmpty}
                    </div>
                  )}
                {this.state.image && this.state.imageSize > 20000000 &&
                  this.state.localValidation && (
                    <div className='validation-field test-imageSize'>
                      {configJSON.imageValidation}
                    </div>
                  )}
                {this.state.alertMessage && (
                  <div className='validation-field test-imageSize'>
                    {configJSON.imageValidationMesage}
                  </div>
                )}
              </div>
            </div>
            <div className="customer-feedback-create-right-side">
              <div className="customer-feedback-create-activated-container">
                <div className="customer-feedback-create-activated-cell-container">
                  <label className="customer-feedback-details-title">
                    {configJSON.activated}
                  </label>
                  <div
                    className={
                      activated
                        ? classes?.activatedCellStyleTrue
                        : classes?.activatedCellStyleFalse
                    }
                  >
                    {activated ? configJSON.yes : configJSON.no}
                  </div>
                </div>
                <div className="customer-feedback-create-switch-container">
                  <IOSSwitch
                    checked={activated}
                    onChange={(event) => this.handleActiveChange(event?.target?.checked)}
                    data-testid='switch-test-change'
                  />
                  <label className="customer-feedback-details-text">
                    {activated ? configJSON.activated : configJSON.notActivated}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal open={cropperOpen}>
          <Box className={classes?.cropperBox} data-testid="cropper-dialog">
            <div className={classes?.titleBlock}>
              <Typography className={classes?.modalTitle}>
                {configJSON.customerFeedbackImage}
              </Typography>
              <CloseIcon
                data-testid="closeButton"
                className={classes?.closeIcon}
                fontSize="medium"
                onClick={this.handleCropperClose}
              />
            </div>
            <AddCropper
              onCancel={this.handleCropperClose}
              dataToPass={{
                selectedFile: selectedFile,
                type: selectedFileType
              }}
              isAspectRatio={false}
              onSubmit={(data: string) => this.handleCropperSubmit(data)}
            />
          </Box>
        </Modal>
      </Scrollbars>
    );
  }
}
export const styles = (theme?: Theme) =>
  createStyles({
    input: {
      height: 48,
      width: 450,
    },
    backIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    activatedCellStyleTrue: {
      backgroundColor: "#EBF5F0",
      borderColor: "#B3DAC7",
      color: "#008243",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 32,
      height: 20,
      fontSize: 12,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      padding: 10,
      display: "flex",
      paddingRight: 20,
      paddingLeft: 20,
    },
    activatedCellStyleFalse: {
      backgroundColor: "#FFECEC",
      borderColor: "#FFD7D7",
      color: "#E00000",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 32,
      height: 20,
      fontSize: 12,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      padding: 10,
      display: "flex",
      paddingRight: 20,
      paddingLeft: 20,
    },
    editUploadBoxContainer: {
      width: "144px",
      margin: "0 24px",
      height: "84px",
      position: "relative",
    },
    crossOverImage: {
      right: "-5px",
      top: "-5px",
      position: "absolute",
      backgroundColor: theme?.palette?.primary?.main,
      color: "white",
      padding: "3px",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      cursor: "pointer",
    },
    editUploadBoxImage: {
      borderRadius: "3px",
      width: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      objectPosition: "center",
    },
    cropperBox: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFF",
      borderRadius: "10px",
    },
    titleBlock:{
      display:"flex",
      padding: 40,
      color: "#3C3E49",
      width: "100%",
      justifyContent: "space-between"
    },
    modalTitle: {
      color: "#3C3E49",
      fontSize: 28,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: 1.21,
      fontStretch: "normal",
      fontFamily: "Rubik",
    },
    closeIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  });
export default withLayout(withStyles(styles)(
  withLoader(withHeaderBar(withDialog(CustomerFeedbackCreate)))
));
// Customizable Area End