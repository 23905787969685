// Customizable Area Start
import React from "react"
import { Field } from 'formik';
import CurrencyInputController from "./CurrencyInputController.web";

class CurrencyInput extends CurrencyInputController {

    render(){
        return <Field
            {...this.props}
            placeholder={this.state.currency}
        />
    }
}

export default CurrencyInput;
// Customizable Area End
