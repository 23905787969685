// Customizable Area Start
import * as React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import * as Yup from "yup";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { payloadTypes } from "./PromoCodeCreateController.web";
import { FormikProps, FormikHelpers, FormikErrors, FormikHandlers, FormikTouched } from "formik";
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config.js");

export type Props = RouterProps & withLoaderProps & WithStyles & {
  onSubmit: (data: payloadTypes) => {},
  openToast: () => void,
  promoCodeData: initialValuesTypes,
  formRef: React.RefObject<FormikProps<initialValuesTypes>>
  classes: { input: string, keyboardButton: string, }
} & {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  }
};

export interface userLimitTypes {
  userLimit: number
}
interface discountTypes {
  value: string,
  label: string
}
export interface InputProps {
  values: initialValuesTypes;
  errors: FormikErrors<initialValuesTypes>;
  touched: FormikTouched<initialValuesTypes>;
  setFieldValue: FormikHelpers<initialValuesTypes>["setFieldValue"];
  handleBlur: FormikHandlers["handleBlur"];
}

export interface initialValuesTypes {
  promoCodeName: string,
  description: string,
  code: string,
  discountType: string,
  discount: string,
  minCartValue: string,
  maxCartValue: string,
  validFrom: Date,
  validTo: Date,
  userLimit: number | null
}

interface S {
  discountTypes: Array<discountTypes>;
  initialValues?: initialValuesTypes;
  promoCodeId: string | number;
}

interface SS {
  id: number;
}

export default class PromoCodeComponentController extends BlockComponent<
  Props,
  S,
  SS
> {

  formRef: React.RefObject<FormikProps<initialValuesTypes>>;
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);
    this.state = {
      discountTypes: configJSON.discountDataType,
      promoCodeId: (props.navigation.getParam("id")) || 0
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {

  }

  getErrorStatus = (error: FormikErrors<initialValuesTypes>, touched: FormikTouched<initialValuesTypes>, fieldKey: keyof initialValuesTypes) => {
    if (error?.[fieldKey] && touched?.[fieldKey]) {
      return true;
    }
    return false;
  };

  getHelperText = (error: FormikErrors<initialValuesTypes>, touched: FormikTouched<initialValuesTypes>, fieldKey: keyof initialValuesTypes) => {
    if (error?.[fieldKey] && touched?.[fieldKey]) {
      return error?.[fieldKey];
    }
    return "";
  };

  formatDate = (date: Date) => {
    var dateObj = new Date(date),
      month = '' + (dateObj.getMonth() + 1),
      days = '' + dateObj.getDate(),
      year = dateObj.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (days.length < 2)
      days = '0' + days;

    return [year, month, days].join('-');
  }

  isValidDate = (date: MaterialUiPickersDate) => {
    return date instanceof Date;
  };

  handleOnChangeValidFrom = (
    date: MaterialUiPickersDate,
    setFieldValue: FormikHelpers<initialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    if (this.isValidDate(date)) {
      setFieldValue(configJSON.validFrom, date);
    } else {
      setFieldValue(configJSON.validFrom, "");
    }
  };

  handleOnChangeValidTo = (
    date: MaterialUiPickersDate,
    setFieldValue: FormikHelpers<initialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    if (this.isValidDate(date)) {
      setFieldValue(configJSON.validTo, date);
    } else {
      setFieldValue(configJSON.validTo, "");
    }
  };

  userLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: FormikHelpers<initialValuesTypes>["setFieldValue"]) => {
    this.props.openToast();
    setFieldValue("userLimit", event.target.value.replace(/\D/g, ""));
  }

  Schema = Yup.object().shape({
    promoCodeName: Yup.string()
      .max(30, configJSON.promoCodeNameValidation)
      .required(configJSON.requiredField),
    description: Yup.string()
      .max(50, configJSON.promoCodeDescriptionValidation)
      .required(configJSON.requiredField),
    code: Yup.string()
      .max(10, configJSON.promoCodeValidation)
      .required(configJSON.requiredField),
    discountType: Yup.string().required(configJSON.requiredField),
    discount: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeDiscountValidation1)
      .max(100000, configJSON.promoCodeDiscountValidation2),
    userLimit: Yup.number()
      .typeError(configJSON.promoCodeUserLimitValidation1)
      .min(1, configJSON.promoCodeUserLimitValidation2)

      .nullable(undefined),

    minCartValue: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeMinCartValueValidation1)
      .max(99999, configJSON.promoCodeMinCartValueValidation2),
    maxCartValue: Yup.lazy(() =>
      Yup.number()
        .when('minCartValue', (minCartValue: any, schema) =>
          minCartValue
            ? schema
              .required(configJSON.requiredField)
              .positive()
              .min(minCartValue, configJSON.promoCodeMaxCartValueValidation3)
              .max(100000, configJSON.promoCodeMaxCartValueValidation2)
            : schema
              .required(configJSON.requiredField)
              .positive()
              .min(1, configJSON.promoCodeMaxCartValueValidation1)
              .max(100000, configJSON.promoCodeMaxCartValueValidation2)
        )
    ),

    validFrom: Yup.date()
      .transform((curr, orig) => curr.toString() === configJSON.inValidDateTxt ? new Date() : (orig === "" ? null : (typeof curr === "string" ? new Date(curr) : curr)))
      .test(configJSON.valid_checkDateInPast, configJSON.pastDate,
        (dateObj) => {
          return !!(dateObj && dateObj > new Date(Date.now() - 86400000));
        })
      .required(configJSON.promoCodeValidFromValidation),
    validTo: Yup.date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(configJSON.promoCodeValidToValidation)
      .min(Yup.ref(configJSON.validFrom), configJSON.promoCodeValidToValidation2),
  });

  Schema1 = Yup.object().shape({
    promoCodeName: Yup.string()
      .max(30, configJSON.promoCodeNameValidation)
      .required(configJSON.requiredField),
    description: Yup.string()
      .max(50, configJSON.promoCodeDescriptionValidation)
      .required(configJSON.requiredField),
    code: Yup.string()
      .max(10, configJSON.promoCodeValidation)
      .required(configJSON.requiredField),
    discountType: Yup.string().required(configJSON.requiredField),
    discount: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeDiscountValidation1)
      .max(100000, configJSON.promoCodeDiscountValidation2),
    userLimit: Yup.number()
      .typeError(configJSON.promoCodeUserLimitValidation1)
      .min(1, configJSON.promoCodeUserLimitValidation2)

      .nullable(undefined),

    minCartValue: Yup.number()
      .required(configJSON.requiredField)
      .positive()
      .min(0, configJSON.promoCodeMinCartValueValidation1)
      .max(99999, configJSON.promoCodeMinCartValueValidation2),
    maxCartValue: Yup.lazy(() =>
      Yup.number()
        .when('minCartValue', (minCartValue: any, schema) =>
          minCartValue
            ? schema
              .required(configJSON.requiredField)
              .positive()
              .min(minCartValue, configJSON.promoCodeMaxCartValueValidation3)
              .max(100000, configJSON.promoCodeMaxCartValueValidation2)
            : schema
              .required(configJSON.requiredField)
              .positive()
              .min(1, configJSON.promoCodeMaxCartValueValidation1)
              .max(100000, configJSON.promoCodeMaxCartValueValidation2)
        )
    ),

    validFrom: Yup.date()
      .transform((curr, orig) => curr.toString() === configJSON.inValidDateTxt ? new Date() : (orig === "" ? null : (typeof curr === "string" ? new Date(curr) : curr))),
    validTo: Yup.date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required(configJSON.promoCodeValidToValidation)
      .min(Yup.ref(configJSON.validFrom), configJSON.promoCodeValidToValidation2),
  });

  handleSubmit = (data: initialValuesTypes) => {
    const { onSubmit } = this.props;
    const promoCode = {
      title: data.promoCodeName,
      description: data.description,
      code: data.code,
      discount_type: data.discountType,
      discount: data.discount,
      valid_from: this.formatDate(data.validFrom),
      valid_to: this.formatDate(data.validTo),
      min_cart_value: data.minCartValue,
      max_cart_value: data.maxCartValue,
      limit: data.userLimit || null,
    };
    onSubmit(promoCode);
  };

  onTagsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: FormikHelpers<initialValuesTypes>["setFieldValue"]
  ) => {
    this.props.openToast();
    setFieldValue(event.target.name, event.target.value);
  };
}
// Customizable Area End
