// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
const baseURL = require("../../framework/src/config.js").baseURL;

// Create a new link element for the favicon
let icon = document.createElement('link');
icon.rel = "shortcut icon"
  icon.href = `${baseURL}/${'bx_block_catalogue/brands/header_image'}`
  let head = document.querySelector("head")
  if (head) {
      head.prepend(icon)
  }
  console.log("baseURL",`${baseURL}/${'bx_block_catalogue/brands/header_image'}`)


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
