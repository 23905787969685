import React from "react";
// Customizable Area Start
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Box,
  TextField,
  Typography,
  Container,
  CardMedia,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import CustomerController, { CustomCustomer } from "./CustomerController";
import Loader from "../../../components/src/Loader.web";
import { viewIcon, searchIcon } from "./assets"
import InputAdornment from "@material-ui/core/InputAdornment";
import NoCustomer from "./NoCustomer.web";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";

const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomerCustomTableRow = withStyles(() => ({
  root: {
    borderBottom: "4px solid #ECEDF0",
    "&:last-child": {
      borderBottom: "none",
    },
    margin: "10px 0",
  },
  hover: {
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
}))(TableRow);

const CustomerCustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "transparent",
  },
}))(TableCell);

// Customizable Area End

export default class AdminCustomer extends CustomerController {
  render() {
    // Customizable Area Start
    const { customerList, loading, showList, pagination } = this.state;

    return (
      //Merge Engine DefaultContainer
      <>
        <div>
          <ThemeProvider theme={theme}>
            <Container style={{ width: "100%", maxWidth: "100%" }}>
              {this.state.customerError != "" ? 
              <>
              <Typography
                variant="h4"
                data-test-id="admin-contact"
                style={webStyle.customButton2}
                onClick={() => this.props.navigation.navigate("CustomerAdminList/AdminContact")}
              >
                {configJSON.inboundQueries}
              </Typography>
                <Card style={webStyle.customBox}>
                  <Typography style={webStyle.title}>You have no customers</Typography>

                  <Typography style={webStyle.paragraph}>
                    You don't have any customers at the moment. They'll start <br />appearing here once you get orders in.
                  </Typography>
                </Card>
              </>
             :
              <Box py={4} style={{ margin: "auto" }}>
                {!loading && customerList && customerList.length > 0 && 
                <>
                  <Box style={webStyle.subContainerOne}>
                      <Box style={{
                        display: "flex", justifyContent: "space-between"
                      }}>
                        <Typography
                          style={{
                            ...webStyle.tableHeading
                          }}
                        >
                          Customers
                        </Typography>
                      </Box>
                      <Box style={{
                        display: "flex", justifyContent: "space-between"
                      }}>
                        <Typography
                          variant="h4"
                          data-test-id="admin-contact"
                          style={webStyle.customButton}
                          onClick={() => this.props.navigation.navigate("CustomerAdminList/AdminContact")}
                        >
                          Inbound queries
                        </Typography>
                        {this.state.isCsvDownloading ? <Box width="100px" display="flex" justifyContent="center"><CircularProgress size="24px" /></Box> : <Typography
                          data-test-id="download-csv"
                          variant="h4"
                          style={webStyle.customButton}
                          onClick={this.download}
                        >
                          Download CSV
                        </Typography>}
                      </Box>
                  </Box>
                  <Box style={{ marginBottom: "10px" }}>
                    <TextField
                      data-test-id="search-input"
                      variant="outlined"
                      fullWidth
                      placeholder="Search customers"
                      InputProps={{
                        style: {
                          borderColor: "#D0D2DA",
                          borderRadius: "3px",
                          background: "#FFFFFF",
                          height: "41px",
                          fontFamily: "Rubik",
                          fontWeight: 400,
                          lineHeight: "24px",
                          fontSize: "14px"
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={searchIcon} alt="searchIcon" />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: webStyle.searchPlaceholder,
                      }}
                      value={this.state.searchBy}
                      onChange={this.handleFilterCustomerList}
                    />
                  </Box>
                </>}
                {loading ? <Loader loading={loading} /> : showList && customerList && customerList.length > 0 ? (
                  <Box style={webStyle.subContainerTwo}>
                    <TableContainer
                      component={Paper}
                      style={{ boxShadow: "none", border: "none" }}
                    >
                      <Table aria-label="contact table">
                        <TableHead style={webStyle.tableHeader}>
                          <TableRow>
                            <CustomerCustomTableCell style={{ ...webStyle.cellHeading, padding: "0px 35px" }}>
                              #
                            </CustomerCustomTableCell>
                            <CustomerCustomTableCell style={webStyle.cellHeading}>
                              Full name
                            </CustomerCustomTableCell>
                            <CustomerCustomTableCell style={webStyle.cellHeading}>
                              Email
                            </CustomerCustomTableCell>
                            <CustomerCustomTableCell style={webStyle.cellHeading}>
                              Number
                            </CustomerCustomTableCell>
                            <CustomerCustomTableCell style={webStyle.cellHeading}>
                              View
                            </CustomerCustomTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={webStyle.tableBodyDesign}>
                          {customerList?.map((item: CustomCustomer, index: number) => (
                            <CustomerCustomTableRow key={item.id} style={webStyle.tableRowDesign}>
                              <TableCell data-test-id={`customer-id-${index}`} style={{ ...webStyle.cellContent, padding: "0px 35px" }}>{item.id}</TableCell>
                              <TableCell data-test-id={`name-${index}`} style={{ ...webStyle.cellContent, width: "20%" }}>
                                {item.attributes.full_name}
                              </TableCell>
                              <TableCell data-test-id={`email-${index}`} style={{ ...webStyle.cellContent, width: "20%" }}>
                                {item.attributes.email}
                              </TableCell>
                              <TableCell data-test-id={`number-${index}`} style={{ ...webStyle.cellContent, width: "35%" }}>
                                {item.attributes.full_phone_number ? item.attributes.full_phone_number : '-'}
                              </TableCell>
                              <TableCell data-test-id={`viewBtn-${index}`} style={{ width: "7%" }}>
                                <Box
                                  onClick={() => {this.handleNavigateCustomerAdminList(item.id)}}
                                  data-test-id={`view-${index}`}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img src={viewIcon} alt="viewIcon" />
                                </Box>
                              </TableCell>
                            </CustomerCustomTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {(!!pagination?.total_count) && 
                    <Box marginBottom={1.5}><Pagination
                                                count={pagination?.total_count}
                                                onChange={this.handlePageChange}
                                                pageSize={pagination?.per_page}
                                                itemText={configJSON.paginationText}
                                                pageNo={pagination?.current_page || 1}
                                            /></Box>
                                            }
                  </Box>
                ) :
                  (
                    <>
                      {this.state.search ? <Card style={webStyle.noServicesCard}>
                        <CardMedia
                          component="img"
                          image={`${require("../assets/Not-found.png")}`}
                          style={webStyle.notFoundImage}
                        />
                        <Typography style={webStyle.noResultsFound}>
                          No Results Found!
                        </Typography>
                        <Typography style={webStyle.pleaseRefineYourSearch}>
                          Please refine your search.
                        </Typography>
                      </Card> :
                        (<NoCustomer navigation={undefined} identifier={""} />)
                      }
                    </>
                  )}
              </Box>
             }
            </Container>
          </ThemeProvider>
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  noServicesCard: {
    flex: "1 1 75%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    height: '210px',
    width: '280px',
    margin: '50px auto' as 'auto',
  },
  notFoundImage: {
    width: "103px",
  },
  noResultsFound: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#000000",
    marginTop: theme.spacing(3),
    fontFamily: "Rubik",
  },
  pleaseRefineYourSearch: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#83889E",
    marginTop: theme.spacing(1),
  },
  subContainerOne: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto"
  },
  subContainerTwo: {
    justifyContent: "end"
  },
  cellHeading: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
  },
  cellContent: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#3C3E49",
  },
  inputLabel: {
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
  },
  input: {
    fontFamily: "Rubik",
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "24px",
    height: "auto",
    borderRadius: "3px",
  },
  deleteButton: {
    color: "#FF1744",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 500,
    textDecoration: "underline solid #FF1744",
    marginTop: "10px",
    cursor: "pointer",
    marginLeft: "30px",
    lineHeight: "20px"
  },
  tableBodyDesign: {
    border: "4px solid #ECEDF0",
  },
  tableRowDesign: {
    border: "4px solid #ECEDF0",
  },
  tableHeading: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    paddingBottom: "50px",
  },
  tableHeader: {
    backgroundColor: "#F8F9FA",
    border: "4px solid #F8F9FA",
  },
  customButton: {
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    textDecoration: "underline solid #3C3E49",
    marginTop: "10px",
    cursor: "pointer",
    marginLeft: "30px",
    lineHeight: "20px"
  },
  customButton2: {
    color: "#3C3E49",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    textDecoration: "underline solid #3C3E49",
    marginTop: "10px",
    cursor: "pointer",
    marginLeft: "30px",
    lineHeight: "20px",
    textAlign: "end" as "end"
  },
  searchPlaceholder: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#AFB2C0",
    lineHeight: "24px"
  },
  title: {
    height: "36px",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
    margin: "20px 0"
  },
  paragraph: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#676B7E",
    marginBottom: "50px",
    textAlign: "center" as "center",
    padding: "0 14px"
  },
  customBox: {
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
    borderRadius: "8px",
    background: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    top: "45%",
    left: "50%",
    alignItems: "center",
    justifyContent: "center",
    padding: 24
  },
};
// Customizable Area End
