// Customizable Area Start
import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"

import EmailAccountLogin from "../../EmailAccountLogin/src/Login.web";
import EmailAccountForgotPassword from "../../EmailAccountForgotPassword/src/ForgotPassword.web";
import AdminHome from "../../AdminHome/src/AdminHome.web";
import AdminEmailTemplates from "../../AdminEmailTemplates/src/EmailTemplates.web";
import AdminEmailTemplatesCreate from "../../AdminEmailTemplates/src/EmailTemplatesCreate.web";
import AdminPaymentIntegration from "../../AdminPaymentIntegration/src/Payment.web";
import AdminMobileApp from "../../AdminMobileApp/src/MobileApp.web";
import AdminStaticPage from "../../AdminStaticPage/src/StaticPageTable.web";
import AdminStaticPageCreate from "../../AdminStaticPage/src/CreateStaticPage.web";
import AdminStoreDetails from "../../AdminStoreDetails/src/StoreDetails.web";
import AdminAccount from "../../AdminAccount/src/AccountDetails.web";
import AdminCustomerFeedback from "../../AdminCustomerFeedback/src/CustomerFeedback.web";
import AdminCustomerFeedbackCreate from "../../AdminCustomerFeedback/src/CustomerFeedbackCreate.web";

import AdminContact from "../../contactus/src/AdminContact.web";
import CategoriessubcategoriesWeb from "../../categoriessubcategories/src/Categoriessubcategories.web";
import GalleryGrid from "../../photolibrary/src/GalleryGrid.web";
import GalleryLists from '../../photolibrary/src/GalleryLists.web';
import GalleryView from '../../photolibrary/src/GalleryView.web';
import OrderMangementList from "../../ordermanagement/src/OrderManagementList.web";
import OrderManagementDetails from '../../ordermanagement/src/OrderManagementDetails.web';
import DashboardAdminWrapper from "../../dashboardAdmin/src/DashboardAdminWrapper.web";
import CommonSettingsAdminWrapper from "../../commonSettingsAdmin/src/CommonSettingsAdminWrapper.web";
import CustomerAdminList from "../../customerAdmin/src/AdminCustomer.web";
import ServicesListWrapper from "../../servicemanagementAdmin/src/ServicesListWrapper.web";
import ServiceAdminWrapper from '../../servicemanagementAdmin/src/ServiceWrapper.web';
import ImageUpload from '../../photolibrary/src/ImageUpload.web';
import ViewCustomer from '../../customerAdmin/src/ViewCustomer.web';
import AddStaffWrapper from '../../accountgroups/src/addStaffWrapper.web';
import AdminStaffList from "../../accountgroups/src/AdminStaffList.web";
import PromoCode from '../../AdminPromoCode/src/PromoCode.web';
import PromoCodeCreate from '../../AdminPromoCode/src/PromoCodeCreate.web';

class WebRoutes extends Component {
  render() {
    return (

      <BrowserRouter basename="/admin">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/Home" />} />
        <Route exact path="/Home" component={AdminHome} />
        <Route exact path="/EmailAccountLogin" component={EmailAccountLogin} />
        <Route
          exact
          path="/EmailAccountForgotPassword"
          component={EmailAccountForgotPassword}
        />
        <Route
          exact
          path="/AdminEmailTemplates"
          component={AdminEmailTemplates}
        />
        <Route
          //exact
          path="/AdminEmailTemplatesCreate/:id"
          component={AdminEmailTemplatesCreate}
        />
        <Route exact path="/AdminStaticPage" component={AdminStaticPage} />
        <Route
          exact
          path="/AdminStaticPageCreate/:id?"
          component={AdminStaticPageCreate}
        />
        <Route
          exact
          path="/AdminPaymentIntegration"
          component={AdminPaymentIntegration}
        />
        <Route exact path="/AdminMobileApp" component={AdminMobileApp} />
        <Route
          exact
          path="/AdminCustomerFeedback"
          component={AdminCustomerFeedback}
        />
        <Route
          exact
          path="/AdminCustomerFeedbackCreate/:id?"
          component={AdminCustomerFeedbackCreate}
        />

        <Route exact path="/AdminStoreDetails" component={AdminStoreDetails} />
        
        <Route exact path="/AdminAccount" component={AdminAccount} />

        <Route exact path="/CategoriessubcategoriesWeb" component={withLayout(CategoriessubcategoriesWeb)} />

        <Route exact path="/OrderMangementList" component={withLayout(OrderMangementList)} />
        <Route exact path="/OrderMangementList/OrderManagementDetails" component={withLayout(OrderManagementDetails)} />

        <Route exact path="/DashboardAdmin" component={withLayout(DashboardAdminWrapper)} />
        <Route exact path="/CommonSettingsAdmin" component={withLayout(CommonSettingsAdminWrapper)} />

        <Route exact path="/AdminStaff" component={withLayout(AdminStaffList)} />
        <Route exact path="/AdminStaffCreate" component={withLayout(AddStaffWrapper)} />
        <Route exact path="/AdminStaffCreate/:id" component={withLayout(AddStaffWrapper)} />

        <Route exact path="/AdminPromoCode" component={withLayout(PromoCode)} />
        <Route exact path="/AdminPromoCodeCreate" component={withLayout(PromoCodeCreate)} />
        <Route exact path="/AdminPromoCodeCreate/:id" component={withLayout(PromoCodeCreate)} />

        <Route exact path="/CustomerAdminList" component={withLayout(CustomerAdminList)} />
        <Route exact path="/CustomerAdminList/AdminContact" component={withLayout(AdminContact)} />
        <Route exact path="/CustomerAdminList/:id/:page" component={withLayout(ViewCustomer)} />

        <Route exact path="/ServicesManagement" component={withLayout(ServicesListWrapper)} />
        <Route exact path="/ServicesManagement" component={withLayout(ServicesListWrapper)} />
        <Route exact path="/ServicesManagement/ServiceManagement" component={withLayout(ServiceAdminWrapper)} />
        <Route exact path="/ServicesManagement/ServiceManagement/:id" component={withLayout(ServiceAdminWrapper)} />

        <Route exact path="/Gallery" render={() => <Redirect to="/Gallery/Grid" />} />
        <Route exact path="/Gallery/Grid" component={withLayout(GalleryGrid)} />
        <Route exact path="/Gallery/ImageUpload" component={withLayout(ImageUpload)} />
        <Route exact path="/Gallery/List" component={withLayout(GalleryLists)} />
        <Route exact path="/Gallery/GalleryView" component={withLayout(GalleryView)} />



      </Switch>
        </BrowserRouter>
    );
  }
}

export default WebRoutes;
// Customizable Area End
