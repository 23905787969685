// Customizable Area Start
import React, { Suspense } from "react";
import PaymentController from "./PaymentController.web";
import PaymentMethods  from "./PaymentMethods.web";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import Header from "../../ss-cms-common-components/src/Header/Header.web";
import MuiTab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {withStyles, createStyles, Theme, CircularProgress} from "@material-ui/core";

//CCAvenue Payment
const CCAvenueCard = React.lazy(() => import('../../AdminCcavenuePayment/src/CCAvenueCard.web'));
//Strip Payment
const StripPaymentCard = React.lazy(() => import('../../AdminStripePayment/src/StripPaymentCard.web'));

const configJSON = require("./config");

export class Payment extends PaymentController {

  renderShippingTabsCompontents(tabIndex: number) {
    const { classes } = this.props;
    if (tabIndex === 1) {
      return (
        <PaymentMethods
          setDialogState={this.openErrorHeaderBar}
          hideLoader={this.props.hideLoader}
          showLoader={this.props.showLoader}
          showHeader={this.props.showHeaderBar}
          paymentMethods={this.state.paymentMethods}
          setPaymentMethods={this.setPaymentMethods}
        />
      );
    } else if (tabIndex === 0) {
      return (
        <div className={classes.paymentGatewayContainer}>
          {
            // this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === "3")?.attributes.active && this.state.countryData?.country_code !== "in" &&
            <Suspense fallback={<CircularProgress/>}>
              <StripPaymentCard
                setDialogState={this.openErrorHeaderBar}
                hideLoader={this.props.hideLoader}
                showLoader={this.props.showLoader}
                showHeader={this.props.showHeaderBar}
              />
            </Suspense>
          }
          {
            // this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === "3")?.attributes.active && this.state.countryData?.country_code === "in" &&
            <Suspense fallback={<CircularProgress/>}>
              <CCAvenueCard
                setDialogState={this.openErrorHeaderBar}
                hideLoader={this.props.hideLoader}
                showLoader={this.props.showLoader}
                showHeader={this.props.showHeaderBar}
              />
            </Suspense>
          }
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { pageInitialized, view } = this.state;
    if (!pageInitialized) {
      return null;
    }

    const isPaymentGatewayTabVisible = (
      this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === "2")?.attributes.active ||
      this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === "3")?.attributes.active
    );
    return (
      <div className={classes.paymentContainer}>
        <Header title={configJSON.payment} />
        <div className={classes.innerPayment}>
          <div className={classes.paymentTab}>
            <Tabs
              value={view}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabContainer}
            >
              {false && <MuiTab
                className={
                  view === 0 ? classes.tabIconWrapperSelected +" "+ classes.tabIconWrapper : classes.tabIconWrapper
                }
                data-testid="list-tab"
                label={configJSON.paymentMethods}
              />}
              <MuiTab
                className={
                  view === 1 ? classes.tabIconWrapperSelected +" "+ classes.tabIconWrapper : classes.tabIconWrapper
                }
                data-testid="card-tab"
                label={configJSON.paymentGateway}
              />
              )
            </Tabs>
          </div>
          {this.renderShippingTabsCompontents(view)}
        </div>
      </div>
    );
  }
}
export const styles = (theme: Theme) =>
  createStyles({
    paymentContainer: {
      "display": "flex",
      "flexDirection": "column",
      "overflow": "auto",
      "height": "100%",
      "background": "#f8f9fa"
    },
    innerPayment: {
      "display": "flex",
      "flexDirection": "column",
      "width": "100%",
      "padding": "0 24px 40px",
      "gap": "30px",
      "marginTop": "40px"
    },
    paymentTab: {
      "flex": 1,
      "marginBottom": "2em !important"
    },
    tabContainer:{
      "display": "flex",
      "gap": "16px",
      "borderBottom": "3px solid #e8e8e8",
      "height": "35px",
      "minHeight": "30px"
    },
    tabIconWrapper: {
      "flexDirection": "row",
      "fontSize": "12px",
      "fontWeight": 400,
      "lineHeight": "10px",
      "textTransform": "capitalize",
      "minHeight": "28px"
    },
    tabIconWrapperSelected:{
      "fontWeight": 600
    },
    paymentGatewayContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 60,
    }
  });
export default withLayout(withStyles(styles)(withDialog(withLoader(withHeaderBar(Payment)))));
// Customizable Area End
