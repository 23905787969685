// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { Navigation } from "../../utilities/src/models/Navigation";
import StorageProvider from "../../../framework/src/StorageProvider.web";

export const configJSON = require("./config");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: Navigation;
    identifier: string;
    classes: Record<string, string>;
    // Customizable Area End
}

// Customizable Area Start

export interface StaffDetails {
    id: string;
    type: string;
    attributes: {
        id: number;
        full_name: string;
        designation: string;
        images: Array<{
            id: number;
            url: string;
            small_url: string;
            thumbnail_url: string;
        }>
    }
}

export interface AllServiceList {
    data: Array<AllServiceArray>
}

export interface AllServiceArray {
    id: string;
    type: string;
    attributes: {
        title: string;
        description: string;
        duration: number;
        status: true;
        discount_option: false;
        discount: number;
        payment_preferences: string;
        price: number;
        actual_price: number;
        category: {
            id: number;
            name: string
        };
        currency: {
            id: number;
            name: string;
            symbol: string
        };
        images: [
            {
                id: number;
                url: string;
                small_url: string;
                thumbnail_url: string;
            }
        ]
    }
}

export interface Pagination {
    current_page: number;
    next_page: number;
    prev_page: number;
    total_pages: number;
    total_count: number;
    current_count: number;
    per_page: number;
}
export interface StaffListingData {
    data: Array<StaffDetails>,
    metadata: {
        meta: {
            pagination: Pagination;
        }
    }
}
export interface CatObj {
    id: string;
    type: string;
    attributes: {
        count: number;
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
        service_providers_count: number;
        image_url: string;
    }
}
export interface Catagoury {
    data: Array<CatObj>;
    metadata: {
        meta: {
            pagination: Pagination;
        }
    }
}
interface Brand {
    data: {
        attributes: {
            header: {
                navigation_item_2: string
            }
        }
    }
}
// Customizable Area End

interface S {
    // Customizable Area Start
    staffList: Array<StaffDetails>;
    categories: CatObj[];
    selectedCategories: number[];
    tempSelectedCategories: number[];
    loading: boolean;
    searchQuery: string;
    pagination?: Pagination;
    isFilterDrawerOpen: boolean;
    screenSize: number;
    searchListService: Array<AllServiceArray>;
    renameItem2: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    identifier: string;
    // Customizable Area End
}

export default class CataloguesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getStaffListingApiCallId: string = "";
    getAllCategoriesApiCallId: string = "";
    searchCataloguesApiCallId: string = "";
    searchStaffApiCallId: string = "";
    getBrandNameApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            // Customizable Area Start
            staffList: [],
            categories: [],
            selectedCategories: [],
            tempSelectedCategories: [],
            loading: true,
            searchQuery: "",
            isFilterDrawerOpen: false,
            searchListService: [],
            screenSize: window.innerWidth,
            renameItem2: ""
            // Customizable Area End
        };

        this.handleResize = this.handleResize.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        const searchQuery = this.props.navigation.getParam("searchQuery");
        if (searchQuery) {
            this.setState({searchQuery : searchQuery.toString()})
            this.searchServices(searchQuery as string);
            this.searchAllServices(searchQuery as string);
        } else {
            this.getAllStaffList();
        }
        window.addEventListener("resize", this.handleResize);
        this.getAllCategories();
        this.getBrandName();
        mixpanel.track(configJSON.navigateStaffListing);
        // Customizable Area End
    }

    async componentWillUnmount() {
        // Customizable Area Start
        window.removeEventListener("resize", this.handleResize);
        // Customizable Area End
    }

    // Customizable Area Start
    handleResize() {
        this.setState({ screenSize: window.innerWidth });
    };

    async componentDidUpdate(prevProps: Props, prevState: S) {
        const searchQuery = this.props.navigation.getParam("searchQuery");
        if(searchQuery && this.state.searchQuery !== searchQuery){
            this.searchServices(searchQuery as string);
            this.searchAllServices(searchQuery as string);
        }
    }

    receive = async (from: string, message: Message) => {
        if (
            (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
                this.getStaffListingApiCallId != null &&
                this.getStaffListingApiCallId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handleGetAllStaff(responseJson);
            } else {
                let responseMeta = responseJson.metadata?.meta?.pagination;
                this.setState({ staffList: [], loading: false, pagination: responseMeta });

                const errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );

                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllCategoriesApiCallId != null &&
            this.getAllCategoriesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handleGetAllCategories(responseJson);
            } else {
                this.setState({ categories: [] });

                const errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );

                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.searchStaffApiCallId != null &&
            this.searchStaffApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.searchStaffSuccessCallBack(responseJson);
            } else if ((responseJson && !responseJson.errors && responseJson.message)) {
                this.setState({ staffList: [], loading: false });
            } else {
                this.setState({ staffList: [] });

                const errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );

                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.searchCataloguesApiCallId != null &&
            this.searchCataloguesApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handlesearchServiceResponse(responseJson);
            } else if ((responseJson && !responseJson.errors && responseJson.message)) {
                this.setState({ searchListService: [], loading: false });
            } else {
                this.setState({ searchListService: [] });

                const errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );

                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBrandNameApiCallId != null &&
            this.getBrandNameApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.brandNameSuccessCallBack(responseJson);
            }
        }
    };

    handleGetAllStaff = (responseJson: StaffListingData) => {
        mixpanel.track(configJSON.viewServiceProviderListEvent)
        this.setState({
            staffList: responseJson.data,
            pagination: responseJson.metadata?.meta?.pagination,
            loading: false
        });
    };

    handleGetAllCategories = (responseJson: Catagoury) => {
        responseJson.data.map((item) => {
            item.attributes.count = item.attributes.service_providers_count
        })
        this.setState({
            categories: responseJson.data
        });
    };

    searchAllServices = (queryRef: string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        this.searchCataloguesApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getSearchStaffData}&query=${queryRef}&show_all=${true}`,
            header,
        });
    };

    handlesearchServiceResponse = (responseData: AllServiceList) => {
        this.setState({ loading: false, searchListService: responseData.data });
    }

    getAllStaffList = (page = 1) => {
        this.setState({ loading: true, searchQuery: "" });
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        let urlParams = this.state.selectedCategories.length
            ? `[category_ids]=${this.state.selectedCategories.join(",")}`
            : null;

        this.getStaffListingApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: `${urlParams ?
                configJSON.getSearchCategoriesEndPoint + "?" + urlParams + "&page=" + page + "&per_page=12" :
                configJSON.getStaffListingApiEndPoint + "?page=" + page + "&per_page=12"
                }`,
            header,
        });
    };

    getAllCategories = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        this.getAllCategoriesApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: configJSON.getCategoriesEndPoint,
            header,
        });
    };

    selectCategory = (identifier: number, updateCategories: boolean) => {
        mixpanel.track(configJSON.selectCategoryServiceProviderEvent)
        this.setState((prevState) => ({
            tempSelectedCategories: prevState.tempSelectedCategories.includes(
                identifier
            )
                ? prevState.tempSelectedCategories.filter(
                    (selectedCategory) => selectedCategory !== identifier
                )
                : [...prevState.tempSelectedCategories, identifier],
        }));

        updateCategories &&
            this.setState((prevState) => ({
                selectedCategories: prevState.selectedCategories.includes(identifier)
                    ? prevState.selectedCategories.filter(
                        (selectedCategory) => selectedCategory !== identifier
                    )
                    : [...prevState.selectedCategories, identifier],
            }), this.getAllStaffList);
    };

    resetCategoriesSelected = () => {
        this.setState({ tempSelectedCategories: [] });
    };

    discardCategoriesSelected = () => {
        this.setState((prevState) => ({
            tempSelectedCategories: [...prevState.selectedCategories],
        }));
    };

    applyCategoriesSelected = () => {
        this.setState((prevState) => ({
            selectedCategories: [...prevState.tempSelectedCategories],
        }), () => {
            this.getAllStaffList();
        });
    };

    handlePageChange = (page: number) => {
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"})
        },200)
        this.getAllStaffList(page);
    }

    goBack = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), `Home`);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    toggleFilterDrawer = () => {
        this.setState({ isFilterDrawerOpen: !this.state.isFilterDrawerOpen })
    }

    navigateStaffDetailPage = (staffListID: string) => {
        mixpanel.track(configJSON.staffMemberSelectedEvent, {
            itemId: staffListID
        })
    }

    handleBookNow = (catalogueId: string) => {
        mixpanel.track(configJSON.selectServicesItemEvent, { itemId: catalogueId });
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Appointments");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
        StorageProvider.set("catalogueId", catalogueId);
        StorageProvider.set("navigationType", "serviceProvider");
    };

    searchServices = (searchQuery: string, page = 1) => {
        this.setState({ loading: true, searchQuery });
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const attrs = {
            query: searchQuery,
            status: "active",
        };
        const queryParams = new URLSearchParams(attrs).toString();

        this.searchStaffApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: `${configJSON.getSearchStaffEndPoint}?${queryParams}`,
            header,
        });
    };

    searchStaffSuccessCallBack = (responseJson: StaffListingData) => {
        mixpanel.track(configJSON.viewServiceProviderListEvent)
        this.setState({
            loading: false,
            staffList: responseJson.data,
            pagination: responseJson.metadata?.meta?.pagination
        });
    };

    deleteChip = () => {
        this.props.navigation.goBack();
    };

    getBrandName = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        this.getBrandNameApiCallId = BlockHelpers.callApi({
            method: configJSON.getApiMethod,
            endPoint: configJSON.getBrandEndPoint,
            header,
        });
    };

    brandNameSuccessCallBack = (responseJson: Brand) => {
        this.setState({
          renameItem2: responseJson.data.attributes.header.navigation_item_2
        });
    };
}
// Customizable Area End
