// Customizable Area Start
import React, { ChangeEvent, useEffect, useRef, useState, Suspense } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Button,
  Switch,
  Typography,
  CardMedia,
  Slide,
  Divider,
  Checkbox,
  List,
  ListItem,
  SvgIcon,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  withStyles,
  Theme,
  Grid
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useStyles } from "./styles/DashboardAdmin.web";
const ImageCard = React.lazy(() => import("../../utilities/src/components/ImageCard.web"));
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { Brand } from "../../utilities/src/models/Brand";
import { ReactComponent as CheckboxIcon } from "../assets/Checkbox.svg";
import { ReactComponent as CheckboxIconChecked } from "../assets/Checkbox-checked.svg";
import { Platform } from "../../utilities/src/models/Platform";
import { countries } from "./Countries";
import { Banner } from "../../utilities/src/models/Banner";
import AddCropper from "../../ss-cms-common-components/src/AddCropper/AddCropper.web";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import infoImage from "../assets/group_.png";

const configJSON = require("./config")

const validationSchema = Yup.object().shape({
  logo: Yup.mixed().required("Logo is required"),
  storeName: Yup.string(),
  websiteTitle: Yup.string()
  .max(60, "Website title must be at most 60 characters."),
  countryCode: Yup.string(),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test("phone", "Invalid Phone Number", function () {
      const { from } = this;
      if (from && from.length) {
        const phoneRegex =
          /^(?:(?:\+44\s?|\+1\s?|\+91\s?)?\-?\s?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}|\+971\s?\(?\d{1,2}\)?[\s]?\d{3}[\s]?\d{4})$/;
        const isValidPhone = phoneRegex.test(
          from[0].value.countryCode + from[0].value.phoneNumber
        );
        if (!isValidPhone) {
          return false;
        }
        return true;
      }
    }),
  copyright: Yup.string()
    .required("Copyright is required")
    .max(55, "Copyright must be at most 55 characters."),
  socialMedias: Yup.object().shape({
    facebook: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    instagram: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    twitter: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    youtube: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
  }),
  applications: Yup.object().shape({
    android: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
    ios: Yup.object().shape({
      selected: Yup.boolean(),
      url: Yup.string().when("selected", {
        is: true,
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    }),
  }),

  banners: Yup.array().of(
    Yup.object().shape({
      image: Yup.mixed()
        .nullable()
        .test("required", "Image is required", function () {
          const { from } = this;
          if (from && from.length) {
            if (from[1].value.banners[0] === from[0].value) {
              return !!from[0].value.image;
            }
            return true;
          }
        }),
      url: Yup.string().nullable().when("image", {
        is: (image: any) => image && (image instanceof File || Boolean(image.url)),
        then: (schema) =>
          schema.required("URL is required").url("Invalid URL format"),
      }),
    })
  ),

  testimonial: Yup.boolean(),
  renameItem1: Yup.string()
    .max(20, "Navigation item 1  must be at most 20 characters."),
  renameItem2: Yup.string()
    .max(20, "Navigation item 2 must be at most 20 characters."),
  renameItem3: Yup.string()
    .max(20, "Navigation item 3 must be at most 20 characters."),
  testimonialName: Yup.string()
    .max(20, "Testimonials must be at most 20 characters."),
});

export interface Props {
  brand: Brand | null;
  isSaved: boolean;
  createFavicon:()=>void;
  upsertBrand: (
    values: any,
    isUpdate: boolean,
    deletedImagesIds?: number[]
  ) => void;
}

const DashboardAdmin: React.FC<Props> = ({ brand, upsertBrand,createFavicon, isSaved = false }) => {
    const logoInputRef = useRef<HTMLInputElement>(null);
    const faviconInputRef = useRef<HTMLInputElement>(null);
  const bannersInputRef = Array.from({ length: 3 }, () =>
    useRef<HTMLInputElement>(null)
  );
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetButtonRef = useRef<HTMLButtonElement>(null);
  const [deletedImagesIds, setDeletedImagesIds] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValidImage,setIsImageValid] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    logo: undefined,
    favicon: undefined,
    storeName: "",
    countryCode: "+1",
    phoneNumber: "",
    copyright: "",
    socialMedias: {
      facebook: {
        selected: false,
        url: "",
      },
      instagram: {
        selected: false,
        url: "",
      },
      twitter: {
        selected: false,
        url: "",
      },
      youtube: {
        selected: false,
        url: "",
      },
    },
    applications: {
      android: {
        selected: false,
        url: "",
      },
      ios: {
        selected: false,
        url: "",
      },
    },
    banners: [
      {
        image: undefined,
        url: "",
      },
      {
        image: undefined,
        url: "",
      },
      {
        image: undefined,
        url: "",
      },
    ],
    testimonial: false,
    renameItem1: "",
    renameItem2: "",
    renameItem3:"",
    testimonialName:"",
  });
  const [selectedImage, setSelectedImage] = useState<any>(null);
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (brand) {
        upsertBrand(values, true, deletedImagesIds);
        setIsSaving(true);
        setDeletedImagesIds([]);
      } else {
        setIsSaving(true);
        upsertBrand(values, false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setValues,
    setErrors,
    setTouched,
    initialErrors,
    initialTouched,
  } = formik;

  const classes = useStyles();
  useEffect(() => {
    setInitialValues({
      logo: brand?.logo || undefined,
      favicon: brand?.favicon || undefined,
      storeName: brand?.storeName || "",
      countryCode: brand?.countryCode || "+1",
      phoneNumber: brand?.phoneNumber || "",
      copyright: brand?.copyright || "",
      websiteTitle: brand?.websiteTitle || '', 
      socialMedias: {
        facebook: {
          selected: false,
          url: "",
        },
        instagram: {
          selected: false,
          url: "",
        },
        twitter: {
          selected: false,
          url: "",
        },
        youtube: {
          selected: false,
          url: "",
        },
        ...brand?.socialMedias,
      },
      applications: {
        android: {
          selected: false,
          url: "",
        },
        ios: {
          selected: false,
          url: "",
        },
        ...brand?.applications,
      },
      banners: initialValues.banners.map((_: Banner, index: number) => ({
        image: undefined,
        url: "",
        ...brand?.banners[index],
      })),
      testimonial: brand?.testimonial || false,
      renameItem1: brand?.renameItem1 || "",
      renameItem2: brand?.renameItem2 || "",
      renameItem3: brand?.renameItem3 || "",
      testimonialName:brand?.testimonialName || ""
    });
  }, [brand]);

  useEffect(() => {
    if (isSaved) {
      setIsSaving(false);
    }
  }, [isSaved]);

  const clearFileInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = "";
    }
  };

  const upsertImage = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.click();
  };
  const upsertFavicon = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current && ref.current.click();
  };

  const removeImage = (ref: React.RefObject<HTMLInputElement>) => {
    const isImage = (obj: any): obj is ServiceImage => {
      return "id" in obj && "url" in obj;
    };
    const bannerIndex = bannersInputRef.indexOf(ref);
    if (values.banners) {
      initialValues.banners[bannerIndex].image &&
        isImage(initialValues.banners[bannerIndex].image) &&
        setDeletedImagesIds((currentIds) => {
          return [
            ...currentIds,
            (initialValues.banners[bannerIndex].image as ServiceImage).id,
          ];
        });

      const modifiedBanners: {
        image: ServiceImage | File | null;
        url: string;
      }[] = [...values.banners];

      modifiedBanners[bannerIndex] = {
        url: "",
        image: null,
      };
      setValues({ ...values, banners: modifiedBanners }, true);
    }

    
  };



  const checkImage = (
    event: ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,
    isLogo: boolean
  ) => {
    const sizeLimit = 20971520;
    const newImage: File | null =
      event &&
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files[0];

    if (newImage) {
      if (isLogo) {
        setTouched({ ...touched, logo: true }, false);
      } else {
        const bannerIndex = bannersInputRef.indexOf(ref);
        const modifiedBannersTouched = initialValues.banners.map(
          (_: Banner, index: number) => ({
            ...(touched &&
              touched.banners &&
              ((
                touched.banners as {
                  [key: string]: any;
                }[]
              )[index] as Banner)),
            image: false,
            url: false,
          })
        );
        modifiedBannersTouched[bannerIndex] = {
          ...modifiedBannersTouched[bannerIndex],
          image: true,
        };
        setTouched({ ...touched, banners: modifiedBannersTouched }, false);
      }
      if ((!newImage.type.startsWith("image/jpeg")) && (!newImage.type.startsWith("image/png")) && (!newImage.type.startsWith("image/jpg")) && (!newImage.type.startsWith("image/gif")) && (!newImage.type.startsWith("image/webp"))) {
        if (isLogo) {
          setErrors({ ...errors, logo: "Image should be a JPEG, PNG, GIF, or JPG type" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              image: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            image: "Image should be a JPEG, PNG, GIF, or JPG type",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
      if (newImage.size > sizeLimit) {
        if (isLogo) {
          setErrors({ ...errors, logo: "Image size should not be greater than 20MB" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              image: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            image: "Image size should not be greater than 20MB",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
    }

    if (isLogo) {
      setValues({ ...values, logo: newImage }, true);
    } else {
      const bannerIndex = bannersInputRef.indexOf(ref);
      setDeletedImagesIds((currentIds) =>
        currentIds.filter(
          (currentId) =>
            currentId !==
            (initialValues.banners[bannerIndex].image as ServiceImage)?.id
        )
      );
      if (values.banners) {
        const modifiedBanners = [...(values.banners as Banner[])];
        modifiedBanners[bannerIndex] = {
          ...modifiedBanners[bannerIndex],
          image: newImage!,
        };
        setValues({ ...values, banners: modifiedBanners }, true);

        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage({selectedFile: reader.result, imageSize: newImage?.size, selectedFileType: newImage?.type, fileName: newImage?.name, bannerIndex});
        };
        reader.readAsDataURL(newImage!);
      }
    }
    clearFileInput(ref);
  };
  const checkImage1 = (
    event: ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>,
    isFavicon: boolean
  ) => {
    const sizeLimit = 1048576;
    const dimensionLimit = 64;
    const newImage: File | null =
      event &&
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files[0];

    if (newImage) {
      if (isFavicon) {
        setTouched({ ...touched, favicon: true }, false);
      } else {
        const bannerIndex = bannersInputRef.indexOf(ref);
        const modifiedBannersTouched = initialValues.banners.map(
          (_: Banner, index: number) => ({
            ...(touched &&
              touched.banners &&
              ((
                touched.banners as {
                  [key: string]: any;
                }[]
              )[index] as Banner)),
            favicon: false,
            url: false,
          })
        );
        modifiedBannersTouched[bannerIndex] = {
          ...modifiedBannersTouched[bannerIndex],
          favicon: true,
        };
        setTouched({ ...touched, banners: modifiedBannersTouched }, false);
      }
      if ((!newImage.type.startsWith("image/jpeg")) && (!newImage.type.startsWith("image/png")) && (!newImage.type.startsWith("image/jpg")) && (!newImage.type.startsWith("image/gif"))) {
        if (isFavicon) {
          setErrors({ ...errors, favicon: "Image should be a JPEG, PNG, GIF, or JPG type" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              favicon: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            favicon: "Image should be a JPEG, PNG, GIF, or JPG type",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
      if (newImage.size > sizeLimit) {
        if (isFavicon) {
          setErrors({ ...errors, favicon: "Size must be less than or equal to 1MB" });
        } else {
          const bannerIndex = bannersInputRef.indexOf(ref);
          const modifiedBannersErrors = initialValues.banners.map(
            (_: Banner, index: number) => ({
              ...(errors &&
                errors.banners &&
                ((
                  errors.banners as {
                    [key: string]: any;
                  }[]
                )[index] as Banner)),
              favicon: "",
              url: "",
            })
          );
          modifiedBannersErrors[bannerIndex] = {
            ...modifiedBannersErrors[bannerIndex],
            favicon: "Size must be less than or equal to 1MB",
          };
          setErrors({ ...errors, banners: modifiedBannersErrors });
        }
        clearFileInput(ref);
        return;
      }
    }

    if (isFavicon) {
      setValues({ ...values, favicon: newImage }, true);
    } else {
      const bannerIndex = bannersInputRef.indexOf(ref);
      setDeletedImagesIds((currentIds) =>
        currentIds.filter(
          (currentId) =>
            currentId !==
            (initialValues.banners[bannerIndex].favicon as ServiceImage)?.id
        )
      );
      if (values.banners) {
        const modifiedBanners = [...(values.banners as Banner[])];
        modifiedBanners[bannerIndex] = {
          ...modifiedBanners[bannerIndex],
          favicon: newImage!,
        };
        setValues({ ...values, banners: modifiedBanners }, true);
        
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage({selectedFile: reader.result, imageSize: newImage?.size, selectedFileType: newImage?.type, fileName: newImage?.name, bannerIndex});
        };
        reader.readAsDataURL(newImage!);
      }
    }
    clearFileInput(ref);
  };

  const handleCropperClose = () => {
    const { bannerIndex } = selectedImage;
    const modifiedBanners = [...(values.banners as Banner[])];
    setSelectedImage(null);
    setValues({...values, banners: []})
    modifiedBanners[bannerIndex] = {
      ...brand?.banners[bannerIndex],
    } as Banner;
    setValues({ ...values, banners: modifiedBanners }, true);
  }

  const handleBannerSubmit = (data: string) => {
    const { bannerIndex, fileName } = selectedImage;
    const modifiedBanners = [...(values.banners as Banner[])];
    fetch(data)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], fileName,{ type: "image/png" })
        modifiedBanners[bannerIndex] = {
          ...modifiedBanners[bannerIndex],
          image: file,
        };
        setValues({ ...values, banners: modifiedBanners }, true);
      })
    setSelectedImage(null);
  }


  const displayPlatformName = (platformName: string) => {
    switch (platformName) {
      case "facebook":
        return "Facebook";
      case "instagram":
        return "Instagram";
      case "twitter":
        return "X";
      case "youtube":
        return "Youtube";
      case "android":
        return "Android App";
      case "ios":
        return "iOS App";
    }
  };

  const displayUrlOrStoreName = (platformName: string) => {
    switch (platformName) {
      case "facebook":
        return "Facebook url";
      case "instagram":
        return "Instagram url";
      case "twitter":
        return "X url";
      case "youtube":
        return "Youtube url";
      case "android":
        return "Google Playstore url";
      case "ios":
        return "App store url";
    }
  };

  const clearPlatformUrl = (isApplication: boolean, platformName: string) => {
    if (!isApplication) {
      if (values.socialMedias) {
        const modifiedSocialMedias = { ...values.socialMedias };
        modifiedSocialMedias[platformName] = {
          selected: !values.socialMedias[platformName].selected,
          ...(!values.socialMedias[platformName].selected && { url: "" }),
        };
        setValues({ ...values, socialMedias: { ...modifiedSocialMedias } });
      }

      if (touched.socialMedias) {
        const modifiedSocialMediasTouched = {
          ...(touched.socialMedias as {
            [key: string]: any;
          } as Record<string, { selected: boolean; url: boolean }>),
        };
        modifiedSocialMediasTouched[platformName] = {
          selected: true,
          url: false,
        };

        setTouched({
          ...touched,
          socialMedias: { ...modifiedSocialMediasTouched },
        });
      }
    } else {
      if (values.applications) {
        const modifiedApplications = { ...values.applications };
        modifiedApplications[platformName] = {
          selected: !values.applications[platformName].selected,
          ...(!values.applications[platformName].selected && { url: "" }),
        };
        setValues({ ...values, applications: { ...modifiedApplications } });
      }

      if (touched.applications) {
        const modifiedApplicationsTouched = {
          ...(touched.applications as {
            [key: string]: any;
          } as Record<string, { selected: boolean; url: boolean }>),
        };
        modifiedApplicationsTouched[platformName] = {
          selected: true,
          url: false,
        };

        setTouched({
          ...touched,
          applications: { ...modifiedApplicationsTouched },
        });
      }
    }
  };

  const isFormChanged =
    JSON.stringify(values) !== JSON.stringify(initialValues);

  const discardChanges = () => {
    resetButtonRef.current && resetButtonRef.current.click();
  };

  const saveChanges = () => {
    submitButtonRef.current && submitButtonRef.current.click();
    createFavicon()
  };

  const reset = () => {
    setDeletedImagesIds([]);
    setValues({
      ...initialValues,
      banners: [...initialValues.banners],
    });
    setErrors({ ...initialErrors });
    setTouched({ ...initialTouched });
  };

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#FFF",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      border: "1px solid #dadde9",
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      padding:"10px"
    },
  }))(Tooltip);

  return (
    <>
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={require("../assets/Builder Studio Store.png")}
              className={classes.logo}
              alt="logo"
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
                disabled={isSaving}
              >
                {isSaving && <CircularProgress size={15} style={{ marginRight: 10 }} />}
                Save changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        <form onSubmit={handleSubmit} className={classes.wrapper}>
          <Box className={classes.leftSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Header</Typography>
              <Box className={`${classes.renameBox}`}>
                <Box className={classes.logoTitleContainer}>
                  <Box>
                    <Typography className={classes.inputlabel}>Logo *</Typography>
                    <input
                      data-testid="image-upload"
                      ref={logoInputRef}
                      name="logo"
                      className={classes.hidden}
                      type="file"
                      accept="image/jpeg, image/jpg, image/png. image/gif"
                      onChange={(event) => checkImage(event, logoInputRef, true)}
                      onBlur={handleBlur}
                    />
                    {(values.logo as ServiceImage | File) ? (
                      <Box>
                        <Suspense fallback={<CircularProgress/>}>
                          <ImageCard
                            imageClassName={classes.previewImage}
                            image={
                              values.logo instanceof File
                              ? URL.createObjectURL(values.logo)
                              : (values.logo as ServiceImage).thumbnail_url || ''
                            }
                            alt="image loading"
                          />
                        </Suspense>
                        <Button
                          variant="text"
                          onClick={() => upsertImage(logoInputRef)}
                          className={classes.editButton}
                        >
                          Edit
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        className={classes.imageUpload}
                        onClick={() => upsertImage(logoInputRef)}
                      >
                        <AddIcon className={classes.addIcon} />
                        <Typography className={classes.addImageText}>
                          Add logo
                        </Typography>
                        <Typography className={classes.fileSize}>
                          Max 20MB
                        </Typography>
                      </Box>
                    )}

                    {touched && touched.logo && errors && errors.logo && (
                      <Typography className={classes.imageValidationError}>
                        {errors.logo}
                      </Typography>
                    )}
                  </Box>
                  <Box className={classes.storeNameBox}>
                    <Typography className={classes.inputlabel1}>
                      Store Name
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Store Name"
                      fullWidth
                      {...getFieldProps("storeName")}
                      error={Boolean(
                        touched && touched.storeName && errors && errors.storeName
                      )}
                      helperText={
                        touched && touched.storeName && errors && errors.storeName
                      }
                    />
                  </Box>
                </Box>
                <Box className={classes.logoTitleContainer}> 
                  <Box>
                    <Typography className={classes.inputlabel} >Favicon (64*64)</Typography>
                    <input
                      data-testid="image-upload"
                      ref={faviconInputRef}
                      name="favicon"
                      className={classes.hidden}
                      type="file"
                      accept="image/jpeg, image/jpg, image/png. image/gif"
                      onChange={(event) => checkImage1(event, faviconInputRef, true)}
                      onBlur={handleBlur}
                    />
                    {(values.favicon as ServiceImage | File) || values.favicon?.name ? (
                      <Box>
                        <Suspense fallback={<CircularProgress/>}>
                        <ImageCard
                          imageClassName={classes.previewImage}
                          image={
                          values.favicon instanceof File
                        ? URL.createObjectURL(values.favicon)
                        : (values.favicon as ServiceImage)?.url || ''
                        }
                          alt="image loading"
                        />
                        </Suspense>
                        <Button
                          variant="text"
                          onClick={() => upsertFavicon(faviconInputRef)}
                          className={classes.editButton}
                        >
                          Edit
                        </Button>

                      </Box>
                    ) : (
                      <Box
                        className={classes.imageUpload}
                        onClick={() => upsertFavicon(faviconInputRef)}
                      >
                        <AddIcon className={classes.addIcon} />
                        <Typography className={classes.addImageText}>
                          Add Favicon
                        </Typography>
                        <Typography className={classes.fileSize}>
                          Max 1MB
                        </Typography>
                      </Box>
                    )}

                    {touched && touched.favicon && errors && errors.favicon && (
                      <Typography className={classes.imageValidationError}>
                        {errors.favicon}
                      </Typography>
                    )}
                  </Box>               
                  <Box className={classes.storeNameBox}>
                    <Typography className={classes.inputlabel1}>
                      Website title
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Website title"
                      fullWidth
                      {...getFieldProps("websiteTitle")}
                      error={Boolean(
                        touched && touched.websiteTitle && errors && errors.websiteTitle
                      )}
                      helperText={
                        touched && touched.websiteTitle && errors && errors.websiteTitle
                      }
                    />
                  </Box>
                </Box>
              </Box>

              <Box className ={classes.renameConatiner}>
                <Box className={classes.storeNameBox}>
                  <Typography className={classes.inputlabel1}>
                    {configJSON.headerText1}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Service"
                    fullWidth
                    {...getFieldProps("renameItem1")}
                    error={Boolean(
                      touched && touched.renameItem1 && errors && errors.renameItem1
                    )}
                    helperText={
                      touched && touched.renameItem1 && errors && errors.renameItem1
                    }
                  />
                </Box>

                <Box className={classes.storeNameBox}>
                  <Typography className={classes.inputlabel1}>
                    {configJSON.headerText2}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Service Provider"
                    fullWidth
                    {...getFieldProps("renameItem2")}
                    error={Boolean(
                      touched && touched.renameItem2 && errors && errors.renameItem2
                    )}
                    helperText={
                      touched && touched.renameItem2 && errors && errors.renameItem2
                    }
                  />
                </Box>

                <Box className={classes.storeNameBox}>
                  <Typography className={classes.inputlabel1}>
                    {configJSON.headerText3}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Gallery"
                    fullWidth
                    {...getFieldProps("renameItem3")}
                    error={Boolean(
                      touched && touched.renameItem3 && errors && errors.renameItem3
                    )}
                    helperText={
                      touched && touched.renameItem3 && errors && errors.renameItem3
                    }
                  />
                </Box>

              </Box>

            </Box>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Footer</Typography>

              <Box className={classes.inputsWrapper}>
                <Box>
                  <Typography className={classes.inputlabel}>
                    Phone number *
                  </Typography>
                  <Box className={classes.phoneNumberWrapper}>
                    <Select
                      type="number"
                      variant="outlined"
                      defaultValue={values.countryCode}
                      className={classes.select}
                      {...getFieldProps("countryCode")}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.phone}>
                          <Box className={classes.countryCodeText}>
                            <img
                              src={`https://flagcdn.com/h20/${country.code.toLowerCase()}.png`}
                              className={classes.countryCodeImage}
                              alt="flag"
                              width={"22px"}
                              height={"16px"}
                            />
                            {country.phone}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>

                    <TextField
                      variant="outlined"
                      placeholder="Enter your phone number"
                      fullWidth
                      className={classes.phoneNumber}
                      {...getFieldProps("phoneNumber")}
                      error={Boolean(
                        touched &&
                        touched.phoneNumber &&
                        errors &&
                        errors.phoneNumber
                      )}
                      helperText={
                        touched &&
                        touched.phoneNumber &&
                        errors &&
                        errors.phoneNumber
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <Box className={classes.copyrightWrapper}>
                    <Typography className={classes.inputlabel1}>
                      Copyright *
                    </Typography>

                    <Typography className={classes.copyrightCounter}>
                      {values.copyright.length} / 55
                    </Typography>
                  </Box>

                  <TextField
                    variant="outlined"
                    placeholder="Copyright © YYYY 'Company's Name'. All rights reserved."
                    fullWidth
                    {...getFieldProps("copyright")}
                    error={Boolean(
                      touched && touched.copyright && errors && errors.copyright
                    )}
                    helperText={
                      touched && touched.copyright && errors && errors.copyright
                    }
                  />

                  <Typography className={classes.helperText}>
                    This copyright text will appear in the bottom of the page
                  </Typography>
                </Box>

                <Divider />

                <Box>
                  <Typography className={classes.inputlabel}>
                    Social Media
                  </Typography>

                  <List className={classes.list}>
                    {Object.entries<Platform>(values.socialMedias).map(
                      ([platformName, platform]) => (
                        <ListItem key={platformName} className={classes.item}>
                          <Checkbox
                            checked={platform.selected}
                            className={classes.checkbox}
                            icon={
                              <SvgIcon
                                component={CheckboxIcon}
                                viewBox="0 0 26 26"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={CheckboxIconChecked}
                                viewBox="0 0 26 26"
                              />
                            }
                            {...getFieldProps(
                              `socialMedias[${platformName}].selected`
                            )}
                            onChange={() =>
                              clearPlatformUrl(false, platformName)
                            }
                          />
                          <Box className={classes.platformWrapper}>
                            <Typography className={classes.inputlabel1}>
                              {displayPlatformName(platformName)}
                            </Typography>
                            {platform.selected && (
                              <>
                                <Typography
                                  className={`${classes.inputlabel} ${classes.storeLabel}`}
                                >
                                  {displayUrlOrStoreName(platformName)} *
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="http://www.example.com"
                                  fullWidth
                                  {...getFieldProps(
                                    `socialMedias[${platformName}].url`
                                  )}
                                  error={Boolean(
                                    touched &&
                                    touched.socialMedias &&
                                    ((
                                      touched.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.socialMedias &&
                                    ((
                                      errors.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  )}
                                  helperText={
                                    touched &&
                                    touched.socialMedias &&
                                    ((
                                      touched.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.socialMedias &&
                                    ((
                                      errors.socialMedias as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.socialMedias as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>

                <Divider />

                <Box>
                  <Typography className={classes.inputlabel}>
                    Download Apps
                  </Typography>

                  <List className={classes.list}>
                    {Object.entries<Platform>(values.applications).map(
                      ([platformName, platform]) => (
                        <ListItem key={platformName} className={classes.item}>
                          <Checkbox
                            checked={platform.selected}
                            className={classes.checkbox}
                            icon={
                              <SvgIcon
                                component={CheckboxIcon}
                                viewBox="0 0 26 26"
                              />
                            }
                            checkedIcon={
                              <SvgIcon
                                component={CheckboxIconChecked}
                                viewBox="0 0 26 26"
                              />
                            }
                            {...getFieldProps(
                              `applications[${platformName}].selected`
                            )}
                            onChange={() =>
                              clearPlatformUrl(true, platformName)
                            }
                          />
                          <Box className={classes.platformWrapper}>
                            <Typography className={classes.inputlabel1}>
                              {displayPlatformName(platformName)}
                            </Typography>
                            {platform.selected && (
                              <>
                                <Typography
                                  className={`${classes.inputlabel} ${classes.storeLabel}`}
                                >
                                  {displayUrlOrStoreName(platformName)} *
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="http://www.example.com"
                                  fullWidth
                                  {...getFieldProps(
                                    `applications[${platformName}].url`
                                  )}
                                  error={Boolean(
                                    touched &&
                                    touched.applications &&
                                    ((
                                      touched.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.applications &&
                                    ((
                                      errors.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  )}
                                  helperText={
                                    touched &&
                                    touched.applications &&
                                    ((
                                      touched.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        touched.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url &&
                                    errors &&
                                    errors.applications &&
                                    ((
                                      errors.applications as {
                                        [key: string]: any;
                                      }
                                    )[platformName] as Platform) &&
                                    (
                                      (
                                        errors.applications as {
                                          [key: string]: any;
                                        }
                                      )[platformName] as Platform
                                    ).url
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.RightSideWrapper}>
            <Box className={classes.card1}>
              <Accordion className={classes.accordionContainer}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.sectionTestimonial}>Banner</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}>
                  <Box className={classes.inputsWrapper}>
                    {values.banners.map((banner: Banner, index: number) => (
                      <Box className={classes.inputsWrapper} key={index}>
                        <Box>
                         
                          <Typography className={classes.inputlabel}>
                            Banner {index + 1} {index === 0 && "*"}
                          </Typography>
                          <input
                            data-testid="image-upload"
                            ref={bannersInputRef[index]}
                            name="banners[index].image"
                            className={classes.hidden}
                            type="file"
                            accept="image/*"
                            onChange={(event) =>
                              checkImage(event, bannersInputRef[index], false)
                            }
                            onBlur={handleBlur}
                          />
                          {(banner.image && (banner.image instanceof File || Boolean(banner.image.small_url))) ? (
                            <Box>
                              <Suspense fallback={<CircularProgress/>}>
                                <ImageCard
                                  imageClassName={classes.previewImage}
                                  alt="image loading"
                                  image={
                                    (banner.image as ServiceImage).small_url ||
                                    URL.createObjectURL && URL.createObjectURL(banner.image as File)
                                  }
                                />
                              </Suspense>
                              <Box>
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    upsertImage(bannersInputRef[index])
                                  }
                                  className={classes.editButton}
                                >
                                  Edit
                                </Button>
                                {index > 0 && (
                                  <Button
                                    variant="text"
                                    onClick={() =>
                                      removeImage(bannersInputRef[index])
                                    }
                                    className={classes.removeButton}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              className={classes.imageUpload}
                              onClick={() => upsertImage(bannersInputRef[index])}
                            >
                              <AddIcon className={classes.addIcon} />
                              <Typography className={classes.addImageText}>
                                Add banner
                              </Typography>
                              <Typography className={classes.fileSize}>
                                Max 20MB
                              </Typography>
                            </Box>
                          )}

                          {Boolean(
                            touched &&
                            touched.banners &&
                            ((
                              touched.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner) &&
                            (
                              (
                                touched.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner
                            ).image &&
                            errors &&
                            errors.banners &&
                            ((
                              errors.banners as {
                                [key: string]: any;
                              }[]
                            )[index] as Banner) &&
                            (
                              (
                                errors.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner
                            ).image
                          ) && (
                              <Typography className={classes.imageValidationError}>
                                {errors &&
                                  errors.banners &&
                                  ((
                                    errors.banners as {
                                      [key: string]: any;
                                    }[]
                                  )[index] as Banner) &&
                                  (
                                    (
                                      errors.banners as {
                                        [key: string]: any;
                                      }[]
                                    )[index] as Banner
                                  ).image}
                              </Typography>
                            )}
                        </Box>

                        <Box>
                          <Typography className={classes.inputlabel}>
                            URL {banner.image && "*"}
                          </Typography>
                          <TextField
                            variant="outlined"
                            inputProps={{ className: classes.input }}
                            placeholder="http://www.example.com"
                            fullWidth
                            {...getFieldProps(`banners[${index}].url`)}
                            disabled={!banner.image}
                            error={Boolean(
                              touched &&
                              touched.banners &&
                              ((
                                touched.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner) &&
                              (
                                (
                                  touched.banners as {
                                    [key: string]: any;
                                  }[]
                                )[index] as Banner
                              ).url &&
                              errors &&
                              errors.banners &&
                              ((
                                errors.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner) &&
                              (
                                (
                                  errors.banners as {
                                    [key: string]: any;
                                  }[]
                                )[index] as Banner
                              ).url
                            )}
                            helperText={
                              touched &&
                              touched.banners &&
                              ((
                                touched.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner) &&
                              (
                                (
                                  touched.banners as {
                                    [key: string]: any;
                                  }[]
                                )[index] as Banner
                              ).url &&
                              errors &&
                              errors.banners &&
                              ((
                                errors.banners as {
                                  [key: string]: any;
                                }[]
                              )[index] as Banner) &&
                              (
                                (
                                  errors.banners as {
                                    [key: string]: any;
                                  }[]
                                )[index] as Banner
                              ).url
                            }
                          />
                        </Box>

                        {index < 2 && <Divider />}
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box className={classes.card1}>
              <Accordion className={classes.accordionContainer}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.sectionTestimonial}>{configJSON.SectionsTestimonials}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}>
                  <Box className={classes.testimonialHeader}>
                    <Box className={classes.testimonalBox}>
                      <Typography className={classes.testimonialTitle}>
                        {configJSON.Testimonial}
                      </Typography>
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{configJSON.displayTestimonalSection}</Typography>
                          </React.Fragment>
                        }
                      >
                        <img src={infoImage} alt="information" width={"20px"} height={"20px"}/>
                      </HtmlTooltip>
                    </Box>

                    <Box className={classes.switchWrapper}>
                      <Switch
                        color="primary"
                        checked={values.testimonial}
                        className={classes.switch}
                        {...getFieldProps("testimonial")}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.storeNameBox}>
                    <Box className={classes.testimonalBox}>
                      <Typography className={classes.inputlabel}>
                        {configJSON.sectionName}
                      </Typography>
                      <HtmlTooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{configJSON.chooseRename}</Typography>
                          </React.Fragment>
                        }
                      >
                        <img src={infoImage} alt="information" width={"20px"} height={"20px"}/>
                      </HtmlTooltip>
                    </Box>
                    <TextField
                      variant="outlined"
                      placeholder="Top Service"
                      fullWidth
                      {...getFieldProps("testimonialName")}
                      error={Boolean(
                        touched && touched.testimonialName && errors && errors.testimonialName
                      )}
                      helperText={
                        touched && touched.testimonialName && errors && errors.testimonialName
                      }
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Button
            ref={resetButtonRef}
            className={classes.hidden}
            onClick={reset}
          />
          <Button
            ref={submitButtonRef}
            type="submit"
            className={classes.hidden}
          />
        </form>
      </Box>


      <Modal open={!!selectedImage}>
        <Box className={classes?.cropperBox} data-testid="cropper-dialog">
          <div className={classes?.titleBlock}>
            <Typography className={classes?.modalTitle}>
              {configJSON.textBannerResizeDialogHeader}
            </Typography>
            <CloseIcon
              data-testid="closeButton"
              className={classes?.closeIcon}
              fontSize="medium"
              onClick={handleCropperClose}
            />
          </div>
          <AddCropper
            onCancel={handleCropperClose}
            dataToPass={{
              selectedFile: selectedImage?.selectedFile,
              type: selectedImage?.selectedFileType
            }}
            isAspectRatio={true}
            onSubmit={handleBannerSubmit}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DashboardAdmin;
// Customizable Area End