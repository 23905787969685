// Customizable Area Start
import React from "react";
import ContactSuccessController from "./ContactSuccessController";
import { Box, Typography, Container } from "@material-ui/core";

class ContactSuccess extends ContactSuccessController {
  render() {
    return (
      <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center", minHeight:"calc(100vh - 250px)" }}>
        <Container maxWidth="md">
          <Box sx={{ ...webStyle.boxStyle }}>
            <Typography variant="h6" color="textPrimary" align="center">
              Thank you for contacting us.
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  }
}

const webStyle = {
  boxStyle: {
    border: "2px solid rgb(130, 176, 133)",
    bgcolor: "rgb(130, 176, 133)",
    margin:" 0px auto",
    p:" 32px",
    right: "0px",
    left: "0px",
    top: "40%",
    maxWidth: "333px"
  }
};

export default ContactSuccess;
// Customizable Area End