// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: { position: "relative" },
  spinner: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
  },
  noImage: {
    display: "none",
  },
  blockImage: {
    display: "block",
  },
}));
// Customizable Area End