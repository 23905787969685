import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import { ChangeEvent } from "react";
// Customizable Area Start
const navigation = require("react-navigation");
import {
  generateRequestMessage,
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import moment from "moment";
import { PaginationType } from "../../ordermanagement/src/types";

export interface ContactUsArray {
  id: string;
  type: string;
  attributes: {
    name: string;
    email: string;
    subject: string;
    phone_number: string;
    description: string;
    created_at: string;
    country_code: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  identifier: string;
  // Customizable Area Start
  classes: {
    select: string,
    phoneBox: string,
  };
  // Customizable Area End
}

export interface Values {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
  countryCode: string;
  // Customizable Area End
}

export interface CustomContact {
  // Customizable Area Start
  data: Array<ContactUsArray>,
  metadata: {
    meta: {
      pagination: {
        current_page: number;
        next_page: number;
        prev_page: null;
        total_pages: number;
        total_count: number;
        current_count: number;
        per_page: number;
      }
    }
  }
  // Customizable Area End
}

interface CustomError {
  // Customizable Area Start
  contact?: string[];
  // Customizable Area End
}


export interface S {
  // Customizable Area Start
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: Array<ContactUsArray>;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  selectedRow: number | null;
  openDialog: boolean;
  deletedRow: string[];
  showDeleteDialog: boolean;
  showSuccessMessage: boolean;
  pagination?: PaginationType;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  identifier: string;
}

export default class ContactController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactApiCallId: string | undefined;
  deleteContactApiCallId: string | undefined;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactApiCallId = "";
    this.deleteContactApiCallId = "";

    this.state = {
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      selectedRow: null,
      openDialog: false,
      loading: true,
      deletedRow: [],
      showDeleteDialog: false,
      showSuccessMessage: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
    }

    const messageID = message.id;
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    runEngine.debugLog("API Message Received", message);

    if (messageID === getName(MessageEnum.SessionResponseMessage)) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList();
    } else if (messageID === getName(MessageEnum.RestAPIResponceMessage)) {
      if (apiRequestCallId === this.contactApiCallId) {
        const contactData = response;
        this.handleContactAPIResponse(contactData);
      } else if (apiRequestCallId === this.deleteContactApiCallId) {
        this.setState({ loading: false });
        if (response?.message) { this.handleDeleteContactAPIResponse(); }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(toNavigate.messageId, toNavigate);
  };

  handleContactAPIResponse(data: CustomContact) {
    this.setState({ contactUsList: data.data, loading: false, pagination: data.metadata?.meta?.pagination });
  }

  handleDeleteContactAPIResponse() {
    this.setState({ showSuccessMessage: true, isVisible: false });
    this.getContactUsList();
    setTimeout(() => {
      this.setState({ showSuccessMessage: false });
    }, 4000);
  }

  handleVisibilityClick = (index: number) => {
    this.setState((prevState) => ({
      openDialog: !prevState.openDialog,
      selectedRow: index,
    }));
  };

  handleDeleteClick = (selectedIds: string[]) => {
    this.setState((prevState) => ({
      showDeleteDialog: !prevState.showDeleteDialog,
      deletedRow: selectedIds,
    }));
  };

  handleDeleteDialog = (queryId: string) => {
    this.setState((prevState) => ({
      deletedRow: prevState.deletedRow.includes(queryId)
        ? prevState.deletedRow.filter(item => item !== queryId)
        : [...prevState.deletedRow, queryId],
    }));
  };


  handleDeleteDialogClose = () => {
    this.setState({
      showDeleteDialog: false,
      deletedRow: [],
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      selectedRow: null,
    });
  };


  handleConfirmDelete = async () => {
    const idsToDelete = this.state.deletedRow;
    if (idsToDelete.length > 0) {
      const newContactUsList = this.state.contactUsList.filter(
        (item: { id: string }) => !idsToDelete.includes(item.id)
      );

      this.setState({
        deletedRow: [],
        contactUsList: newContactUsList,
        showDeleteDialog: false,
      });
      this.deleteMultipleContactQueries(idsToDelete);
    }
  };

  handleSelectAllFields = () => {
    this.setState((prevState) => {
      const allRowsSelected = prevState.deletedRow.length === prevState.contactUsList.length;
      const updatedDeletedRow = allRowsSelected ? [] : prevState.contactUsList.map((item: { id: string }) => item.id);

      return {
        deletedRow: prevState.deletedRow.length > 0 ? [] : updatedDeletedRow,
      };
    });
  };

  deleteMultipleContactQueries = async (queryIds: string[]) => {
    const idsQueryParam = queryIds.map(queryId => `[ids][]=${queryId}`).join("&");
    const requestMessage = await generateRequestMessage(
      configJSON.getContactUsAPiEndPoint + `/destroy_contact?${idsQueryParam}`,
      configJSON.httpDeleteMethod
    )
    this.deleteContactApiCallId = requestMessage.messageId;
    this.setState({ loading: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = async (page = 1) => {
    const requestMessage = await generateRequestMessage(
      configJSON.getContactUsAPiEndPoint + "?page=" + page,
      configJSON.httpGetMethod
    )
    this.contactApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatDate = (inputDate: string): string => {
    return moment.utc(inputDate).format("LLL")
  }

  handlePageChange = (page: number) => {
    this.getContactUsList(page);
    window.scrollTo({top:0, behavior:"smooth"})
  }
  // Customizable Area End
}
