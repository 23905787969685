// Customizable Area Start
import CustomerFeedbackController from "./CustomerFeedbackController.web";
import "../assets/customerFeedback.css";
import * as React from "react";
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withSnackBar from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import TableComponent from "../../ss-cms-common-components/src/TableComponent/TableComponent";
import EmptyPage from "../../ss-cms-common-components/src/EmptyPage/EmptyPage";
import Pagination from "../../ss-cms-common-components/src/Pagination/Pagination";
import { Scrollbars } from "react-custom-scrollbars";
import SearchAndFilterWeb from "../../ss-cms-common-components/src/SearchAndFilter/SearchAndFilter.web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withHeaderBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import NotFound from "../../ss-cms-common-components/src/NotFound/NotFound.web";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
export const configJSON = require("./config.js");

const titles = [
  configJSON.textTableHeaderId,
  configJSON.textTableHeaderDescription,
  configJSON.textTableHeaderPosition,
  configJSON.textTableHeaderCustomerName,
  configJSON.textTableHeaderActivated
];

export class CustomerFeedback extends CustomerFeedbackController {
  render() {
    const { customerFeedbackData, customerFeedbackMeta, checkedFeedbackList, rowPerPage } = this.state;
    if (customerFeedbackData?.length > 0) {
      return (
        <Scrollbars>
          <div className="customer-feedback-page tableContainer">
            <div className="header">
              <div className="header-title-wrapper">
                <section className="d-flex align-items-center">
                  <span className="header-title">{configJSON.customerFeedback}</span>
                  {checkedFeedbackList?.length > 0 &&
                    <p
                      className="delete-items"
                      onClick={this.handleDeleteModal}>{configJSON.deleteCustomerFeedback}</p>
                  }
                </section>

                <div className="header-buttons">
                  <button
                    className="header-create-button-customer-feedback"
                    data-testid="create-button"
                    onClick={this.goToAddCustomerFeedback}
                  >
                    {configJSON.createCustomerFeedback}
                  </button>
                </div>
              </div>
            </div>
            <SearchAndFilterWeb
              className="customers-filter-bar"
              showFilterButton={false}
              searchValue={""}
              handleSearch={this.handleSearch}
              placeholderText={configJSON.searchCustomerFeedback}
              removeSearchValue={this.handleClearSearch}
            />

            {this.state.noData === false ?
              <>
                <TableComponent
                  titles={titles}
                  data={customerFeedbackData}
                  edit
                  handleEditClick={(item) => this.handleEditClick(item)}
                  activatedCellStyleTrue={activatedCellStyleTrue}
                  activatedCellStyleFalse={activatedCellStyleFalse}
                  getCheckedItems={(item) => this.checkedItems(item)}
                  checkbox 
                  readMoreType={false}                />
                <Pagination
                  count={+customerFeedbackMeta?.total_count}
                  onChange={this.handlePageChange}
                  pageSize={rowPerPage}
                  itemText={configJSON.paginationText}
                />
              </>
              :
              (
                <NotFound titles={titles} edit={true} />
              )}
          </div>
        </Scrollbars>
      );
    } else if (customerFeedbackData?.length === 0 && !this.state.loading) {
      return (
        <Scrollbars>
          <div className="customer-feedback-page emptyPageContainer">
            <EmptyPage
              title={configJSON.emptyPageTitle}
              message={configJSON.emptyPageMessage}
              button_label={configJSON.emptyPageButtonLabel}
              handleClick={this.goToAddCustomerFeedback}
            />
          </div>
        </Scrollbars>
      );
    } else {
      return <div className="emptyDiv" />;
    }
  }
}
const activatedCellStyleTrue = {
  width: "min-content",
  paddingRight: 20,
  paddingLeft: 20,
  backgroundColor: "#EBF5F0",
  borderColor: "#B3DAC7",
  color: "#008243",
};
const activatedCellStyleFalse = {
  width: "min-content",
  paddingRight: 20,
  paddingLeft: 20,
  backgroundColor: "#FFECEC",
  borderColor: "#FFD7D7",
  color: "#E00000",
};

export default withLayout(withSnackBar(withLoader(withHeaderBar(withDialog(CustomerFeedback)))));
// Customizable Area End