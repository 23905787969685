// Customizable Area Start
import React from "react";

const configJSON = require("./config.js");

interface HeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">
          {configJSON.textHeaderEmailTemplates}
        </span>
        <div className="header-buttons">
        </div>
      </div>
      {children}
    </div>
  );
};
// Customizable Area End