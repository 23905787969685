// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Navigation } from "../../utilities/src/models/Navigation";
import { WithStyles } from "@material-ui/core";
import { styles } from "./StaticPages.web";
import BlockHelpers from "../../utilities/src/BlockHelpers";

const configJSON = require("./config.js");
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";

export type Props = WithStyles<typeof styles> & {
  navigation: Navigation;
};

interface S {
  staticPages: StaticPage[];
}

interface SS { }

export type StaticPage = {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      content: string;
      questions?: {
        attributes: {
          title: string;
          content: string;
          status: boolean;
        }
      }[]
    };
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    };
    page_slug: string;
    created_at: string;
    updated_at: string;
  };
};

export default class StaticPageController extends BlockComponent<Props, S, SS> {
  getStaticPagesMessageId: string | undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      staticPages: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getStaticPages();
  }

  navigateToStatic = (staticPage: StaticPage) => {
    this.props.navigation.navigate("StaticPageDetail", {id: staticPage.id});
  }

  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.props.navigation.navigate("StaticPageDetail", {id: event.target.value});
  }

  receive = async (from: string, message: Message) => {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStaticPagesMessageId === apiRequestCallID
    ) {
      if (responseJSON?.data) {
        this.setState({
          staticPages: responseJSON.data.filter(
            (staticPage: StaticPage) => staticPage.attributes.active
          ),
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  };

  getStaticPages = () => {
    mixpanel.track("webcustomer_static_pages_enter");
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStaticPagesMessageId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStaticPagesEndPoint,
      header
    });
  }
}
// Customizable Area End
