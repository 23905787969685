Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";
exports.getStaticPagesEndPoint = "bx_block_static_pages/static_pages";
exports.getMethod = "GET";

exports.textHome = "Home";
exports.textHelpCenter = "Help Center";
exports.textAboutUs = "About us";
exports.textFAQs = "FAQs";
exports.textPrivacyPolicy = "Privacy Policy";
exports.textTOS = "Terms of Service";
// Customizable Area End
