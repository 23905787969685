// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { generateRequestMessage } from "../../ss-cms-common-components/src/Utilities/Utilities";

const configJSON = require("./config");

export type Props = {
};

interface S {
}

interface SS {
}

class DownloadJsonController extends BlockComponent<Props, S, SS> {
  getWebJsonMessageId: string = "";
  getMobileJsonMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {};

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleGetWebJson = async () => {
    const getWebJsonMessage = await generateRequestMessage(
      configJSON.webJsonLink,
      configJSON.getApiMethodType
    );
    this.getWebJsonMessageId = getWebJsonMessage.messageId;
    this.send(getWebJsonMessage);
  };

  handleGetWMobileJson = async () => {
    const getWebJsonMessage = await generateRequestMessage(
      configJSON.mobileJsonLink,
      configJSON.getApiMethodType
    );
    this.getMobileJsonMessageId = getWebJsonMessage.messageId;
    this.send(getWebJsonMessage);
  };

  downloadJsonFile = (json: JSON, fileName: string) => {
    const blob = new Blob([JSON.stringify(json)], {
      type: "text/json",
    });
    const link = document.createElement("a");

    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(
      ":"
    );

    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(event);
    link.remove();
  };

  receive = async (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getWebJsonMessageId === apiRequestCallId) {
        this.downloadJsonFile(responseJson, "web.json");
      } else if (this.getMobileJsonMessageId === apiRequestCallId) {
        this.downloadJsonFile(responseJson, "mobile.json");
      }
    }
  };
}

export default DownloadJsonController;
// Customizable Area End