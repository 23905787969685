Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";

exports.getCataloguesEndPoint = "bx_block_catalogue/catalogues";
exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.searchCataloguesEndPoint = 'bx_block_catalogue/catalogues/search'

exports.getMethod = "GET";

exports.textHome = "Home"
exports.textServices = "Services"
exports.textDuration = "Duration"
exports.textMins = "mins"
exports.textPrice = "Price"
exports.textDescription = "Description"
exports.textBookNow = "Book now"
exports.textFilter = "Filter"
exports.textReset = "Reset"
exports.textApplyNow = "Apply Now"
exports.textCategory = "Category"
exports.textLoading = "Loading results...   "
exports.textNoResultsFound = "No Results Found"
exports.textNoResultHint = "Please refine your search."
exports.paginationText = "Services"
exports.bookNow = "Book Now"
exports.getBrandsEndPoint = "bx_block_catalogue/brands/home_brand_header";
exports.navigateServiceListingPage = "webcustomer_navigate_service_listing_page";
// Customizable Area End
