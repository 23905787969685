import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Portal: React.FC = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  let portalRoot = document.getElementById("portal-root");
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", "portal-root");
    document.body.appendChild(portalRoot);
  }
  return ReactDOM.createPortal(children, portalRoot!);
};

export default Portal;
