import React from "react";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 12,
    padding: "12px 40px",
    border: "3px",
    lineHeight: 1.5,
    backgroundColor: "#00D659",

    fontFamily: ["Rubik"].join(","),
    "&:hover": {
      backgroundColor: "#00D659",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#00D659",
    },
  },
})(Button);

type CustomizedButtonsPropType = {
  label: string;
  handleClick: () => void;
  disabled?: boolean;
  testId?: string
};
export default function CustomizedButtons(props: CustomizedButtonsPropType) {
  const { label, handleClick, disabled, testId} = props;
  return (
    <div>
      <CustomButton data-testid={testId} disableElevation onClick={handleClick} variant="contained" disabled={disabled}>
        {label}
      </CustomButton>
    </div>
  );
}
