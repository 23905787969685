// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  TextareaAutosize,
  FormHelperText,
  IconButton,
  Select,
  MenuItem,
  withStyles
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { CountryType, countries } from "./Countries";
import AddContactController from "./AddContactController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E5E5E5",
      contrastText: "#fff",
    },
  },
});

const errorMessage = (error: string | undefined) => {
  return (error ? <FormHelperText style={webStyle.inputError}>{error}</FormHelperText> : null)
};

export class AddContact extends AddContactController {
  render() {
    const { name, email, phoneNumber, subject, message, errors, showSuccessMessageDialog } = this.state;
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm" style={webStyle.formContainer}>
          <Box
            bgcolor="#FFFFFF"
            boxShadow="0px 4px 4px #F5F5F5"
            borderRadius={5}
            p={3}
          >
            <Typography
              variant="h4"
              component="h1"
              align="left"
              gutterBottom
              style={webStyle.ContactHeading}
            >
              Contact Us
            </Typography>
            <form>
              <Box mb={2}>
                <Typography style={webStyle.inputLabel}>Name*</Typography>
                <TextField
                  data-test-id="txtName"
                  name="name"
                  value={name}
                  onChange={this.handleChange("name")}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: webStyle.input
                  }}
                  error={!!errors.name}
                />
                {errorMessage(errors.name)}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <Typography style={webStyle.inputLabel}>Email*</Typography>
                    <TextField
                      data-test-id="txtEmail"
                      name="email"
                      value={email}
                      onChange={this.handleChange("email")}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: webStyle.input
                      }}
                      error={!!errors.email}
                    />
                    {errorMessage(errors.email)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mb={2}>
                    <Typography style={webStyle.inputLabel}>
                      Phone Number*
                    </Typography>
                    <Box style={webStyle.phoneNumberWrapper}>
                      <Select
                        type="number"
                        variant="outlined"
                        defaultValue={this.state.countryCode}
                        onChange={this.handleChange('countryCode')}
                        className={classes.select}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                      >
                        {countries.map((country: CountryType) => (
                          <MenuItem key={country.code} value={country.phone}>
                            <Box style={webStyle.countryCodeText}>
                              <img
                                src={`https://flagcdn.com/h20/${country.code.toLowerCase()}.png`}
                                style={webStyle.countryCodeImage}
                                alt="image loading"
                              />
                              {country.phone}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        data-test-id="txtPhoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={this.handleChange("phoneNumber")}
                        variant="outlined"
                        type="number"
                        fullWidth
                        className={classes.phoneBox}
                        inputProps={{
                          style: webStyle.input
                        }}
                        error={!!errors.phoneNumber}
                      />
                    </Box>
                    {errorMessage(errors.phoneNumber)}
                  </Box>
                </Grid>
              </Grid>
              <Box mb={2}>
                <Typography style={webStyle.inputLabel}>Subject*</Typography>
                <TextField
                  data-test-id="txtSubject"
                  name="subject"
                  value={subject}
                  onChange={this.handleChange("subject")}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: webStyle.input
                  }}
                  error={!!errors.subject}
                />
                {errorMessage(errors.subject)}
              </Box>
              <Box>
                <Typography style={webStyle.inputLabel}>Message*</Typography>
                <TextareaAutosize
                  data-test-id="txtMessage"
                  name="message"
                  value={message}
                  onChange={this.handleChange("message")}
                  minRows={3}
                  style={{
                    ...webStyle.input,
                    width: "100%",
                    height: "140px",
                    border:
                      !errors.message || !message.length
                        ? "1px solid #D0D2DA"
                        : "1px solid #f44336",
                    borderRadius: "3px",
                    resize: "none",
                  }}
                />
                {errorMessage(errors.message)}
              </Box>

              <Button
                data-test-id="btn-contact-us"
                type="button"
                variant="contained"
                fullWidth
                style={{ ...webStyle.sendButton, textTransform: "none" }}
                onClick={this.handleSubmit}
              >
                Send
              </Button>
            </form>
          </Box>
          {showSuccessMessageDialog && (
            <Box
              style={webStyle.successMessageDialog}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={11}>
                  <Typography
                    style={webStyle.successMessageText}>
                    Thanks, we have received your query. We will get back to you soon.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton edge="end" color="inherit" onClick={this.hideDialog} aria-label="close">
                    <CloseOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  inputLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    paddingBottom: "4px",
    fontFamily: "Rubik, sans-serif",
  },
  input: {
    fontFamily: "Rubik, sans-serif",
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "24px",
    height: "auto",
    borderRadius: "3px",
  },
  sendButton: {
    fontFamily: "Rubik, sans-serif",
    backgroundColor: "#3FC1CB",
    color: "#ffffff",
    fontSize: "16px",
    lineHeight: "24px",
    height: "48px",
    borderRadius: "5px",
    marginTop: "20px",
  },
  ContactHeading: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "24px",
    color: "#364F6B",
    marginBottom: "24px",
    fontFamily: "Rubik, sans-serif",
  },
  inputError: {
    color: "#f44336",
    marginLeft: "14px",
    marginRight: "14px",
  },
  formContainer: {
    marginBottom: "5rem"
  },
  successMessageDialog: {
    bgcolor: "#FFFFFF",
    boxShadow: "0px 4px 4px #F5F5F5",
    borderRadius: "5px",
    padding: "7px 24px",
  },
  successMessageText: {
    fontSize: "14px",
    fontWeight: 400,
    paddingBottom: "4px",
    fontFamily: "Rubik, sans-serif",
    color: "#00D659"
  },
  phoneNumberWrapper: { display: "flex" },
  select: {
    height: "48px",
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
    "& > div:focus": {
      background: "transparent !important",
    }
  },
  phoneBox: {
    "& > div": {
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px",
    },
  },
  countryCodeText: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    color: "#3C3E49",
  },
  countryCodeImage: {
    width: "22px",
    height: "16px",
    marginRight: theme.spacing(0.5),
  },
};

export default withStyles(webStyle)(AddContact);
// Customizable Area End