import React, { Component } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parser from "html-react-parser";
import "./styles.css";
import htmlToDraft from "html-to-draftjs";

export type EditorStateType = EditorState;
export const StateToDraft = (state: EditorState) =>
  draftToHtml(convertToRaw(state.getCurrentContent()));

type EditorConvertToHTMLState = {
  showCode: boolean;
  uploadedImages: any;
};
type EditorConvertToHTMLProps = {
  editorState?: EditorState;
  onEditorStateChange?: (editorState: EditorState) => void;
  isErrorShow?: boolean;
  className?: string;
  [key: string]: any;
};
function uploadImageCallBack(file: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.imgur.com/3/image");
    xhr.setRequestHeader("Authorization", "Client-ID XXXXX");
    const data = new FormData();
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
}
export const convertFromHtml = (htmlString: string) => {
  if (htmlString) {
    const blocksFromHTML = convertFromHTML(htmlString);
    const blockArray = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(blockArray);
  }
  return EditorState.createEmpty();
};

export const imageIssues = (htmlString: string) => {
  if (htmlString) {
    if (htmlString.charAt(1) == "i") {
      htmlString = `<p>${htmlString}</p>`;
    }
    const contentBlock = htmlToDraft(htmlString);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      return editorState;
    }
  }
  return EditorState.createEmpty();
};

const ShowEditorCode = ({
  showCode,
  onClick,
}: {
  showCode: boolean;
  onClick: any;
}) => (
  <div className="rdw-option-wrapper" onClick={onClick}>
    {showCode ? "Hide Text" : "Show Draft as Text"}
  </div>
);

export default class EditorConvertToHTML extends Component<
  EditorConvertToHTMLProps,
  EditorConvertToHTMLState
> {
  constructor(props: EditorConvertToHTMLProps) {
    super(props);

    this.state = {
      showCode: false,
      uploadedImages: [],
    };
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
  }

  toggleShowCode = () => {
    this.setState({ showCode: !this.state.showCode });
  };
  getFileBase64 = (file: any, callback: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = (error) => {};
  };

  imageUploadCallback = (file: any) =>
    new Promise((resolve, reject) =>
      this.getFileBase64(file, (data: any) => resolve({ data: { link: data } }))
    );

  _uploadImageCallBack = (file: any) => {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };
  render() {
    const {
      editorState,
      isErrorShow,
      className,
      wrapperClassName,
      ...props
    } = this.props;
    const { showCode } = this.state;
    return (
      <div>
        <Editor
          {...props}
          editorState={editorState}
          wrapperClassName={`wrapper-class ${wrapperClassName || ""} ${
            isErrorShow ? className : ""
          }`}
          editorClassName="editor-class"
          editorStyle={{
            color: 'black'
          }}
          toolbarClassName="toolbar-class"
          onEditorStateChange={this.props.onEditorStateChange}
          toolbarCustomButtons={[]}
          toolbar={{
            options: ["inline", "list", "textAlign", "link", "image"],

            inline: { inDropdown: false },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            //  history: { inDropdown: true },
            image: {
              uploadCallback: this.imageUploadCallback,
              previewImage: true,
            },
          }}
        />
        {/* {showCode && editorState && (
          <div className="wysiwyg-editor-text-preview">
            <div>Text Preview:</div>
            {/* <div>{StateToDraft(editorState)}</div> */}
        {/* </div>
        )} */}
      </div>
    );
  }
}

interface PreviewProps {
  editorState: EditorState;
  className?: string;
  [key: string]: any;
}

export const Preview = ({
  editorState,
  className = "",
  ...props
}: PreviewProps) => {
  return (
    <div className={"wysiwyg-editor-preview " + className} {...props}>
      {parser(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
    </div>
  );
};
export const getHtml = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState?.getCurrentContent()));
};
