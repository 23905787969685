// Customizable Area Start
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  SvgIcon,
  Slide,
  CardMedia,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
  Collapse,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add as AddIcon } from "@material-ui/icons";
import { commonSettingsAdmin as useStyles } from "./styles/CommonSettingsAdmin.web";
import { Availability } from "../../utilities/src/models/Appointment";
import { CommonSettingsManager } from "../../utilities/src/models/CommonSettingsManager";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import Portal from "../../../components/src/Portal";
import AppointmentHours from "./AppointmentHours.web";
import { Switch } from "../../AdminEmailTemplates/src/Switch.web";

const configJSON = require("./config")

export interface Props {
  data: CommonSettingsManager | null;
  isSaving: boolean;
  upsertService: (values: CommonSettingsManager) => void;
  resetValues?: boolean;
  setResetValues?: (value: boolean) => void;
  availabilityErrors: string[];
  isShowPopup: boolean;
}

export interface ITimeZone {
  name: string;
} 

const time_zones: ITimeZone[] = configJSON.timeZones;

const CommonSettingsAdmin: React.FC<Props> = ({
  data,
  isSaving,
  upsertService,
  resetValues,
  setResetValues,
  availabilityErrors,
  isShowPopup
}) => {
  const imageUploadInputRef = useRef<HTMLInputElement>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isShowPop, setIsShowPop] = useState(isShowPopup);

  const [initialValues, setInitialValues] = useState<CommonSettingsManager>(
    (data || {}) as CommonSettingsManager
  );

  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required(configJSON.textImageRequired),
    city: Yup.string().required(configJSON.textCityRequired),
    country: Yup.string().required(configJSON.textCountryRequired),
    location_url: Yup.string()
      .required(configJSON.textUrlRequired)
      .url(configJSON.textUrlNotValid),
    pin_code: Yup.string().required(configJSON.textPinRequired),
    time_zone: Yup.string().required(configJSON.textTimezoneRequired),
    address_line1: Yup.string().required(configJSON.textAddress1Required),
    address_line2: Yup.string().required(configJSON.textAddress2Required),
    availability: Yup.array().of(
      Yup.object().shape({
        day: Yup.string(),
        workingHours: Yup.array().of(
          Yup.object().shape({
            openingTime: Yup.string(),
            closingTime: Yup.string()
          })
        ),
        selected: Yup.bool()
      })
    ),
    is_refund_policy: Yup.bool(),
    refund_text: Yup.string().when("is_refund_policy", {
      is: true,
      then: (schema) => schema.required("Description of refund policy required."),
    })
  });

  const formik = useFormik<CommonSettingsManager>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {}
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setValues,
    setErrors,
    setTouched
  } = formik;

  const addImage = () => {
    imageUploadInputRef.current && imageUploadInputRef.current.click();
  };

  const removeImage = () => {
    setTouched({ ...touched, image: undefined });
    setValues({ ...values, image: null }, true);
  };

  const checkImage = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files;
    let image = (fileList && (fileList[0] || false)) as File | Blob | null;
    setTouched({ ...touched, image: Boolean(image) }, false);

    const allowedFormats = configJSON.allowedImageFormats;
    const fileType = fileList && fileList[0].type;
    const fileExtension =
      fileList &&
      fileList[0].name
        .split(".")
        .pop()
        ?.toLowerCase();
    const validImage =
      (fileType && allowedFormats.includes(fileType)) ||
      (fileExtension && allowedFormats.includes(`image/${fileExtension}`));

    if (!validImage) {
      setErrors({
        ...errors,
        image:
          configJSON.textImageFormatInvalid
      });
      clearFileInput();
      return;
    }

    if (
      fileList &&
      fileList.length > 0 &&
      fileList[0].size > 20 * 1024 * 1024
    ) {
      setErrors({
        ...errors,
        image: configJSON.textImageSizeInvalid
      });
      clearFileInput();
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setValues({ ...values, image: reader.result as string, image_raw: image as Blob  }, true);
    };
    reader.readAsDataURL(fileList?.[0] as Blob);
    clearFileInput();
  };

  const clearFileInput = () => {
    if (imageUploadInputRef.current) {
      imageUploadInputRef.current.value = "";
    }
  };

  const isFormChanged = 
  isShowPop ? 
    JSON.stringify(values) !== JSON.stringify(initialValues)
    : false

  const discardChanges = () => {
    formik.handleReset(0);
  };

  const saveChanges = () => {
    setIsShowPop(false);
    if (Object.keys(errors).length) {
      return;
    }

    upsertService(values);
  };

  useEffect(() => {
    if (data) {
      setValues(data);
      setInitialValues(data);
      setIsShowPop(true);
    }
  }, [data, setValues]);

  useEffect(() => {
    if (resetValues) {
      setInitialValues(values);
      setResetValues?.(false);
    }
    setIsShowPop(true);
  }, [resetValues, setResetValues, values]);

  const openModal = () => setIsModalOpened(true);
  const closeModal = () => setIsModalOpened(false);
  const checkAvailabilityForm = (availability: Availability[]) =>
    setValues({ ...values, availability });
  const availabilities = values.availability || [];
  const shouldRenderAppointmentHours = availabilities.some(
    (hourItem: Availability) => hourItem.selected
  );
  const classes = useStyles({ hasImages: Boolean(values.image) });

  const InputField = React.useCallback(
    (label: string, name: string) => {
      return (
        <Box className={classes.inputfield}>
          <Typography className={classes.inputlabel}>{label}</Typography>
          <TextField
            data-testid={name}
            variant="outlined"
            fullWidth
            {...getFieldProps(name)}
            error={Boolean(
              touched &&
                (touched as Record<string, string>)[name] &&
                errors &&
                (errors as Record<string, string>)[name]
            )}
            helperText={
              touched &&
              (touched as Record<string, string>)[name] &&
              errors &&
              (errors as Record<string, string>)[name]
            }
          />
        </Box>
      );
    },
    [classes, errors, getFieldProps, touched]
  );

  return (
    <>
      <Slide direction="down" in={isFormChanged} mountOnEnter unmountOnExit style={{left: 0, right: 0}}>
        <Box className={classes.formActionsWrapper}>
          <Box className={classes.formActions}>
            <CardMedia
              component="img"
              src={`${require("../assets/Builder Studio Store.png")}`}
              className={classes.logo}
            />

            <Box>
              <Button
                variant="text"
                className={classes.discardChangesbutton}
                onClick={discardChanges}
                data-testid="discard_changes"
              >
                {configJSON.textDiscardChanges}
              </Button>
              <Button
                variant="contained"
                className={classes.saveChangesbutton}
                onClick={saveChanges}
                data-testid="save_changes"
                disabled={isSaving || Object.keys(errors).length > 0}
              >
                {isSaving && (
                  <CircularProgress
                    size={15}
                    className={classes.isSavingCircular}
                  />
                )}
                {configJSON.textSaveChanges}
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box className={classes.container}>
        <Typography className={classes.title}>{configJSON.textCommonSettings}</Typography>

        <form className={classes.wrapper}>
          <Box className={classes.leftSideWrapper}>
            <Box className={classes.card}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography className={classes.cardTitle}>
                  {configJSON.textAppointmentHours}
                </Typography>
                <IconButton
                  data-testid="appointment_modal_button"
                  onClick={openModal}
                >
                  <SvgIcon className={classes.appoIcon} component={Pencil} />
                </IconButton>
              </Box>
              <Typography className={classes.description}>
                {configJSON.textHintOperatingHours}
              </Typography>
              {shouldRenderAppointmentHours && (
                <TableContainer>
                  <Table className={classes.tableCells}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                            {configJSON.textDay}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={classes.tableHeaderText}>
                          {configJSON.textTime}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {values.availability.map(
                        (availability: Availability, index: number) =>
                          availability.selected && (
                            <TableRow
                              key={index}
                              className={classes.tableBodyRowCells}
                            >
                              <TableCell>
                                <Typography className={classes.tableCellText}>
                                  {availability.day}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Box className={classes.workingHoursWrapper}>
                                  <Box className={classes.workingHours}>
                                    {availability.workingHours.map(
                                      (
                                        { openingTime, closingTime },
                                        subIndex
                                      ) => (
                                        <Typography
                                          key={subIndex}
                                          className={classes.tableCellText}
                                        >
                                          {openingTime} - {closingTime}
                                        </Typography>
                                      )
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>

            <Box className={[classes.card, classes.inputsWrapper].join(" ")}>
              <Typography className={classes.cardTitle}>
                Location details
              </Typography>
              <Box>
                <Typography className={classes.inputlabel}>{configJSON.textUrlLabel}</Typography>
                <TextField
                  data-testid="location_url"
                  variant="outlined"
                  fullWidth
                  {...getFieldProps("location_url")}
                  error={Boolean(
                    touched &&
                      touched.location_url &&
                      errors &&
                      errors.location_url
                  )}
                  helperText={
                    touched &&
                    touched.location_url &&
                    errors &&
                    errors.location_url
                  }
                />
              </Box>

              <Box>
                <Typography className={classes.inputlabel}>{configJSON.textImageLabel}</Typography>
                <input
                  data-testid="image_upload"
                  ref={imageUploadInputRef}
                  name="image"
                  className={classes.hidden}
                  type="file"
                  accept="image/*"
                  onChange={checkImage}
                  onBlur={event => {
                    handleBlur(event);
                    checkImage(event);
                  }}
                />
                <Box className={classes.imagesWrapper}>
                  {values.image && (
                      <Box>
                        <img src={(values.image as ServiceImage).url || values.image as string} alt="image loading" className={classes.image}/> 
                        <Button
                          data-testid="remove_image"
                          variant="text"
                          onClick={() => removeImage()}
                          className={classes.removeButton}
                        >
                          Remove
                        </Button>
                      </Box>
                    )}
                  {!values.image && (
                    <Box
                      data-testid="image_upload_container"
                      className={classes.imageUpload}
                      onClick={addImage}
                    >
                      <AddIcon className={classes.addIcon} />
                      <Typography className={classes.addImageText}>
                        {configJSON.textAddImage}
                      </Typography>
                      <Typography className={classes.fileSize}>
                        {configJSON.textImageMaxSize}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {touched && touched.image && errors && errors.image && (
                  <Typography className={classes.validationError}>
                    {errors.image}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className={[classes.card, classes.inputsWrapper].join(" ")}>
              <Typography className={classes.cardTitle}>
                {configJSON.textAddressDetails}
              </Typography>

              {InputField(configJSON.textAddressLine1Label, "address_line1")}
              {InputField(configJSON.textAddressLine2Label, "address_line2")}

              <Box className={classes.AddressLineInputsWrapper}>
                {InputField(configJSON.textCityLabel, "city")}
                {InputField(configJSON.textStateLabel, "state")}
              </Box>

              <Box className={classes.AddressLineInputsWrapper}>
                {InputField(configJSON.textCountryLabel, "country")}
                {InputField(configJSON.textPincodeLabel, "pin_code")}
              </Box>
            </Box>

            <Box className={[classes.card, classes.inputsWrappers].join(" ")}>
              <Box className={classes.cardTitleContainer}>
                <Box className={classes.cardTitleBox}>
                  <Typography className={classes.cardTitle}>
                    {configJSON.cancellation}
                  </Typography>
                </Box>
                <Box>
                  {
                    values.is_cancellation_policy ?
                      <Switch
                        checked={true}
                        {...getFieldProps("is_cancellation_policy")}
                      />
                      :
                      <Switch
                        checked={false}
                        {...getFieldProps("is_cancellation_policy")}
                      />
                  }
                </Box>
              </Box>
              {
                (initialValues.no_of_days_cancel != undefined || values.is_cancellation_policy) &&
                <Collapse in={!!values.is_cancellation_policy}>
                  <Box className={classes.addDesc}>
                    <Typography className={classes.inputlabel}>{configJSON.cancelTitle}</Typography>
                    <FormControl variant="outlined" className={classes.inputfields}>
                      {
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className={classes.selelctBox}
                          {...getFieldProps("no_of_days_cancel")}
                          value={values.no_of_days_cancel}
                          defaultValue={3}
                          displayEmpty
                        >
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"2"}>2</MenuItem>
                          <MenuItem value={"3"}>3</MenuItem>
                          <MenuItem value={"4"}>4</MenuItem>
                          <MenuItem value={"5"}>5</MenuItem>
                          <MenuItem value={"6"}>6</MenuItem>
                          <MenuItem value={"7"}>7</MenuItem>
                        </Select>
                      }
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography className={classes.inputlabel}>{configJSON.addCancelPolicy}</Typography>
                    <TextField
                      variant="outlined"
                      className={classes.inputfields}
                      {...getFieldProps("cancel_text")}
                      minRows={4}
                      multiline
                      placeholder="Description of cancellation policy"
                    />
                  </Box>
                </Collapse>
              }
            </Box>

            <Box className={[classes.card, classes.inputsWrappers].join(" ")}>
              <Box className={classes.cardTitleContainer}>
                <Box className={classes.cardTitleBox}>
                  <Typography className={classes.cardTitle}>
                    {configJSON.reschduleTitle}
                  </Typography>
                </Box>
                <Box>
                  {
                    values.is_reschedule_policy ?
                      <Switch
                        checked={true}
                        {...getFieldProps("is_reschedule_policy")}
                      />
                      :
                      <Switch
                        checked={false}
                        {...getFieldProps("is_reschedule_policy")}
                      />
                  }
                </Box>
              </Box>
              {(initialValues.no_of_days_reschedule != undefined || values.is_reschedule_policy) &&
                <Collapse in={!!values.is_reschedule_policy}>
                  <Box className={classes.addDesc}>
                    <Typography className={classes.inputlabel}>{configJSON.reschduleSelectTitle}</Typography>
                    <FormControl variant="outlined" className={classes.inputfields}>
                      {
                        <Select
                          defaultValue={1}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className={classes.selelctBox}
                          {...getFieldProps("no_of_days_reschedule")}
                          value={values.no_of_days_reschedule}
                          displayEmpty
                        >
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"2"}>2</MenuItem>
                          <MenuItem value={"3"}>3</MenuItem>
                          <MenuItem value={"4"}>4</MenuItem>
                          <MenuItem value={"5"}>5</MenuItem>
                          <MenuItem value={"6"}>6</MenuItem>
                          <MenuItem value={"7"}>7</MenuItem>
                        </Select>
                      }
                    </FormControl>
                  </Box>

                  <Box>
                    <Typography className={classes.inputlabel}>{configJSON.addReschdule}</Typography>
                    <TextField
                      variant="outlined"
                      className={classes.inputfields}
                      {...getFieldProps("reschedule_text")}
                      minRows={4}
                      multiline
                      placeholder={configJSON.placeholderReschdule}
                    />
                  </Box>
                </Collapse>
              }
            </Box>

            <Box className={[classes.card, classes.inputsWrappers].join(" ")}>
              <Box className={classes.cardTitleContainer}>
                <Box className={classes.cardTitleBox}>
                  <Typography className={classes.cardTitle}>
                    {configJSON.refundTxt}
                  </Typography>
                </Box>
                <Box>
                  {
                    values.is_refund_policy ?
                      <Switch
                        checked={true}
                        {...getFieldProps("is_refund_policy")}
                      />
                      :
                      <Switch
                        checked={false}
                        {...getFieldProps("is_refund_policy")}
                      />
                  }
                </Box>
              </Box>
              <Collapse in={!!values.is_refund_policy}>
                <Box className={classes.addDescs}>
                  <Typography className={classes.inputlabel}>{configJSON.refundTitle}</Typography>
                  {values.is_refund_policy &&
                  <TextField
                    variant="outlined"
                    className={classes.inputfields}
                    {...getFieldProps("refund_text")}
                    minRows={4}
                    multiline
                    placeholder={configJSON.refundDesc}
                    error={Boolean(
                      touched &&
                      touched.refund_text &&
                      errors &&
                      errors.refund_text
                    )}
                    helperText={
                      touched &&
                      touched.refund_text &&
                      errors &&
                      errors.refund_text
                    }
                  />
                  }
                </Box>
              </Collapse>
            </Box>
          </Box>

          <Box className={classes.RightSideWrapper}>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>{configJSON.textTimezoneLabel}</Typography>
              <Typography className={classes.inputlabel}>{configJSON.textTimeLabel}</Typography>
              <Autocomplete
                options={time_zones}
                getOptionLabel={time_zone => time_zone.name}
                value={
                  time_zones.find(
                    time_zone => time_zone.name === values.time_zone
                  ) || null
                }
                onChange={(_event, value) =>
                  setValues({
                    ...values,
                    time_zone:
                      (typeof value === "string" ? value : value?.name) || ""
                  })
                }
                onBlur={handleBlur("time_zone")}
                renderInput={params => (
                  <TextField
                    {...params}
                    data-testid="time_zone"
                    variant="outlined"
                    placeholder="Select"
                    fullWidth
                    error={Boolean(
                      touched && touched.time_zone && errors && errors.time_zone
                    )}
                    helperText={
                      touched && touched.time_zone && errors && errors.time_zone
                    }
                  />
                )}
              />
              <Typography className={classes.helperText}>
                {configJSON.textHintTimezone}
              </Typography>
            </Box>
          </Box>

          {isModalOpened && (
            <Portal>
              <AppointmentHours
                availability={values.availability}
                availabilityErrors={availabilityErrors}
                checkAvailabilityForm={checkAvailabilityForm}
                closeModal={closeModal}
              />
            </Portal>
          )}
        </form>
      </Box>
    </>
  );
};

export default CommonSettingsAdmin;
// Customizable Area End