Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.storeDetailsAPiEndPoint =
  "/bx_block_store_details/public/store_profile";
// Customizable Area End

//Login API's
exports.loginAPiEndPoint = "/bx_block_login/login";
// Customizable Area Start

exports.textInvalidEmailFormat = "Email must be in valid format.";
exports.textThisFieldIsRequired = "This field is required.";
exports.textDefaultStoreName = "Store Name";
exports.textEmail = "Email";
exports.textPassword = "Password";
exports.textForgotPassword = "Forgot password?";
exports.textLoginButton = "Login";
exports.textLoginHeading = "Login";
exports.textErrorInvalidEmail = "Email is invalid";
exports.textErrorInvalidPassword = "Password is invalid";

exports.responseErrorUserNotFound = "Admin user not found";
exports.responseErrorInvalidPassword = "Invalid password";
// Customizable Area End
