Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentTypeApplicationJson = "application/json";

exports.getBrandEndPoint = "catalogue/brands";
exports.updateBrandEndPoint = "bx_block_catalogue/brands";

exports.postMethod = 'POST'
exports.putMethod = 'PUT'
exports.getMethod = 'GET'

exports.textBannerResizeDialogHeader = "Select banner image sizing"
exports.headerText1 = "Navigation item 1";
exports.headerText2 = "Navigation item 2";
exports.headerText3 = "Navigation item 3";
exports.sectionName = "Service Display Section"
exports.SectionsTestimonials = "Homepage Sections";
exports.Testimonial = "Testimonials";
exports.displayTestimonalSection = "Display Testimonials section on the Homepage";
exports.chooseRename = "Choose to rename the Service Display section on Homepage";
// Customizable Area End