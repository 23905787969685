// Customizable Area Start
import React from "react";
import AppCategoryInputController from "./AppCategoryInputController.web";
const configJSON = require("./config");
import _ from "lodash";
import {
    CheckboxInput,
    ImageInput,
    SelectInput,
    TextInput,
  } from "../../ss-cms-common-components/src/Inputs/Inputs.web";

export class AppCategoryInput extends AppCategoryInputController {
  render() {
    const {itemIndex, formikProps, showHeaderBar} = this.props;
    const {isDestroid} = this.state;
    const name = `app_categories_attributes[${itemIndex}]`
    const { handleBlur} = formikProps;
    const id = _.get(formikProps.values, name + "id");
    if(isDestroid) {return null}
    return (<>
      <CheckboxInput
        {...formikProps}
        data-testid="isDestroy"
        showHeaderBar={showHeaderBar}
        name={name + "destroy"}
        label={id ? configJSON.deleteAppType : configJSON.remove}
        onChange={this.handleDestroy}
      />
      <SelectInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        data-testid="appType"
        name={name + "app_type"}
        label={configJSON.appType}
        options={[
          { label: configJSON.labelAndroid, value: "android" },
          { label: configJSON.labelIOs, value: "ios" },
        ]}
      />
      <ImageInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "feature_graphic"}
        label={configJSON.featureGraphic}
        height="100px"
        width="200px"
      />
      <TextInput
        {...formikProps}
        data-testid="product_title"
        showHeaderBar={showHeaderBar}
        name={name + "product_title"}
        label={configJSON.productTitle}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "app_category"}
        label={configJSON.appCategory}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_username"}
        label={configJSON.reviewUsername}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_password"}
        label={configJSON.reviewPassword}
        type="password"
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_notes"}
        label={configJSON.reviewNotes}
      />
      <ImageInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "attachments_attributes"}
        label={configJSON.screenshots}
        multiple
        width="200px"
        height="100px"
      />
      <Divider />
    </>);
  }
}

export const Divider = () => (
    <div />
);
export default AppCategoryInput;
// Customizable Area End