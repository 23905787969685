// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSONUrl = require("../../../framework/src/config");
import moment from "moment";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const navigation = require("react-navigation");
import { IBlock } from "../../../framework/src/IBlock";
import storageProvider  from "../../../framework/src/StorageProvider.web";
import BlockHelpers from "../../utilities/src/BlockHelpers";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    classes: Record<string, string>;
    navigation: typeof navigation;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    orderData: any;
    isValidOrder: boolean;
    isCancelModalOpen: boolean;
    isCanceled: boolean;
    isCanceledValid: boolean;
    isCancelAvailable: boolean;
    isRescheduleAvailable: boolean;
    orderAlreadyCancelled: boolean;
    // Customizable Area End
}
export interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}
export default class OrderStatusController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    cancelBookingAPICallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            orderData: '',
            isValidOrder: false,
            isCancelModalOpen: false,
            isCanceled: false,
            isCanceledValid: false,
            isCancelAvailable: false,
            isRescheduleAvailable: false,
            orderAlreadyCancelled: false
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        mixpanel.track("webcustomer_appointment_Booking_detail");
        const orderData = JSON.parse(await storageProvider.get("bookingData"));
        this.setState({isValidOrder: !orderData.errors},()=>{
            if(this.state.isValidOrder)
            {
                this.setState({ 
                    orderData: orderData?.serializer?.data[0], 
                    isCancelAvailable: orderData?.cancel_status,
                    isRescheduleAvailable : orderData?.reschedule_status,
                    orderAlreadyCancelled: (orderData?.serializer?.data[0]?.attributes?.status === "refund_requested" || orderData?.serializer?.data[0]?.attributes?.status === "cancelled" || orderData?.serializer?.data[0]?.attributes?.status === 'refunded')
                })
            }
        })
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.cancelBookingAPICallId) {
                this.cancelBookingSuccessCallBack(responseJson);
            }
        } else if(message.properties.NavigationTargetMessage === 'OrderStatus')
            {
                const orderData = JSON.parse(await storageProvider.get("bookingData"));
                this.setState({isValidOrder: !orderData.errors, isCanceled: false, isCanceledValid: false},()=>{
                    if(this.state.isValidOrder)
                    {
                        this.setState({ 
                            orderData: orderData?.serializer?.data[0], 
                            isCancelAvailable: orderData?.cancel_status,
                            isRescheduleAvailable : orderData?.reschedule_status,
                            orderAlreadyCancelled: (orderData?.serializer?.data[0]?.attributes?.status === "refund_requested" || orderData?.serializer?.data[0]?.attributes?.status === "cancelled" || orderData?.serializer?.data[0]?.attributes?.status === 'refunded')
                        })
                    }
                })
            }
        // Customizable Area End
    }

    // Customizable Area Start

    momentChangeForAppointmentDate = (appointment_date: string, time_zone_short: string) => {
        return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])")
    }

    momentChangeForOrderData = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
    }

    handleGoToScreen = (isModalOpen : boolean = false) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),'Home');
        if(isModalOpen)
        {
            navigation.addData(getName(MessageEnum.NavigationPayLoadMessage),{openBookingModal: isModalOpen});
        }
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        window.scrollTo({top:0, behavior:"smooth"});
    }
    handleToResheduleScreen = async () => {
        mixpanel.track(configJSON.manageBookingClickedRescheduleBooking);
        storageProvider.set("orderData", JSON.stringify(this.state.orderData));
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"AppointmentReschedule");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"});
        },200)
    }
    toogleCancelModal = () => {
        mixpanel.track(configJSON.manageBookingClickedCancelBooking);
        this.setState({ isCancelModalOpen: !this.state.isCancelModalOpen })
    }

    handleCancelBookingClick = () => {
        mixpanel.track(configJSON.manageBookingBookingCancelled);
        const header = {
            "Content-Type": configJSON.contentTypeApplicationJson
        };
    
        this.cancelBookingAPICallId = BlockHelpers.callApi({
            method: configJSON.putMethod,
            endPoint: `${configJSON.cancelBookingAPIEndPoint}?id=${this.state.orderData.id}`,
            header
        });
    }

    cancelBookingSuccessCallBack = (responseJson: any) => {
        this.setState({isCanceled: true, isCanceledValid: !responseJson.errors} ,()=> {
            this.toogleCancelModal()
            window.scrollTo({top:0, behavior:"smooth"})
        })
    }

    handleMixPanelTrack = () => {
        mixpanel.track(configJSON.appointmentViewPolicies);
    };
    // Customizable Area End
}
