// Customizable Area Start
import React from "react";

const configJSON = require("./config");

type Props = {
  expireDate: Date;
  resendOtp: () => void;
};

export default class CountdownTimerController extends React.Component<
  Props,
  { remainingTime: number }
> {
  interval: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      remainingTime: 300,
    };
  }

  componentDidMount(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = window.setInterval(() => {
      this.setState({
        remainingTime: (this.props.expireDate.getTime() - Date.now()) / 1000,
      });
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  timeToText = (remainingTime: number) => {
    if (remainingTime < 0) {
      return configJSON.textResendOtp;
    }
    const minutes = Math.floor(remainingTime / 60);
    const seconds = Math.floor(remainingTime - minutes * 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
}
// Customizable Area End