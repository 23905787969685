// Customizable Area Start
import React from "react";
import { Switch } from "./Switch.web";
import EmailSettingsController from "./EmailSettingsController.web";

const configJSON = require("./config.js");

export default class EmailSettings extends EmailSettingsController {
  render(): React.ReactNode {
    const { emailSettings } = this.props;

    return (
      <div className="email-setting-box">
        <div className="email-setting-wrapper">
          <div className="email-setting-left">
            <span>{emailSettings?.title}</span>
          </div>
          <div className="email-setting-right">
            <i
              className="icon-pencil edit-icon"
              onClick={this.handleEditClick}
              data-testid={"email-edit-" + emailSettings?.id}
            />
            <Switch
              checked={emailSettings?.active}
              onChange={this.handleChange}
              data-testid={"email-activation-checkbox-" + emailSettings?.id}
            />
            <span className="status">
              {emailSettings?.active ? configJSON.textActive : configJSON.textInactive}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
// Customizable Area End