import React from "react";
// Customizable Area Start
import { Container, Box, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomerController from "./CustomerController";
// Customizable Area End 

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "32px",
      color: "#3C3E49",
      marginBottom: "20px",
      fontFamily: "Rubik",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      color: "#676B7E",
      marginBottom: "20px",
      fontFamily: "Rubik",
    }
  },
  overrides: {
    MuiButton: {
      text: {
        display: "none",
        textTransform: "capitalize"
      }
    }
  }
});
// Customizable Area End

export default class NoCustomer extends CustomerController {
  // Customizable Area Start
  // Customizable Area End
  render() {

    return (
      <ThemeProvider theme={theme}>
        <Box style={webStyles.mainContainer}>
          <Container>
            <Box style={{...webStyles.photoUploadContainer, textAlign: "center"}}>
              <Box>
                <Typography data-test-id="heading" variant="h6">You have no customers</Typography>
                <Box style={webStyles.subContainer}>
                <Typography data-test-id="subheading" variant="subtitle1">
                  You don’t have any customers at the moment. They’ll start appearing here once you get orders in.
                </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent"
  },
  photoUploadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", 
    maxWidth: "684px", 
    minHeight: "396px", 
    margin: "40px auto", 
    padding: "0",
    backgroundColor: "#fff",
  },
  uploadButton: {
    backgroundColor: "#00D659",
    color: "#3C3E49",
    padding:"12px 40px",
    borderRadius: "0px",
    maxHeight: "48px",
    width: "221px",
  },
  subContainer: {
    maxWidth:"395px", 
    height:"95px", 
    display:"flex",
    alignItems:"center"
  }
};
// Customizable Area End
