// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const pencil = require("../assets/pencil.svg");
export const ErrorIcon = require("../assets/Error-cross-circle.svg");
export const CheckboxIcon = require("../assets/Checkbox-2.svg");
export const CheckboxIconChecked = require("../assets/Checkbox-checked.svg");
export const SuccessIcon = require("../assets/SuccessIcon.svg");
export const CheckboxIcon1 = require("../assets/CheckBox.svg");
export const CheckboxIconChecked1 = require("../assets/CheckedBox-checked.svg");
export const StaffDummyImage = require("../../appointmentmanagement/assets/dummyAvatar.png");
// Customizable Area End