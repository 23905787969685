// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseFooter } from "../../utilities/src/helpers/utils";
import { Platform } from "../../utilities/src/models/Platform";
import { Navigation } from "../../utilities/src/models/Navigation";

export const configJSON = require("./config");

export interface Footer {
	data: {
		attributes: {
			footer: {
				id: number,
				copy_right_text: string,
				phone_number: string,
				country_code: string,
				social_media: {
					facebook: {
						selected: boolean,
						url: string
					},
					twitter: {
						selected: boolean,
						url: string
					},
					instagram: {
						selected: boolean,
						url: string
					},
					youtube: {
						selected: boolean,
						url: string
					}
				},
				download_apps: {
					android: {
						selected: boolean,
						url: string
					},
					ios: {
						selected: boolean,
						url: string
					}
				}
			}
		}
	}
}

export interface Props {
  navigation: Navigation;
  identifier: string;
}

interface S {
  countryCode: string;
  phoneNumber: string;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];
  loading:boolean
}

interface SS {
  identifier: string;
}

export type StaticPage = {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      content: string;
    };
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    };
    page_slug: string;
    created_at: string;
    updated_at: string;
  };
};

export default class AppfooterController extends BlockComponent<Props, S, SS> {
  getBrandsApiCallId: string;
  getStaticPagesMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      countryCode: "",
      phoneNumber: "",
      socialMedias: {},
      applications: {},
      copyright: "",
      staticPages: [],
      loading:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBrands();
    this.getStaticPages();
   
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBrandsApiCallId != null &&
      this.getBrandsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({loading:false},()=>{
          this.handleGetBrandsResponse(responseJson);
        })
      } else {
        this.setState({
          countryCode: "",
          phoneNumber: "",
          socialMedias: {},
          applications: {},
          copyright: "",
          loading:false
        });

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if(this.getStaticPagesMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        const responseJSON = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJSON?.data){
          this.setState({
            staticPages: responseJSON.data.filter((staticPage: StaticPage) => staticPage.attributes.active,
           ), 
          })
        }else{
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
  
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
  };

  handleGetBrandsResponse = (
    responseJson: Footer
  ) => {
    const response = responseJson;
    
    const {
      countryCode,
      phoneNumber,
      socialMedias,
      applications,
      copyright,
    } = parseFooter(response);

    this.setState({
      countryCode,
      phoneNumber,
      socialMedias,
      applications,
      copyright,
    });
  };
  
  getBrands = () => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };
    this.getBrandsApiCallId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getBrandsEndPoint,
      header
    });
  };

  getStaticPages = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStaticPagesMessageId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStaticPagesEndPoint,
      header
    });
  }
}
// Customizable Area End
