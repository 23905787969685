// Customizable Area Start
import React from "react";
import {createStyles, WithStyles, withStyles} from "@material-ui/core";

export interface PropsType extends WithStyles {
    title: string,
    children?: object
}


const HeaderWithStyle = ({
  children,
  title,
  classes,
  ...props
}: PropsType) => {
    return (
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>{title}</span>
        </div>
        {children}
      </div>
    );
}


const Header = withStyles((theme) => createStyles({
  header: {
    "width": "100%",
    "padding": "40px 22px 0"
  },
  titleWrapper:{
    "display": "flex",
    "justifyContent": "space-between",
    "alignItems": "center",
    "width": "100%"
  },
  title: {
    "fontStyle": "normal",
    "fontWeight": 500,
    "fontSize": "28px",
    "lineHeight": "32px",
    "display": "flex",
    "alignItems": "center",
    "letterSpacing": "-0.02em",
    "color": "#3c3e49"
  }
}))(HeaderWithStyle);

export default Header;
// Customizable Area End
