import React from "react";
// Customizable Area Start
import { Box, Grid, Card, Typography, Divider, Theme, withStyles, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ResheduleConfirmController, {Props,configJSON} from "./ResheduleConfirmController.web"
import Currency from "../../utilities/src/components/Currency.web";
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import {CheckMarkCurrect} from "./assets";
// Customizable Area End

// Customizable Area Start
export const styles = (theme: Theme) => ({
  confirmationBox: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 20px",
    }
  },
  textFirstBoxStyle: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  dividerBoxStyle: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  textSecondBoxStyle: {
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "14px",
    marginBottom: "20px",
    color: "#3C3E49",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "35px",
    }
  },
  containerBoxStyle: {
    maxWidth: "628px",
    margin: "auto"
  },
  appointmentTextBoxStyle: {
    maxWidth: "628px",
    margin: "auto",
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Rubik",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subTextBoxStyle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#3C3E49",
    lineHeight: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#757575"
    }
  },
  serviceTextBoxStyleSize: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Rubik",
    color: "#757575",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  titleTextStylesSize: {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px",
    paddingBottom: "5px",
  },
  servicePriceBoxStyle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  successIconStyle: {
    width: "82.54px",
    height: "82.54px",
    cursor: "pointer",
    top:"250px",
    marginBottom: "40px",
  },

  footerBoxStyle: {
    borderTop: "1px solid #DFDFDF",
  },
  footerContainerBoxStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px"
  },
  footerTextBoxStyle: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionFirstBoxStyle: {
    borderTop: "1px solid #DFDFDF",
    marginTop: "35px",
    marginBottom: "20px",
  },
  subHeadingBoxStyle: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subContainerBoxStyle: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    borderTop: "1px solid #DFDFDF",
  },
  textConatinerStyle: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px"
  },
  commentBox: {
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    }
  },
  cardContainersStyle: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  imageContainerStyleBox: {
    height: "160px",
    [theme.breakpoints.down(960)]: {
      height: "140px"
    },
    [theme.breakpoints.down(480)]: {
      height: "120px"
    },
  },
  imageTagBoxStyle: {
    objectFit: "cover" as "cover"
  },
  proceedBtnStyle: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '& .MuiButton-label': {
      textTransform: "none"
    },
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    }
  },
  buttonContainerBoxStyle: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.down(500)]: {
      flexDirection: "column-reverse" as 'column-reverse'
    }
  },
  accordionContainerStyle: {
    boxShadow: 'none',
    borderRadius: '5px',
    border: '1px solid #E8E8E8',
    margin: '16px 0',
    '&:before': {
      backgroundColor: 'transparent'
    }
  },
  viewPolicyBoxStyle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },
  policyContainerBoxStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    borderBottom: '1px solid #E8E8E8',
    width: '100%',
    paddingBottom: '16px',
    marginBottom: '16px'
  },
  refundPolicyContainerStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
  },
  policyHeaderStyle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  policyMsgBoxStyle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#3C3E49'
  },
  accordionDetailsContainerStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    maxHeight: '380px',
    overflowY: 'auto' as 'auto',
  }
})
// Customizable Area End

export class ResheduleConfirmStatus extends ResheduleConfirmController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  render() {
    const { classes } = this.props;
    const { orderData } = this.state;
    let paymentMethod = 'pay_online';

    if (this.state.orderDatadata1?.attributes?.payment_mode === "pay_in_person" || this.state.orderDatadata1?.attributes?.payment_mode === "pay_later"){
      paymentMethod = "Pay later at location";
    } else if (this.state.orderDatadata1?.attributes?.payment_mode === "pay_online" || this.state.orderDatadata1?.attributes?.payment_mode === 'pay_now') {
      paymentMethod = "Online";
    } else {
      paymentMethod = "";
    }

    return (
      <>
        <Box className={classes.confirmationBox}>
          <Box style={{ textAlign: "center" }}> 
          <img src={CheckMarkCurrect} alt="icon" className={classes.successIconStyle} />
            <Typography
              className={classes.textFirstBoxStyle}
              style={{ textTransform: "uppercase" }}
            >
              {configJSON.thankyou} {this.state.orderDatadata1?.attributes?.customer.full_name}
            </Typography>
         
            <Divider className={classes.dividerBoxStyle} />
            
            <Typography className={classes.textSecondBoxStyle}>
              {configJSON.yourBookingIsReshedule}{this.state.orderDatadata1?.attributes?.customer.email}
            </Typography>
            
          </Box>
          <Box className={classes.containerBoxStyle}>
          <Card className={classes.cardContainersStyle}>
              <Typography
                className={classes.appointmentTextBoxStyle}
              >
                {configJSON.appointmentSummary}
              </Typography>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subTextBoxStyle}>{configJSON.orderId}{this.state.orderDatadata1?.attributes?.order_number}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subTextBoxStyle}>{configJSON.orderDate}{this.momentChangeForOrderData(this.state.appointmentUpdate1?.attributes?.order_date)}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                <Grid item xs={4} sm={4} md={4} className={classes.imageContainerStyleBox}>
                  <img
                    src={this.state.orderDatadata1?.attributes?.service_images?.small_url ? this.state.orderDatadata1?.attributes?.service_images?.small_url: this.state.orderDatadata1?.attributes?.service_images?.url}
                    alt="img"
                    width="100%"
                    height= "100%"
                    className={classes.imageTagBoxStyle}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Box>
                    <Typography className={classes.serviceTextBoxStyleSize}>
                      {configJSON.service}
                    </Typography>
                    <Typography className={classes.titleTextStylesSize}>
                      {this.state.orderDatadata1?.attributes?.service?.title}, {this.state.orderDatadata1?.attributes?.service?.duration} {configJSON.mins}
                    </Typography>
                    {this.state.orderDatadata1?.attributes?.service_provider?.full_name &&  <Typography className={classes.serviceTextServiceName}>
                      {configJSON.textAppointmentWith}
                      {this.state.orderDatadata1?.attributes?.service_provider?.full_name}
                    </Typography>}
                   
                    <Typography className={classes.servicePriceBoxStyle}>

                    <Currency text={`${String(this.state.orderDatadata1?.attributes?.total?.toFixed(2))} `}
                      disountPrice={String(this.state.orderDatadata1?.attributes?.catalogue_price?.toFixed(2))}
                      time={""}
                      hasDiscount={this.state.orderDatadata1?.attributes?.total !== this.state.orderDatadata1?.attributes?.catalogue_price} /> 
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.sectionFirstBoxStyle}>
                <Typography className={classes.subHeadingBoxStyle}>{configJSON.dateAndTime}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerStyle}>{this.momentChangeForAppointmentDate(this.state.appointmentUpdate1?.attributes?.appointment_date,this.state.appointmentUpdate1?.attributes?.time_zone_short)}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainerBoxStyle}>
                <Typography className={classes.subHeadingBoxStyle}>{configJSON.personalDetails}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerStyle}>{this.state.orderDatadata1?.attributes?.customer.full_name}</Typography>
                  <Typography className={classes.textConatinerStyle}>{this.state.orderDatadata1?.attributes?.customer.email} | {this.state.orderDatadata1?.attributes?.customer.full_phone_number}</Typography>
                  <Typography className={classes.commentBox}>{this.state.orderDatadata1?.attributes?.customer.comment}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainerBoxStyle}>
                <Typography className={classes.subHeadingBoxStyle}>{configJSON.modeOfPayment}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatinerStyle}>{paymentMethod}</Typography>
                </Box>
              </Box>
              <Box className={classes.footerBoxStyle}>
                <Box className={classes.footerContainerBoxStyle}>
                  <Typography className={classes.footerTextBoxStyle}>{configJSON.total}</Typography>
                  <Typography className={classes.footerTextBoxStyle}>
                  <Currency
                    text={`${this.state.orderDatadata1?.attributes?.total?.toFixed(2)} `}
                    disountPrice={""}
                    time={""}
                    hasDiscount={false}
                  />
                  </Typography>
                </Box>
              </Box>
              <Accordion className={classes.accordionContainerStyle}>
                <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.viewPolicyBoxStyle}
                >
                  View Policies
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainerStyle}>
                  <Box className={classes.policyContainerBoxStyle}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.cancellationPolicy}
                    </Typography>
                    <Typography className={classes.policyMsgBoxStyle}>
                    {this.state.orderDatadata1?.attributes?.cancellation_policy?.no_of_days_cancel} {configJSON.cancellationPolicyMsg}
                    </Typography>
                    <Typography className={classes.policyMsgBoxStyle} style={{ color: '#676B7E' }}>
                      {this.state.orderDatadata1?.attributes?.cancellation_policy?.cancel_text}
                    </Typography>
                  </Box>
                  <Box className={classes.policyContainerBoxStyle}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.reschedulePolicy}
                    </Typography>
                    <Typography className={classes.policyMsgBoxStyle}>
                    {this.state.orderDatadata1?.attributes?.reschedule_policy?.no_of_days_reschedule} {configJSON.reschedulePolicyMsg}
                    </Typography>
                    <Typography className={classes.policyMsgBoxStyle} style={{ color: '#676B7E' }}>
                      {this.state.orderDatadata1?.attributes?.reschedule_policy?.reschedule_text}
                    </Typography>
                  </Box>

                  {this.state.orderDatadata1?.attributes?.refund_policy?.is_refund_policy &&  <Box className={classes.refundPolicyContainerStyle}>
                    <Typography className={classes.policyHeaderStyle}>
                      {configJSON.refundPolicy}
                    </Typography>
                    <Typography className={classes.policyMsgBoxStyle} style={{ color: '#676B7E' }}>
                      {this.state.orderDatadata1?.attributes?.refund_policy?.refund_text}
                    </Typography>
                  </Box>
                  }
                </AccordionDetails>
              </Accordion>
              <Box className={classes.buttonContainerBoxStyle}>
                <Button
                  variant="contained"
                  className={classes.proceedBtnStyle}
                  onClick={this.handleBack}
                >
                  {configJSON.ExploreMoreServices}
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ResheduleConfirmStatus);
// Customizable Area End