// Customizable Area Start
import React, { Suspense } from "react";
import {
  Box,
  List,
  ListItem,
  Divider,
  SvgIcon,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { Dashboard as useStyles } from "./styles/Dashboard.web";
const TopServices = React.lazy(() => import("./TopServices.web"));
const Location = React.lazy(() => import("./Location.web"));
import { ReactComponent as StarRateIcon } from "../assets/Rate-Star.svg";
import { Location as LocationDetails } from "../../utilities/src/models/Location";
import { Banner } from "./DashboardController.web";
import { Service } from "../../utilities/src/models/Service";
import Skeleton from "@material-ui/lab/Skeleton";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
export const configJSON = require("./config");
import ProgressiveImage  from 'react-progressive-image';
interface Props {
  banners: Banner[];
  topServices: Service[];
  showTestimonials: boolean;
  testimonials: Record<string, string | number>[];
  locationDetails: LocationDetails | null;
  testimonialName: string;
}

const Dashboard: React.FC<Props> = ({
  banners,
  topServices,
  showTestimonials,
  testimonials,
  locationDetails,
  testimonialName,
  
}) => {
  const classes = useStyles();
  const [firstBanner, secondBanner, thirdBanner] = banners;

  return (
    <Box className={classes.container}>
      <Box className={classes.containerHeight}>
        {firstBanner ?
          <ProgressiveImage delay={1000} src={firstBanner?.small_url.url} placeholder={firstBanner.thumbnail.url}>
            {(src: string) =>
              <img
                src={src}
                className={classes.banner}
                alt="image loading"
                onClick={() => {
                  mixpanel.track("webcustomer_banner_1_click");
                  window.open(firstBanner.redirect_url);
                }}
              />
            }
          </ProgressiveImage>
          : <Skeleton variant="rect" className={classes.ServiceListSkeltonBox} />
        }
      </Box>

      <Box className={classes.sectionWrapper}>
        <Suspense fallback={<CircularProgress />}>
          <TopServices services={topServices} testimonialName={testimonialName} />
        </Suspense>
      </Box>

      <Box className={classes.containerHeight}>
        {secondBanner ?
          <ProgressiveImage delay={1000} src={secondBanner?.small_url.url} placeholder={secondBanner.thumbnail.url}>
            {(src: string) =>
              <img
                src={src}
                className={classes.banner}
                alt="image loading"
                onClick={() => {
                  mixpanel.track("webcustomer_banner_2_click");
                  window.open(secondBanner.redirect_url);
                }}
              />
            }
          </ProgressiveImage>
          : <Skeleton variant="rect" className={classes.ServiceListSkeltonBox} />
        }
      </Box>
     

      {testimonials.length > 0 ? (
        <Box className={classes.sectionWrapper}>
          <Box className={classes.sectionTitleWrapper}>
            <Typography className={classes.sectionTitle}>{configJSON.textTESTIMONIALS}</Typography>
            <Divider className={classes.titleDivider} />
          </Box>
          <List className={classes.testimonials}>
            {testimonials.map((testimonial, index) => (
              <ListItem key={index} className={classes.testimonial}>
                <Typography className={classes.testimonialUsername}>
                  {testimonial.name}
                </Typography>
                <Box className={classes.testimonialStarsWrapper}>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <SvgIcon
                        key={index}
                        component={StarRateIcon}
                        className={`${classes.testimonialStar} ${index < +testimonial.rating
                            ? classes.testimonialRated
                            : classes.testimonialUnrated
                          }`}
                      />
                    ))}
                </Box>
                <Typography className={classes.testimonialDescription}>{testimonial.description}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )
        : <Skeleton variant="rect" className={classes.testimonialSkeltonBox} />
      }
      
      <Box className={classes.containerHeight}>
        {thirdBanner ?
          <ProgressiveImage delay={1000} src={thirdBanner?.small_url.url} placeholder={thirdBanner.thumbnail.url}>
            {(src: string) =>
              <img
                src={src}
                className={classes.banner}
                alt="image loading"
                onClick={() => {
                  mixpanel.track("webcustomer_banner_3_click");
                  window.open(thirdBanner.redirect_url);
                }}
              />
            }
          </ProgressiveImage>
          : <Skeleton variant="rect" className={classes.ServiceListSkeltonBox} />
        }
      </Box>
     
     
      {locationDetails && (
        <Box className={classes.sectionWrapper}>
          <Suspense fallback={<CircularProgress />}>
            <Location locationDetails={locationDetails} />
          </Suspense>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
// Customizable Area End
