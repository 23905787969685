// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bf",
    position: "fixed",
    top: 0,
  },
  container: {
    position: "absolute",
    width: "684px",
    height: "277px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "3px",
  },
  wrapper: {
    height: "132px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    padding: theme.spacing(5),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(5),
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
  },
  iconWrapper: {
    padding: 0,
  },
  closeIcon: {
    fontSize: "32px",
    color: "#000000",
  },
  message: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3C3E49",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid #E8E8E8",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  cancelButton: {
    textTransform: "none",
    textDecoration: "underline",
    padding: theme.spacing(2.5, 6),
    color: "#3C3E49",
  },
  submitButton: {
    textTransform: "none",
    borderRadius: "3px",
    padding: theme.spacing(2.5, 6),
    backgroundColor: "#FF1744",
    color: "#FFFFFF",
  },
}));
// Customizable Area End