import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Service } from "../../utilities/src/models/Service";
// Customizable Area Start
export const configJSONUrl = require("../../../framework/src/config");
import moment from "moment";
import { BookingDataInterface, CommonSettingObj } from "./AppointmentsController.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const navigation = require("react-navigation");
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    classes: Record<string, string>;
    bookingData: BookingDataInterface;
    service: Service;
    paymentOption: string;
    navigation: typeof navigation;
    commonSetting: CommonSettingObj;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    expandedPolicies: boolean;
    // Customizable Area End
}

export interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class ConfirmationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            expandedPolicies : false
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        mixpanel.track("webcustomer_appointment_Booking_Confirm");
        // Customizable Area End
    }
    // Customizable Area Start

    momentChangeForAppointmentDate = (appointment_date: string, time_zone_short: string) => {
        return moment.utc(appointment_date).format("dddd, Do MMMM YYYY | h:mm A ([" + time_zone_short + "])")
    }

    momentChangeForOrderData = (order_date: string) => {
    return moment.utc(order_date).format("MMMM Do YYYY, LT");
    }

    handleGoToHomeScreen = () => {
        setTimeout(()=>{
            window.scrollTo({top:0, behavior:"smooth"});
        },200)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"Home");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
        this.send(navigation);
        window.scrollTo({top:0, behavior:"smooth"});
    };

    handleExpandPolicies = () => {
        this.setState({ expandedPolicies: !this.state.expandedPolicies });
    }
    // Customizable Area End
}
