Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'
exports.appointmentAPiEndPoint = 'bx_block_appointment_management/availabilities'
exports.addappointmentAPiEndPoint = 'bx_block_appointment_management/appointments'
exports.appointmentValidationAPiEndPoint = 'bx_block_appointment_management/appointments/validate_appointment'
exports.orderResheduleApiEndPoint = 'bx_block_appointment_management/appointments/'
exports.catalogueAPIEndPoint = 'bx_block_catalogue/catalogues'
exports.addAppointmentAPiMethod = 'POST'
exports.orderResheduleApiMethod = 'PUT'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'
exports.cancelBookingAPIEndPoint = 'bx_block_appointment_management/appointments/appointment_cancel_customer'
exports.putMethod = 'PUT';
exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.stripeAPiMethod = 'POST'
exports.postStripePaymentApiEndPoints = 'bx_block_stripe_integration/payments'
exports.dataEncryptionEndPoint = 'bx_block_ccavenue_integration/encrypts_and_decrypts/check_out_encrypt_data'
exports.bookAppointment = "Book appointment"
exports.dateAndTime = "Date & time"
exports.availableTimes = "Available times"
exports.in = "in"
exports.noSlotsAvailable = "No slots available"
exports.appointmentSummary = "Appointment summary"
exports.service = "Service"
exports.serviceName = "Services Name"
exports.mins = "mins"
exports.total = "Total"
exports.proceed = "Proceed"
exports.payNow = "Pay now"
exports.cancelBackToAllServices = "Cancel, back to all services"
exports.cancelBackToAllStylists = "Back"
exports.RescheduleBookingappointment = "Reschedule Booking appointment "
exports.personalDetails = "Personal details"
exports.name = "Name *"
exports.email = "Email *"
exports.mobile = "Mobile *"
exports.country = "Country *"
exports.address = "Address"
exports.addressLine1 = "Address line 1 *"
exports.addressLine2 = "Address line 2"
exports.city = "City *"
exports.state = "State *"
exports.zipCode = "Zip code *"
exports.comments = "Comments"
exports.totalWordLimit = "/300"
exports.paymentLocation = "Payment location"
exports.flatHouseApartment = "Flat / House / Apartment No."
exports.areYouSure = "Are you sure you want to place the order?"
exports.no = "No"
exports.yes = "Yes"
exports.ok = "OK"
exports.alert = "Alert"
exports.close = "Close"
exports.thankyou = "THANK YOU,"
exports.sorry = "SORRY,"
exports.yourBookingIsConfirmed = "Your booking is confirmed. We have sent a confirmation email to "
exports.yourBookingIsReshedule = "Your booking is rescheduled. We have sent a confirmation email to "
exports.yourCancelConfirmed = "Your booking was declined!. Due to some issue."
exports.orderId = "Order ID: "
exports.orderDate = "Order Date : "
exports.appointmentDateAndTime = "Appointment Date & Time"
exports.serviceDateAndTime = "Date & Time"
exports.modeOfPayment = "Mode of Payment"
exports.changePaymentMethod = "Change the Payment Method"
exports.CancelTransaction = "Cancel transaction?"
exports.service1 = "Services offered by"
exports.serviceProvider  = "bx_block_account_groups/customer_service_providers/";
exports.serviceProviderEndpint  = "service_provider_checkout";
exports.textAppointmentWith = "Appointment with ";
exports.textedit = "Edit";
exports.serviceProviderAvailabilityEndPoint = "bx_block_account_groups/customer_service_providers/service_provider_availabilites";
exports.brnadEndPoint  = "bx_block_catalogue/brands";
exports.serviceProviderTitle = "Select Service Provider";
exports.serviceProvidersApiEndPoint = "bx_block_catalogue/catalogues/service_providers"
exports.OrderSummeryEndPoint = "bx_block_appointment_management/appointments/manage_booking?"
exports.getServiceProvidersListAPiMethod = 'GET'
exports.serviceProvidersApiContentType = 'application/json'
exports.ExploreMoreServices = "Explore More Services";
exports.viewPolicies = "View Policies";
exports.cancelPolicies = "Cancellation Policy";
exports.rechedulePolicies = "Reschedule Policy";
exports.refundPolicies = "Refund Policy";
exports.commonSettingEndPoint = "bx_block_settings/common_settings";
exports.manageBooking = 'Manage Booking';
exports.rescheduleBooking ='Reschedule Booking';
exports.cancelBooking = 'Cancel Booking';
exports.noBookingAlert = 'Sorry, no booking available';
exports.noBookingMsg = 'on your given booking id and email address,';
exports.checkAgain = 'check again';
exports.cancellationPolicy = 'Cancellation Policy';
exports.cancellationPolicyMsg = 'Days within which cancellations can be accommodated.';
exports.reschedulePolicy = 'Reschedule Policy';
exports.reschedulePolicyMsg = 'Days within which reschedule can be accommodated.';
exports.refundPolicy = 'Refund Policy';
exports.cancleMore = "More Description of cancellation policy";
exports.refundMore = "More Description of refund  policy";
exports.rescheduleMore = "More Description of reschedule policy";
exports.cancelBookingAlert = 'Are you sure you want to Cancel Booking?';
exports.notCancelMessage = 'Sorry, this booking cannot be cancelled.';
exports.cancelSuccessfully = 'Booking cancelled successfully.';
exports.cancelledMsg='This appointment is cancelled';
exports.selectServiceProviderEvent='webcustomer_select_service_provider_from_service';
exports.RescheduleProceedFromDateTime = "webcustomer_appointmentReschedule_proceed_from_date_time";
exports.appointmentRescheduleSuccess = "webcustomer_appointmentReschedule_success";
exports.manageBookingClickedRescheduleBooking = "webcustomer_manage_booking_clicked_reschedule_booking";
exports.manageBookingClickedCancelBooking = "webcustomer_manage_Booking_clicked_cancel_booking";
exports.manageBookingBookingCancelled = "webcustomer_manage_Booking_booking_cancelled";
exports.appointmentViewPolicies = "webcustomer_appointment_View_policies";
exports.appointmentReschedulePageEnter = "webcustomer_appointment_reschedule_page_enter";
exports.appointmentSuccessCceavenue = "webcustomer_appointment_success_cceavenue";
exports.appointmentFailCceavenue = "webcustomer_appointment_fail_cceavenue";
exports.appointmentPageEnter = "webcustomer_appointment_page_enter";
exports.appointmentSuccessPayInPerson = "webcustomer_appointment_success_pay_in_person";
exports.appointmentSuccessStripe = "webcustomer_appointment_success_stripe";
exports.appointmentCceavenuePayment = "webcustomer_appointment_cceavenue_payment";
exports.appointmentStripePayment = "webcustomer_appointment_stripe_payment";
exports.appointmentCreatePayInPerson = "webcustomer_appointment_create_pay_in_person";
exports.appointmentCreatePayNow = "webcustomer_appointment_create_pay_now";
exports.appointmentFailStripe = "webcustomer_appointment_fail_stripe";
exports.appointmentProceedFromStaffService = "webcustomer_appointment_proceed_from_staff_service";
exports.appointmentProceedFromDateTime = "webcustomer_appointment_proceed_from_date_time";
exports.appointmentProceedToPersonalDetails = "webcustomer_appointment_proceed_to_personal_details";
// Customizable Area End
