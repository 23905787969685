// Customizable Area Start
import React, { useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    List,
    RadioGroup,
    FormControlLabel,
    ListItem,
    TextField,
    IconButton,
    Collapse,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles/Itemavailability.web";
import {
    Add as AddIcon,
    Remove as RemoveIcon,
} from "@material-ui/icons";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { Availability } from "../../utilities/src/models/Availability";
import moment from "moment";
import { ErrorIcon, CheckboxIcon, CheckboxIconChecked } from "./assets";
import { withStyles } from '@material-ui/core/styles';
import { green, blueGrey } from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
const initialAvailabilityValues = [
    {
        day: "Monday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Tuesday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Wednesday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Thursday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Friday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Saturday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
    {
        day: "Sunday",
        workingHours: [
            {
                openingTime: "",
                closingTime: "",
            },
        ],
        selected: false,
    },
];
const GreenRadio = withStyles({
    root: {
        color: blueGrey[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);
const availabilitySchema = Yup.array().of(
    Yup.object().shape({
        day: Yup.string(),
        workingHours: Yup.array().of(
            Yup.object().shape({
                openingTime: Yup.string()
                    .test("required", "Opening time is required", function () {
                        const { from } = this;
                        if (from && from.length) {
                            if (from[1].value.selected) {
                                return !!from[0].value.openingTime;
                            } else {
                                return (
                                    !from[0].value.closingTime || !!from[0].value.openingTime
                                );
                            }
                        }
                    })
                    .matches(
                        /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
                        "Opening time must be in 12-hour format"
                    ),
                closingTime: Yup.string()
                    .test("required", "Closing time is required", function () {
                        const { from } = this;
                        if (from && from.length) {
                            if (from[1].value.selected) {
                                return !!from[0].value.closingTime;
                            } else {
                                return (
                                    !from[0].value.openingTime || !!from[0].value.closingTime
                                );
                            }
                        }
                    })
                    .matches(
                        /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$|^$/,
                        "Closing time must be in 12-hour format"
                    )
                    .when(
                        "openingTime",
                        ([openingTime], schema: any) =>
                            openingTime &&
                            schema.test({
                                test: (closingTime: string) => {
                                    if (closingTime) {
                                        const openingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${openingTime}`, "DD/MM/yyyy h:mm a").unix()
                                        const closingDateTime = moment(`${moment().format("DD/MM/yyyy")} ${closingTime}`, "DD/MM/yyyy h:mm a").unix()
                                        return closingDateTime > openingDateTime;
                                    }
                                },
                                message: "Closing time should be after Opening time",
                            })
                    ),
            })
        ),
        selected: Yup.bool(),
    })
);


interface Props {
    availability: Availability[];
    availabilityErrors: string[];
    checkAvailabilityForm: (confirmedAvailability: Availability[]) => void;
    closeModal: () => void;
    availabilityModal: () => void;
    instance: any;
    fullName: any;
}

const Itemavailability: React.FC<Props> = ({
    availability,
    availabilityErrors,
    checkAvailabilityForm,
    closeModal,
    instance,
    availabilityModal,
    fullName,
}) => {
    const classes = useStyles();

    const initialValues = availability;

    const formik = useFormik({
        initialValues,
        validationSchema: availabilitySchema,
        onSubmit: checkAvailabilityForm,
    });

    const {
        errors,
        getFieldProps,
        handleSubmit,
        setFieldTouched,
        setValues,
        touched,
        values,
    } = formik;
    const handleCheckBox = (value: any) => {
        if (instance.state.radioSelection == value) {
            instance.setState({ radioSelection: 'default' })
        }
        else {
            instance.setState({ radioSelection: value })
        }
    }

    const dateFormat = (date: any) => {
        const parsedDate = moment(date);
        const formattedTime = parsedDate.utc().format('hh:mm A');
        return formattedTime;
    }

    const isAvailableCheck = (day: any) => {
        const isDataAvailable = instance.state.defaultAppointmentHours.some((item: any) => item.week_day == day);

        if (isDataAvailable) {
            return true;
        } else {
            return false;
        }
    }
    const removeWorkingHour = (dayIndex: number, workingHourIndex: number) => {
        setValues((currentAvailability) =>
            currentAvailability.map((availability, index) => {
                if (index === dayIndex) {
                    const newWorkingHours = availability.workingHours.filter(
                        (_, index) => index !== workingHourIndex
                    );

                    const fieldPath = `[${dayIndex}].workingHours[${workingHourIndex}]`;
                    setFieldTouched(`${fieldPath}.openingTime`, false);
                    setFieldTouched(`${fieldPath}.closingTime`, false);

                    return {
                        ...availability,
                        workingHours: newWorkingHours,
                    };
                }
                return availability;
            })
        );
    };

    const addWorkingHour = (dayIndex: number) => {
        setValues((currentAvailability) =>
            currentAvailability.map((availability, index) => {
                if (index === dayIndex) {
                    const newWorkingHours = [
                        ...availability.workingHours,
                        {
                            openingTime: "",
                            closingTime: "",
                        },
                    ];

                    const fieldPath = `[${dayIndex}].workingHours[${availability.workingHours.length}]`;
                    setFieldTouched(`${fieldPath}.openingTime`, false);
                    setFieldTouched(`${fieldPath}.closingTime`, false);

                    return {
                        ...availability,
                        workingHours: newWorkingHours,
                    };
                }
                return availability;
            })
        );
    };
    
    useEffect(() => {
        if(instance.state.selectedCustomHours === "default") {
            setValues(initialAvailabilityValues);
        }
    },[instance.state.selectedCustomHours])

    return (
        <Box className={classes.backdrop}>
            <form onSubmit={handleSubmit} className={classes.container}>
                <Collapse in={!!availabilityErrors.length} mountOnEnter unmountOnExit>
                    <Box className={classes.errorWrapper}>
                        <Box className={classes.error}>
                            {availabilityErrors.length > 0 && (
                                <>
                                    <img
                                        src={ErrorIcon}
                                        className={classes.errorIcon}
                                        alt="image loading"
                                    />
                                    <Box className={classes.errorMessages}>
                                        {availabilityErrors.map((error, index) => (
                                            <Typography key={index} className={classes.errorText}>
                                                {error}
                                            </Typography>
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Collapse>

                <Box className={classes.wrapper}>
                    <Box className={classes.header}>
                        <Typography className={classes.title}>
                            Edit availability
                        </Typography>

                        <IconButton className={classes.iconWrapper} onClick={closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M0.75 23.249L23.25 0.749023" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M23.25 23.249L0.75 0.749023" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </IconButton>
                    </Box>



                    <Box className={classes.content}>
                        <Box>
                            <Typography className={classes.inputlabel}>
                                Staff member name *
                            </Typography>
                            <Typography className={classes.userName}>
                                {fullName}
                            </Typography>
                        </Box>


                        <Box style={{ margin: '20px 0px' }}>
                            <Typography className={classes.inputlabel2}>
                                Staff hours *
                            </Typography>
                            <Box>
                                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControlLabel
                                        value="default"
                                        checked={instance.state.radioSelection === 'default'}
                                        onChange={() => handleCheckBox("default")}
                                        control={<Radio className={classes.radioChecked} />}
                                        label="Default hours"
                                        classes={{ label: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="custom"
                                        checked={instance.state.radioSelection === 'custom'}
                                        onChange={() => handleCheckBox("custom")}
                                        control={<Radio className={classes.radioChecked} />}
                                        label="Custom hours"
                                        classes={{ label: classes.radioLabel }}
                                    />

                                </RadioGroup>
                            </Box>
                            <hr />

                        </Box>
                        {instance.state.radioSelection == 'default' &&
                            <Box>
                                <table>
                                    <tr style={{ fontSize: '14px', fontWeight: 500, color: '#3C3E49' }}><td style={{ paddingRight: '65px', paddingBottom: '23px' }}>Day</td> <td style={{ padding: '0px 45px', paddingBottom: '23px' }}>Working hours</td></tr>
                                    {instance.state.defaultAppointmentHours && instance.state.defaultAppointmentHours.map((value: any) => {
                                        return (
                                            value.selected && <tr style={{ fontSize: '14px', fontWeight: 400, color: '#3C3E49' }}><td style={{ paddingRight: '65px', paddingBottom: '11px' }}>{value.week_day}</td> <td style={{ padding: '0px 45px', paddingBottom: '11px', fontVariantNumeric: 'tabular-nums' }}>{dateFormat(value.start_time)} - {dateFormat(value.end_time)}</td></tr>
                                        )
                                    })}
                                </table>
                            </Box>
                        }
                        {instance.state.radioSelection == 'custom' && <>
                            <Box className={classes.subheaders}>
                                <Typography className={classes.subtitle}>Day</Typography>
                                <Typography className={classes.subtitle}>
                                    Working hours
                                </Typography>
                            </Box>

                            <List className={classes.list}>
                                {values.map(({ day, workingHours, selected }, index) => (
                                    <>
                                        {isAvailableCheck(day) ?
                                            <ListItem
                                                key={index}
                                                data-testid={`${day}-availability`}
                                                disableGutters
                                                className={classes.listItem}
                                            >
                                                <Box className={classes.dayWrapper}>
                                                    <Checkbox
                                                        className={classes.checkbox}
                                                        checked={selected}
                                                        {...getFieldProps(`[${index}].selected`)}
                                                        icon={
                                                            <img src={CheckboxIcon} alt="image loading" width={20} height={20} />
                                                        }
                                                        checkedIcon={
                                                            <img src={CheckboxIconChecked} alt="image loading"/>
                                                        }
                                                    />
                                                    <Typography className={classes.day}>{day}</Typography>
                                                </Box>
                                                <List>
                                                    {workingHours.map((_, hourIndex) => (
                                                        <ListItem
                                                            key={`${index}-${hourIndex}`}
                                                            disableGutters
                                                            className={classes.workingRange}
                                                        >
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="hh:mm AM/PM"
                                                                inputProps={{ className: classes.input }}
                                                                className={classes.field}
                                                                disabled={!selected}
                                                                {...getFieldProps(
                                                                    `[${index}].workingHours[${hourIndex}].openingTime`
                                                                )}
                                                                error={Boolean(
                                                                    touched &&
                                                                    touched[index] &&
                                                                    touched[index]!.workingHours &&
                                                                    touched[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        touched[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).openingTime &&
                                                                    errors &&
                                                                    errors[index] &&
                                                                    errors[index]!.workingHours &&
                                                                    errors[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        errors[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).openingTime
                                                                )}
                                                                helperText={
                                                                    touched &&
                                                                    touched[index] &&
                                                                    touched[index]!.workingHours &&
                                                                    touched[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        touched[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).openingTime &&
                                                                    errors &&
                                                                    errors[index] &&
                                                                    errors[index]!.workingHours &&
                                                                    errors[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        errors[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).openingTime
                                                                }
                                                            />
                                                            <Typography className={classes.separator}>-</Typography>
                                                            <TextField
                                                                variant="outlined"
                                                                placeholder="hh:mm AM/PM"
                                                                inputProps={{ className: classes.input }}
                                                                className={classes.field}
                                                                disabled={!selected}
                                                                {...getFieldProps(
                                                                    `[${index}].workingHours[${hourIndex}].closingTime`
                                                                )}
                                                                error={Boolean(
                                                                    touched &&
                                                                    touched[index] &&
                                                                    touched[index]!.workingHours &&
                                                                    touched[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        touched[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).closingTime &&
                                                                    errors &&
                                                                    errors[index] &&
                                                                    errors[index]!.workingHours &&
                                                                    errors[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        errors[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).closingTime
                                                                )}
                                                                helperText={
                                                                    touched &&
                                                                    touched[index] &&
                                                                    touched[index]!.workingHours &&
                                                                    touched[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        touched[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).closingTime &&
                                                                    errors &&
                                                                    errors[index] &&
                                                                    errors[index]!.workingHours &&
                                                                    errors[index]!.workingHours![hourIndex] &&
                                                                    (
                                                                        errors[index]!.workingHours![
                                                                        hourIndex
                                                                        ] as FormikErrors<
                                                                            (typeof workingHours)[typeof hourIndex]
                                                                        >
                                                                    ).closingTime
                                                                }
                                                            />
                                                            {hourIndex > 0 ? (
                                                                <IconButton
                                                                    className={`${classes.iconWrapper} ${classes.availabilityIcon}`}
                                                                    disabled={!selected}
                                                                    onClick={() => removeWorkingHour(index, hourIndex)}
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton
                                                                    className={`${classes.iconWrapper} ${classes.availabilityIcon}`}
                                                                    disabled={!selected}
                                                                    onClick={() => addWorkingHour(index)}
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                            )}
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </ListItem>
                                            : ""}
                                    </>
                                ))}
                            </List>
                        </>}
                    </Box>
                </Box>

                <Box className={classes.actions}>
                    <Button
                        variant="text"
                        className={classes.cancelButton}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.saveButton}
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default Itemavailability;
// Customizable Area End