import React from "react";
// Customizable Area Start
import AccountGroupsController, {
    Props,
} from "./AccountGroupsController";
import AddStaff from "./addStaff.web";
// Customizable Area End

export default class AddStaffWrapper extends AccountGroupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        const itemId = this.props.navigation.getParam("id");
        if(itemId){
            this.getEditData(itemId)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <AddStaff
                isLoading={this.state.isLoading}
                instance={this}
            />
            //Merge Engine End DefaultContainer
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
