Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.CustomerApiContentType = "application/json";
exports.getCustomerAPiEndPoint = "bx_block_appointment_management/personal_details";
exports.searchCustomerApiEndPoint = 'bx_block_appointment_management/personal_details/search_details';
exports.routeDownloadCSV = "/bx_block_appointment_management/personal_details/download_csv.csv";
exports.textCSVFileName = "customers.csv"

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "customer";
exports.labelBodyText = "customer Body";

exports.btnExampleTitle = "CLICK ME";

exports.paginationText = "customers";
exports.inboundQueries = "Inbound queries";
// Customizable Area End