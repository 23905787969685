// Customizable Area Start
import React, { Suspense } from "react";
import CataloguesController, { Props } from "./CataloguesController.web";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import {
  Box,
  CardMedia,
  Card,
  List,
  ListItem,
  CardContent,
  Breadcrumbs,
  CircularProgress,
  Chip,
  withStyles,
  Theme,
  createStyles,
  Button,
} from "@material-ui/core";
import {
  NavigateNext as NavigateNextIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { ServiceImage } from "../../utilities/src/models/ServiceImage";
import Currency from "../../utilities/src/components/Currency.web";
import { IconNotFound, IconFilter } from "./assets";
import Skeleton from "@material-ui/lab/Skeleton";
const FilterItems = React.lazy(() => import('./FilterItems.web'));
const FilterOptions = React.lazy(() => import('./FilterOptions.web'));
const ImageCard = React.lazy(() => import('../../utilities/src/components/ImageCard.web'));
const Pagination = React.lazy(() => import('../../ss-cms-common-components/src/Pagination/Pagination'));
const configJSON = require("./config");
// Customizable Area End
class ServicesWrapper extends CataloguesController {
  render() {
    // Customizable Area Start
    const {
      searchQuery,
      isFilterDrawerOpen,
      pagination,
      categories,
      services,
      loading,
      tempSelectedCategories,
      screenSize
    } = this.state;

    const style = this.props.classes;

    const skeletonData = [1,2,3,4,5,6];
    return (
      <>
        <Box className={style.breadcrumbsWrapper}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{color: "black"}}/>}>
            <Box className={style.breadcrumbLink} onClick={this.goBack}>
              <Typography>{configJSON.textHome}</Typography>
            </Box>
            <Typography className={style.breadcrumbText}>
              {this.state.renameService ? this.state.renameService : "Services"}
            </Typography>
          </Breadcrumbs>

          {screenSize < 1280 && (
            <Box
              className={style.filterButton}
              onClick={this.toggleFilterDrawer}
            >
              <CardMedia
                component="img"
                image={IconFilter}
                className={style.filterIcon}
              />
              <Typography className={style.filterButtonText}>
                {configJSON.textFilter}
              </Typography>
            </Box>
          )}
        </Box>
      <Suspense fallback={<CircularProgress/>}>
        <FilterItems
          options={categories}
          isDrawerOpen={isFilterDrawerOpen}
          toggleDrawer={this.toggleFilterDrawer}
          tempSelectedCategories={tempSelectedCategories}
          resetCategoriesSelected={this.resetCategoriesSelected}
          applyCategoriesSelected={this.applyCategoriesSelected}
          discardCategoriesSelected={this.discardCategoriesSelected}
          selectCategory={this.selectCategory}
        />
      </Suspense>
      <Box className={style.container}>
        {screenSize > 1280 && (
          <Box className={style.filter}>
           {!loading ? 
            <Suspense fallback={<CircularProgress/>}>
              <FilterOptions
                options={categories}
                tempSelectedCategories={tempSelectedCategories}
                selectCategory={this.selectCategory}
              />
            </Suspense>
                :
                <Box style={{ flexDirection: "row", width: "100%" }}>
                  <Skeleton width={"100%"} />
                  <Skeleton width={"90%"} />
                  <Skeleton width={"80%"} />
                  <Skeleton width={"70%"} />
                </Box>
              } 
          </Box>
        )}

          <Box className={style.resultsWrapper}>
            {searchQuery && (
              <Chip
                label={searchQuery}
                variant="outlined"
                className={style.chip}
                onDelete={this.deleteChip}
                deleteIcon={<CloseIcon className={style.deleteChipIcon} />}
              />
            )}

            {loading && (
              <Box className={style.loaderContainer}>
                {skeletonData.map((item) => {
                  return (
                    <>
                        <Box className={style.skeleton}>
                          <Skeleton variant="rect" width={424} height={352} />
                          <Skeleton />
                          <Skeleton width={"60%"} />
                        </Box>
                    </>
                  )
                })}
              </Box>
            )}

            {!loading && services.length > 0 && (
              <Box className={style.listWrapper}>
                <List className={style.list}>
                  {services.map((catalogue, index) => (
                    <ListItem key={index} className={style.item}>
                      <Link
                        className={style.link}
                        to={`/Service/${catalogue.id}`}
                        onClick={() =>
                          mixpanel.track("webcustomer_services_select_item", {
                            itemId: catalogue.id,
                          })
                        }
                      >
                        <Card className={style.card}>
                          {
                            catalogue ? 
                          <Suspense fallback={<CircularProgress/>}>
                            <ImageCard
                              imageClassName={style.image}
                              image={
                                 catalogue.images &&
                                catalogue.images[0] &&
                                (catalogue.images[0] as ServiceImage).small_url
                              }
                              alt="image loading"
                            />
                          </Suspense>
                          :
                          <Skeleton variant="rect" width={210} height={118} />
                          }
                          <CardContent className={style.content}>
                            {catalogue ?
                              <Typography className={style.name}>
                                {catalogue.title}
                              </Typography>
                              :
                              <Skeleton />
                            }

                            {catalogue ?
                              <>
                                <Typography className={style.briefWeb}>
                                  <Currency
                                    text={`${catalogue.discountedPrice.toFixed(2)} ${" "}`}
                                    disountPrice={`${catalogue.undiscountedPrice.toFixed(2)}`}
                                    time={` | ${catalogue.duration} ${configJSON.textMins}`}
                                    hasDiscount={catalogue.hasDiscount}
                                  />
                                </Typography>
                                <Typography className={style.briefMobile}>
                                  <Currency
                                    text={`${catalogue.discountedPrice.toFixed(2)} ${" "}`}
                                    disountPrice={`${catalogue.undiscountedPrice.toFixed(2)}`}
                                    time={<><br />{catalogue.duration} {configJSON.textMins}</>}
                                    hasDiscount={catalogue.hasDiscount}
                                  />
                                </Typography>
                              </>
                              :
                              <Skeleton width={"60%"} />
                            }
                          </CardContent>
                        </Card>
                      </Link>
                        <Button disableRipple onClick={() => this.handleBookNow(catalogue.id)} className={style.bookNow}>{configJSON.bookNow}</Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {!!pagination?.total_count && (
            <Box marginBottom={1.5}>
              <Suspense  fallback={<CircularProgress/>}>
                <Pagination
                  count={pagination?.total_count}
                  onChange={this.handlePageChange}
                  pageSize={pagination?.per_page}
                  itemText={this.state.renameService ? this.state.renameService : "Services"}
                  pageNo={pagination?.current_page || 1}
                />
              </Suspense>
            </Box>
          )}

            {!loading && services.length === 0 && (
              <Card className={style.noServicesCard}>
                <CardMedia
                  component="img"
                  image={IconNotFound}
                  className={style.notFoundImage}
                />
                <Typography className={style.noResultsFound}>
                  {configJSON.textNoResultsFound}
                </Typography>
                <Typography className={style.pleaseRefineYourSearch}>
                  {configJSON.textNoResultHint}
                </Typography>
              </Card>
            )}
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Styles = (theme: Theme) =>
  createStyles({
    breadcrumbsWrapper: {
      margin: theme.spacing(5, 10, 1),
      [theme.breakpoints.down(1280)]: {
        margin: theme.spacing(2, 2.25, 1),
        display: "flex",
        justifyContent: "space-between"
      }
    },
    breadcrumbLink: {
      color: "#3FC1CB",
      textDecoration: "none",
      cursor: "pointer",
    },
    breadcrumbText: {
      color: "#000000"
    },
    container: {
      display: "flex",
      gap: theme.spacing(3.5),
      margin: theme.spacing(0, 10),
      [theme.breakpoints.down(1280)]: {
        margin: theme.spacing(0)
      }
    },
    filterButton: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    filterIcon: {
      objectFit: "none"
    },
    filterButtonText: {
      fontSize: "12px",
      color: "#9B9FB1"
    },
    filter: {
      display: "flex",
      flex: "1 1 25%",
      height: "fit-content",
      paddingTop: theme.spacing(2)
    },
    noServicesCard: {
      flex: "1 1 75%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(2),
      padding: theme.spacing(12.5),
      height: "394px",
      marginBottom: '10px'
    },
    loadingResults: {
      display: "flex",
      alignItems: "center"
    },
    loadingResultsText: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000000",
      whiteSpace: "pre"
    },
    notFoundImage: {
      width: "103px"
    },
    noResultsFound: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000000",
      marginTop: theme.spacing(5)
    },
    pleaseRefineYourSearch: {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#83889E",
      marginTop: theme.spacing(1)
    },
    resultsWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 75%",
      margin: '0px 10px'
    },
    chip: {
      alignSelf: "flex-start",
      minHeight: "32px",
      marginTop: theme.spacing(2),
      backgroundColor: "#FFFFFF",
      border: "1px solid #D0D2DA",
      fontSize: "14px",
      lineHeight: "24px",
      [theme.breakpoints.down(1280)]: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(1)
      }
    },
    deleteChipIcon: {
      width: "10px",
      height: "10px",
      color: "#D0D2DA",
      marginRight: "10px !important",
      marginLeft: theme.spacing(0)
    },
    listWrapper: {
      display: "flex",
      flex: "1 1 75%",
      justifyContent: "center"
    },
    list: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      width: "100%",
      [theme.breakpoints.up(1440)]: {
        maxWidth: "1330px",
      }
    },
    item: {
      width: "calc(100% / 3)",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% / 2)",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.down(1280)]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      }
    },
    link: {
      textDecoration: "none",
      width: "100%",
      display: "flex"
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "424px",
      height: "352px",
      flex: "1",
      boxShadow: '0 0',
      background: "inherit",
      [theme.breakpoints.down(1280)]: {
        width: "170px",
        height: "311px"
      },
      borderRadius: "0px",
    },
    image: {
      height: "75%",
      [theme.breakpoints.down("sm")]: {
        height: "213px"
      },
      [theme.breakpoints.down(450)]: {
        height: "203px"
      },
    },
    content: {
      padding: "16px 0",
      background: "inherit",
    },
    name: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#000000",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down(1280)]: {
        fontSize: "14px",
        lineHeight: "20px"
      },
    },
    briefWeb: {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000000",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down(1280)]: {
        fontSize: "12px",
        lineHeight: "20px"
      },
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },
    briefMobile:{
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block"
      },
    },
    bookNow: {
      width: "100%",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      backgroundColor: "#3FC1CB",
      color: "#FFFFFF",
      borderRadius: "5px",
      padding: "6px 60px",
      [theme.breakpoints.down("sm")]: {
        flex: 1,
        padding: "6px 10px",
      },
      "&.MuiButton-root:hover": {
        backgroundColor: "#3FC1CB",
      }
    },
    skeleton:{
      width: "430px",
      height: "300px",
      display: "flex",
      flexDirection:"column"
    },
    loaderContainer:{
      display: "flex",
      flexWrap:"wrap"
    }
  });

export default withStyles(Styles, { withTheme: true })(ServicesWrapper);
// Customizable Area End
