// Customizable Area Start
import * as React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../ss-cms-common-components/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { DialogProps } from "../../ss-cms-common-components/src/Dialog/DialogContext";
import { withHeadeActionBarProps } from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import { withToastProps } from "../../ss-cms-common-components/src/HOC/withSnackBar.Web";
import {FormikProps} from "formik";
import {generateRequestMessage, isTokenExpired, getError, clearStorageData} from "../../ss-cms-common-components/src/Utilities/Utilities";
export const configJSON = require("./config.js");
import moment from "moment";
import {WithStyles} from "@material-ui/core";

export type Props = RouterProps & WithStyles &
  DialogProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    id: string;
    showTable: () => void;
    match: MatchType;
  };

interface MatchType {
  params: {
    id: string;
  }
}


export interface payloadTypes {
  code: string,
  description: string,
  discount: string,
  discount_type: string,
  limit: string | number | null,
  max_cart_value: string,
  min_cart_value: string,
  title: string,
  valid_from: string,
  valid_to: string
}
export interface validationTypes {
  promoCodeName: boolean,
  description: boolean,
  code: boolean,
  discountType: boolean,
  discount: boolean,
  validFrom: boolean,
  validTo: boolean,
  minCartValue: boolean,
  maxCartValue: boolean,
}

interface filledDataTypes {
  promoCodeName: string,
  description: string,
  code: string,
  discountType: string,
  discount: string,
  validFrom: string,
  validTo: string,
  minCartValue: string,
  maxCartValue: string,
  userLimit: string | number | null,
  userLimitDisabled: boolean,
}

interface responseTypes{
  errors?: {};
  id: string,
  types: string,
  attributes: {
    id: number,
    title: string,
    description: string,
    code: string,
    discount_type: string,
    discount: string,
    valid_from: string,
    valid_to: string,
    min_cart_value: string,
    max_cart_value: string,
    limit: string | number | null,
  }
}

interface responseDataType {
  data: responseTypes,
  errors? : object
}
interface S {
  promoCodeId: number | string;
  validations: validationTypes;
  filledData?: filledDataTypes;
  isSpinner: boolean;
}

interface SS {
  id: number;
}

export default class PromoCodeCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPromoCodeApiCallId: string = "";
  addPromoCodeApiCallId: string = "";
  updatePromoCodeApiCallId: string = "";
  formRef: React.RefObject<FormikProps<filledDataTypes>>;
  
  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);
    this.state = {
      promoCodeId: (props.navigation.getParam("id")) || 0,
      validations: {
        promoCodeName: true,
        description: true,
        code: true,
        discountType: true,
        discount: true,
        validFrom: true,
        validTo: true,
        minCartValue: true,
        maxCartValue: true,
      },
      isSpinner: true,
    };
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.messageGetPromoCodeApiCall(apiRequestCallId, responseJson)
      this.messageAddPromoCodeApiCall(apiRequestCallId, responseJson)
      this.messageUpdatePromoCodeApiCall(apiRequestCallId, responseJson)
    }  else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      this.messageActionMessageFromToaster(type)
    }
  }

  logoutAndNavigateLogin = () => {
    clearStorageData();
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLogin"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  messageActionMessageFromToaster = (type: string) => {
    if (type === "SAVECHANGES") {
      this.formRef?.current?.handleSubmit();
    } else if (type === "DISCARDCHANGES") {
      this.formRef?.current?.resetForm();
    }
  }


  messageGetPromoCodeApiCall = (apiRequestCallId: string, responseJson: responseDataType) => {
    if (this.getPromoCodeApiCallId === apiRequestCallId) {
      this.props.hideLoader();
      this.setState({ isSpinner: false });
      if (responseJson && responseJson?.data) {
        this.initPromoCodeData(responseJson.data);
      } else {
        this.handleErrorRespone(responseJson);
      }
    }
  }

  messageAddPromoCodeApiCall = (apiRequestCallId: string, responseJson: responseDataType) => {
    if (this.addPromoCodeApiCallId === apiRequestCallId) {
      this.props.hideLoader();
      if (responseJson && !responseJson?.errors) {
        this.handleAddUpdateResponse(configJSON.addPromoCodeSuccess);
      } else {
        this.handleErrorRespone(responseJson);
      }
    }
  } 
  
  messageUpdatePromoCodeApiCall = (apiRequestCallId: string, responseJson: responseDataType) => {
     if (this.updatePromoCodeApiCallId === apiRequestCallId) {
      this.props.hideLoader();
      if (responseJson && !responseJson?.errors) {
        this.handleAddUpdateResponse(configJSON.updatePromoCodeSuccess);
      } else {
        this.handleErrorRespone(responseJson);
      }
    }
  } 

  handleErrorRespone = (responseJson: responseDataType) => {
    let errorMessage = getError(responseJson);
    this.props.showHeaderBar({
      message: errorMessage,
      type: "error",
    });
  };
  initPromoCodeData = (item: responseTypes) => {
    let filledData = {
      promoCodeName: item.attributes.title,
      description: item.attributes.description,
      code: item.attributes.code,
      discountType: item.attributes.discount_type,
      discount: item.attributes.discount,
      validFrom: item.attributes.valid_from+"T"+moment(0).format().split("T")[1],
      validTo: item.attributes.valid_to+"T"+moment(0).format().split("T")[1],
      minCartValue: item.attributes.min_cart_value,
      maxCartValue: item.attributes.max_cart_value,
      userLimit: item.attributes.limit,
      userLimitDisabled: item.attributes.limit === null ? true : false,
    };
    this.setState({ filledData: filledData });
  };

  handleAddUpdateResponse = (message: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "AdminPromoCode");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  getPromoCodeDataApi = async () => {
    this.setState({ isSpinner: true });
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      `${configJSON.getPromoCodeApi}/${this.state.promoCodeId}`,
      configJSON.getApiMethod
    );
    this.getPromoCodeApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addPromoCodeApi = async (promoCode: payloadTypes) => {
    this.props.showLoader();
    const requestMessage = await generateRequestMessage(
      `${configJSON.getPromoCodeApi}`,
      configJSON.postAPiMethod
    );
    this.addPromoCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(promoCode)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePromoCodeApi = async (promoCode: payloadTypes) => {
    const { promoCodeId } = this.state;
    this.props.showLoader();
     const requestMessage = await generateRequestMessage(
      `${configJSON.getPromoCodeApi}/${promoCodeId}`,
      configJSON.putAPiMethod
    );
    this.updatePromoCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(promoCode)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  };

   async componentDidMount() {
    if (this.state.promoCodeId) {
       await this.getPromoCodeDataApi();
    }
  }
  
  
  openToastOnChange = () => {
    this.props.showHeaderBar({});
  };

  handleBackClick = () => {
    this.props.navigation.goBack();
  };
  handleSubmitPromocode = (data: payloadTypes) => {
    if (this.state.promoCodeId) {
      this.updatePromoCodeApi(data);
    } else {
      this.addPromoCodeApi(data);
    }
  };
}
// Customizable Area End
