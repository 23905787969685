import React from "react";
// Customizable Area Start
import { Box, Grid, Card, Typography, Divider, Theme, withStyles, Button, CardActions, CardContent, Collapse, IconButton } from "@material-ui/core";
import ConfirmationController, { Props, configJSON } from "./ConfirmationController.web"
import Currency from "../../utilities/src/components/Currency.web";
import {CheckMarkCurrect} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
// Customizable Area End

// Customizable Area Start
export const styles = (theme: Theme) => ({
  confirmationContainer: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "40px 20px",
    }
  },
  textFirst: {
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#364F6B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    }
  },
  divider: {
    maxWidth: "160px",
    margin: "10px auto",
    marginBottom: "50px",
    backgroundColor: '#3FC1CB',
    height: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "35px"
    }
  },
  textSecond: {
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "14px",
    marginBottom: "20px",
    color: "#3C3E49",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "35px",
    }
  },
  containerBox: {
    maxWidth: "628px",
    margin: "auto"
  },
  appointmentText: {
    maxWidth: "628px",
    margin: "auto",
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Rubik",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  subText: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#3C3E49",
    lineHeight: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#757575"
    }
  },
  serviceText: {
    fontWeight: 400,
    fontSize: "18px",
    fontFamily: "Rubik",
    color: "#757575",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  title: {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px",
    paddingBottom: "5px",
  },
  servicePrice: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Rubik",
    color: "#000000",
    lineHeight: "24px"
  },
  successIcon: {
    width: "82.54px",
    height: "82.54px",
    cursor: "pointer",
    top:"250px",
    marginBottom: "40px",
  },

  footer: {
    borderTop: "1px solid #DFDFDF",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px"
  },
  footerText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  sectionFirst: {
    borderTop: "1px solid #DFDFDF",
    marginTop: "35px",
    marginBottom: "20px",
  },
  subHeading: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  content: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    color: "#757575"
  },
  subContainer: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "20px",
    borderTop: "1px solid #DFDFDF",
  },
  textConatiner: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    lineHeight: "24px"
  },
  comment: {
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#8C8C8C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    }
  },
  cardContainer: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    },
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  serviceTextServiceName: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    color: "black",
    paddingBottom: "10px",
  },
  imageContainer: {
    height: "160px",
    [theme.breakpoints.down(960)]: {
      height: "140px"
    },
    [theme.breakpoints.down(480)]: {
      height: "120px"
    },
  },
  imageTag: {
    objectFit: "cover" as "cover"
  },
  proceedBtn: {
    color: "white",
    backgroundColor: "#3FC1CB",
    width: "100%",
    height: "48px",
    margin: "auto",
    borderRadius: "5px",
    marginTop: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    boxShadow: "none",
    '& .MuiButton-label': {
      textTransform: "none"
    },
    '&:hover': {
      backgroundColor: "#3FC1CB",
      boxShadow: "none"
    }
  },

  card_Shadow:{
    marginTop: "25px",
    '@media(max-width: 480px)': {
      boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
    }
  },
  collapseViewCard: {
    padding: "10px 0px",
    height:"40px",
    alignItems: "flex-start"
  },
  viewPolicies:{
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
    lineHeight: "20px",
  },
  expandedPoliciess: {
    transform: "rotate(-90deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    padding: "0px",
    marginBottom: "16px"
  },
  expandedPoliciesOpen: {
    transform: "rotate(0deg)",
  },
  cardContent:{
    "&.MuiCardContent-root":{
      padding:"0px"
    },
    "&.MuiCardContent-root:last-child":{
      paddingBottom: "10px"
    }
  },
  policyTitle:{
    fontSize: "12px",
    fontWeight: 500,
    marginBottom:"5px",
    lineHeight: "20px"
  },
  policyDevider:{
    margin:"10px 0px",
    border:"1px solid #E8E8E8"
  },
  policyDesc:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#3C3E49",
    lineHeight: "20px"
  },
  policytext:{
    fontSize: "12px",
    fontWeight: 400,
    color: "#676B7E",
    lineHeight: "20px"
  },
  collapseBox:{
    marginTop: "10px"
  },
})
// Customizable Area End

export class ConfirmationService extends ConfirmationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderPolicies = (classes: Record<string, string>) => {
    const { no_of_days_cancel, no_of_days_reschedule, reschedule_text, refund_text, cancel_text, is_cancellation_policy, is_refund_policy, is_reschedule_policy } = this.props.commonSetting.attributes
    return (
      <>
        {
          (is_cancellation_policy || is_refund_policy || is_reschedule_policy) &&
          <Card className={classes.card_Shadow} style={{ maxWidth: "1923px" }}>
            <Box style={{ padding: "0px 15px" }}>
              <CardActions disableSpacing className={classes.collapseViewCard}>
                <Typography variant="h6" className={classes.viewPolicies}>
                  {configJSON.viewPolicies}
                </Typography>
                <IconButton
                  className={clsx(classes.expandedPoliciess, {
                    [classes.expandedPoliciesOpen]: this.state.expandedPolicies,
                  })}
                  data-testid="expandTestId"
                  onClick={this.handleExpandPolicies}
                  aria-expandedPolicies={this.state.expandedPolicies}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse
                in={this.state.expandedPolicies}
                timeout="auto" unmountOnExit >
                <CardContent className={classes.cardContent}>
                  {
                    is_cancellation_policy &&
                    <>
                      <Box>
                        <Typography className={classes.policyTitle}>{configJSON.cancelPolicies}</Typography>
                        <Typography className={classes.policyDesc}>{`${no_of_days_cancel} ${configJSON.cancellationPolicyMsg}`}</Typography>
                        <Typography className={classes.policytext}>{cancel_text}</Typography>
                      </Box>
                      <Divider className={classes.policyDevider} />
                    </>
                  }
                  {
                    is_reschedule_policy &&
                    <>
                      <Box className={classes.collapseBox}>
                        <Typography className={classes.policyTitle}>{configJSON.rechedulePolicies}</Typography>
                        <Typography className={classes.policyDesc}>{`${no_of_days_reschedule} ${configJSON.reschedulePolicyMsg}`}</Typography>
                        <Typography className={classes.policytext}>{reschedule_text}</Typography>
                      </Box>
                      <Divider className={classes.policyDevider} />
                    </>
                  }
                  {
                    is_refund_policy &&
                    <>
                      <Box className={classes.collapseBox}>
                        <Typography className={classes.policyTitle}>{configJSON.refundPolicies}</Typography>
                        <Typography className={classes.policytext}>{refund_text}</Typography>
                      </Box>
                    </>
                  }
                </CardContent>
              </Collapse>
            </Box>
          </Card>
        }
      </>
    )
  }

  render() {
  
    const { bookingData, paymentOption, classes } = this.props;
    const {order_id, order_date, service_provider, service, appointment_date, time_zone_short, is_promocode_applied} = bookingData.attributes;
    const { full_name, comment, email, full_phone_number } =  bookingData.attributes.personal_detail
    console.log("service.small_url",service.small_url)
    let paymentMethod;

    if (paymentOption === "pay_in_person") {
      paymentMethod = "Pay later at location";
    } else if (paymentOption === "pay_online") {
      paymentMethod = "Online";
    } else {
      paymentMethod = "";
    }
    return (
     
      <>
        <Box className={classes.confirmationContainer}>
          <Box style={{ textAlign: "center" }}> 
          <img src={CheckMarkCurrect} alt="icon" className={classes.successIcon} />
            <Typography
              className={classes.textFirst}
              style={{ textTransform: "uppercase" }}
            >
              {configJSON.thankyou} {full_name}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.textSecond}>
              {configJSON.yourBookingIsConfirmed}{email}
            </Typography>
          </Box>
          <Box className={classes.containerBox}>
            <Card className={classes.cardContainer}>
              <Typography
                className={classes.appointmentText}
              >
                {configJSON.appointmentSummary}
              </Typography>
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subText}>{configJSON.orderId}{order_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography className={classes.subText}>{configJSON.orderDate}{this.momentChangeForOrderData(order_date)}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                <Grid item xs={4} sm={4} md={4} className={classes.imageContainer}>
                  <img
                    src={service.small_url ? service.small_url : service.images}
                    alt="img"
                    width="100%"
                    height= "100%"
                    className={classes.imageTag}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <Box>
                    <Typography className={classes.serviceText}>
                      {configJSON.service}
                    </Typography>
                    <Typography className={classes.title}>
                      {service.title}, {service.duration} {configJSON.mins}
                    </Typography>
                    <Typography className={classes.serviceTextServiceName}>
                      {configJSON.textAppointmentWith}{service_provider?.full_name}
                    </Typography>
                    <Typography className={classes.servicePrice}>
                        <Currency text={`${bookingData?.attributes?.total?.toFixed(2)} `}
                        disountPrice={service?.price.toFixed(2)}
                        time={""}
                        hasDiscount={service.discount_option} />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className={classes.sectionFirst}>
                <Typography className={classes.subHeading}>{configJSON.appointmentDateAndTime}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{this.momentChangeForAppointmentDate(appointment_date,time_zone_short)}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainer}>
                <Typography className={classes.subHeading}>{configJSON.personalDetails}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{full_name}</Typography>
                  <Typography className={classes.textConatiner}>{email} | {full_phone_number}</Typography>
                  <Typography className={classes.comment}>{comment}</Typography>
                </Box>
              </Box>
              <Box className={classes.subContainer}>
                <Typography className={classes.subHeading}>{configJSON.modeOfPayment}</Typography>
                <Box style={{ marginTop: "10px" }}>
                  <Typography className={classes.textConatiner}>{paymentMethod}</Typography>
                </Box>
              </Box>
              <Box className={classes.footer}>
                <Box className={classes.footerContainer}>
                  <Typography className={classes.footerText}>{configJSON.total}</Typography>
                  <Typography className={classes.footerText}>
                    <Currency
                      text={`${bookingData?.attributes?.total?.toFixed(2)}`}
                      disountPrice={""}
                      time={""}
                      hasDiscount={false} />
                  </Typography>
                </Box>
              </Box>
              {this.renderPolicies(classes)}
              <Button
                variant="contained"
                className={classes.proceedBtn}
                onClick={this.handleGoToHomeScreen}
              >
                {configJSON.ExploreMoreServices}
              </Button>
            </Card>
          </Box>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(ConfirmationService);
// Customizable Area End