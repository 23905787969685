// Customizable Area Start
import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000bf",
    position: "fixed",
    top: 0,
  },
  errorWrapper: {
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  error: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3C3E49",
    padding: theme.spacing(1.25, 3),
    height: "68px",
  },
  errorIcon: {
    color: "transparent",
  },
  errorMessages: {
    padding: theme.spacing(0, 1.25),
    maxHeight: "52px",
    overflow: "auto",
  },
  errorText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  container: {
    position: "absolute",
    width: "780px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "3px",
  },
  wrapper: {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    padding: theme.spacing(5),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(5),
  },
  title: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
  },
  iconWrapper: {
    padding: 0,
  },
  closeIcon: {
    fontSize: "32px",
    color: "#000000",
  },
  content: { display: "flex", flexDirection: "column", overflow: "auto" },
  subheaders: { display: "flex" },
  subtitle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.56px",
    color: "#3C3E49",
    flex: "0 0 25%",
  },
  list: {},
  listItem: { display: "flex" },
  dayWrapper: {
    display: "flex",
    flex: "0 0 25%",
    alignSelf: "flex-start",
    marginTop: theme.spacing(3.5),
  },
  checkbox: {
    color: "#364F6B !important",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 1.5, 0, 0),
  },
  day: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#3C3E49",
  },
  workingRange: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(1.5),
  },
  field: {
    flex: "0 0 213px",
  },
  input: {
    width: "186px",
    height: "48px",
    padding: theme.spacing(0, 1.75),
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3C3E49",
    "&.Mui-disabled": {
      backgroundColor: "#3C3E4920",
      color: "#00000042",
      cursor: "not-allowed",
    },
  },
  separator: {
    marginTop: theme.spacing(1.5),
  },
  availabilityIcon: {
    color: "#3C3E49",
    marginTop: theme.spacing(1.5),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid #E8E8E8",
  },
  cancelButton: {
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
    textDecoration: "underline",
    padding: theme.spacing(2.5, 6),
    color: "#3C3E49",
  },
  saveButton: {
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "3px",
    padding: theme.spacing(2.5, 6),
    backgroundColor: "#00D659",
    color: "#3C3E49",
  },
}));
// Customizable Area End